import React, { useContext, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { TemplateEmailContext } from "../TemplateEmailContext";
import Formulario from "../../../../../Components/Cadastro/Formulario/Formulario";

const FormularioTemplateEmail = () => {
  const { values, setValues, getDetailHandler } = useContext(
    TemplateEmailContext
  );
  let { id } = useParams();

  useEffect(() => {
    getDetailHandler(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      data: {
        ...values.data,
        [name]: value,
      },
    });
  }

  return (
    <Fragment>
      <Formulario context={TemplateEmailContext}>
        <div className="col-12 col-md-3  form-group">
          <label>
            Produto <span className="campoObratorio">*</span>
          </label>
          <select
            disabled={values.formDisabled}
            className="form-control"
            name="produtoId"
            value={values.data.produtoId}
            onChange={onChangeInput}
          >
            <option className="disabled" value="0"></option>
            {values.produtos.map((produto) => (
              <option key={produto.produtoId} value={produto.produtoId}>
                {produto.produtoNome}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-6 form-group">
          <label>
            Nome do Template <span className="campoObratorio">*</span>
          </label>
          <input
            disabled={values.formDisabled}
            className="form-control"
            type="text"
            maxLength="50"
            name="templateEmailNome"
            value={values.data.templateEmailNome}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-3  form-group">
          <label>
            Tipo <span className="campoObratorio">*</span>
          </label>
          <select
            disabled={values.formDisabled}
            className="form-control"
            name="templateEmailTipo"
            value={values.data.templateEmailTipo}
            onChange={onChangeInput}
          >
            <option value="I">INFORMATIVO</option>
            <option value="C">CAMPANHA</option>

          </select>
        </div>
        <div className="col-12 form-group">
          <label>
            Assunto <span className="campoObratorio">*</span>
          </label>
          <input
            disabled={values.formDisabled}
            className="form-control"
            type="text"
            maxLength="50"
            name="templateEmailAssunto"
            value={values.data.templateEmailAssunto}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 form-group">
          <label>
            Mensagem <span className="campoObratorio">*</span>
          </label>
          <textarea
            disabled={values.formDisabled}
            className="form-control"
            type="text"
            cols="30"
            rows="10"
            name="templateEmailMsg"
            value={values.data.templateEmailMsg}
            onChange={onChangeInput}
          />
        </div>
      </Formulario>
    </Fragment>
  );
};

export default FormularioTemplateEmail;
