import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "Help";
export const BuscarHelp = (identificadorHelp) =>
  RequestWebApi.get(`/${controller}/BuscarHelp`, {
    params: { identificadorHelp: identificadorHelp }
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const help = resultData;
      return { success, help };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, help: null };
    });