import { createContext } from "react";

const AppContext = createContext({
  token: null,
  setToken: () => {},
  removeToken: () => {},
  session: null,
  setSession: () => {},
  removeSession: () => {},
  loading: false,
  message: "",
  showLoading: (message) => {},
  hideLoading: () => {},
  showNotifyMessage: (messageNotify) => {},
  tituloTela: "",
  setTituloTela: (tituloTela) => {},
  removeTituloTela: () => {},
  showMessage: (tipo, mensagem) => {},
  acessos: [],
  setAcessos: (tituloTela) => {},
  removeAcessos: () => {},
  modulo: [],
  setModulo: (tituloTela) => {},
  removeModulo: () => {},
});

export default AppContext;
