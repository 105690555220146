import React, { useContext, Fragment, useEffect } from 'react'
import { RelatoriosModuloContext } from '../RelatoriosModuloContext'
import Modulo from "../Component/Modulo/Modulo";

const PesquisarRelatoriosModulo = () => {
  const { values, resetScreen } = useContext(RelatoriosModuloContext)

  useEffect(() => {
    resetScreen()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <div className="relatorioModuloV2Body">
        <div className="row">
          {
            values.relatorios
              ? values.relatorios.map((relatorio) => (
                <div key={relatorio.relatorioId} className="col-3">
                  <Modulo
                    key={relatorio.relatorioId}
                    relatorioId={relatorio.relatorioId}
                    idModulo={relatorio.relatorioNome}
                    descricao={relatorio.relatorioDescricao}
                    helpDescricao={relatorio.relatorioDescricaoAjuda}
                    titulo={relatorio.relatorioNome}
                    path={relatorio.relatorioPath}
                    icone={relatorio.relatorioIcone}
                  />
                </div>
              ))
              : null}
        </div>
      </div>
    </Fragment>
  )
}

export default PesquisarRelatoriosModulo
