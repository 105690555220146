import React, { Fragment } from "react";
import GroupBox from "../../../GroupBox/GroupBox";
import BackofficeRealizado from "../BackofficesRealizados/BackofficeRealizado";
const VendaRealizada = ({
  defineDetalhesObjeto = () => { },
  venda = {},
  apolice = {},
  backoffice = {},
  endossos = [],
  cobrancas = [],
  sinistros = [],
}) => {
  return (
    <Fragment>


      {venda.vendaDataHora ? (
        <div className="col-md-2">
          <div className="font-weight-bold">
            Data:{" "}
            <span className="font-weight-normal">
              {venda.vendaDataHora}
            </span>
          </div>
        </div>
      ) : null}

      {venda.seguradoraDescricao ? (
        <div className="col-md-4">
          <div className="font-weight-bold">
            Seguradora:{" "}
            <span className="font-weight-normal">
              {venda.seguradoraDescricao}
            </span>
          </div>
        </div>
      ) : null}


      {venda.vendaValorLiq ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Valor Liq:{" "}
            <span className="font-weight-normal">
              {venda.vendaValorLiq}
            </span>
          </div>
        </div>
      ) : null}

      {venda.vendaPercComissao ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Perc. Comissão:{" "}
            <span className="font-weight-normal">
              {venda.vendaPercComissao}
            </span>
          </div>
        </div>
      ) : null}

      {venda.vendaServicoContratado ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Serv. Contratado:{" "}
            <span className="font-weight-normal">
              {venda.vendaServicoContratado}
            </span>
          </div>
        </div>
      ) : null}


      {venda.vendaCodStatusSeguradora ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Cód. Status Seguradora:{" "}
            <span className="font-weight-normal">
              {venda.vendaCodStatusSeguradora}
            </span>
          </div>
        </div>
      ) : null}


      {venda.vendaTipoTransmissao ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Tipo Transmissão:{" "}
            <span className="font-weight-normal">
              {venda.vendaTipoTransmissao}
            </span>
          </div>
        </div>
      ) : null}

      {venda.vendaRenovacao ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Renovação:{" "}
            <span className="font-weight-normal">
              {venda.vendaRenovacao ? "Sim" : "Não"}
            </span>
          </div>
        </div>
      ) : null}

      {apolice.apoliceNumProposta ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Núm. Proposta:{" "}
            <span className="font-weight-normal">
              {apolice.apoliceNumProposta}
            </span>
          </div>
        </div>
      ) : null}

      {apolice.apoliceDataProposta ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Data. Proposta:{" "}
            <span className="font-weight-normal">
              {apolice.apoliceDataProposta}
            </span>
          </div>
        </div>
      ) : null}

      {apolice.apoliceNumero ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Núm. Apólice:{" "}
            <span className="font-weight-normal">
              {apolice.apoliceNumero}
            </span>
          </div>
        </div>
      ) : null}

      {apolice.apoliceData ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Data Apólice:{" "}
            <span className="font-weight-normal">
              {apolice.apoliceData}
            </span>
          </div>
        </div>
      ) : null}


      {backoffice ? (
        <GroupBox titulo="Backoffice" className="col-12">
          <div className="col-md-12">
            <div className="row">
              <BackofficeRealizado backoffice={backoffice} />
            </div>
          </div>
        </GroupBox>
      ) : null}

      {endossos.length > 0 ? (
        <GroupBox titulo="Endossos" className="col-12">
          <div className="col-md-12">
            <div className="row">
              {endossos.map((endosso) => {
                return <Fragment>

                  {endosso.endossoId ? (
                    <div className="col-md-3">
                      <div className="font-weight-bold">
                        Prot. Endosso:{" "}
                        <span className="font-weight-normal">
                          {endosso.endossoId}
                        </span>
                      </div>
                    </div>
                  ) : null}

                  {endosso.endossoDataSolicitacao ? (
                    <div className="col-md-3">
                      <div className="font-weight-bold">
                        Data Endosso:{" "}
                        <span className="font-weight-normal">
                          {endosso.endossoDataSolicitacao}
                        </span>
                      </div>
                    </div>
                  ) : null}
                  <div className="col-md-6"></div>
                </Fragment>

              })}
            </div>
          </div>
        </GroupBox>
      ) : null}

      {cobrancas.length > 0 ? (
        <GroupBox titulo="Cobranças" className="col-12">
          <div className="col-md-12">
            <div className="row">
              {cobrancas.map((cobranca) => {
                return <Fragment>

                  {cobranca.cobrancaId ? (
                    <div className="col-md-3">
                      <div className="font-weight-bold">
                        Prot. Cobrança:{" "}
                        <span className="font-weight-normal">
                          {cobranca.cobrancaId}
                        </span>
                      </div>
                    </div>
                  ) : null}

                  {cobranca.cobrancaDataHora ? (
                    <div className="col-md-3">
                      <div className="font-weight-bold">
                        Data Cobrança:{" "}
                        <span className="font-weight-normal">
                          {cobranca.cobrancaDataHora}
                        </span>
                      </div>
                    </div>
                  ) : null}
                  <div className="col-md-6"></div>
                </Fragment>

              })}
            </div>
          </div>
        </GroupBox>
      ) : null}

      {sinistros.length > 0 ? (
        <GroupBox titulo="Sinistros" className="col-12">
          <div className="col-md-12">
            <div className="row">
              {sinistros.map((sinistro) => {
                return <Fragment>

                  {sinistro.sinistroId ? (
                    <div className="col-md-3">
                      <div className="font-weight-bold">
                        Prot. Sinistro:{" "}
                        <span className="font-weight-normal">
                          {sinistro.sinistroId}
                        </span>
                      </div>
                    </div>
                  ) : null}

                  {sinistro.sinistroDataHora ? (
                    <div className="col-md-3">
                      <div className="font-weight-bold">
                        Data Sinistro:{" "}
                        <span className="font-weight-normal">
                          {sinistro.sinistroDataHora}
                        </span>
                      </div>
                    </div>
                  ) : null}
                  <div className="col-md-6"></div>
                </Fragment>

              })}
            </div>
          </div>
        </GroupBox>
      ) : null}

    </Fragment>
  );
};

export default VendaRealizada;
