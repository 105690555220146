/* eslint-disable array-callback-return */
import React, { Fragment, useContext } from "react";

export const EnviarLeads = ({ context }) => {
  const { values } = useContext(context);
  let totalProspectRepetido = 0;
  function logArrayElements(element, index, array) {
    let baseProspectsItens = array.filter(
      (item) => item.baseProspectItensId !== element.baseProspectItensId
    );

    let base = baseProspectsItens.find(
      (item) =>
        (item.baseProspectItensEMail &&
          item.baseProspectItensEMail === element.baseProspectItensEMail) ||
        (item.baseProspectItensTelefone &&
          item.baseProspectItensTelefone === element.baseProspectItensTelefone)
    );
    if (base) {
      totalProspectRepetido++;
    }
  }

  const tatalRepetidos = () => {
    values.baseProspectsItens.forEach(logArrayElements);

    return totalProspectRepetido;
  };

  return (
    <Fragment>
      <div className="col-3">
        <div className="default-title">Resultado</div>
        <div className="card shadow-sm mb-4">
          <div className="row">
            <div className="col-12 col-md-12 pt-2">
              <label>Prospects Repetidos: {tatalRepetidos()}</label>
            </div>
            <div className="col-12 col-md-12 pt-2">
              <label>
                Total de base selecionadas:{" "}
                {values.data.baseProspectsItens.length}
              </label>
            </div>
            <div className="col-12 col-md-12 pt-2">
              <label>
                Prospects totais: {values.baseProspectsItens.length}
              </label>
            </div>
            <div className="col-12 col-md-12 pt-2">
              <label>
                Prospects não selecionados:{" "}
                {values.baseProspectsItens.length -
                  values.data.baseProspectsItens.length}
              </label>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EnviarLeads;
