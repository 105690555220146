import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "TipoEndosso";
export const Listar = () =>
  RequestWebApi.get(`/${controller}/Listar`)
    .then(function (response) {
      const { resultData, success } = response.data;
      const tiposEndosso = resultData;
      return { success, tiposEndosso };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { tiposEndosso: [] };
    });

export const Incluir = (tipoEndosso) =>
  RequestWebApi.post(`/${controller}/Incluir`, tipoEndosso)
    .then(function (response) {
      const { resultData, success } = response.data;
      const novoTipoEndosso = resultData;
      return { success, novoTipoEndosso };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Atualizar = (tipoEndosso) =>
  RequestWebApi.put(`/${controller}/Atualizar`, tipoEndosso)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (tipoEndossoId) =>
  RequestWebApi.delete(`/${controller}/Remover`, {
    params: { tipoEndossoId: tipoEndossoId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });
