import React, { Fragment } from "react";


const FormularioVendaLeadAuto = ({ produto, vendaAuto }) => {
  return (
    <Fragment>
      {
        produto.produtoFormAuxVenda !== "FORMVDAAUTO" || !vendaAuto ? null :
          <Fragment>
            <div className="col-12 col-md-3">
              <label>
                Forma Pagto 1ª Vistoria
              </label>
              <input
                disabled
                type="text"
                maxLength="30"
                className="form-control"
                name="vendaAutoFormaPagto1Parc"
                defaultValue = {vendaAuto.vendaAutoFormaPagto1Parc}
              />
            </div>
            <div className="col-12 col-md-3">
              <label>
                Tipo Vistoria
              </label>
              <input
                disabled
                type="text"
                maxLength="30"
                className="form-control"
                name="vendaAutoTipoVistoria"
                defaultValue = {vendaAuto.vendaAutoTipoVistoria}
              />
            </div>
            <div className="col-12 col-md-3">
              <div className="form-group">
                <label>
                  No. Agendamento
                </label>
                <input
                  disabled
                  type="text"
                  maxLength="30"
                  className="form-control"
                  name="vendaAutoNumAgendVistoria"
                  defaultValue = {vendaAuto.vendaAutoNumAgendVistoria}
                />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="form-group">
                <label>
                  Nome da Prestadora
                </label>
                <input
                  disabled
                  type="text"
                  maxLength="30"
                  className="form-control"
                  name="vendaAutoNomePrestadora"
                  defaultValue = {vendaAuto.vendaAutoNomePrestadora}
                />
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="form-group">
                <label>
                  Data Prevista Vist.
                </label>
                <input
                  disabled
                  type="date"
                  className="form-control"
                  name="vendaAutoDataPrevistaVistoria"
                  defaultValue = {vendaAuto.vendaAutoDataPrevistaVistoria}
                />
              </div>
            </div>
            <div className="col-12 col-md-1">
              <div className="form-group">
                <label>
                  Ramal
                </label>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  name="vendaAutoRamal"
                  defaultValue = {vendaAuto.vendaAutoRamal}
                />
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="form-group">
                <label>
                  Data
                </label>
                <input
                  disabled
                  type="date"
                  className="form-control"
                  name="vendaAutoData"
                  defaultValue = {vendaAuto.vendaAutoData}
                />
              </div>
            </div>
            <div className="col-12 col-md-1">
              <div className="form-group">
                <label>
                  Hora
                </label>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  name="vendaAutoHora"
                  defaultValue = {vendaAuto.vendaAutoHora}
                />
              </div>
            </div>

            <div className="col-12 col-md-3">
              <label>
              Adquirido cartão Porto
              </label>
              <input
                disabled
                type="text"
                className="form-control"
                name="vendaAutoAdiqCartaoPortoVenda"
                defaultValue = {vendaAuto.vendaAutoAdiqCartaoPortoVenda}
              />
            </div>
          </Fragment>
      }
    </Fragment>
  );
};

export default FormularioVendaLeadAuto;
