import React, { useEffect } from "react";
import DistribuicaoLeadProvider from "./DistribuicaoLeadContext";
import PesquisarDistribuicaoLead from "./Events/PesquisarDistribuicaoLead";
import { LoadMask } from "../../../../Utils/mask";

const Index = () => {
  useEffect(() => {
    LoadMask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DistribuicaoLeadProvider>
      <div className="container-fluid pl-3 pt-3 pb-3">
        <PesquisarDistribuicaoLead />
      </div>
    </DistribuicaoLeadProvider>
  );
};

export default Index;
