import { RequestWebApi, validateRequestErrorStatus } from './Requests';

const controller = 'Grafico';
export const RetornarUrlGrafico = (rotulo) =>
	RequestWebApi.get(`/${controller}/RetornarUrlGrafico`, {
		params: { rotulo }
	})
		.then(function(response) {
			const { resultData, success } = response.data;
			const url = resultData;
			return { success, url };
		})
		.catch(function(error) {
			validateRequestErrorStatus(error.response.status);
			return { url: null };
		});

export const RetornarMetaEquipe = () =>
	RequestWebApi.get(`/${controller}/RetornarMetaEquipe`)
		.then(function(response) {
			const { resultData, success } = response.data;
			const metasEquipes = resultData;
			return { success, metasEquipes };
		})
		.catch(function(error) {
			validateRequestErrorStatus(error.response.status);
			return { metasEquipes: null };
		});

export const RetornarMetaConsultor = () =>
	RequestWebApi.get(`/${controller}/RetornarMetaConsultor`)
		.then(function(response) {
			const { resultData, success } = response.data;
			const metasConsultor = resultData;
			return { success, metasConsultor };
		})
		.catch(function(error) {
			validateRequestErrorStatus(error.response.status);
			return { metasConsultor: null };
		});
