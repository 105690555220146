import React, { useContext, Fragment, useEffect } from 'react'
import { EndossoContext } from '../EndossoContext'
import Pesquisar from '../../../../Components/Cadastro/Pesquisar/Pesquisar'
import TabelaResultados from '../../../../Components/Cadastro/TabelaResultados/TabelaResultados'
import GroupBox from '../../../../Components/GroupBox/GroupBox'

const PesquisarEndosso = () => {
  const { values, setValues, resetScreen } = useContext(EndossoContext)

  useEffect(() => {
    resetScreen()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onChangeInput(event) {
    const { name, value } = event.target
    setValues({
      ...values,
      searchData: {
        ...values.searchData,
        [name]: value,
      },
    })
  }

  function onChangeCheck(event) {
    const { name, checked } = event.target
    setValues({
      ...values,
      searchData: {
        ...values.searchData,
        [name]: checked,
      },
    })
  }

  return (
    <Fragment>
      <Pesquisar context={EndossoContext}>
        <div className="col-12 col-md-2 form-group">
          <label>CPF/CNPJ:</label>
          <input
            className="form-control"
            type="text"
            name="leadNumDocumento"
            value={values.searchData.leadNumDocumento}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12" />
        <div className="col-12 col-md-5 form-group">
          <label>Nome</label>
          <input
            className="form-control"
            type="text"
            name="endossoNomeCliente"
            value={values.searchData.endossoNomeCliente}
            onChange={onChangeInput}
          />
        </div>

        <div className="col-12 col-md-3 form-group">
          <label>Seguradora</label>
          <select
            className="form-control"
            name="seguradoraId"
            value={values.searchData.seguradoraId}
            onChange={onChangeInput}
          >
            <option className="disabled" value="0"></option>
            {values.seguradoras.map((seguradora) => (
              <option
                key={seguradora.seguradoraId}
                value={seguradora.seguradoraId}
              >
                {seguradora.seguradoraDescricao}
              </option>
            ))}
          </select>
        </div>

        <div className="col-12 col-md-3 form-group">
          <label>Tipo Endosso</label>
          <select
            className="form-control"
            name="tipoEndossoId"
            value={values.searchData.tipoEndossoId}
            onChange={onChangeInput}
          >
            <option className="disabled" value=""></option>
            {values.tiposEndosso.map((tipoEndosso) => (
              <option
                key={tipoEndosso.tipoEndossoId}
                value={tipoEndosso.tipoEndossoId}
              >
                {tipoEndosso.tipoEndossoDescricao}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-3 form-group">
          <label>
            Consultor Solicitante
          </label>
          <select
            className="form-control"
            name="endossoSolicitante"
            value={values.searchData.endossoSolicitante}
            onChange={onChangeInput}
          >
            <option className="disabled" value="" />
            {values.usuarios.map((usuario) => (
              <option key={usuario.usuarioId} value={usuario.usuarioNome}>
                {usuario.usuarioNome}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-2 form-group">
          <label>
            Situação
          </label>
          <select
            className="form-control"
            name="endossoFinalizado"
            value={values.searchData.endossoFinalizado}
            onChange={onChangeInput}
          >
            <option className="disabled" value="" />
            {values.situacoes.map((situacoe) => (
              <option key={situacoe.codigo} value={situacoe.codigo}>
                {situacoe.descricao}
              </option>
            ))}
          </select>
        </div>

        <div className="col-12 col-md-12 form-group"></div>
        <GroupBox titulo="Data Endosso" className="col-12 col-md-4 form-group">
          <div className="col-12 col-md-6 ">
            <label>De:</label>
            <input
              className="form-control"
              type="date"
              name="endossoDataHoraDe"
              value={values.searchData.endossoDataHoraDe}
              onChange={onChangeInput}
            />
          </div>
          <div className="col-12 col-md-6 ">
            <label>Até:</label>
            <input
              className="form-control"
              type="date"
              name="endossoDataHoraAte"
              value={values.searchData.endossoDataHoraAte}
              onChange={onChangeInput}
            />
          </div>
        </GroupBox>
        <GroupBox
          titulo="Data Solicitação"
          className="col-12 col-md-4 form-group"
        >
          <div className="col-12 col-md-6 ">
            <label>De:</label>
            <input
              className="form-control"
              type="date"
              name="endossoDataSolicitacaoDe"
              value={values.searchData.endossoDataSolicitacaoDe}
              onChange={onChangeInput}
            />
          </div>
          <div className="col-12 col-md-6 ">
            <label>Até:</label>
            <input
              className="form-control"
              type="date"
              name="endossoDataSolicitacaoAte"
              value={values.searchData.endossoDataSolicitacaoAte}
              onChange={onChangeInput}
            />
          </div>
        </GroupBox>
      </Pesquisar>
      <TabelaResultados context={EndossoContext} />
    </Fragment>
  )
}

export default PesquisarEndosso
