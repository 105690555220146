import React, { useContext, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { MotivoPerdaContext } from "../MotivoPerdaContext";
import Formulario from "../../../../../Components/Cadastro/Formulario/Formulario";

const FormularioMotivoPerda = () => {
  const { values, setValues, getDetailHandler } = useContext(
    MotivoPerdaContext
  );
  let { id } = useParams();

  useEffect(() => {
    getDetailHandler(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      data: {
        ...values.data,
        [name]: value,
      },
    });
  }
  return (
    <Fragment>
      <Formulario context={MotivoPerdaContext}>
        <div className="col-12 col-md-5 form-group">
          <label>Descrição</label>
          <input
            disabled={values.formDisabled}
            className="form-control uppercase"
            type="text"
            maxLength="30"
            name="motivoPerdaDescricao"
            value={values.data.motivoPerdaDescricao}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-2 form-group">
          <label>Status</label>
          <select
            disabled={values.formDisabled}
            className="form-control"
            name="motivoPerdaStatus"
            value={values.data.motivoPerdaStatus}
            onChange={onChangeInput}
          >
            <option className="disabled"></option>
            <option value="A"> Ativo </option>
            <option value="I"> Inativo </option>
          </select>
        </div>
        <div className="col-12 col-md-3 form-group">
          <label>Solicita Venc. Apólice?</label>
          <select
            disabled={values.formDisabled}
            className="form-control"
            name="motivoPerdaSolitarVencimentoApolice"
            value={values.data.motivoPerdaSolitarVencimentoApolice}
            onChange={onChangeInput}
          >
            <option value={true}> Sim </option>
            <option value={false}> Não </option>
          </select>
        </div>
      </Formulario>
    </Fragment>
  );
};

export default FormularioMotivoPerda;
