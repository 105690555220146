import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

export const Listar = (grupoUsuario) =>
  RequestWebApi.get("/GrupoUsuario/Listar", {
    params: { GrupoUsuarioNome: grupoUsuario.grupoUsuarioNome },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const gruposUsuario = resultData;
      return { success, gruposUsuario };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Detalhes = (grupoUsuarioId) =>
  RequestWebApi.get("/GrupoUsuario/Detalhes", {
    params: { grupoUsuarioId: grupoUsuarioId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const grupoUsuario = resultData;
      return { success, grupoUsuario };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, grupoUsuario: {} };
    });

export const Incluir = (grupoUsuario) =>
  RequestWebApi.post("/GrupoUsuario/Incluir", grupoUsuario)
    .then(function (response) {
      const { resultData, success } = response.data;
      const novoGrupoUsuario = resultData;
      return { success, novoGrupoUsuario };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Atualizar = (grupoUsuario) =>
  RequestWebApi.put("/GrupoUsuario/Atualizar", grupoUsuario)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (grupoUsuarioId) =>
  RequestWebApi.delete("/GrupoUsuario/Remover", {
    params: { grupoUsuarioId: grupoUsuarioId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const ListarNivelAcesso = () =>
  RequestWebApi.get("/GrupoUsuario/ListarNivelAcesso")
    .then(function (response) {
      const { resultData, success } = response.data;
      const niveisAcesso = resultData;
      return { success, niveisAcesso };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, niveisAcesso: [] };
    });
