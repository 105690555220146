import React, { Fragment } from "react";
import VendasRealizadas from "./VendasRealizadas";

const DetalheCompletoVendaRealizada = ({
  defineDetalhesObjeto = () => { },
  tipo = "",
  venda = {},
  vendas = [],
  apolices = [],
  backoffices = [],
  endossos = [],
  cobrancas = [],
  sinistros = [],
}) => {
  return (
    <Fragment>
      {tipo !== "V" ? null :
        <VendasRealizadas defineDetalhesObjeto={defineDetalhesObjeto}
          vendas={vendas.filter(x => x.vendaId === venda.vendaId)}
          apolices={apolices}
          backoffices={backoffices}
          endossos={endossos}
          cobrancas={cobrancas}
          sinistros={sinistros}
        />
      }
    </Fragment>
  );
};

export default DetalheCompletoVendaRealizada;
