import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "TarefasExecutar";

export const Listar = (tarefasExecutar) =>
  RequestWebApi.get(`/${controller}/Listar`, {
    params: { ...tarefasExecutar },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const tarefasExecutarList = resultData;
      return { success, tarefasExecutarList };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { tarefasExecutarList: [] };
    });

export const Detalhes = (tarefasExecutarId) =>
  RequestWebApi.get(`/${controller}/Detalhes`, {
    params: { tarefasExecutarId: tarefasExecutarId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const tarefasExecutar = resultData;
      return { success, tarefasExecutar };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, tarefasExecutar: {} };
    });

export const Incluir = (tarefasExecutar) =>
  RequestWebApi.post(`/${controller}/Incluir`, tarefasExecutar)
    .then(function (response) {
      const { resultData, success } = response.data;
      const novoGrupoUsuario = resultData;
      return { success, novoGrupoUsuario };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Atualizar = (tarefasExecutar) =>
  RequestWebApi.put(`/${controller}/Atualizar`, tarefasExecutar)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (tarefasExecutarId) =>
  RequestWebApi.delete(`/${controller}/Remover`, {
    params: { tarefasExecutarId: tarefasExecutarId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });
