import { RequestWebApi, validateRequestErrorStatus } from './Requests';

const controller = 'Equipe';
export const Listar = (equipe) =>
	RequestWebApi.get(`/${controller}/Listar`, {
		params: { ...equipe }
	})
		.then(function (response) {
			const { resultData, success } = response.data;
			const equipes = resultData;
			return { success, equipes };
		})
		.catch(function (error) {
			validateRequestErrorStatus(error.response.status);
			return { equipes: [] };
		});

export const Detalhes = (equipeId) =>
	RequestWebApi.get(`/${controller}/Detalhes`, {
		params: { equipeId: equipeId }
	})
		.then(function (response) {
			const { resultData, success } = response.data;
			const { equipe, supervisores, landingPages, lojasVirtual } = resultData;
			return { success, equipe, supervisores, landingPages, lojasVirtual };
		})
		.catch(function (error) {
			validateRequestErrorStatus(error.response.status);
			const { success, message, errors } = error.response.data;
			return { success, message, errors, equipe: {}, supervisores: [], lojasVirtual: [] };
		});

export const Incluir = (equipe) =>
	RequestWebApi.post(`/${controller}/Incluir`, equipe)
		.then(function (response) {
			const { resultData, success } = response.data;
			const novaEquipe = resultData;
			return { success, novaEquipe };
		})
		.catch(function (error) {
			validateRequestErrorStatus(error.response.status);
			const { success, message, errors } = error.response.data;
			return { success, message, errors };
		});

export const Atualizar = (equipe) =>
	RequestWebApi.put(`/${controller}/Atualizar`, equipe)
		.then(function (response) {
			const { success } = response.data;
			return { success };
		})
		.catch(function (error) {
			validateRequestErrorStatus(error.response.status);
			const { success, message, errors } = error.response.data;
			return { success, message, errors };
		});

export const Remover = (equipeId) =>
	RequestWebApi.delete(`/${controller}/Remover`, {
		params: { equipeId: equipeId }
	})
		.then(function (response) {
			const { success } = response.data;
			return { success };
		})
		.catch(function (error) {
			validateRequestErrorStatus(error.response.status);
			const { success, message, errors } = error.response.data;
			return { success, message, errors };
		});
