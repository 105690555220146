/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext } from "react";
import AppContext from "../../../../Store/AppContext";
import {
  Listar as ListarTarefasExecutar,
  Detalhes as DetalhesTarefasExecutar,
  Remover as RemoverTarefasExecutar,
  Incluir as IncluirTarefasExecutar,
  Atualizar as AtualizarTarefasExecutar,
} from "../../../../Services/TarefasExecutar";
import { useHistory } from "react-router-dom";
import { validarPermissoesPagina } from "../../../../Utils/validarPermissoesPagina";
import { ListarSimples as ListarUsuario } from "../../../../Services/Usuario";
export const TarefasExecutarContext = createContext();

const tituloTela = "Tarefas Executar";

const defaultData = {
  tarefasExecutarId: 0,
  usuarioIdSolicitante: 0,
  usuarioIdDono: 0,
  tarefasExecutarCodigoigo: "",
  vendaId: 0,
  tarefasExecutarTipo: "",
  tarefasExecutarStatusControle: "",
  tarefasExecutarDataHoraRecebido: "",
  tarefasExecutarDataHoraEnviado: "",
  itens: []
};

const searchData = {
  tarefasExecutarCodigo: "",
  tarefasExecutarTipo: "",
};

const columnsDefinition = [
  {
    text: "Id",
    dataField: "tarefasExecutarId",
    hidden: true,
  },
  {
    text: "Código",
    dataField: "tarefasExecutarCodigo",
    headerStyle: { width: "100px" },
    sort: true,
  },
  {
    text: "Tipo",
    dataField: "tarefasExecutarTipo",
    headerStyle: { width: "450px" },
    sort: true,
  },
];

function initialState() {
  return {
    searchData: searchData,
    resultSearch: [],
    columnsDefinition: columnsDefinition,
    data: defaultData,
    formDisabled: true,
    usuarios: []
  };
}

const TarefasExecutarProvider = ({ children }) => {
  const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(
    AppContext
  );
  const [values, setValues] = useState(initialState());
  let history = useHistory();

  async function loadDependences() {
    let usuarios = [];

    const [
      responseListarUsuaro,
      permissoesPaginaAux
    ] = await Promise.all([
      await ListarUsuario({}),
      validarPermissoesPagina()
    ]);

    if (!responseListarUsuaro.success) {
      showMessage("Erro", "Erro Inesperado ao carregar dados basicos.");
    } else {
      usuarios = await responseListarUsuaro.usuarios;
    }


    return { permissoesPagina: permissoesPaginaAux, usuarios };
  }

  const resetScreen = () => {
    showLoading();
    async function carregarDependencias() {
      const dependencias = await loadDependences();
      setTituloTela(tituloTela);
      setValues({
        ...initialState(),
        permissoesPagina: await dependencias.permissoesPagina,
        usuarios: await dependencias.usuarios,
      });
      hideLoading();
    }
    carregarDependencias();
  };


  const searchHandler = async () => {
    showLoading();
    const responseListar = await ListarTarefasExecutar(values.searchData);

    if (!responseListar.success) {
      showMessage("Erro", "Erro Inesperado durante a pesquisa.");
      hideLoading();
      return;
    }

    const resultSearchData = await responseListar.tarefasExecutarList;

    resultSearchData.map((data) => (data.Id = data.tarefasExecutarId));

    setValues({
      ...values,
      resultSearch: resultSearchData,
    });

    if (resultSearchData.length === 0) {
      showMessage("Alerta", "Nenhum registro encontrado.");
    }
    hideLoading();
  };

  const removeHandler = async (id) => {
    showLoading();

    const responseRemover = await RemoverTarefasExecutar(id);

    if (!responseRemover.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro durante a exclusão: ${responseRemover.message}`
      );
      responseRemover.errors.map((erro) =>
        showMessage("Erro", erro.errorMessage)
      );
      return;
    }
    searchHandler();
    hideLoading();
    showMessage("Sucesso", "Registro excluido.");
  };

  const getDetailHandler = async (id) => {
    showLoading();
    const dependencias = await loadDependences();

    if (id > 0) {
      const responseDetalhes = await DetalhesTarefasExecutar(id);

      const { success, tarefasExecutar } = await responseDetalhes;

      if (!success) {
        showMessage("Erro", "Erro ao buscar os detalhes do registro.");
        setValues({
          ...values,
          data: defaultData,
          permissoesPagina: await dependencias.permissoesPagina,
          usuarios: await dependencias.usuarios,
        });
      }

      setValues({
        ...values,
        data: tarefasExecutar,
        permissoesPagina: await dependencias.permissoesPagina,
        usuarios: await dependencias.usuarios,
      });
    } else {
      setValues({
        ...values,
        data: {
          ...defaultData,
          tarefasExecutarId: 0,
        },
        permissoesPagina: await dependencias.permissoesPagina,
        usuarios: await dependencias.usuarios,
        formDisabled: false,
      });
    }
    hideLoading();
  };

  const createData = async () => {
    showLoading();
    const responseIncluir = await IncluirTarefasExecutar(values.data);
    if (!responseIncluir.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao criar o registro: ${responseIncluir.message}`
      );
      responseIncluir.errors.map((erro) =>
        showMessage("Erro", erro.errorMessage)
      );
      return;
    }

    resetScreen();
    hideLoading();
    showMessage("Sucesso", "Registro criado.");
    history.goBack();
  };

  const editHandler = () => {
    setValues({
      ...values,
      formDisabled: false,
    });
  };

  const updateData = async () => {
    showLoading();
    const responseAtualizar = await AtualizarTarefasExecutar(values.data);
    if (!responseAtualizar.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao atualizar o registro: ${responseAtualizar.message}`
      );
      responseAtualizar.errors.map((erro) =>
        showMessage("Erro", erro.errorMessage)
      );
      return;
    }
    resetScreen();
    hideLoading();
    showMessage("Sucesso", "Registro atualizado.");
    history.goBack();
  };

  const saveHandler = async () => {
    if (values.data.tarefasExecutarId === 0) {
      await createData();
    } else {
      await updateData();
    }
  };

  const cancelHandler = () => {
    setValues({
      ...values,
      data: defaultData,
      formDisabled: true,
    });
  };

  return (
    <TarefasExecutarContext.Provider
      value={{
        values,
        setValues,
        resetScreen,
        searchHandler,
        cancelHandler,
        removeHandler,
        saveHandler,
        getDetailHandler,
        editHandler,
      }}
    >
      {children}
    </TarefasExecutarContext.Provider>
  );
};

export default TarefasExecutarProvider;
