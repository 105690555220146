/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext } from "react";
import AppContext from "../../../Store/AppContext";
import {
  Listar as ListarMotorEventoApi,
  ListarRotuloMotorEvento,
  AlterarStatusEventos,
} from "../../../Services/MotorEventos";
import { validarPermissoesPagina } from "../../../Utils/validarPermissoesPagina";
export const MotorEventosContext = createContext();

const tituloTela = "Motor Eventos";

const defaultData = {
  eventosId: [],
  status: 1,
};

const searchData = {
  motorEventoApiDirecao: "",
  motorEventoApiRotulo: "",
  motorEventoApiCodigo: "",
  motorEventoApiDataHoraInicio: "",
  motorEventoApiDataHoraFim: "",
  motorEventoApiStatus: 0,
};

function initialState() {
  return {
    searchData: searchData,
    resultSearch: [],
    data: defaultData,
    rotulos:[]
  };
}

const MotorEventosProvider = ({ children }) => {
  const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(
    AppContext
  );
  const [values, setValues] = useState(initialState());

  async function loadDependences() {
    let rotulos = [];
    const permissoesPagina = validarPermissoesPagina();
    const responseListarRotuloMotorEvento = await ListarRotuloMotorEvento();
    if (!responseListarRotuloMotorEvento.success) {
      showMessage("Erro", "Erro Inesperado ao carregar dados basicos.");
    } else {
      rotulos = await responseListarRotuloMotorEvento.rotulos;
    }

    return { permissoesPagina, rotulos };
  }

  const resetScreen = () => {
    showLoading();
    async function carregarDependencias() {
      const dependencias = await loadDependences();
      setTituloTela(tituloTela);
      setValues({
        ...initialState(),
        permissoesPagina: await dependencias.permissoesPagina,
        rotulos: await dependencias.rotulos,
      });
      hideLoading();
    }
    carregarDependencias();
  };

  const searchHandler = async () => {
    showLoading();
    const responseListar = await ListarMotorEventoApi(values.searchData);
    if (!responseListar.success) {
      showMessage("Erro", "Erro Inesperado durante a pesquisa.");
      hideLoading();
      return;
    }

    const resultSearchData = await responseListar.motorEventosApi;
    resultSearchData.map((data) => (data.Id = data.motorEventoApiId));

    setValues({
      ...values,
      data: { ...defaultData, eventosId: [] },
      resultSearch: resultSearchData,
    });

    if (resultSearchData.length === 0) {
      showMessage("Alerta", "Nenhum registro encontrado.");
    }
    hideLoading();
  };

  const resetEventHandler = async () => {
    showLoading();
    const responseAtualizar = await AlterarStatusEventos(values.data);
    if (!responseAtualizar.success) {
      hideLoading();
      showMessage("Erro", `Erro na execução: ${responseAtualizar.message}`);
      responseAtualizar.errors.map((erro) =>
        showMessage("Erro", erro.errorMessage)
      );
      return;
    }
    searchHandler();
    hideLoading();
    showMessage("Sucesso", "Eventos Enviados Para Reprocessamento.");
  };

  return (
    <MotorEventosContext.Provider
      value={{
        values,
        setValues,
        resetScreen,
        searchHandler,
        resetEventHandler,
      }}
    >
      {children}
    </MotorEventosContext.Provider>
  );
};

export default MotorEventosProvider;
