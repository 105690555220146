import React, { useEffect, useState } from 'react';
import './card.css';
import './PagamentoStyleDefault.css';
import Pagamento from './Pagamento';
import { LoadMask } from '../../Utils/mask';

const PagamentoCartaoIndex = () => {
	const [ pagamentoCartaoValues, SetPagamentoCartaoValues ] = useState(null);
	useEffect(() => {
		LoadMask();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagamentoCartaoValues]);

	const pagamentoHandler = (component) => {
		SetPagamentoCartaoValues(component);
	};

	return (
		<div className="pagePagamentoCartao_body">
			{!pagamentoCartaoValues ? <Pagamento pagamentoHandler={pagamentoHandler} /> : pagamentoCartaoValues}
		</div>
	);
};

export default PagamentoCartaoIndex;
