import React, { useEffect, useRef, useState, useContext, Fragment } from "react";
import "./Index.css";
import AppContext from "../../Store/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Login } from "../../Services/Autenticacao";
import { RedefinirSenha } from "../../Services/Usuario";
import { getToken } from "../../Services/Requests";
import peopleImg from "../../assets/img/Login/people.png";
import logoImg from "../../assets/img/Login/logo.png";


function initialState() {
  return {
    usuarioId: 0,
    usuarioLogin: "",
    senha: "",
    novaSenha: "",
    repetirNovaSenha: "",
    mostrarSenha: false,
    redefinirSenha: false,
  };
}

let tokenToRequest = "";

const PageLoginIndex = (props) => {
  const [valuesLogin, setValuesLogin] = useState(initialState);

  const usuarioLoginRef = useRef(null);
  useEffect(() => {
    usuarioLoginRef.current.focus();
  }, [])
  const {
    setToken,
    setSession,
    showLoading,
    hideLoading,
    showMessage,
  } = useContext(AppContext);

  const formularioComErro =
    !valuesLogin.usuarioLogin ||
    !valuesLogin.senha ||
    (valuesLogin.redefinirSenha &&
      (!valuesLogin.novaSenha ||
        !valuesLogin.repetirNovaSenha ||
        valuesLogin.novaSenha !== valuesLogin.repetirNovaSenha));

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValuesLogin({
      ...valuesLogin,
      [name]: value,
    });
  }


  function onPassWordEnter(event) {

    if (!formularioComErro && event.key === 'Enter') {
      loginHandler();
    }
  }

  const loginHandler = async () => {
    localStorage.clear();
    showLoading("Login");

    const responseLogin = await Login(
      valuesLogin.usuarioLogin,
      valuesLogin.senha
    );

    if (!responseLogin.success) {
      hideLoading();
      setValuesLogin({
        ...valuesLogin,
        senha: "",
      });
      showMessage("Erro", `Erro ao realizar login : ${responseLogin.message}`);
      responseLogin.errors.map((erro) =>
        showMessage("Erro", erro.errorMessage)
      );
      return;
    }
    tokenToRequest = responseLogin.token.token;
    setToken(responseLogin.token);
    setSession(responseLogin.usuario);

    if (!getToken()) {
      showMessage(
        "erro",
        "Não foi possível salvar os dados de sessão, verifique as permissões em seu navegador."
      );
      hideLoading();
      return;
    }

    hideLoading();

    if (responseLogin.usuario.redefinirSenha) {
      setValuesLogin({
        ...valuesLogin,
        usuarioId: responseLogin.usuario.usuarioId,
        redefinirSenha: responseLogin.usuario.redefinirSenha,
      });
      return;
    }

    setValuesLogin(initialState);
    showMessage("sucesso", "Bem Vindo.");
    window.location.replace("/Modulos");
    return;
  };

  const redefinirSenhaHandler = async () => {
    showLoading();

    if (!tokenToRequest) {
      showMessage("Erro", "Não foi Possível obter o token!");
      return;
    }

    const responseRedefinirSenha = await RedefinirSenha(
      valuesLogin.usuarioId,
      valuesLogin.senha,
      valuesLogin.novaSenha,
      tokenToRequest
    );

    if (!responseRedefinirSenha.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao redefinir senha: ${responseRedefinirSenha.message
          ? responseRedefinirSenha.message
          : "Indefinido"
        }`
      );
      if (responseRedefinirSenha.errors) {
        responseRedefinirSenha.errors.map((erro) =>
          showMessage("Erro", erro.errorMessage)
        );
      }
      setValuesLogin(initialState);
      return;
    }
    hideLoading();
    setValuesLogin(initialState);
    showMessage("sucesso", "Bem Vindo.");
    window.location.replace("/Modulos");
    return;
  };

  function exibirSenha(target) {
    setValuesLogin({
      ...valuesLogin,
      [`mostrar${target}`]: !valuesLogin[`mostrar${target}`],
    });
  }

  return (
    <section className="loginPageBody">
      <div className="loginPageLogo">
        <img src={logoImg} alt="" height="50" />
      </div>

      <div className="loginPageContainerLogin">
        <div className="loginPageHeroText">
          <h1>Seja bem-vindo</h1>
          <h2>à nossa plataforma digital</h2>
        </div>
        <div className={valuesLogin.redefinirSenha ? "loginPageLupaRedifinicaoSenha" : "loginPageLupa"}></div>
        <div className="loginPageHeroPosition">
        </div>
        <div className="loginPageLoginCard">
          <div className="loginPageImgLogin">
            <img src={peopleImg} alt="" />
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="inpLogin">Login</label>
                <input
                  type="text"
                  className="form-control"
                  id="inpLogin"
                  name="usuarioLogin"
                  onChange={onChangeInput}
                  value={valuesLogin.usuarioLogin}
                  ref={usuarioLoginRef}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group view-password">
                <label htmlFor="inpSenha">Senha</label>
                <div className="PageLogin_ContainerPasswordEye">
                  <input
                    type={valuesLogin.mostrarSenha ? "text" : "password"}
                    className="form-control input-password"
                    aria-label="password"
                    id="inpSenha"
                    name="senha"
                    onKeyPress={onPassWordEnter}
                    onChange={onChangeInput}
                    value={valuesLogin.senha}
                  />
                  <div
                    role="button"
                    className="PageLogin_ContainerIconView"
                    onClick={() => exibirSenha("Senha")}
                  >
                    <FontAwesomeIcon
                      icon={valuesLogin.mostrarSenha ? faEyeSlash : faEye}
                    />
                  </div>
                </div>
              </div>
            </div>

            {valuesLogin.redefinirSenha ? (
              <Fragment>
                <div className="col-12">
                  <div className="form-group view-password">
                    <label htmlFor="inpNovaSenha">Nova Senha</label>
                    <div className="PageLogin_ContainerPasswordEye">
                      <input
                        type={
                          valuesLogin.mostrarNovaSenha ? "text" : "password"
                        }
                        className="form-control input-password"
                        aria-label="password"
                        id="inpNovaSenha"
                        name="novaSenha"
                        onChange={onChangeInput}
                        value={valuesLogin.novaSenha}
                      />
                      <div
                        role="button"
                        className="PageLogin_ContainerIconView"
                        onClick={() => exibirSenha("NovaSenha")}
                      >
                        <FontAwesomeIcon
                          icon={
                            valuesLogin.mostrarNovaSenha
                              ? faEyeSlash
                              : faEye
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group view-password">
                    <label htmlFor="inpRepetirNovaSenha">
                      Repetir Nova Senha
                    </label>
                    <div className="PageLogin_ContainerPasswordEye">
                      <input
                        type={
                          valuesLogin.mostrarRepetirNovaSenha
                            ? "text"
                            : "password"
                        }
                        className="form-control input-password"
                        aria-label="password"
                        id="inpRepetirNovaSenha"
                        name="repetirNovaSenha"
                        onChange={onChangeInput}
                        value={valuesLogin.repetirNovaSenha}
                      />
                      <div
                        role="button"
                        className="PageLogin_ContainerIconView"
                        onClick={() => exibirSenha("RepetirNovaSenha")}
                      >
                        <FontAwesomeIcon
                          icon={
                            valuesLogin.mostrarRepetirNovaSenha
                              ? faEyeSlash
                              : faEye
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            ) : null}
          </div>
          <div className="row align-items-center mt-4">
            <div className="col-12 col-md-12">
              {valuesLogin.redefinirSenha ? (
                <button
                  disabled={formularioComErro}
                  type="button"
                  onClick={() => redefinirSenhaHandler()}
                  className="btn btn-default btn-block"
                >
                  Redefinir e Entrar
                </button>
              ) : (
                <button
                  disabled={formularioComErro}
                  type="button"
                  onClick={() => loginHandler()}
                  className="btn btn-default btn-block"
                >
                  Entrar
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageLoginIndex;
