import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRoute from "../../../../Routes/PrivateRoutes";
import LandingPageProvider from "./LandingPageContext";
import PesquisarLandingPage from "./Events/PesquisarLandingPage";
import FormularioLandingPage from "./Events/FormularioLandingPage";

const Index = () => {
  let match = useRouteMatch();
  return (
    <LandingPageProvider>
      <div className="container-fluid pl-3 pt-3 pb-3">
        <Switch>
          <PrivateRoute
            exact
            path={`${match.url}`}
            component={PesquisarLandingPage}
          />
          <PrivateRoute
            path={`${match.url}/:id`}
            component={FormularioLandingPage}
          />
        </Switch>
      </div>
    </LandingPageProvider>
  );
};

export default Index;
