/* eslint-disable array-callback-return */
import React, { Fragment, useContext } from "react";
import "./ListaPublico.css";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { removeItemFromArray } from "../../../Utils/globalFunctions";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

const columnsDefinition = [
  {
    text: "Id",
    dataField: "baseProspectItensId",
    hidden: true,
  },
  {
    text: "Nome",
    dataField: "baseProspectItensNome",
    headerStyle: { width: "130px" },
    filter: textFilter({placeholder: 'Nome'}),
    sort: true,
  },
  {
    text: "Email",
    dataField: "baseProspectItensEMail",
    headerStyle: { width: "100px" },
    filter: textFilter({placeholder: 'Email'}),
    sort: true,
  },
  {
    text: "Telefone",
    dataField: "baseProspectItensTelefone",
    headerStyle: { width: "60px" },
    filter: textFilter({placeholder: 'Telefone'}),
    sort: true,
  },
];

export const TabelaProspect = ({ context }) => {
  const { values, setValues } = useContext(context);

  const handleOnSelect = (row, isSelect) => {
    let baseProspectsItens = values.data.baseProspectsItens;

    if (isSelect) {
      baseProspectsItens.push(row.baseProspectItensId);
    } else {
      removeItemFromArray(
        baseProspectsItens,
        baseProspectsItens.find((item) => item === row.baseProspectItensId)
      );
    }

    setValues({
      ...values,
      data: {
        ...values.data,
        baseProspectsItens,
      },
    });
  };

  const handleOnSelectAll = (isSelect, rows) => {
    let baseProspectsItens = values.data.baseProspectsItens;
    rows.map((row) => {
      if (isSelect) {
        baseProspectsItens.push(row.baseProspectItensId);
      } else {
        removeItemFromArray(
          baseProspectsItens,
          baseProspectsItens.find((item) => item === row.baseProspectItensId)
        );
      }
    });

    setValues({
      ...values,
      data: {
        ...values.data,
        baseProspectsItens,
      },
    });
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    selected: values.data.baseProspectsItens,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    hideSelectColumn: values.formDisabled,
  };

  return (
    <Fragment>
      <div className="col-6">
        <div className="default-title">Prospects</div>
        <div className="card shadow-sm mb-4">
          <div className="row">
            <div className="col-12">
              <BootstrapTable
                bootstrap4={true}
                condensed={true}
                noDataIndication={() => "Nenhum Registro Selecionado"}
                striped={true}
                hover={true}
                keyField="baseProspectItensId"
                data={values.baseProspectsItens}
                columns={columnsDefinition}
                selectRow={selectRow}
                filter={filterFactory()}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TabelaProspect;
