import React, { Fragment } from "react";
import HistoricoLead from '../../Lead/HistoricoLead/HistoricoLead';
import DetalhesLead from '../../Lead/DetalhesLead/DetalhesLead';
import DadosUtimaContratacao from "../DadosUtimaContratacao/DadosUltimaContratacao";
import HistoricoAtendimento from "../HistoricoAtendimento/HistoricoAtendimento";

const InformacoesComplementares = ({
  totalContatos = "",
  dataRecebimentoLead = "",
  horaRecebimentoLead = "",
  dataVencimentoApolice = "",
  cidade = "",
  uf = "",
  temperaturaDescricao = "",
  historicoAtendimento = [],
  lead = null,
  atendimentoDadosUltimaContratacao = null,
}) => {
  return (
    <Fragment>
      <div className="default-title">Informações Complementares</div>
      <div className="card shadow-sm mb-4">
        <div className="row align-items-center">
          {dataRecebimentoLead || horaRecebimentoLead ? (
            <div className="col-md-5">
              <div className="font-weight-bold">
                Recebimento:{" "}
                <span className="font-weight-normal">
                  {dataRecebimentoLead} – {horaRecebimentoLead}
                </span>
              </div>
            </div>
          ) : null}

          {totalContatos && totalContatos !== 0 && totalContatos !== "0" ? (
            <div className="col-md-3">
              <div className="font-weight-bold">
                Nrs. contato:{" "}
                <span className="font-weight-normal">{totalContatos}</span>
              </div>
            </div>
          ) : null}

          {dataVencimentoApolice ? (
            <div className="col-md-4">
              <div className="font-weight-bold">
                Vencimento Apólice:{" "}
                <span className="font-weight-normal">
                  {dataVencimentoApolice}
                </span>
              </div>
            </div>
          ) : null}

          {cidade || uf ? (
            <div className="col-md-4">
              <div className="font-weight-bold">
                {cidade}/{uf}
              </div>
            </div>
          ) : null}

          {lead.leadDataHoraAgendaContato ? (
            <div className="col-md-12">
              <div className="font-weight-bold">
                Preferência de Data e Horario para Contato:{' '}
                <span className="font-weight-normal">{lead.leadDataHoraAgendaContato}</span>
              </div>
            </div>
          ) : null}

          {lead.leadPreferenciaContato ? (
            <Fragment>
              <div className="col-md-12">
                <div className="font-weight-bold">
                  Preferência de Contato:{" "}
                  <span className="font-weight-normal">{lead.leadPreferenciaContato}</span>
                </div>
              </div>

              {lead.leadObservacaoLead ? (
                <div className="col-md-12">
                  <div className="font-weight-bold">
                    Observação Lead:{" "}
                    <span className="font-weight-normal">{lead.leadObservacaoLead}</span>
                  </div>
                </div>
              ) : null}
            </Fragment>
          ) : null}

          <div className="col-12">
            <hr />
          </div>
          {lead.marcaVeiculo ? (
            <div className="col-md-4">
              <div className="font-weight-bold">
                Marca:{" "}
                <span className="font-weight-normal">{lead.marcaVeiculo}</span>
              </div>
            </div>
          ) : null}
          {lead.modeloVeiculo ? (
            <div className="col-md-8">
              <div className="font-weight-bold">
                Modelo:{" "}
                <span className="font-weight-normal">{lead.modeloVeiculo}</span>
              </div>
            </div>
          ) : null}
          {lead.placaVeiculo ? (
            <div className="col-md-4">
              <div className="font-weight-bold">
                Placa:{" "}
                <span className="font-weight-normal">{lead.placaVeiculo}</span>
              </div>
            </div>
          ) : null}

          {lead.anoFabricacaoVeiculo ? (
            <div className="col-md-4">
              <div className="font-weight-bold">
                Ano Fabricacao:{" "}
                <span className="font-weight-normal">
                  {lead.anoFabricacaoVeiculo}
                </span>
              </div>
            </div>
          ) : null}
          {lead.anoModeloVeiculo ? (
            <div className="col-md-4">
              <div className="font-weight-bold">
                Ano Modelo:{" "}
                <span className="font-weight-normal">
                  {lead.anoModeloVeiculo}
                </span>
              </div>
            </div>
          ) : null}
          <div className="col-12">
            <hr />
          </div>

          <div className="col-12 d-flex justify-content-center">

            {lead.leadIdRenovacao && lead.leadIdRenovacao > 0 ? (
              <DadosUtimaContratacao
                lead={lead}
                atendimentoDadosUltimaContratacao={
                  atendimentoDadosUltimaContratacao
                }
              />
            ) : null}

            {historicoAtendimento.length > 0 ? (
              <HistoricoAtendimento
                historicoAtendimento={historicoAtendimento}
                titulo={temperaturaDescricao}
              />) : null}

            {lead.leadId > 0 ? (
              <HistoricoLead leadId={lead.leadId} disabled={false} >
                <span className={`btn btn-info`} >
                  Histórico do Lead
                </span>
              </HistoricoLead>) : null}

            <DetalhesLead leadId={lead.leadId}>
              <span className={`btn btn-warning ml-3`} >
                Detalhes
              </span>
            </DetalhesLead>

          </div>

        </div>
      </div>
    </Fragment>
  );
};

export default InformacoesComplementares;
