import React, { Fragment, useEffect, useState } from 'react';
import './GraficoBarrasLinha.css';
import { Bar } from 'react-chartjs-2';

const optionsBar = {
	legend: {
		display: false
	}
};
const initialState = {
	legendas: [],
	dataChart: [ 0 ],
	legendasLinha: [],
	dataChartLinha: [ 0 ]
};
function getBarDataChartDefinition(serieList, dataList, dataListLinha = null) {
	const data = {
		labels: serieList,
		showLabel: false,
		datasets: [
			{
				type: 'line',
				fill: false,
				backgroundColor: 'rgba(33,150,243,1)',
				borderColor: 'rgba(33,150,243,1)',
				borderWidth: 2,
				hoverBackgroundColor: 'rgba(33,150,243,1)',
				data: dataListLinha
			},
			{
				type: 'bar',
				fill: false,
				backgroundColor: 'rgba(254,102,51,1)',
				borderWidth: 1,
				hoverBackgroundColor: 'rgba(254,102,51,1)',
				data: dataList
			}
		]
	};
	return data;
}
const datasetKeyProvider = () => {
	return Math.random();
};

const GraficoBarrasLinha = ({ legendas = [], dataChart = [ 0 ], dataChartLinha = [ 0 ], height = 80 }) => {
	const [ graficoBarrasLinhaValues, setGraficoBarrasLinhaValues ] = useState(initialState);
	useEffect(
		() => {
			if (dataChart.length > 0) {
				setGraficoBarrasLinhaValues({
					legendas: legendas,
					dataChart: dataChart,
					dataChartLinha: dataChartLinha
				});
			}
		},
		[ legendas, dataChart, dataChartLinha ]
	);
	return (
		<Fragment>
			<Bar
				data={getBarDataChartDefinition(
					graficoBarrasLinhaValues.legendas,
					graficoBarrasLinhaValues.dataChart,
					graficoBarrasLinhaValues.dataChartLinha
				)}
				datasetKeyProvider={datasetKeyProvider}
				options={optionsBar}
				height={height}
			/>
		</Fragment>
	);
};

export default GraficoBarrasLinha;
