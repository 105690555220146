import React, { useContext, Fragment, useEffect } from "react";
import { CobrancaContext } from "../CobrancaContext";
import Pesquisar from "../../../../Components/Cadastro/Pesquisar/Pesquisar";
import TabelaResultados from "../../../../Components/Cadastro/TabelaResultados/TabelaResultados";
import GroupBox from "../../../../Components/GroupBox/GroupBox";
import Help from "../../../../Components/Help/Help";

const PesquisarCobranca = () => {
  const { values, setValues, resetScreen } = useContext(CobrancaContext);

  useEffect(() => {
    resetScreen();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      searchData: {
        ...values.searchData,
        [name]: value,
      },
    });
  }

  return (
    <Fragment>
      <Pesquisar context={CobrancaContext}>
        <div className="col-12 col-md-2 form-group">
          <label>CPF/CNPJ:</label>
          <input
            className="form-control"
            type="text"
            name="leadNumDocumento"
            value={values.searchData.leadNumDocumento}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-4 form-group">
          <label>Nome</label>
          <input
            className="form-control"
            type="text"
            name="leadNome"
            value={values.searchData.leadNome}
            onChange={onChangeInput}
          />
        </div>

        <div className="col-12 col-md-3 form-group">
          <label>Seguradora</label>
          <select
            className="form-control"
            name="seguradoraId"
            value={values.searchData.seguradoraId}
            onChange={onChangeInput}
          >
            <option className="disabled" value="0"></option>
            {values.seguradoras.map((seguradora) => (
              <option
                key={seguradora.seguradoraId}
                value={seguradora.seguradoraId}
              >
                {seguradora.seguradoraDescricao}
              </option>
            ))}
          </select>
        </div>

        <div className="col-12 col-md-3 form-group">
          <label>Forma Pagamento</label>
          <select
            className="form-control"
            name="cobrancaFormaPagto"
            value={values.searchData.cobrancaFormaPagto}
            onChange={onChangeInput}
          >
            <option className="disabled" value=""></option>
            {values.formasPagamento.map((formaPagamento) => (
              <option key={formaPagamento.codigo} value={formaPagamento.codigo}>
                {formaPagamento.descricao}
              </option>
            ))}
          </select>
        </div>

        <div className="col-12 col-md-3 form-group">
          <label>Status</label>
          <select
            className="form-control"
            name="cobrancaStatus"
            value={values.searchData.cobrancaStatus}
            onChange={onChangeInput}
          >
            <option className="disabled" value=""></option>
            {values.statusCobranca.map((statusCobranca) => (
              <option key={statusCobranca.codigo} value={statusCobranca.codigo}>
                {statusCobranca.descricao}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-3">
          <div className="form-group">
            <Help identificadorHelp="ORIGEMLEAD">
              <label>
                Origem
              </label>
            </Help>
            <select
              className="form-control"
              name="origemLeadId"
              value={values.searchData.origemLeadId}
              onChange={onChangeInput}
            >
              <option className="disabled" value="0" />
              {values.origensLead.map((origem) => (
                <option key={origem.origemLeadId} value={origem.origemLeadId}>
                  {origem.origemLeadNome}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-12" />
        <GroupBox titulo="Data Cobrança" className="col-12 col-md-4 form-group">
          <div className="col-12 col-md-6 ">
            <label>De:</label>
            <input
              className="form-control"
              type="date"
              name="cobrancaDataHoraDe"
              value={values.searchData.cobrancaDataHoraDe}
              onChange={onChangeInput}
            />
          </div>
          <div className="col-12 col-md-6 ">
            <label>Até:</label>
            <input
              className="form-control"
              type="date"
              name="cobrancaDataHoraAte"
              value={values.searchData.cobrancaDataHoraAte}
              onChange={onChangeInput}
            />
          </div>
        </GroupBox>

        <GroupBox titulo="Data Repique" className="col-12 col-md-4 form-group">
          <div className="col-12 col-md-6 ">
            <label>De:</label>
            <input
              className="form-control"
              type="date"
              name="cobrancaDataVencRepiqueDe"
              value={values.searchData.cobrancaDataVencRepiqueDe}
              onChange={onChangeInput}
            />
          </div>
          <div className="col-12 col-md-6 ">
            <label>Até:</label>
            <input
              className="form-control"
              type="date"
              name="cobrancaDataVencRepiqueAte"
              value={values.searchData.cobrancaDataVencRepiqueAte}
              onChange={onChangeInput}
            />
          </div>
        </GroupBox>

        <GroupBox titulo="Data Cancelamento" className="col-12 col-md-4 form-group">
          <div className="col-12 col-md-6 ">
            <label>De:</label>
            <input
              className="form-control"
              type="date"
              name="cobrancaDataCancelamentoDe"
              value={values.searchData.cobrancaDataCancelamentoDe}
              onChange={onChangeInput}
            />
          </div>
          <div className="col-12 col-md-6 ">
            <label>Até:</label>
            <input
              className="form-control"
              type="date"
              name="cobrancaDataCancelamentoAte"
              value={values.searchData.cobrancaDataCancelamentoAte}
              onChange={onChangeInput}
            />
          </div>
        </GroupBox>
      </Pesquisar>
      <TabelaResultados context={CobrancaContext} />
    </Fragment>
  );
};

export default PesquisarCobranca;
