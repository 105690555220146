/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext } from "react";
import AppContext from "../../../../Store/AppContext";
import {
  Listar as ListarMotivoPerda,
  Detalhes as DetalhesMotivoPerda,
  Remover as RemoverMotivoPerda,
  Incluir as IncluirMotivoPerda,
  Atualizar as AtualizarMotivoPerda,
} from "../../../../Services/MotivoPerda";
import { useHistory } from "react-router-dom";
import { validarPermissoesPagina } from "../../../../Utils/validarPermissoesPagina";
export const MotivoPerdaContext = createContext();

const tituloTela = "Motivo Perda";

const defaultData = {
  motivoPerdaId: 0,
  motivoPerdaDescricao: "",
  motivoPerdaStatus: "",
  motivoPerdaSolitarVencimentoApolice: false
};

const searchData = {
  motivoPerdaDescricao: "",
};

const columnsDefinition = [
  {
    text: "Id",
    dataField: "motivoPerdaId",
    hidden: true,
  },
  {
    text: "Descrição",
    dataField: "motivoPerdaDescricao",
    headerStyle: { width: "450px" },
    sort: true,
  },
  {
    text: "Solic. Venc. Apólice",
    dataField: "motivoPerdaSolitarVencimentoApolice",
    headerStyle: { width: "150px" },
    sort: true,
    formatter: (cell, row) => {
      return cell ? (
        <span className="badge badge-success">Sim</span>
      ) : (
        <span className="badge badge-danger">Não</span>
      );
    },
  },
  {
    text: "Status",
    dataField: "motivoPerdaStatus",
    headerStyle: { width: "80px" },
    sort: true,
    formatter: (cell, row) => {
      return cell === "A" ? (
        <span className="badge badge-success">Ativo</span>
      ) : (
        <span className="badge badge-danger">Inátivo</span>
      );
    },
  },
];

function initialState() {
  return {
    searchData: searchData,
    resultSearch: [],
    columnsDefinition: columnsDefinition,
    data: defaultData,
    formDisabled: true,
  };
}

const MotivoPerdaProvider = ({ children }) => {
  const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(
    AppContext
  );
  const [values, setValues] = useState(initialState());
  let history = useHistory();

  async function loadDependences() {
    const permissoesPagina = validarPermissoesPagina();
    return { permissoesPagina };
  }

  const resetScreen = () => {
    showLoading();
    async function carregarDependencias() {
      const dependencias = await loadDependences();
      setTituloTela(tituloTela);
      setValues({
        ...initialState(),
        permissoesPagina: await dependencias.permissoesPagina,
      });
      hideLoading();
    }
    carregarDependencias();
  };

  const searchHandler = async () => {
    showLoading();
    const responseListarMotivoPerda = await ListarMotivoPerda(
      values.searchData
    );

    if (!responseListarMotivoPerda.success) {
      showMessage("Erro", "Erro Inesperado durante a pesquisa.");
      hideLoading();
      return;
    }

    const resultSearchData = await responseListarMotivoPerda.motivosPerda;

    resultSearchData.map((data) => (data.Id = data.motivoPerdaId));

    setValues({
      ...values,
      resultSearch: resultSearchData,
    });

    if (resultSearchData.length === 0) {
      showMessage("Alerta", "Nenhum registro encontrado.");
    }
    hideLoading();
  };

  const removeHandler = async (id) => {
    showLoading();

    const responseRemover = await RemoverMotivoPerda(id);

    if (!responseRemover.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro durante a exclusão: ${responseRemover.message}`
      );
      return;
    }
    searchHandler();
    hideLoading();
    showMessage("Sucesso", "Registro excluido.");
  };

  const getDetailHandler = async (id) => {
    showLoading();
    const dependencias = await loadDependences();

    if (id > 0) {
      const responseDetalhes = await DetalhesMotivoPerda(id);

      const { success, motivoPerda } = await responseDetalhes;

      if (!success) {
        showMessage("Erro", "Erro ao buscar os detalhes do registro.");
        setValues({
          ...values,
          data: defaultData,
          permissoesPagina: await dependencias.permissoesPagina,
        });
      }

      setValues({
        ...values,
        data: motivoPerda,
        permissoesPagina: await dependencias.permissoesPagina,
      });
    } else {
      setValues({
        ...values,
        data: {
          ...defaultData,
          motivoPerdaId: 0,
        },
        permissoesPagina: await dependencias.permissoesPagina,
        formDisabled: false,
      });
    }
    hideLoading();
  };

  const createData = async () => {
    showLoading();
    const responseIncluir = await IncluirMotivoPerda(values.data);
    if (!responseIncluir.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao criar o registro: ${responseIncluir.message}`
      );
      responseIncluir.errors.map((erro) =>
        showMessage("Erro", erro.errorMessage)
      );
      return;
    }

    resetScreen();
    hideLoading();
    showMessage("Sucesso", "Registro criado.");
    history.goBack();
  };

  const editHandler = () => {
    setValues({
      ...values,
      formDisabled: false,
    });
  };

  const updateData = async () => {
    showLoading();
    const responseAtualizar = await AtualizarMotivoPerda(values.data);
    if (!responseAtualizar.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao atualizar o registro: ${responseAtualizar.message}`
      );
      return;
    }
    resetScreen();
    hideLoading();
    showMessage("Sucesso", "Registro atualizado.");
    history.goBack();
  };

  const saveHandler = async () => {
    if (values.data.motivoPerdaId === 0) {
      await createData();
    } else {
      await updateData();
    }
  };

  const cancelHandler = () => {
    setValues({
      ...values,
      data: defaultData,
      formDisabled: true,
    });
  };

  return (
    <MotivoPerdaContext.Provider
      value={{
        values,
        setValues,
        resetScreen,
        searchHandler,
        cancelHandler,
        removeHandler,
        saveHandler,
        getDetailHandler,
        editHandler,
      }}
    >
      {children}
    </MotivoPerdaContext.Provider>
  );
};

export default MotivoPerdaProvider;
