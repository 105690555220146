import React, { Fragment } from 'react';

const FormularioLeadAuto = ({ produto, leadAuto }) => {
	return (
		<Fragment>
			{leadAuto && (produto.produtoFormAuxLead === 'FORMLEADAUTO' || produto.produtoFormAuxLead === 'FORMAUTORASTREADOR') ? (
				<Fragment>
					<div className="col-12 col-md-2">
						<div className="form-group">
							<label>Placa</label>
							<input
								disabled
								type="text"
								className="form-control"
								name="leadAutoPlaca"
								defaultValue={leadAuto.leadAutoPlaca}
							/>
						</div>
					</div>
					<div className="col-12 col-md-3">
						<div className="form-group">
							<label>Veículo Marca</label>
							<input
								disabled
								type="text"
								className="form-control"
								name="leadAutoFabricante"
								defaultValue={leadAuto.leadAutoFabricante}
							/>
						</div>
					</div>
					<div className="col-12 col-md-3">
						<div className="form-group">
							<label>Veículo Modelo</label>
							<input
								disabled
								type="text"
								className="form-control"
								name="leadAutoModelo"
								defaultValue={leadAuto.leadAutoModelo}
							/>
						</div>
					</div>
					<div className="col-12 col-md-2">
						<div className="form-group">
							<label>Ano Fabric.</label>
							<input
								disabled
								type="text"
								className="form-control "
								maxLength="4"
								name="leadAutoAnoFabricacao"
								defaultValue={leadAuto.leadAutoAnoFabricacao}
							/>
						</div>
					</div>
					<div className="col-12 col-md-2">
						<div className="form-group">
							<label>Ano Modelo</label>
							<input
								disabled
								type="text"
								className="form-control "
								maxLength="4"
								name="leadAutoAnoModelo"
								defaultValue={leadAuto.leadAutoAnoModelo}
							/>
						</div>
					</div>
				</Fragment>
			) : null}
		</Fragment>
	);
};

export default FormularioLeadAuto;
