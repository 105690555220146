import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "Lead";
export const Listar = (pesquisa) =>
  RequestWebApi.get(`/${controller}/Listar`, {
    params: { ...pesquisa },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const leads = resultData;
      return { success, leads };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { leads: [] };
    });

export const ListarLeadAutoNaoAtribuido = (pesquisa) =>
  RequestWebApi.post(`/${controller}/ListarLeadAutoNaoAtribuido`, {
    ...pesquisa,
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const leadsAutoNaoAtribuido = resultData;
      return { success, leadsAutoNaoAtribuido };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, leadsAutoNaoAtribuido: [] };
    });

export const ListarClassificacaoLead = () =>
  RequestWebApi.get(`/${controller}/ListarClassificacaoLead`)
    .then(function (response) {
      const { resultData, success } = response.data;
      const classificacoes = resultData;
      return { success, classificacoes };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { classificacoes: [] };
    });

export const AtribuirLead = (atribuirLead) =>
  RequestWebApi.post(`/${controller}/AtribuirLead`, atribuirLead)
    .then(function (response) {
      const { resultData, success } = response.data;
      const sucesso = resultData;
      return { success, sucesso };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const IncluirLeadAuto = (leadAuto) =>
  RequestWebApi.post(`/${controller}/IncluirLeadAuto`, leadAuto)
    .then(function (response) {
      const { resultData, success } = response.data;
      const leadId = resultData;
      return { success, leadId };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const IncluirLead = (leadData) =>
  RequestWebApi.post(`/${controller}/IncluirLead`, leadData)
    .then(function (response) {
      const { resultData, success } = response.data;
      const leadId = resultData;
      return { success, leadId };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });


export const IncluirLeadInterno = () =>
  RequestWebApi.post(`/${controller}/IncluirLeadInterno`, {})
    .then(function (response) {
      const { resultData, success } = response.data;
      const leadId = resultData;
      return { success, leadId };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const ImportarLeads = (arquivo) => {
  let formFile = new FormData();
  formFile.append("arquivo", arquivo);
  let result = RequestWebApi.post(`/${controller}/ImportarLeads`, formFile)
    .then(function (response) {
      const { resultData, success } = response.data;
      const resultadosImportacao = resultData;
      return { success, resultadosImportacao };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, resultadosImportacao: [] };
    });
  return result;
};

export const EnriquecerLeadManual = (leadId) =>
  RequestWebApi.post(`/${controller}/EnriquecerLeadManual`, { leadId: leadId })
    .then(function (response) {
      const { resultData, success } = response.data;
      const data = resultData;
      return { success, data };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, data: false };
    });

export const DefinirPermissaoDeContato = (contato, permissao) =>
  RequestWebApi.post(`/${controller}/DefinirPermissaoDeContato`, { contato, permissao })
    .then(function (response) {
      const { resultData, success } = response.data;
      const data = resultData;
      return { success, data };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, data: false };
    });

export const ListarArquivosLead = (leadId) =>
  RequestWebApi.get(`/${controller}/ListarArquivosLead`, {
    params: { leadId: leadId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const arquivos = resultData;
      return { success, arquivos };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, arquivos: [] };
    });

export const UploadAnexosLead = (leadId, nome, arquivo) => {
  let formFile = new FormData();
  formFile.append(`LeadId`, leadId);
  formFile.append(`Nome`, nome);
  formFile.append(`Arquivo`, arquivo);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  let result = RequestWebApi.post(
    `/${controller}/UploadAnexosLead`,
    formFile,
    config
  )
    .then(function (response) {
      const { resultData, success } = response.data;
      const arquivos = resultData;
      return { success, arquivos };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, arquivos: [] };
    });
  return result;
};

export const RemoverAnexosLead = (leadId, arquivoId) =>
  RequestWebApi.delete(`/${controller}/RemoverAnexosLead`, {
    params: { leadId: leadId, arquivoId: arquivoId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const arquivos = resultData;
      return { success, arquivos };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { arquivos: [] };
    });


export const ExibirHistoricoLead = (leadId) =>
  RequestWebApi.get(`/${controller}/ExibirHistoricoLead`, {
    params: { leadId: leadId }
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const historicoLead = resultData;
      return { success, historicoLead };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, historicoLead: null };
    });

export const ExibirHistoricoAtendimentoLead = (leadId) =>
  RequestWebApi.get(`/${controller}/ExibirHistoricoAtendimentoLead`, {
    params: { leadId: leadId }
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const historicoAtendimentoLead = resultData;
      return { success, historicoAtendimentoLead };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, historicoAtendimentoLead: null };
    });

export const ExibirHistoricoVendasLead = (leadId) =>
  RequestWebApi.get(`/${controller}/ExibirHistoricoVendasLead`, {
    params: { leadId: leadId }
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const historicoVendasLead = resultData;
      return { success, historicoVendasLead };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, historicoVendasLead: null };
    });

export const ExibirHistoricoBackOfficesLead = (leadId) =>
  RequestWebApi.get(`/${controller}/ExibirHistoricoBackOfficesLead`, {
    params: { leadId: leadId }
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const historicoBackOfficesLead = resultData;
      return { success, historicoBackOfficesLead };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, historicoBackOfficesLead: null };
    });

export const ExibirHistoricoCobrancasLead = (leadId) =>
  RequestWebApi.get(`/${controller}/ExibirHistoricoCobrancasLead`, {
    params: { leadId: leadId }
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const historicoCobrancasLead = resultData;
      return { success, historicoCobrancasLead };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, historicoCobrancasLead: null };
    });

export const ExibirHistoricoEndossosLead = (leadId) =>
  RequestWebApi.get(`/${controller}/ExibirHistoricoEndossosLead`, {
    params: { leadId: leadId }
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const historicoEndossosLead = resultData;
      return { success, historicoEndossosLead };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, historicoEndossosLead: null };
    });

export const ExibirHistoricoSinistrosLead = (leadId) =>
  RequestWebApi.get(`/${controller}/ExibirHistoricoSinistrosLead`, {
    params: { leadId: leadId }
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const historicoSinistrosLead = resultData;
      return { success, historicoSinistrosLead };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, historicoSinistrosLead: null };
    });

export const ExibirHistoricoApolicesLead = (leadId) =>
  RequestWebApi.get(`/${controller}/ExibirHistoricoApolicesLead`, {
    params: { leadId: leadId }
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const historicoApolicesLead = resultData;
      return { success, historicoApolicesLead };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, historicoApolicesLead: null };
    });


export const ObterDetalhesLead = (leadId) =>
  RequestWebApi.get(`/${controller}/ObterDetalhesLead`, {
    params: { leadId: leadId }
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const data = resultData;
      return { success, data };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, data: null };
    });