import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "MotivoPerda";
export const Listar = (motivoPerda) =>
  RequestWebApi.get(`/${controller}/Listar`, {
    params: { ...motivoPerda },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const motivosPerda = resultData;
      return { success, motivosPerda };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { motivosPerda: [] };
    });

export const Detalhes = (motivoPerdaId) =>
  RequestWebApi.get(`/${controller}/Detalhes`, {
    params: { motivoPerdaId: motivoPerdaId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const motivoPerda = resultData;
      return { success, motivoPerda };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, motivoPerda: {} };
    });

export const Incluir = (motivoPerda) =>
  RequestWebApi.post(`/${controller}/Incluir`, motivoPerda)
    .then(function (response) {
      const { resultData, success } = response.data;
      const novoProduto = resultData;
      return { success, novoProduto };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Atualizar = (motivoPerda) =>
  RequestWebApi.put(`/${controller}/Atualizar`, motivoPerda)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (motivoPerdaId) =>
  RequestWebApi.delete(`/${controller}/Remover`, {
    params: { motivoPerdaId: motivoPerdaId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });
