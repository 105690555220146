export const retornarUnicos = (value, index, self) => {
	return self.indexOf(value) === index;
};

export function convertFileParaBase(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});
}

export const removeItemFromArray = (array, item) => {
	for (var i = array.length; i--;) {
		if (array[i] === item) {
			array.splice(i, 1);
		}
	}
};

export const formatarMoeda = (valor) => {
	var formatter = new Intl.NumberFormat('pt-BR', {
		style: 'currency',
		currency: 'BRL'
	});
	return formatter.format(valor);
};

export const calcularValorPercentual = (valorBase, percentual) => {
	let valorBaseValue = parseFloat(valorBase);
	let percentualValue = parseFloat(percentual);
	return valorBaseValue / 100 * percentualValue;
};

export const retornaDescricaoTipoAtendimento = (tipoAtendimento) => {
	if (tipoAtendimento === 'C') {
		return 'Contato';
	} else if (tipoAtendimento === 'N') {
		return 'Negociação';
	}
	return 'Novo';
};

export const formatarDataParaInput = (data) => {
	return data.split('/').reverse().join('-');
};

export const sleep = (ms) => {
	return new Promise((resolve) => setTimeout(resolve, ms));
};

export const copyToClipboard = (text) => {
	const el = document.createElement('textarea');
	el.value = text;
	document.body.appendChild(el);
	el.select();
	let result = document.execCommand('copy');
	document.body.removeChild(el);
	return result;
};

export const capitalizeFirstLetter = (text) => {
	return text.charAt(0).toUpperCase() + text.slice(1);
};

export const validarCpf = (value) => {
	if (value == null) return false;
	var cpf = value.replace(/[.-]/g, '');
	var soma;
	var resto;
	soma = 0;
	if (cpf === '00000000000') return false;

	for (var i = 1; i <= 9; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
	resto = (soma * 10) % 11;

	if (resto === 10 || resto === 11) resto = 0;
	if (resto !== parseInt(cpf.substring(9, 10))) return false;

	soma = 0;
	for (var i2 = 1; i2 <= 10; i2++) soma = soma + parseInt(cpf.substring(i2 - 1, i2)) * (12 - i2);
	resto = (soma * 10) % 11;

	if (resto === 10 || resto === 11) resto = 0;
	if (resto !== parseInt(cpf.substring(10, 11))) return false;
	return true;
};

export const validarCnpj = (cnpj) => {
	if (!cnpj) return false
	cnpj = cnpj.replace(/[^\d]+/g, '');

	if (cnpj == '') return false;

	if (cnpj.length != 14)
		return false;

	// Elimina CNPJs invalidos conhecidos
	if (cnpj == "00000000000000" ||
		cnpj == "11111111111111" ||
		cnpj == "22222222222222" ||
		cnpj == "33333333333333" ||
		cnpj == "44444444444444" ||
		cnpj == "55555555555555" ||
		cnpj == "66666666666666" ||
		cnpj == "77777777777777" ||
		cnpj == "88888888888888" ||
		cnpj == "99999999999999")
		return false;

	// Valida DVs
	let tamanho = cnpj.length - 2
	let numeros = cnpj.substring(0, tamanho);
	let digitos = cnpj.substring(tamanho);
	let soma = 0;
	let pos = tamanho - 7;
	for (let i = tamanho; i >= 1; i--) {
		soma += numeros.charAt(tamanho - i) * pos--;
		if (pos < 2)
			pos = 9;
	}
	let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
	if (resultado != digitos.charAt(0))
		return false;

	tamanho = tamanho + 1;
	numeros = cnpj.substring(0, tamanho);
	soma = 0;
	pos = tamanho - 7;
	for (let i = tamanho; i >= 1; i--) {
		soma += numeros.charAt(tamanho - i) * pos--;
		if (pos < 2)
			pos = 9;
	}
	resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
	if (resultado != digitos.charAt(1))
		return false;

	return true;
};
