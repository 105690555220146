import React,{useEffect} from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRoute from "../../../../Routes/PrivateRoutes";
import NegociosFechadosProvider from "./NegociosFechadosContext";
import PesquisarNegociosFechados from "./Events/PesquisarNegociosFechados";
import { LoadMask } from "../../../../Utils/mask";

const Index = () => {
  let match = useRouteMatch();
  useEffect (() => {
    LoadMask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <NegociosFechadosProvider>
      <div className="container-fluid pl-3 pt-3 pb-3">
        <Switch>
          <PrivateRoute
            exact
            path={`${match.url}`}
            component={PesquisarNegociosFechados}
          />
        </Switch>
      </div>
    </NegociosFechadosProvider>
  );
};

export default Index;
