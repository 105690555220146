import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRoute from "../../../../Routes/PrivateRoutes";
import ProdutoProvider from "./ProdutoContext";
import PesquisarProduto from "./Events/PesquisarProduto";
import FormularioProduto from "./Events/FormularioProduto";

const Index = () => {
  let match = useRouteMatch();
  return (
    <ProdutoProvider>
      <div className="container-fluid pl-3 pt-3 pb-3">
        <Switch>
          <PrivateRoute
            exact
            path={`${match.url}`}
            component={PesquisarProduto}
          />
          <PrivateRoute
            path={`${match.url}/:id`}
            component={FormularioProduto}
          />
        </Switch>
      </div>
    </ProdutoProvider>
  );
};

export default Index;
