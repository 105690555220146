import React, { useContext, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import $ from "jquery";
import { BaseProspectContext } from "../BaseProspectContext";
import Formulario from "../../../../../Components/Cadastro/Formulario/Formulario";
import FormularioComplementar from "../../../../../Components/Cadastro/FormularioComplementar/FormularioComplementar";
import ResultadoImportacao from "../../../../../Components/Lead/ResultadoImportacao/ResultadoImportacao";
import TabelaResultado from "../../../../../Components/Lead/TabelaResultado/TabelaResultado";
const FormularioBaseProspect = () => {
  $(".custom-file-input").on("change", function () {
    var fileName = $(this).val().split("\\").pop();
    $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
  });

  const {
    values,
    setValues,
    getDetailHandler,
    downloadTemplate,
    downloadArquivoImportado,
  } = useContext(BaseProspectContext);
  let { id } = useParams();

  useEffect(() => {
    getDetailHandler(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      data: {
        ...values.data,
        [name]: value,
      },
    });
  }

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setValues({
        ...values,
        data: {
          ...values.data,
          baseProspectArquivo: undefined,
        },
      });
      return;
    }

    const selectedFile = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(selectedFile);

    reader.onload = function () {
      const baseProspectArquivo = reader.result;
      setValues({
        ...values,
        data: {
          ...values.data,
          baseProspectArquivo: baseProspectArquivo,
        },
      });
      return;
    };
    reader.onerror = function (error) {
      setValues({
        ...values,
        data: {
          ...values.data,
          baseProspectArquivo: undefined,
        },
      });
      return;
    };
  };

  return (
    <Fragment>
      <Formulario context={BaseProspectContext}>
        <div className="col-12 col-md-6 form-group">
          <label>Nome da Base<span className="campoObratorio">*</span></label>
          <input
            disabled={values.formDisabled}
            className="form-control"
            type="text"
            maxLength="50"
            name="baseProspectNome"
            value={values.data.baseProspectNome}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-3 form-group">
          <label>Produto</label>
          <select
            disabled={values.formDisabled}
            className="form-control"
            name="produtoId"
            value={values.data.produtoId}
            onChange={onChangeInput}
          >
            <option className="disabled" value="0"></option>
            {values.produtos.map((produto) => (
              <option key={produto.produtoId} value={produto.produtoId}>
                {produto.produtoNome}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-3 form-group">
          <label>Status<span className="campoObratorio">*</span></label>
          <select
            disabled={values.formDisabled}
            className="form-control"
            name="baseProspectStatus"
            value={values.data.baseProspectStatus}
            onChange={onChangeInput}
          >
            <option className="disabled" value=""></option>
            {values.statusList.map((status) => (
              <option key={status.key} value={status.key}>
                {status.value}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-12 form-group">
          <label>Descrição</label>
          <textarea
            disabled={values.formDisabled}
            rows="4"
            className="form-control"
            name="baseProspectDescr"
            value={values.data.baseProspectDescr}
            onChange={onChangeInput}
          ></textarea>
        </div>
        {values.permitirImportacao ? (
          <Fragment>
            <div className="col-12 col-md-7  form-group">
              <label>Escolher arquivo<span className="campoObratorio">*</span></label>
              <div className="custom-file">
                <input
                  type="file"
                  className="custom-file-input"
                  id="customFile"
                  name="arquivo"
                  accept=".xlsx , .xls "
                  onChange={onSelectFile}
                />
                <label
                  className="custom-file-label"
                  htmlFor="customFile"
                ></label>
              </div>
            </div>
            <div className="col-1 col-md-3 pt-4 form-group">
              <button
                type="button"
                onClick={downloadTemplate}
                className="btn btn-info"
              >
                Download de Layout
              </button>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="col-12 col-md-5 pt-4 form-group">
              <label>Usuário : {values.usuarioImportacao}</label>
            </div>
            <div className="col-12 col-md-4 pt-4 form-group">
              <label>
                Data de Importação : {values.data.baseProspectDataHora}
              </label>
            </div>
            <div className="col-1 col-md-3 pt-4 form-group">
              <button
                type="button"
                onClick={downloadArquivoImportado}
                className="btn btn-info"
              >
                Download Arquivo Importado
              </button>
            </div>
          </Fragment>
        )}
      </Formulario>

      {values.permitirImportacao ? (
        <FormularioComplementar titulo="Resultado da Importação">
          <div className="col-12 col-md-9 pt-3">
            <div className="card shadow-sm mb-4">
              <div className="row">
                <TabelaResultado
                  exibirCodigo={false}
                  resultadosImportacao={values.errosImportacao}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 pt-3">
            <div className="card shadow-sm mb-4">
              <div className="row">
                <ResultadoImportacao
                  tipoImportacao="Bases"
                  totalImportados={values.totalImportados}
                  totalSucesso={values.totalSucesso}
                  totalErro={values.totalErro}
                />
              </div>
            </div>
          </div>
        </FormularioComplementar>
      ) : null}
    </Fragment>
  );
};

export default FormularioBaseProspect;
