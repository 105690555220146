import React, { useEffect } from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRoute from "../../../../Routes/PrivateRoutes";
import PreLeadCotacaoProvider from "./PreLeadCotacaoContext";
import PesquisarPreLeadCotacao from "./Events/PesquisarPreLeadCotacao";
import FormularioPreLeadCotacao from "./Events/FormularioPreLeadCotacao";
import { LoadMask } from "../../../../Utils/mask";

const Index = () => {
  let match = useRouteMatch();
  useEffect(() => {
    LoadMask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <PreLeadCotacaoProvider>
      <div className="container-fluid pl-3 pt-3 pb-3">
        <Switch>
          <PrivateRoute
            exact
            path={`${match.url}`}
            component={PesquisarPreLeadCotacao}
          />
          <PrivateRoute
            path={`${match.url}/:id`}
            component={FormularioPreLeadCotacao}
          />
        </Switch>
      </div>
    </PreLeadCotacaoProvider>
  );
};

export default Index;
