import React, { useContext, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { TipoNegociacaoContext } from "../TipoNegociacaoContext";
import Formulario from "../../../../../Components/Cadastro/Formulario/Formulario";

const FormularioTipoNegociacao = () => {
  const { values, setValues, getDetailHandler } = useContext(
    TipoNegociacaoContext
  );
  let { id } = useParams();

  useEffect(() => {
    getDetailHandler(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      data: {
        ...values.data,
        [name]: value,
      },
    });
  }
  return (
    <Fragment>
      <Formulario context={TipoNegociacaoContext}>
        <div className="col-12 col-md-5 form-group">
          <label>Descrição</label>
          <input
            disabled={values.formDisabled}
            className="form-control uppercase"
            type="text"
            maxLength="30"
            name="tipoNegociacaoDescricao"
            value={values.data.tipoNegociacaoDescricao}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-2 form-group">
          <label>Status</label>
          <select
            disabled={values.formDisabled}
            className="form-control"
            name="tipoNegociacaoStatus"
            value={values.data.tipoNegociacaoStatus}
            onChange={onChangeInput}
          >
            <option className="disabled"></option>
            <option value="A"> Ativo </option>
            <option value="I"> Inativo </option>
          </select>
        </div>
      </Formulario>
    </Fragment>
  );
};

export default FormularioTipoNegociacao;
