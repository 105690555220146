import { RequestWebApi, validateRequestErrorStatus } from './Requests';

const controller = 'FipeSuhai';
export const ListarFabricante = () =>
	RequestWebApi.get(`/${controller}/ListarFabricante`)
		.then(function(response) {
			const { resultData, success } = response.data;
			const fabricantes = resultData;
			return { success, fabricantes };
		})
		.catch(function(error) {
			validateRequestErrorStatus(error.response.status);
			return { fabricantes: [] };
		});

export const ListarFipeSuhaiPorFabricante = (fipeSuhaiDescrFabricante) =>
	RequestWebApi.get(`/${controller}/ListarFipeSuhaiPorFabricante`, {
		params: { fipeSuhaiDescrFabricante: fipeSuhaiDescrFabricante }
	})
		.then(function(response) {
			const { resultData, success } = response.data;
			const tabelaFipeSuhai = resultData;
			return { success, tabelaFipeSuhai };
		})
		.catch(function(error) {
			validateRequestErrorStatus(error.response.status);
			return { tabelaFipeSuhai: [] };
		});

export const BuscarParametrosTipoVeiculoSuhai = (tipoVeiculoId, anoFabricacao) =>
	RequestWebApi.get(`/${controller}/BuscarParametrosTipoVeiculoSuhai`, {
		params: { tipoVeiculoId: tipoVeiculoId, anoFabricacao: anoFabricacao }
	})
		.then(function(response) {
			const { resultData, success } = response.data;
			const parametrosTipoVeiculoSuhai = resultData;
			return { success, parametrosTipoVeiculoSuhai };
		})
		.catch(function(error) {
			validateRequestErrorStatus(error.response.status);
			return { parametrosTipoVeiculoSuhai: {} };
		});
