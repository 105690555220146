import React, { useContext, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { UsuarioContext } from "../UsuarioContext";
import Formulario from "../../../../../Components/Cadastro/Formulario/Formulario";
import FormularioComplementar from "../../../../../Components/Cadastro/FormularioComplementar/FormularioComplementar";
import PermissoesRelatorios from "../../../../../Components/PermissoesRelatorios/PermissoesRelatorios";
import DataTable from "../../../../../Components/DataTable/DataTable";
import Avatar from "../../../../../Components/Avatar/Avatar";
import { LoadMask } from "../../../../../Utils/mask";

const FormularioUsuario = () => {
  const {
    values,
    setValues,
    getDetailHandler,
    filtraEquipes,
    adicionarEquipe,
    removerEquipe,
    adicionarIp,
    removerIp,
    reiniciarSenha,
  } = useContext(UsuarioContext);
  let { id } = useParams();
  useEffect(() => {
    LoadMask();
    getDetailHandler(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      data: {
        ...values.data,
        [name]: value,
      },
    });
  }
  function onChangeInputProduto(event) {
    const value = event.target.value;
    const text = event.target.options[event.target.selectedIndex].text;
    filtraEquipes(value, text);
  }

  function onChangeInputEquipe(event) {
    const value = event.target.value;
    const text = event.target.options[event.target.selectedIndex].text;

    setValues({
      ...values,
      dataEquipe: {
        ...values.dataEquipe,
        id: value,
        equipeId: value,
        equipeNome: text,
      },
    });
  }

  function onChangeInputIp(event) {
    const { name, value } = event.target;

    setValues({
      ...values,
      dataIp: {
        ...values.dataIp,
        id: value,
        [name]: value,
      },
    });
  }

  function onChangeInputSupervisor(event) {
    setValues({
      ...values,
      dataEquipe: {
        ...values.dataEquipe,
        supervisor: event.target.checked ? 1 : 0,
      },
    });
  }

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setValues({
        ...values,
        data: {
          ...values.data,
          usuarioFoto: undefined,
        },
      });
      return;
    }

    const selectedFile = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(selectedFile);

    reader.onload = function () {
      const usuarioFoto = reader.result;
      setValues({
        ...values,
        data: {
          ...values.data,
          usuarioFoto: usuarioFoto,
        },
      });
      return;
    };
    reader.onerror = function (error) {
      setValues({
        ...values,
        data: {
          ...values.data,
          usuarioFoto: undefined,
        },
      });
      return;
    };
  };

  function checkItemRelatorioChange(item) {
    let id_array = item.id.split('_');
    let relatorioModulo = id_array[0];
    let relatorioId = id_array[1];

    let relatorios = values.permissoesRelatoriosUsuario;

    let relatoriosModulo = relatorios.find(x => x.relatorioModulo === relatorioModulo);

    relatoriosModulo.relatorios.map(relario => {
      relario.temPermissao = relario.relatorioId == relatorioId ? item.checked : relario.temPermissao;
    })

    let permissoes = [];
    relatorios.map(relatorioModulo => {
      if (relatorioModulo.relatorioModulo === relatorioModulo) {
        relatorioModulo.relatorios = relatoriosModulo.relatorios;
      }
      let permissoesModulo = relatorioModulo.relatorios.filter(x => x.temPermissao === true);
      permissoesModulo.map(p => permissoes.push(p));

    })

    setValues({
      ...values,
      data: {
        ...values.data,
        permissoesRelatorios: permissoes,
      },
      permissoesRelatoriosUsuario: relatorios
    });

  }

  return (
    <Fragment>
      <Formulario context={UsuarioContext}>
        <div className="col-12 col-md-2">
          <div className="row justify-content-md-center">
            <Avatar src={values.data.usuarioFoto} className="profile" />

            <input
              disabled={values.formDisabled}
              type="file"
              className="customFileInputHide"
              onChange={onSelectFile}
              id="imageUpload"
            />
            {values.formDisabled ? null : (
              <label
                type="button"
                className="btn btn-primary"
                htmlFor="imageUpload"
                style={{ marginTop: 10 }}
              >
                Importar Foto
              </label>
            )}
          </div>
        </div>
        <div className="col-12 col-md-10 form-group">
          <div className="row">
            <div className="col-12 col-md-2 form-group">
              <label>
                Login<span className="campoObratorio">*</span>
              </label>
              <input
                disabled={values.formDisabled || values.data.usuarioId > 0}
                className="form-control lowercase"
                type="text"
                name="usuarioLogin"
                value={values.data.usuarioLogin}
                onChange={onChangeInput}
              />
            </div>
            <div className="col-12 col-md-5 form-group">
              <label>
                Nome Completo<span className="campoObratorio">*</span>
              </label>
              <input
                disabled={values.formDisabled}
                className="form-control uppercase"
                type="text"
                name="usuarioNome"
                value={values.data.usuarioNome}
                onChange={onChangeInput}
              />
            </div>

            <div className="col-12 col-md-5 form-group">
              <label>
                E-mail<span className="campoObratorio">*</span>
              </label>
              <input
                disabled={values.formDisabled}
                className="form-control"
                type="text"
                name="usuarioEmail"
                value={values.data.usuarioEmail}
                onChange={onChangeInput}
              />
            </div>

            <div className="col-12 col-md-2 form-group">
              <label>
                Status<span className="campoObratorio">*</span>
              </label>
              <select
                disabled={values.formDisabled}
                className="form-control"
                name="usuarioStatus"
                value={values.data.usuarioStatus}
                onChange={onChangeInput}
              >
                <option className="disabled"></option>
                <option value="A"> Ativo </option>
                <option value="I"> Inátivo </option>
              </select>
            </div>

            <div className="col-12 col-md-3 form-group">
              <label>
                Perfil de Acesso<span className="campoObratorio">*</span>
              </label>
              <select
                disabled={values.formDisabled}
                className="form-control"
                name="grupoUsuarioId"
                value={values.data.grupoUsuarioId}
                onChange={onChangeInput}
              >
                <option className="disabled" value="0"></option>
                {values.perfis.map((perfil) => (
                  <option
                    key={perfil.grupoUsuarioId}
                    value={perfil.grupoUsuarioId}
                  >
                    {perfil.grupoUsuarioNome}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-md-2 form-group">
              <label>
                Ramal
              </label>
              <input
                disabled={values.formDisabled}
                className="form-control numerico"
                type="text"
                name="usuarioRamal"
                maxLength="20"
                value={values.data.usuarioRamal ? values.data.usuarioRamal : ""}
                onChange={onChangeInput}
              />
            </div>
            <div className="col-12 col-md-3 form-group">
              <label>
                Nível
              </label>
              <select
                disabled={values.formDisabled}
                className="form-control"
                name="consultorNivelId"
                value={values.data.consultorNivelId ? values.data.consultorNivelId : 0}
                onChange={onChangeInput}
              >
                <option className="disabled" value="0"></option>
                {values.niveisConsultor.map((nivelConsultor) => (
                  <option
                    key={nivelConsultor.consultorNivelId}
                    value={nivelConsultor.consultorNivelId}
                  >
                    {nivelConsultor.consultorNivelDescr}
                  </option>
                ))}
              </select>
            </div>
            {id > 0 ? (
              <Fragment>
                <div className="col-12 col-md-12" />
                <div className="col-12 col-md-3 pt-4">
                  <button
                    type="button"
                    onClick={() => reiniciarSenha(id)}
                    className="btn btn-primary"
                  >
                    Reiniciar Senha
                  </button>
                </div>
              </Fragment>
            ) : null}
          </div>
        </div>
      </Formulario>
      <FormularioComplementar titulo="Equipes">
        <div className="col-12 col-md-3 form-group">
          <label>Produto</label>
          <select
            disabled={values.formDisabled}
            className="form-control"
            name="produtoId"
            value={values.dataEquipe.produtoId}
            onChange={onChangeInputProduto}
          >
            <option className="disabled"></option>
            {values.produtos.map((produto) => (
              <option key={produto.produtoId} value={produto.produtoId}>
                {`${produto.produtoCod} - ${produto.produtoNome}`}
              </option>
            ))}
          </select>
        </div>

        <div className="col-12 col-md-3 form-group">
          <label>Equipe</label>
          <select
            disabled={values.formDisabled}
            className="form-control"
            name="equipeId"
            value={values.dataEquipe.equipeId}
            onChange={onChangeInputEquipe}
          >
            <option className="disabled"></option>
            {values.equipesProduto.map((equipe) => (
              <option key={equipe.equipeId} value={equipe.equipeId}>
                {equipe.equipeNome}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-2 form-group ">
          <label>Supervisor</label>
          <input
            disabled={values.formDisabled}
            type="checkbox"
            name="supervisor"
            checked={values.dataEquipe.supervisor}
            onChange={onChangeInputSupervisor}
          />
        </div>

        <div className="col-12 col-md-2 form-group">
          <button
            disabled={!values.dataEquipe.equipeId}
            type="button"
            className="btn btn-default buttonFormularios"
            onClick={adicionarEquipe}
          >
            Adicionar
          </button>
        </div>
        <div className="col-12 col-md-8 form-group">
          <DataTable
            data={values.equipesUsuario}
            columnsDefinition={values.equipesUsuarioColumnsDefinition}
            removeHandler={values.formDisabled ? null : removerEquipe}
          />
        </div>
      </FormularioComplementar>

      <FormularioComplementar titulo="Ips Permitidos">
        <div className="col-12 col-md-3 form-group">
          <label>
            Ip
          </label>
          <input
            disabled={values.formDisabled}
            className="form-control"
            type="text"
            name="usuarioQuaisIpsNumeroIp"
            maxLength="50"
            value={values.dataIp.usuarioQuaisIpsNumeroIp ? values.dataIp.usuarioQuaisIpsNumeroIp : ""}
            onChange={onChangeInputIp}
          />
        </div>
        <div className="col-12 col-md-2 form-group">
          <button
            disabled={!values.dataIp.usuarioQuaisIpsNumeroIp.trim()}
            type="button"
            className="btn btn-default buttonFormularios"
            onClick={adicionarIp}
          >
            Adicionar
          </button>
        </div>
        <div className="col-12" />
        <div className="col-12 col-md-7 form-group">
          <DataTable
            data={values.ips}
            columnsDefinition={values.ipsColumnsDefinition}
            removeHandler={values.formDisabled ? null : removerIp}

          />
        </div>
      </FormularioComplementar>

      <FormularioComplementar titulo="Relatórios">
        <div className="col-12">
          <PermissoesRelatorios
            formDisabled={values.formDisabled}
            permissoes={values.permissoesRelatoriosUsuario}
            checkItemChange={checkItemRelatorioChange}
          />
        </div>
      </FormularioComplementar>
    </Fragment >
  );
};

export default FormularioUsuario;
