import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRoute from "../../../../Routes/PrivateRoutes";
import TemplateEmailProvider from "./TemplateEmailContext";
import PesquisarTemplateEmail from "./Events/PesquisarTemplateEmail";
import FormularioTemplateEmail from "./Events/FormularioTemplateEmail";

const Index = () => {
  let match = useRouteMatch();
  return (
    <TemplateEmailProvider>
      <div className="container-fluid pl-3 pt-3 pb-3">
        <Switch>
          <PrivateRoute
            exact
            path={`${match.url}`}
            component={PesquisarTemplateEmail}
          />
          <PrivateRoute
            path={`${match.url}/:id`}
            component={FormularioTemplateEmail}
          />
        </Switch>
      </div>
    </TemplateEmailProvider>
  );
};

export default Index;
