import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "TipoNegociacao";
export const Listar = (tipoNegociacao) =>
  RequestWebApi.get(`/${controller}/Listar`, {
    params: { ...tipoNegociacao },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const tiposNegociacao = resultData;
      return { success, tiposNegociacao };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { tiposNegociacao: [] };
    });

export const Detalhes = (tipoNegociacaoId) =>
  RequestWebApi.get(`/${controller}/Detalhes`, {
    params: { tipoNegociacaoId: tipoNegociacaoId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const tipoNegociacao = resultData;
      return { success, tipoNegociacao };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, tipoNegociacao: {} };
    });

export const Incluir = (tipoNegociacao) =>
  RequestWebApi.post(`/${controller}/Incluir`, tipoNegociacao)
    .then(function (response) {
      const { resultData, success } = response.data;
      const novoProduto = resultData;
      return { success, novoProduto };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Atualizar = (tipoNegociacao) =>
  RequestWebApi.put(`/${controller}/Atualizar`, tipoNegociacao)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (tipoNegociacaoId) =>
  RequestWebApi.delete(`/${controller}/Remover`, {
    params: { tipoNegociacaoId: tipoNegociacaoId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });
