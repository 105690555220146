import React from "react";
import { Fragment } from "react";
import PermissoesItem from "../PermissoesItem/PermissoesItem";

const PermissoesSubFolder = ({ subMenus, context }) => {
  return (
    <Fragment>
      {subMenus.map((menu) =>
        menu.subMenus.length === 0 ? (
          <PermissoesItem
            key={menu.id}
            id={menu.id}
            nome={menu.nome}
            incluir={menu.permissao.incluir}
            editar={menu.permissao.editar}
            excluir={menu.permissao.excluir}
            context={context}
          />
        ) : (
          <li
            key={menu.id}
            className="cd-accordion__item cd-accordion__item--has-children"
          >
            <input
              className="cd-accordion__input"
              type="checkbox"
              name={`sub-group-${menu.id}`}
              id={`sub-group-${menu.id}`}
            />
            <label
              className="cd-accordion__label cd-accordion__label--icon-folder"
              htmlFor={`sub-group-${menu.id}`}
            >
              <span>{menu.nome}</span>
            </label>

            <ul className="cd-accordion__sub cd-accordion__sub--l2">
              <PermissoesSubFolder
                key={menu.id}
                subMenus={menu.subMenus}
                context={context}
              />
            </ul>
          </li>
        )
      )}
    </Fragment>
  );
};

export default PermissoesSubFolder;
