import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "TemplateSms";
export const Listar = (templateSms) =>
  RequestWebApi.get(`/${controller}/Listar`, {
    params: { ...templateSms },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const templatesSms = resultData;
      return { success, templatesSms };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { templatesSms: [] };
    });

export const Detalhes = (templateSmsId) =>
  RequestWebApi.get(`/${controller}/Detalhes`, {
    params: { templateSmsId: templateSmsId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const templateSms = resultData;
      return { success, templateSms };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, templateSms: {} };
    });

export const Incluir = (templateSms) =>
  RequestWebApi.post(`/${controller}/Incluir`, templateSms)
    .then(function (response) {
      const { resultData, success } = response.data;
      const novoGrupoUsuario = resultData;
      return { success, novoGrupoUsuario };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Atualizar = (templateSms) =>
  RequestWebApi.put(`/${controller}/Atualizar`, templateSms)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (templateSmsId) =>
  RequestWebApi.delete(`/${controller}/Remover`, {
    params: { templateSmsId: templateSmsId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });
