import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "DadosSeguradora";



export const ImportarDadosSeguradora = (dadosSeguradora) => {
  let result = RequestWebApi.post(`/${controller}/ImportarDadosSeguradora`, dadosSeguradora)
    .then(function (response) {
      const { resultData, success } = response.data;
      const resultadosImportacao = resultData;
      return { success, resultadosImportacao };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, resultadosImportacao: [] };
    });
  return result;
};
