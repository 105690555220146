import React, { useContext, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { JornadaTrabalhoContext } from "../JornadaTrabalhoContext";
import Formulario from "../../../../../Components/Cadastro/Formulario/Formulario";
import FormularioComplementar from "../../../../../Components/Cadastro/FormularioComplementar/FormularioComplementar";
import DataTable from "../../../../../Components/DataTable/DataTable";
import JornadaSemanal from "../../../../../Components/JornadaTrabalho/JornadaSemanal";
import { retornarDescricaoJornada } from "../../../../../Utils/JornadaTrabalho";

const FormularioJornadaTrabalho = () => {
  const {
    values,
    setValues,
    getDetailHandler,
    removerConsultor,
    adionarConsultor,
  } = useContext(JornadaTrabalhoContext);
  let { id } = useParams();

  useEffect(() => {
    getDetailHandler(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      data: {
        ...values.data,
        [name]: value,
      },
    });
  }

  function onChangeInputConsultores(event) {
    const value = event.target.value;
    const text = event.target.options[event.target.selectedIndex].text;
    setValues({
      ...values,
      dataConsultor: {
        id: value,
        usuarioId: value,
        usuarioNome: text,
      },
    });
  }

  function onChangeCheck(event) {
    const { name, checked } = event.target;
    setValues({
      ...values,
      data: {
        ...values.data,
        [name]: checked,
      },
    });
  }

  function recuperarDias(dias) {
    let descricaoDias = retornarDescricaoJornada(dias);
    setValues({
      ...values,
      data: {
        ...values.data,
        descricaoDias: descricaoDias,
        dias: dias,
      },
    });
  }

  return (
    <Fragment>
      <Formulario context={JornadaTrabalhoContext}>
        <div className="col-12 col-md-5 form-group">
          <label>Descrição</label>
          <input
            disabled={values.formDisabled}
            className="form-control uppercase"
            type="text"
            name="jornadaTrabalhoDescricao"
            value={values.data.jornadaTrabalhoDescricao}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-12 form-group " />
        <JornadaSemanal
          formDisabled={values.formDisabled}
          recuperarJornadaSemanal={recuperarDias}
          jornadaSemanalBase={values.data.dias}
          descricaoJornadaSemanal={values.data.descricaoDias}
        />
        <div className="col-12 col-md-3 form-group ">
          <label>Trabalha em feriados</label>
          <input
            disabled={values.formDisabled}
            type="checkbox"
            name="jornadaTrabalhoFeriado"
            checked={values.data.jornadaTrabalhoFeriado}
            onChange={onChangeCheck}
          />
        </div>
      </Formulario>
      <FormularioComplementar titulo="Consultores">
        <div className="col-12 col-md-3 form-group">
          <label>Consultor</label>
          <select
            disabled={values.formDisabled}
            className="form-control"
            name="usuarioId"
            value={values.dataConsultor.usuarioId}
            onChange={onChangeInputConsultores}
          >
            <option className="disabled"></option>
            {values.usuarios.map((usuario) => (
              <option key={usuario.usuarioId} value={usuario.usuarioId}>
                {usuario.usuarioNome}
              </option>
            ))}
          </select>
        </div>

        <div className="col-12 col-md-2 form-group">
          <button
            disabled={!values.dataConsultor.usuarioId}
            type="button"
            className="btn btn-default buttonFormularios"
            onClick={adionarConsultor}
          >
            Adicionar
          </button>
        </div>
        <div className="col-12 col-md-12 form-group">
          <DataTable
            data={values.consultoresJornada}
            columnsDefinition={values.columnsDefinitionConsultores}
            removeHandler={values.formDisabled ? null : removerConsultor}
          />
        </div>
      </FormularioComplementar>
    </Fragment>
  );
};

export default FormularioJornadaTrabalho;
