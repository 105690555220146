import React, { useContext } from "react";
import "./FormularioCampanhaSegmentacao.css";
import { CampanhaContext } from "../CampanhaContext";
import FormularioComplementar from "../../../../../Components/Cadastro/FormularioComplementar/FormularioComplementar";
import DataTableDefault from "../../../../../Components/DataTableDefault/DataTableDefault";
import AdicionarSegmentacao from "../../../../../Components/Campanha/AdicionarSegmentacao/AdicionarSegmentacao";
import EditarSegmentacao from "../../../../../Components/Campanha/EditarSegmentacao/EditarSegmentacao";
// const images = require.context("../../../../../assets/img/icones/pages", true);
// const iconeDownload = images("./surface1.png");

const FormularioCampanhaSegmentacao = () => {
  const { values } = useContext(CampanhaContext);

  const actionsTable = (cell, row) => {
    return (
      <div className="tabelasResultados__acoes">
        <EditarSegmentacao
          id={row.segmentacaoPublicoId}
          context={CampanhaContext}
        />

        {/* <button
          type="button"
          className="btn btn-success btn-sm  ml-1"
          onClick={() => alert(row.segmentacaoPublicoId)}
        >
          <img src={iconeDownload} alt="" />
        </button> */}
      </div>
    );
  };

  const columnsDefinition = [
    {
      dataField: "actions",
      text: "Ações",
      headerStyle: { width: "60px" },
      isDummyField: true,
      csvExport: false,
      headerAttrs: { width: 100 },
      formatter: actionsTable,
    },
    {
      text: "Id",
      dataField: "segmentacaoPublicoId",
      hidden: true,
    },
    {
      text: "Nome Segmentacão",
      dataField: "segmentacaoPublicoNome",
      headerStyle: { width: "450px" },
      sort: true,
    },
    {
      text: "Qtd. Total Público",
      dataField: "totalPublico",
      headerStyle: { width: "100px" },
      sort: true,
    },
    {
      text: "Qtd. Removidos",
      dataField: "totalPublicoRemovido",
      headerStyle: { width: "100px" },
      sort: true,
    },
    {
      text: "Status",
      dataField: "campanhaQuaisSegmentacaoDesativado",
      headerStyle: { width: "80px" },
      sort: true,
      formatter: (cell, row) => {
        return cell ? (
          <span className="badge badge-danger">Inátivo</span>
        ) : (
          <span className="badge badge-success">Ativo</span>
        );
      },
    },
  ];

  return (
    <FormularioComplementar>
      <div className="col-12 text-right" style={{ marginBottom: 20 }}>
        {values.formDisabled ? null : (
          <AdicionarSegmentacao
            id="adicionarSegmentacao"
            context={CampanhaContext}
            selectedItensBase={values.data.segmentacoesPublico.map((item) => {
              return item.segmentacaoPublicoId;
            })}
            selectedItensBaseBloqueados={values.segmentacoesPublicoBloqueados}
          />
        )}
      </div>
      <div className="row">
        <div className="col-12">
          <DataTableDefault
            keyField="segmentacaoPublicoId"
            data={values.data.segmentacoesPublico}
            columns={columnsDefinition}
          />
        </div>
      </div>
    </FormularioComplementar>
  );
};

export default FormularioCampanhaSegmentacao;
