import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "TipoSinistro";
export const Listar = () =>
  RequestWebApi.get(`/${controller}/Listar`)
    .then(function (response) {
      const { resultData, success } = response.data;
      const tiposSinistro = resultData;
      return { success, tiposSinistro };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { tiposSinistro: [] };
    });

export const Incluir = (tipoSinistro) =>
  RequestWebApi.post(`/${controller}/Incluir`, tipoSinistro)
    .then(function (response) {
      const { resultData, success } = response.data;
      const tipoSinistro = resultData;
      return { success, tipoSinistro };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Atualizar = (tipoSinistro) =>
  RequestWebApi.put(`/${controller}/Atualizar`, tipoSinistro)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (tipoSinistroId) =>
  RequestWebApi.delete(`/${controller}/Remover`, {
    params: { tipoSinistroId: tipoSinistroId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });
