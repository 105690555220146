import React, { Fragment } from "react";
import "./ConfirmarExclusao.css";
const images = require.context("../../../assets/img/icones/pages", true);
const iconeRemove = images("./trash.png");
const ConfirmarExclusao = ({ removeHandler, id }) => {
  return (
    <Fragment>
      <button
        type="button"
        className="btn btn-danger btn-sm confirmarExclusao_botao"
        data-toggle="modal"
        data-target={`#modalConfirmacao${id}`}
      >
        <img src={iconeRemove} alt="" />
      </button>

      <div
        className="modal fade"
        id={`modalConfirmacao${id}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalConfirmacaoTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Excluir Registro</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Fechar"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">Confirmar a exclusão do registro?</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancelar
              </button>
              <button
                type="button"
                onClick={() => removeHandler(id)}
                className="btn btn-default"
                data-dismiss="modal"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ConfirmarExclusao;
