import React, { useContext, Fragment, useEffect } from 'react'
import { UltimoAtendimentoLeadContext } from '../UltimoAtendimentoLeadContext'
import PesquisarRelatorio from '../../../../../Components/PesquisarRelatorio/PesquisarRelatorio'
import GroupBox from '../../../../../Components/GroupBox/GroupBox'

const PesquisarUltimoAtendimentoLead = () => {
  const { values, setValues, resetScreen } = useContext(UltimoAtendimentoLeadContext)

  useEffect(() => {
    resetScreen()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onChangeInput(event) {
    const { name, value } = event.target
    setValues({
      ...values,
      searchData: {
        ...values.searchData,
        [name]: value,
      },
    })
  }

  return (
    <Fragment>
      <PesquisarRelatorio context={UltimoAtendimentoLeadContext}>

        <GroupBox
          titulo="Data"
          className="col-12 col-md-6 form-group"
        >
          <div className="col-12 col-md-6 ">
            <label>De:</label>
            <input
              className="form-control"
              type="date"
              name="dataDe"
              value={values.searchData.dataDe}
              onChange={onChangeInput}
            />
          </div>
          <div className="col-12 col-md-6 ">
            <label>Até:</label>
            <input
              className="form-control"
              type="date"
              name="dataAte"
              value={values.searchData.dataAte}
              onChange={onChangeInput}
            />
          </div>
        </GroupBox>

      </PesquisarRelatorio>
    </Fragment>
  )
}

export default PesquisarUltimoAtendimentoLead
