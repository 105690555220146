import React from 'react';
import { Fragment } from 'react';
import './PagamentoStyleDefault.css';
import asseguroulogo from '../../assets/img/asseguroulogo.svg';

const Sucesso = () => {
	return (
		<Fragment>
			<div className="pagePagamentoCartao_header">
				<div className="pagePagamentoCartao_container">
					<div className="row align-items-center">
						<div className="col-6 col-md-6">
							<div className="logo">
								<img src={asseguroulogo} alt="" width="250" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container mt-5 pt-5">
				<div className="row">
					<div className="col-12 text-center">
						<div className="pagePagamentoCartao_title-step">Show, seguro contrado com sucesso!</div>
						<div className="pagePagamentoCartao_title-section">
							Dentro de alguns minutos você receberá um e-mail de boas vindas da
						</div>
						<div className="pagePagamentoCartao_title-section">
							equipe Assegurou, com todos nossos canais de atendimento, para que
						</div>
						<div className="pagePagamentoCartao_title-section">
							você conte conosco sempre que precisar ; )
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Sucesso;
