import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRoute from "../../../Routes/PrivateRoutes";
import MotorEventosProvider from "./MotorEventosContext";
import PesquisarMotorEventos from "./Events/PesquisarMotorEventos";

const Index = () => {
  let match = useRouteMatch();
  return (
    <MotorEventosProvider>
      <div className="container-fluid pl-3 pt-3 pb-3">
        <Switch>
          <PrivateRoute
            exact
            path={`${match.url}`}
            component={PesquisarMotorEventos}
          />
        </Switch>
      </div>
    </MotorEventosProvider>
  );
};

export default Index;
