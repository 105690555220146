import { RequestWebApi, validateRequestErrorStatus } from "./Requests";
import qs from "qs";

const controller = "BaseProspect";
export const Listar = (baseProspect) =>
  RequestWebApi.get(`/${controller}/Listar`, {
    params: { ...baseProspect },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const baseProspects = resultData;
      return { success, baseProspects };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { baseProspects: [] };
    });

export const ListarBaseProspectItens = (baseProspectIdListArray) =>
  RequestWebApi.get(`/${controller}/ListarBaseProspectItens`, {
    params: { baseProspectIdList: baseProspectIdListArray },
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const baseProspectsItens = resultData;
      return { success, baseProspectsItens };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { baseProspectsItens: [] };
    });

export const Detalhes = (baseProspectId) =>
  RequestWebApi.get(`/${controller}/Detalhes`, {
    params: { baseProspectId: baseProspectId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const baseProspect = resultData;
      return { success, baseProspect };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, baseProspect: {} };
    });

export const Incluir = (baseProspect) =>
  RequestWebApi.post(`/${controller}/Incluir`, baseProspect)
    .then(function (response) {
      const { resultData, success } = response.data;
      const baseProspectInfo = resultData;
      return { success, baseProspectInfo };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors, resultData } = error.response.data;
      const baseProspectInfo = resultData;
      return { success, message, errors, baseProspectInfo };
    });

export const Atualizar = (baseProspect) =>
  RequestWebApi.put(`/${controller}/Atualizar`, baseProspect)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (baseProspectId) =>
  RequestWebApi.delete(`/${controller}/Remover`, {
    params: { baseProspectId: baseProspectId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const DownloadArquivoImportado = (baseProspectId) =>
  RequestWebApi.get(`/${controller}/DownloadArquivoImportado`, {
    params: { baseProspectId: baseProspectId },
    responseType: "blob",
  })
    .then(function (response) {
      const type = response.headers["content-type"];
      const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `BaseProspect`;
      link.click();
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { filaAtendimento: [] };
    });
