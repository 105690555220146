/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import HistoricoLead from "../../../Components/Lead/HistoricoLead/HistoricoLead";
import { DownloadArquivo } from '../../../Services/Download';
import { IncluirLeadInterno, ListarArquivosLead, RemoverAnexosLead, UploadAnexosLead } from '../../../Services/Lead';
import { ListarListBoxPorRotulo } from '../../../Services/ListBox';
import { Listar as ListarProduto, Detalhes as DetalhesProduto } from '../../../Services/Produto';
import { Listar as ListarSeguradora } from '../../../Services/Seguradora';
import {
	Detalhes as DetalhesSinistro, Listar as ListarSinistro, ProcessarSinistro, Remover as RemoverSinistro
} from '../../../Services/Sinistro';
import { Listar as ListarSituacaoSinistro } from '../../../Services/SituacaoSinistro';
import { Listar as ListarTipoSinistro } from '../../../Services/TipoSinistro';
import { ListarSimples as ListarUsuario } from '../../../Services/Usuario';
import AppContext from '../../../Store/AppContext';
import { validarPermissoesPagina } from '../../../Utils/validarPermissoesPagina';

export const SinistroContext = createContext();

const tituloTela = 'Sinistro';

const defaultData = {
	sinistroId: 0,
	leadId: 0,
	seguradoraId: 0,
	produtoId: 0,
	tipoSinistroId: 0,
	situacaoSinistroId: 0,
	leadNumDocumento: '',
	sinistroNomeCliente: '',
	sinistroAvisoSegCliente: '',
	sinistroDddCliente: '',
	sinistroTelCliente: '',
	sinistroNomeTerceiro: '',
	sinistroAvisoSegTerceiro: '',
	sinistroDddTerceiro: '',
	sinistroTelTerceiro: '',
	sinistroDataAcidente: '',
	sinistroDataAvisoSeguradora: '',
	sinistroObservacao: '',
	observacaoHistorico: '',
	sinistroFinalizado: false,
	sinistroDataLiquidacao: '',
	sinistroVlrIndenizacao: 0,
	sinistroVlrIndenizacaoTerceiro: 0,
	sinistroDataHora: '',
	sinistroNumeroAviso: '',
	numeroApolice: '',
	dataApolice: '',
	sinistroDataRetorno: '',
	sinistroSolicitante: '',
	leadAuto: null,
	terceiros: []
};

const searchData = {
	leadNumDocumento: '',
	sinistroNomeCliente: '',
	sinistroNomeTerceiro: '',
	sinistroNumeroAviso: '',
	numeroApolice: '',
	sinistroDataHoraDe: '',
	sinistroDataHoraAte: '',
	sinistroDataAcidenteDe: '',
	sinistroDataAcidenteAte: '',
	sinistroDataRetornoDe: '',
	sinistroDataRetornoAte: '',
	situacaoSinistroId: 0,
	sinistroSolicitante: '',
	sinistroFinalizado: false,
	placaVeiculo: '',
	numeroAvisoSeguradora: '',
};

const columnsDefinition = [
	{
		text: 'Id',
		dataField: 'sinistroId',
		hidden: true
	},
	{
		text: 'Numero Aviso',
		dataField: 'sinistroId',
		headerStyle: { width: '100px' },
		sort: true
	},
	{
		text: 'Aviso do Corretor',
		dataField: 'sinistroDataHora',
		headerStyle: { width: '100px' },
		sort: true
	},
	{
		text: 'Seguradora',
		dataField: 'seguradoraDescricao',
		headerStyle: { width: '100px' },
		sort: true
	},
	{
		text: 'Tipo Sinistro',
		dataField: 'tipoSinistroDescricao',
		headerStyle: { width: '100px' },
		sort: true
	},
	{
		text: 'Nome',
		dataField: 'sinistroNomeCliente',
		headerStyle: { width: '100px' },
		sort: true,
		formatter: (cell, row) => {
			return (
				<HistoricoLead leadId={row.leadId} disabled={false} >
					<span className="link_primary">
						{cell}
					</span>
				</HistoricoLead>
			);
		},
	},
	{
		text: 'Situação',
		dataField: 'situacaoSinistroDescricao',
		headerStyle: { width: '100px' },
		sort: true
	},
	{
		text: 'Finalizado?',
		dataField: 'sinistroFinalizado',
		headerStyle: { width: '55px' },
		sort: true,
		formatter: (cell, row) => {
			return <span className={`badge badge-${cell ? 'success' : 'danger'}`}>{cell ? 'Sim' : 'Não'}</span>;
		}
	}
];

function initialState() {
	return {
		searchData: searchData,
		resultSearch: [],
		columnsDefinition: columnsDefinition,
		data: defaultData,
		seguradoras: [],
		produtos: [],
		tiposSinistro: [],
		situacoesSinistro: [],
		usuarios: [],
		documentosSinistro: [],
		formDisabled: true,
		produto: {},
	};
}
let arquivos = [];
const SinistroProvider = ({ children }) => {
	const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(AppContext);
	const [values, setValues] = useState(initialState());
	let history = useHistory();

	const resetScreen = () => {
		async function carregarDependencias() {
			showLoading();
			const dependencias = await loadDependences();
			setTituloTela(tituloTela);
			setValues({
				...initialState(),
				permissoesPagina: await dependencias.permissoesPagina,
				seguradoras: await dependencias.seguradoras,
				produtos: await dependencias.produtos,
				documentosSinistro: await dependencias.documentosSinistro,
				tiposSinistro: await dependencias.tiposSinistro,
				situacoesSinistro: await dependencias.situacoesSinistro,
				usuarios: await dependencias.usuarios,
				produto: {},
			});
			hideLoading();
		}
		arquivos = [];
		carregarDependencias();
	};

	const searchHandler = async () => {
		showLoading();
		const responseListar = await ListarSinistro(values.searchData);

		if (!responseListar.success) {
			showMessage('Erro', 'Erro Inesperado durante a pesquisa.');
			hideLoading();
			return;
		}

		const resultSearchData = await responseListar.origensLead;

		resultSearchData.map((data) => (data.Id = data.sinistroId));

		setValues({
			...values,
			resultSearch: resultSearchData
		});

		if (resultSearchData.length === 0) {
			showMessage('Alerta', 'Nenhum registro encontrado.');
		}
		hideLoading();
	};

	const removeHandler = async (id) => {
		showLoading();
		const responseRemover = await RemoverSinistro(id);
		if (!responseRemover.success) {
			hideLoading();
			showMessage('Erro', `Erro durante a exclusão: ${responseRemover.message}`);
			return;
		}
		searchHandler();
		hideLoading();
		showMessage('Sucesso', 'Registro excluido.');
	};

	async function loadDependences() {
		let seguradoras = [];
		let produtos = [];
		let documentosSinistro = [];
		let tiposSinistro = [];
		let situacoesSinistro = [];
		let usuarios = [];

		const [
			permissoesPaginaAux,
			responseListarSeguradora,
			responseListarProduto,
			responseListarTipoSinistro,
			responseListarSituacaoSinistro,
			responseListarUsuario,
			responseListarListBoxPorRotuloDocumentosSinistro

		] = await Promise.all([
			validarPermissoesPagina(),
			ListarSeguradora({}),
			ListarProduto({}),
			ListarTipoSinistro({}),
			ListarSituacaoSinistro({}),
			ListarUsuario({}),
			ListarListBoxPorRotulo('DocsSinistro')


		]);

		if (
			!responseListarSeguradora.success ||
			!responseListarProduto.success ||
			!responseListarTipoSinistro.success ||
			!responseListarSituacaoSinistro.success ||
			!responseListarUsuario.success ||
			!responseListarListBoxPorRotuloDocumentosSinistro.success
		) {
			showMessage('Erro', 'Erro Inesperado ao carregar dados basicos.');
		} else {
			seguradoras = await responseListarSeguradora.seguradoras.filter(
				(seguradora) => seguradora.seguradoraStatus === 'A'
			);
			produtos = await responseListarProduto.produtos.filter((item) => item.produtoAtivo === 'A');
			documentosSinistro = await responseListarListBoxPorRotuloDocumentosSinistro.items;
			tiposSinistro = await responseListarTipoSinistro.tiposSinistro.filter(
				(tipoSinistro) => tipoSinistro.tipoSinistroAtivo === 'A'
			);
			situacoesSinistro = await responseListarSituacaoSinistro.situacoesSinistro.filter(
				(situacaoSinistro) => situacaoSinistro.situacaoSinistroAtivo === 'A'
			);

			usuarios = await responseListarUsuario.usuarios;
		}
		return {
			permissoesPagina: permissoesPaginaAux,
			seguradoras: seguradoras,
			produtos: produtos,
			documentosSinistro: documentosSinistro,
			tiposSinistro: tiposSinistro,
			situacoesSinistro: situacoesSinistro,
			usuarios: usuarios
		};
	}

	const getDetailHandler = async (id) => {
		setTituloTela(tituloTela);
		showLoading();
		const dependencias = await loadDependences();

		if (id > 0) {
			const responseDetalhes = await DetalhesSinistro(id);

			const { success, sinistro } = await responseDetalhes;
			if (!success) {
				showMessage('Erro', 'Erro ao buscar os detalhes do registro.');
				setValues({
					...values,
					data: defaultData,
					permissoesPagina: await dependencias.permissoesPagina,
					seguradoras: [],
					produtos: [],
					tiposSinistro: [],
					situacoesSinistro: [],
					usuarios: [],
					documentosSinistro: [],
					produto: {},
				});
			}

			const responseListarArquivosLead = await ListarArquivosLead(sinistro.leadId);

			if (!responseListarArquivosLead.success) {
				showMessage('Erro', responseListarArquivosLead.message);
				responseListarArquivosLead.errors.map((erro) => showMessage('Erro', erro.errorMessage));
				arquivos = [];
			}

			arquivos = await responseListarArquivosLead.arquivos;

			const responseDetalhesProduto = await DetalhesProduto(
				sinistro.produtoId
			);

			if (!responseDetalhesProduto.success) {
				showMessage("Erro", responseDetalhesProduto.message);
				responseDetalhesProduto.errors.map((erro) =>
					showMessage("Erro", erro.errorMessage)
				);
			}
			setValues({
				...values,
				data: sinistro,
				permissoesPagina: await dependencias.permissoesPagina,
				seguradoras: await dependencias.seguradoras,
				produtos: await dependencias.produtos,
				tiposSinistro: await dependencias.tiposSinistro,
				situacoesSinistro: await dependencias.situacoesSinistro,
				usuarios: await dependencias.usuarios,
				documentosSinistro: await dependencias.documentosSinistro,
				produto: await responseDetalhesProduto.produto,
			});
		} else {
			const responseIncluirLeadInterno = await IncluirLeadInterno();

			setValues({
				...values,
				data: {
					...defaultData,
					sinistroId: 0,
					leadId: responseIncluirLeadInterno.leadId
				},
				formDisabled: false,
				seguradoras: await dependencias.seguradoras,
				produtos: await dependencias.produtos,
				tiposSinistro: await dependencias.tiposSinistro,
				situacoesSinistro: await dependencias.situacoesSinistro,
				usuarios: await dependencias.usuarios,
				documentosSinistro: await dependencias.documentosSinistro,
				produto: {},
			});
		}
		hideLoading();
	};

	const editHandler = () => {
		setValues({
			...values,
			formDisabled: false
		});
	};

	const saveHandler = async () => {
		showLoading();
		const responseAtualizar = await ProcessarSinistro(values.data);
		if (!responseAtualizar.success) {
			hideLoading();
			showMessage('Erro', `Erro ao atualizar o registro: ${responseAtualizar.message}`);
			responseAtualizar.errors.map((erro) => showMessage('Erro', erro.errorMessage));
			return;
		}
		resetScreen();
		hideLoading();
		showMessage('Sucesso', 'Registro atualizado.');
		history.goBack();
	};

	const cancelHandler = () => {
		setValues({
			...values,
			data: defaultData,
			formDisabled: true
		});
	};

	const uploadAnexo = async (arquivo) => {
		showLoading('Aguarde, Importando Arquivo.');
		const responseUploadAnexosLead = await UploadAnexosLead(values.data.leadId, arquivo.nome, arquivo.arquivo);
		if (!responseUploadAnexosLead.success) {
			hideLoading();
			showMessage(
				'Erro',
				`Erro ao finalizar o atendimento: ${responseUploadAnexosLead.message
					? responseUploadAnexosLead.message
					: 'Indefinido'}`
			);
			if (responseUploadAnexosLead.errors) {
				responseUploadAnexosLead.errors.map((erro) => showMessage('Erro', erro.errorMessage));
			}
			return;
		}
		arquivos = responseUploadAnexosLead.arquivos;

		hideLoading();
		showMessage('Sucesso', 'Arquivo Importado com sucesso.');
	};

	const removerAnexo = async (arquivoId) => {
		showLoading('Aguarde, Removendo o arquivo.');
		const responseRemoverAnexoLead = await RemoverAnexosLead(values.data.leadId, arquivoId);
		if (!responseRemoverAnexoLead.success) {
			hideLoading();
			showMessage(
				'Erro',
				`Erro ao finalizar o atendimento: ${responseRemoverAnexoLead.message
					? responseRemoverAnexoLead.message
					: 'Indefinido'}`
			);
			if (responseRemoverAnexoLead.errors) {
				responseRemoverAnexoLead.errors.map((erro) => showMessage('Erro', erro.errorMessage));
			}
			return;
		}
		arquivos = await responseRemoverAnexoLead.arquivos;
		hideLoading();
		showMessage('Sucesso', 'Arquivo Removido.');
	};

	const downloadAnexo = async (arquivoId) => {
		showLoading('Aguarde, Baixando Arquivo.');
		await DownloadArquivo(arquivoId);
		hideLoading();
	};

	return (
		<SinistroContext.Provider
			value={{
				values,
				setValues,
				resetScreen,
				searchHandler,
				cancelHandler,
				removeHandler,
				saveHandler,
				getDetailHandler,
				editHandler,
				uploadAnexo,
				removerAnexo,
				downloadAnexo,
				arquivos,
				showMessage
			}}
		>
			{children}
		</SinistroContext.Provider>
	);
};

export default SinistroProvider;
