/* eslint-disable eqeqeq */
import React, { useContext, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { FluxoEventosContext } from '../FluxoEventosContext';
import Formulario from '../../../../../Components/Cadastro/Formulario/Formulario';
import FormularioComplementar from '../../../../../Components/Cadastro/FormularioComplementar/FormularioComplementar';
import DataTable from '../../../../../Components/DataTable/DataTable';
import GroupBox from "../../../../../Components/GroupBox/GroupBox";
const FormularioFluxoEventos = () => {
	const {
		values,
		setValues,
		getDetailHandler,
		removerItem,
		adionarItem
	} = useContext(FluxoEventosContext);
	let { id } = useParams();

	useEffect(() => {
		getDetailHandler(id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function onChangeInput(event) {
		const { name, value } = event.target;
		setValues({
			...values,
			data: {
				...values.data,
				[name]: value
			}
		});
	}

	function onChangeInputItens(event) {
		const { name, value } = event.target;
		setValues({
			...values,
			dataItens: {
				...values.dataItens,
				[name]: value
			}
		});
	}

	function onChangeInputItensDropDown(event) {
		const { name, value, selectedIndex } = event.target;
		const text = event.nativeEvent.target[selectedIndex].text;
		setValues({
			...values,
			dataItens: {
				...values.dataItens,
				[name]: value,
				[`${name}Desc`]: text
			}
		});
	}

	return (
		<Fragment>
			<Formulario context={FluxoEventosContext}>
				<div className="col-12 col-md-5 form-group">
					<label>Nome</label>
					<input
						disabled={values.formDisabled}
						className="form-control"
						type="text"
						name="fluxoEventosNome"
						value={values.data.fluxoEventosNome}
						maxLength={20}
						onChange={onChangeInput}
					/>
				</div>
				<div className="col-12 col-md-2 form-group">
					<label>Intervalo(minutos)</label>
					<input
						disabled={values.formDisabled}
						className="form-control"
						type="number"
						name="fluxoEventosMinutosEntreExecucoes"
						value={values.data.fluxoEventosMinutosEntreExecucoes}
						onChange={onChangeInput}
					/>
				</div>
				<div className="col-12 col-md-2 form-group">
					<label>Status</label>
					<select
						disabled={values.formDisabled}
						className="form-control"
						name="fluxoEventosAtivo"
						value={values.data.fluxoEventosAtivo}
						onChange={onChangeInput}
					>
						<option value="A"> Ativo </option>
						<option value="I"> Inativo </option>
					</select>
				</div>
				<GroupBox titulo="Período de Execução" className="col-12 col-md-12 form-group">
					<div className="col-12 col-md-3 ">
						<label>Inicia Em:</label>
						<input
							className="form-control"
							type="date"
							name="fluxoEventosInicioExecucaoData"
							value={values.data.fluxoEventosInicioExecucaoData}
							onChange={onChangeInput}
						/>
					</div>
					<div className="col-12 col-md-2">
						<label>
							Hora
						</label>
						<input
							type="time"
							className="form-control"
							name="fluxoEventosInicioExecucaoHora"
							value={values.data.fluxoEventosInicioExecucaoHora}
							onChange={onChangeInput}
						/>
					</div>
					<div className="col-12 col-md-2" />
					<div className="col-12 col-md-3 ">
						<label>Termina Em:</label>
						<input
							className="form-control"
							type="date"
							name="fluxoEventosFimExecucaoData"
							value={values.data.fluxoEventosFimExecucaoData}
							onChange={onChangeInput}
						/>
					</div>
					<div className="col-12 col-md-2">
						<label>
							Hora
						</label>
						<input
							type="time"
							className="form-control"
							name="fluxoEventosFimExecucaoHora"
							value={values.data.fluxoEventosFimExecucaoHora}
							onChange={onChangeInput}
						/>
					</div>
				</GroupBox>
				<div className="col-12 col-md-12 form-group">
					<label>Descrição</label>
					<textarea
						disabled={values.formDisabled}
						className="form-control"
						type="text"
						name="fluxoEventosDescricao"
						value={values.data.fluxoEventosDescricao}
						maxLength={500}
						cols="30"
						rows="10"
						onChange={onChangeInput}
					/>
				</div>

			</Formulario>
			<FormularioComplementar titulo="Parâmetros">
				<div className="col-12 col-md-2 form-group">
					<label>Tipo</label>
					<select
						disabled={values.formDisabled}
						className="form-control"
						name="fluxoEventosItensTipo"
						value={values.dataItens.fluxoEventosItensTipo}
						onChange={onChangeInputItens}
					>
						<option className="disabled" />
						<option value="SMS"> SMS </option>
						<option value="EMAIL"> E-MAIL </option>
						<option value="WHATSAPP"> WHATSAPP </option>
					</select>
				</div>
				{values.dataItens.fluxoEventosItensTipo == "SMS" ? <div className="col-12 col-md-3 form-group">
					<label>Template Sms</label>
					<select
						disabled={values.formDisabled}
						className="form-control"
						name="fluxoEventosItensTemplateId"
						value={values.dataItens.fluxoEventosItensTemplateId}
						onChange={onChangeInputItensDropDown}
					>
						<option className="disabled" />
						{values.templatesSms.map((templateSms) => (
							<option key={templateSms.templateSmsId} value={templateSms.templateSmsId}>
								{templateSms.templateSmsNome}
							</option>
						))}
					</select>
				</div> : null}
				{values.dataItens.fluxoEventosItensTipo == "EMAIL" ? <div className="col-12 col-md-3 form-group">
					<label>Template E-Mail</label>
					<select
						disabled={values.formDisabled}
						className="form-control"
						name="fluxoEventosItensTemplateId"
						value={values.dataItens.fluxoEventosItensTemplateId}
						onChange={onChangeInputItensDropDown}
					>
						<option className="disabled" />
						{values.templatesEmail.map((templateEmail) => (
							<option key={templateEmail.templateEmailId} value={templateEmail.templateEmailId}>
								{templateEmail.templateEmailNome}
							</option>
						))}
					</select>
				</div> : null}
				{values.dataItens.fluxoEventosItensTipo == "WHATSAPP" ? <div className="col-12 col-md-3 form-group">
					<label>Template WhatsApp</label>
					<select
						disabled={values.formDisabled}
						className="form-control"
						name="fluxoEventosItensTemplateId"
						value={values.dataItens.fluxoEventosItensTemplateId}
						onChange={onChangeInputItensDropDown}
					>
						<option className="disabled" />
						{values.templatesWhatsAppBlip.map((templateWhatsAppBlip) => (
							<option key={templateWhatsAppBlip.templateWhatsAppBlipId} value={templateWhatsAppBlip.templateWhatsAppBlipId}>
								{templateWhatsAppBlip.templateWhatsAppBlipNome}
							</option>
						))}
					</select>
				</div> : null}

				<div className="col-12 col-md-3 form-group">
					<label>Filtro de Dados</label>
					<select
						disabled={values.formDisabled}
						className="form-control"
						name="filtroDeDadosId"
						value={values.dataItens.filtroDeDadosId}
						onChange={onChangeInputItensDropDown}
					>
						<option className="disabled" />
						{values.filtrosDeDados.map((filtroDeDados) => (
							<option key={filtroDeDados.filtroDeDadosId} value={filtroDeDados.filtroDeDadosId}>
								{filtroDeDados.filtroDeDadosNome}
							</option>
						))}
					</select>
				</div>
				<div className="col-12 col-md-2 form-group">
					<button
						disabled={!values.dataItens.fluxoEventosItensTipo
							|| !values.dataItens.fluxoEventosItensTemplateId
							|| !values.dataItens.filtroDeDadosId}
						style={{ marginTop: '26px' }}
						type="button"
						className="btn btn-default buttonFormularios"
						onClick={adionarItem}
					>
						Adicionar
					</button>
				</div>
				<div className="col-12 col-md-12 form-group">
					<DataTable
						data={values.fluxoEventosItens}
						columnsDefinition={values.columnsDefinitionItens}
						removeHandler={values.formDisabled ? null : removerItem}
					/>
				</div>
			</FormularioComplementar>
		</Fragment>
	);
};

export default FormularioFluxoEventos;
