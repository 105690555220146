import React, { useContext, Fragment, useEffect } from "react";
import { FeriadoContext } from "../FeriadoContext";
import Pesquisar from "../../../../../Components/Cadastro/Pesquisar/Pesquisar";
import TabelaResultados from "../../../../../Components/Cadastro/TabelaResultados/TabelaResultados";

const PesquisarProduto = () => {
  const { values, setValues, resetScreen } = useContext(FeriadoContext);

  useEffect(() => {
    resetScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      searchData: {
        ...values.searchData,
        [name]: value,
      },
    });
  }

  return (
    <Fragment>
      <Pesquisar context={FeriadoContext}>
        <div className="col-12 col-md-3">
          <label>Data</label>
          <input
            className="form-control"
            type="date"
            name="feriadoData"
            value={values.searchData.feriadoData}
            onChange={onChangeInput}
          />
        </div>

        <div className="col-12 col-md-4">
          <label>Nome do Feriado</label>
          <input
            className="form-control"
            type="text"
            name="feriadoDescricao"
            value={values.searchData.feriadoDescricao}
            onChange={onChangeInput}
          />
        </div>
      </Pesquisar>
      <TabelaResultados context={FeriadoContext} />
    </Fragment>
  );
};

export default PesquisarProduto;
