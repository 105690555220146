import React, { memo } from "react";
import "./Condicao.css";
import { Handle } from "react-flow-renderer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default memo(({ data }) => {
  return (
    <>
      <Handle
        type="target"
        position="top"
        style={{ top: 10 }}
      />

      <div className="border border-secondary rounded customNodeCondicaoBody">
        <FontAwesomeIcon
          style={{ color: data.corIcone }}
          className="customNodeCondicaoIcone fa-7x"
          icon={data.icone}
        />
        <div className="row" />
        <div className="customNodeCondicaoText">{data.descricao}</div>
      </div>

      <Handle type="source" position="bottom" id="true" style={{ left: 140 }} />
      <Handle type="source" position="bottom" id="false" style={{ left: 10 }} />
    </>
  );
});
