import React,{useEffect} from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRoute from "../../../Routes/PrivateRoutes";
import EndossoProvider from "./EndossoContext";
import PesquisarEndosso from "./Events/PesquisarEndosso";
import FormularioEndosso from "./Events/FormularioEndosso";
import { LoadMask } from "../../../Utils/mask";

const Index = () => {
  let match = useRouteMatch();
  useEffect(() => {
    LoadMask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <EndossoProvider>
      <div className="container-fluid pl-3 pt-3 pb-3">
        <Switch>
          <PrivateRoute
            exact
            path={`${match.url}`}
            component={PesquisarEndosso}
          />
          <PrivateRoute
            path={`${match.url}/:id`}
            component={FormularioEndosso}
          />
        </Switch>
      </div>
    </EndossoProvider>
  );
};

export default Index;
