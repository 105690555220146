import React, { useContext, Fragment } from "react";
import "./Redirecionar.css";
const Redirecionar = ({ context }) => {
  const { agendaValues, setAgendaValues, redirecionarAgenda } = useContext(
    context
  );

  function onChangeInput(event) {
    const { name, value } = event.target;
    setAgendaValues({
      ...agendaValues,
      data: {
        ...agendaValues.data,
        [name]: value,
      },
    });
  }
  return (
    <Fragment>
      <button
        disabled={agendaValues.data.filaAtendimentos.length === 0}
        type="button"
        className="btn btn-success"
        data-toggle="modal"
        data-target="#modalRedirecionarAgenda"
        data-dismiss="modal"
      >
        {agendaValues.data.filaAtendimentos.length === 0
          ? "Nenhum agendamento selecionado"
          : `Redirecionar ${
              agendaValues.data.filaAtendimentos.length
            } agendamento${
              agendaValues.data.filaAtendimentos.length > 1 ? "s" : ""
            } selecionado${
              agendaValues.data.filaAtendimentos.length > 1 ? "s" : ""
            }.`}
      </button>
      <div
        className="modal fade"
        id="modalRedirecionarAgenda"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalRedirecionarAgendaLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalRedirecionarAgendaLabel">
                Redirecionar Agenda
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body componenteAgendaRedirecionar_modalBody">
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Consultor</label>
                    <select
                      className="form-control"
                      name="usuarioId"
                      value={agendaValues.data.usuarioId}
                      onChange={onChangeInput}
                    >
                      <option className="disabled" value="0"></option>
                      {agendaValues.consultores.map((consultor) => (
                        <option key={consultor.value} value={consultor.value}>
                          {consultor.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Fechar
              </button>
              <button
                disabled={agendaValues.data.usuarioId === "0"}
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={redirecionarAgenda}
              >
                Redirecionar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Redirecionar;
