import React, { Fragment, useContext } from "react";
import "./TabelaResultados.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ConfirmarExclusao from "../ConfirmarExclusao/ConfirmarExclusao";
import Visualizar from "../Visualizar/Visualizar";

const paginationOptions = {
  pageStartIndex: 0,
  paginationSize: 5,
  showTotal: true,
  withFirstAndLast: true,
  alwaysShowAllBtns: true,
  firstPageText: "Primeira",
  prePageText: "Anterior",
  nextPageText: "Proximo",
  lastPageText: "Ultima",
  nextPageTitle: "Proximo",
  prePageTitle: "Anterior",
  firstPageTitle: "Primeira",
  lastPageTitle: "Ultima",
  hideSizePerPage: true,
  hidePageListOnlyOnePage: false,
  paginationTotalRenderer: (from, to, size) => (
    <span>
      Mostrando de {from} até {to} de {size} registros
    </span>
  ),
};

export const TabelaResultados = ({
  context,
  getAditionalEventComponentList = () => {
    return [];
  },
}) => {
  const { values, removeHandler } = useContext(context);

  const actionsTable = (cell, row) => {
    return (
      <div className="tabelasResultados__acoes">
        <Visualizar id={row.Id} />

        {values.permissoesPagina != null &&
        values.permissoesPagina.excluir &&
        removeHandler ? (
          <ConfirmarExclusao removeHandler={removeHandler} id={row.Id} />
        ) : null}

        {getAditionalEventComponentList(row.Id).length > 0
          ? getAditionalEventComponentList(row.Id).map((aditionalEvent) => {
              return (
                <Fragment key={aditionalEvent.id}>
                  {aditionalEvent.component}
                </Fragment>
              );
            })
          : null}
      </div>
    );
  };

  const columnsDefinitionComplete = [
    {
      dataField: "actions",
      text: "Ações",
      headerStyle: { width: `${removeHandler ? 100 : 50}px` },
      isDummyField: true,
      csvExport: false,
      headerAttrs: { width: removeHandler ? 100 : 50 },
      formatter: actionsTable,
    },
    ...values.columnsDefinition,
  ];

  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <div className="default-title">Resultados da Pesquisa</div>
          <div className="card shadow-sm mb-4">
            <div className="row">
              <div className="col-12">
                <BootstrapTable
                  bootstrap4={true}
                  condensed={true}
                  noDataIndication={() => "Nenhum Registro Selecionado"}
                  striped={true}
                  hover={true}
                  pagination={paginationFactory(paginationOptions)}
                  keyField="Id"
                  data={values.resultSearch}
                  columns={columnsDefinitionComplete}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TabelaResultados;
