import React, { useContext, Fragment, useEffect } from "react";
import { DistribuicaoLeadContext } from "../DistribuicaoLeadContext";
import Select from "react-select";
import Pesquisar from "../../../../../Components/Pesquisar/Pesquisar";
import EnviarLeads from "../../../../../Components/DistribuicaoLead/EnviarLeads/EnviarLeads";
import TabelaLeads from "../../../../../Components/DistribuicaoLead/TabelaLeads/TabelaLeads";

const PesquisarDistribuicaoLead = () => {
  const { distribuirLeadsValues, setDistribuirLeadsValues, resetScreen } =
    useContext(DistribuicaoLeadContext);

  useEffect(() => {
    resetScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const styleSelect = { input: () => ({}) };

  const handleChangeCidade = (cidadesSelecionadas) => {
    setDistribuirLeadsValues({
      ...distribuirLeadsValues,
      searchData: {
        ...distribuirLeadsValues.searchData,
        cidadesSelecionadas,
        cidades: cidadesSelecionadas
          ? cidadesSelecionadas.map(
            (cidadeSelecionada) => cidadeSelecionada.value
          )
          : [],
      },
    });
  };
  const handleChangeClassificacao = (classificacoesSelecionadas) => {
    setDistribuirLeadsValues({
      ...distribuirLeadsValues,
      searchData: {
        ...distribuirLeadsValues.searchData,
        classificacoesSelecionadas,
        classificacoes: classificacoesSelecionadas
          ? classificacoesSelecionadas.map(
            (classificacaoSelecionada) => classificacaoSelecionada.value
          )
          : [],
      },
    });
  };

  const handleChangeOrigenLead = (origemLead) => {
    setDistribuirLeadsValues({
      ...distribuirLeadsValues,
      searchData: {
        ...distribuirLeadsValues.searchData,
        origemLead: origemLead,
        origemLeadId: origemLead ? origemLead.value : 0,
      },
    });
  };

  const handleChangeLojasVirtual = (lojaVirtual) => {
    setDistribuirLeadsValues({
      ...distribuirLeadsValues,
      searchData: {
        ...distribuirLeadsValues.searchData,
        lojaVirtual: lojaVirtual,
        lojaVirtualId: lojaVirtual ? lojaVirtual.value : 0,
      },
    });
  };

  function onChangeCheck(event) {
    const { name, checked } = event.target;
    setDistribuirLeadsValues({
      ...distribuirLeadsValues,
      searchData: {
        ...distribuirLeadsValues.searchData,
        [name]: checked ? 1 : 0,
      },
    });
  }
  const handleChangeUsuario = (usuario) => {
    setDistribuirLeadsValues({
      ...distribuirLeadsValues,
      searchData: {
        ...distribuirLeadsValues.searchData,
        usuario,
        usuarioId: usuario ? usuario.value : 0,
      },
    });
  };

  const handleChangeProduto = (produtosSelecionados) => {
    setDistribuirLeadsValues({
      ...distribuirLeadsValues,
      searchData: {
        ...distribuirLeadsValues.searchData,
        produtosSelecionados,
        produtos: produtosSelecionados
          ? produtosSelecionados.map(
            (produtoSelecionado) => produtoSelecionado.value
          )
          : [],
      },
    });
  };

  const handleChangeFornecedor = (fornecedoresSelecionados) => {
    setDistribuirLeadsValues({
      ...distribuirLeadsValues,
      searchData: {
        ...distribuirLeadsValues.searchData,
        fornecedoresSelecionados,
        fornecedores: fornecedoresSelecionados
          ? fornecedoresSelecionados.map(
            (fornecedorSelecionado) => fornecedorSelecionado.value
          )
          : [],
      },
    });
  };

  const handleChangeLandingPage = (landingPagesSelecionadas) => {
    setDistribuirLeadsValues({
      ...distribuirLeadsValues,
      searchData: {
        ...distribuirLeadsValues.searchData,
        landingPagesSelecionadas,
        landingPages: landingPagesSelecionadas
          ? landingPagesSelecionadas.map(
            (landingPageSelecionada) => landingPageSelecionada.value
          )
          : [],
      },
    });
  };

  function onChangeInput(event) {
    const { name, value } = event.target
    setDistribuirLeadsValues({
      ...distribuirLeadsValues,
      searchData: {
        ...distribuirLeadsValues.searchData,
        [name]: value,
      },
    });
  }


  return (
    <Fragment>
      <Pesquisar context={DistribuicaoLeadContext}>
        <div className="col-12 col-md-4">
          <label>Consultor</label>
          <Select
            styles={styleSelect}
            placeholder=""
            value={distribuirLeadsValues.searchData.usuario}
            onChange={handleChangeUsuario}
            options={[{ value: 0, label: 'NENHUM' },
            ...distribuirLeadsValues.consultoresOnline]}

          />
        </div>
        <div className="col-12 col-md-5">
          <label>Cidade</label>
          <Select
            styles={styleSelect}
            placeholder=""
            closeMenuOnSelect={false}
            value={distribuirLeadsValues.searchData.cidadesSelecionadas}
            onChange={handleChangeCidade}
            options={distribuirLeadsValues.cidades}
            isMulti
          />
        </div>
        <div className="col-12 col-md-3">
          <label>Classificação</label>
          <Select
            styles={styleSelect}
            placeholder=""
            closeMenuOnSelect={false}
            value={distribuirLeadsValues.searchData.classificacoesSelecionadas}
            onChange={handleChangeClassificacao}
            options={distribuirLeadsValues.classificacoes}
            isMulti
          />
        </div>
        <div className="col-12 col-md-4">
          <label>Produto</label>
          <Select
            styles={styleSelect}
            placeholder=""
            closeMenuOnSelect={false}
            value={distribuirLeadsValues.searchData.produtosSelecionadas}
            onChange={handleChangeProduto}
            options={distribuirLeadsValues.produtos}
            isMulti
          />
        </div>
        <div className="col-12 col-md-4">
          <label>Fornecedor</label>
          <Select
            styles={styleSelect}
            placeholder=""
            closeMenuOnSelect={false}
            value={distribuirLeadsValues.searchData.fornecedoresSelecionadas}
            onChange={handleChangeFornecedor}
            options={distribuirLeadsValues.fornecedores}
            isMulti
          />
        </div>
        <div className="col-12 col-md-4">
          <label>LandingPage</label>
          <Select
            styles={styleSelect}
            placeholder=""
            closeMenuOnSelect={false}
            value={distribuirLeadsValues.searchData.landingPagesSelecionadas}
            onChange={handleChangeLandingPage}
            options={distribuirLeadsValues.landingPages}
            isMulti
          />
        </div>
        <div className="col-12 col-md-4">
          <label>Origem</label>
          <Select
            styles={styleSelect}
            placeholder=""
            value={distribuirLeadsValues.searchData.origemLead}
            onChange={handleChangeOrigenLead}
            options={distribuirLeadsValues.origensLead}
          />
        </div>
        <div className="col-12 col-md-3">
          <label>Lojas Virtual</label>
          <Select
            styles={styleSelect}
            placeholder=""
            value={distribuirLeadsValues.searchData.lojaVirtual}
            onChange={handleChangeLojasVirtual}
            options={distribuirLeadsValues.lojasVirtual}
          />
        </div>
        <div className="col-12 col-md-2">
          <label>Mês/Ano Produção</label>
          <input
            className="css-yk16xz-control mesano"
            style={{ height: 42 }}
            type="text"
            name="mesAno"
            value={distribuirLeadsValues.searchData.mesAno}
            onChange={onChangeInput}
          />
        </div>

        <div className="col-12 col-md-2 d-flex ml-2">
          <label style={{ width: 100 }}>Somente Renovação?</label>
          <div>
            <input
              type="checkbox"
              name="listarSomenteRenovacao"
              checked={distribuirLeadsValues.searchData.listarSomenteRenovacao}
              onChange={onChangeCheck}
            />
          </div>
        </div>
      </Pesquisar>
      <div className="row">
        <TabelaLeads context={DistribuicaoLeadContext} />
        <EnviarLeads context={DistribuicaoLeadContext} />
      </div>
    </Fragment>
  );
};

export default PesquisarDistribuicaoLead;
