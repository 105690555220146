import React, { Fragment, useContext } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Excluir from "./Excluir/Excluir";
import Download from "./Download/Download";

export const DataTableAnexos = ({ disabled = false, context }) => {
  const { arquivos } = useContext(context);

  const actionsTable = (cell, row) => {
    return (
      <div className="tabelasResultados__acoes">
        <Excluir context={context} arquivoId={row.arquivoId} />
        <Download context={context} arquivoId={row.arquivoId} />
      </div>
    );
  };

  let columnsDefinition = [
    {
      dataField: "actions",
      text: "Ações",
      headerStyle: { width: "60px" },
      isDummyField: true,
      csvExport: false,
      headerAttrs: { width: 100 },
      formatter: actionsTable,
      hidden: disabled,
    },
    {
      text: "arquivoId",
      dataField: "arquivoId",
      hidden: true,
    },
    {
      text: "Nome",
      dataField: "nome",
      headerStyle: { width: "200px" },
    },
    {
      text: "Usúario",
      dataField: "usuarioNome",
      headerStyle: { width: "200px" },
    },
    {
      text: "Arquivo",
      dataField: "nomeArquivo",
      headerStyle: { width: "350px" },
    },
  ];

  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <BootstrapTable
            bootstrap4={true}
            condensed={true}
            noDataIndication={() => "Nenhum Registro Selecionado"}
            striped={true}
            hover={true}
            keyField="arquivoId"
            data={arquivos}
            columns={columnsDefinition}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default DataTableAnexos;
