import React, { Fragment } from "react";
import "./SideBarTopList.css";
import SideBarLink from "../SideBarSubLink/SideBarSubLink";
const images = require.context("../../../assets/img/icones/sidebar", true);

const SideBarTopList = ({ id, modulo, menu }) => {
  return (
    <div>
      <div
        href={`#sideBarTopList${menu.id}`}
        className="list-group-item collapsed"
        data-toggle="collapse"
        data-parent="#sidebar"
        aria-expanded="false"
        role="button"
      >
        {menu.icone ? (
          <img
            src={images(`./${menu.icone}`)}
            alt=""
            className="nav-link__icone"
          />
        ) : null}
        {menu.nome}
      </div>
      <div className="collapse" id={`sideBarTopList${menu.id}`}>
        {menu.subMenus.map((subMenu) => (
          <SideBarSubList
            id={`sideBarSubListMenu${menu.id}`}
            key={subMenu.id}
            menu={subMenu}
            modulo={modulo}
          />
        ))}
      </div>
    </div>
  );
};

const SideBarSubList = ({ id, menu }) => {
  return (
    <Fragment>
      {menu.subMenus.length === 0 ? (
        <SideBarLink menu={menu} id={id} />
      ) : (
        <Fragment>
          <div
            href={`#sideBarSubList${id + menu.id}`}
            role="button"
            className="list-group-item"
            data-toggle="collapse"
            data-parent="#sidebar"
            aria-expanded="false"
          >
            {menu.nome}
          </div>
          <div className="collapse" id={`sideBarSubList${id + menu.id}`}>
            {menu.subMenus.map((subMenu) => (
              <SideBarSubList
                id={menu.id}
                key={subMenu.id}
                menu={subMenu}
                dataParent={`#sideBarSubList${id + menu.id}`}
              />
            ))}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default SideBarTopList;
