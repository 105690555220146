import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "Agendamento";
export const ListarAgendamento = ({
  dataAgendamentoDe,
  dataAgendamentoAte,
  produtoId,
  equipeId,
  usuarioId,
}) =>
  RequestWebApi.get(`/${controller}/ListarAgendamento`, {
    params: {
      dataAgendamentoDe,
      dataAgendamentoAte,
      produtoId,
      equipeId,
      usuarioId,
    },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const agendamentos = resultData;
      return { success, agendamentos };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { agendamentos: [] };
    });

export const AlterarAgendamento = (
  agendamentoId,
  agendamentoDataHoraRetorno,
  tipoAgendamentoId
) =>
  RequestWebApi.put(`/${controller}/AlterarAgendamento`, {
    agendamentoId,
    agendamentoDataHoraRetorno,
    tipoAgendamentoId,
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const PodeRedirecionarAgendamento = () =>
  RequestWebApi.get(`/${controller}/PodeRedirecionarAgendamento`)
    .then(function (response) {
      const { resultData, success } = response.data;
      const podeRedirecionar = resultData;
      return { success, podeRedirecionar };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { podeRedirecionar: false };
    });
