import React, { useContext, Fragment, useEffect } from "react";
import { CarsystemApiConsultaHdiContext } from "../CarsystemApiConsultaHdiContext";
import Pesquisar from "../../../../../Components/Cadastro/Pesquisar/Pesquisar";
import DataTableDefault from "../../../../../Components/DataTableDefault/DataTableDefault";
import GroupBox from "../../../../../Components/GroupBox/GroupBox";
import FormularioComplementar from "../../../../../Components/Cadastro/FormularioComplementar/FormularioComplementar";

const PesquisarCarsystemApiConsultaHdi = () => {
  const { values, setValues, resetScreen, saveHandler, onChangeInputPadraoGrid } = useContext(CarsystemApiConsultaHdiContext);

  useEffect(() => {
    resetScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      searchData: {
        ...values.searchData,
        [name]: value,
      },
    });
  }

  function onChangeCheck(event) {
    const { name, checked } = event.target;
    setValues({
      ...values,
      searchData: {
        ...values.searchData,
        [name]: checked,
      },
    });
  }


  function rowStyle(row, index) {
    let estiloPadrao = {
      fontSize: "11px"
    };
    if (row.foiModificado) {
      return {
        ...estiloPadrao,
        backgroundColor: "#fe6633"

      };
    } else {
      return {
        ...estiloPadrao
      };

    }

  }


  return (
    <Fragment>
      <Pesquisar exibirBotaoNovo={false} context={CarsystemApiConsultaHdiContext}>
        <GroupBox titulo="Data Confirmação" className="col-12 col-md-4 form-group">
          <div className="col-12 col-md-6 ">
            <label>De:</label>
            <input
              className="form-control"
              type="date"
              name="carsystemApiConsultaHdiDataConfirmacaoDe"
              value={values.searchData.carsystemApiConsultaHdiDataConfirmacaoDe}
              onChange={onChangeInput}
            />
          </div>
          <div className="col-12 col-md-6 ">
            <label>Até:</label>
            <input
              className="form-control"
              type="date"
              name="carsystemApiConsultaHdiDataConfirmacaoAte"
              value={values.searchData.carsystemApiConsultaHdiDataConfirmacaoAte}
              onChange={onChangeInput}
            />
          </div>
        </GroupBox>

        <div className="col-12 col-md-2 form-group">
          <label>Uf:</label>
          <select
            className="form-control"
            name="carsystemApiConsultaHdiUf"
            value={values.searchData.carsystemApiConsultaHdiUf}
            onChange={onChangeInput}
          >
            <option className="disabled" value=""></option>
            {values.ufs.map((uf) => (
              <option
                key={uf}
                value={uf}
              >
                {uf}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-2 form-group">
          <label>Cidade:</label>
          <select
            className="form-control"
            name="carsystemApiConsultaHdiCidade"
            value={values.searchData.carsystemApiConsultaHdiCidade}
            onChange={onChangeInput}
          >
            <option className="disabled" value=""></option>
            {values.cidades.map((cidade) => (
              <option
                key={cidade}
                value={cidade}
              >
                {cidade}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-2 form-group">
          <label>Utilização:</label>
          <select
            className="form-control"
            name="carsystemApiConsultaHdiUtilizacao"
            value={values.searchData.carsystemApiConsultaHdiUtilizacao}
            onChange={onChangeInput}
          >
            <option className="disabled" value=""></option>
            {values.utilizacoes.map((utilizacao) => (
              <option
                key={utilizacao}
                value={utilizacao}
              >
                {utilizacao}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-2 d-flex form-group">
          <label style={{ width: 100 }}>Somente Chassi Com Erro?</label>
          <div>
            <input
              type="checkbox"
              name="somenteChassisComErro"
              checked={values.searchData.somenteChassisComErro}
              onChange={onChangeCheck}
            />
          </div>
        </div>
      </Pesquisar>

      <FormularioComplementar>
        <div className="col-12 text-right" style={{ marginBottom: 20 }}>
          <button
            disabled={values.itensModificados.length === 0}
            type="button"
            className={`btn ${values.itensModificados.length === 0
              ? "btn-secondary"
              : "btn-primary"
              } `}
            onClick={() => saveHandler()}
          >
            {values.itensModificados.length === 0
              ? "Nenhum item modificado"
              : `Salvar ${values.itensModificados.length} ${values.itensModificados.length > 1 ? "itens" : "item"
              } Modificado${values.itensModificados.length > 1 ? "s" : ""}`}
          </button>
        </div>
      </FormularioComplementar>

      <DataTableDefault
        keyField="id"
        data={values.resultSearch}
        rowStyle={(row, index) => rowStyle(row, index)}
        columns={
          [
            ...values.columnsDefinition,
            {
              text: "Utilização",
              dataField: "carsystemApiConsultaHdiUtilizacao",
              headerStyle: { width: "120px" },
              sort: true,
              formatter: (cell, row) => {
                return (
                  <select
                    className="form-control"
                    name="carsystemApiConsultaHdiUtilizacao"
                    id={"carsystemApiConsultaHdiUtilizacao_" + row.carsystemApiConsultaHdiId}
                    value={row.carsystemApiConsultaHdiUtilizacao}
                    onChange={onChangeInputPadraoGrid}
                  >
                    <option className="disabled" value=""></option>
                    {values.utilizacoes.map((utilizacao) => (
                      <option
                        key={utilizacao}
                        value={utilizacao}
                      >
                        {utilizacao}
                      </option>
                    ))}
                  </select>
                )
              },
            },
            {
              text: "Chassi",
              dataField: "carsystemApiConsultaHdiChassi",
              headerStyle: { width: "200px" },
              sort: true,
              formatter: (cell, row) => {
                return (
                  <input
                    className="form-control"
                    type="text"
                    id={"carsystemApiConsultaHdiChassi_" + row.carsystemApiConsultaHdiId}
                    name="carsystemApiConsultaHdiChassi"
                    value={row.carsystemApiConsultaHdiChassi}
                    onChange={onChangeInputPadraoGrid}
                  />

                )
              },
            }
          ]}
      />

    </Fragment>
  );
};

export default PesquisarCarsystemApiConsultaHdi;
