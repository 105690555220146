import React from "react";
import AgendaProvider from "./AgendaContext";
import Agenda from "./Events/Agenda";

const Index = () => {
  return (
    <AgendaProvider>
      <div className="container-fluid pl-3 pt-3 pb-3">
        <Agenda />
      </div>
    </AgendaProvider>
  );
};

export default Index;
