import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRoute from "../../../../Routes/PrivateRoutes";
import BaseProspectProvider from "./BaseProspectContext";
import PesquisarBaseProspect from "./Events/PesquisarBaseProspect";
import FormularioBaseProspect from "./Events/FormularioBaseProspect";

const Index = () => {
  let match = useRouteMatch();
  return (
    <BaseProspectProvider>
      <div className="container-fluid pl-3 pt-3 pb-3">
        <Switch>
          <PrivateRoute
            exact
            path={`${match.url}`}
            component={PesquisarBaseProspect}
          />
          <PrivateRoute
            path={`${match.url}/:id`}
            component={FormularioBaseProspect}
          />
        </Switch>
      </div>
    </BaseProspectProvider>
  );
};

export default Index;
