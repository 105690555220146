import React, { useContext, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { SegmentacaoPublicoContext } from "../SegmentacaoPublicoContext";
import Formulario from "../../../../../Components/Cadastro/Formulario/Formulario";
import TabelaProspect from "../../../../../Components/SegmentacaoPublico/ListaPublico/ListaPublico";
import Publico from "../../../../../Components/SegmentacaoPublico/Publico/Publico";
import Resultado from "../../../../../Components/SegmentacaoPublico/Resultado/Resultado";

const FormularioSegmentacaoPublico = () => {
  const { values, setValues, getDetailHandler } = useContext(
    SegmentacaoPublicoContext
  );
  let { id } = useParams();

  useEffect(() => {
    getDetailHandler(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      data: {
        ...values.data,
        [name]: value,
      },
    });
  }

  return (
    <Fragment>
      <Formulario context={SegmentacaoPublicoContext}>
        <div className="col-12 col-md-6 form-group">
          <label>
            Nome da Segmentação<span className="campoObratorio">*</span>
          </label>
          <input
            disabled={values.formDisabled}
            className="form-control"
            type="text"
            maxLength="50"
            name="segmentacaoPublicoNome"
            value={values.data.segmentacaoPublicoNome}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-3 form-group">
          <label>Produto</label>
          <select
            disabled={values.formDisabled}
            className="form-control"
            name="produtoId"
            value={values.data.produtoId}
            onChange={onChangeInput}
          >
            <option className="disabled" value="0"></option>
            {values.produtos.map((produto) => (
              <option key={produto.produtoId} value={produto.produtoId}>
                {produto.produtoNome}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-3 form-group">
          <label>
            Status<span className="campoObratorio">*</span>
          </label>
          <select
            disabled={values.formDisabled}
            className="form-control"
            name="segmentacaoPublicoStatus"
            value={values.data.segmentacaoPublicoStatus}
            onChange={onChangeInput}
          >
            <option className="disabled" value=""></option>
            {values.statusList.map((status) => (
              <option key={status.key} value={status.key}>
                {status.value}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-12 form-group">
          <label>Descrição</label>
          <textarea
            disabled={values.formDisabled}
            rows="4"
            className="form-control"
            name="segmentacaoPublicoDescr"
            value={values.data.segmentacaoPublicoDescr}
            onChange={onChangeInput}
          ></textarea>
        </div>

        {values.data.usuarioNomeCriacao ? (
          <Fragment>
            <div className="col-12 col-md-4">
              <label>
                Criação : {` ${values.data.segmentacaoPublicoDataHoraCriacao}`}
              </label>
            </div>

            <div className="col-12 col-md-7 ">
              <label>Usuário : {` ${values.data.usuarioNomeCriacao}`}</label>
            </div>
          </Fragment>
        ) : null}
        {values.data.usuarioNomeAlteracao ? (
          <Fragment>
            <div className="col-12 col-md-4 ">
              <label>
                Ultima Alteração :{" "}
                {` ${values.data.segmentacaoPublicoDataHoraUltAlter}`}
              </label>
            </div>

            <div className="col-12 col-md-7 ">
              <label>Usuário : {` ${values.data.usuarioNomeAlteracao}`}</label>
            </div>
          </Fragment>
        ) : null}
      </Formulario>
      <div className="col-12">
        <div className="row">
          <Publico context={SegmentacaoPublicoContext} />
          <TabelaProspect context={SegmentacaoPublicoContext} />
          <Resultado context={SegmentacaoPublicoContext} />
        </div>
      </div>
    </Fragment>
  );
};

export default FormularioSegmentacaoPublico;
