/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext } from "react";
import AppContext from "../../../../Store/AppContext";
import {
  Listar as ListarOrigemLead,
  Detalhes as DetalhesOrigemLead,
  Remover as RemoverOrigemLead,
  Incluir as IncluirOrigemLead,
  Atualizar as AtualizarOrigemLead,
} from "../../../../Services/OrigemLead";
import { useHistory } from "react-router-dom";
import { validarPermissoesPagina } from "../../../../Utils/validarPermissoesPagina";
export const OrigemLeadContext = createContext();

const tituloTela = "Origem Leads";

const defaultData = {
  origemLeadId: 0,
  origemLeadCod: "",
  origemLeadNome: "",
  origemLeadIcone: "",
  origemLeadAtivo: "A",
};

const searchData = {
  origemLeadCod: "",
  origemLeadNome: "",
};

const columnsDefinition = [
  {
    text: "Id",
    dataField: "origemLeadId",
    hidden: true,
  },
  {
    text: "Código",
    dataField: "origemLeadCod",
    headerStyle: { width: "100px" },
    sort: true,
  },
  {
    text: "Origem dos Leads",
    dataField: "origemLeadNome",
    headerStyle: { width: "450px" },
    sort: true,
  },
  {
    text: "Status",
    dataField: "origemLeadAtivo",
    headerStyle: { width: "80px" },
    sort: true,
    formatter: (cell, row) => {
      return cell === "A" ? (
        <span className="badge badge-success">Ativo</span>
      ) : (
        <span className="badge badge-danger">Inátivo</span>
      );
    },
  },
];

function initialState() {
  return {
    searchData: searchData,
    resultSearch: [],
    columnsDefinition: columnsDefinition,
    data: defaultData,
    formDisabled: true,
  };
}

const OrigemLeadProvider = ({ children }) => {
  const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(
    AppContext
  );
  const [values, setValues] = useState(initialState());
  let history = useHistory();

  async function loadDependences() {
    const permissoesPagina = validarPermissoesPagina();
    return { permissoesPagina };
  }

  const resetScreen = () => {
    showLoading();
    async function carregarDependencias() {
      const dependencias = await loadDependences();
      setTituloTela(tituloTela);
      setValues({
        ...initialState(),
        permissoesPagina: await dependencias.permissoesPagina,
      });
      hideLoading();
    }
    carregarDependencias();
  };


  const searchHandler = async () => {
    showLoading();
    const responseListar = await ListarOrigemLead(values.searchData);

    if (!responseListar.success) {
      showMessage("Erro", "Erro Inesperado durante a pesquisa.");
      hideLoading();
      return;
    }

    const resultSearchData = await responseListar.origensLead;

    resultSearchData.map((data) => (data.Id = data.origemLeadId));

    setValues({
      ...values,
      resultSearch: resultSearchData,
    });

    if (resultSearchData.length === 0) {
      showMessage("Alerta", "Nenhum registro encontrado.");
    }
    hideLoading();
  };

  const removeHandler = async (id) => {
    showLoading();

    const responseRemover = await RemoverOrigemLead(id);

    if (!responseRemover.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro durante a exclusão: ${responseRemover.message}`
      );
      responseRemover.errors.map((erro) =>
        showMessage("Erro", erro.errorMessage)
      );
      return;
    }
    searchHandler();
    hideLoading();
    showMessage("Sucesso", "Registro excluido.");
  };

  const getDetailHandler = async (id) => {
    showLoading();
    const dependencias = await loadDependences();

    if (id > 0) {
      const responseDetalhes = await DetalhesOrigemLead(id);

      const { success, origemLead } = await responseDetalhes;

      if (!success) {
        showMessage("Erro", "Erro ao buscar os detalhes do registro.");
        setValues({
          ...values,
          data: defaultData,
          permissoesPagina: await dependencias.permissoesPagina,
        });
      }

      setValues({
        ...values,
        data: origemLead,
        permissoesPagina: await dependencias.permissoesPagina,
      });
    } else {
      setValues({
        ...values,
        data: {
          ...defaultData,
          origemLeadId: 0,
        },
        permissoesPagina: await dependencias.permissoesPagina,
        formDisabled: false,
      });
    }
    hideLoading();
  };

  const createData = async () => {
    showLoading();
    const responseIncluir = await IncluirOrigemLead(values.data);
    if (!responseIncluir.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao criar o registro: ${responseIncluir.message}`
      );
      responseIncluir.errors.map((erro) =>
        showMessage("Erro", erro.errorMessage)
      );
      return;
    }

    resetScreen();
    hideLoading();
    showMessage("Sucesso", "Registro criado.");
    history.goBack();
  };

  const editHandler = () => {
    setValues({
      ...values,
      formDisabled: false,
    });
  };

  const updateData = async () => {
    showLoading();
    const responseAtualizar = await AtualizarOrigemLead(values.data);
    if (!responseAtualizar.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao atualizar o registro: ${responseAtualizar.message}`
      );
      responseAtualizar.errors.map((erro) =>
        showMessage("Erro", erro.errorMessage)
      );
      return;
    }
    resetScreen();
    hideLoading();
    showMessage("Sucesso", "Registro atualizado.");
    history.goBack();
  };

  const saveHandler = async () => {
    if (values.data.origemLeadId === 0) {
      await createData();
    } else {
      await updateData();
    }
  };

  const cancelHandler = () => {
    setValues({
      ...values,
      data: defaultData,
      formDisabled: true,
    });
  };

  return (
    <OrigemLeadContext.Provider
      value={{
        values,
        setValues,
        resetScreen,
        searchHandler,
        cancelHandler,
        removeHandler,
        saveHandler,
        getDetailHandler,
        editHandler,
      }}
    >
      {children}
    </OrigemLeadContext.Provider>
  );
};

export default OrigemLeadProvider;
