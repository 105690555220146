import React, { Fragment } from "react";
import IndicadoPor from "../../../Components/Lead/IndicadoPor/IndicadoPor"
// import Termometro from "../../../Components/Graficos/Termometro/Termometro";

const DetalhesLead = ({
  temperaturaValor = 0,
  temperaturaDescricao = "",
  fornecedorLead = "",
  landingPageNome = "",
  landingPageUrl = "",
  tipoLead = "",
  origemLeadNome = "",
  leadDataInteracao = "",
  leadClassificacao = "",
  leadIdRenovacao = 0,
  produto = {},
  keyWords = [],
  lojaVirtualNome = "",
  lojaVirtualImagem = ""
}) => {
  let index = 0;
  return (
    <Fragment>
      <div className="default-title">Detalhes do Lead</div>
      <div className="card shadow-sm mb-4">
        <div className="row align-items-start">
          {/* <div className="col-12 col-md-4 text-center">
            <Termometro
              id="termometroDetalhesLead"
              minValue={0}
              maxValue={5}
              currentValue={temperaturaValor}
            />
          </div> */}
          <div className="col">
            <div className="text-danger font-weight-bold text-uppercase">
              {temperaturaDescricao}
            </div>
            {leadIdRenovacao || leadIdRenovacao > 0 ? (
              <div className="font-weight-bold text-orange">
                RENOVAÇÃO
              </div>
            ) : null}
            <div>{fornecedorLead}</div>
            <div>{landingPageUrl}</div>
            <div>{landingPageNome}</div>
            <div>{tipoLead}</div>
            <div>{origemLeadNome}</div>
            <div>{produto.produtoNome}</div>
            <div>
              {keyWords.map((keyword) => {
                index = index + 1;
                return (
                  <span key={index} className="badge btn-default">
                    {keyword}
                  </span>
                );
              })}
            </div>
            <div>{leadClassificacao}</div>
            <div>Interação em {leadDataInteracao}</div>
            {lojaVirtualNome || lojaVirtualImagem ?
              <Fragment>
                <hr />
                <IndicadoPor nome={lojaVirtualNome} imagem={lojaVirtualImagem} />
              </Fragment> : null
            }
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DetalhesLead;
