import React, {useContext } from "react";
import "./Index.css";
import AppContext from "../../Store/AppContext";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const tituloTela = "Sem Acesso";

const Index = () => {
  const { setTituloTela } = useContext(AppContext);
  setTituloTela(tituloTela);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card shadow-sm mb-4">
            <div className="row mb-3">
              <div className="col-12 text-center">
                <i className="semAcessoIcon">
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                </i>
              </div>
              <div className="col-12 text-center">
                <div className="semAcessoText">
                  Você não tem permissão para acessar esse recurso! consulte seu
                  administrador de acessos.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
