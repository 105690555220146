import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRoute from "../../../../Routes/PrivateRoutes";
import CarsystemApiConsultaHdiProvider from "./CarsystemApiConsultaHdiContext";
import PesquisarCarsystemApiConsultaHdi from "./Events/PesquisarCarsystemApiConsultaHdi";

const Index = () => {
  let match = useRouteMatch();
  return (
    <CarsystemApiConsultaHdiProvider>
      <div className="container-fluid pl-3 pt-3 pb-3">
        <Switch>
          <PrivateRoute
            exact
            path={`${match.url}`}
            component={PesquisarCarsystemApiConsultaHdi}
          />
        </Switch>
      </div>
    </CarsystemApiConsultaHdiProvider>
  );
};

export default Index;
