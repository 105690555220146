import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "Produto";
export const Listar = (produto) =>
  RequestWebApi.get(`/${controller}/Listar`, {
    params: { ...produto },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const produtos = resultData;
      return { success, produtos };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { produtos: [] };
    });

export const Detalhes = (produtoId) =>
  RequestWebApi.get(`/${controller}/Detalhes`, {
    params: { produtoId: produtoId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const produto = resultData;
      return { success, produto };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, produto: {} };
    });

export const Incluir = (produto) =>
  RequestWebApi.post(`/${controller}/Incluir`, produto)
    .then(function (response) {
      const { resultData, success } = response.data;
      const novoProduto = resultData;
      return { success, novoProduto };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Atualizar = (produto) =>
  RequestWebApi.put(`/${controller}/Atualizar`, produto)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (produtoId) =>
  RequestWebApi.delete(`/${controller}/Remover`, {
    params: { produtoId: produtoId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });
