import React, { Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import './DataTableDefault.css';

const paginationOptions = {
	pageStartIndex: 0,
	paginationSize: 5,
	showTotal: true,
	withFirstAndLast: true,
	alwaysShowAllBtns: true,
	firstPageText: 'Primeira',
	prePageText: 'Anterior',
	nextPageText: 'Proximo',
	lastPageText: 'Ultima',
	nextPageTitle: 'Proximo',
	prePageTitle: 'Anterior',
	firstPageTitle: 'Primeira',
	lastPageTitle: 'Ultima',
	hideSizePerPage: true,
	hidePageListOnlyOnePage: true,
	paginationTotalRenderer: (from, to, size) => (
		<span>
			Mostrando de {from} até {to} de {size} registros
		</span>
	)
};

export const DataTableDefault = ({ keyField, data, columns, rowStyle = null, removerPaginacao = false }) => {
	return (
		<Fragment>
			<div className="row">
				<div className="col-12">
					<BootstrapTable
						bootstrap4={true}
						condensed={true}
						noDataIndication={() => 'Nenhum Registro Selecionado'}
						striped={true}
						hover={true}
						keyField={keyField}
						data={data}
						columns={columns}
						rowStyle={rowStyle ? rowStyle : { fontSize: '11px' }}
						pagination={removerPaginacao ? null : paginationFactory(paginationOptions)}
					/>
				</div>
			</div>
		</Fragment>
	);
};

export default DataTableDefault;
