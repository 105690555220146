import React, { useContext, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { FiltroDeDadosContext } from "../FiltroDeDadosContext";
import Formulario from "../../../../../Components/Cadastro/Formulario/Formulario";

const FormularioFiltroDeDados = () => {
  const { values, setValues, getDetailHandler } = useContext(
    FiltroDeDadosContext
  );
  let { id } = useParams();

  useEffect(() => {
    getDetailHandler(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      data: {
        ...values.data,
        [name]: value,
      },
    });
  }

  return (
    <Fragment>
      <Formulario context={FiltroDeDadosContext}>

        <div className="col-12 col-md-9 form-group">
          <label>
            Nome<span className="campoObratorio">*</span>
          </label>
          <input
            disabled={values.formDisabled}
            className="form-control"
            type="text"
            maxLength="50"
            name="filtroDeDadosNome"
            value={values.data.filtroDeDadosNome}
            onChange={onChangeInput}
          />
        </div>

        <div className="col-12 col-md-2 form-group">
          <label>
            Limite Registros<span className="campoObratorio">*</span>
          </label>
          <input
            disabled={values.formDisabled}
            className="form-control"
            type="number"
            name="filtroDeDadosLimiteRegistrosRetornados"
            value={values.data.filtroDeDadosLimiteRegistrosRetornados}
            onChange={onChangeInput}
          />
        </div>

        <div className="col-12 form-group">
          <label>
            Filtro <span className="campoObratorio">*</span>
          </label>
          <textarea
            disabled={values.formDisabled}
            className="form-control"
            type="text"
            cols="30"
            rows="10"
            name="filtroDeDadosFiltro"
            value={values.data.filtroDeDadosFiltro}
            onChange={onChangeInput}
          />
        </div>

      </Formulario>
    </Fragment>
  );
};

export default FormularioFiltroDeDados;
