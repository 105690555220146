import React, { useContext, Fragment, useEffect, useState } from 'react';
import $ from 'jquery';
import ModalCotacaoAutoRastreador, {
	showModalCotacaoAutoRastreador,
	buscarCotacoes
} from './ModalCotacao/ModalCotacaoAutoRastreador';
import GroupBox from './../../../GroupBox/GroupBox';
import {
	ListarFabricante,
	ListarFipeSuhaiPorFabricante,
	BuscarParametrosTipoVeiculoSuhai
} from '../../../../Services/FipeSuhai';
import { Listar as ListarDut } from '../../../../Services/Dut';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

const autoRastreadorInitialState = {
	leadId: '',
	leadAutoRastreadorPlaca: '',
	leadAutoRastreadorFabricante: '',
	leadAutoRastreadorModelo: '',
	leadAutoRastreadorAnoModelo: '',
	leadAutoRastreadorAnoFabricacao: '',
	leadAutoRastreadorCodFipe: '',
	leadAutoRastreadorZeroKm: '',
	leadAutoRastreadorTipoUtilizacaoDut: '',
	leadAutoRastreadorTipoUtilizacao: '',
	leadAutoRastreadorCepPernoite: '',
	leadAutoRastreadorPossuiOutroVeiculo: '',
	leadAutoRastreadorCpfPrincCondutor: '',
	leadAutoRastreadorNomePrincCondutor: '',
	leadAutoRastreadorDataNascPrincCondutor: '',
	leadAutoRastreadorSexoPrincCondutor: '',
	leadAutoRastreadorEstadoCivilPrincCondutor: '',
	leadAutoRastreadorPergunta01: '',
	leadAutoRastreadorPergunta02: '',
	leadAutoRastreadorPergunta03: '',
	leadAutoRastreadorPergunta04: '',
	leadAutoRastreadorPercDesconto: '',
	leadAutoRastreadorNumChassi: '',
	leadAutoRastreadorRenavam: '',
	leadAutoRastreadorCor: '',
	leadAutoRastreadorCombustivel: '',
	leadAutoRastreadorClasseBonus: 0,
	leadAutoRastreadorValorComissao: 0,
	leadAutoRastreadorValorDanosCorporais: 0,
	leadAutoRastreadorValorDanosMateriais: 0,
	leadAutoRastreadorValorDanosMorais: 0
};

let comissaoInitialValue = {
	codigo: '',
	descricao: '',
	percentual: 0
};

const initialValue = {
	fabricantes: [],
	tabelaFipeSuhai: [],
	listaDut: [],
	parametrosTipoVeiculoSuhai: {
		exibirPergunta01: false,
		exibirPergunta02: false,
		exibirPergunta03: false,
		exibirPergunta04: false,
		tabelasComissao: [],
		tabelaComissaoMinima: comissaoInitialValue,
		tabelaComissaoMaxima: comissaoInitialValue
	}
};

export const buscarInitialStateFormularioAutoRastreador = () => {
	return autoRastreadorInitialState;
};
const FormularioAutoRastreador = ({ context }) => {
	const { atendimentoValues, setAtendimentoValues, showMessage, showLoading, hideLoading } = useContext(context);
	const [formularioAutoRastreadorValues, SetFormularioAutoRastreadorValues] = useState(initialValue);
	useEffect(() => {
		$(function () {
			$('[data-toggle="tooltip"]').tooltip();
		});

		showLoading('Carregando Formulario.');
		async function carregarDependencias() {
			const listarFabricanteResult = await ListarFabricante();

			let tabelaFipeSuhai = [];
			if (atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorFabricante) {
				const listarFipeSuhaiPorFabricanteResult = await ListarFipeSuhaiPorFabricante(
					atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorFabricante
				);
				if (listarFipeSuhaiPorFabricanteResult.success) {
					tabelaFipeSuhai = listarFipeSuhaiPorFabricanteResult.tabelaFipeSuhai;
				}
			}

			let listaDut = [];
			let parametrosTipoVeiculoSuhai = initialValue.parametrosTipoVeiculoSuhai;
			if (
				tabelaFipeSuhai.length > 0 &&
				atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorModelo
			) {
				let modelo = atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorModelo;
				let fipeSuhaiDetalhes = tabelaFipeSuhai.find((x) => x.fipeSuhaiDescrModelo === modelo);
				let tipoVeiculoId = fipeSuhaiDetalhes.tipoVeiculoId;
				if (tipoVeiculoId && tipoVeiculoId > 0) {
					const listarDutResult = await ListarDut(tipoVeiculoId);
					if (listarDutResult.success) {
						listaDut = listarDutResult.listaDut;
					}

					if (atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorAnoFabricacao) {
						var buscarParametrosTipoVeiculoSuhaiResult = await BuscarParametrosTipoVeiculoSuhai(
							tipoVeiculoId,
							atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorAnoFabricacao
						);
						if (buscarParametrosTipoVeiculoSuhaiResult.success) {
							parametrosTipoVeiculoSuhai =
								buscarParametrosTipoVeiculoSuhaiResult.parametrosTipoVeiculoSuhai;
						}
					}
				}
			}

			if (!listarFabricanteResult.success) {
				showMessage('Erro', 'Erro Inesperado ao carregar dados basicos.');
				hideLoading();
			} else {
				SetFormularioAutoRastreadorValues({
					...formularioAutoRastreadorValues,
					fabricantes: listarFabricanteResult.fabricantes,
					tabelaFipeSuhai: tabelaFipeSuhai,
					listaDut: listaDut,
					parametrosTipoVeiculoSuhai: parametrosTipoVeiculoSuhai
				});
				hideLoading();
			}
		}
		carregarDependencias();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function onChangeDescontoEvent(event) {
		const { value } = event.target;
		let descontoValue = parseFloat(value);
		if (descontoValue > 20) {
			showMessage('Erro', 'Desconto não pode ser superior a 20%.');
			return;
		}
		onChangeEvent(event);
	}

	function onChangeEvent(event) {
		const { name, value } = event.target;
		setAtendimentoValues({
			...atendimentoValues,
			dadosAtendimento: {
				...atendimentoValues.dadosAtendimento,
				autoRastreador: {
					...atendimentoValues.dadosAtendimento.autoRastreador,
					[name]: value
				}
			}
		});
	}

	function fabricanteOnChange(event) {
		showLoading('Buscando Modelos');
		const { value } = event.target;
		let tabelaFipeSuhaiLocal = [];

		onChangeEvent(event);
		if (value) {
			ListarFipeSuhaiPorFabricante(value).then((listarFipeSuhaiPorFabricanteResult) => {
				const { tabelaFipeSuhai, success } = listarFipeSuhaiPorFabricanteResult;
				if (success) {
					tabelaFipeSuhaiLocal = tabelaFipeSuhai;
				} else {
					hideLoading();
					showMessage('Erro', `Erro ao criar o registro: ${listarFipeSuhaiPorFabricanteResult.message}`);
					listarFipeSuhaiPorFabricanteResult.errors.map((erro) => showMessage('Erro', erro.errorMessage));
					tabelaFipeSuhaiLocal = [];
				}
				SetFormularioAutoRastreadorValues({
					...formularioAutoRastreadorValues,
					tabelaFipeSuhai: tabelaFipeSuhaiLocal
				});
				hideLoading();
			});
		} else {
			SetFormularioAutoRastreadorValues({
				...formularioAutoRastreadorValues,
				tabelaFipeSuhai: [],
				listaDut: []
			});
			hideLoading();
		}
	}

	const carregarParametroTipoVeiculo = (modelo = '', anoModelo = 0) => {
		showLoading('Aguarde');
		let listaDutLocal = [];
		let parametrosTipoVeiculoSuhaiLocal = formularioAutoRastreadorValues.parametrosTipoVeiculoSuhai;

		if (!modelo || !anoModelo) {
			hideLoading();
			SetFormularioAutoRastreadorValues({
				...formularioAutoRastreadorValues,
				listaDut: listaDutLocal,
				parametrosTipoVeiculoSuhai: parametrosTipoVeiculoSuhaiLocal
			});
		}

		let fipeSuhaiDetalhes = formularioAutoRastreadorValues.tabelaFipeSuhai.find(
			(x) => x.fipeSuhaiDescrModelo === modelo
		);

		BuscarParametrosTipoVeiculoSuhai(
			fipeSuhaiDetalhes.tipoVeiculoId,
			anoModelo
		).then((buscarParametrosTipoVeiculoSuhaiResult) => {
			const { parametrosTipoVeiculoSuhai } = buscarParametrosTipoVeiculoSuhaiResult;
			ListarDut(fipeSuhaiDetalhes.tipoVeiculoId).then((listarDutResult) => {
				hideLoading();
				const { listaDut, success } = listarDutResult;
				if (buscarParametrosTipoVeiculoSuhaiResult.success || success) {
					listaDutLocal = listaDut;
					parametrosTipoVeiculoSuhaiLocal = parametrosTipoVeiculoSuhai;
				} else {
					showMessage('Erro', `Erro ao buscar informações: ${listarDutResult.message}`);
					listarDutResult.errors.map((erro) => showMessage('Erro', erro.errorMessage));
					listaDutLocal = [];
				}
				SetFormularioAutoRastreadorValues({
					...formularioAutoRastreadorValues,
					listaDut: listaDutLocal,
					parametrosTipoVeiculoSuhai: parametrosTipoVeiculoSuhaiLocal
				});
				setAtendimentoValues({
					...atendimentoValues,
					dadosAtendimento: {
						...atendimentoValues.dadosAtendimento,
						autoRastreador: {
							...atendimentoValues.dadosAtendimento.autoRastreador,
							leadAutoRastreadorCodFipe: fipeSuhaiDetalhes.fipeSuhaiCodFipe,
							leadAutoRastreadorModelo: modelo,
							leadAutoRastreadorAnoModelo: anoModelo,
							leadAutoRastreadorValorComissao: parametrosTipoVeiculoSuhai.comissaoMaxima
						}
					}
				});
			});
		});
	};

	function modeloOnChange(event) {
		const { value } = event.target;
		if (value) {
			carregarParametroTipoVeiculo(
				value,
				atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorAnoFabricacao
			);
		} else {
			onChangeEvent(event);
			SetFormularioAutoRastreadorValues({
				...formularioAutoRastreadorValues,
				listaDut: []
			});
		}
	}

	function anoModeloOnChange(event) {
		const { value } = event.target;
		if (value) {
			carregarParametroTipoVeiculo(
				atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorModelo,
				value
			);
		} else {
			onChangeEvent(event);
			SetFormularioAutoRastreadorValues({
				...formularioAutoRastreadorValues,
				listaDut: []
			});
		}
	}

	const carregarModalCotacoes = () => {
		showLoading('Carregando Cotações');
		buscarCotacoes(atendimentoValues.dadosAtendimento.autoRastreador).then((buscarCotacoesResult) => {
			if (buscarCotacoesResult.cotacoes.length > 0) {
				setAtendimentoValues({
					...atendimentoValues,
					cotacoes: buscarCotacoesResult.cotacoes
				});
				showModalCotacaoAutoRastreador();
			} else {
				showMessage('Alerta', 'Não foram encontradas cotações!');
			}
			hideLoading();
		});
	};

	const carregarDadosDoLead = () => {
		setAtendimentoValues({
			...atendimentoValues,
			dadosAtendimento: {
				...atendimentoValues.dadosAtendimento,
				autoRastreador: {
					...atendimentoValues.dadosAtendimento.autoRastreador,
					leadAutoRastreadorCpfPrincCondutor: atendimentoValues.dadosAtendimento.lead.leadNumDocumento,
					leadAutoRastreadorNomePrincCondutor: atendimentoValues.dadosAtendimento.lead.leadNome,
					leadAutoRastreadorEmail: atendimentoValues.dadosAtendimento.lead.leadEmail,
					leadAutoRastreadorCelularDdd: atendimentoValues.dadosAtendimento.lead.leadDddCelular,
					leadAutoRastreadorCelularNumero: atendimentoValues.dadosAtendimento.lead.leadNumCelular
				}
			}
		});
	};

	return (
		<Fragment>
			{atendimentoValues.dadosAtendimento.produto.produtoFormAuxLead !== 'FORMAUTORASTREADOR' ? null : (
				<Fragment>
					<GroupBox className="col-12 col-md-12" titulo="Dados do Veículo">
						<div className="col-12 col-md-3">
							<div className="form-group">
								<label>Placa</label>
								<input
									type="text"
									maxLength={15}
									className="form-control"
									name="leadAutoRastreadorPlaca"
									value={atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorPlaca}
									onChange={onChangeEvent}
								/>
							</div>
						</div>
						<div className="col-12 col-md-4">
							<div className="form-group">
								<label>Num. Chassi</label>
								<input
									type="text"
									maxLength={20}
									className="form-control"
									name="leadAutoRastreadorNumChassi"
									value={
										atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorNumChassi
									}
									onChange={onChangeEvent}
								/>
							</div>
						</div>
						<div className="col-12 col-md-3">
							<div className="form-group">
								<label>Renavam</label>
								<input
									type="text"
									maxLength={15}
									className="form-control"
									name="leadAutoRastreadorRenavam"
									value={atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorRenavam}
									onChange={onChangeEvent}
								/>
							</div>
						</div>
						<div className="col-12 col-md-2">
							<div className="form-group">
								<label>Cor</label>
								<select
									className="form-control"
									name="leadAutoRastreadorCor"
									value={atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorCor}
									onChange={onChangeEvent}
								>
									<option className="disabled" />
									<option value="1"> Amarelo </option>
									<option value="2"> Azul </option>
									<option value="3"> Bege </option>
									<option value="4"> Branco </option>
									<option value="5"> Bronze </option>
									<option value="6"> Cinza </option>
									<option value="7"> Dourado </option>
									<option value="8"> Laranja </option>
									<option value="9"> Marrom </option>
									<option value="10"> Prata </option>
									<option value="11"> Preto </option>
									<option value="12"> Rosa </option>
									<option value="13"> Roxo </option>
									<option value="14"> Verde </option>
									<option value="15"> Vermelho </option>
									<option value="16"> Vinho </option>
								</select>
							</div>
						</div>

						<div className="col-12 col-md-6">
							<div className="form-group">
								<label>
									Fabricante<span className="campoObratorio2">*</span>
								</label>
								<select
									className="form-control"
									name="leadAutoRastreadorFabricante"
									value={
										atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorFabricante
									}
									onChange={fabricanteOnChange}
								>
									<option className="disabled" value="" />
									{formularioAutoRastreadorValues.fabricantes.map((fabricante) => (
										<option
											key={fabricante.fipeSuhaiCodFabricante}
											value={fabricante.fipeSuhaiDescrFabricante}
										>
											{fabricante.fipeSuhaiDescrFabricante}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label>
									Modelo<span className="campoObratorio2">*</span>
								</label>
								<select
									className="form-control"
									name="leadAutoRastreadorModelo"
									value={atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorModelo}
									onChange={modeloOnChange}
								>
									<option className="disabled" value="" />
									{formularioAutoRastreadorValues.tabelaFipeSuhai.map((fipeSuhai) => (
										<option
											key={fipeSuhai.fipeSuhaiCodModelo}
											value={fipeSuhai.fipeSuhaiDescrModelo}
										>
											{fipeSuhai.fipeSuhaiDescrModelo}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className="col-12 col-md-3">
							<div className="form-group">
								<label>
									Ano Fabric.<span className="campoObratorio2">*</span>
								</label>
								<input
									type="number"
									className="form-control numerico"
									maxLength="4"
									name="leadAutoRastreadorAnoFabricacao"
									value={
										atendimentoValues.dadosAtendimento.autoRastreador
											.leadAutoRastreadorAnoFabricacao
									}
									onChange={onChangeEvent}
								/>
							</div>
						</div>
						<div className="col-12 col-md-3">
							<div className="form-group">
								<label>
									Ano Modelo<span className="campoObratorio2">*</span>
								</label>
								<input
									type="number"
									className="form-control numerico"
									maxLength="4"
									name="leadAutoRastreadorAnoModelo"
									value={
										atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorAnoModelo
									}
									onChange={onChangeEvent}
									onBlur={anoModeloOnChange}
								/>
							</div>
						</div>
						<div className="col-12 col-md-3">
							<div className="form-group">
								<label>Combustivel</label>
								<select
									className="form-control"
									name="leadAutoRastreadorCombustivel"
									value={
										atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorCombustivel
									}
									onChange={onChangeEvent}
								>
									<option className="disabled" />
									<option value="1"> Alcool </option>
									<option value="2"> Diesel </option>
									<option value="3"> Hibrido </option>
									<option value="4"> Flex </option>
									<option value="5"> GNV </option>
									<option value="6"> Gasolina </option>
								</select>
							</div>
						</div>
						<div className="col-12 col-md-3">
							<div className="form-group">
								<label>
									Zero Km<span className="campoObratorio2">*</span>
								</label>
								<select
									className="form-control"
									name="leadAutoRastreadorZeroKm"
									value={atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorZeroKm}
									onChange={onChangeEvent}
								>
									<option className="disabled" />
									<option value="S"> Sim </option>
									<option value="N"> Não </option>
								</select>
							</div>
						</div>
						<div className="col-12 col-md-4">
							<div className="form-group">
								<label>
									Tipo Utilização Dut<span className="campoObratorio2">*</span>
								</label>
								<select
									className="form-control"
									name="leadAutoRastreadorTipoUtilizacaoDut"
									value={
										atendimentoValues.dadosAtendimento.autoRastreador
											.leadAutoRastreadorTipoUtilizacaoDut
									}
									onChange={onChangeEvent}
								>
									<option className="disabled" value="" />
									{formularioAutoRastreadorValues.listaDut.map((dut) => (
										<option key={dut.dutCodigo} value={dut.dutCodigo}>
											{dut.dutDescricao}
										</option>
									))}
								</select>
							</div>
						</div>

						{atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorTipoUtilizacaoDut !==
							'1' ? null : (
							<div className="col-12 col-md-4">
								<div className="form-group">
									<label>
										Tipo Utilização<span className="campoObratorio2">*</span>
									</label>
									<select
										className="form-control"
										name="leadAutoRastreadorTipoUtilizacao"
										value={
											atendimentoValues.dadosAtendimento.autoRastreador
												.leadAutoRastreadorTipoUtilizacao
										}
										onChange={onChangeEvent}
									>
										<option className="disabled" />
										<option value="1"> Locomoção/ida-retorno local fixo de trabalho/Lazer </option>
										<option value="2"> Atividade profissional </option>
										<option value="3"> Lazer exclusivamente </option>
									</select>
								</div>
							</div>
						)}
					</GroupBox>

					<GroupBox className="col-12 col-md-12" titulo="Principal Condutor">
						<div className="col-12 col-md-1">
							<div className="form-group">
								<button
									type="button"
									className="btn btn-info"
									style={{ width: 40 }}
									data-toggle="tooltip"
									data-placement="top"
									title="Copiar dados do Lead"
									onClick={() => {
										carregarDadosDoLead();
									}}
								>
									<FontAwesomeIcon style={{ fontSize: 16 }} icon={faCopy} />
								</button>
							</div>
						</div>
						<div className="col-12 col-md-4">
							<div className="form-group">
								<label>
									Cpf<span className="campoObratorio2">*</span>
								</label>
								<input
									type="text"
									className="form-control cpf"
									name="leadAutoRastreadorCpfPrincCondutor"
									value={
										atendimentoValues.dadosAtendimento.autoRastreador
											.leadAutoRastreadorCpfPrincCondutor
									}
									onChange={onChangeEvent}
								/>
							</div>
						</div>
						<div className="col-12 col-md-7">
							<div className="form-group">
								<label>
									Nome <span className="campoObratorio2">*</span>
								</label>
								<input
									type="text"
									maxLength={150}
									className="form-control"
									name="leadAutoRastreadorNomePrincCondutor"
									value={
										atendimentoValues.dadosAtendimento.autoRastreador
											.leadAutoRastreadorNomePrincCondutor
									}
									onChange={onChangeEvent}
								/>
							</div>
						</div>
						<div className="col-12 col-md-4">
							<div className="form-group">
								<label>
									Data Nascimento<span className="campoObratorio2">*</span>
								</label>
								<input
									type="date"
									className="form-control"
									name="leadAutoRastreadorDataNascPrincCondutor"
									value={
										atendimentoValues.dadosAtendimento.autoRastreador
											.leadAutoRastreadorDataNascPrincCondutor
									}
									onChange={onChangeEvent}
								/>
							</div>
						</div>
						<div className="col-12 col-md-4">
							<div className="form-group">
								<label>
									Sexo <span className="campoObratorio2">*</span>
								</label>
								<select
									className="form-control"
									name="leadAutoRastreadorSexoPrincCondutor"
									value={
										atendimentoValues.dadosAtendimento.autoRastreador
											.leadAutoRastreadorSexoPrincCondutor
									}
									onChange={onChangeEvent}
								>
									<option className="disabled" />
									<option value="1"> Feminino </option>
									<option value="2"> Masculino </option>
									<option value="3"> Pessoa Jurídica </option>
								</select>
							</div>
						</div>
						<div className="col-12 col-md-4">
							<div className="form-group">
								<label>
									Estado Civil<span className="campoObratorio2">*</span>
								</label>
								<select
									className="form-control"
									name="leadAutoRastreadorEstadoCivilPrincCondutor"
									value={
										atendimentoValues.dadosAtendimento.autoRastreador
											.leadAutoRastreadorEstadoCivilPrincCondutor
									}
									onChange={onChangeEvent}
								>
									<option className="disabled" />
									<option value="1"> Casado/União Estável</option>
									<option value="2"> Solteiro </option>
									<option value="3"> Separado/Divorciado/Outros </option>
								</select>
							</div>
						</div>
						<div className="col-12 col-md-3">
							<div className="form-group">
								<label>Possui Outro Veículo</label>
								<select
									className="form-control"
									name="leadAutoRastreadorPossuiOutroVeiculo"
									value={
										atendimentoValues.dadosAtendimento.autoRastreador
											.leadAutoRastreadorPossuiOutroVeiculo
									}
									onChange={onChangeEvent}
								>
									<option className="disabled" />
									<option value="S"> Sim </option>
									<option value="N"> Não </option>
								</select>
							</div>
						</div>
						<div className="col-12 col-md-4">
							<div className="form-group">
								<label>
									Cep Pernoite<span className="campoObratorio2">*</span>
								</label>
								<input
									type="text"
									className="form-control cep"
									name="leadAutoRastreadorCepPernoite"
									value={
										atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorCepPernoite
									}
									onChange={onChangeEvent}
								/>
							</div>
						</div>
					</GroupBox>
					{!formularioAutoRastreadorValues.parametrosTipoVeiculoSuhai.exibirPergunta01 &&
						!formularioAutoRastreadorValues.parametrosTipoVeiculoSuhai.exibirPergunta02 &&
						!formularioAutoRastreadorValues.parametrosTipoVeiculoSuhai.exibirPergunta03 &&
						!formularioAutoRastreadorValues.parametrosTipoVeiculoSuhai.exibirPergunta04 ? null : (
						<GroupBox className="col-12 col-md-12" titulo="Questionário">
							{!formularioAutoRastreadorValues.parametrosTipoVeiculoSuhai.exibirPergunta01 ? (
								false
							) : (
								<div className="col-12 col-md-12">
									<div className="form-group">
										<label>
											Veículo é guardado em garagem/estacionamento fechado na residência?<span className="campoObratorio2">*</span>
										</label>
										<select
											className="form-control"
											name="leadAutoRastreadorPergunta01"
											value={
												atendimentoValues.dadosAtendimento.autoRastreador
													.leadAutoRastreadorPergunta01
											}
											onChange={onChangeEvent}
										>
											<option className="disabled" value="" />
											<option value="1">Sim, garagem na residência</option>
											<option value="2">Sim, garagem em condomínio fechado </option>
											<option value="3">Sim, estacionamento </option>
											<option value="4">Não. </option>
										</select>
									</div>
								</div>
							)}
							{!formularioAutoRastreadorValues.parametrosTipoVeiculoSuhai.exibirPergunta02 ? (
								false
							) : (
								<div className="col-12 col-md-12">
									<div className="form-group">
										<label>
											Veículo é guardado em garagem/estacionamento fechado quando utilizado para
											ir a local de trabalho?<span className="campoObratorio2">*</span>
										</label>
										<select
											className="form-control"
											name="leadAutoRastreadorPergunta02"
											value={
												atendimentoValues.dadosAtendimento.autoRastreador
													.leadAutoRastreadorPergunta02
											}
											onChange={onChangeEvent}
										>
											<option className="disabled" value="" />
											<option value="1">
												Não utiliza para ir ao local de trabalho/ serviços externos
											</option>
											<option value="2">Utiliza e guarda no local de trabalho/ serviços </option>
											<option value="3">
												Utiliza mas não guarda quando em trabalho/ serviços externos{' '}
											</option>
										</select>
									</div>
								</div>
							)}
							{!formularioAutoRastreadorValues.parametrosTipoVeiculoSuhai.exibirPergunta03 ? (
								false
							) : (
								<div className="col-12 col-md-12">
									<div className="form-group">
										<label>
											Veículo é guardado em garagem/estacionamento fechado quando utilizado para
											ir a faculdade/colégio?<span className="campoObratorio2">*</span>
										</label>
										<select
											className="form-control"
											name="leadAutoRastreadorPergunta03"
											value={
												atendimentoValues.dadosAtendimento.autoRastreador
													.leadAutoRastreadorPergunta03
											}
											onChange={onChangeEvent}
										>
											<option className="disabled" value="" />
											<option value="1">Não utiliza para ir à escola</option>
											<option value="2">
												Utiliza para ir à escola e o guarda em estacionamento
											</option>
											<option value="3">
												Utiliza para ir à escola mas não o guarda em estacionamento
											</option>
										</select>
									</div>
								</div>
							)}
							{!formularioAutoRastreadorValues.parametrosTipoVeiculoSuhai.exibirPergunta04 ? (
								false
							) : (
								<div className="col-12 col-md-12">
									<div className="form-group">
										<label>
											Mantém o veículo em garagem/estacionamento quando não está em serviço?<span className="campoObratorio2">*</span>
										</label>
										<select
											className="form-control"
											name="leadAutoRastreadorPergunta04"
											value={
												atendimentoValues.dadosAtendimento.autoRastreador
													.leadAutoRastreadorPergunta04
											}
											onChange={onChangeEvent}
										>
											<option className="disabled" value="" />
											<option value="1">Sim</option>
											<option value="2">Não</option>
										</select>
									</div>
								</div>
							)}
						</GroupBox>
					)}
					<GroupBox className="col-12 col-md-12" titulo="Informações Adicionais">

						<div className="col-12 col-md-2">
							<div className="form-group">
								<label>Comissão</label>
								<input
									disabled={true}
									type="text"
									className="form-control"
									value={`${atendimentoValues.dadosAtendimento.autoRastreador
										.leadAutoRastreadorValorComissao}%`}
								/>
							</div>
						</div>
						<div className="col-12 col-md-3">
							<div className="form-group">
								<label>Perc. Desconto<span className="campoObratorio2">*</span></label>
								<input
									type="text"
									className="form-control"
									name="leadAutoRastreadorPercDesconto"
									value={
										atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorPercDesconto
									}
									onChange={onChangeDescontoEvent}
								/>
							</div>
						</div>
						<div className="col-12 col-md-2">
							<div className="form-group">
								<label>
									Bonus<span className="campoObratorio2">*</span>
								</label>
								<select
									className="form-control"
									name="leadAutoRastreadorClasseBonus"
									value={
										atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorClasseBonus
									}
									onChange={onChangeEvent}
								>
									<option className="disabled" />
									<option value="0">0</option>
									<option value="1">1</option>
									<option value="2">2</option>
									<option value="3">3</option>
									<option value="4">4</option>
									<option value="5">5</option>
									<option value="6">6</option>
									<option value="7">7</option>
									<option value="8">8</option>
									<option value="9">9</option>
									<option value="10">10</option>
								</select>
							</div>
						</div>
						<div className="col-12 col-md-12" />
						<div className="col-12 col-md-3">
							<div className="form-group">
								<label>Danos Corporais</label>
								<input
									type="text"
									className="form-control"
									name="leadAutoRastreadorValorDanosCorporais"
									value={
										atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorValorDanosCorporais
									}
									onChange={onChangeEvent}
								/>
							</div>
						</div>

						<div className="col-12 col-md-3">
							<div className="form-group">
								<label>Danos Materiais</label>
								<input
									type="text"
									className="form-control"
									name="leadAutoRastreadorValorDanosMateriais"
									value={
										atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorValorDanosMateriais
									}
									onChange={onChangeEvent}
								/>
							</div>
						</div>

						<div className="col-12 col-md-3">
							<div className="form-group">
								<label>Danos Morais</label>
								<input
									type="text"
									className="form-control"
									name="leadAutoRastreadorValorDanosMorais"
									value={
										atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorValorDanosMorais
									}
									onChange={onChangeEvent}
								/>
							</div>
						</div>
					</GroupBox>
					<div className="col-12 pb-3 text-right">
						<span className="campoObratorio2" style={{ fontSize: 12 }}>
							(*) - Campos obrigatórios para gerar cotação.
						</span>
					</div>
					<div className="col-12 pb-3 pt-3 border-bottom text-right">
						<ModalCotacaoAutoRastreador carregarDadosDoLead={carregarDadosDoLead} context={context} />
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => {
								carregarModalCotacoes();
							}}
						>
							Processar Cotação
						</button>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default FormularioAutoRastreador;
