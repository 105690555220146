import { RequestOpenWebApi } from './Requests';

const controller = 'Pagamento';

export const ObterChavePublicaWireCard = () =>
	RequestOpenWebApi.get(`/${controller}/ObterChavePublicaWireCard`)
		.then(function(response) {
			const { resultData, success } = response.data;
			const chavePublica = resultData;
			return { success, chavePublica };
		})
		.catch(function(error) {
			const { success, message, errors } = error.response.data;
			return { success, message, errors, chavePublica: '' };
		});

export const DetalhesPagamento = (hash) =>
	RequestOpenWebApi.get(`/${controller}/DetalhesPagamento`, {
		params: { hash: hash }
	})
		.then(function(response) {
			const { resultData, success } = response.data;
			const detalhePagamento = resultData;
			return { success, detalhePagamento };
		})
		.catch(function(error) {
			const { success, message, errors } = error.response.data;
			return { success, message, errors, detalhePagamento: {} };
		});

export const ProcessarPagamento = (pagamento) =>
	RequestOpenWebApi.post(`/${controller}/ProcessarPagamento`, pagamento)
		.then(function(response) {
			const { resultData, success } = response.data;
			const pagamentoResult = resultData;
			return { success, pagamentoResult };
		})
		.catch(function(error) {
			const { success, message, errors } = error.response.data;
			return { success, message, errors, pagamentoResult: [] };
		});
