import axios from "axios";

const controller = "Autenticacao";

export const Login = (usuario, senha) =>
  axios
    .create({
      baseURL: process.env.REACT_APP_WEB_API_URL_BASE,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    })
    .post(`/${controller}/Autenticar`, {
      username: usuario,
      password: senha,
    })
    .then(function (response) {
      const { resultData, success } = response.data;
      const { token, usuario } = resultData;
      return { success, token, usuario };
    })
    .catch(function (error) {
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Autenticado = (token) =>
  axios
    .create({
      baseURL: process.env.REACT_APP_WEB_API_URL_BASE,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`,
      },
    })
    .get(`/${controller}/Autenticado`)
    .then(function () {
      return { autenticado: true };
    })
    .catch(function () {
      return { autenticado: false };
    });
