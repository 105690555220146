import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "Cobranca";

export const Listar = (cobranca) =>
  RequestWebApi.get(`/${controller}/Listar`, {
    params: { ...cobranca },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const origensLead = resultData;
      return { success, origensLead };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { origensLead: [] };
    });

export const Detalhes = (cobrancaId) =>
  RequestWebApi.get(`/${controller}/Detalhes`, {
    params: { cobrancaId: cobrancaId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const cobranca = resultData;
      return { success, cobranca };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, cobranca: {} };
    });

export const ProcessarCobranca = (cobranca) =>
  RequestWebApi.put(`/${controller}/ProcessarCobranca`, cobranca)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const AlterarStatusCobranca = (cobranca) =>
  RequestWebApi.put(`/${controller}/AlterarStatusCobranca`, cobranca)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (cobrancaId) =>
  RequestWebApi.delete(`/${controller}/Remover`, {
    params: { cobrancaId: cobrancaId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const ImportarCobrancas = (arquivo) => {
  let formFile = new FormData();
  formFile.append("arquivo", arquivo);
  let result = RequestWebApi.post(`/${controller}/ImportarCobrancas`, formFile)
    .then(function (response) {
      const { resultData, success } = response.data;
      const resultadosImportacao = resultData;
      return { success, resultadosImportacao };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, resultadosImportacao: [] };
    });
  return result;
};