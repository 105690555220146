/* eslint-disable no-useless-escape */
import $ from "jquery";

export const LoadMask = () => {
    $('.cep').mask('99999-999', { clearIfNotMatch: true });
    $('.cartao_credito').mask('9999 9999 9999 9999 999');
    $('.cnpj').mask('99.999.999/9999-99');
    $('.cpf').mask('999.999.999-99', { clearIfNotMatch: false });
    $('.telefone').mask('(99) 999999999');
    $('.telSemDDD').mask('99999-9999');
    $('.data').mask('99/99/9999', { clearIfNotMatch: true });
    $('.mesano').mask('99/9999', { clearIfNotMatch: true });
    $('.hora').mask('99:99', { clearIfNotMatch: true });
    $('.numerico').mask("#################9");
    $('.monetario').mask("############9,99", {
        reverse: true,
        maxlength: false
    });

    $('.placa_veiculo').mask('AAA0U00', {

        translation: {
            'A': {
                pattern: /[A-Za-z]/
            },
            'U': {
                pattern: /[A-Za-z0-9]/
            },
        },
        clearIfNotMatch: true
    });

    $('.porcentagem').mask('Z#9V##', {
        'translation': {
            'Z': {
                pattern: /[\-\+]/,
                optional: true
            },
            'V': {
                pattern: /[\,]/
            },
            '#': {
                pattern: /[0-9]/,
                optional: true
            }
        }
    });
    $('.porcentagem3Digitos').mask('Z##9V##', {
        'translation': {
            'Z': {
                pattern: /[\-\+]/,
                optional: true
            },
            'V': {
                pattern: /[\,]/
            },
            '#': {
                pattern: /[0-9]/,
                optional: true
            }
        }
    });
};
