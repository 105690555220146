import React, { Fragment, useContext } from 'react';
import "./Incluir.css";
import { LoadMask } from "../../../../../Utils/mask";
import { LeadManualContext } from '../LeadManualContext';
import FormularioComplementar from "../../../../../Components/Cadastro/FormularioComplementar/FormularioComplementar";
import DataTable from "../../../../../Components/DataTable/DataTable";
import { validarCpf, validarCnpj, formatarMoeda } from '../../../../../Utils/globalFunctions';
import Help from "../../../../../Components/Help/Help";
const Incluir = () => {
	const { values, setValues, Incluir, filtrarCidades,
		removerContato,
		adicionarContato, showMessage } = useContext(LeadManualContext);
	LoadMask();
	const formularioComErro =
		!values.lead.nomeCompleto ||
		!values.lead.telefone ||
		!values.lead.dddTelefone ||
		!values.lead.email ||
		!values.lead.origem ||
		values.lead.origem === '0' ||
		!values.lead.fornecedor ||
		values.lead.fornecedor === '0' ||
		!values.lead.produtoId ||
		values.lead.produtoId === '0';

	function onChangeInput(event) {
		const { name, value } = event.target;
		setValues({
			...values,
			lead: {
				...values.lead,
				[name]: value
			}
		});
	}

	function onChangeInputCpfCnpj(event) {
		const { value } = event.target;
		var numeroDocumento = value;
		if (numeroDocumento == null) numeroDocumento = '';

		numeroDocumento = numeroDocumento.replace(/[/.-]/g, '');
		setValues({
			...values,
			lead: {
				...values.lead,
				leadNumDocumento: numeroDocumento
			}
		});
	}
	function validarDocumentoDigitado(event) {

		const { value } = event.target;
		var numeroDocumento = value;
		if (numeroDocumento == null) numeroDocumento = '';

		numeroDocumento = numeroDocumento.replace(/[.-]/g, '');
		if (!numeroDocumento) {
			return
		}
		if (numeroDocumento.length !== 11 && numeroDocumento.length !== 14) {
			showMessage('erro', 'Documento digitado esta no formato inválido.');
			setValues({
				...values,
				lead: {
					...values.lead,
					leadNumDocumento: ''
				}
			});
			return;
		}

		if (numeroDocumento.length === 11) {
			if (!validarCpf(numeroDocumento)) {
				showMessage('erro', 'Cpf Inválido.');
				setValues({
					...values,
					lead: {
						...values.lead,
						leadNumDocumento: ''
					}
				});
				return;
			}
		}
		if (numeroDocumento.length === 14) {
			if (!validarCnpj(numeroDocumento)) {
				showMessage('erro', 'Cnpj Inválido.');
				setValues({
					...values,
					lead: {
						...values.lead,
						leadNumDocumento: ''
					}
				});
				return;
			}
		}
	}

	function onChangeInputAuto(event) {
		const { name, value } = event.target;
		setValues({
			...values,
			lead: {
				...values.lead,
				leadAuto: {
					...values.lead.leadAuto,
					[name]: value
				}
			}
		});
	}


	function onChangeInputCorporativo(event) {
		const { name, value } = event.target;
		setValues({
			...values,
			lead: {
				...values.lead,
				leadCorporativo: {
					...values.lead.leadCorporativo,
					[name]: value
				}
			}
		});
	}

	function onChangeInputRisk(event) {
		const { name, value } = event.target;
		setValues({
			...values,
			lead: {
				...values.lead,
				leadRisk: {
					...values.lead.leadRisk,
					[name]: value
				}
			}
		});
	}



	function onChangeInputContato(event) {
		const { name, value } = event.target;
		setValues({
			...values,
			dataLeadContato: {
				...values.dataLeadContato,
				[name]: value
			}
		});
	}



	function onChangeUf(event) {
		filtrarCidades(event.target.value);
	}

	function salvar() {
		Incluir(values.lead);
	}

	const valorAgenciamento = formatarMoeda(
		values.lead.leadCorporativo.leadCorporativoVlrFaturamentoAnual === 0 ||
			values.lead.leadCorporativo.leadCorporativoPercAgenciamento === 0
			? 0
			: (values.lead.leadCorporativo.leadCorporativoVlrFaturamentoAnual.replace(
				",",
				"."
			) /
				100) *
			values.lead.leadCorporativo.leadCorporativoPercAgenciamento.replace(
				",",
				"."
			)
	);
	const valorVitalicio = formatarMoeda(
		values.lead.leadCorporativo.leadCorporativoVlrFaturamentoAnual === 0 ||
			values.lead.leadCorporativo.leadCorporativoPercVitalicio === 0
			? 0
			: (values.lead.leadCorporativo.leadCorporativoVlrFaturamentoAnual.replace(
				",",
				"."
			) /
				100) *
			values.lead.leadCorporativo.leadCorporativoPercVitalicio.replace(
				",",
				"."
			)
	);

	return (
		<Fragment>
			<form>
				<div className="row">
					<div className="col-12">
						<div className="default-title">Básicos</div>
						<div className="card shadow-sm mb-4">
							<div className="row">
								<div className="col-12 col-md-6">
									<div className="form-group">
										<label>
											Nome Completo<span className="campoObratorio">*</span>
										</label>
										<input
											type="text"
											className="form-control uppercase"
											name="nomeCompleto"
											value={values.lead.nomeCompleto}
											onChange={onChangeInput}
										/>
									</div>
								</div>
								<div className="col-12 col-md-3 form-group">
									<label>
										CPF/CNPJ
									</label>
									<input
										className="form-control"
										type="text"
										name="leadNumDocumento"
										value={values.lead.leadNumDocumento}
										onChange={onChangeInputCpfCnpj}
										onBlur={validarDocumentoDigitado}
									/>
								</div>
								<div className="col-12 col-md-1">
									<div className="form-group">
										<label>
											DDD<span className="campoObratorio">*</span>
										</label>
										<input
											type="text"
											maxLength="2"
											className="form-control numerico"
											name="dddTelefone"
											value={values.lead.dddTelefone}
											onChange={onChangeInput}
										/>
									</div>
								</div>
								<div className="col-12 col-md-2">
									<div className="form-group">
										<label>
											Telefone<span className="campoObratorio">*</span>
										</label>
										<input
											type="text"
											maxLength="9"
											className="form-control numerico"
											name="telefone"
											value={values.lead.telefone}
											onChange={onChangeInput}
										/>
									</div>
								</div>
								<div className="col-12 col-md-3">
									<div className="form-group">
										<label>
											Email<span className="campoObratorio">*</span>
										</label>
										<input
											type="email"
											className="form-control lowercase"
											name="email"
											value={values.lead.email}
											onChange={onChangeInput}
										/>
									</div>
								</div>
								<div className="col-12 col-md-2">
									<div className="form-group">
										<label>UF</label>
										<select
											className="form-control"
											name="uf"
											value={values.lead.uf}
											onChange={onChangeUf}
										>
											<option className="disabled" />
											{values.ufs.map((uf) => (
												<option key={uf} value={uf}>
													{uf}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className="col-12 col-md-4">
									<div className="form-group autocomplete">
										<label>Cidade</label>
										<select
											disabled={!values.lead.uf}
											className="form-control"
											name="cidade"
											value={values.lead.cidade}
											onChange={onChangeInput}
										>
											<option className="disabled" />
											{values.cidades.map((cidade) => (
												<option key={cidade.cidadeId} value={cidade.cidadeId}>
													{cidade.cidadeNome}
												</option>
											))}
										</select>
									</div>
								</div>

								<div className="col-12 col-md-3">
									<div className="form-group">
										<label>Vencimento da Apólice</label>
										<input
											type="date"
											className="form-control"
											name="vencimentoApolice"
											value={values.lead.vencimentoApolice}
											onChange={onChangeInput}
										/>
									</div>
								</div>
								<div className="col-12 col-md-3">
									<div className="form-group">
										<label>Tipo Veículo</label>
										<select
											disabled={values.formDisabled}
											className="form-control"
											name="tipoVeiculoId"
											value={values.lead.tipoVeiculoId}
											onChange={onChangeInput}
										>
											<option className="disabled" value="0" />
											{values.tiposVeiculo.map((tipoVeiculo) => (
												<option
													key={tipoVeiculo.tipoVeiculoId}
													value={tipoVeiculo.tipoVeiculoId}
												>
													{tipoVeiculo.tipoVeiculoDescricao}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className="col-12 col-md-3">
									<div className="form-group">
										<label>Tipo Negócio</label>
										<select
											disabled={values.formDisabled}
											className="form-control"
											name="leadTipoNegocio"
											value={values.lead.leadTipoNegocio}
											onChange={onChangeInput}
										>
											<option className="disabled" value="" />
											{values.tiposNegocio.map((tipoNegocio) => (
												<option key={tipoNegocio.codigo} value={tipoNegocio.codigo}>
													{tipoNegocio.descricao}
												</option>
											))}
										</select>
									</div>
								</div>

								<div className="col-12 col-md-3">
									<div className="form-group">
										<label>Formulario Adicional</label>
										<select
											disabled={values.formDisabled}
											className="form-control"
											name="formularioAdicional"
											value={values.lead.formularioAdicional}
											onChange={onChangeInput}
										>
											<option className="disabled" value="" >NENHUM</option>
											<option key="LEADAUTO" value="LEADAUTO">AUTO</option>
											<option key="LEADCORPORATIVO" value="LEADCORPORATIVO">CORPORATIVO</option>
											<option key="LEADRISK" value="LEADRISK">RISK</option>

										</select>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{values.lead.formularioAdicional === "LEADAUTO" ? <div className="row">
					<div className="col-12">
						<div className="default-title">Informações Do Veículo</div>
						<div className="card shadow-sm mb-4">
							<div className="row">
								<div className="col-12 col-md-2">
									<div className="form-group">
										<label>Placa</label>
										<input
											type="text"
											className="form-control placa_veiculo"
											name="leadAutoPlaca"
											maxLength="8"
											value={values.lead.leadAuto.leadAutoPlaca}
											onChange={onChangeInputAuto}
										/>
									</div>
								</div>
								<div className="col-12 col-md-3">
									<div className="form-group">
										<label>Veículo Marca</label>
										<input
											type="text"
											className="form-control uppercase"
											name="leadAutoFabricante"
											value={values.lead.leadAuto.leadAutoFabricante}
											onChange={onChangeInputAuto}
										/>
									</div>
								</div>
								<div className="col-12 col-md-3">
									<div className="form-group">
										<label>Veículo Modelo</label>
										<input
											type="text"
											className="form-control uppercase"
											name="leadAutoModelo"
											value={values.lead.leadAuto.leadAutoModelo}
											onChange={onChangeInputAuto}
										/>
									</div>
								</div>
								<div className="col-12 col-md-2">
									<div className="form-group">
										<label>Ano Fabricação</label>
										<input
											type="text"
											className="form-control numerico"
											name="leadAutoAnoFabricacao"
											maxLength="4"
											value={values.lead.leadAuto.leadAutoAnoFabricacao}
											onChange={onChangeInputAuto}
										/>
									</div>
								</div>
								<div className="col-12 col-md-2">
									<div className="form-group">
										<label>Ano Modelo</label>
										<input
											type="text"
											className="form-control numerico"
											name="leadAutoAnoModelo"
											maxLength="4"
											value={values.lead.leadAuto.leadAutoAnoModelo}
											onChange={onChangeInputAuto}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> : null
				}

				{values.lead.formularioAdicional === "LEADRISK" ? <div className="row">
					<div className="col-12">
						<div className="default-title">Informações da Empresa</div>
						<div className="card shadow-sm mb-4">
							<div className="row">
								<div className="col-12 col-md-4">
									<div className="form-group">
										<label>Porte</label>
										<select
											className="form-control"
											name="leadRiskPorte"
											value={values.lead.leadRisk.leadRiskPorte}
											onChange={onChangeInputRisk}
										>
											<option className="disabled" value="" />
											{values.leadRiskPortes.map((leadRiskPorte) => (
												<option key={leadRiskPorte.codigo} value={leadRiskPorte.codigo}>
													{leadRiskPorte.descricao}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className="col-12 col-md-2">
									<div className="form-group">
										<label>Numero Funcionários</label>
										<input
											type="number"
											className="form-control"
											name="leadRiskNumFuncionarios"
											value={values.lead.leadRisk.leadRiskNumFuncionarios}
											onChange={onChangeInputRisk}
										/>
									</div>
								</div>
								<FormularioComplementar titulo="Contatos">
									<div className="col-12 col-md-5">
										<div className="form-group">
											<label>Nome</label>
											<input
												type="text"
												className="form-control"
												name="leadContatoNome"
												value={values.dataLeadContato.leadContatoNome}
												onChange={onChangeInputContato}
											/>
										</div>
									</div>
									<div className="col-12 col-md-3">
										<div className="form-group">
											<label>Cargo</label>
											<input
												type="text"
												className="form-control"
												name="leadContatoCargo"
												value={values.dataLeadContato.leadContatoCargo}
												onChange={onChangeInputContato}
											/>
										</div>
									</div>
									<div className="col-12 col-md-4">
										<div className="form-group">
											<label>Email</label>
											<input
												type="text"
												className="form-control"
												name="leadContatoEmail"
												value={values.dataLeadContato.leadContatoEmail}
												onChange={onChangeInputContato}
											/>
										</div>
									</div>
									<div className="col-12 col-md-2">
										<div className="form-group">
											<label>DDD</label>
											<input
												type="text"
												maxLength="2"
												className="form-control numerico"
												name="leadContatoDdd"
												value={values.dataLeadContato.leadContatoDdd}
												onChange={onChangeInputContato}
											/>
										</div>
									</div>
									<div className="col-12 col-md-3">
										<div className="form-group">
											<label>Telefone</label>
											<input
												type="text"
												maxLength="9"
												className="form-control numerico"
												name="leadContatoTelefone"
												value={values.dataLeadContato.leadContatoTelefone}
												onChange={onChangeInputContato}
											/>
										</div>
									</div>
									<div className="col-12 col-md-2 form-group">
										<button
											type="button"
											disabled={
												!values.dataLeadContato.leadContatoNome
												|| !values.dataLeadContato.leadContatoCargo
												|| !values.dataLeadContato.leadContatoEmail
												|| !values.dataLeadContato.leadContatoDdd
												|| !values.dataLeadContato.leadContatoTelefone
											}
											className="btn btn-default buttonFormularios"
											onClick={adicionarContato}
										>
											Adicionar
										</button>
									</div>
									<div className="col-12 col-md-12 form-group">
										<DataTable
											data={values.lead.leadContatos}
											columnsDefinition={values.columnsDefinitionContatos}
											removeHandler={removerContato}
										/>
									</div>
								</FormularioComplementar>

							</div>
						</div>
					</div>
				</div> : null
				}

				{values.lead.formularioAdicional === "LEADCORPORATIVO" ? <div className="row">
					<div className="col-12">
						<div className="default-title">Informações da Empresa</div>
						<div className="card shadow-sm mb-4">
							<div className="row">
								<div className="col-12 col-md-4">
									<div className="form-group">
										<label>Porte</label>
										<select
											className="form-control"
											name="leadCorporativoPorte"
											value={values.lead.leadCorporativo.leadCorporativoPorte}
											onChange={onChangeInputCorporativo}
										>
											<option className="disabled" value="" />
											{values.leadCorporativoPortes.map((leadCorporativoPorte) => (
												<option key={leadCorporativoPorte.codigo} value={leadCorporativoPorte.codigo}>
													{leadCorporativoPorte.descricao}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className="col-12 col-md-4">
									<div className="form-group">
										<label>Plano Vigente</label>
										<input
											type="text"
											className="form-control"
											name="leadCorporativoEmpresaPlanoVigente"
											value={values.lead.leadCorporativo.leadCorporativoEmpresaPlanoVigente}
											onChange={onChangeInputCorporativo}
										/>
									</div>
								</div>
								<div className="col-12 col-md-2">
									<div className="form-group">
										<label>Aniversário Contrato</label>
										<input
											type="date"
											className="form-control"
											name="leadCorporativoDataAnivContrato"
											value={values.lead.leadCorporativo.leadCorporativoDataAnivContrato}
											onChange={onChangeInputCorporativo}
										/>
									</div>
								</div>
								<div className="col-12 col-md-2">
									<div className="form-group">
										<label>Numero Funcionários</label>
										<input
											type="number"
											className="form-control"
											name="leadCorporativoNumFuncionarios"
											value={values.lead.leadCorporativo.leadCorporativoNumFuncionarios}
											onChange={onChangeInputCorporativo}
										/>
									</div>
								</div>
								<div className="col-12 col-md-2">
									<div className="form-group">
										<label>Fatura Anual<span className="campoObratorio">*</span></label>
										<input
											type="text"
											className="form-control monetario"
											name="leadCorporativoVlrFaturamentoAnual"
											value={values.lead.leadCorporativo.leadCorporativoVlrFaturamentoAnual}
											onChange={onChangeInputCorporativo}
											onBlur={onChangeInputCorporativo}
											onKeyDown={onChangeInputCorporativo}
										/>
									</div>
								</div>
								<div className="col-12 col-md-2">
									<div className="form-group">
										<label>Perc. Agenciamento</label>
										<input
											type="text"
											className="form-control porcentagem3Digitos"
											name="leadCorporativoPercAgenciamento"
											value={values.lead.leadCorporativo.leadCorporativoPercAgenciamento}
											onChange={onChangeInputCorporativo}
										/>
									</div>
								</div>
								<div className="col-12 col-md-2">
									<div className="form-group">
										<label>Valor Agenciamento</label>
										<input
											disabled={true}
											type="text"
											className="form-control leadManual-valoresCalculados"
											name="valorComissao"
											value={valorAgenciamento}
										/>
									</div>
								</div>
								<div className="col-12 col-md-2">
									<div className="form-group">
										<label>Perc. Vitalício</label>
										<input
											type="text"
											className="form-control porcentagem3Digitos"
											name="leadCorporativoPercVitalicio"
											value={values.lead.leadCorporativo.leadCorporativoPercVitalicio}
											onChange={onChangeInputCorporativo}
										/>
									</div>
								</div>
								<div className="col-12 col-md-2">
									<div className="form-group">
										<label>Valor Vitalício</label>
										<input
											disabled={true}
											type="text"
											className="form-control leadManual-valoresCalculados"
											name="valorComissao"
											value={valorVitalicio}
										/>
									</div>
								</div>

								<FormularioComplementar titulo="Contatos">
									<div className="col-12 col-md-5">
										<div className="form-group">
											<label>Nome</label>
											<input
												type="text"
												className="form-control"
												name="leadContatoNome"
												value={values.dataLeadContato.leadContatoNome}
												onChange={onChangeInputContato}
											/>
										</div>
									</div>
									<div className="col-12 col-md-3">
										<div className="form-group">
											<label>Cargo</label>
											<input
												type="text"
												className="form-control"
												name="leadContatoCargo"
												value={values.dataLeadContato.leadContatoCargo}
												onChange={onChangeInputContato}
											/>
										</div>
									</div>
									<div className="col-12 col-md-4">
										<div className="form-group">
											<label>Email</label>
											<input
												type="text"
												className="form-control"
												name="leadContatoEmail"
												value={values.dataLeadContato.leadContatoEmail}
												onChange={onChangeInputContato}
											/>
										</div>
									</div>
									<div className="col-12 col-md-2">
										<div className="form-group">
											<label>DDD</label>
											<input
												type="text"
												maxLength="2"
												className="form-control numerico"
												name="leadContatoDdd"
												value={values.dataLeadContato.leadContatoDdd}
												onChange={onChangeInputContato}
											/>
										</div>
									</div>
									<div className="col-12 col-md-3">
										<div className="form-group">
											<label>Telefone</label>
											<input
												type="text"
												maxLength="9"
												className="form-control numerico"
												name="leadContatoTelefone"
												value={values.dataLeadContato.leadContatoTelefone}
												onChange={onChangeInputContato}
											/>
										</div>
									</div>
									<div className="col-12 col-md-2 form-group">
										<button
											type="button"
											disabled={
												!values.dataLeadContato.leadContatoNome
												|| !values.dataLeadContato.leadContatoCargo
												|| !values.dataLeadContato.leadContatoEmail
												|| !values.dataLeadContato.leadContatoDdd
												|| !values.dataLeadContato.leadContatoTelefone
											}
											className="btn btn-default buttonFormularios"
											onClick={adicionarContato}
										>
											Adicionar
										</button>
									</div>
									<div className="col-12 col-md-12 form-group">
										<DataTable
											data={values.lead.leadContatos}
											columnsDefinition={values.columnsDefinitionContatos}
											removeHandler={removerContato}
										/>
									</div>
								</FormularioComplementar>

							</div>
						</div>
					</div>
				</div> : null
				}


				<div className="row">
					<div className="col-12">
						<div className="default-title">Complementares</div>
						<div className="card shadow-sm mb-4">
							<div className="row">
								<div className="col-12 col-md-3 form-group">
									<label>
										Produto<span className="campoObratorio">*</span>
									</label>
									<select
										disabled={values.formDisabled}
										className="form-control"
										name="produtoId"
										value={values.lead.produtoId}
										onChange={onChangeInput}
									>
										<option className="disabled" value="0" />
										{values.produtos.map((produto) => (
											<option key={produto.produtoId} value={produto.produtoId}>
												{produto.produtoNome}
											</option>
										))}
									</select>
								</div>
								<div className="col-12 col-md-4">
									<div className="form-group">
										<label>Categoria Produto</label>
										<select
											disabled={values.formDisabled}
											className="form-control"
											name="leadCategoriaProduto"
											value={values.lead.leadCategoriaProduto}
											onChange={onChangeInput}
										>
											<option className="disabled" value="" />
											{values.categoriasProduto.map((categoriaProduto) => (
												<option key={categoriaProduto.codigo} value={categoriaProduto.codigo}>
													{categoriaProduto.descricao}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className="col-12 col-md-2">
									<div className="form-group">
										<label>Tipo</label>
										<select
											className="form-control"
											name="tipo"
											value={values.lead.tipo}
											onChange={onChangeInput}
										>
											<option className="disabled" />
											<option value="O">Orgânico</option>
											<option value="P">Pago</option>
										</select>
									</div>
								</div>
								<div className="col-12 col-md-3">
									<div className="form-group">
										<label>Classificação</label>
										<select
											className="form-control"
											name="classificacao"
											value={values.lead.classificacao}
											onChange={onChangeInput}
										>
											<option className="disabled" />
											<option value="MQL">Qualificado</option>
											<option value="SAL">Visitante</option>
											<option value="SQL">Inscrito</option>
										</select>
									</div>
								</div>
								<div className="col-12 col-md-3">
									<div className="form-group">
										<Help identificadorHelp="ORIGEMLEAD">
											<label >
												Origem<span className="campoObratorio">*</span>
											</label>
										</Help>
										<select
											className="form-control"
											name="origem"
											value={values.lead.origem}
											onChange={onChangeInput}
										>
											<option className="disabled" value="0" />
											{values.origensLeadApi.map((origem) => (
												<option key={origem.origemLeadId} value={origem.origemLeadId}>
													{origem.origemLeadNome}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className="col-12 col-md-4">
									<div className="form-group">
										<Help identificadorHelp="FORNECEDORLEAD">
											<label>
												Fornecedor<span className="campoObratorio">*</span>
											</label>
										</Help>
										<select
											className="form-control"
											name="fornecedor"
											value={values.lead.fornecedor}
											onChange={onChangeInput}
										>
											<option className="disabled" value="0" />
											{values.fornecedoresLeadApi.map((fornecedor) => (
												<option
													key={fornecedor.fornecedorLeadId}
													value={fornecedor.fornecedorLeadId}
												>
													{fornecedor.fornecedorLeadNome}
												</option>
											))}
										</select>
									</div>
								</div>

								<div className="col-12 col-md-3">
									<div className="form-group">
										<label>URL Landing Page</label>
										<select
											className="form-control"
											name="landingPage"
											value={values.lead.landingPage}
											onChange={onChangeInput}
										>
											<option className="disabled" value="0" />
											{values.landingPagesApi.map((landingPage) => (
												<option
													key={landingPage.landingPageId}
													value={landingPage.landingPageId}
												>
													{landingPage.landingPageNome}
												</option>
											))}
										</select>
									</div>
								</div>

								{values.lead.formularioAdicional === "LEADRISK" ?
									<Fragment>
										<div className="col-12 col-md-3">
											<div className="form-group">
												<label>Seguradora Atual</label>
												<select
													disabled={values.formDisabled}
													className="form-control"
													name="seguradoraId"
													value={values.lead.seguradoraId}
													onChange={onChangeInput}
												>
													<option className="disabled" value="" />
													{values.seguradoras.map((seguradora) => (
														<option key={seguradora.seguradoraId} value={seguradora.seguradoraId}>
															{seguradora.seguradoraDescricao}
														</option>
													))}
												</select>
											</div>
										</div>
										<div className="col-12 col-md-3">
											<div className="form-group">
												<label>Data de Vencimento</label>
												<input
													type="date"
													className="form-control"
													name="vencimentoApolice"
													value={values.lead.vencimentoApolice}
													onChange={onChangeInput}
												/>
											</div>
										</div>
										<div className="col-12 col-md-6" />
									</Fragment> : null}

								<div className="col-12 col-md-3 form-group">
									<label>Consultor</label>
									<select
										disabled={values.formDisabled}
										className="form-control"
										name="leadConsultorId"
										value={values.lead.leadConsultorId}
										onChange={onChangeInput}
									>
										<option className="disabled" value="0" />
										{values.consultores.map((usuario) => (
											<option key={usuario.usuarioId} value={usuario.usuarioId}>
												{usuario.usuarioNome}
											</option>
										))}
									</select>
								</div>
								<div className="col-12 col-md-3">
									<div className="form-group">
										<label>Data da Interação</label>
										<input
											type="date"
											className="form-control"
											name="dataInteracao"
											value={values.lead.dataInteracao}
											onChange={onChangeInput}
										/>
									</div>
								</div>
								<div className="col-12 col-md-3">
									<div className="form-group">
										<label>Chance</label>
										<div className="rating">
											<div className="rating-form">
												<label htmlFor="super-happy">
													<input
														type="radio"
														className="super-happy"
														id="super-happy"
														value="1"
														name="chance"
														onChange={onChangeInput}
													/>
													<svg className="svg-rating" viewBox="0 0 24 24">
														<path d="M12,17.5C14.33,17.5 16.3,16.04 17.11,14H6.89C7.69,16.04 9.67,17.5 12,17.5M8.5,11A1.5,1.5 0 0,0 10,9.5A1.5,1.5 0 0,0 8.5,8A1.5,1.5 0 0,0 7,9.5A1.5,1.5 0 0,0 8.5,11M15.5,11A1.5,1.5 0 0,0 17,9.5A1.5,1.5 0 0,0 15.5,8A1.5,1.5 0 0,0 14,9.5A1.5,1.5 0 0,0 15.5,11M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
													</svg>
												</label>
												<label htmlFor="happy">
													<input
														type="radio"
														className="happy"
														id="happy"
														value="2"
														name="chance"
														onChange={onChangeInput}
													/>
													<svg
														className="svg-rating"
														version="1.1"
														width="100%"
														height="100%"
														viewBox="0 0 24 24"
													>
														<path d="M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M10,9.5C10,10.3 9.3,11 8.5,11C7.7,11 7,10.3 7,9.5C7,8.7 7.7,8 8.5,8C9.3,8 10,8.7 10,9.5M17,9.5C17,10.3 16.3,11 15.5,11C14.7,11 14,10.3 14,9.5C14,8.7 14.7,8 15.5,8C16.3,8 17,8.7 17,9.5M12,17.23C10.25,17.23 8.71,16.5 7.81,15.42L9.23,14C9.68,14.72 10.75,15.23 12,15.23C13.25,15.23 14.32,14.72 14.77,14L16.19,15.42C15.29,16.5 13.75,17.23 12,17.23Z" />
													</svg>
												</label>

												<label htmlFor="neutral">
													<input
														type="radio"
														className="neutral"
														id="neutral"
														value="3"
														name="chance"
														onChange={onChangeInput}
													/>
													<svg
														className="svg-rating"
														version="1.1"
														width="100%"
														height="100%"
														viewBox="0 0 24 24"
													>
														<path d="M8.5,11A1.5,1.5 0 0,1 7,9.5A1.5,1.5 0 0,1 8.5,8A1.5,1.5 0 0,1 10,9.5A1.5,1.5 0 0,1 8.5,11M15.5,11A1.5,1.5 0 0,1 14,9.5A1.5,1.5 0 0,1 15.5,8A1.5,1.5 0 0,1 17,9.5A1.5,1.5 0 0,1 15.5,11M12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C6.47,22 2,17.5 2,12A10,10 0 0,1 12,2M9,14H15A1,1 0 0,1 16,15A1,1 0 0,1 15,16H9A1,1 0 0,1 8,15A1,1 0 0,1 9,14Z" />
													</svg>
												</label>

												<label htmlFor="sad">
													<input
														type="radio"
														className="sad"
														id="sad"
														value="4"
														name="chance"
														onChange={onChangeInput}
													/>
													<svg
														className="svg-rating"
														version="1.1"
														width="100%"
														height="100%"
														viewBox="0 0 24 24"
													>
														<path d="M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M15.5,8C16.3,8 17,8.7 17,9.5C17,10.3 16.3,11 15.5,11C14.7,11 14,10.3 14,9.5C14,8.7 14.7,8 15.5,8M10,9.5C10,10.3 9.3,11 8.5,11C7.7,11 7,10.3 7,9.5C7,8.7 7.7,8 8.5,8C9.3,8 10,8.7 10,9.5M12,14C13.75,14 15.29,14.72 16.19,15.81L14.77,17.23C14.32,16.5 13.25,16 12,16C10.75,16 9.68,16.5 9.23,17.23L7.81,15.81C8.71,14.72 10.25,14 12,14Z" />
													</svg>
												</label>

												<label htmlFor="super-sad">
													<input
														type="radio"
														className="super-sad"
														id="super-sad"
														value="5"
														name="chance"
														onChange={onChangeInput}
													/>
													<svg className="svg-rating" viewBox="0 0 24 24">
														<path d="M12,2C6.47,2 2,6.47 2,12C2,17.53 6.47,22 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M16.18,7.76L15.12,8.82L14.06,7.76L13,8.82L14.06,9.88L13,10.94L14.06,12L15.12,10.94L16.18,12L17.24,10.94L16.18,9.88L17.24,8.82L16.18,7.76M7.82,12L8.88,10.94L9.94,12L11,10.94L9.94,9.88L11,8.82L9.94,7.76L8.88,8.82L7.82,7.76L6.76,8.82L7.82,9.88L6.76,10.94L7.82,12M12,14C9.67,14 7.69,15.46 6.89,17.5H17.11C16.31,15.46 14.33,14 12,14Z" />
													</svg>
												</label>
											</div>
										</div>
									</div>
								</div>
								<div className="col-12 col-md-1">
									<div className="form-group">
										<label>Scoring</label>
										<input
											type="text"
											min="0"
											max="5"
											className="form-control"
											name="scoring"
											value={values.lead.scoring}
											onChange={onChangeInput}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12 text-center">
						{values.permissoesPagina != null && values.permissoesPagina.incluir ? (
							<button
								type="button"
								onClick={salvar}
								disabled={formularioComErro}
								className="btn btn-default"
							>
								Salvar
							</button>
						) : null}
					</div>
				</div>
			</form>
		</Fragment>
	);
};

export default Incluir;
