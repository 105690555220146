import React from "react";
import ImportarLeadProvider from "./ImportarLeadContext";
import ImportarLead from "./Events/ImportarLead";

const LeadCadstroManual = () => {
  return (
    <ImportarLeadProvider>
      <div className="container-fluid pl-3 pt-3 pb-3">
        <ImportarLead />
      </div>
    </ImportarLeadProvider>
  );
};

export default LeadCadstroManual;
