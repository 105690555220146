import React, { Fragment, useContext, useEffect } from "react";
import "./FormularioCampanha.css";
import Formulario from "../../../../../Components/Cadastro/Formulario/Formulario";
import FormularioComplementar from "../../../../../Components/Cadastro/FormularioComplementar/FormularioComplementar";
import { CampanhaContext } from "../CampanhaContext";
import { useParams } from "react-router-dom";
import FormularioCampanhaSegmentacao from "./FormularioCampanhaSegmentacao";
import FormularioCampanhaJornada from "./FormularioCampanhaJornada";

const FormularioCampanha = () => {
  const { values, setValues, getDetailHandler } = useContext(CampanhaContext);

  let { id, processo } = useParams();

  useEffect(() => {
    getDetailHandler(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      data: {
        ...values.data,
        [name]: value,
      },
    });
  }

  return (
    <Fragment>
      <Formulario context={CampanhaContext} />
      {processo ? (
        <Fragment>
            <FormularioCampanhaJornada />
        </Fragment>
      ) : (
        <Fragment>
          <ul
            className="nav nav-tabs"
            style={{ paddingLeft: 15, borderBottomColor: "#f7f8fa" }}
            id="myTab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <a
                className="nav-link active"
                id="dadosBasicosCampanha-tab"
                data-toggle="tab"
                href="#dadosBasicosCampanha"
                role="tab"
                aria-controls="dadosBasicosCampanha"
                aria-selected="true"
              >
                Contato
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className="nav-link"
                id="segmentacaoCampanha-tab"
                data-toggle="tab"
                href="#segmentacaoCampanha"
                role="tab"
                aria-controls="segmentacaoCampanha"
                aria-selected="false"
              >
                Público
              </a>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="dadosBasicosCampanha"
              role="tabpanel"
              aria-labelledby="dadosBasicosCampanha-tab"
            >
              <FormularioComplementar>
                <div className="col-12 col-md-6 form-group">
                  <label>Nome</label>
                  <input
                    disabled={values.formDisabled}
                    className="form-control"
                    type="text"
                    maxLength="50"
                    name="campanhaNome"
                    value={values.data.campanhaNome}
                    onChange={onChangeInput}
                  />
                </div>
                <div className="col-12 col-md-2">
                  <div className="form-group">
                    <label>Início</label>
                    <input
                      disabled={values.formDisabled}
                      type="date"
                      className="form-control"
                      name="campanhaDataInicio"
                      value={values.data.campanhaDataInicio}
                      onChange={onChangeInput}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-2">
                  <div className="form-group">
                    <label>Fim</label>
                    <input
                      disabled={values.formDisabled}
                      type="date"
                      className="form-control"
                      name="campanhaDataFim"
                      value={values.data.campanhaDataFim}
                      onChange={onChangeInput}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-2 form-group">
                  <label>Status</label>
                  <select
                    disabled={values.formDisabled}
                    className="form-control"
                    name="campanhaStatus"
                    value={values.data.campanhaStatus}
                    onChange={onChangeInput}
                  >
                    <option className="disabled"></option>
                    <option value="A"> Ativo </option>
                    <option value="I"> Inativo </option>
                  </select>
                </div>
                <div className="col-12 form-group">
                  <label>Observação</label>
                  <textarea
                    disabled={values.formDisabled}
                    className="form-control"
                    type="text"
                    cols="30"
                    rows="10"
                    name="campanhaObservacao"
                    value={values.data.campanhaObservacao}
                    onChange={onChangeInput}
                  />
                </div>
              </FormularioComplementar>
            </div>
            <div
              className="tab-pane fade"
              id="segmentacaoCampanha"
              role="tabpanel"
              aria-labelledby="segmentacaoCampanha-tab"
            >
              <FormularioCampanhaSegmentacao />
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default FormularioCampanha;
