import React, { useState, useEffect, Fragment } from 'react';
import './PermormanceConsultor.css';
import GraficoTemplate from '../GraficoTemplate/GraficoTemplate';
import GraficoBarrasLinha from '../GraficoBarrasLinha/GraficoBarrasLinha';

const initialState = {
	legendas: [],
	dataChart: [ 0 ],
	dataChartLinha: [ 0 ]
};
const tatalizador = 'diario';
const PermormanceConsultor = ({ dataPerformace = null, children }) => {
	const [ metasEquipesValues, setmetasEquipesValues ] = useState(initialState);
	const [ metasConsultorValues, setmetasConsultorValues ] = useState(initialState);

	useEffect(() => {
		if (!dataPerformace) return;
		async function carregarDependencias() {
			metasEquipesHandler(tatalizador);
			metasConsultorHandler(tatalizador);
		}
		carregarDependencias();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const metasEquipesHandler = (agrupador) => {
		if (!dataPerformace) {
			return;
		}
		let legendas = dataPerformace.metasEquipes.legendas;
		let dataChart = dataPerformace.metasEquipes.metasAtingida[agrupador];
		let dataChartLinha = dataPerformace.metasEquipes.metasDefinida[agrupador];
		setmetasEquipesValues({ legendas, dataChart, dataChartLinha });
	};

	const metasConsultorHandler = (agrupador) => {
		if (!dataPerformace) {
			return;
		}
		let legendas = dataPerformace.metasConsultor.legendas;
		let dataChart = dataPerformace.metasConsultor.metasAtingida[agrupador];
		let dataChartLinha = dataPerformace.metasConsultor.metasDefinida[agrupador];
		setmetasConsultorValues({ legendas, dataChart, dataChartLinha });
	};

	return (
		<Fragment>
			<div data-toggle="modal" data-target="#modalPerformance" data-dismiss="modal">
				<div className="row justify-content-center">{children}</div>
			</div>

			<div
				className="modal fade"
				id="modalPerformance"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="modalPerformanceLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered PageAtendimento_ModalSizeXXL">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="modalPerformanceLabel">
								Minha Performance
							</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="row">
								<GraficoTemplate
									id="metasEquipes"
									titulo="Meta das Equipes"
									selectResultHandler={metasEquipesHandler}
								>
									<GraficoBarrasLinha
										legendas={metasEquipesValues.legendas}
										dataChart={metasEquipesValues.dataChart}
										dataChartLinha={metasEquipesValues.dataChartLinha}
									/>
								</GraficoTemplate>

								<GraficoTemplate
									id="metasConsultor"
									titulo="Meta dos Consultores"
									selectResultHandler={metasConsultorHandler}
								>
									<GraficoBarrasLinha
										legendas={metasConsultorValues.legendas}
										dataChart={metasConsultorValues.dataChart}
										dataChartLinha={metasConsultorValues.dataChartLinha}
									/>
								</GraficoTemplate>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default PermormanceConsultor;
