import React, { Fragment } from 'react';
import './Avatar.css';

const Avatar = ({ src, className = '' }) => {
	return (
		<Fragment>
			<div role="button" className="photo-thumb">
				<img src={src} alt="" srcSet="" className={`rounded-circle img-fluid ${className}`} />
			</div>
		</Fragment>
	);
};

export default Avatar;
