import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "Hunter";

export const Listar = (hunter) =>
  RequestWebApi.get(`/${controller}/Listar`, {
    params: { ...hunter },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const hunters = resultData;
      return { success, hunters };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { hunters: [] };
    });

export const Detalhes = (hunterId) =>
  RequestWebApi.get(`/${controller}/Detalhes`, {
    params: { hunterId: hunterId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const hunter = resultData;
      return { success, hunter };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, hunter: {} };
    });

export const Incluir = (hunter) =>
  RequestWebApi.post(`/${controller}/Incluir`, hunter)
    .then(function (response) {
      const { resultData, success } = response.data;
      const novoGrupoUsuario = resultData;
      return { success, novoGrupoUsuario };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Atualizar = (hunter) =>
  RequestWebApi.put(`/${controller}/Atualizar`, hunter)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (hunterId) =>
  RequestWebApi.delete(`/${controller}/Remover`, {
    params: { hunterId: hunterId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });
