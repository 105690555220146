import { RequestWebApi, validateRequestErrorStatus } from './Requests';

const controller = 'PipeLine';
export const Listar = (pipeLine) =>
	RequestWebApi.get(`/${controller}/Listar`, {
		params: { ...pipeLine }
	})
		.then(function (response) {
			const { resultData, success } = response.data;
			const pipeLineList = resultData;
			return { success, pipeLineList };
		})
		.catch(function (error) {
			validateRequestErrorStatus(error.response.status);
			return { pipeLineList: [] };
		});

export const Detalhes = (pipeLineId) =>
	RequestWebApi.get(`/${controller}/Detalhes`, {
		params: { pipeLineId: pipeLineId }
	})
		.then(function (response) {
			const { resultData, success } = response.data;
			return { success, ...resultData };
		})
		.catch(function (error) {
			validateRequestErrorStatus(error.response.status);
			const { success, message, errors } = error.response.data;
			return {
				success,
				message,
				errors
			};
		});

export const Incluir = (pipeLine) =>
	RequestWebApi.post(`/${controller}/Incluir`, pipeLine)
		.then(function (response) {
			const { resultData, success } = response.data;
			const novaPipeLine = resultData;
			return { success, novaPipeLine };
		})
		.catch(function (error) {
			validateRequestErrorStatus(error.response.status);
			const { success, message, errors } = error.response.data;
			return { success, message, errors };
		});

export const Atualizar = (pipeLine) =>
	RequestWebApi.put(`/${controller}/Atualizar`, pipeLine)
		.then(function (response) {
			const { success } = response.data;
			return { success };
		})
		.catch(function (error) {
			validateRequestErrorStatus(error.response.status);
			const { success, message, errors } = error.response.data;
			return { success, message, errors };
		});

export const Remover = (pipeLineId) =>
	RequestWebApi.delete(`/${controller}/Remover`, {
		params: { pipeLineId: pipeLineId }
	})
		.then(function (response) {
			const { success } = response.data;
			return { success };
		})
		.catch(function (error) {
			validateRequestErrorStatus(error.response.status);
			const { success, message, errors } = error.response.data;
			return { success, message, errors };
		});
