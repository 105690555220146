import React, { Fragment } from "react";
import CobrancaRealizada from "./CobrancaRealizada";

const CobrancasRealizadas = ({
  defineDetalhesObjeto = () => { },
  cobrancas = [],
}) => {
  return (
    <Fragment>
      <div className="default-title">Cobranças</div>
      <div className="card shadow-sm mb-4">
        <div className="row align-items-center">
          {cobrancas.map((cobranca) => {
            return <Fragment key={cobranca.cobrancaId}>
              <CobrancaRealizada defineDetalhesObjeto={defineDetalhesObjeto} cobranca={cobranca} />
              <div className="col-12">
                <hr />
              </div>
            </Fragment>
          })}

        </div>
      </div>
    </Fragment>
  );
};

export default CobrancasRealizadas;
