import React, { useEffect, useState } from "react";
import $ from "jquery";
import "./TelefonarModal.css";
import { Fragment } from "react";
let eventId = 0;

export const showTelefonarModal = (id) => {
  eventId = id;
  $("#telefonarModal").modal({
    backdrop: "static",
    keyboard: false,
    show: true,
  });
};
export const hideTelefonarModal = () => {
  $("#telefonarModal").modal("hide");
};

function initialState() {
  return {
    tipo: "telefonar",
    produto: "",
    equipe: "",
  };
}
let equipesProduto = [];
const TelefonarModal = ({
  eventoCorrente,
  confirmar = (data) => {},
  cancelar = (data) => {},
  produtos = [],
  equipes = [],
  formDisabled = true,
}) => {
  const [valuesEventoTelefonar, setEventoTelefonar] = useState(initialState());

  const formularioComErro =
    !valuesEventoTelefonar.produto ||
    !valuesEventoTelefonar.produto === "0" ||
    !valuesEventoTelefonar.equipe ||
    !valuesEventoTelefonar.equipe === "0";

  useEffect(() => {
    if (eventoCorrente) {
      setEventoTelefonar(eventoCorrente);
    } else {
      setEventoTelefonar(initialState());
    }
    equipesProduto = equipes;
  }, [eventoCorrente, equipes]);

  const confirmarHandler = () => {
    setEventoTelefonar(initialState());
    confirmar({ id: eventId, ...valuesEventoTelefonar });
    hideTelefonarModal();
  };

  const cancelarHandler = () => {
    setEventoTelefonar(initialState());
    hideTelefonarModal();
    cancelar({ id: eventId, ...valuesEventoTelefonar });
  };

  function onChangeInput(event) {
    const { name, value } = event.target;
    setEventoTelefonar({
      ...valuesEventoTelefonar,
      [name]: value,
    });
  }

  function onChangeInputProduto(event) {
    onChangeInput(event);
    const { value } = event.target;
    equipesProduto = equipes.filter((x) => x.produtoId === parseInt(value));
  }

  return (
    <Fragment>
      <div
        className="modal fade"
        id="telefonarModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="telefonarModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="telefonarModalLabel">
                Telefonar
              </h5>
            </div>
            <div className="modal-body componenteAgendaRedirecionar_modalBody">
              <div className="row align-items-center">
                <div className="col-6 form-group">
                  <label>Produto</label>
                  <select
                    disabled={formDisabled}
                    className="form-control"
                    name="produto"
                    value={
                      valuesEventoTelefonar.produto
                        ? valuesEventoTelefonar.produto
                        : ""
                    }
                    onChange={onChangeInputProduto}
                  >
                    <option className="disabled" value=""></option>
                    {produtos.map((produto) => (
                      <option key={produto.produtoId} value={produto.produtoId}>
                        {produto.produtoNome}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-6 form-group">
                  <label>Equipe</label>
                  <select
                    disabled={formDisabled}
                    className="form-control"
                    name="equipe"
                    value={
                      valuesEventoTelefonar.equipe
                        ? valuesEventoTelefonar.equipe
                        : ""
                    }
                    onChange={onChangeInput}
                  >
                    <option className="disabled" value=""></option>
                    {equipesProduto.map((equipe) => (
                      <option key={equipe.equipeId} value={equipe.equipeId}>
                        {equipe.equipeNome}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  cancelarHandler();
                }}
              >
                {formDisabled ? "Fechar" : "Cancelar"}
              </button>
              {formDisabled ? null : (
                <button
                  disabled={formularioComErro}
                  type="button"
                  className="btn btn-default"
                  onClick={confirmarHandler}
                >
                  Confirmar
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TelefonarModal;
