import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRoute from "../../../../Routes/PrivateRoutes";
import JornadaTrabalhoProvider from "./JornadaTrabalhoContext";
import PesquisarJornadaTrabalho from "./Events/PesquisarJornadaTrabalho";
import FormularioJornadaTrabalho from "./Events/FormularioJornadaTrabalho";

const Index = () => {
  let match = useRouteMatch();
  return (
    <JornadaTrabalhoProvider>
      <div className="container-fluid pl-3 pt-3 pb-3">
        <Switch>
          <PrivateRoute
            exact
            path={`${match.url}`}
            component={PesquisarJornadaTrabalho}
          />
          <PrivateRoute
            path={`${match.url}/:id`}
            component={FormularioJornadaTrabalho}
          />
        </Switch>
      </div>
    </JornadaTrabalhoProvider>
  );
};

export default Index;
