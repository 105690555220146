/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext } from 'react';
import AppContext from '../../../../Store/AppContext';
import {
	Listar as ListarSegmentacaoPublico,
	Detalhes as DetalhesSegmentacaoPublico,
	Remover as RemoverSegmentacaoPublico,
	Incluir as IncluirSegmentacaoPublico,
	Atualizar as AtualizarSegmentacaoPublico
} from '../../../../Services/SegmentacaoPublico';
import { Listar as ListarProduto } from '../../../../Services/Produto';
import { Listar as ListarBase } from '../../../../Services/BaseProspect';
import { Listar as ListarUsuarios } from '../../../../Services/Usuario';
import { ListarBaseProspectItens } from '../../../../Services/BaseProspect';
import { useHistory } from 'react-router-dom';
import { validarPermissoesPagina } from '../../../../Utils/validarPermissoesPagina';
import { removeItemFromArray } from '../../../../Utils/globalFunctions';

export const SegmentacaoPublicoContext = createContext();

const tituloTela = 'Segmentação de Público';

const defaultData = {
	segmentacaoPublicoId: 0,
	produtoId: 0,
	segmentacaoPublicoNome: '',
	segmentacaoPublicoDescr: '',
	segmentacaoPublicoDataHoraCriacao: '',
	usuarioIdCriacao: 0,
	segmentacaoPublicoDataHoraUltAlter: '',
	usuarioIdUltAlter: 0,
	segmentacaoPublicoStatus: '',
	baseProspectsItens: [],
	basesProspect: []
};

const searchData = {
	produtoId: 0,
	segmentacaoPublicoNome: '',
	segmentacaoPublicoStatus: ''
};

const columnsDefinition = [
	{
		text: 'Id',
		dataField: 'segmentacaoPublicoId',
		hidden: true
	},
	{
		text: 'Data',
		dataField: 'segmentacaoPublicoDataHoraCriacao',
		headerStyle: { width: '150px' },
		sort: true
	},
	{
		text: 'Nome da Segmentação',
		dataField: 'segmentacaoPublicoNome',
		headerStyle: { width: '450px' },
		sort: true
	},
	{
		text: 'Status',
		dataField: 'segmentacaoPublicoStatus',
		headerStyle: { width: '80px' },
		sort: true,
		formatter: (cell, row) => {
			return cell === 'A' ? (
				<span className="badge badge-success">Ativo</span>
			) : (
				<span className="badge badge-danger">Inátivo</span>
			);
		}
	}
];

const statusList = [{ key: 'A', value: 'Ativo' }, { key: 'I', value: 'Inativo' }];

function initialState() {
	return {
		searchData: searchData,
		resultSearch: [],
		columnsDefinition: columnsDefinition,
		data: defaultData,
		statusList: statusList,
		formDisabled: true,

		produtos: [],
		baseProspects: [],
		baseProspectsItens: [],
		baseProspectsSelecionados: [],
		baseProspectsItensSelecionados: []
	};
}

const SegmentacaoPublicoProvider = ({ children }) => {
	const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(AppContext);
	const [values, setValues] = useState(initialState());
	let history = useHistory();

	async function loadDependences() {
		const permissoesPagina = await validarPermissoesPagina();
		const responseListarProduto = await ListarProduto({});
		const responseListarBase = await ListarBase({});
		const responseListarUsuarios = await ListarUsuarios({});

		if (!responseListarProduto.success || !responseListarBase.success || !responseListarUsuarios.success) {
			showMessage('Erro', 'Erro Inesperado ao carregar dados basicos.');
			return {
				permissoesPagina: [],
				produtos: [],
				baseProspects: [],
				usuarios: []
			};
		}

		const produtos = await responseListarProduto.produtos.filter((item) => item.produtoAtivo === 'A');
		const baseProspects = await responseListarBase.baseProspects.filter((item) => item.baseProspectStatus === 'A');
		const usuarios = await responseListarUsuarios.usuarios;

		return { permissoesPagina, produtos, baseProspects, usuarios };
	}

	const resetScreen = () => {

		async function carregarDependencias() {
			showLoading();
			const dependencias = await loadDependences();
			setTituloTela(tituloTela);
			setValues({
				...initialState(),
				permissoesPagina: await dependencias.permissoesPagina,
				produtos: await dependencias.produtos,
				baseProspects: await dependencias.baseProspects
			});
			hideLoading();
		}
		carregarDependencias();
	};

	const searchHandler = async () => {
		showLoading();
		const responseListarSegmentacaoPublico = await ListarSegmentacaoPublico(values.searchData);

		if (!responseListarSegmentacaoPublico.success) {
			showMessage('Erro', 'Erro Inesperado durante a pesquisa.');
			hideLoading();
			return;
		}
		const resultSearchData = await responseListarSegmentacaoPublico.segmentacaoPublicos;
		resultSearchData.map((data) => (data.Id = data.segmentacaoPublicoId));
		setValues({
			...values,
			resultSearch: resultSearchData
		});

		if (resultSearchData.length === 0) {
			showMessage('Alerta', 'Nenhum registro encontrado.');
		}
		hideLoading();
	};

	const removeHandler = async (id) => {
		showLoading();
		const responseRemover = await RemoverSegmentacaoPublico(id);
		if (!responseRemover.success) {
			hideLoading();
			showMessage('Erro', `Erro durante a exclusão: ${responseRemover.message}`);
			return;
		}
		searchHandler();
		hideLoading();
		showMessage('Sucesso', 'Registro excluido.');
	};

	const getDetailHandler = async (id) => {
		showLoading();
		const dependencias = await loadDependences();
		if (id > 0) {
			const responseDetalhes = await DetalhesSegmentacaoPublico(id);

			const { success, segmentacaoPublico } = await responseDetalhes;

			if (!success) {
				hideLoading();
				showMessage('Erro', 'Erro ao buscar os detalhes do registro.');
				setValues({
					...values,
					data: defaultData,
					permitirImportacao: false,
					permissoesPagina: await dependencias.permissoesPagina
				});
			}

			let usuarioCriacao = await dependencias.usuarios.find(
				(usuario) => usuario.usuarioId === segmentacaoPublico.usuarioIdCriacao
			);

			let usuarioAlteracao = await dependencias.usuarios.find(
				(usuario) => usuario.usuarioId === segmentacaoPublico.usuarioIdUltAlter
			);

			let basesProspect = [];

			responseDetalhes.segmentacaoPublico.basesProspect.map((publico) => {
				basesProspect.push({
					...publico,
					id: publico.baseProspectId
				});
			});

			let baseProspectsItensIdList = [];
			responseDetalhes.segmentacaoPublico.baseProspectsItens.map((baseProspectsItem) => {
				baseProspectsItensIdList.push(baseProspectsItem.baseProspectItensId);
			});

			let baseProspectsItens = await obterProspects(basesProspect);

			setValues({
				...values,
				data: {
					...segmentacaoPublico,
					baseProspectsItens: baseProspectsItensIdList,
					usuarioNomeCriacao: usuarioCriacao ? usuarioCriacao.usuarioNome : null,
					usuarioNomeAlteracao: usuarioAlteracao ? usuarioAlteracao.usuarioNome : null,
					basesProspect
				},
				permitirImportacao: false,
				permissoesPagina: await dependencias.permissoesPagina,
				produtos: await dependencias.produtos,
				baseProspects: await dependencias.baseProspects,
				baseProspectsItens: baseProspectsItens
			});
		} else {
			setValues({
				...values,
				data: {
					...defaultData,
					segmentacaoPublicoId: 0
				},
				permissoesPagina: await dependencias.permissoesPagina,
				produtos: await dependencias.produtos,
				baseProspects: await dependencias.baseProspects,
				permitirImportacao: true,
				formDisabled: false
			});
		}
		hideLoading();
	};

	const createData = async () => {
		showLoading();
		const responseIncluir = await IncluirSegmentacaoPublico(values.data);
		if (!responseIncluir.success) {
			hideLoading();
			showMessage('Erro', `Erro ao criar o registro: ${responseIncluir.message}`);

			responseIncluir.errors.map((erro) => showMessage('Erro', erro.errorMessage));
			return;
		}

		resetScreen();
		hideLoading();
		showMessage('Sucesso', 'Registro criado.');
		history.goBack();
	};

	const editHandler = () => {
		setValues({
			...values,
			formDisabled: false
		});
	};

	const updateData = async () => {
		showLoading();
		const responseAtualizar = await AtualizarSegmentacaoPublico(values.data);
		if (!responseAtualizar.success) {
			hideLoading();
			showMessage('Erro', `Erro ao atualizar o registro: ${responseAtualizar.message}`);
			return;
		}
		resetScreen();
		hideLoading();
		showMessage('Sucesso', 'Registro atualizado.');
		history.goBack();
	};

	const saveHandler = async () => {
		if (values.data.segmentacaoPublicoId === 0) {
			await createData();
		} else {
			await updateData();
		}
	};

	const cancelHandler = () => {
		setValues({
			...values,
			data: defaultData,
			formDisabled: true
		});
	};

	const obterProspects = async (basesProspect = []) => {
		let baseProspectIdList = [];
		basesProspect.map((item) => baseProspectIdList.push(item.baseProspectId));

		const responseListarBaseProspectItens = await ListarBaseProspectItens(baseProspectIdList);

		if (!responseListarBaseProspectItens.success) {
			showMessage('Erro', 'Erro ao buscar Publico.');
			hideLoading();
			return;
		}
		const baseProspectsItens = await responseListarBaseProspectItens.baseProspectsItens;

		return baseProspectsItens;
	};

	const carregarProspects = async (basesProspect = []) => {
		showLoading();

		let baseProspectsItens = await obterProspects(basesProspect);

		let baseProspectsItensSelecionados = [];
		let baseProspectsItensSelecionadosNew = [];
		baseProspectsItensSelecionados = values.data.baseProspectsItens;

		baseProspectsItens.map((prospect) => {
			let itemExistente = baseProspectsItensSelecionados.find(
				(itemSelecionado) => itemSelecionado === prospect.baseProspectItensId
			);
			if (itemExistente) {
				baseProspectsItensSelecionadosNew.push(prospect.baseProspectItensId);
			}
		});

		setValues({
			...values,
			data: {
				...values.data,
				baseProspectsItens: baseProspectsItensSelecionadosNew,
				basesProspect: basesProspect
			},
			baseProspectsItens
		});

		hideLoading();
	};

	const adicionarPublico = async (id) => {
		let baseProspectJaAdiocionada = values.data.basesProspect.find(
			// eslint-disable-next-line eqeqeq
			(item) => item.id == id
		);
		if (baseProspectJaAdiocionada) {
			showMessage('Alerta', 'Publico já incluído.');
			return;
		}
		let basesProspect = values.data.basesProspect;

		if (!basesProspect) basesProspect = [];

		let baseProspect = values.baseProspects.find((item) => item.baseProspectId == id);

		basesProspect.push({
			...baseProspect,
			id: id
		});

		carregarProspects(basesProspect);
	};

	const removerPublico = (id) => {
		var basesProspectNew = values.data.basesProspect;

		removeItemFromArray(basesProspectNew, basesProspectNew.find((item) => item.id === id));

		carregarProspects(basesProspectNew);
	};

	return (
		<SegmentacaoPublicoContext.Provider
			value={{
				values,
				setValues,
				resetScreen,
				searchHandler,
				cancelHandler,
				removeHandler,
				saveHandler,
				getDetailHandler,
				editHandler,
				adicionarPublico,
				removerPublico
			}}
		>
			{children}
		</SegmentacaoPublicoContext.Provider>
	);
};

export default SegmentacaoPublicoProvider;
