import React from "react";

const Resultado = ({ exibirCodigo, linha, identificador, sucesso, mensagem }) => {
  return (
    <tr key={linha}>
      <td>{linha}</td>
      <td>{identificador}</td>
      <td>
        <span
          className={sucesso ? "badge badge-success" : "badge badge-danger"}
        >
          {sucesso ? "Sucesso" : "Erro"}
        </span>
      </td>
      <td>{mensagem}</td>
    </tr>
  );
};

export default Resultado;
