import React from "react";
import "./SideBarTopLink.css";
import { useHistory } from "react-router-dom";
const images = require.context("../../../assets/img/icones/sidebar", true);

const SideBarTopLink = ({ id, menu }) => {
  const history = useHistory();

  const openContent = (tituloTela, path) => {
    return history.push(`/${path}`);
  };

  return (
    <div
      href={`#sideBarTopLink${id}`}
      role="button"
      className="list-group-item d-inline-block collapsed bg-transparent"
      data-parent="#sidebar"
      onClick={() => openContent(menu.descricao, menu.path)}
    >
      <img src={images(`./${menu.icone ?menu.icone: "cadastros.png" }`)} alt="" className="nav-link__icone" />
      {menu.nome}
    </div>
  );
};

export default SideBarTopLink;
