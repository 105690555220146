import React, { Fragment } from "react";
import "./DataTableDefaultSelection.css";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { removeItemFromArray } from "../../Utils/globalFunctions";

const paginationOptions = {
  pageStartIndex: 0,
  paginationSize: 5,
  showTotal: true,
  withFirstAndLast: true,
  alwaysShowAllBtns: true,
  firstPageText: "Primeira",
  prePageText: "Anterior",
  nextPageText: "Proximo",
  lastPageText: "Ultima",
  nextPageTitle: "Proximo",
  prePageTitle: "Anterior",
  firstPageTitle: "Primeira",
  lastPageTitle: "Ultima",
  hideSizePerPage: true,
  hidePageListOnlyOnePage: true,
  paginationTotalRenderer: (from, to, size) => (
    <span>
      Mostrando de {from} até {to} de {size} registros
    </span>
  ),
};

export const DataTableDefaultSelection = ({
  keyField = "keyField",
  columns = [],
  data = [],
  selectedItens = [],
  nonSelectable = [],
  selectedItensStyle = {},
  selectedItemHandler = (selectedItens) => {}
}) => {
  const handleOnSelect = (row, isSelect) => {
    let selectedItensLocal = selectedItens;
    if (isSelect) {
      selectedItensLocal.push(row[keyField]);
    } else {
      removeItemFromArray(
        selectedItensLocal,
        selectedItensLocal.find((item) => item === row[keyField])
      );
    }

    selectedItemHandler(selectedItensLocal);
  };

  const handleOnSelectAll = (isSelect, rows) => {
    let selectedItensLocal = selectedItens;
    rows.map((row) => {
      if (isSelect) {
        selectedItensLocal.push(row[keyField]);
      } else {
        removeItemFromArray(
          selectedItensLocal,
          selectedItensLocal.find((item) => item === row[keyField])
        );
      }
      return row;
    });
    selectedItemHandler(selectedItensLocal);
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    selected: selectedItens,
    nonSelectable: nonSelectable,
    style: selectedItensStyle,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <BootstrapTable
            bootstrap4={true}
            condensed={true}
            noDataIndication={() => "Nenhum Registro Selecionado"}
            striped={true}
            hover={true}
            keyField={keyField}
            data={data}
            columns={columns.map((column) => {
              return {
                ...column,
                headerStyle: { ...column.headerStyle, textAlign: "left" },
              };
            })}
            rowStyle={{ textAlign: "left" }}
            pagination={paginationFactory(paginationOptions)}
            selectRow={selectRow}
            filter={filterFactory()}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default DataTableDefaultSelection;
