import $ from "jquery";
import React, { Fragment, useContext } from "react";
import ResultadoImportacao from "../../../../../Components/Importacao/ResultadoImportacao/ResultadoImportacao";
import TabelaResultado from "../../../../../Components/Importacao/TabelaResultado/TabelaResultado";
import { ImportarDadosSeguradoraContext } from "../ImportarDadosSeguradoraContext";
import "./ImportarDadosSeguradora.css";


const ImportarDadosSeguradora = () => {
  $(".custom-file-input").on("change", function () {
    var fileName = $(this).val().split("\\").pop();
    $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
  });

  const { values, setValues, importar, downloadTemplate } = useContext(
    ImportarDadosSeguradoraContext
  );

  function onChangeInputArquivo(event) {
    setValues({
      ...values,
      arquivo: event.target.files[0],
    });
  }

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value
    });
  }

  const bloquearImportacao = !values.arquivo || values.anoProposta == 0;

  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <div className="default-title">Importação</div>
          <div className="card shadow-sm mb-4">
            <div className="row align-items-end">
              <div className="col-12 col-md-2">
                <label>
                  Ano Proposta
                  <span className="campoObratorio">*</span>
                </label>
                <select
                  disabled={values.formDisabled}
                  className="form-control"
                  name="anoProposta"
                  value={values.anoProposta}
                  onChange={onChangeInput}
                >
                  <option value="0" > SELECIONE </option>
                  {values.anoPropostaList.map((anoProposta) => (
                    <option key={anoProposta} value={anoProposta}>
                      {anoProposta}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-12 col-md-5">
                <label>Escolher arquivo<span className="campoObratorio">*</span></label>
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="customFile"
                    name="arquivo"
                    accept=".xlsx , .xls "
                    onChange={(event) => onChangeInputArquivo(event)}
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="customFile"
                  ></label>
                </div>
              </div>
              <div className="col-auto">
                {values.permissoesPagina != null &&
                  values.permissoesPagina.incluir ? (
                  <button
                    type="button"
                    disabled={bloquearImportacao}
                    onClick={importar}
                    className="btn btn-success"
                  >
                    Importar Arquivo
                  </button>
                ) : null}
              </div>
              <div className="col-auto">
                <button
                  type="button"
                  onClick={downloadTemplate}
                  className="btn btn-info"
                >
                  Download de Layout
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="default-title">Resultado</div>
        </div>
        <div className="col-12 col-md-9">
          <div className="card shadow-sm mb-4">
            <div className="row">
              <TabelaResultado
                resultadosImportacao={values.resultadosImportacao}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3">
          <div className="card shadow-sm mb-4">
            <div className="row">
              <ResultadoImportacao
                totalImportados={values.totalPropostasImportados}
                totalSucesso={values.totalPropostasComSucesso}
                totalErro={values.totalPropostasComErro}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ImportarDadosSeguradora;
