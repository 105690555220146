import React, { Fragment, useContext } from 'react';
import { useState } from 'react';
import $ from 'jquery';
import './ModalCotacaoAutoRastreador.css';
import Cotacoes from './Cotacoes/Cotacoes';
import FormularioTransmissao from './FormularioTransmissao/FormularioTransmissao';
import FormularioFinalizacao from './FormularioFinalizacao/FormularioFinalizacao';
import FormularioErrosEAlertas from './FormularioErrosEAlertas/FormularioErrosEAlertas';
import { GerarCotacao, TransmitirProposta } from '../../../../../Services/Cotacoes';
const modalCotacaoAutoRastreadorInitialState = {
	passo: 1,
	passoFinal: 3,
	cotacaoSelecionada: null
};

const mapearDadosCotacao = (autoRastreador) => {
	return {
		nome: autoRastreador.leadAutoRastreadorNomePrincCondutor,
		cpfCnpj: autoRastreador.leadAutoRastreadorCpfPrincCondutor,
		dataNascimento: autoRastreador.leadAutoRastreadorDataNascPrincCondutor,
		sexo: autoRastreador.leadAutoRastreadorSexoPrincCondutor,
		estadoCivil: autoRastreador.leadAutoRastreadorEstadoCivilPrincCondutor,
		nomePrincipalCondutor: autoRastreador.leadAutoRastreadorNomePrincCondutor,
		cpfCnpjPrincipalCondutor: autoRastreador.leadAutoRastreadorCpfPrincCondutor,
		dataNascimentoPrincipalCondutor: autoRastreador.leadAutoRastreadorDataNascPrincCondutor,
		sexoPrincipalCondutor: autoRastreador.leadAutoRastreadorSexoPrincCondutor,
		estadoCivilPrincipalCondutor: autoRastreador.leadAutoRastreadorEstadoCivilPrincCondutor,
		marca: autoRastreador.leadAutoRastreadorFabricante,
		modelo: autoRastreador.leadAutoRastreadorModelo,
		anoModelo: autoRastreador.leadAutoRastreadorAnoModelo,
		anoFabricacao: autoRastreador.leadAutoRastreadorAnoFabricacao,
		cepPernoite: autoRastreador.leadAutoRastreadorCepPernoite,
		classeBonus: autoRastreador.leadAutoRastreadorClasseBonus,
		tipoUtilizacaoDut: autoRastreador.leadAutoRastreadorTipoUtilizacaoDut,
		tipoUtilizacao: autoRastreador.leadAutoRastreadorTipoUtilizacao,
		tipoContratacao: 1,
		zeroKm: autoRastreador.leadAutoRastreadorZeroKm,
		possuiOutroVeiculo: autoRastreador.leadAutoRastreadorPossuiOutroVeiculo,
		pergunta1: autoRastreador.leadAutoRastreadorPergunta01,
		pergunta2: autoRastreador.leadAutoRastreadorPergunta02,
		pergunta3: autoRastreador.leadAutoRastreadorPergunta03,
		pergunta4: autoRastreador.leadAutoRastreadorPergunta04,
		valorComissao: autoRastreador.leadAutoRastreadorValorComissao,
		codigoCobertura: autoRastreador.codigoCobertura,
		percDesconto: autoRastreador.leadAutoRastreadorPercDesconto,
		valorDanosCorporais: autoRastreador.leadAutoRastreadorValorDanosCorporais,
		valorDanosMateriais: autoRastreador.leadAutoRastreadorValorDanosMateriais,
		valorDanosMorais: autoRastreador.leadAutoRastreadorValorDanosMorais
	};
};

export const buscarCotacoes = async (autoRastreador) => {
	let cotacao = mapearDadosCotacao(autoRastreador);
	let gerarCotacaoResult = await GerarCotacao({ cotacao });
	if (gerarCotacaoResult.success) {
		let cotacoes = gerarCotacaoResult.cotacoes;
		return { cotacoes: cotacoes };
	} else {
		return { cotacoes: [] };
	}
};
export const showModalCotacaoAutoRastreador = () => {
	$('#modalCotacaoAutoRastreador').modal({
		backdrop: 'static',
		keyboard: false,
		show: true
	});
};

export const hideModalCotacaoAutoRastreador = () => {
	$('#modalCotacaoAutoRastreador').modal('hide');
};

const ModalCotacaoAutoRastreador = ({ context, carregarDadosDoLead = () => { } }) => {
	const { atendimentoValues, setAtendimentoValues, showMessage, showLoading, hideLoading } = useContext(context);
	const [modalCotacaoAutoRastreadorValues, SetModalCotacaoAutoRastreadorValues] = useState(
		modalCotacaoAutoRastreadorInitialState
	);

	const obterTitulo = () => {
		switch (modalCotacaoAutoRastreadorValues.passo) {
			case 0:
				return 'Detalhes retornados da cotação solicitada';
			case 1:
				return 'Seleção de Cotação';
			case 2:
				return 'Dados Complementares para Transmissão de Proposta';
			case 3:
				return 'Finalização';
			default:
				break;
		}
	};
	const tituloModal = obterTitulo();

	const alterarComissao = (id, comissaoSelecionada, codigoCobertura) => {
		let cotacoesNew = [];
		for (let index = 0; index < atendimentoValues.cotacoes.length; index++) {
			let cotacao = atendimentoValues.cotacoes[index];
			if (cotacao.codigoCobertura === codigoCobertura) {
				cotacao.comissaoAplicada = comissaoSelecionada;
			}

			cotacoesNew.push(cotacao);
		}
		setAtendimentoValues({
			...atendimentoValues,
			cotacoes: [...cotacoesNew]
		});
		SetModalCotacaoAutoRastreadorValues({
			...modalCotacaoAutoRastreadorValues,
			cotacaoSelecionada: null
		});
	};

	const selecionarCotacao = (cotacao) => {
		SetModalCotacaoAutoRastreadorValues({
			...modalCotacaoAutoRastreadorValues,
			cotacaoSelecionada: cotacao
		});
	};
	const selecionarDetalhesCotacaoComErro = (cotacao) => {
		SetModalCotacaoAutoRastreadorValues({
			...modalCotacaoAutoRastreadorValues,
			cotacaoSelecionada: cotacao,
			passo: 0
		});
	};
	const proximoPasso = () => {
		let passo = modalCotacaoAutoRastreadorValues.passo + 1;

		if (passo === 2) {
			SetModalCotacaoAutoRastreadorValues({
				...modalCotacaoAutoRastreadorValues,
				passo: passo
			});
		}
	};
	const voltarPasso = () => {
		SetModalCotacaoAutoRastreadorValues({
			...modalCotacaoAutoRastreadorValues,
			passo: modalCotacaoAutoRastreadorValues.passo - 1
		});
	};
	const cancelar = () => {
		SetModalCotacaoAutoRastreadorValues(modalCotacaoAutoRastreadorInitialState);
		hideModalCotacaoAutoRastreador();
	};

	const voltarParaCotacoes = () => {
		SetModalCotacaoAutoRastreadorValues({
			...modalCotacaoAutoRastreadorValues,
			cotacaoSelecionada: null,
			passo: 1
		});
	};

	const mapearDadosProposta = () => {
		const { autoRastreador } = atendimentoValues.dadosAtendimento;
		let cotacaoSelecionada = modalCotacaoAutoRastreadorValues.cotacaoSelecionada;
		return {
			protocolo: cotacaoSelecionada.comissaoAplicada.protocoloSeguradora,
			codigoCobertura: cotacaoSelecionada.codigoCobertura,
			email: autoRastreador.leadAutoRastreadorEmail,
			celularDdd: autoRastreador.leadAutoRastreadorCelularDdd,
			celularNumero: autoRastreador.leadAutoRastreadorCelularNumero,
			enderecoLogradouro: autoRastreador.leadAutoRastreadorLogradouro,
			enderecoComplemento: autoRastreador.leadAutoRastreadorComplemento,
			enderecoNumero: autoRastreador.leadAutoRastreadorNumero,
			enderecoBairro: autoRastreador.leadAutoRastreadorBairro,
			enderecoUf: autoRastreador.leadAutoRastreadorUf,
			enderecoCidade: autoRastreador.leadAutoRastreadorCidade,
			enderecoCep: autoRastreador.leadAutoRastreadorCep,
			dataInicioVigencia: null,
			tipoPagamento: autoRastreador.leadAutoRastreadorTipoPagamento,
			diaVencimentoDemaisParcelas: autoRastreador.leadAutoRastreadorDiaVencimentoDemaisParcelas,
			quantidadeParcelas: autoRastreador.leadAutoRastreadorQuantidadeParcelas,
			veiculoRenavam: autoRastreador.leadAutoRastreadorRenavam,
			veiculoChassi: autoRastreador.leadAutoRastreadorNumChassi,
			veiculoPlaca: autoRastreador.leadAutoRastreadorPlaca,
			veiculoCombustivel: autoRastreador.leadAutoRastreadorCombustivel,
			veiculoCor: autoRastreador.leadAutoRastreadorCor
		};
	};
	const mapEndereco = () => {
		if (atendimentoValues.dadosAtendimento.autoRastreador) {
			const endereco = {
				enderecoCep: atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorCep,
				enderecoLogradouro: atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorLogradouro,
				enderecoNumero: atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorNumero,
				enderecoComplemento: atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorComplemento,
				enderecoBairro: atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorBairro,
				enderecoCidade: atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorCidade,
				enderecoUf: atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorUf
			};
			return endereco;
		}
		return null;
	};
	const transmitirProposta = async () => {
		showLoading();
		let proposta = mapearDadosProposta();
		let transmitirPropostaResult = await TransmitirProposta({ proposta });

		hideLoading();
		if (transmitirPropostaResult.success) {
			const { proposta } = transmitirPropostaResult;
			SetModalCotacaoAutoRastreadorValues({
				...modalCotacaoAutoRastreadorValues,
				passo: modalCotacaoAutoRastreadorValues.passoFinal
			});
			setAtendimentoValues({
				...atendimentoValues,
				dadosAtendimento: {
					...atendimentoValues.dadosAtendimento,
					venda: {
						...atendimentoValues.dadosAtendimento.venda,
						vendaProtocoloSeguradora: proposta.protocoloSeguradora,
						vendaPropostaSeguradora: proposta.proposta,
						vendaServicoContratado: modalCotacaoAutoRastreadorValues.cotacaoSelecionada.nomeCobertura
					},
					endereco: mapEndereco()
				}
			});
		} else {
			showMessage('Erro', transmitirPropostaResult.message);
		}
	};
	const finalizar = () => {
		SetModalCotacaoAutoRastreadorValues(modalCotacaoAutoRastreadorInitialState);
		hideModalCotacaoAutoRastreador();
	};
	return (
		<Fragment>
			<div
				className="modal fade "
				id="modalCotacaoAutoRastreador"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="modalCotacaoAutoRastreadorTitle"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="modalLeadLabel">
								{tituloModal}
							</h5>
						</div>

						<div className="modal-body componenteModalCotacaoAutoRastreador_modalBody">
							{modalCotacaoAutoRastreadorValues.passo === 0 ? (
								<FormularioErrosEAlertas
									cotacoes={atendimentoValues.cotacoes}
									cotacaoSelecionada={modalCotacaoAutoRastreadorValues.cotacaoSelecionada}
									selecionarCotacao={selecionarCotacao}
								/>
							) : null}
							{modalCotacaoAutoRastreadorValues.passo === 1 ? (
								<Cotacoes
									cotacoes={atendimentoValues.cotacoes}
									cotacaoSelecionada={modalCotacaoAutoRastreadorValues.cotacaoSelecionada}
									selecionarCotacao={selecionarCotacao}
									selecionarDetalhesCotacaoComErro={selecionarDetalhesCotacaoComErro}
									alterarComissao={alterarComissao}
								/>
							) : null}
							{modalCotacaoAutoRastreadorValues.passo === 2 ? (
								<FormularioTransmissao
									carregarDadosDoLead={carregarDadosDoLead}
									context={context}
									cotacaoSelecionada={modalCotacaoAutoRastreadorValues.cotacaoSelecionada}
								/>
							) : null}
							{modalCotacaoAutoRastreadorValues.passo === 3 ? (
								<FormularioFinalizacao
									context={context}
									cotacaoSelecionada={modalCotacaoAutoRastreadorValues.cotacaoSelecionada}
								/>
							) : null}
						</div>
						<div className="modal-footer">
							{modalCotacaoAutoRastreadorValues.passo === modalCotacaoAutoRastreadorValues.passoFinal ? (
								<button
									onClick={() => {
										finalizar();
									}}
									type="button"
									className="btn btn-success"
								>
									Finalizar
								</button>
							) : modalCotacaoAutoRastreadorValues.passo > 0 ? (
								<button
									type="button"
									className="btn btn-secondary"
									onClick={() => {
										cancelar();
									}}
								>
									Cancelar
								</button>
							) : (
								<button
									type="button"
									className="btn btn-default"
									onClick={() => {
										voltarParaCotacoes();
									}}
								>
									Voltar Para cotações
								</button>
							)}

							{modalCotacaoAutoRastreadorValues.passo > 1 &&
								modalCotacaoAutoRastreadorValues.passo < modalCotacaoAutoRastreadorValues.passoFinal ? (
								<button
									disabled={modalCotacaoAutoRastreadorValues.cotacaoSelecionada === null}
									onClick={() => {
										voltarPasso();
									}}
									type="button"
									className="btn btn-default"
								>
									Voltar
								</button>
							) : null}

							{modalCotacaoAutoRastreadorValues.passo < modalCotacaoAutoRastreadorValues.passoFinal &&
								modalCotacaoAutoRastreadorValues.passo !== 2 &&
								modalCotacaoAutoRastreadorValues.passo !== 0 ? (
								<button
									disabled={modalCotacaoAutoRastreadorValues.cotacaoSelecionada === null}
									onClick={() => {
										proximoPasso();
									}}
									type="button"
									className="btn btn-default"
								>
									Proximo
								</button>
							) : null}

							{modalCotacaoAutoRastreadorValues.passo < modalCotacaoAutoRastreadorValues.passoFinal &&
								modalCotacaoAutoRastreadorValues.passo === 2 ? (
								<button
									disabled={modalCotacaoAutoRastreadorValues.cotacaoSelecionada === null}
									onClick={() => {
										transmitirProposta();
									}}
									type="button"
									className="btn btn-primary"
								>
									Transmitir Proposta
								</button>
							) : null}
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default ModalCotacaoAutoRastreador;
