import React from "react";
import ImportarDadosSeguradora from "./Events/ImportarDadosSeguradora";
import ImportarDadosSeguradoraProvider from "./ImportarDadosSeguradoraContext";

const ImportarDadosSeguradoraIndex = () => {
  return (
    <ImportarDadosSeguradoraProvider>
      <div className="container-fluid pl-3 pt-3 pb-3">
        <ImportarDadosSeguradora />
      </div>
    </ImportarDadosSeguradoraProvider>
  );
};

export default ImportarDadosSeguradoraIndex;
