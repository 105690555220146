import { RequestWebApi, RequestWebApiSetToken, validateRequestErrorStatus } from "./Requests";

export const ListarSimples = (usuario) =>
  RequestWebApi.get("/Usuario/ListarSimples", {
    params: {
      usuarioNome: usuario.usuarioNome,
      usuarioLogin: usuario.usuarioLogin,
      grupoUsuarioId: usuario.grupoUsuarioId,
    },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const usuarios = resultData;
      return { success, usuarios };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, usuarios: [] };
    });

export const Listar = (usuario) =>
  RequestWebApi.get("/Usuario/Listar", {
    params: {
      usuarioNome: usuario.usuarioNome,
      usuarioLogin: usuario.usuarioLogin,
      grupoUsuarioId: usuario.grupoUsuarioId,
    },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const usuarios = resultData;
      return { success, usuarios };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, usuarios: [] };
    });

export const Detalhes = (usuarioId) =>
  RequestWebApi.get("/Usuario/Detalhes", {
    params: { usuarioId: usuarioId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const { usuario, equipes, ips } = resultData;
      return { success, usuario, equipes, ips };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, usuario: {}, equipes: {} };
    });

export const Incluir = (usuario) =>
  RequestWebApi.post("/Usuario/Incluir", usuario)
    .then(function (response) {
      const { resultData, success } = response.data;
      const novoGrupoUsuario = resultData;
      return { success, novoGrupoUsuario };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Atualizar = (usuario) =>
  RequestWebApi.put("/Usuario/Atualizar", usuario)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (usuarioId) =>
  RequestWebApi.delete("/Usuario/Remover", {
    params: { usuarioId: usuarioId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const RedefinirSenha = (usuarioId, senhaAtual, novaSenha, token) =>
  RequestWebApiSetToken(token).put("/Usuario/RedefinirSenha", {
    usuarioId,
    senhaAtual,
    novaSenha,
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const ReiniciarSenha = (usuarioId) =>
  RequestWebApi.put("/Usuario/ReiniciarSenha", { usuarioId })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });
