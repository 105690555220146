import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "OrigemLead";

export const Listar = (origemLead) =>
  RequestWebApi.get(`/${controller}/Listar`, {
    params: { ...origemLead },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const origensLead = resultData;
      return { success, origensLead };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { origensLead: [] };
    });

export const ListarSimples = (origemLead) =>
  RequestWebApi.get(`/${controller}/ListarSimples`, {
    params: { ...origemLead },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const origensLead = resultData;
      return { success, origensLead };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { origensLead: [] };
    });



export const Detalhes = (origemLeadId) =>
  RequestWebApi.get(`/${controller}/Detalhes`, {
    params: { origemLeadId: origemLeadId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const origemLead = resultData;
      return { success, origemLead };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, origemLead: {} };
    });

export const Incluir = (origemLead) =>
  RequestWebApi.post(`/${controller}/Incluir`, origemLead)
    .then(function (response) {
      const { resultData, success } = response.data;
      const novoGrupoUsuario = resultData;
      return { success, novoGrupoUsuario };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Atualizar = (origemLead) =>
  RequestWebApi.put(`/${controller}/Atualizar`, origemLead)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (origemLeadId) =>
  RequestWebApi.delete(`/${controller}/Remover`, {
    params: { origemLeadId: origemLeadId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });
