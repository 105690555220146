import React, { Fragment, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Anexos from '../../../../Components/Anexos/Anexos';
import Formulario from '../../../../Components/Cadastro/Formulario/Formulario';
import HistoricoLead from "../../../../Components/Lead/HistoricoLead/HistoricoLead";
import PesquisaVenda from '../../../../Components/Venda/PesquisaVenda/PesquisaVenda';
import { validarCpf, validarCnpj } from '../../../../Utils/globalFunctions';
import { EndossoContext } from '../EndossoContext';

const FormularioEndosso = () => {
	const { values, setValues, getDetailHandler, showMessage } = useContext(EndossoContext);
	let { id } = useParams();

	useEffect(() => {
		getDetailHandler(id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function onChangeInput(event) {
		const { name, value } = event.target;
		setValues({
			...values,
			data: {
				...values.data,
				[name]: value
			}
		});
	}

	function onChangeInputTipoEndosso(event) {
		const { name, value } = event.target;
		var tipoEndosso = values.tiposEndosso.find(x => x.tipoEndossoId == value);
		setValues({
			...values,
			data: {
				...values.data,
				tipoEndossoSubstituicao: tipoEndosso.tipoEndossoSubstituicao,
				[name]: value
			}
		});
	}

	function onChangeInputAuto(event) {
		const { name, value } = event.target;
		setValues({
			...values,
			data: {
				...values.data,
				leadAuto: {
					...values.data.leadAuto,
					[name]: value
				}
			}
		});
	}

	function onChangeInputCpfCnpj(event) {
		const { name, value } = event.target;
		var numeroDocumento = value;
		if (numeroDocumento == null) numeroDocumento = '';

		numeroDocumento = numeroDocumento.replace(/[.-]/g, '');
		setValues({
			...values,
			data: {
				...values.data,
				[name]: numeroDocumento
			}
		});
	}

	function onChangeCheck(event) {
		const { name, checked } = event.target;
		setValues({
			...values,
			data: {
				...values.data,
				[name]: checked
			}
		});
	}

	function validarDocumentoDigitado(event) {

		const { value } = event.target;
		var numeroDocumento = value;
		if (numeroDocumento == null) numeroDocumento = '';

		numeroDocumento = numeroDocumento.replace(/[.-]/g, '');

		if (numeroDocumento.length !== 11 && numeroDocumento.length !== 14) {
			showMessage('erro', 'Documento digitado esta no formato inválido.');
			setValues({
				...values,
				data: {
					...values.data,
					leadNumDocumento: ''
				}
			});
			return;
		}

		if (numeroDocumento.length === 11) {
			if (!validarCpf(numeroDocumento)) {
				showMessage('erro', 'Cpf Inválido.');
				setValues({
					...values,
					data: {
						...values.data,
						leadNumDocumento: ''
					}
				});
				return;
			}
		}
		if (numeroDocumento.length === 14) {
			if (!validarCnpj(numeroDocumento)) {
				showMessage('erro', 'Cnpj Inválido.');
				setValues({
					...values,
					data: {
						...values.data,
						leadNumDocumento: ''
					}
				});
				return;
			}
		}
	}
	function callBackPesquisaVenda(vendaComDependencias) {
		setValues({
			...values,
			data: {
				...values.data,
				vendaId: vendaComDependencias.vendaId,
				leadNome: vendaComDependencias.clienteNomeCompleto,
				leadNumDocumento: vendaComDependencias.clienteNumDocumento,
				produtoId: vendaComDependencias.produtoId,
				seguradoraId: vendaComDependencias.seguradoraId
			}
		});
	}
	return (
		<Fragment>
			<Formulario context={EndossoContext}>

				<div className="col-12 col-md-12 form-group">
					<PesquisaVenda vendaId={values.data.vendaId} disabled={values.formDisabled} callBack={callBackPesquisaVenda} />
				</div>
				<div className="col-12 col-md-12 pt-4 form-group ">
					{values.data.leadId > 0 ?
						<HistoricoLead leadId={values.data.leadId} disabled={false} >
							<span className="link_primary">
								Histórico do Lead
							</span>
						</HistoricoLead> : null
					}
				</div>
				<div className="col-12 col-md-4 form-group">
					<label>
						Nome<span className="campoObratorio">*</span>
					</label>
					<input
						disabled={values.formDisabled}
						className="form-control uppercase"
						type="text"
						maxLength="150"
						name="leadNome"
						value={values.data.leadNome}
						onChange={onChangeInput}
					/>
				</div>
				<div className="col-12 col-md-2 form-group">
					<label>
						CPF/CNPJ<span className="campoObratorio">*</span>
					</label>
					<input
						disabled={values.formDisabled}
						className="form-control"
						type="text"
						name="leadNumDocumento"
						value={values.data.leadNumDocumento}
						onChange={onChangeInputCpfCnpj}
						onBlur={validarDocumentoDigitado}
					/>
				</div>
				<div className="col-12 col-md-3 form-group">
					<label>
						Produto<span className="campoObratorio">*</span>
					</label>
					<select
						disabled={values.formDisabled}
						className="form-control"
						name="produtoId"
						value={values.data.produtoId}
						onChange={onChangeInput}
					>
						<option className="disabled" value="0" />
						{values.produtos.map((produto) => (
							<option key={produto.produtoId} value={produto.produtoId}>
								{produto.produtoNome}
							</option>
						))}
					</select>
				</div>

				<div className="col-12 col-md-3 form-group">
					<label>
						Seguradora<span className="campoObratorio">*</span>
					</label>
					<select
						disabled={values.formDisabled}
						className="form-control"
						name="seguradoraId"
						value={values.data.seguradoraId}
						onChange={onChangeInput}
					>
						<option className="disabled" value="0" />
						{values.seguradoras.map((seguradora) => (
							<option key={seguradora.seguradoraId} value={seguradora.seguradoraId}>
								{seguradora.seguradoraDescricao}
							</option>
						))}
					</select>
				</div>

				<div className="col-12 col-md-2 form-group">
					<label>
						Data Solicitação<span className="campoObratorio">*</span>
					</label>
					<input
						disabled={values.formDisabled}
						className="form-control"
						type="date"
						name="endossoDataSolicitacao"
						value={values.data.endossoDataSolicitacao}
						onChange={onChangeInput}
					/>
				</div>

				<div className="col-12 col-md-2 form-group">
					<label>
						Tipo do Endosso<span className="campoObratorio">*</span>
					</label>
					<select
						disabled={values.formDisabled}
						className="form-control"
						name="tipoEndossoId"
						value={values.data.tipoEndossoId}
						onChange={onChangeInputTipoEndosso}
					>
						<option className="disabled" value="0" />
						{values.tiposEndosso.map((tipoEndosso) => (
							<option key={tipoEndosso.tipoEndossoId} value={tipoEndosso.tipoEndossoId}>
								{tipoEndosso.tipoEndossoDescricao}
							</option>
						))}
					</select>
				</div>

				<div className="col-12 col-md-3 form-group">
					<label>
						Consultor Solicitante
					</label>
					<select
						disabled={values.formDisabled}
						className="form-control"
						name="endossoSolicitante"
						value={values.data.endossoSolicitante ? values.data.endossoSolicitante : 0}
						onChange={onChangeInput}
					>
						<option className="disabled" value="0" />
						{values.usuarios.filter(x => x.usuarioStatus === "A").map((usuario) => (
							<option key={usuario.usuarioId} value={usuario.usuarioNome}>
								{usuario.usuarioNome}
							</option>
						))}
					</select>
				</div>

				<div className="col-12 col-md-2 form-group">
					<label>Data Transmissão</label>
					<input
						disabled={values.formDisabled}
						className="form-control"
						type="date"
						name="endossoDataTransmissao"
						value={values.data.endossoDataTransmissao}
						onChange={onChangeInput}
					/>
				</div>
				<div className="col-12 col-md-2 form-group">
					<label>
						Situação
					</label>
					<select
						disabled={values.formDisabled}
						className="form-control"
						name="endossoFinalizado"
						value={values.data.endossoFinalizado}
						onChange={onChangeInput}
					>

						{values.situacoes.map((situacoe) => (
							<option key={situacoe.codigo} value={situacoe.codigo}>
								{situacoe.descricao}
							</option>
						))}


					</select>
				</div>


				{values.data.tipoEndossoSubstituicao ?
					<Fragment>
						<div className="col-12 col-md-2">
							<div className="form-group">
								<label>Placa</label>
								<input
									disabled={values.formDisabled}
									type="text"
									className="form-control placa_veiculo"
									name="leadAutoPlaca"
									maxLength="8"
									value={values.data.leadAuto.leadAutoPlaca}
									onChange={onChangeInputAuto}
								/>
							</div>
						</div>
						<div className="col-12 col-md-3">
							<div className="form-group">
								<label>Veículo Marca</label>
								<input
									disabled={values.formDisabled}
									type="text"
									className="form-control uppercase"
									name="leadAutoFabricante"
									value={values.data.leadAuto.leadAutoFabricante}
									onChange={onChangeInputAuto}
								/>
							</div>
						</div>
						<div className="col-12 col-md-3">
							<div className="form-group">
								<label>Veículo Modelo</label>
								<input
									disabled={values.formDisabled}
									type="text"
									className="form-control uppercase"
									name="leadAutoModelo"
									value={values.data.leadAuto.leadAutoModelo}
									onChange={onChangeInputAuto}
								/>
							</div>
						</div>
						<div className="col-12 col-md-2">
							<div className="form-group">
								<label>Ano Fabricação</label>
								<input
									disabled={values.formDisabled}
									type="text"
									className="form-control numerico"
									name="leadAutoAnoFabricacao"
									maxLength="4"
									value={values.data.leadAuto.leadAutoAnoFabricacao}
									onChange={onChangeInputAuto}
								/>
							</div>
						</div>
						<div className="col-12 col-md-2">
							<div className="form-group">
								<label>Ano Modelo</label>
								<input
									disabled={values.formDisabled}
									type="text"
									className="form-control numerico"
									name="leadAutoAnoModelo"
									maxLength="4"
									value={values.data.leadAuto.leadAutoAnoModelo}
									onChange={onChangeInputAuto}
								/>
							</div>
						</div>
					</Fragment>
					: null

				}



				<div className="col-12 col-md-2 form-group">
					<label>
						Apólice
						<span className="campoObratorio">{values.data.endossoFinalizado ? '*' : ''}</span>
					</label>
					<input
						disabled={values.formDisabled}
						className="form-control"
						type="text"
						maxLength="150"
						name="apolice"
						value={values.data.apolice}
						onChange={onChangeInput}
					/>
				</div>
				<div className="col-12 col-md-2 form-group">
					<label>
						Data Venc. Apólice<span className="campoObratorio">*</span>
					</label>
					<input
						disabled={values.formDisabled}
						className="form-control"
						type="date"
						name="endossoDataFimApolice"
						value={values.data.endossoDataFimApolice}
						onChange={onChangeInput}
					/>
				</div>
				<div className="col-12 col-md-2 form-group">
					<label>
						Endosso
						<span className="campoObratorio">{values.data.endossoFinalizado ? '*' : ''}</span>
					</label>
					<input
						disabled={values.formDisabled}
						className="form-control"
						type="text"
						maxLength="150"
						name="endossoNumeroEndosso"
						value={values.data.endossoNumeroEndosso}
						onChange={onChangeInput}
					/>
				</div>
				<div className="col-12 col-md-3">
					<div className="form-group">
						<label>Valor Prêmio Líq.</label>
						<input
							type="text"
							className="form-control"
							name="endossoValorLiqPremio"
							value={values.data.endossoValorLiqPremio}
							onChange={onChangeInput}
						/>
					</div>
				</div>
				<div className="col-12 col-md-3">
					<div className="form-group">
						<label>% Comissão</label>
						<input
							type="text"
							className="form-control porcentagem"
							name="endossoPercComissao"
							value={values.data.endossoPercComissao}
							onChange={onChangeInput}
						/>
					</div>
				</div>
				<div className="col-12 col-md-5 form-group">
					<label>
						Documentos<span className="campoObratorio">*</span>
					</label>
					<select
						disabled={values.formDisabled}
						className="form-control"
						name="endossoStatusDocumentos"
						value={values.data.endossoStatusDocumentos}
						onChange={onChangeInput}
					>
						<option className="disabled" value="" />
						{values.documentosEndosso.map((documento) => (
							<option key={documento.codigo} value={documento.codigo}>
								{documento.descricao}
							</option>
						))}
					</select>
				</div>
				<div className="col-12 col-md-2 form-group">
					<label>Anexos</label>
					<Anexos id="formularioEndossoContext" context={EndossoContext} disabled={values.formDisabled} />
				</div>
				<div className="col-12 col-md-12 form-group">
					<label>Observação</label>
					<textarea
						disabled={values.formDisabled}
						className="form-control"
						type="text"
						rows="4"
						maxLength="1000"
						name="endossoObservacao"
						value={values.data.endossoObservacao ? values.data.endossoObservacao : ''}
						onChange={onChangeInput}
					/>
				</div>

				{values.data.observacaoHistorico ? (
					<div className="col-12 col-md-12 form-group">
						<label>Histórico</label>
						<textarea
							cols="30"
							rows="6"
							disabled={true}
							className="form-control"
							type="text"
							name="observacaoHistorico"
							value={values.data.observacaoHistorico}
							onChange={onChangeInput}
						/>
					</div>
				) : null}
			</Formulario>
		</Fragment>
	);
};

export default FormularioEndosso;
