import $ from 'jquery';
import React, { Fragment, useState, useEffect } from "react";
import GroupBox from "../../GroupBox/GroupBox";
import "./DetalhesLead.css";
import { ObterDetalhesLead, EnriquecerLeadManual } from '../../../Services/Lead';

function initialState() {
  return {
    leadDetalhes: []
  };
}

const DetalhesLead = ({ leadId = 0, children = null }) => {
  const [detalhesLeadValues, setDetalhesLeadValues] = useState(initialState());

  useEffect(() => {
    async function BuscarDetalhesLead() {
      const responseDetalhesLead = await ObterDetalhesLead(leadId);
      if (!responseDetalhesLead.success) {
        return;
      } else {
        let leadDetalhes = responseDetalhesLead.data;
        setDetalhesLeadValues({
          leadDetalhes: leadDetalhes
        });
      }
    }
    if (leadId > 0 && detalhesLeadValues.leadDetalhes.length == 0) {
      BuscarDetalhesLead();
    }
  }, [leadId]);

  function showModal() {
    $(`#modalDetalhesLead${leadId}`).modal('show');
  }

  async function sincDetalhesHandle() {
    const responseEnriquecerLeadManual = await EnriquecerLeadManual(leadId);
    if (responseEnriquecerLeadManual.success && responseEnriquecerLeadManual.data) {
      const responseDetalhesLead = await ObterDetalhesLead(leadId);
      if (!responseDetalhesLead.success) {
        return;
      } else {
        let leadDetalhes = responseDetalhesLead.data;
        setDetalhesLeadValues({
          leadDetalhes: leadDetalhes
        });
      }
    }
  }


  function closeModalAction() {
  }
  return (
    <Fragment>
      <div className="row compomenteDetalhesLeadBorda">
        <div className="col-12 col-md-12">
          <span role="button"
            onClick={showModal}
          >
            {
              detalhesLeadValues.leadDetalhes.length == 0 ? <button
                className={`btn btn-warning ml-1`}
                onClick={sincDetalhesHandle}
              >
                Sinc. Detalhes
              </button> : (children == null ?
                <button
                  className={`btn btn-default`}
                >
                  Detalhes
                </button> : children)
            }
          </span>

        </div>
      </div>

      <div
        className="modal fade"
        id={`modalDetalhesLead${leadId}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby={`modalDetalhesLead${leadId}Label`}
        aria-hidden="true"
        onClick={() => closeModalAction()}
      >

        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">

          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id={`modalDetalhesLead${leadId}Label`}
              >
                {"Detalhes do Lead"}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body componenteAtendimentoDetalhesLead_modalBody">

              <Fragment>
                {detalhesLeadValues.leadDetalhes.length > 0 ?

                  detalhesLeadValues.leadDetalhes.map((leadDetalhe) => (
                    <GroupBox titulo={leadDetalhe.leadDetalheDescricaoAgrupador ? leadDetalhe.leadDetalheDescricaoAgrupador : "Geral"}>
                      {leadDetalhe.detalhes.map((detalhe) => (
                        <Fragment>
                          <div className="col-md-4 font-weight-bold">
                            {detalhe.leadDetalheDescricao}:
                          </div>
                          <div className="col-md-8">
                            {detalhe.leadDetalheValor}
                          </div>
                        </Fragment>
                      ))}
                    </GroupBox>
                  ))
                  : <div> Nenhuma informação encontrada! </div>
                }

              </Fragment>
            </div>
          </div>

        </div>

      </div>
    </Fragment >
  );
};

export default DetalhesLead;
