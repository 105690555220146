import React from "react";
import Card from "../Card/Card";
import "./Coluna.css";

const Coluna = ({ titulo, agendamentos, context }) => {
  return (
    <div className="PageAgenda_ContainerCardAgenda">
      <div className="PageAgenda_ContainerCardAgendaTitle">{titulo}</div>
      <div className="PageAgenda_ContainerCardAgendaBody">
        {agendamentos.map((agendamento) => (
          <Card
            key={agendamento.filaAtendimentoId}
            agendamento={agendamento}
            context={context}
          />
        ))}
      </div>
    </div>
  );
};

export default Coluna;
