import React, { Fragment } from "react";
import "./Selecionar.css";
const images = require.context("../../../assets/img/icones/pages", true);
const iconeEdit = images("./edit.png");
const Selecionar = ({ selectHandler = () => { }, id, showIcone = false }) => {
  return (
    <Fragment>
      <button
        type="button"
        className="btn btn-info btn-sm"
        onClick={() => selectHandler(id)}>
        {showIcone ? <img src={iconeEdit} alt="" /> : "Selecionar"}
      </button>
    </Fragment>
  );
};

export default Selecionar;
