import React, { useContext, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { FornecedorLeadContext } from "../FornecedorLeadContext";
import Formulario from "../../../../../Components/Cadastro/Formulario/Formulario";

const FormularioFornecedorLead = () => {
  const { values, setValues, getDetailHandler } = useContext(
    FornecedorLeadContext
  );
  let { id } = useParams();

  useEffect(() => {
    getDetailHandler(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      data: {
        ...values.data,
        [name]: value,
      },
    });
  }

  return (
    <Fragment>
      <Formulario context={FornecedorLeadContext}>
        <div className="col-12 col-md-2 form-group">
          <label>Código</label>
          <input
            disabled={values.formDisabled}
            className="form-control"
            type="text"
            maxLength="5"
            name="fornecedorLeadCod"
            value={values.data.fornecedorLeadCod}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-4 form-group">
          <label>Nome do Fornecedor</label>
          <input
            disabled={values.formDisabled}
            className="form-control uppercase"
            type="text"
            maxLength="30"
            name="fornecedorLeadNome"
            value={values.data.fornecedorLeadNome}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-2 form-group">
          <label>Status</label>
          <select
            disabled={values.formDisabled}
            className="form-control"
            name="fornecedorLeadAtivo"
            value={values.data.fornecedorLeadAtivo}
            onChange={onChangeInput}
          >
            <option className="disabled"></option>
            <option value="A"> Ativo </option>
            <option value="I"> Inativo </option>
          </select>
        </div>
      </Formulario>
    </Fragment>
  );
};

export default FormularioFornecedorLead;
