/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ListaAcessosGrupoUsuario } from "../../../../Services/Acessos";
import { ListarRelatoriosGrupoUsuario } from "../../../../Services/Relatorio";
import {
  Atualizar as AtualizarGrupoUsuario, Detalhes as DetalhesGrupoUsuario, Incluir as IncluirGrupoUsuario, Listar as ListarGrupoUsuario, ListarNivelAcesso, Remover as RemoverGrupoUsuario
} from "../../../../Services/GrupoUsuario";
import AppContext from "../../../../Store/AppContext";
import { validarPermissoesPagina } from "../../../../Utils/validarPermissoesPagina";

export const GrupoUsuarioContext = createContext();

const tituloTela = "Cadastro de Grupo de Usuário";

const niveisAcesso = [];

const defaultData = {
  grupoUsuarioId: "",
  grupoUsuarioNome: "",
  grupoUsuarioNivel: 0,
  DescricaoNivel: "",
  permissoes: [],
  permissoesRelatorios: [],
};

const searchData = {
  grupoUsuarioNome: "",
};

const columnsDefinition = [
  {
    text: "Id",
    dataField: "grupoUsuarioId",
    sort: true,
    hidden: true,
  },
  {
    text: "Nome Perfil",
    dataField: "grupoUsuarioNome",
    sort: true,
  },
  {
    text: "NivelId",
    dataField: "grupoUsuarioNivel",
    sort: true,
    hidden: true,
  },
  {
    text: "Nível",
    dataField: "DescricaoNivel",
    sort: true,
  },
];

const resultSearch = [];

let permissoesDefinidas = [];

function initialState() {
  return {
    searchData: searchData,
    resultSearch: resultSearch,
    columnsDefinition: columnsDefinition,
    data: defaultData,
    niveisAcesso: niveisAcesso,
    formDisabled: true,
    permissoesPerfil: [],
    permissoesRelatoriosPerfil: [],
    permissoesPagina: {},
  };
}

const GrupoUsuarioProvider = ({ children }) => {
  const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(
    AppContext
  );
  const [values, setValues] = useState(initialState());
  let history = useHistory();

  async function loadDependences() {
    const permissoesPagina = validarPermissoesPagina();
    const responseListarNivelAcesso = await ListarNivelAcesso();

    let niveisAcesso = [];

    if (!responseListarNivelAcesso.success) {
      showMessage("Erro", "Erro Inesperado ao carregar dados basicos.");
    } else {
      niveisAcesso = await responseListarNivelAcesso.niveisAcesso;
    }

    return { permissoesPagina: permissoesPagina, niveisAcesso: niveisAcesso };
  }

  const resetScreen = () => {
    showLoading();
    async function carregarDependencias() {
      const dependencias = await loadDependences();
      setTituloTela(tituloTela);
      setValues({
        ...initialState(),
        permissoesPagina: await dependencias.permissoesPagina,
        niveisAcesso: await dependencias.niveisAcesso,
      });
      hideLoading();
    }
    carregarDependencias();
  };

  useEffect(() => {
    resetScreen();
  }, [tituloTela]);

  const searchHandler = async () => {
    showLoading();

    const responseListarGrupoUsuario = await ListarGrupoUsuario(
      values.searchData
    );

    if (!responseListarGrupoUsuario.success) {
      showMessage("Erro", "Erro Inesperado durante a pesquisa.");
      return;
    }

    const resultSearchData = await responseListarGrupoUsuario.gruposUsuario;

    resultSearchData.map((data) => (data.Id = data.grupoUsuarioId));

    resultSearchData.map(
      (data) =>
      (data.DescricaoNivel = values.niveisAcesso.find(
        (nivel) => nivel.nivel === data.grupoUsuarioNivel
      ).descricao)
    );

    setValues({
      ...values,
      resultSearch: resultSearchData,
    });

    if (resultSearchData.length === 0) {
      showMessage("Alerta", "Nenhum registro encontrado.");
    }
    hideLoading();
  };

  const getDetailHandler = async (id) => {
    showLoading();

    const [
      responseListaAcessosGrupoUsuario,
      responseListarRelatorios
    ] = await Promise.all([
      ListaAcessosGrupoUsuario(id),
      ListarRelatoriosGrupoUsuario(id)
    ]);

    if (!responseListaAcessosGrupoUsuario.success) {
      showMessage("Erro", responseListaAcessosGrupoUsuario.message);
      return;
    }
    const permissoesPerfil = await responseListaAcessosGrupoUsuario.permissoesPerfil;
    const permissoesRelatoriosPerfil = responseListarRelatorios.relatorios;

    let permissoes = [];
    permissoesRelatoriosPerfil.map(relatorioModulo => {
      let permissoesModulo = relatorioModulo.relatorios.filter(x => x.temPermissao === true);
      permissoesModulo.map(p => permissoes.push(p));
    })

    const dependencias = await loadDependences();

    const permissoesPagina = await dependencias.permissoesPagina;
    const niveisAcesso = await dependencias.niveisAcesso;

    if (id > 0) {
      const responseDetalhes = await DetalhesGrupoUsuario(id);
      if (!responseDetalhes.success) {
        hideLoading();
        showMessage("Erro", `${responseDetalhes.message}`);
        responseDetalhes.errors.map((erro) =>
          showMessage("Erro", erro.errorMessage)
        );
        setTimeout(() => {
          history.goBack();
        }, 1500);
        return;
      }

      const grupoUsuario = await responseDetalhes.grupoUsuario;

      setValues({
        ...values,
        data: {
          ...grupoUsuario,
          permissoesRelatorios: permissoes
        },
        grupoUsuarioId: id,
        permissoesPerfil: permissoesPerfil,
        permissoesRelatoriosPerfil: permissoesRelatoriosPerfil,
        permissoesPagina,
        niveisAcesso,
      });
    } else {
      setValues({
        ...values,
        data: {
          ...defaultData,
          grupoUsuarioId: 0,
        },
        formDisabled: false,
        permissoesPerfil: permissoesPerfil,
        permissoesRelatoriosPerfil: permissoesRelatoriosPerfil,
        permissoesPagina,
        niveisAcesso,
      });
    }
    hideLoading();
  };

  const removeHandler = async (id) => {
    showLoading();

    const responseRemover = await RemoverGrupoUsuario(id);

    if (!responseRemover.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro durante a exclusão: ${responseRemover.message}`
      );
      responseRemover.errors.map((erro) =>
        showMessage("Erro", erro.errorMessage)
      );
      return;
    }
    searchHandler();
    hideLoading();
    showMessage("Sucesso", "Registro excluido.");
  };

  const saveNewHandler = async () => {
    showLoading();
    permissoesDefinidas = [];
    montarPermissoes(values.permissoesPerfil);
    if (permissoesDefinidas.length === 0) {
      hideLoading();
      showMessage(
        "Alerta",
        "Necessário definir ao menos uma função para o grupo."
      );
      return;
    }

    const responseIncluir = await IncluirGrupoUsuario({
      ...values.data,
      permissoes: permissoesDefinidas,
    });
    if (!responseIncluir.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao criar o registro: ${responseIncluir.message}`
      );
      responseIncluir.errors.map((erro) =>
        showMessage("Erro", erro.errorMessage)
      );
      return;
    }

    resetScreen();
    hideLoading();
    showMessage("Sucesso", "Registro criado.");
    history.goBack();
  };

  const saveUpdateHandler = async () => {
    showLoading();
    permissoesDefinidas = [];
    montarPermissoes(values.permissoesPerfil);

    if (permissoesDefinidas.length === 0) {
      hideLoading();
      showMessage(
        "Alerta",
        "Necessário definir ao menos uma função para o grupo."
      );
      return;
    }

    const responseAtualizar = await AtualizarGrupoUsuario({
      ...values.data,
      permissoes: permissoesDefinidas,
    });
    if (!responseAtualizar.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao atualizar o registro: ${responseAtualizar.message}`
      );
      responseAtualizar.errors.map((erro) =>
        showMessage("Erro", erro.errorMessage)
      );
      return;
    }

    resetScreen();
    hideLoading();
    showMessage("Sucesso", "Registro atualizado.");
    history.goBack();
  };

  const saveHandler = async () => {
    if (values.data.grupoUsuarioId === 0) {
      await saveNewHandler();
    } else {
      await saveUpdateHandler();
    }
  };

  const editHandler = () => {
    setValues({
      ...values,
      formDisabled: false,
    });
  };

  const cancelHandler = () => {
    setValues({
      ...values,
      data: defaultData,
      formDisabled: true,
    });
  };

  const obterPermissao = (id, tipo, permissao, menus = []) => {
    let item = null;
    item = menus.find((x) => x.id === id);
    if (item) {
      item.permissao = {
        ...item.permissao,
        [tipo]: permissao,
      };
      return item;
    }

    for (var i = 0; i < menus.length; i++) {
      item = obterPermissao(id, tipo, permissao, menus[i].subMenus);
      if (item) {
        break;
      }
    }

    return item;
  };
  const montarPermissoes = (permissoes = []) => {
    for (var i = 0; i < permissoes.length; i++) {
      let menus = permissoes[i].subMenus;

      if (menus.length > 0) {
        montarPermissoes(menus);
      } else {
        let item = permissoes[i];

        if (
          item.permissao &&
          (item.permissao.incluir ||
            item.permissao.editar ||
            item.permissao.excluir)
        ) {
          permissoesDefinidas.push({
            funcaoId: item.funcaoId,
            incluir: item.permissao.incluir,
            editar: item.permissao.editar,
            excluir: item.permissao.excluir,
          });
        }
      }
    }
  };

  const definirPermissao = (id, tipo, permissao) => {
    let permissoesPerfil = values.permissoesPerfil;
    let item = obterPermissao(id, tipo, permissao, permissoesPerfil);
    if (item) {
      setValues({
        ...values,
        permissoesPerfil: permissoesPerfil,
      });
    }
  };

  return (
    <GrupoUsuarioContext.Provider
      value={{
        values,
        setValues,
        searchHandler,
        cancelHandler,
        removeHandler,
        saveHandler,
        getDetailHandler,
        editHandler,
        definirPermissao,
      }}
    >
      {children}
    </GrupoUsuarioContext.Provider>
  );
};

export default GrupoUsuarioProvider;
