import React, { useState, useEffect, useContext, Fragment } from "react";
import "./Index.css";
import lgAssegurou from "../../assets/img/icones/modulos/logo.png";
import hero from "../../assets/img/icones/modulos/hero.png";
import Modulo from "../../Components/Modulo/Modulo";
import { ListaModulos } from "../../Services/Acessos";
import AppContext from "../../Store/AppContext";

function initialState() {
  return {
    modulos: [],
  };
}

export const PagesModulosIndex = () => {
  const { showLoading, hideLoading } = useContext(
    AppContext
  );
  const [values, setValues] = useState(initialState);

  async function loadDependences() {
    showLoading("Carregando Modulos do Sistema");
    const responseListaModuloso = await ListaModulos({});

    if (!responseListaModuloso.success) {
      // showMessage("Erro", "Erro Inesperado ao carregar dados basicos.");
      return { produtos: [] };
    }

    const modulos = await responseListaModuloso.modulos;
    hideLoading();
    return { modulos };
  }

  useEffect(() => {
    loadDependences().then(function (data) {
      setValues({
        ...values,
        modulos: data.modulos,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div class="telaModulosV2Body">
      <div class="telaModulosV2Logo">
        <img src={lgAssegurou} alt="" height="60" />
      </div>
      <div className="telaModulosV2ContainerModules">
        <div className="telaModulosV2Modules">
          {values.modulos
            ? values.modulos.map((modulo) => (
              <Modulo
                key={modulo.id}
                idModulo={modulo.id}
                icone={modulo.icone}
                titulo={modulo.titulo}
                descricao={modulo.descricao}
              />
            ))
            : null}

        </div>
        <div className="telaModulosVHeroImg">
          <img src={hero} alt="" width="600" />
        </div>
      </div>
    </div>

  );
};

export default PagesModulosIndex;
