import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "FluxoEventos";
export const Listar = (fluxoEventos) =>
  RequestWebApi.get(`/${controller}/Listar`, {
    params: { ...fluxoEventos },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const fluxosEventos = resultData;
      return { success, fluxosEventos };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { fluxosEventos: [] };
    });

export const Detalhes = (fluxoEventosId) =>
  RequestWebApi.get(`/${controller}/Detalhes`, {
    params: { fluxoEventosId: fluxoEventosId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const fluxoEventos = resultData;
      return { success, fluxoEventos };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, fluxoEventos: {} };
    });

export const Incluir = (fluxoEventos) =>
  RequestWebApi.post(`/${controller}/Incluir`, fluxoEventos)
    .then(function (response) {
      const { resultData, success } = response.data;
      const novoId = resultData;
      return { success, novoId };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Atualizar = (fluxoEventos) =>
  RequestWebApi.put(`/${controller}/Atualizar`, fluxoEventos)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (fluxoEventosId) =>
  RequestWebApi.delete(`/${controller}/Remover`, {
    params: { fluxoEventosId: fluxoEventosId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const BuscarMuralFluxoEventos = (buscarMuralFluxoEventos) =>
  RequestWebApi.get(`/${controller}/BuscarMuralFluxoEventos`, {
    params: { ...buscarMuralFluxoEventos },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const muralFluxoEventos = resultData;
      return { success, muralFluxoEventos };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, muralFluxoEventos: {} };
    });
