import React, { useContext, Fragment } from "react";


const FormularioVendaLeadAuto = ({ context, onChangeEvent = (event) => { } }) => {
  const {
    atendimentoValues
  } = useContext(context);
  return (
    <Fragment>
      {
        atendimentoValues.dadosAtendimento.produto.produtoFormAuxVenda !== "FORMVDAAUTO" ? null :
          <Fragment>
            <div className="col-12 col-md-4">
              <label>
                Forma de pagto 1ª parcela<span className="campoObratorio">*</span>
              </label>
              <select
                className="form-control"
                name="vendaAutoFormaPagto1Parc"
                value={
                  atendimentoValues.dadosAtendimento.vendaAuto.vendaAutoFormaPagto1Parc
                }
                onChange={onChangeEvent}
              >
                <option className="disabled" value=""></option>
                {atendimentoValues.formasPagamento.map((formaPagamento) => (
                  <option
                    key={formaPagamento.codigo}
                    value={formaPagamento.descricao}
                  >
                    {formaPagamento.descricao}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-md-4">
              <label>
                Tipo Vistoria
              </label>
              <select
                className="form-control"
                name="vendaAutoTipoVistoria"
                value={
                  atendimentoValues.dadosAtendimento.vendaAuto.vendaAutoTipoVistoria
                }
                onChange={onChangeEvent}
              >
                <option className="disabled" value=""></option>
                {atendimentoValues.tiposVistoria.map((tipoVistoria) => (
                  <option
                    key={tipoVistoria.codigo}
                    value={tipoVistoria.codigo}
                  >
                    {tipoVistoria.descricao}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group">
                <label>
                  No. Agendamento
                </label>
                <input
                  type="text"
                  maxLength="30"
                  className="form-control"
                  name="vendaAutoNumAgendVistoria"
                  value={atendimentoValues.dadosAtendimento.vendaAuto.vendaAutoNumAgendVistoria}
                  onChange={onChangeEvent}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label>
                  Nome da Prestadora
                </label>
                <input
                  type="text"
                  maxLength="30"
                  className="form-control"
                  name="vendaAutoNomePrestadora"
                  value={atendimentoValues.dadosAtendimento.vendaAuto.vendaAutoNomePrestadora}
                  onChange={onChangeEvent}
                />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="form-group">
                <label>
                  Data Prevista Vist.
                </label>
                <input
                  type="date"
                  className="form-control"
                  name="vendaAutoDataPrevistaVistoria"
                  value={atendimentoValues.dadosAtendimento.vendaAuto.vendaAutoDataPrevistaVistoria}
                  onChange={onChangeEvent}
                />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="form-group">
                <label>
                  Ramal
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="vendaAutoRamal"
                  value={atendimentoValues.dadosAtendimento.vendaAuto.vendaAutoRamal}
                  onChange={onChangeEvent}
                />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="form-group">
                <label>
                  Data
                </label>
                <input
                  type="date"
                  className="form-control"
                  name="vendaAutoData"
                  value={atendimentoValues.dadosAtendimento.vendaAuto.vendaAutoData}
                  onChange={onChangeEvent}
                />
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="form-group">
                <label>
                  Hora
                </label>
                <input
                  type="time"
                  className="form-control"
                  name="vendaAutoHora"
                  value={atendimentoValues.dadosAtendimento.vendaAuto.vendaAutoHora}
                  onChange={onChangeEvent}
                />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <label>
                Adquirido cartão Porto
              </label>
              <select
                className="form-control"
                name="vendaAutoAdiqCartaoPortoVenda"
                value={
                  atendimentoValues.dadosAtendimento.vendaAuto.vendaAutoAdiqCartaoPortoVenda
                }
                onChange={onChangeEvent}
              >
                <option value="S">SIM</option>
                <option value="N">NÃO</option>

              </select>
            </div>
          </Fragment>
      }
    </Fragment>
  );
};

export default FormularioVendaLeadAuto;
