import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "Venda";
export const ListarVendaPorCliente = (inputParams) =>
  RequestWebApi.get(`/${controller}/ListarVendaPorCliente`, {
    params: { ...inputParams },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const vendas = resultData;
      return { success, vendas };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { vendas: [] };
    });

export const DetalhesVenda = (vendaId) =>
  RequestWebApi.get(`/${controller}/DetalhesVenda`, {
    params: { vendaId: vendaId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const venda = resultData;
      return { success, venda };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, venda: {} };
    });

export const DetalhesVendaComDependencias = (vendaId) =>
  RequestWebApi.get(`/${controller}/DetalhesVendaComDependencias`, {
    params: { vendaId: vendaId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const vendaComDependencias = resultData;
      return { success, vendaComDependencias };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, vendaComDependencias: {} };
    });