import React, { useContext, useState } from "react";
import "./EditarSegmentacao.css";
import { Fragment } from "react";
import DataTableDefaultSelection from "../../DataTableDefaultSelection/DataTableDefaultSelection";
import { textFilter } from "react-bootstrap-table2-filter";

const images = require.context("../../../assets/img/icones/pages", true);
const iconeEditar = images("./edit.png");

const columnsDefinition = [
  {
    text: "Id",
    dataField: "baseProspectItensId",
    hidden: true,
  },
  {
    text: "Nome",
    dataField: "baseProspectItensNome",
    headerStyle: { width: "130px" },
    filter: textFilter({ placeholder: "Nome" }),
    sort: true,
  },
  {
    text: "Email",
    dataField: "baseProspectItensEMail",
    headerStyle: { width: "100px" },
    filter: textFilter({ placeholder: "Email" }),
    sort: true,
  },
  {
    text: "Telefone",
    dataField: "baseProspectItensTelefone",
    headerStyle: { width: "60px" },
    filter: textFilter({ placeholder: "Telefone" }),
    sort: true,
  },
];

const EditarSegmentacao = ({ id, context, selectedItensBase = [] }) => {
  const [
    editarSegmentacaoSelectedItensValues,
    setEditarSegmentacaoSelectedItens,
  ] = useState(selectedItensBase);

  const {
    values,
    adicionaProspectsRemovidos,
    buscarProspectSegmentacao,
  } = useContext(context);

  const selectedItemHandler = (selectedItens) => {
    setEditarSegmentacaoSelectedItens(selectedItens);
  };

  const initializeBase = () => {
    setEditarSegmentacaoSelectedItens(values.data.prospectsRemovidos);
    buscarProspectSegmentacao(id);
  };

  return values.formDisabled ? null : (
    <Fragment>
      <button
        type="button"
        className="btn btn-info btn-sm ml-1"
        data-toggle="modal"
        data-target={`#modalEditarSegmentacao${id}`}
        data-dismiss="modal"
        onClick={() => initializeBase()}
      >
        <img src={iconeEditar} alt="" />
      </button>
      <div
        className="modal fade"
        id={`modalEditarSegmentacao${id}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby={`modalEditarSegmentacao${id}Label`}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog modal-lg ">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id={`modalEditarSegmentacao${id}Label`}
              >
                Editar Segmentacão
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body componenteEditarSegmentacao_modalBody">
              <div className="row mt-3">
                <div className="col-12">
                  <DataTableDefaultSelection
                    keyField="baseProspectItensId"
                    data={values.baseProspectsItens}
                    selectedItens={editarSegmentacaoSelectedItensValues}
                    selectedItensStyle={{ textDecorationLine: "line-through",  background: '#f44336b8'}}
                    columns={columnsDefinition}
                    selectedItemHandler={selectedItemHandler}
                  />
                </div>

                <div className="col-12 text-center pt-4">
                  <button
                    type="button"
                    data-dismiss="modal"
                    className="btn btn-default"
                    aria-label="Close"
                    onClick={() => {
                      adicionaProspectsRemovidos(
                        editarSegmentacaoSelectedItensValues
                      );
                    }}
                  >
                    Adicionar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditarSegmentacao;
