import React, { Fragment } from "react";

const BackofficeRealizado = ({
  backoffice = {},
}) => {
  return (
    <Fragment>

      {backoffice.usuarioNome ? (
        <div className="col-md-5">
          <div className="font-weight-bold">
            Nome Usuário:{" "}
            <span className="font-weight-normal">
              {backoffice.usuarioNome}
            </span>
          </div>
        </div>
      ) : null}

      {backoffice.backofficeStatusEmissao ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Status Emissão:{" "}
            <span className="font-weight-normal">
              {backoffice.backofficeStatusEmissao}
            </span>
          </div>
        </div>
      ) : null}

      {backoffice.backofficeDataPendencia ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Data Pendencia:{" "}
            <span className="font-weight-normal">
              {backoffice.backofficeDataPendencia}
            </span>
          </div>
        </div>
      ) : null}

      {backoffice.backofficePendencia ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Mot. Pendência:{" "}
            <span className="font-weight-normal">
              {backoffice.backofficePendencia}
            </span>
          </div>
        </div>
      ) : null}

      {backoffice.backofficeDataLimitePendencia ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Data Lim. Pendência:{" "}
            <span className="font-weight-normal">
              {backoffice.backofficeDataLimitePendencia}
            </span>
          </div>
        </div>
      ) : null}

      {backoffice.backofficeDataRecusa ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Data Recusa:{" "}
            <span className="font-weight-normal">
              {backoffice.backofficeDataRecusa}
            </span>
          </div>
        </div>
      ) : null}

      {backoffice.backofficeMotivoRecusa ? (
        <div className="col-md-4">
          <div className="font-weight-bold">
            Mot. Recusa:{" "}
            <span className="font-weight-normal">
              {backoffice.backofficeMotivoRecusa}
            </span>
          </div>
        </div>
      ) : null}

      {backoffice.backofficeErroOperador ? (
        <div className="col-md-2">
          <div className="font-weight-bold">
            Erro Operador:{" "}
            <span className="font-weight-normal">
              {backoffice.backofficeErroOperador}
            </span>
          </div>
        </div>
      ) : null}

      {backoffice.backofficeStatusCadastro ? (
        <div className="col-md-2">
          <div className="font-weight-bold">
            Status Cadastro:{" "}
            <span className="font-weight-normal">
              {backoffice.backofficeStatusCadastro}
            </span>
          </div>
        </div>
      ) : null}

      {backoffice.backofficeDataCadastro ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Data Cadastro:{" "}
            <span className="font-weight-normal">
              {backoffice.backofficeDataCadastro}
            </span>
          </div>
        </div>
      ) : null}

      {backoffice.backofficeTipoTransmissao ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Tipo Transmissão:{" "}
            <span className="font-weight-normal">
              {backoffice.backofficeTipoTransmissao}
            </span>
          </div>
        </div>
      ) : null}

      {backoffice.backofficeValorLiq ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Valor Liq.:{" "}
            <span className="font-weight-normal">
              {backoffice.backofficeValorLiq}
            </span>
          </div>
        </div>
      ) : null}

      {backoffice.backofficePercComissao ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Perc. Comissão:{" "}
            <span className="font-weight-normal">
              {backoffice.backofficePercComissao}
            </span>
          </div>
        </div>
      ) : null}

      {backoffice.backofficeObservacao ? (
        <div className="col-md-12">
          <div className="font-weight-bold">
            Observações :{" "}
            <br />
            <textarea
              disabled={true}
              className="form-control"
              type="text"
              rows="4"
              maxLength="1000"
              name="backofficeObservacao"
              value={backoffice.backofficeObservacao}
            />
          </div>
        </div>
      ) : null}

    </Fragment>
  );
};

export default BackofficeRealizado;
