/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext } from "react";
import AppContext from "../../../../Store/AppContext";
import {
  Listar as ListarBaseProspect,
  Detalhes as DetalhesBaseProspect,
  Remover as RemoverBaseProspect,
  Incluir as IncluirBaseProspect,
  Atualizar as AtualizarBaseProspect,
  DownloadArquivoImportado,
} from "../../../../Services/BaseProspect";
import { Listar as ListarProduto } from "../../../../Services/Produto";
import { Detalhes as DetalhesUsuario } from "../../../../Services/Usuario";
import { useHistory } from "react-router-dom";
import { validarPermissoesPagina } from "../../../../Utils/validarPermissoesPagina";
import { DownloadTemplate } from "../../../../Services/Download";
export const BaseProspectContext = createContext();

const tituloTela = "Importação de Base";

const defaultData = {
  baseProspectId: 0,
  produtoId: 0,
  baseProspectNome: "",
  baseProspectDescr: "",
  baseProspectArquivo: "",
  baseProspectDataHora: "",
  usuarioId: 0,
  baseProspectStatus: "",
};

const searchData = {
  produtoId: 0,
  baseProspectNome: "",
  baseProspectStatus: "",
};

const columnsDefinition = [
  {
    text: "Id",
    dataField: "baseProspectId",
    hidden: true,
  },
  {
    text: "Data",
    dataField: "baseProspectDataHora",
    headerStyle: { width: "150px" },
    sort: true,
  },
  {
    text: "Nome da Base",
    dataField: "baseProspectNome",
    headerStyle: { width: "450px" },
    sort: true,
  },
  {
    text: "Status",
    dataField: "baseProspectStatus",
    headerStyle: { width: "80px" },
    sort: true,
    formatter: (cell, row) => {
      return cell === "A" ? (
        <span className="badge badge-success">Ativo</span>
      ) : (
        <span className="badge badge-danger">Inátivo</span>
      );
    },
  },
];

const statusList = [
  { key: "A", value: "Ativo" },
  { key: "I", value: "Inativo" },
];

function initialState() {
  return {
    searchData: searchData,
    resultSearch: [],
    columnsDefinition: columnsDefinition,
    data: defaultData,
    produtos: [],
    errosImportacao: [],
    totalImportados: 0,
    totalSucesso: 0,
    totalErro: 0,
    statusList: statusList,
    formDisabled: true,
    permitirImportacao: false,
    usuarioImportacao: "",
  };
}

const BaseProspectProvider = ({ children }) => {
  const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(
    AppContext
  );
  const [values, setValues] = useState(initialState());
  let history = useHistory();

  async function loadDependences() {
    const permissoesPagina = validarPermissoesPagina();
    const responseListarProduto = await ListarProduto({});

    if (!responseListarProduto.success) {
      showMessage("Erro", "Erro Inesperado ao carregar dados basicos.");
      return { produtos: [] };
    }

    const produtos = await responseListarProduto.produtos.filter((item) => item.produtoAtivo === 'A');

    return { permissoesPagina, produtos };
  }

  const resetScreen = () => {
    showLoading();
    async function carregarDependencias() {
      const dependencias = await loadDependences();
      setTituloTela(tituloTela);
      setValues({
        ...initialState(),
        permissoesPagina: await dependencias.permissoesPagina,
        produtos: await dependencias.produtos,
      });
      hideLoading();
    }
    carregarDependencias();
  };

  const searchHandler = async () => {
    showLoading();
    const responseListarBaseProspect = await ListarBaseProspect(
      values.searchData
    );

    if (!responseListarBaseProspect.success) {
      showMessage("Erro", "Erro Inesperado durante a pesquisa.");
      hideLoading();
      return;
    }

    const resultSearchData = await responseListarBaseProspect.baseProspects;

    resultSearchData.map((data) => (data.Id = data.baseProspectId));

    setValues({
      ...values,
      resultSearch: resultSearchData,
    });

    if (resultSearchData.length === 0) {
      showMessage("Alerta", "Nenhum registro encontrado.");
    }
    hideLoading();
  };

  const removeHandler = async (id) => {
    showLoading();

    const responseRemover = await RemoverBaseProspect(id);

    if (!responseRemover.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro durante a exclusão: ${responseRemover.message}`
      );
      return;
    }
    searchHandler();
    hideLoading();
    showMessage("Sucesso", "Registro excluido.");
  };

  const getDetailHandler = async (id) => {
    showLoading();
    const dependencias = await loadDependences();

    if (id > 0) {
      const responseDetalhes = await DetalhesBaseProspect(id);

      const { success, baseProspect } = await responseDetalhes;

      if (!success) {
        showMessage("Erro", "Erro ao buscar os detalhes do registro.");
        setValues({
          ...values,
          data: defaultData,
          permitirImportacao: false,
          permissoesPagina: await dependencias.permissoesPagina,
        });
      }
      const responseDetalhesUsuario = await DetalhesUsuario(
        baseProspect.usuarioId
      );

      setValues({
        ...values,
        data: baseProspect,
        permitirImportacao: false,
        permissoesPagina: await dependencias.permissoesPagina,
        produtos: await dependencias.produtos,
        usuarioImportacao: await responseDetalhesUsuario.usuario.usuarioNome,
      });
    } else {
      setValues({
        ...values,
        data: {
          ...defaultData,
          baseProspectId: 0,
        },
        permissoesPagina: await dependencias.permissoesPagina,
        produtos: await dependencias.produtos,
        permitirImportacao: true,
        formDisabled: false,
      });
    }
    hideLoading();
  };

  const createData = async () => {
    showLoading();
    const responseIncluir = await IncluirBaseProspect(values.data);
    if (!responseIncluir.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao criar o registro: ${responseIncluir.message}`
      );

      responseIncluir.errors.map((erro) =>
        showMessage("Erro", erro.errorMessage)
      );

      if (responseIncluir.baseProspectInfo) {
        const totalLeadsImportados =
          responseIncluir.baseProspectInfo.erros.length;

        const totalLeadsComSucesso = responseIncluir.baseProspectInfo.erros.filter(
          (resultadoImportacao) => resultadoImportacao.sucesso
        ).length;

        const totalLeadsComErro = responseIncluir.baseProspectInfo.erros.filter(
          (resultadoImportacao) => !resultadoImportacao.sucesso
        ).length;

        setValues({
          ...values,
          errosImportacao: responseIncluir.baseProspectInfo.erros,
          totalImportados: totalLeadsImportados,
          totalSucesso: totalLeadsComSucesso,
          totalErro: totalLeadsComErro,
        });
      }
      return;
    }

    resetScreen();
    hideLoading();
    showMessage("Sucesso", "Registro criado.");
    history.goBack();
  };

  const editHandler = () => {
    setValues({
      ...values,
      formDisabled: false,
    });
  };

  const updateData = async () => {
    showLoading();
    const responseAtualizar = await AtualizarBaseProspect(values.data);
    if (!responseAtualizar.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao atualizar o registro: ${responseAtualizar.message}`
      );
      return;
    }
    resetScreen();
    hideLoading();
    showMessage("Sucesso", "Registro atualizado.");
    history.goBack();
  };

  const saveHandler = async () => {
    if (values.data.baseProspectId === 0) {
      await createData();
    } else {
      await updateData();
    }
  };

  const cancelHandler = () => {
    setValues({
      ...values,
      data: defaultData,
      formDisabled: true,
    });
  };

  const downloadTemplate = async () => {
    const result = await DownloadTemplate("BaseProspect.xlsx");
    return result;
  };

  const downloadArquivoImportado = async () => {
    if (values.data.baseProspectId === 0) {
      return;
    }
    const result = await DownloadArquivoImportado(values.data.baseProspectId);
    return result;
  };

  return (
    <BaseProspectContext.Provider
      value={{
        values,
        setValues,
        resetScreen,
        searchHandler,
        cancelHandler,
        removeHandler,
        saveHandler,
        getDetailHandler,
        editHandler,
        downloadTemplate,
        downloadArquivoImportado,
      }}
    >
      {children}
    </BaseProspectContext.Provider>
  );
};

export default BaseProspectProvider;
