import React, { Fragment } from "react";
import AtendimentoRealizado from "../AtendimentosRealizados/AtendimentoRealizado";

const AtendimentosRealizados = ({
  defineDetalhesObjeto = () => { },
  atendimentos = [],
  leadsAuto = [],
}) => {

  const obterLeadAuto = (atendimento) => {
    return leadsAuto.find(x => x.leadId === atendimento.leadId);
  }

  return (
    <Fragment>
      <div className="default-title">Atendimentos</div>
      <div className="card shadow-sm mb-4">
        <div className="row align-items-center">
          {atendimentos.map((atendimento) => {
            return <Fragment key={atendimento.atendimentoId}>
              <AtendimentoRealizado defineDetalhesObjeto={defineDetalhesObjeto} atendimento={atendimento} leadAuto={obterLeadAuto(atendimento)} />
              <div className="col-12">
                <hr />
              </div>
            </Fragment>
          })}

        </div>
      </div>
    </Fragment>
  );
};

export default AtendimentosRealizados;
