import React, { Fragment, useContext } from "react";
const images = require.context("../../../../assets/img/icones/pages", true);
const iconeDownload = images("./surface1.png");
const Download = ({ context, arquivoId }) => {
  const { downloadAnexo } = useContext(context);
  return (
    <Fragment>
      <button
        type="button"
        className="btn btn-success btn-sm  ml-1"
        onClick={() => downloadAnexo(arquivoId)}
      >
        <img src={iconeDownload} alt="" />
      </button>
    </Fragment>
  );
};

export default Download;
