import React, { useContext, Fragment, useEffect } from "react";
import { FornecedorLeadContext } from "../FornecedorLeadContext";
import Pesquisar from "../../../../../Components/Cadastro/Pesquisar/Pesquisar";
import TabelaResultados from "../../../../../Components/Cadastro/TabelaResultados/TabelaResultados";

const PesquisarFornecedorLead = () => {
  const { values, setValues, resetScreen } = useContext(FornecedorLeadContext);

  useEffect(() => {
    resetScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      searchData: {
        ...values.searchData,
        [name]: value,
      },
    });
  }

  return (
    <Fragment>
      <Pesquisar context={FornecedorLeadContext}>
        <div className="col-12 col-md-3">
          <label>Código do Fornecedor</label>
          <input
            className="form-control"
            type="text"
            name="fornecedorLeadCod"
            value={values.searchData.fornecedorLeadCod}
            onChange={onChangeInput}
          />
        </div>

        <div className="col-12 col-md-5">
          <label>Nome do Fornecedor</label>
          <input
            className="form-control"
            type="text"
            name="fornecedorLeadNome"
            value={values.searchData.fornecedorLeadNome}
            onChange={onChangeInput}
          />
        </div>
      </Pesquisar>
      <TabelaResultados context={FornecedorLeadContext} />
    </Fragment>
  );
};

export default PesquisarFornecedorLead;
