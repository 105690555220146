import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "LojaVirtual";
export const Listar = (lojaVirtual) =>
  RequestWebApi.get(`/${controller}/Listar`, {
    params: { ...lojaVirtual },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const lojasVirtual = resultData;
      return { success, lojasVirtual };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { lojasVirtual: [] };
    });

export const Detalhes = (lojaVirtualId) =>
  RequestWebApi.get(`/${controller}/Detalhes`, {
    params: { lojaVirtualId: lojaVirtualId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const lojaVirtual = resultData;
      return { success, lojaVirtual };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, lojaVirtual: {} };
    });

export const Incluir = (lojaVirtual) =>
  RequestWebApi.post(`/${controller}/Incluir`, lojaVirtual)
    .then(function (response) {
      const { resultData, success } = response.data;
      const novoProduto = resultData;
      return { success, novoProduto };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Atualizar = (lojaVirtual) =>
  RequestWebApi.put(`/${controller}/Atualizar`, lojaVirtual)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (lojaVirtualId) =>
  RequestWebApi.delete(`/${controller}/Remover`, {
    params: { lojaVirtualId: lojaVirtualId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });
