import React, { useContext, Fragment, useEffect } from "react";
import "./FormularioNegociacao.css";
import {
  MuitoFeliz,
  Feliz,
  Neutro,
  Triste,
  MuitoTriste,
} from "../../../Components/Score/Score";
import Agendamento from "../Agendamento/Agendamento";
import FormularioAutoRastreador from "../FormulariosEspecificosProduto/AutoRastreador/FormularioAutoRastreador";
import FormularioLeadAuto from "../FormulariosEspecificosProduto/LeadAuto/FormularioLeadAuto";
import FormularioVendaLeadAuto from "../FormulariosEspecificosProduto/LeadAuto/FormularioVendaLeadAuto";
import FormularioLeadCorporativo from "../FormulariosEspecificosProduto/LeadCorporativo/FormularioLeadCorporativo";
import FormularioLeadRisk from "../FormulariosEspecificosProduto/LeadRisk/FormularioLeadRisk";
import Anexos from "../../Anexos/Anexos";
import { formatarMoeda } from "../../../Utils/globalFunctions";
import { LoadMask } from "../../../Utils/mask";

const FormularioNegociacao = ({ context }) => {
  const {
    atendimentoValues,
    setAtendimentoValues,
    agendarNegociacaoHandler,
    finalizarNegociacaoHandler,
  } = useContext(context);

  useEffect(() => {
    LoadMask();


    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [atendimentoValues.gerarPedidoVenda]);

  const formularioFormAuxVendaComErro =
    atendimentoValues.dadosAtendimento.produto.produtoFormAuxVenda === "FORMVDAAUTO" ?
      !atendimentoValues.dadosAtendimento.vendaAuto.vendaAutoFormaPagto1Parc : false;

  const formularioLeadAutoComErro =
    atendimentoValues.dadosAtendimento.produto.produtoFormAuxLead === "FORMLEADAUTO" ?
      !atendimentoValues.dadosAtendimento.lead.placaVeiculo ||
      !atendimentoValues.dadosAtendimento.lead.marcaVeiculo ||
      !atendimentoValues.dadosAtendimento.lead.modeloVeiculo ||
      !atendimentoValues.dadosAtendimento.lead.anoFabricacaoVeiculo ||
      !atendimentoValues.dadosAtendimento.lead.anoModeloVeiculo ||
      !atendimentoValues.dadosAtendimento.lead.leadAutoTipoUtilizacaoDut : false;

  const formularioGeralComErro =
    !atendimentoValues.dadosAtendimento.lead.leadNome ||
    !atendimentoValues.dadosAtendimento.lead.leadNumDocumento ||
    !atendimentoValues.dadosAtendimento.lead.leadScoringUser ||
    atendimentoValues.dadosAtendimento.venda.seguradoraId === "0" ||
    !atendimentoValues.dadosAtendimento.venda.seguradoraId ||
    !atendimentoValues.dadosAtendimento.venda.valorLiquido ||
    !atendimentoValues.dadosAtendimento.venda.vendaTipoTransmissao ||
    atendimentoValues.dadosAtendimento.venda.vendaPercComissao === 0 ||
    atendimentoValues.dadosAtendimento.venda.vendaPercComissao === "0" ||
    !atendimentoValues.dadosAtendimento.venda.vendaPercComissao ||
    !atendimentoValues.dadosAtendimento.negociacao.observacao ||
    atendimentoValues.dadosAtendimento.tipoNegociacaoId === "0" ||
    atendimentoValues.dadosAtendimento.tipoNegociacaoId === 0 ||
    !atendimentoValues.dadosAtendimento.tipoNegociacaoId ||
    atendimentoValues.dadosAtendimento.lead.leadTipoNegocio === "0" ||
    atendimentoValues.dadosAtendimento.lead.leadTipoNegocio === 0 ||
    !atendimentoValues.dadosAtendimento.lead.leadTipoNegocio ||
    formularioLeadAutoComErro ||
    formularioFormAuxVendaComErro;

  const bloquearAgendamento =
    atendimentoValues.dadosAtendimento.tipoNegociacaoId === "0" ||
    atendimentoValues.dadosAtendimento.tipoNegociacaoId === 0 ||
    !atendimentoValues.dadosAtendimento.tipoNegociacaoId;

  function onChangeInputVenda(event) {
    const { name, value } = event.target;
    setAtendimentoValues({
      ...atendimentoValues,
      dadosAtendimento: {
        ...atendimentoValues.dadosAtendimento,
        venda: {
          ...atendimentoValues.dadosAtendimento.venda,
          [name]: value,
        },
      },
    });
  }
  function onChangeInputVendaAuto(event) {
    const { name, value } = event.target;
    setAtendimentoValues({
      ...atendimentoValues,
      dadosAtendimento: {
        ...atendimentoValues.dadosAtendimento,
        vendaAuto: {
          ...atendimentoValues.dadosAtendimento.vendaAuto,
          [name]: value,
        },
      },
    });
  }

  function onChangeInputNegociacao(event) {
    const { name, value } = event.target;
    setAtendimentoValues({
      ...atendimentoValues,
      dadosAtendimento: {
        ...atendimentoValues.dadosAtendimento,
        negociacao: {
          ...atendimentoValues.dadosAtendimento.negociacao,
          [name]: value,
        },
      },
    });
  }
  function onChangeLeadAtendimento(event) {
    const { name, value } = event.target;
    setAtendimentoValues({
      ...atendimentoValues,
      dadosAtendimento: {
        ...atendimentoValues.dadosAtendimento,
        [name]: value,
      },
    });
  }

  function onChangeLead(event) {
    const { name, value } = event.target;
    setAtendimentoValues({
      ...atendimentoValues,
      dadosAtendimento: {
        ...atendimentoValues.dadosAtendimento,
        lead: {
          ...atendimentoValues.dadosAtendimento.lead,
          [name]: value,
        },
      },
    });
  }

  const valorComissao = formatarMoeda(
    atendimentoValues.dadosAtendimento.venda.valorLiquido === 0 ||
      atendimentoValues.dadosAtendimento.venda.vendaPercComissao === 0
      ? 0
      : (atendimentoValues.dadosAtendimento.venda.valorLiquido.replace(
        ",",
        "."
      ) /
        100) *
      atendimentoValues.dadosAtendimento.venda.vendaPercComissao.replace(
        ",",
        "."
      )
  );

  function onChangeCheck(event) {
    const { checked } = event.target;
    setAtendimentoValues({
      ...atendimentoValues,
      gerarPedidoVenda: checked,
    });
  }
  return (
    <Fragment>
      <div className="row">
        <div className="col-12 col-md-4">
          <div className="form-group">
            <label>
              Chance<span className="campoObratorio">*</span>
            </label>
            <div className="rating">
              <div className="rating-form">
                <label htmlFor="super-sad">
                  <input
                    type="radio"
                    className="super-sad"
                    id="super-sad"
                    value="1"
                    name="leadScoringUser"
                    checked={
                      atendimentoValues.dadosAtendimento.lead
                        .leadScoringUser === "1"
                    }
                    onChange={onChangeLead}
                  />

                  <MuitoTriste />
                </label>
                <label htmlFor="sad">
                  <input
                    type="radio"
                    className="sad"
                    id="sad"
                    value="2"
                    name="leadScoringUser"
                    checked={
                      atendimentoValues.dadosAtendimento.lead
                        .leadScoringUser === "2"
                    }
                    onChange={onChangeLead}
                  />

                  <Triste />
                </label>

                <label htmlFor="neutral">
                  <input
                    type="radio"
                    className="neutral"
                    id="neutral"
                    value="3"
                    name="leadScoringUser"
                    checked={
                      atendimentoValues.dadosAtendimento.lead
                        .leadScoringUser === "3"
                    }
                    onChange={onChangeLead}
                  />
                  <Neutro />
                </label>

                <label htmlFor="happy">
                  <input
                    type="radio"
                    className="happy"
                    id="happy"
                    value="4"
                    name="leadScoringUser"
                    checked={
                      atendimentoValues.dadosAtendimento.lead
                        .leadScoringUser === "4"
                    }
                    onChange={onChangeLead}
                  />

                  <Feliz />
                </label>

                <label htmlFor="super-happy">
                  <input
                    type="radio"
                    className="super-happy"
                    id="super-happy"
                    value="5"
                    name="leadScoringUser"
                    checked={
                      atendimentoValues.dadosAtendimento.lead
                        .leadScoringUser === "5"
                    }
                    onChange={onChangeLead}
                  />

                  <MuitoFeliz />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <label>
            Tipo Negociacão<span className="campoObratorio">*</span>
          </label>
          <select
            className="form-control"
            name="tipoNegociacaoId"
            value={atendimentoValues.dadosAtendimento.tipoNegociacaoId}
            onChange={onChangeLeadAtendimento}
          >
            <option className="disabled" value={0}></option>
            {atendimentoValues.tiposNegociacao.map((tipoNegociacao) => (
              <option key={tipoNegociacao.value} value={tipoNegociacao.value}>
                {tipoNegociacao.label}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-12" />
        <FormularioLeadAuto context={context} onChangeEvent={onChangeLead} />
        <FormularioAutoRastreador context={context} />
        <FormularioLeadCorporativo context={context} />
        <FormularioLeadRisk context={context} />
        {atendimentoValues.gerarPedidoVenda ? (
          <Fragment>
            <div className="col-12 col-md-4">
              <label>
                Seguradora<span className="campoObratorio">*</span>
              </label>
              <select
                className="form-control"
                name="seguradoraId"
                value={atendimentoValues.dadosAtendimento.venda.seguradoraId}
                onChange={onChangeInputVenda}
              >
                <option className="disabled" value="0"></option>
                {atendimentoValues.seguradoras.map((seguradora) => (
                  <option key={seguradora.value} value={seguradora.value}>
                    {seguradora.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-md-2">
              <div className="form-group">
                <label>Nº Protocolo</label>
                <input
                  type="text"
                  className="form-control "
                  name="vendaProtocoloSeguradora"
                  value={atendimentoValues.dadosAtendimento.venda.vendaProtocoloSeguradora}
                  onChange={onChangeInputVenda}
                />
              </div>
            </div>
            <div className="col-12 col-md-5">
              <div className="form-group">
                <label>Nº Proposta</label>
                <input
                  type="text"
                  className="form-control "
                  name="vendaPropostaSeguradora"
                  value={atendimentoValues.dadosAtendimento.venda.vendaPropostaSeguradora}
                  onChange={onChangeInputVenda}
                />
              </div>
            </div>

            <div className="col-12 col-md-4">
              <label>
                Tipo Transmissão<span className="campoObratorio">*</span>
              </label>
              <select
                className="form-control"
                name="vendaTipoTransmissao"
                value={
                  atendimentoValues.dadosAtendimento.venda.vendaTipoTransmissao
                }
                onChange={onChangeInputVenda}
              >
                <option className="disabled" value="0"></option>
                {atendimentoValues.tiposTransmissao.map((tipoTransmissao) => (
                  <option
                    key={tipoTransmissao.codigo}
                    value={tipoTransmissao.codigo}
                  >
                    {tipoTransmissao.descricao}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-12 col-md-3">
              <div className="form-group">
                <label>
                  Prêmio Liq.<span className="campoObratorio">*</span>
                </label>
                <input
                  type="text"
                  className="form-control monetario"
                  name="valorLiquido"
                  value={atendimentoValues.dadosAtendimento.venda.valorLiquido}
                  onChange={onChangeInputVenda}
                  onBlur={onChangeInputVenda}
                  onKeyDown={onChangeInputVenda}
                />
              </div>
            </div>

            <div className="col-12 col-md-3">
              <div className="form-group">
                <label>
                  % Comissão <span className="campoObratorio">*</span>
                </label>
                <input
                  type="text"
                  className="form-control porcentagem3Digitos"
                  name="vendaPercComissao"
                  value={
                    atendimentoValues.dadosAtendimento.venda.vendaPercComissao
                  }
                  onChange={onChangeInputVenda}
                />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="form-group">
                <label>Total Comissão</label>
                <input
                  disabled={true}
                  type="text"
                  className="form-control formularioNegociacao-totalComissao"
                  name="valorComissao"
                  value={valorComissao}
                />
              </div>
            </div>


            <FormularioVendaLeadAuto context={context} onChangeEvent={onChangeInputVendaAuto} />
          </Fragment>
        ) : null}


        <div className="col-12 form-group">
          <label>Anexos</label>
          <Anexos id="AbaNegociacao" context={context} />
        </div>

        <div className="col-12 form-group">
          <label>
            Observação<span className="campoObratorio">*</span>
          </label>
          <textarea
            cols="30"
            rows="4"
            className="form-control"
            name="observacao"
            value={atendimentoValues.dadosAtendimento.negociacao.observacao}
            onChange={onChangeInputNegociacao}
          ></textarea>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-12 col-md-6 ">
          <div className="custom-control custom-switch float-right">
            <input
              checked={atendimentoValues.gerarPedidoVenda}
              type="checkbox"
              className="custom-control-input"
              id="customSwitch1"
              onChange={onChangeCheck}
            />
            <label className="custom-control-label" htmlFor="customSwitch1">
              Finalizar Venda?
            </label>
          </div>
        </div>

        <div className="col-3">
          {atendimentoValues.gerarPedidoVenda ? (
            <button
              type="button"
              className="btn btn-default"
              disabled={formularioGeralComErro}
              onClick={finalizarNegociacaoHandler}
            >
              Salvar e Finalizar
            </button>
          ) : (
            <Agendamento
              id="agendarNegociacao"
              context={context}
              disabled={bloquearAgendamento}
              onClickHandler={agendarNegociacaoHandler}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default FormularioNegociacao;
