import React, { useEffect, useState } from "react";
import $ from "jquery";
import "./SmsModal.css";
import { Fragment } from "react";
let eventId = 0;

export const showSmsModal = (id) => {
  eventId = id;
  $("#smsModal").modal({
    backdrop: "static",
    keyboard: false,
    show: true,
  });
};
export const hideSmsModal = () => {
  $("#smsModal").modal("hide");
};

function initialState() {
  return {
    tipo: "sms",
    motor: "",
    modeloMensagem: "",
  };
}

const SmsModal = ({
  eventoCorrente,
  confirmar = (data) => {},
  cancelar = (data) => {},
  tiposEventoSms = [],
  templatesSms = [],
  formDisabled = true,
}) => {
  const [valuesEventoSms, setEventoSms] = useState(initialState());

  const formularioComErro =
  !valuesEventoSms.motor || !valuesEventoSms.modeloMensagem;

  useEffect(() => {
    if (eventoCorrente) {
      setEventoSms(eventoCorrente);
    } else {
      setEventoSms(initialState());
    }
  }, [eventoCorrente]);

  const confirmarHandler = () => {
    setEventoSms(initialState());
    confirmar({ id: eventId, ...valuesEventoSms });
    hideSmsModal();
  };
  const cancelarHandler = () => {
    setEventoSms(initialState());
    hideSmsModal();
    cancelar({ id: eventId, ...valuesEventoSms });
  };
  function onChangeInput(event) {
    const { name, value } = event.target;
    setEventoSms({
      ...valuesEventoSms,
      [name]: value,
    });
  }

  return (
    <Fragment>
      <div
        className="modal fade"
        id="smsModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="smsModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="smsModalLabel">
                Sms
              </h5>
            </div>
            <div className="modal-body componenteAgendaRedirecionar_modalBody">
              <div className="row align-items-center">
                <div className="col-6 form-group">
                  <label>Motor</label>
                  <select
                    disabled={formDisabled}
                    className="form-control"
                    name="motor"
                    value={valuesEventoSms.motor ? valuesEventoSms.motor : ""}
                    onChange={onChangeInput}
                  >
                    <option className="disabled" value=""></option>
                    {tiposEventoSms.map((tipoEvento) => (
                      <option key={tipoEvento.tipoEventoId} value={tipoEvento.tipoEventoId}>
                        {tipoEvento.tipoEventoDescricao}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-6 form-group">
                  <label>Modelo de Mensagem</label>
                  <select
                    disabled={formDisabled}
                    className="form-control"
                    name="modeloMensagem"
                    value={
                      valuesEventoSms.modeloMensagem
                        ? valuesEventoSms.modeloMensagem
                        : ""
                    }
                    onChange={onChangeInput}
                  >
                    <option className="disabled" value=""></option>
                    {templatesSms.map((templateSms) => (
                      <option
                        key={templateSms.templateSmsId}
                        value={templateSms.templateSmsId}
                      >
                        {templateSms.templateSmsNome}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  cancelarHandler();
                }}
              >
                {formDisabled ? "Fechar" : "Cancelar"}
              </button>
              {formDisabled ? null : (
                <button
                disabled={formularioComErro}
                  type="button"
                  className="btn btn-default"
                  onClick={confirmarHandler}
                >
                  Confirmar
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SmsModal;
