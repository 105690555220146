import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "Campanha";
export const Listar = (campanha) =>
  RequestWebApi.get(`/${controller}/Listar`, {
    params: { ...campanha },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const campanhas = resultData;
      return { success, campanhas };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { campanhas: [] };
    });

export const Detalhes = (campanhaId) =>
  RequestWebApi.get(`/${controller}/Detalhes`, {
    params: { campanhaId: campanhaId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const campanha = resultData;
      return { success, campanha };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, campanha: {} };
    });

export const Incluir = (campanha) =>
  RequestWebApi.post(`/${controller}/Incluir`, campanha)
    .then(function (response) {
      const { resultData, success } = response.data;
      const novaCampanha = resultData;
      return { success, novaCampanha };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Atualizar = (campanha) =>
  RequestWebApi.put(`/${controller}/Atualizar`, campanha)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (campanhaId) =>
  RequestWebApi.delete(`/${controller}/Remover`, {
    params: { campanhaId: campanhaId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });
