import React from "react";
import { Link, useRouteMatch } from "react-router-dom";

const Novo = ({ id }) => {
  let match = useRouteMatch();
  return (
    <Link to={`${match.url}/0`}>
      <button type="button" className="btn btn-default">
        Novo
      </button>
    </Link>
  );
};

export default Novo;
