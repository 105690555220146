import React, { useState } from 'react';
import './SinistroTerceiro.css';
import { Fragment } from 'react';
let idComponente = '';

const defaultData = {
	sinistroTerceirosId: null,
	sinistroId: 0,
	sinistroTerceirosNome: null,
	sinistroTerceirosAvisoSeguradora: null,
	sinistroTerceirosDdd: null,
	sinistroTerceirosTelefone: null,
	sinistroTerceirosNumeroChassi: null,
	sinistroTerceirosPlaca: null,
	sinistroTerceirosObservacao: null
};
function initialState() {
	return {
		data: defaultData
	};
}

const SinistroTerceiro = ({ id, children, sinistroTerceiro, disabled = false, confirmarHandler = () => { } }) => {
	idComponente = id;
	const [valuesSinistroTerceiro, setValuesSinistroTerceiro] = useState({
		...initialState,
		data: sinistroTerceiro == null ? defaultData : sinistroTerceiro
	});

	function onChangeInput(event) {
		const { name, value } = event.target;
		setValuesSinistroTerceiro({
			...valuesSinistroTerceiro,
			data: {
				...valuesSinistroTerceiro.data,
				[name]: value,
			},
		});
	}
	function getRandomInt(min, max) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min) + min);
	}
	function confirmar() {
		let data = valuesSinistroTerceiro.data;
		if (data.sinistroTerceirosId == null) {
			data.sinistroTerceirosId = getRandomInt(-2147483648, 2147483647);
			setValuesSinistroTerceiro(initialState);
		}
		confirmarHandler(data);
	}

	const formularioComErro = !valuesSinistroTerceiro.data.sinistroTerceirosNome ||
		!valuesSinistroTerceiro.data.sinistroTerceirosDdd ||
		!valuesSinistroTerceiro.data.sinistroTerceirosTelefone;

	return (
		<Fragment>
			<div data-toggle="modal" data-target={`#modalSinistroTerceiro${idComponente}`} data-dismiss="modal">
				{children}
			</div>

			<div
				className="modal fade"
				data-backdrop="static"
				data-keyboard={false}
				id={`modalSinistroTerceiro${idComponente}`}
				tabIndex="-1"
				role="dialog"
				aria-labelledby={`modalSinistroTerceiro${idComponente}Label`}
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id={`modalSinistroTerceiro${idComponente}Label`}>
								Dados Terceiro
							</h5>
						</div>

						<div className="modal-body modalSinistroTerceiro_modalBody">

							<div className="row">
								<div className="col-12 col-md-8 form-group">
									<label>Nome Terceiro</label>
									<input
										disabled={disabled}
										className="form-control"
										type="text"
										maxLength="150"
										name="sinistroTerceirosNome"
										value={valuesSinistroTerceiro.data.sinistroTerceirosNome ?? ""}
										onChange={onChangeInput}
									/>
								</div>
								<div className="col-12 col-md-4 form-group">
									<label>Num. Aviso Seg. Terceiro</label>
									<input
										disabled={disabled}
										className="form-control"
										type="text"
										maxLength="50"
										name="sinistroTerceirosAvisoSeguradora"
										value={valuesSinistroTerceiro.data.sinistroTerceirosAvisoSeguradora ?? ""}
										onChange={onChangeInput}
									/>
								</div>
								<div className="col-12 col-md-2 form-group">
									<label>
										DDD
									</label>
									<input
										disabled={disabled}
										className="form-control numerico"
										type="text"
										maxLength="2"
										name="sinistroTerceirosDdd"
										value={valuesSinistroTerceiro.data.sinistroTerceirosDdd ?? ""}
										onChange={onChangeInput}
									/>
								</div>
								<div className="col-12 col-md-4 form-group">
									<label>Telefone</label>
									<input
										disabled={disabled}
										className="form-control numerico"
										type="text"
										maxLength="10"
										name="sinistroTerceirosTelefone"
										value={valuesSinistroTerceiro.data.sinistroTerceirosTelefone ?? ""}
										onChange={onChangeInput}
									/>
								</div>
								<div className="col-12 col-md-6 form-group">
									<label>Numero Chassi</label>
									<input
										disabled={disabled}
										className="form-control"
										type="text"
										maxLength="100"
										name="sinistroTerceirosNumeroChassi"
										value={valuesSinistroTerceiro.data.sinistroTerceirosNumeroChassi ?? ""}
										onChange={onChangeInput}
									/>
								</div>
								<div className="col-12 col-md-4 form-group">
									<label>Placa</label>
									<input
										disabled={disabled}
										className="form-control"
										type="text"
										maxLength="10"
										name="sinistroTerceirosPlaca"
										value={valuesSinistroTerceiro.data.sinistroTerceirosPlaca ?? ""}
										onChange={onChangeInput}
									/>
								</div>
								<div className="col-12 col-md-12 form-group">
									<label>Observacão</label>
									<textarea
										disabled={disabled}
										className="form-control"
										type="text"
										rows={5}
										name="sinistroTerceirosObservacao"
										value={valuesSinistroTerceiro.data.sinistroTerceirosObservacao ?? ""}
										onChange={onChangeInput}
									/>
								</div>
								<div className="col-12 col-md-12 form-group">
									<label>Histórico</label>
									<textarea
										disabled={true}
										className="form-control"
										type="text"
										rows={10}
										name="SinistroTerceirosObservacaoHistorico"
										value={valuesSinistroTerceiro.data.sinistroTerceirosObservacaoHistorico ?? ""}
										onChange={onChangeInput}
									/>
								</div>
							</div>
						</div>

						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-secondary"
								data-dismiss="modal"
							>
								{disabled ? "Fechar" : "Cancelar"}
							</button>
							{
								disabled ? null : <button
									disabled={formularioComErro}
									type="button"
									className="btn btn-default"
									data-dismiss="modal"
									onClick={confirmar}
								>
									Confirmar
								</button>
							}

						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default SinistroTerceiro;
