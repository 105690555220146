import React from 'react';
import 'jquery-mask-plugin/dist/jquery.mask.min';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import 'Styles/multilevelfolder/css/style.css';


import 'Styles/default.css';
import ReactDOM from 'react-dom';
import './index.css';
import StoreProvider from './Store/Provider';
import Routes from './Routes/Routes';

ReactDOM.render(
	<StoreProvider>
		<ToastContainer autoClose={4000} />
		<Routes />
	</StoreProvider>,
	document.getElementById('root')
);
