/* eslint-disable eqeqeq */
import React, { useContext, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { TemplateWhatsAppBlipContext } from '../TemplateWhatsAppBlipContext';
import Formulario from '../../../../../Components/Cadastro/Formulario/Formulario';
import FormularioComplementar from '../../../../../Components/Cadastro/FormularioComplementar/FormularioComplementar';
import DataTable from '../../../../../Components/DataTable/DataTable';

const FormularioTemplateWhatsAppBlip = () => {
	const {
		values,
		setValues,
		getDetailHandler,
		removerParametro,
		adionarParametro
	} = useContext(TemplateWhatsAppBlipContext);
	let { id } = useParams();

	useEffect(() => {
		getDetailHandler(id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function onChangeInput(event) {
		const { name, value } = event.target;
		setValues({
			...values,
			data: {
				...values.data,
				[name]: value
			}
		});
	}

	function onChangeInputParametros(event) {
		const { name, value } = event.target;
		setValues({
			...values,
			dataParametros: {
				...values.dataParametros,
				[name]: value
			}
		});
	}

	return (
		<Fragment>
			<Formulario context={TemplateWhatsAppBlipContext}>
				<div className="col-12 col-md-5 form-group">
					<label>Nome</label>
					<input
						disabled={values.formDisabled}
						className="form-control"
						type="text"
						name="templateWhatsAppBlipNome"
						value={values.data.templateWhatsAppBlipNome}
						maxLength={20}
						onChange={onChangeInput}
					/>
				</div>
				<div className="col-12 col-md-4 form-group">
					<label>Template Blip</label>
					<input
						disabled={values.formDisabled}
						className="form-control"
						type="text"
						name="templateWhatsAppBlipTemplateBlip"
						value={values.data.templateWhatsAppBlipTemplateBlip}
						maxLength={20}
						onChange={onChangeInput}
					/>
				</div>
				<div className="col-12 col-md-3  form-group">
					<label>
						Tipo <span className="campoObratorio">*</span>
					</label>
					<select
						disabled={values.formDisabled}
						className="form-control"
						name="templateWhatsAppBlipTipo"
						value={values.data.templateWhatsAppBlipTipo}
						onChange={onChangeInput}
					>
						<option className="disabled" value="" />
						<option value="I">INFORMATIVO</option>
						<option value="C">CAMPANHA</option>

					</select>
				</div>
				<div className="col-12 col-md-3  form-group">
					<label>
						Conta Blip <span className="campoObratorio">*</span>
					</label>
					<select
						disabled={values.formDisabled}
						className="form-control"
						name="templateWhatsAppBlipContaBlip"
						value={values.data.templateWhatsAppBlipContaBlip}
						onChange={onChangeInput}
					>
						<option className="disabled" value="" />
						{values.catalogos.map((catalogo) => (
							<option key={catalogo.catalogoId} value={catalogo.catalogo001}>
								{catalogo.catalogo001}
							</option>
						))}

					</select>
				</div>
			</Formulario>
			<FormularioComplementar titulo="Parâmetros">
				<div className="col-12 col-md-2 form-group">
					<label>Sequência</label>
					<input
						disabled={values.formDisabled}
						className="form-control numerico"
						type="text"
						name="templateWhatsAppBlipParametroSequencia"
						value={values.dataParametros.templateWhatsAppBlipParametroSequencia}
						maxLength={2}
						onChange={onChangeInputParametros}
					/>
				</div>
				<div className="col-12 col-md-8 form-group">
					<label>Chave</label>
					<input
						disabled={values.formDisabled}
						className="form-control uppercase"
						type="text"
						name="templateWhatsAppBlipParametroChave"
						value={values.dataParametros.templateWhatsAppBlipParametroChave}
						maxLength={100}
						onChange={onChangeInputParametros}
					/>
				</div>

				<div className="col-12 col-md-2 form-group">
					<button
						disabled={(values.dataParametros.templateWhatsAppBlipParametroSequencia == ''
							|| values.dataParametros.templateWhatsAppBlipParametroSequencia < 0)
							|| !values.dataParametros.templateWhatsAppBlipParametroChave}
						style={{ marginTop: '26px' }}
						type="button"
						className="btn btn-default buttonFormularios"
						onClick={adionarParametro}
					>
						Adicionar
					</button>
				</div>
				<div className="col-12 col-md-12 form-group">
					<DataTable
						data={values.templateWhatsAppBlipParametros}
						columnsDefinition={values.columnsDefinitionParametros}
						removeHandler={values.formDisabled ? null : removerParametro}
					/>
				</div>
			</FormularioComplementar>
		</Fragment>
	);
};

export default FormularioTemplateWhatsAppBlip;
