import React, { Fragment } from "react";
import CobrancaRealizada from "./EndossoRealizado";

const EndossosRealizados = ({
  defineDetalhesObjeto = () => { },
  endossos = [],
}) => {
  return (
    <Fragment>
      <div className="default-title">Endossos</div>
      <div className="card shadow-sm mb-4">
        <div className="row align-items-center">
          {endossos.map((endosso) => {
            return <Fragment key={endosso.endossoId}>
              <CobrancaRealizada defineDetalhesObjeto={defineDetalhesObjeto} endosso={endosso} />
              <div className="col-12">
                <hr />
              </div>
            </Fragment>
          })}

        </div>
      </div>
    </Fragment>
  );
};

export default EndossosRealizados;
