import $ from 'jquery';
import React, { Fragment, useState, useContext } from "react";
import "./Help.css";
import { BuscarHelp } from "../../Services/Help";
import AppContext from '../../Store/AppContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const initialState = {
  help: {
    tipo: "",
    tituloHelp: "",
    conteudoHelp: ""
  }
}

const Help = ({ children = null, identificadorHelp = "", disabled = false }) => {
  const { showLoading, hideLoading, showMessage } = useContext(AppContext);
  const [helpValues, setHelpValues] = useState(initialState);

  function showModal() {
    $(`#modalHelp${identificadorHelp}`).modal('show');
  }

  async function BuscarHistoricoLead(catalogo) {
    if (!helpValues.help.conteudoHelp) {
      showLoading();
      const responseBuscarHelp = await BuscarHelp(catalogo);
      if (!responseBuscarHelp.success) {
        setHelpValues({
          ...helpValues,
          help: initialState.help
        });
        showMessage("Erro", `Erro ao obter Help. ${responseBuscarHelp.message}`);
      } else {
        const help = responseBuscarHelp.help;
        setHelpValues({
          ...helpValues,
          help: help
        });
        showModal();
      }
      hideLoading();
    } else {
      showModal();
    }
  }

  return (
    <Fragment>
      {
        identificadorHelp ? <Fragment>
          <div className="row compomenteHelpBorda">
            <div className="col-12 col-md-12">
              <span role="button"
                disabled={disabled}
                onClick={() => BuscarHistoricoLead(identificadorHelp)}>
                <div style={{ display: "flex" }}>
                  {children}<FontAwesomeIcon className='componenteAtendimentoAtalhosTelefone_icone' icon={faQuestionCircle} />
                </div>
              </span>
            </div>
          </div>

          <div
            className="modal fade"
            id={`modalHelp${identificadorHelp}`}
            tabIndex="-1"
            role="dialog"
            aria-labelledby={`modalHelp${identificadorHelp}Label`}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title"
                    id={`modalHelp${identificadorHelp}Label`}
                  >
                    {helpValues.help.tituloHelp}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body componenteAtendimentoHelp_modalBody">
                  <div className='componenteAtendimentoHelp_container'>
                    <div dangerouslySetInnerHTML={{ __html: helpValues.help.conteudoHelp ? helpValues.help.conteudoHelp : "" }} />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Fechar
                  </button>
                </div>
              </div>
            </div>

          </div>
        </Fragment> : null
      }

    </Fragment>
  );
};

export default Help;
