import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

export const ListaModulos = () =>
  RequestWebApi.get("/Acessos/ListaModulos")
    .then(function (response) {
      const { resultData, success } = response.data;
      const modulos = resultData;
      return { success, modulos };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const ListaAcessos = (moduloId) =>
  RequestWebApi.get("/Acessos/ListaAcessos", {
    params: { moduloId: moduloId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const funcoes = resultData;
      return { success, funcoes };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, funcoes: [] };
    });

export const ListaAcessosGrupoUsuario = (grupoUsuarioId) =>
  RequestWebApi.get("/Acessos/ListaAcessosGrupoUsuario", {
    params: { grupoUsuarioId: grupoUsuarioId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const permissoesPerfil = resultData;
      return { success, permissoesPerfil };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, permissoesPerfil: [] };
    });

export const ValidarAcessoPath = (urlPath) =>
  RequestWebApi.get("/Acessos/ValidarAcessoPath", {
    params: { urlPath: urlPath },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const permissoes = resultData;
      return { success, permissoes };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, permissoes: null };
    });

export const ValidarAcessoRelatorio = (urlPath) =>
  RequestWebApi.get("/Acessos/ValidarAcessoRelatorioPath", {
    params: { urlPath: urlPath },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const permissoes = resultData;
      return { success, permissoes };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, permissoes: null };
    });
