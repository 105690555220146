import React, { useEffect, useState } from "react";
import "./TempoAtendimento.css";
import { Fragment } from "react";

const TempoAtendimento = ({
  atendimentoIniciado = false,
  obterTempoAtendimento = (segundos) => { },
}) => {
  const [tempoAtendimento, setTempoAtendimento] = useState(0);

  useEffect(() => {
    if (atendimentoIniciado) {
      setTimeout(() => {
        setTempoAtendimento(tempoAtendimento + 1);
        obterTempoAtendimento(tempoAtendimento);
      }, 1000);
    } else {
      setTempoAtendimento(0);
    }
  }, [atendimentoIniciado, obterTempoAtendimento, tempoAtendimento]);

  const tempoTela = (tempoTela) => {
    let horas = Math.floor(tempoTela / (60 * 60));
    let restoHoras = tempoTela % (60 * 60);
    let minutos = Math.floor(restoHoras / 60);
    let restoMinutos = restoHoras % 60;
    let segundos = Math.ceil(restoMinutos);
    let timer = {
      horas: `0${horas}`.slice(-2),
      minutos: `0${minutos}`.slice(-2),
      segundos: `0${segundos}`.slice(-2),
    };
    return `${timer.horas}:${timer.minutos}:${timer.segundos}`;
  };
  return (
    <Fragment>
      <div className="default-title">Tempo de atendimento</div>
      <div className="card shadow-sm mb-4 btn-default">
        <div className="row align-items-start">
          <div className="col-12 text-center">
            <h2 data-chronometer id="timer" className="tempoAtendimento-timer">
              {tempoTela(tempoAtendimento)}
            </h2>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TempoAtendimento;
