/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext } from "react";
import AppContext from "../../../../Store/AppContext";
import { removeItemFromArray } from "../../../../Utils/globalFunctions";
import { Listar as ListarUsuario } from "../../../../Services/Usuario";
import { Listar as ListarTipoAgendamento } from "../../../../Services/TipoAgendamento";
import {
  Listar as ListarPipeLine,
  Detalhes as DetalhesPipeLine,
  Remover as RemoverPipeLine,
  Incluir as IncluirPipeLine,
  Atualizar as AtualizarPipeLine,
} from "../../../../Services/PipeLine";
import { useHistory } from "react-router-dom";
import { validarPermissoesPagina } from "../../../../Utils/validarPermissoesPagina";
export const PipeLineContext = createContext();

const tituloTela = "Pipeline de Trabalho";

const defaultData = {
  pipeLineId: 0,
  pipeLineDescricao: "",
  descricaoDias: "",
  pipeLineFeriado: 0,
  consultores: [],
};

const searchData = {
  pipeLineDescricao: "",
  usuarioId: 0,
};

const columnsDefinition = [
  {
    text: "Id",
    dataField: "pipeLineId",
    hidden: true,
  },
  {
    text: "Código",
    dataField: "pipeLineCodigo",
    headerStyle: { width: "150px" },
    sort: true,
  },
  {
    text: "Descrição",
    dataField: "pipeLineDescricao",
    headerStyle: { width: "150px" },
    sort: true,
  },
  {
    text: "Status",
    dataField: "pipeLineAtivo",
    headerStyle: { width: "100px" },
    sort: true,
    formatter: (cell, row) => {
      return cell == "S" ? (
        <span className="badge badge-success">Ativo</span>
      ) : (
        <span className="badge badge-danger">Inátivo</span>
      );
    },
  }
];

const columnsDefinitionConsultores = [
  {
    text: "Id",
    dataField: "usuarioId",
    hidden: true,
  },
  {
    text: "Consultor",
    dataField: "usuarioNome",
    headerStyle: { width: "250px" },
    sort: true,
  },
];

const columnsDefinitionTiposAgendamento = [
  {
    text: "Id",
    dataField: "tipoAgendamentoId",
    hidden: true,
  },
  {
    text: "Tipo Agendamento",
    dataField: "tipoAgendamentoDescricao",
    headerStyle: { width: "250px" },
    sort: true,
  },
];

const defaultDataConsultor = { usuarioId: 0, usuarioNome: "" };
const defaultDataTipoAgendamento = { tipoAgendamentoId: 0, tipoAgendamentoDescricao: "" };

function initialState() {
  return {
    searchData: searchData,
    resultSearch: [],
    columnsDefinition: columnsDefinition,
    data: defaultData,
    formDisabled: true,
    usuarios: [],
    tiposAgendamento: [],
    dataConsultor: defaultDataConsultor,
    dataTipoAgendamento: defaultDataTipoAgendamento,
    columnsDefinitionConsultores: columnsDefinitionConsultores,
    columnsDefinitionTiposAgendamento: columnsDefinitionTiposAgendamento,
    consultoresPipeLine: [],
    tiposAgendamentoPipeLine: [],
  };
}

const PipeLineProvider = ({ children }) => {
  const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(
    AppContext
  );
  const [values, setValues] = useState(initialState());
  let history = useHistory();

  async function loadDependences() {
    const permissoesPagina = validarPermissoesPagina();
    const responseListarUsuaro = await ListarUsuario({});
    const responseListarTipoAgendamento = await ListarTipoAgendamento({ desconsiderarPipeLine: true });

    let usuarios = [];
    let tiposAgendamento = [];

    if (!responseListarUsuaro.success
      || !responseListarTipoAgendamento.success) {
      showMessage("Erro", "Erro Inesperado ao carregar dados basicos.");
    } else {
      usuarios = await responseListarUsuaro.usuarios;
      tiposAgendamento = await responseListarTipoAgendamento.tiposAgendamento;
    }

    return { permissoesPagina: permissoesPagina, usuarios: usuarios, tiposAgendamento: tiposAgendamento };
  }

  const resetScreen = () => {
    showLoading();
    async function carregarDependencias() {
      const dependencias = await loadDependences();
      setTituloTela(tituloTela);
      setValues({
        ...initialState(),
        permissoesPagina: await dependencias.permissoesPagina,
        usuarios: await dependencias.usuarios,
        tiposAgendamento: await dependencias.tiposAgendamento,
      });
      hideLoading();
    }
    carregarDependencias();
  };

  const searchHandler = async () => {
    showLoading();
    const responseListarPipeLine = await ListarPipeLine(
      values.searchData
    );

    if (!responseListarPipeLine.success) {
      showMessage("Erro", "Erro Inesperado durante a pesquisa.");
      hideLoading();
      return;
    }

    let resultSearchData = await responseListarPipeLine.pipeLineList;

    resultSearchData.map((data) => {
      data.Id = data.pipeLineId;
      return data;
    });

    setValues({
      ...values,
      resultSearch: resultSearchData,
    });

    if (resultSearchData.length === 0) {
      showMessage("Alerta", "Nenhum registro encontrado.");
    }
    hideLoading();
  };

  const removeHandler = async (id) => {
    showLoading();

    const responseRemover = await RemoverPipeLine(id);

    if (!responseRemover.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro durante a exclusão: ${responseRemover.message}`
      );
      return;
    }
    searchHandler();
    hideLoading();
    showMessage("Sucesso", "Registro excluido.");
  };

  const getDetailHandler = async (id) => {
    showLoading();
    const dependencias = await loadDependences();

    let usuariosBase = await dependencias.usuarios;

    if (id > 0) {
      const responseDetalhes = await DetalhesPipeLine(id);

      if (!responseDetalhes.success) {
        showMessage("Erro", "Erro ao buscar os detalhes do registro.");
        setValues({
          ...values,
          data: defaultData,
          permissoesPagina: await dependencias.permissoesPagina,
          usuarios: [],
          tiposAgendamento: [],
        });
      }

      let consultoresPipeLine = responseDetalhes.consultores;
      consultoresPipeLine.map((consultorPipeLine) => {
        consultorPipeLine.id = consultorPipeLine.usuarioId;
        consultorPipeLine.usuarioNome = usuariosBase.find(
          (consultor) => consultor.usuarioId === consultorPipeLine.usuarioId
        ).usuarioNome;
        return consultorPipeLine;
      });

      let tiposAgendamentoPipeLine = responseDetalhes.tiposAgendamento;
      setValues({
        ...values,
        data: { ...responseDetalhes },
        permissoesPagina: await dependencias.permissoesPagina,
        usuarios: usuariosBase,
        tiposAgendamento: await dependencias.tiposAgendamento,
        consultoresPipeLine: consultoresPipeLine,
        tiposAgendamentoPipeLine: tiposAgendamentoPipeLine,
      });
    } else {
      setValues({
        ...values,
        data: {
          ...defaultData,
          pipeLineId: 0,
        },
        formDisabled: false,
        permissoesPagina: await dependencias.permissoesPagina,
        usuarios: usuariosBase,
        tiposAgendamento: await dependencias.tiposAgendamento,
        consultoresPipeLine: [],
        tiposAgendamentoPipeLine: [],
      });
    }
    hideLoading();
  };

  const createData = async () => {
    showLoading();
    const responseIncluir = await IncluirPipeLine(values.data);
    if (!responseIncluir.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao criar o registro: ${responseIncluir.message}`
      );
      responseIncluir.errors.map((erro) =>
        showMessage("Erro", erro.errorMessage)
      );

      return;
    }

    resetScreen();
    hideLoading();
    showMessage("Sucesso", "Registro criado.");
    history.goBack();
  };

  const editHandler = () => {
    setValues({
      ...values,
      formDisabled: false,
    });
  };

  const updateData = async () => {
    showLoading();
    const responseAtualizar = await AtualizarPipeLine(values.data);
    if (!responseAtualizar.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao atualizar o registro: ${responseAtualizar.message}`
      );
      responseAtualizar.errors.map((erro) =>
        showMessage("Erro", erro.errorMessage)
      );

      return;
    }
    resetScreen();
    hideLoading();
    showMessage("Sucesso", "Registro atualizado.");
    history.goBack();
  };

  const saveHandler = async () => {
    if (values.data.pipeLineId === 0) {
      await createData();
    } else {
      await updateData();
    }
  };

  const cancelHandler = () => {
    setValues({
      ...values,
      data: defaultData,
      formDisabled: true,
    });
  };

  const adionarConsultor = () => {
    let supervisorJaAdiocionado = values.consultoresPipeLine.find(
      // eslint-disable-next-line eqeqeq
      (consultor) => consultor.usuarioId == values.dataConsultor.id
    );

    if (supervisorJaAdiocionado) {
      showMessage("Alerta", "Consultor já incluído.");
      return;
    }

    var consultoresPipeLineNew = values.consultoresPipeLine;
    consultoresPipeLineNew.push(values.dataConsultor);
    setValues({
      ...values,
      dataConsultor: defaultDataConsultor,
      consultoresPipeLine: consultoresPipeLineNew,
      data: {
        ...values.data,
        consultores: consultoresPipeLineNew,
      },
    });
  };

  const removerConsultor = (id) => {
    var consultoresPipeLineNew = values.consultoresPipeLine;

    removeItemFromArray(
      consultoresPipeLineNew,
      consultoresPipeLineNew.find((consultor) => consultor.id === id)
    );

    setValues({
      ...values,
      dataConsultor: defaultDataConsultor,
      consultoresPipeLine: consultoresPipeLineNew,
      data: {
        ...values.data,
        consultores: consultoresPipeLineNew,
      },
    });
  };


  const adionarTipoAgendamento = () => {
    let tipoAgendamentoJaAdiocionado = values.tiposAgendamentoPipeLine.find(
      // eslint-disable-next-line eqeqeq
      (tipoAgendamento) => tipoAgendamento.tipoAgendamentoId == values.dataTipoAgendamento.id
    );
    if (tipoAgendamentoJaAdiocionado) {
      showMessage("Alerta", "Tipo agendamento já incluído.");
      return;
    }

    var tiposAgendamentoPipeLineNew = values.tiposAgendamentoPipeLine;
    tiposAgendamentoPipeLineNew.push(values.dataTipoAgendamento);
    setValues({
      ...values,
      dataTipoAgendamento: defaultDataTipoAgendamento,
      tiposAgendamentoPipeLine: tiposAgendamentoPipeLineNew,
      data: {
        ...values.data,
        tiposAgendamento: tiposAgendamentoPipeLineNew,
      },
    });
  };

  const removerTipoAgendamento = (id) => {
    var tiposAgendamentoPipeLineNew = values.tiposAgendamentoPipeLine;

    removeItemFromArray(
      tiposAgendamentoPipeLineNew,
      tiposAgendamentoPipeLineNew.find((tipoAgendamento) => tipoAgendamento.id === id)
    );

    setValues({
      ...values,
      dataTipoAgendamento: defaultDataTipoAgendamento,
      tiposAgendamentoPipeLine: tiposAgendamentoPipeLineNew,
      data: {
        ...values.data,
        tiposAgendamento: tiposAgendamentoPipeLineNew,
      },
    });
  };

  return (
    <PipeLineContext.Provider
      value={{
        values,
        setValues,
        resetScreen,
        searchHandler,
        cancelHandler,
        removeHandler,
        saveHandler,
        getDetailHandler,
        editHandler,
        adionarConsultor,
        removerConsultor,
        removerTipoAgendamento,
        adionarTipoAgendamento,
      }}
    >
      {children}
    </PipeLineContext.Provider>
  );
};

export default PipeLineProvider;
