/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext } from "react";
import AppContext from "../../../../Store/AppContext";
import {
  Listar as ListarPreLeadCotacao,
  ListarItem as ListarItensPreLeadCotacao,
  Remover as RemoverPreLeadCotacao,
  Incluir as IncluirPreLeadCotacao,
  Atualizar as AtualizarPreLeadCotacao,
} from "../../../../Services/PreLeadCotacao";
import { useHistory } from "react-router-dom";
import { validarPermissoesPagina } from "../../../../Utils/validarPermissoesPagina";
export const PreLeadCotacaoContext = createContext();

const tituloTela = "Pré Lead Cotacão";

const defaultData = {
  preLeadId: 0,
  leadNome: '',
  produtoId: 0,
  produtoNome: '',
  preLeadCotacaoUid: '',
  preLeadCotacaoCampo: '',
  preLeadCotacaoValor: '',
  preLeadCotacaoDataHora: null,
  preLeadCotacaoJornadaAssistida: '',
  tipoVeiculoId: 0,
  preLeadCotacaoJornada: ''
};

const searchData = {
  preLeadCotacaoJornadaAssistida: "",
  placaVeiculo: "",
  cpf: "",
};

const columnsDefinition = [
  {
    text: "id",
    dataField: "preLeadCotacaoId",
    hidden: true,
  },
  {
    text: "Nome",
    dataField: "leadNome",
    headerStyle: { width: "100px" },
    sort: true,
  },
  {
    text: "Jornada Assistida",
    dataField: "preLeadCotacaoJornadaAssistida",
    headerStyle: { width: "100px" },
    sort: true,
  },
  {
    text: "Jornada",
    dataField: "preLeadCotacaoJornada",
    headerStyle: { width: "100px" },
    sort: true,
  },
  {
    text: "Produto",
    dataField: "produtoNome",
    headerStyle: { width: "120px" },
    sort: true,
  },
  {
    text: "Total Campos",
    dataField: "totalCampos",
    headerStyle: { width: "80px" },
    sort: true,
  }
];


function initialState() {
  return {
    searchData: searchData,
    preLeadCotacoes: [],
    resultSearch: [],
    columnsDefinition: columnsDefinition,
    data: defaultData,
    formDisabled: true,
  };
}

const PreLeadCotacaoProvider = ({ children }) => {
  const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(
    AppContext
  );
  const [values, setValues] = useState(initialState());
  let history = useHistory();

  async function loadDependences() {
    const permissoesPagina = validarPermissoesPagina();
    return { permissoesPagina };
  }

  const resetScreen = () => {
    showLoading();
    async function carregarDependencias() {
      const dependencias = await loadDependences();
      setTituloTela(tituloTela);
      setValues({
        ...initialState(),
        permissoesPagina: await dependencias.permissoesPagina,
      });
      hideLoading();
    }
    carregarDependencias();
  };

  const searchHandler = async () => {
    showLoading();
    let filtro = values.searchData.preLeadCotacaoJornadaAssistida.trim()
    filtro += values.searchData.placaVeiculo.trim();
    filtro += values.searchData.cpf.trim();
    if (!filtro) {
      showMessage("Erro", "Informe ao menos um filtro.");
      hideLoading();
      return;
    }

    const responseListarPreLeadCotacao = await ListarPreLeadCotacao(
      values.searchData
    );

    if (!responseListarPreLeadCotacao.success) {
      showMessage("Erro", "Erro Inesperado durante a pesquisa.");
      hideLoading();
      return;
    }

    const resultSearchData = await responseListarPreLeadCotacao.preLeadCotacoes;

    resultSearchData.map((data) => (data.Id = data.preLeadCotacaoId));

    setValues({
      ...values,
      resultSearch: resultSearchData,
    });

    if (resultSearchData.length === 0) {
      showMessage("Alerta", "Nenhum registro encontrado.");
    }
    hideLoading();
  };

  const removeHandler = async (id) => {
    showLoading();

    const responseRemover = await RemoverPreLeadCotacao(id);

    if (!responseRemover.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro durante a exclusão: ${responseRemover.message}`
      );
      return;
    }
    searchHandler();
    hideLoading();
    showMessage("Sucesso", "Registro excluido.");
  };

  const getDetailHandler = async (id) => {
    showLoading();

    const responseListarPreLeadCotacaoItens = await ListarItensPreLeadCotacao({
      preLeadCotacaoId: id
    });
    let dataItem = responseListarPreLeadCotacaoItens.preLeadItensCotacao.length > 0 ? responseListarPreLeadCotacaoItens.preLeadItensCotacao[0] : defaultData;
    setValues({
      ...values,
      data: dataItem,
      preLeadCotacoes: responseListarPreLeadCotacaoItens.preLeadItensCotacao,
    });

    hideLoading();
  };

  const createData = async () => {
    showLoading();
    const responseIncluir = await IncluirPreLeadCotacao(values.data);
    if (!responseIncluir.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao criar o registro: ${responseIncluir.message}`
      );
      responseIncluir.errors.map((erro) =>
        showMessage("Erro", erro.errorMessage)
      );
      return;
    }

    resetScreen();
    hideLoading();
    showMessage("Sucesso", "Registro criado.");
    history.goBack();
  };

  const editHandler = () => {
    setValues({
      ...values,
      formDisabled: false,
    });
  };

  const updateData = async () => {
    showLoading();
    const responseAtualizar = await AtualizarPreLeadCotacao(values.data);
    if (!responseAtualizar.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao atualizar o registro: ${responseAtualizar.message}`
      );
      return;
    }
    resetScreen();
    hideLoading();
    showMessage("Sucesso", "Registro atualizado.");
    history.goBack();
  };

  const saveHandler = async () => {
    if (values.data.preLeadCotacaoId === 0) {
      await createData();
    } else {
      await updateData();
    }
  };

  const cancelHandler = () => {
    setValues({
      ...values,
      data: defaultData,
      formDisabled: true,
    });
  };

  return (
    <PreLeadCotacaoContext.Provider
      value={{
        values,
        setValues,
        resetScreen,
        searchHandler,
        cancelHandler,
        removeHandler,
        saveHandler,
        getDetailHandler,
        editHandler,
      }}
    >
      {children}
    </PreLeadCotacaoContext.Provider>
  );
};

export default PreLeadCotacaoProvider;
