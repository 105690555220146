import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRoute from "../../../../Routes/PrivateRoutes";
import TipoAgendamentoProvider from "./TipoAgendamentoContext";
import PesquisarTipoAgendamento from "./Events/PesquisarTipoAgendamento";
import FormularioTipoAgendamento from "./Events/FormularioTipoAgendamento";

const Index = () => {
  let match = useRouteMatch();
  return (
    <TipoAgendamentoProvider>
      <div className="container-fluid pl-3 pt-3 pb-3">
        <Switch>
          <PrivateRoute
            exact
            path={`${match.url}`}
            component={PesquisarTipoAgendamento}
          />
          <PrivateRoute
            path={`${match.url}/:id`}
            component={FormularioTipoAgendamento}
          />
        </Switch>
      </div>
    </TipoAgendamentoProvider>
  );
};

export default Index;
