import React from "react";
import "./GroupBox.css";

const GroupBox = ({ children, titulo, className }) => {
  return (
    <div className={className}>
      <fieldset>
        <legend>{titulo}</legend>
        <div className="row align-items-end">{children}</div>
      </fieldset>
    </div>
  );
};

export default GroupBox;
