import React, { useContext, Fragment, useEffect } from "react";
import { SinistroContext } from "../SinistroContext";
import Pesquisar from "../../../../Components/Cadastro/Pesquisar/Pesquisar";
import TabelaResultados from "../../../../Components/Cadastro/TabelaResultados/TabelaResultados";
import GroupBox from "../../../../Components/GroupBox/GroupBox";

const PesquisarSinistro = () => {
  const { values, setValues, resetScreen } = useContext(SinistroContext);

  useEffect(() => {
    resetScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      searchData: {
        ...values.searchData,
        [name]: value,
      },
    });
  }

  function onChangeCheck(event) {
    const { name, checked } = event.target;
    setValues({
      ...values,
      searchData: {
        ...values.searchData,
        [name]: checked
      }
    });
  }


  return (
    <Fragment>
      <Pesquisar context={SinistroContext}>
        <div className="col-12 col-md-2 form-group">
          <label>CPF/CNPJ:</label>
          <input
            className="form-control"
            type="text"
            name="leadNumDocumento"
            value={values.searchData.leadNumDocumento}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-5 form-group">
          <label>Nome Cliente</label>
          <input
            className="form-control"
            type="text"
            name="sinistroNomeCliente"
            value={values.searchData.sinistroNomeCliente}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-2 form-group">
          <label>Apólice</label>
          <input
            className="form-control"
            type="text"
            name="numeroApolice"
            value={values.searchData.numeroApolice}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-3 form-group">
          <label>
            Situação
          </label>
          <select
            className="form-control"
            name="situacaoSinistroId"
            value={values.searchData.situacaoSinistroId}
            onChange={onChangeInput}
          >
            <option className="disabled" value="0"></option>
            {values.situacoesSinistro.map((situacaoSinistro) => (
              <option
                key={situacaoSinistro.situacaoSinistroId}
                value={situacaoSinistro.situacaoSinistroId}
              >
                {situacaoSinistro.situacaoSinistroDescricao}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-5 form-group">
          <label>Nome Terceiro</label>
          <input
            className="form-control"
            type="text"
            name="sinistroNomeTerceiro"
            value={values.searchData.sinistroNomeTerceiro}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-2 form-group">
          <label>Finalizado?</label>
          <div className="col-12 col-md-2 ">
            <div className="custom-control custom-switch float-center">
              <input
                checked={values.searchData.sinistroFinalizado}
                type="checkbox"
                className="custom-control-input"
                id="sinistroFinalizado"
                name="sinistroFinalizado"
                onChange={onChangeCheck}
              />
              <label className="custom-control-label" htmlFor="sinistroFinalizado" />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-2 form-group">
          <label>Placa</label>
          <input
            className="form-control placa_veiculo"
            type="text"
            name="placaVeiculo"
            value={values.searchData.placaVeiculo}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-2 form-group">
          <label>Numero Aviso</label>
          <input
            className="form-control"
            type="text"
            name="numeroAvisoSeguradora"
            value={values.searchData.numeroAvisoSeguradora}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-12 form-group"></div>
        <GroupBox
          titulo="Aviso ao Corretor"
          className="col-12 col-md-4 form-group"
        >
          <div className="col-12 col-md-6 ">
            <label>De:</label>
            <input
              className="form-control"
              type="date"
              name="sinistroDataHoraDe"
              value={values.searchData.sinistroDataHoraDe}
              onChange={onChangeInput}
            />
          </div>
          <div className="col-12 col-md-6 ">
            <label>Até:</label>
            <input
              className="form-control"
              type="date"
              name="sinistroDataHoraAte"
              value={values.searchData.sinistroDataHoraAte}
              onChange={onChangeInput}
            />
          </div>
        </GroupBox>
        <GroupBox
          titulo="Data do Acidente"
          className="col-12 col-md-4 form-group"
        >
          <div className="col-12 col-md-6 ">
            <label>De:</label>
            <input
              className="form-control"
              type="date"
              name="sinistroDataAcidenteDe"
              value={values.searchData.sinistroDataAcidenteDe}
              onChange={onChangeInput}
            />
          </div>
          <div className="col-12 col-md-6 ">
            <label>Até:</label>
            <input
              className="form-control"
              type="date"
              name="sinistroDataAcidenteAte"
              value={values.searchData.sinistroDataAcidenteAte}
              onChange={onChangeInput}
            />
          </div>
        </GroupBox>
        <GroupBox
          titulo="Data Retorno"
          className="col-12 col-md-4 form-group"
        >
          <div className="col-12 col-md-6 ">
            <label>De:</label>
            <input
              className="form-control"
              type="date"
              name="sinistroDataRetornoDe"
              value={values.searchData.sinistroDataRetornoDe}
              onChange={onChangeInput}
            />
          </div>
          <div className="col-12 col-md-6 ">
            <label>Até:</label>
            <input
              className="form-control"
              type="date"
              name="sinistroDataRetornoAte"
              value={values.searchData.sinistroDataRetornoAte}
              onChange={onChangeInput}
            />
          </div>
        </GroupBox>
      </Pesquisar>
      <TabelaResultados context={SinistroContext} />
    </Fragment>
  );
};

export default PesquisarSinistro;
