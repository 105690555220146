import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "PreLeadCotacao";
export const Listar = (data) =>
  RequestWebApi.get(`/${controller}/Listar`, {
    params: { ...data },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const preLeadCotacoes = resultData;
      return { success, preLeadCotacoes };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { preLeadCotacoes: [] };
    });

export const ListarItem = (data) =>
  RequestWebApi.get(`/${controller}/ListarItem`, {
    params: { ...data },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const preLeadItensCotacao = resultData;
      return { success, preLeadItensCotacao };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { preLeadItensCotacao: [] };
    });

export const Detalhes = (preLeadCotacaoId) =>
  RequestWebApi.get(`/${controller}/Detalhes`, {
    params: { preLeadCotacaoId: preLeadCotacaoId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const produto = resultData;
      return { success, produto };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, produto: {} };
    });
export const Incluir = (preLeadCotacao) =>
  RequestWebApi.post(`/${controller}/Incluir`, preLeadCotacao)
    .then(function (response) {
      const { resultData, success } = response.data;
      const novaPreLeadCotacao = resultData;
      return { success, novaPreLeadCotacao };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Atualizar = (preLeadCotacao) =>
  RequestWebApi.put(`/${controller}/Atualizar`, preLeadCotacao)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (preLeadCotacaoId) =>
  RequestWebApi.delete(`/${controller}/Remover`, {
    params: { preLeadCotacaoId: preLeadCotacaoId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });
