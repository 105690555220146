/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext } from "react";
import AppContext from "../../../../Store/AppContext";
import { removeItemFromArray } from "../../../../Utils/globalFunctions";
import { Listar as ListarGrupoUsuario } from "../../../../Services/GrupoUsuario";
import { Listar as ListarProduto } from "../../../../Services/Produto";
import { Listar as ListarEquipe } from "../../../../Services/Equipe";
import { Listar as ListarConsultorNivel } from "../../../../Services/ConsultorNivel";
import { ListarRelatoriosUsuario } from "../../../../Services/Relatorio";
import {
  Listar as ListarUsuario,
  Detalhes as DetalhesUsuario,
  Remover as RemoverUsuario,
  Incluir as IncluirUsuario,
  Atualizar as AtualizarUsuario,
  ReiniciarSenha,
} from "../../../../Services/Usuario";
import { useHistory } from "react-router-dom";
import { validarPermissoesPagina } from "../../../../Utils/validarPermissoesPagina";
export const UsuarioContext = createContext();

const tituloTela = "Cadastro de Usuário";

const defaultData = {
  usuarioId: 0,
  usuarioLogin: "",
  grupoUsuarioId: 0,
  grupoUsuarioDesc: "",
  usuarioNome: "",
  usuarioEmail: "",
  usuarioSenhaValidade: "",
  usuarioStatus: "",
  usuarioFoto: "",
  usuarioRamal: "",
  usuarioEquipe: "",
  consultorNivelId: 0,
  equipes: [],
  ips: [],
  permissoesRelatorios: [],
};

const searchData = {
  usuarioNome: "",
  usuarioLogin: "",
  grupoUsuarioId: 0,
};

const columnsDefinition = [
  {
    text: "Id",
    dataField: "usuarioId",
    hidden: true,
  },
  {
    text: "Login",
    dataField: "usuarioLogin",
    headerStyle: { width: "120px" },
    sort: true,
  },
  {
    text: "Nome Completo",
    dataField: "usuarioNome",
    headerStyle: { width: "250px" },
    sort: true,
  },
  {
    text: "E-mail",
    dataField: "usuarioEmail",
    headerStyle: { width: "250" },
    sort: true,
  },
  {
    text: "Perfil",
    dataField: "grupoUsuarioDesc",
    headerStyle: { width: "200px" },
    sort: true,
  },
  {
    text: "Status",
    dataField: "usuarioStatus",
    headerStyle: { width: "100px" },
    sort: true,
    formatter: (cell, row) => {
      return cell === "A" ? (
        <span className="badge badge-success">Ativo</span>
      ) : (
        <span className="badge badge-danger">Inátivo</span>
      );
    },
  },
];

const defaultDataEquipe = {
  equipeId: "",
  equipeNome: "",
  produtoId: "",
  produtoNome: "",
  supervisor: 0,
};

const defaultIp = {
  usuarioQuaisIpsNumeroIp: ""
};

const equipesUsuarioColumnsDefinition = [
  {
    text: "equipeId",
    dataField: "equipeId",
    hidden: true,
  },
  {
    text: "produtoId",
    dataField: "produtoId",
    headerStyle: { width: "120px" },
    hidden: true,
    sort: true,
  },
  {
    text: "Produto",
    dataField: "produtoNome",
    headerStyle: { width: "120px" },
    sort: true,
  },
  {
    text: "Equipe",
    dataField: "equipeNome",
    headerStyle: { width: "120px" },
    sort: true,
  },

  {
    text: "Supervisor",
    dataField: "supervisor",
    headerStyle: { width: "80px" },
    sort: true,
    formatter: (cell, row) => {
      return cell === 1 ? (
        <span className="badge badge-success">Sim</span>
      ) : (
        <span className="badge badge-danger">Não</span>
      );
    },
  },
];

const ipsColumnsDefinition = [
  {
    text: "Ip",
    dataField: "usuarioQuaisIpsNumeroIp",
    headerStyle: { width: "60px" },
    sort: true,
  }
];

function initialState() {
  return {
    searchData: searchData,
    resultSearch: [],
    columnsDefinition: columnsDefinition,
    data: defaultData,
    formDisabled: true,
    perfis: [],
    niveisConsultor: [],
    produtos: [],
    equipes: [],
    ips: [],
    dataIp: defaultIp,
    equipesProduto: [],
    dataEquipe: defaultDataEquipe,
    equipesUsuario: [],
    equipesUsuarioColumnsDefinition: equipesUsuarioColumnsDefinition,
    ipsColumnsDefinition: ipsColumnsDefinition,
    permissoesRelatoriosUsuario: [],
  };
}

const UsuarioProvider = ({ children }) => {
  const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(
    AppContext
  );
  const [values, setValues] = useState(initialState());
  let history = useHistory();

  async function loadDependences() {
    const permissoesPagina = validarPermissoesPagina();
    const responseListarGrupoUsuario = await ListarGrupoUsuario({});
    const responseListarProduto = await ListarProduto({});
    const responseListarEquipe = await ListarEquipe({});
    const responseListarConsultorNivel = await ListarConsultorNivel({});

    if (
      !responseListarGrupoUsuario.success ||
      !responseListarProduto.success ||
      !responseListarEquipe.success ||
      !responseListarConsultorNivel.success
    ) {
      showMessage("Erro", "Erro Inesperado ao carregar dados basicos.");
      return { perfis: [], produtos: [], equipes: [] };
    }

    const perfis = await responseListarGrupoUsuario.gruposUsuario;
    const produtos = await responseListarProduto.produtos.filter((item) => item.produtoAtivo === 'A');
    const equipes = await responseListarEquipe.equipes;
    const niveisConsultor = await responseListarConsultorNivel.consultorNivelList;
    return {
      permissoesPagina: permissoesPagina,
      perfis: perfis,
      produtos: produtos,
      equipes: equipes,
      niveisConsultor: niveisConsultor,
    };
  }

  const resetScreen = () => {
    showLoading();
    async function carregarDependencias() {
      const dependencias = await loadDependences();
      setTituloTela(tituloTela);
      setValues({
        ...initialState(),
        permissoesPagina: await dependencias.permissoesPagina,
        produtos: await dependencias.produtos,
        perfis: await dependencias.perfis,
        equipes: await dependencias.equipes,
        niveisConsultor: await dependencias.niveisConsultor,
      });
      hideLoading();
    }
    carregarDependencias();
  };

  const searchHandler = async () => {
    showLoading();
    const responseListarUsuario = await ListarUsuario(values.searchData);

    if (!responseListarUsuario.success) {
      showMessage("Erro", "Erro Inesperado durante a pesquisa.");
      hideLoading();
      return;
    }

    const resultSearchData = await responseListarUsuario.usuarios;

    resultSearchData.map((data) => {
      data.Id = data.usuarioId;
      data.grupoUsuarioDesc = values.perfis.find(
        (perfil) => perfil.grupoUsuarioId === data.grupoUsuarioId
      ).grupoUsuarioNome;
      return data;
    });

    setValues({
      ...values,
      resultSearch: resultSearchData,
    });

    if (resultSearchData.length === 0) {
      showMessage("Alerta", "Nenhum registro encontrado.");
    }
    hideLoading();
  };

  const removeHandler = async (id) => {
    showLoading();

    const responseRemover = await RemoverUsuario(id);

    if (!responseRemover.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro durante a exclusão: ${responseRemover.message}`
      );
      responseRemover.errors.map((erro) =>
        showMessage("Erro", erro.errorMessage)
      );
      return;
    }
    searchHandler();
    hideLoading();
    showMessage("Sucesso", "Registro excluido.");
  };

  const getDetailHandler = async (id) => {
    showLoading();
    const dependencias = await loadDependences();

    if (id > 0) {
      const [
        responseDetalhes,
        responseListarRelatorios
      ] = await Promise.all([
        DetalhesUsuario(id),
        ListarRelatoriosUsuario(id)
      ]);


      if (!responseDetalhes.success) {
        hideLoading();
        showMessage("Erro", `${responseDetalhes.message}`);
        responseDetalhes.errors.map((erro) =>
          showMessage("Erro", erro.errorMessage)
        );
        setTimeout(() => {
          history.goBack();
        }, 1500);
        return {};
      }

      const { usuario, equipes, ips } = await responseDetalhes;
      let equipesBase = await dependencias.equipes;
      let produtosBase = await dependencias.produtos;
      let niveisConsultor = await dependencias.niveisConsultor;

      const permissoesRelatoriosUsuario = responseListarRelatorios.relatorios;
      let permissoes = [];
      permissoesRelatoriosUsuario.map(relatorioModulo => {
        let permissoesModulo = relatorioModulo.relatorios.filter(x => x.temPermissao === true);
        permissoesModulo.map(p => permissoes.push(p));
      })

      let equipesUsuario = equipes.map((equipe) => {
        const { equipeNome, produtoId } = equipesBase.find(
          (equipeBase) => equipeBase.equipeId === equipe.equipeId
        );

        const { produtoNome, produtoCod } = produtosBase.find(
          (produtoBase) => produtoBase.produtoId === produtoId
        );

        return {
          id: equipe.equipeId,
          equipeId: equipe.equipeId,
          equipeNome: equipeNome,
          produtoId: produtoId,
          produtoNome: `${produtoCod} - ${produtoNome}`,
          supervisor: equipe.equipeQuaisUsuariosSupervisor,
        };
      });

      let ipsAdaptado = ips.map(ip => {
        return {
          ...ip,
          id: ip.usuarioQuaisIpsNumeroIp
        }
      });

      setValues({
        ...values,
        data: { ...usuario, equipes, ips, permissoesRelatorios: permissoes },
        perfis: await dependencias.perfis,
        permissoesPagina: await dependencias.permissoesPagina,
        produtos: produtosBase,
        niveisConsultor: niveisConsultor,
        equipes: equipesBase,
        equipesUsuario: equipesUsuario,
        ips: ipsAdaptado,
        permissoesRelatoriosUsuario: permissoesRelatoriosUsuario,
      });
    } else {
      const [
        responseListarRelatorios
      ] = await Promise.all([
        ListarRelatoriosUsuario(0)
      ]);
      const permissoesRelatoriosUsuario = responseListarRelatorios.relatorios;

      setValues({
        ...values,
        data: {
          ...defaultData,
          usuarioId: 0,
        },
        formDisabled: false,
        perfis: dependencias.perfis,
        permissoesPagina: await dependencias.permissoesPagina,
        produtos: await dependencias.produtos,
        niveisConsultor: await dependencias.niveisConsultor,
        equipes: await dependencias.equipes,
        permissoesRelatoriosUsuario: permissoesRelatoriosUsuario,
      });
    }
    hideLoading();
  };

  const createData = async () => {
    showLoading();
    const responseIncluir = await IncluirUsuario(values.data);
    if (!responseIncluir.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao criar o registro: ${responseIncluir.message}`
      );
      responseIncluir.errors.map((erro) =>
        showMessage("Erro", erro.errorMessage)
      );

      return;
    }

    resetScreen();
    hideLoading();
    showMessage("Sucesso", "Registro criado.");
    history.goBack();
  };

  const editHandler = () => {
    setValues({
      ...values,
      formDisabled: false,
    });
  };

  const updateData = async () => {
    showLoading();
    const responseAtualizar = await AtualizarUsuario(values.data);
    if (!responseAtualizar.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao atualizar o registro: ${responseAtualizar.message}`
      );
      responseAtualizar.errors.map((erro) =>
        showMessage("Erro", erro.errorMessage)
      );
      return;
    }
    resetScreen();
    hideLoading();
    showMessage("Sucesso", "Registro atualizado.");
    history.goBack();
  };

  const saveHandler = async () => {
    if (values.data.usuarioId === 0) {
      await createData();
    } else {
      await updateData();
    }
  };

  const cancelHandler = () => {
    setValues({
      ...values,
      data: defaultData,
      formDisabled: true,
    });
  };

  const filtraEquipes = (produtoId, produtoNome) => {
    const equipes = values.equipes.filter(
      // eslint-disable-next-line eqeqeq
      (equipe) => equipe.produtoId == produtoId
    );

    setValues({
      ...values,
      equipesProduto: equipes,
      dataEquipe: {
        ...values.dataEquipe,
        produtoId: produtoId,
        produtoNome: produtoNome,
        equipeId: "",
      },
    });
  };

  const adicionarEquipe = () => {
    let equipeJaAdiocionada = values.equipesUsuario.find(
      // eslint-disable-next-line eqeqeq
      (equipe) => equipe.equipeId == values.dataEquipe.id
    );

    if (equipeJaAdiocionada) {
      showMessage("Alerta", "Equipe já incluída.");
      return;
    }

    let listaEquipesUsuario = values.equipesUsuario;

    if (!listaEquipesUsuario) listaEquipesUsuario = [];

    listaEquipesUsuario.push(values.dataEquipe);

    setValues({
      ...values,
      dataEquipe: {
        ...defaultDataEquipe,
        produtoId: values.dataEquipe.produtoId,
        produtoNome: values.dataEquipe.produtoNome,
      },
      equipesUsuario: listaEquipesUsuario,
      data: {
        ...values.data,
        equipes: atualizarEquipesDoUsuario(listaEquipesUsuario),
      },
    });
  };

  const removerEquipe = (id) => {
    var equipesUsuarioNew = values.equipesUsuario;

    removeItemFromArray(
      equipesUsuarioNew,
      equipesUsuarioNew.find((equipe) => equipe.equipeId === id)
    );

    setValues({
      ...values,
      dataEquipe: defaultDataEquipe,
      equipesUsuario: equipesUsuarioNew,
      data: {
        ...values.data,
        equipes: atualizarEquipesDoUsuario(equipesUsuarioNew),
      },
    });
  };

  const atualizarEquipesDoUsuario = (equipesUsuario) => {
    return equipesUsuario.map((equipe) => {
      return {
        equipeId: equipe.equipeId,
        equipeQuaisUsuariosSupervisor: equipe.supervisor,
      };
    });
  };


  const adicionarIp = () => {
    let ipJaAdiocionado = values.ips.find(
      // eslint-disable-next-line eqeqeq
      (ip) => ip.usuarioQuaisIpsNumeroIp == values.dataIp.usuarioQuaisIpsNumeroIp
    );

    if (ipJaAdiocionado) {
      showMessage("Alerta", "Ip já incluído.");
      return;
    }

    let listaIpsUsuario = values.ips;

    if (!listaIpsUsuario) listaIpsUsuario = [];

    listaIpsUsuario.push(values.dataIp);

    setValues({
      ...values,
      dataIp: {
        ...defaultIp
      },
      ips: listaIpsUsuario,
      data: {
        ...values.data,
        ips: listaIpsUsuario,
      },
    });
  };
  const removerIp = (usuarioQuaisIpsNumeroIp) => {

    var ipsUsuarioNew = values.ips;

    removeItemFromArray(
      ipsUsuarioNew,
      ipsUsuarioNew.find((ip) => ip.usuarioQuaisIpsNumeroIp === usuarioQuaisIpsNumeroIp)
    );

    setValues({
      ...values,
      dataIp: defaultIp,
      ips: ipsUsuarioNew,
      data: {
        ...values.data,
        ips: ipsUsuarioNew,
      },
    });
  };
  const reiniciarSenha = async (usuarioId) => {
    const responseRedefinirSenha = await ReiniciarSenha(usuarioId);
    if (!responseRedefinirSenha.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao reinicar senha: ${responseRedefinirSenha.message
          ? responseRedefinirSenha.message
          : "Indefinido"
        }`
      );
      if (responseRedefinirSenha.errors) {
        responseRedefinirSenha.errors.map((erro) =>
          showMessage("Erro", erro.errorMessage)
        );
      }
      return;
    }
    showMessage("Sucesso", "Senha redefinida com sucesso.");
  };

  return (
    <UsuarioContext.Provider
      value={{
        values,
        setValues,
        resetScreen,
        searchHandler,
        cancelHandler,
        removeHandler,
        saveHandler,
        getDetailHandler,
        editHandler,
        filtraEquipes,
        adicionarEquipe,
        removerEquipe,
        adicionarIp,
        removerIp,
        reiniciarSenha
      }}
    >
      {children}
    </UsuarioContext.Provider>
  );
};

export default UsuarioProvider;
