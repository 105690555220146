import React, { Fragment } from "react";
import "./Agendamento.css";
import TimeLine from "../../Historico/TimeLine/TimeLine";
import AlteraAgendamento from "../AlteraAgendamento/AlteraAgendamento";
import { useHistory } from "react-router-dom";
const Agendamento = ({
  children = null,
  refreshEventHandler = () => {},
  tiposAgendamento = [],
  bloquearModal = false,
  agendamentoId = 0,
  tipoAgendamentoId = 0,
  filaAtendimentoId = 0,
  tipoAgendamentoDescricao = "",
  leadNome = "",
  cidadeLead = "",
  totalContatos = "",
  dataRecebimentoLead = "",
  horaRecebimentoLead = "",
  dataAgendamento = "",
  horaAgendamento = "",
  diaSemanaAgendamento = "",
  atendimentos = [],
  leadIdRenovacao = 0,
}) => {
  let history = useHistory();
  return (
    <Fragment>
      <div
        data-toggle={bloquearModal ? null : "modal"}
        data-target={
          bloquearModal ? null : `#modalAgendamento${filaAtendimentoId}`
        }
      >
        {children}
      </div>

      <div
        className="modal fade ComponenteAgendamento_ContainerScroll"
        id={`modalAgendamento${filaAtendimentoId}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalAgendamentoTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalLeadLabel">
                {tipoAgendamentoDescricao}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body componenteAgendamento_modalBody">
              <div className="row align-items-center mb-3">
              <div className="col-12 col-md-2">
                  {leadIdRenovacao && leadIdRenovacao > 0 ? (
                    <span
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Renovação"
                      className={`badge-default rounded-pill pl-1 pt-1 pb-1 pr-1 mr-1`}
                    >
                      Renovação
                    </span>
                  ) : (
                    <span
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Novo Lead"
                      className={`badge-info rounded-pill pl-1 pt-1 pb-1 pr-1 mr-1`}
                    >
                      Novo Lead
                    </span>
                  )}
                </div>
                <div className="col-12 col-md-4">{leadNome}</div>
                <div className="col-12 col-md-6 text-right">
                  <AlteraAgendamento
                    agendamentoId={agendamentoId}
                    tipoAgendamentoId={tipoAgendamentoId}
                    dataAgendamento={dataAgendamento}
                    horaAgendamento={horaAgendamento}
                    diaSemanaAgendamento={diaSemanaAgendamento}
                    dataToggle="modal"
                    dataTarget={`#modalAgendamento${filaAtendimentoId}`}
                    dataDismiss="modal"
                    refreshEventHandler={refreshEventHandler}
                    tiposAgendamento={tiposAgendamento}
                  />
                </div>
              </div>
              <div className="row align-items-center mb-3">
                <div className="col-12 col-md-4 font-weight-bold">
                  {cidadeLead}
                </div>
                <div className="col-12 col-md-2 font-weight-bold">
                  Nrs. contato:{" "}
                  <span className="font-weight-normal"> {totalContatos}</span>
                </div>
                <div className="col-12 col-md-6 font-weight-bold text-right">
                  Data de Recebimento:{"  "}
                  <span className="font-weight-normal">
                    {dataRecebimentoLead} - {horaRecebimentoLead}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p>
                    {atendimentos.length > 0
                      ? atendimentos[0].atendimentoObservacao
                      : ""}
                  </p>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12 text-center">
                  <button
                    type="button"
                    className="btn btn-success"
                    data-dismiss="modal"
                    onClick={() => {
                      history.push(
                        `/Atendimento/Atendimento/${filaAtendimentoId}`
                      );
                    }}
                  >
                    Ir para atendimento
                  </button>
                </div>
              </div>
              <div className="row">
                <TimeLine
                  show="true"
                  atendimentos={
                    atendimentos.length > 0
                      ? atendimentos.slice(1, atendimentos.length)
                      : atendimentos
                  }
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Agendamento;
