import React, { Fragment, useContext } from "react";
import "./ImportarLead.css";
import $ from "jquery";
import { ImportarLeadContext } from "../ImportarLeadContext";
import ResultadoImportacao from "../../../../../Components/Lead/ResultadoImportacao/ResultadoImportacao";
import TabelaResultado from "../../../../../Components/Lead/TabelaResultado/TabelaResultado";

const ImportarLead = () => {
  $(".custom-file-input").on("change", function () {
    var fileName = $(this).val().split("\\").pop();
    $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
  });

  const { values, setValues, importar, downloadTemplate } = useContext(
    ImportarLeadContext
  );

  function onChangeInput(event) {
    setValues({
      ...values,
      arquivo: event.target.files[0],
    });
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <div className="default-title">Importação</div>
          <div className="card shadow-sm mb-4">
            <div className="row align-items-end">
              <div className="col-12 col-md-7">
                <label>Escolher arquivo</label>
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="customFile"
                    name="arquivo"
                    accept=".xlsx , .xls "
                    onChange={(event) => onChangeInput(event)}
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="customFile"
                  ></label>
                </div>
              </div>
              <div className="col-auto">
                {values.permissoesPagina != null &&
                values.permissoesPagina.incluir ? (
                  <button
                    type="button"
                    onClick={importar}
                    className="btn btn-success"
                  >
                    Importar Arquivo
                  </button>
                ) : null}
              </div>
              <div className="col-auto">
                <button
                  type="button"
                  onClick={downloadTemplate}
                  className="btn btn-info"
                >
                  Download de Layout
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="default-title">Resultado</div>
        </div>
        <div className="col-12 col-md-9">
          <div className="card shadow-sm mb-4">
            <div className="row">
              <TabelaResultado
                resultadosImportacao={values.resultadosImportacao}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3">
          <div className="card shadow-sm mb-4">
            <div className="row">
              <ResultadoImportacao
                tipoImportacao="Leads"
                totalImportados={values.totalLeadsImportados}
                totalSucesso={values.totalLeadsComSucesso}
                totalErro={values.totalLeadsComErro}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ImportarLead;
