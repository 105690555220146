import React, { Fragment } from "react";
import Avatar from "../../../Components/Avatar/Avatar";
import "./IndicadoPor.css";

const IndicadoPor = ({ imagem = null, nome = "" }) => {
    return (
        <Fragment>
            <div className="row" style={{ maxWidth: 250 }}>
                {imagem ?
                    <div className="col-md-3">
                        <Avatar src={imagem} className="navBar" />
                    </div> : null
                }
                {nome ?
                    <div className="col-md-9 pt-1">
                        <div className="h8">
                            Indicado por:
                        </div>
                        <p className="h5">
                            {nome}
                        </p>
                    </div> : null}
            </div>
        </Fragment>
    );

};

export default IndicadoPor;
