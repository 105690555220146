/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext } from "react";
import AppContext from "../../../Store/AppContext";
import { Listar as ListarTipoAgendamento } from "../../../Services/TipoAgendamento";
import {
  ListarAgendamento,
  PodeRedirecionarAgendamento,
} from "../../../Services/Agendamento";
import { Listar as ListarProduto } from "../../../Services/Produto";
import { Listar as ListarEquipe } from "../../../Services/Equipe";
import { ListarConsultoresOnline } from "../../../Services/JornadaTrabalho";
import { RedirecionarFilaAtendimento } from "../../../Services/Atendimento";
import { validarPermissoesPagina } from "../../../Utils/validarPermissoesPagina";
export const AgendaContext = createContext();

const tituloTela = "Agenda";

const defaultData = {
  usuarioId: "0",
  filaAtendimentos: [],
};

const searchData = {
  dataAgendamentoDe: "",
  dataAgendamentoAte: "",
  produtoId: "0",
  equipeId: "0",
  usuarioId: "0",
};

function initialState(usuarioId) {
  return {
    podeRedirecionar: true,
    habilitarSelecao: false,
    data: defaultData,
    searchData: { ...searchData, usuarioId: usuarioId },
    resultSearch: [],
    tiposAgendamento: [],
    produtos: [],
    consultores: [],
    equipes: [],
  };
}

const AgendaProvider = ({ children }) => {
  const {
    showLoading,
    hideLoading,
    showMessage,
    setTituloTela,
    session,
  } = useContext(AppContext);
  const [agendaValues, setAgendaValues] = useState(
    initialState(session.usuarioId)
  );

  async function loadDependences() {
    let tiposAgendamento = [];
    let produtos = [];
    let consultores = [];
    let equipes = [];

    const [
      permissoesPaginaAux,
      responseListarTipoAgendamento,
      responseListarProduto,
      responseListarConsultoresOnline,
      responseListarEquipe
    ] = await Promise.all([
      validarPermissoesPagina(),
      ListarTipoAgendamento(),
      ListarProduto(),
      ListarConsultoresOnline(),
      ListarEquipe()
    ]);

    if (
      !responseListarTipoAgendamento.success ||
      !responseListarProduto.success ||
      !responseListarConsultoresOnline.success ||
      !responseListarEquipe.success
    ) {
      showMessage("Erro", "Erro Inesperado ao carregar dados basicos.");
    } else {
      tiposAgendamento = await responseListarTipoAgendamento.tiposAgendamento.filter(
        (x) => x.tipoAgendamentoAtivo === "A"
      );

      produtos = await responseListarProduto.produtos.filter((item) => item.produtoAtivo === 'A').map((produto) => {
        return {
          value: produto.produtoId,
          label: produto.produtoNome,
        };
      });

      consultores = await responseListarConsultoresOnline.consultoresOnline.map(
        (consultorOnline) => {
          return {
            value: consultorOnline.usuarioId,
            label: `${consultorOnline.usuarioNome} - ${consultorOnline.totalLeadsEmAtendimento} leads`,
          };
        }
      );

      equipes = await responseListarEquipe.equipes.map((equipe) => {
        return {
          produtoId: equipe.produtoId,
          value: equipe.equipeId,
          label: equipe.equipeNome,
        };
      });
    }

    return { permissoesPagina: permissoesPaginaAux, tiposAgendamento, produtos, consultores, equipes };
  }

  const resetScreen = () => {
    setTituloTela(tituloTela);
    searchHandler();
  };

  const searchHandler = async () => {
    showLoading();
    const dependencias = await loadDependences();
    const responseListarAgendamento = await ListarAgendamento(
      agendaValues.searchData
    );


    if (!responseListarAgendamento.success) {
      showMessage("Erro", "Erro Inesperado durante a pesquisa.");
      hideLoading();
      return;
    }
    const responsePodeRedirecionarAgendamento = await PodeRedirecionarAgendamento();

    if (!responsePodeRedirecionarAgendamento.success) {
      showMessage("Erro", "Erro Inesperado durante a pesquisa.");
      hideLoading();
      return;
    }

    let resultSearchData = await responseListarAgendamento.agendamentos;

    let podeRedirecionar = await responsePodeRedirecionarAgendamento.podeRedirecionar;
    setAgendaValues({
      ...agendaValues,
      podeRedirecionar: podeRedirecionar,
      resultSearch: resultSearchData,
      permissoesPagina: await dependencias.permissoesPagina,
      tiposAgendamento: await dependencias.tiposAgendamento,
      produtos: await dependencias.produtos,
      consultores: await dependencias.consultores,
      equipes: await dependencias.equipes,
      data: defaultData,
      habilitarSelecao: false,
    });

    if (resultSearchData.length === 0) {
      showMessage("Alerta", "Nenhum registro encontrado.");
    }
    hideLoading();
  };

  const selectHandler = (adicionar, filaAtendimentoId) => {
    if (adicionar) {
      setAgendaValues({
        ...agendaValues,
        data: {
          ...agendaValues.data,
          filaAtendimentos: [
            ...agendaValues.data.filaAtendimentos,
            filaAtendimentoId,
          ],
        },
      });
    } else {
      setAgendaValues({
        ...agendaValues,
        data: {
          ...agendaValues.data,
          filaAtendimentos: agendaValues.data.filaAtendimentos.filter(
            (x) => x !== filaAtendimentoId
          ),
        },
      });
    }
  };

  const redirecionarAgenda = async () => {
    showLoading();
    const responseRedirecionarFilaAtendimento = await RedirecionarFilaAtendimento(
      agendaValues.data
    );
    if (!responseRedirecionarFilaAtendimento.success) {
      hideLoading();

      showMessage(
        "Erro",
        `Erro ao atualizar o registro: ${responseRedirecionarFilaAtendimento.message}`
      );
      if (responseRedirecionarFilaAtendimento.errors) {
        responseRedirecionarFilaAtendimento.errors.map((erro) =>
          showMessage("Erro", erro.errorMessage)
        );

      }


      return;
    }
    resetScreen();
    hideLoading();
    showMessage("Sucesso", "Agendamento Redirecionado.");
  };

  return (
    <AgendaContext.Provider
      value={{
        agendaValues,
        setAgendaValues,
        resetScreen,
        searchHandler,
        selectHandler,
        redirecionarAgenda,
      }}
    >
      {children}
    </AgendaContext.Provider>
  );
};

export default AgendaProvider;
