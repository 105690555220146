import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "TemplateEmail";
export const Listar = (templateEmail) =>
  RequestWebApi.get(`/${controller}/Listar`, {
    params: { ...templateEmail },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const templatesEmail = resultData;
      return { success, templatesEmail };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { templatesEmail: [] };
    });

export const Detalhes = (templateEmailId) =>
  RequestWebApi.get(`/${controller}/Detalhes`, {
    params: { templateEmailId: templateEmailId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const templateEmail = resultData;
      return { success, templateEmail };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, templateEmail: {} };
    });

export const Incluir = (templateEmail) =>
  RequestWebApi.post(`/${controller}/Incluir`, templateEmail)
    .then(function (response) {
      const { resultData, success } = response.data;
      const novoGrupoUsuario = resultData;
      return { success, novoGrupoUsuario };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Atualizar = (templateEmail) =>
  RequestWebApi.put(`/${controller}/Atualizar`, templateEmail)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (templateEmailId) =>
  RequestWebApi.delete(`/${controller}/Remover`, {
    params: { templateEmailId: templateEmailId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });
