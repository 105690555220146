/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext } from 'react'
import AppContext from '../../../../Store/AppContext'
import {
  Listar as ListarProduto,
  Detalhes as DetalhesProduto,
  Remover as RemoverProduto,
  Incluir as IncluirProduto,
  Atualizar as AtualizarProduto,
} from '../../../../Services/Produto'
import { useHistory } from 'react-router-dom'
import { validarPermissoesPagina } from '../../../../Utils/validarPermissoesPagina'
export const ProdutoContext = createContext()

const tituloTela = 'Produtos'

const defaultData = {
  produtoId: 0,
  produtoCod: '',
  produtoNome: '',
  produtoFormAuxLead: '',
  produtoFormAuxVenda: '',
  produtoAtivo: 'A',
}

const searchData = {
  produtoCod: '',
  produtoNome: '',
}

const columnsDefinition = [
  {
    text: 'Id',
    dataField: 'produtoId',
    hidden: true,
  },
  {
    text: 'Código',
    dataField: 'produtoCod',
    headerStyle: { width: '100px' },
    sort: true,
  },
  {
    text: 'Nome do Produto',
    dataField: 'produtoNome',
    headerStyle: { width: '450px' },
    sort: true,
  },
  {
    text: "Status",
    dataField: "produtoAtivo",
    headerStyle: { width: "80px" },
    sort: true,
    formatter: (cell, row) => {
      return cell === "A" ? (
        <span className="badge badge-success">Ativo</span>
      ) : (
        <span className="badge badge-danger">Inátivo</span>
      );
    },
  },
]

function initialState() {
  return {
    searchData: searchData,
    resultSearch: [],
    columnsDefinition: columnsDefinition,
    data: defaultData,
    formDisabled: true,
  }
}

const ProdutoProvider = ({ children }) => {
  const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(
    AppContext,
  )
  const [values, setValues] = useState(initialState())
  let history = useHistory()

  async function loadDependences() {
    const permissoesPagina = validarPermissoesPagina()
    return { permissoesPagina }
  }

  const resetScreen = () => {
    showLoading()
    async function carregarDependencias() {
      const dependencias = await loadDependences()
      setTituloTela(tituloTela)
      setValues({
        ...initialState(),
        permissoesPagina: await dependencias.permissoesPagina,
      })
      hideLoading()
    }
    carregarDependencias()
  }

  const searchHandler = async () => {
    showLoading()
    const responseListarProduto = await ListarProduto(values.searchData)

    if (!responseListarProduto.success) {
      showMessage('Erro', 'Erro Inesperado durante a pesquisa.')
      hideLoading()
      return
    }

    const resultSearchData = await responseListarProduto.produtos

    resultSearchData.map((data) => (data.Id = data.produtoId))

    setValues({
      ...values,
      resultSearch: resultSearchData,
    })

    if (resultSearchData.length === 0) {
      showMessage('Alerta', 'Nenhum registro encontrado.')
    }
    hideLoading()
  }

  const removeHandler = async (id) => {
    showLoading()

    const responseRemover = await RemoverProduto(id)

    if (!responseRemover.success) {
      hideLoading()
      showMessage('Erro', `Erro durante a exclusão: ${responseRemover.message}`)
      return
    }
    searchHandler()
    hideLoading()
    showMessage('Sucesso', 'Registro excluido.')
  }

  const getDetailHandler = async (id) => {
    showLoading()
    const dependencias = await loadDependences()

    if (id > 0) {
      const responseDetalhes = await DetalhesProduto(id)

      const { success, produto } = await responseDetalhes

      if (!success) {
        showMessage('Erro', 'Erro ao buscar os detalhes do registro.')
        setValues({
          ...values,
          data: defaultData,
          permissoesPagina: await dependencias.permissoesPagina,
        })
      }

      setValues({
        ...values,
        data: produto,
        permissoesPagina: await dependencias.permissoesPagina,
      })
    } else {
      setValues({
        ...values,
        data: {
          ...defaultData,
          produtoId: 0,
        },
        permissoesPagina: await dependencias.permissoesPagina,
        formDisabled: false,
      })
    }
    hideLoading()
  }

  const createData = async () => {
    showLoading()
    const responseIncluir = await IncluirProduto(values.data)
    if (!responseIncluir.success) {
      hideLoading()
      showMessage(
        'Erro',
        `Erro ao criar o registro: ${responseIncluir.message}`,
      )
      responseIncluir.errors.map((erro) =>
        showMessage('Erro', erro.errorMessage),
      )
      return
    }

    resetScreen()
    hideLoading()
    showMessage('Sucesso', 'Registro criado.')
    history.goBack()
  }

  const editHandler = () => {
    setValues({
      ...values,
      formDisabled: false,
    })
  }

  const updateData = async () => {
    showLoading()
    const responseAtualizar = await AtualizarProduto(values.data)
    if (!responseAtualizar.success) {
      hideLoading()
      showMessage(
        'Erro',
        `Erro ao atualizar o registro: ${responseAtualizar.message}`,
      )
      return
    }
    resetScreen()
    hideLoading()
    showMessage('Sucesso', 'Registro atualizado.')
    history.goBack()
  }

  const saveHandler = async () => {
    if (values.data.produtoId === 0) {
      await createData()
    } else {
      await updateData()
    }
  }

  const cancelHandler = () => {
    setValues({
      ...values,
      data: defaultData,
      formDisabled: true,
    })
  }

  return (
    <ProdutoContext.Provider
      value={{
        values,
        setValues,
        resetScreen,
        searchHandler,
        cancelHandler,
        removeHandler,
        saveHandler,
        getDetailHandler,
        editHandler,
      }}
    >
      {children}
    </ProdutoContext.Provider>
  )
}

export default ProdutoProvider
