import React, { useState } from 'react';
import $ from 'jquery';
import { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';

function initialState() {
	return {
		filaAtendimentoId: '',
		usuarioId: '',
		produtoId: ''
	};
}

const AlterarFilaModal = ({
	id,
	usuarioId,
	produtoId,
	produtos = [],
	consultores = [],
	modificarFilaHandler = () => {}
}) => {
	const [ valuesEventoAlterarFila, setEventoAlterarFila ] = useState({
		...initialState(),
		filaAtendimentoId: id,
		usuarioId: usuarioId,
		produtoId: produtoId
	});

	function onChangeInput(event) {
		const { name, value } = event.target;
		setEventoAlterarFila({
			...valuesEventoAlterarFila,
			[name]: value
		});
	}

	const exibeConsultor = consultores.length > 1;

	const formularioComErro =
		!valuesEventoAlterarFila.usuarioId ||
		!valuesEventoAlterarFila.usuarioId === '0' ||
		!valuesEventoAlterarFila.produtoId ||
		!valuesEventoAlterarFila.produtoId === '0';

	return (
		<Fragment>
			<button
				type="button"
				className="btn btn-primary btn-sm "
				onClick={() => {
					$(`#modalConfirmacao${id}`).modal({
						backdrop: 'static',
						keyboard: false,
						show: true
					});
				}}
			>
				<FontAwesomeIcon icon={faExchangeAlt} />
			</button>

			<div
				className="modal fade"
				id={`modalConfirmacao${id}`}
				tabIndex="-1"
				role="dialog"
				aria-labelledby="modalConfirmacaoTitle"
				aria-hidden="true"
			>
				<div
					className={`modal-dialog modal-dialog-centered  ${exibeConsultor ? 'modal-md' : 'modal-sm'}`}
					role="document"
				>
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Alterar Fila</h5>
						</div>
						<div className="modal-body">
							<div className="row align-items-center">
								{exibeConsultor ? (
									<div className={`${exibeConsultor ? 'col-6' : 'col-12'} form-group`}>
										<label>Consultor</label>

										<select
											className="form-control"
											name="usuarioId"
											value={valuesEventoAlterarFila.usuarioId}
											onChange={onChangeInput}
										>
											{consultores ? (
												consultores.map((consultor) => (
													<option key={consultor.value} value={consultor.value}>
														{consultor.label}
													</option>
												))
											) : null}
										</select>
									</div>
								) : null}
								<div className={`${exibeConsultor ? 'col-6' : 'col-12'} form-group`}>
									<label>Produto</label>
									<select
										className="form-control"
										name="produtoId"
										value={valuesEventoAlterarFila.produtoId}
										onChange={onChangeInput}
									>
										<option className="disabled" value="" />
										{produtos.map((produto) => (
											<option key={produto.produtoId} value={produto.produtoId}>
												{produto.produtoNome}
											</option>
										))}
									</select>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-dismiss="modal">
								Cancelar
							</button>
							<button
								disabled={formularioComErro}
								type="button"
								onClick={() => modificarFilaHandler(valuesEventoAlterarFila)}
								className="btn btn-default"
								data-dismiss="modal"
							>
								Confirmar
							</button>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default AlterarFilaModal;
