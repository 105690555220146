import React from 'react';
import './FormularioErrosEAlertas.css';

const FormularioErrosEAlertas = ({ cotacaoSelecionada }) => {
	return (
		<div className="componenteModalCotacaoAutoRastreador_FormularioErrosEAlertasContainer">
			{cotacaoSelecionada.notificacoes.map((x,index) => {
				return <div key={index} className="alert alert-warning" role="alert">{x}</div>;
			})}
		</div>
	);
};

export default FormularioErrosEAlertas;
