import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRoute from "../../../../Routes/PrivateRoutes";
import GrupoUsuarioProvider from "./GrupoUsuarioContext";
import PesquisarGrupoUsuario from "./Events/PesquisarGrupoUsuario";
import FormularioGrupoUsuario from "./Events/FormularioGrupoUsuario";

const Index = () => {
  let match = useRouteMatch();
  return (
    <GrupoUsuarioProvider >
      <div className="container-fluid pl-3 pt-3 pb-3">
        <Switch>
          <PrivateRoute
            exact
            path={`${match.url}`}
            component={PesquisarGrupoUsuario}
          />
          <PrivateRoute
            path={`${match.url}/:id`}
            component={FormularioGrupoUsuario}
          />
        </Switch>
      </div>
    </GrupoUsuarioProvider>
  );
};

export default Index;
