import React from "react";
import { Fragment } from "react";
import PermissoesSubFolder from "./PermissoesSubFolder/PermissoesSubFolder";

const Permissoes = ({ permissoes = [], context}) => {
  return (
    <Fragment>
      {permissoes.length > 0 ? (
        <ul className="cd-accordion cd-accordion--animated">
          {permissoes.map((menu) => (
            <li
              key={menu.id}
              className="cd-accordion__item cd-accordion__item--has-children"
            >
              <input
                className="cd-accordion__input"
                type="checkbox"
                name={`modulo-${menu.id}`}
                id={`modulo-${menu.id}`}
              />
              <label
                className="cd-accordion__label cd-accordion__label--icon-folder"
                htmlFor={`modulo-${menu.id}`}
              >
                <span>{menu.nome}</span>
              </label>
              <ul className="cd-accordion__sub cd-accordion__sub--l1">
                <PermissoesSubFolder
                  id={menu.id}
                  subMenus={menu.subMenus}
                  context={context}
                />
              </ul>
            </li>
          ))}
        </ul>
      ) : null}
    </Fragment>
  );
};

export default Permissoes;
