import React, { useContext, useState } from 'react';
import $ from 'jquery';
import './RedefinicaoSenha.css';
import { Fragment } from 'react';
import { RedefinirSenha } from '../../Services/Usuario';
import AppContext from '../../Store/AppContext';
let usuarioIdResetSenha = 0;
let idComponente = '';

export const showRedefinicaoSenha = (target, usuarioId) => {
	usuarioIdResetSenha = usuarioId;
	$(`#modalRedefinicaoSenha${target}`).modal({
		backdrop: 'static',
		keyboard: false,
		show: true
	});
};

export const hideRedefinicaoSenha = (target) => {
	$(`#modalRedefinicaoSenha${target}`).modal('hide');
};

const initialState = {
	usuarioId: () => {
		return usuarioIdResetSenha;
	},
	senhaAtual: '',
	novaSenha: '',
	confirmarNovaSenha: ''
};
const RedefinicaoSenha = ({ id, children }) => {
	idComponente = id;
	const [ valuesRedefinicaoSenha, setValuesRedefinicaoSenha ] = useState(initialState);
	const { showLoading, hideLoading, showMessage, token } = useContext(AppContext);

	const formularioComErro =
		!valuesRedefinicaoSenha.senhaAtual ||
		!valuesRedefinicaoSenha.novaSenha ||
		!valuesRedefinicaoSenha.confirmarNovaSenha ||
		valuesRedefinicaoSenha.novaSenha !== valuesRedefinicaoSenha.confirmarNovaSenha;

	function onChangeInput(event) {
		const { name, value } = event.target;
		setValuesRedefinicaoSenha({
			...valuesRedefinicaoSenha,
			[name]: value
		});
	}
	const cancelHandler = async () => {
		setValuesRedefinicaoSenha(initialState);
	};
	const redefinirSenhaHandler = async () => {
		showLoading();
		const responseRedefinirSenha = await RedefinirSenha(
			usuarioIdResetSenha,
			valuesRedefinicaoSenha.senhaAtual,
			valuesRedefinicaoSenha.novaSenha,
			token.token
		);

		if (!responseRedefinirSenha.success) {
			hideLoading();
			showMessage(
				'Erro',
				`Erro ao redefinir senha: ${responseRedefinirSenha.message
					? responseRedefinirSenha.message
					: 'Indefinido'}`
			);
			if (responseRedefinirSenha.errors) {
				responseRedefinirSenha.errors.map((erro) => showMessage('Erro', erro.errorMessage));
			}
			setValuesRedefinicaoSenha(initialState);
			return;
		}
		setValuesRedefinicaoSenha(initialState);
		hideLoading();
		showMessage('Sucesso', 'Senha redefinida com sucesso.');
	};

	return (
		<Fragment>
			<div data-toggle="modal" data-target={`#modalRedefinicaoSenha${idComponente}`} data-dismiss="modal">
				{children}
			</div>

			<div
				className="modal fade"
				id={`modalRedefinicaoSenha${idComponente}`}
				tabIndex="-1"
				role="dialog"
				aria-labelledby={`modalRedefinicaoSenha${idComponente}Label`}
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-sm">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id={`modalRedefinicaoSenha${idComponente}Label`}>
								Redefinir Senha
							</h5>
						</div>

						<div className="modal-body modalRedefinicaoSenha_modalBody">
							<Fragment>
								<div className="row align-items-center">
									<div className="col-12  text-right">
										<label>Senha Atual</label>
										<input
											type="password"
											maxLength="50"
											className="form-control"
											name="senhaAtual"
											value={valuesRedefinicaoSenha.senhaAtual}
											onChange={onChangeInput}
										/>
									</div>
									<div className="col-12  text-right">
										<label>Nova Senha</label>
										<input
											type="password"
											maxLength="50"
											className="form-control"
											name="novaSenha"
											value={valuesRedefinicaoSenha.novaSenha}
											onChange={onChangeInput}
										/>
									</div>
									<div className="col-12  text-right">
										<label>Confirmar Senha</label>
										<input
											type="password"
											maxLength="50"
											className="form-control"
											name="confirmarNovaSenha"
											value={valuesRedefinicaoSenha.confirmarNovaSenha}
											onChange={onChangeInput}
										/>
									</div>
								</div>
							</Fragment>
						</div>

						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-secondary"
								data-dismiss="modal"
								onClick={cancelHandler}
							>
								Cancelar
							</button>
							<button
								disabled={formularioComErro}
								type="button"
								className="btn btn-default"
								data-dismiss="modal"
								onClick={redefinirSenhaHandler}
							>
								Redefinir
							</button>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default RedefinicaoSenha;
