import { faSquareFull } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const ResultadoImportacao = ({
  totalImportados,
  totalSucesso,
  totalErro,
}) => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="font-weight-bold mb-3">
          <i className="text-orange">
            {" "}
            <FontAwesomeIcon icon={faSquareFull} />{" "}
          </i>{" "}
          Total:
          <span className="font-weight-normal">{" " + totalImportados}</span>
        </div>
        <div className="font-weight-bold mb-3">
          <i className="text-success">
            <FontAwesomeIcon icon={faSquareFull} />
          </i>{" "}
          Total com Sucesso:{" "}
          <span className="font-weight-normal"> {" " + totalSucesso}</span>
        </div>
        <div className="font-weight-bold mb-3">
          <i className="text-danger">
            <FontAwesomeIcon icon={faSquareFull} />
          </i>{" "}
          Total com Erro:{" "}
          <span className="font-weight-normal"> {" " + totalErro}</span>
        </div>
      </div>
    </div>
  );
};

export default ResultadoImportacao;
