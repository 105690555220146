import React, { useContext, Fragment, useEffect } from "react";
import { TarefasExecutarContext } from "../TarefasExecutarContext";
import Pesquisar from "../../../../../Components/Cadastro/Pesquisar/Pesquisar";
import TabelaResultados from "../../../../../Components/Cadastro/TabelaResultados/TabelaResultados";
import GroupBox from "../../../../../Components/GroupBox/GroupBox";
const TesquisartarefasExecutar = () => {
  const { values, setValues, resetScreen } = useContext(TarefasExecutarContext);

  useEffect(() => {
    resetScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      searchData: {
        ...values.searchData,
        [name]: value,
      },
    });
  }

  return (
    <Fragment>
      <Pesquisar context={TarefasExecutarContext}>
        <div className="col-12 col-md-6">
          <label>Nome</label>
          <input
            className="form-control"
            type="text"
            name="nomeCliente"
            value={values.searchData.nomeCliente}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-3">
          <label>Cpf</label>
          <input
            className="form-control"
            type="text"
            name="cpfCliente"
            value={values.searchData.cpfCliente}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-3">
          <label>Placa</label>
          <input
            className="form-control"
            type="text"
            name="placaCliente"
            value={values.searchData.placaCliente}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-4">
          <label>Status Controle</label>
          <input
            className="form-control"
            type="text"
            name="tarefasExecutarStatusControle"
            value={values.searchData.tarefasExecutarStatusControle}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-4">
          <label>
            Consultor Solicitante
          </label>
          <select
            className="form-control"
            name="usuarioIdSolicitante"
            value={values.searchData.usuarioIdSolicitante}
            onChange={onChangeInput}
          >
            <option className="disabled" value="0" />
            {values.usuarios.map((usuario) => (
              <option key={usuario.usuarioId} value={usuario.usuarioId}>
                {usuario.usuarioNome}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-4">
          <label>Tipo</label>
          <select
            className="form-control"
            name="tarefasExecutarTipo"
            value={values.searchData.tarefasExecutarTipo}
            onChange={onChangeInput}
          >
            <option className="disabled" value="" />
            <option className="disabled" value="P" >Pendência</option>
            <option className="disabled" value="E" >Endosso</option>
            <option className="disabled" value="S" >Sinistro</option>
            <option className="disabled" value="C" >Cobrança</option>
          </select>
        </div>
        <GroupBox titulo="Recebido" className="col-12 col-md-4 mt-3">
          <div className="col-12 col-md-6">
            <label>De:</label>
            <input
              className="form-control"
              type="date"
              name="tarefasExecutarDataHoraRecebidoDe"
              value={values.searchData.tarefasExecutarDataHoraRecebidoDe}
              onChange={onChangeInput}
            />
          </div>

          <div className="col-12 col-md-6">
            <label>Até:</label>
            <input
              className="form-control"
              type="date"
              name="tarefasExecutarDataHoraRecebidoAte"
              value={values.searchData.tarefasExecutarDataHoraRecebidoAte}
              onChange={onChangeInput}
            />
          </div>
        </GroupBox>
      </Pesquisar>
      <TabelaResultados context={TarefasExecutarContext} />
    </Fragment>
  );
};

export default TesquisartarefasExecutar;
