import React, { Fragment, useContext, useEffect, useState } from "react";
import "./FormularioCampanhaJornada.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSms,
  faEnvelope,
  faPhoneAlt,
  faClock,
  faQuestionCircle,
  faStopCircle,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";

import ReactFlow, {
  ReactFlowProvider,
  removeElements,
  addEdge,
  Background,
  Controls,
} from "react-flow-renderer";
import { CampanhaContext } from "../CampanhaContext";
import { useParams } from "react-router-dom";
import FormularioComplementar from "../../../../../Components/Cadastro/FormularioComplementar/FormularioComplementar";
import SmsModal, {
  showSmsModal,
} from "../../../../../Components/Campanha/JornadaCampanha/Modal/Sms/SmsModal";
import EmailModal, {
  showEmailModal,
} from "../../../../../Components/Campanha/JornadaCampanha/Modal/Email/EmailModal";
import AguardarModal, {
  showAguardarModal,
} from "../../../../../Components/Campanha/JornadaCampanha/Modal/Aguardar/AguardarModal";
import CondicaoModal, {
  showCondicaoModal,
} from "../../../../../Components/Campanha/JornadaCampanha/Modal/Condicao/CondicaoModal";
import TelefonarModal, {
  showTelefonarModal,
} from "../../../../../Components/Campanha/JornadaCampanha/Modal/Telefonar/TelefonarModal";
import Condicao from "../../../../../Components/Campanha/JornadaCampanha/Elementos/Condicao/Condicao";
import Padrao from "../../../../../Components/Campanha/JornadaCampanha/Elementos/Padrao/Padrao";
import ListaObjetos from "../../../../../Components/Campanha/JornadaCampanha/ListaObjetos/ListaObjetos";
import { capitalizeFirstLetter } from "../../../../../Utils/globalFunctions";

import { useStoreState } from "react-flow-renderer";

const nodeTypes = {
  condicao: Condicao,
  padrao: Padrao,
};

const getDefaultEvent = (template) => {
  let event = {
    id: template.id,
    type: template.tipoObjeto,
    selectable: true,
    data: { ...template },
    position: { x: 0, y: 0 },
  };
  return event;
};

const templateElementList = [
  {
    type: "aguardar",
    tipoObjeto: "padrao",
    descricao: "AGUARDAR",
    icone: faClock,
    corIcone: "#607d8b",
  },
  {
    type: "condicao",
    tipoObjeto: "condicao",
    descricao: "CONDIÇÃO",
    icone: faQuestionCircle,
    corIcone: "#ffc107",
  },
  {
    type: "email",
    tipoObjeto: "padrao",
    descricao: "EMAIL",
    icone: faEnvelope,
    corIcone: "#ff5722",
  },
  {
    type: "sms",
    tipoObjeto: "padrao",
    descricao: "SMS",
    icone: faSms,
    corIcone: "#03a9f4",
  },
  {
    type: "telefonar",
    tipoObjeto: "padrao",
    descricao: "TELEFONAR",
    icone: faPhoneAlt,
    corIcone: "#3f51b5",
  },
];

let initialElements = [
  {
    id: "inicio",
    type: "input",
    selectable: false,
    sourcePosition: "bottom",
    data: {
      label: (
        <div>
          <FontAwesomeIcon
            style={{ color: "#4caf50" }}
            className="fa-10x"
            icon={faPlay}
          />
          INICIO
        </div>
      ),
    },
    position: { x: 375, y: 0 },
  },

  {
    id: "fim",
    type: "output",
    selectable: false,
    targetPosition: "top",
    data: {
      label: (
        <div>
          <FontAwesomeIcon
            style={{ color: "#f44336" }}
            className="fa-10x"
            icon={faStopCircle}
          />
          FIM
        </div>
      ),
    },
    position: { x: 375, y: 500 },
  },
];

let estruturaJornada = [];

let elementsChanged = [];
const FlowChartChangesRead = () => {
  elementsChanged = useStoreState((store) => store.elements);
  return null;
};

const FormularioCampanhaJornada = () => {
  const { values, setValues, getDetailHandler } = useContext(CampanhaContext);
  const [elements, setElements] = useState(initialElements);
  const [eventoCorrente, setEventoCorrente] = useState(null);

  let { id } = useParams();

  const loadFlowChart = (estruturaJornadaData) => {

    estruturaJornada = estruturaJornadaData;

    let localElements = elements;
    for (var i = 0; i < estruturaJornada.length; i++) {
      const element = estruturaJornada[i];
      if (
        element.type.toLowerCase() === "inicio" ||
        element.type.toLowerCase() === "fim"
      ) {
        //Inicio e Fim
        var standardElement = localElements.find(
          (standardElement) => standardElement.id === element.id
        );
        localElements = localElements.filter(
          (standardElement) => standardElement.id !== element.id
        );

        standardElement = {
          ...standardElement,
          position: {
            x: parseInt(element.positionX),
            y: parseInt(element.positionY),
          },
        };

        localElements.push(standardElement);
      } else {
        //Eventos

        let elementTemplate = templateElementList.find(
          (template) => template.type === element.type
        );

        let defaultElement = getDefaultEvent({
          ...elementTemplate,
          id: element.id,
        });
        defaultElement = {
          ...defaultElement,
          id: element.id,
          position: {
            x: parseInt(element.positionX),
            y: parseInt(element.positionY),
          },
        };

        localElements.push(defaultElement);
      }

      //Conexoes
      const { target, targetTrue, targetFalse } = element;
      if (element.target || (targetTrue && targetFalse)) {
        let label = null;
        let conexaoProps = {
          style: { stroke: "#fe6633", strokeWidth: 5 },
          labelStyle: { fontSize: 18, fontWeight: "bold" },
          label: label,
          type: "smoothstep",
        };

        let conexao = {
          id: `conexao_${element.id}_${target}`,
          source: element.id,
          target,
          ...conexaoProps,
        };

        if (targetTrue && targetFalse) {
          let conexaoTrue = {
            ...conexao,
            id: `conexao_${element.id}_${targetTrue}_true`,
            sourceHandle: "true",
            label: "SIM",
            target: targetTrue,
          };

          localElements.push(conexaoTrue);
          let conexaoFalse = {
            ...conexao,
            id: `conexao_${element.id}_${targetFalse}_false`,
            sourceHandle: "false",
            label: "NÃO",
            target: targetFalse,
          };
          localElements.push(conexaoFalse);
        } else {
          localElements.push(conexao);
        }
      }
    }
    setElements(localElements);
  };

  useEffect(() => {
    getDetailHandler(id, loadFlowChart);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFlowChartElements = () => {
    let estruturaJornadaNova = [];

    for (var i = 0; i < elementsChanged.length; i++) {
      const element = elementsChanged[i];

      const {
        id,
        type,
        source,
        sourceHandle,
        target,
        position,
        __rf,
      } = element;

      let definedPosition = null;
      if (__rf) {
        definedPosition = __rf.position;
      } else {
        definedPosition = position;
      }

      let positionX = null,
        positionY = null;
      if (definedPosition) {
        positionX = definedPosition.x;
        positionY = definedPosition.y;
      }

      let itemFlowChart = estruturaJornada.find((x) => x.id === id);

      estruturaJornadaNova.push({
        ...itemFlowChart,
        id,
        type,
        source,
        sourceHandle,
        target,
        positionX,
        positionY,
      });
    }

    return estruturaJornadaNova;
  };

  const getAllChangesFlowChart = () => {
    //Busca a estrutura de elementos do FlowChart
    let estruturaJornadaNova = getFlowChartElements();

    //Elementos tipo Evento
    const estruturaJornadaNovaEventos = estruturaJornadaNova.filter(
      (x) => x.type !== "smoothstep"
    );

    //Elementos tipo Conexão
    const estruturaJornadaNovaConexoes = estruturaJornadaNova.filter(
      (x) => x.type === "smoothstep"
    );

    //Intera os elemtnos tipo evento e mescla o evento com sua conexão relativa
    estruturaJornadaNova = [];
    for (var i = 0; i < estruturaJornadaNovaEventos.length; i++) {
      let evento = estruturaJornadaNovaEventos[i];

      let type = evento.id.split("_")[0];

      const conexao = estruturaJornadaNovaConexoes.filter(
        (x) => x.source === evento.id
      );

      if (conexao.length > 0) {
        for (var c = 0; c < conexao.length; c++) {
          const { source, sourceHandle, target } = conexao[c];

          evento = {
            ...evento,
            source,
            sourceId: sourceHandle,
            [`target${sourceHandle ? capitalizeFirstLetter(sourceHandle) : ""
              }`]: target,
          };
        }
      }

      estruturaJornadaNova.push({
        ...evento,
        type,
      });
    }

    estruturaJornada = estruturaJornadaNova;
    setValues({ ...values, data: { ...values.data, estruturaJornada } });
  };

  const onElementsRemove = (elementsToRemove) => {
    setElements((els) => removeElements(elementsToRemove, els));
    getAllChangesFlowChart();
  };

  const onConnect = (params) => {
    const { sourceHandle } = params;
    let label = null;
    if (sourceHandle) label = sourceHandle === "true" ? "SIM" : "NÃO";

    setElements((els) =>
      addEdge(
        {
          ...params,
          type: "smoothstep",
          labelStyle: { fontSize: 18, fontWeight: "bold" },
          label: label,
          style: { stroke: "#fe6633", strokeWidth: 5 },
        },
        els
      )
    );
    getAllChangesFlowChart();
  };
  const onElementClick = (event, element) => {
    const { id, type } = element;
    if (id === "inicio" || id === "fim" || type === "smoothstep") {
      return;
    }

    var eventType = id.split("_")[0];

    let itemFlowChart = estruturaJornada.find((x) => x.id === id);
    setEventoCorrente(itemFlowChart);

    switch (eventType) {
      case "aguardar":
        showAguardarModal(id);
        break;
      case "email":
        showEmailModal(id);
        break;
      case "sms":
        showSmsModal(id);
        break;
      case "telefonar":
        showTelefonarModal(id);
        break;
      case "condicao":
        showCondicaoModal(id);
        break;
      default:
        break;
    }
  };
  const adicionarElemento = (template) => {
    template.id = `${template.type}_${elements.length + 1}`;
    setElements([...elements, getDefaultEvent(template)]);
    getAllChangesFlowChart();
  };
  const confirmarModalHandler = (data) => {
    let itemFlowChart = estruturaJornada.find((x) => x.id === data.id);
    let estruturaJornadaNova = estruturaJornada.filter((x) => x.id !== data.id);
    estruturaJornadaNova.push({ ...itemFlowChart, ...data });
    estruturaJornada = estruturaJornadaNova;
    setEventoCorrente(null);
    getAllChangesFlowChart();
  };
  const cancelarModalHandler = (data) => {
    setEventoCorrente(null);
    getAllChangesFlowChart();
  };
  const onNodeDragStop = (event, node) => {
    getAllChangesFlowChart();
  };
  const onLoad = (reactFlowInstance) => {
    //reactFlowInstance.fitView();
  };
  return (
    <Fragment>
      <SmsModal
        formDisabled={values.formDisabled}
        eventoCorrente={eventoCorrente}
        confirmar={confirmarModalHandler}
        cancelar={cancelarModalHandler}
        tiposEventoSms={values.tiposEventoSms}
        templatesSms={values.templatesSms}
      />
      <EmailModal
        formDisabled={values.formDisabled}
        eventoCorrente={eventoCorrente}
        confirmar={confirmarModalHandler}
        cancelar={cancelarModalHandler}
        tiposEventoEmail={values.tiposEventoEmail}
        templatesEmail={values.templatesEmail}
      />
      <AguardarModal
        formDisabled={values.formDisabled}
        eventoCorrente={eventoCorrente}
        confirmar={confirmarModalHandler}
        cancelar={cancelarModalHandler}
      />
      <CondicaoModal
        formDisabled={values.formDisabled}
        eventoCorrente={eventoCorrente}
        confirmar={confirmarModalHandler}
        cancelar={cancelarModalHandler}
      />
      <TelefonarModal
        formDisabled={values.formDisabled}
        eventoCorrente={eventoCorrente}
        confirmar={confirmarModalHandler}
        cancelar={cancelarModalHandler}
        produtos={values.produtos}
        equipes={values.equipes}
      />
      <FormularioComplementar>
        {values.formDisabled ? null : (
          <div className="col-2">
            <ListaObjetos
              templateElementList={templateElementList}
              adicionarElemento={adicionarElemento}
            />
          </div>
        )}

        <div className={`col-${values.formDisabled ? 12 : 10}`}>
          <div className="border border-default rounded">
            <ReactFlowProvider>
              <ReactFlow
                elements={elements}
                style={{ height: 800, width: "100%" }}
                selectNodesOnDrag={true}
                zoomOnDoubleClick={false}
                onElementsRemove={onElementsRemove}
                onConnect={values.formDisabled ? null : onConnect}
                onElementClick={onElementClick}
                onNodeDragStop={onNodeDragStop}
                onLoad={onLoad}
                deleteKeyCode={46}
                nodeTypes={nodeTypes}
                nodesDraggable={values.formDisabled ? false : true}
                nodesConnectable={values.formDisabled ? false : true}
                elementsSelectable={values.formDisabled ? false : true}
                defaultZoom={0.50}
                defaultPosition={[0, 0]}
              >
                <Controls />
                <Background variant="lines" />
              </ReactFlow>
              <FlowChartChangesRead />
            </ReactFlowProvider>
          </div>
        </div>
      </FormularioComplementar>
    </Fragment>
  );
};

export default FormularioCampanhaJornada;
