import React, { useContext } from "react";
import "./SideBarSubLink.css";
import AppContext from "../../../Store/AppContext";
import { useHistory } from "react-router-dom";

const SideBarLink = ({ id, menu, dataParent }) => {
  const history = useHistory();
  const { setTituloTela } = useContext(AppContext);

  const openContent = (tituloTela, path) => {
    setTituloTela(tituloTela);
    return history.push(`/${path}`);
  };

  return (
    <div
      href="#"
      role="button"
      className="list-group-item"
      data-parent={dataParent}
      onClick={() => openContent(menu.descricao, menu.path)}
    >
      {menu.nome}
    </div>
  );
};

export default SideBarLink;
