import React, { useContext, Fragment, useEffect } from "react";
import { BackOfficeAtendimentoContext } from "../BackOfficeAtendimentoContext";
import Pesquisar from "../../../../../Components/Cadastro/Pesquisar/Pesquisar";
import TabelaResultados from "../../../../../Components/Cadastro/TabelaResultados/TabelaResultados";
import GroupBox from "../../../../../Components/GroupBox/GroupBox";

const PesquisarBackOfficeAtendimento = () => {
  const { values, setValues, resetScreen } = useContext(
    BackOfficeAtendimentoContext
  );

  useEffect(() => {
    resetScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      searchData: {
        ...values.searchData,
        [name]: value,
      },
    });
  }

  function onChangeCheck(event) {
    const { name, checked } = event.target;
    setValues({
      ...values,
      searchData: {
        ...values.searchData,
        [name]: checked,
      },
    });
  }

  return (
    <Fragment>
      <Pesquisar context={BackOfficeAtendimentoContext}>
        <div className="col-12 col-md-2 form-group">
          <label>CPF/CNPJ:</label>
          <input
            className="form-control"
            type="text"
            name="leadNumDocumento"
            value={values.searchData.leadNumDocumento}
            onChange={onChangeInput}
            onBlur={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-4 form-group">
          <label>Nome:</label>
          <input
            className="form-control"
            type="text"
            name="leadNome"
            value={values.searchData.leadNome}
            onChange={onChangeInput}
            onBlur={onChangeInput}
          />
        </div>
        <div className="col-12" />

        <div className="col-12 col-md-2 form-group">
          <label>Tipo de Transmissão:</label>
          <select
            className="form-control"
            name="tipoTransmissao"
            value={values.searchData.tipoTransmissao}
            onChange={onChangeInput}
          >
            <option className="disabled" value=""></option>
            {values.tiposTransmissao.map((tipoTransmissao) => (
              <option
                key={tipoTransmissao.codigo}
                value={tipoTransmissao.codigo}
              >
                {tipoTransmissao.descricao}
              </option>
            ))}
          </select>
        </div>

        <div className="col-12 col-md-2 form-group">
          <label>Usuário da Venda:</label>
          <select
            className="form-control"
            name="usuarioVenda"
            value={values.searchData.usuarioVenda}
            onChange={onChangeInput}
          >
            <option className="disabled" value="0"></option>
            {values.consultores.map((usuario) => (
              <option key={usuario.usuarioId} value={usuario.usuarioId}>
                {usuario.usuarioNome}
              </option>
            ))}
          </select>
        </div>

        <div className="col-12 col-md-2 form-group">
          <label>Usuário da Backoffice:</label>
          <select
            className="form-control"
            name="usuarioBackoffice"
            value={values.searchData.usuarioBackoffice}
            onChange={onChangeInput}
          >
            <option className="disabled" value="0"></option>
            {values.usuariosBackOffice.map((usuario) => (
              <option key={usuario.usuarioId} value={usuario.usuarioId}>
                {usuario.usuarioNome}
              </option>
            ))}
          </select>
        </div>

        <div className="col-12 col-md-2 form-group">
          <label>Status da Emissão:</label>
          <select
            className="form-control"
            name="statusEmissao"
            value={values.searchData.statusEmissao}
            onChange={onChangeInput}
          >
            <option className="disabled" value=""></option>
            {values.statusEmissaoList.map((status) => (
              <option key={status.id} value={status.id}>
                {status.descricao}
              </option>
            ))}
          </select>
        </div>

        <div className="col-12 col-md-2 form-group">
          <label>Num. da Proposta:</label>
          <input
            className="form-control"
            type="text"
            name="numeroProposta"
            value={values.searchData.numeroProposta}
            onChange={onChangeInput}
          />
        </div>

        <div className="col-12 col-md-2 form-group">
          <label>Status do Cadastro?</label>
          <div className="col-12 col-md-2 pt-3 pl-5">
            <div className="custom-control custom-switch float-center">
              <input
                checked={values.searchData.statusCadastro}
                type="checkbox"
                className="custom-control-input"
                id="statusCadastro"
                name="statusCadastro"
                onChange={onChangeCheck}
              />
              <label
                className="custom-control-label"
                htmlFor="statusCadastro"
              ></label>
            </div>
          </div>
        </div>
        <GroupBox titulo="Data Proposta" className="col-12 col-md-4 form-group">
          <div className="col-12 col-md-6 ">
            <label>De:</label>
            <input
              className="form-control"
              type="date"
              name="dataPropostaDe"
              value={values.searchData.dataPropostaDe}
              onChange={onChangeInput}
            />
          </div>
          <div className="col-12 col-md-6 ">
            <label>Até:</label>
            <input
              className="form-control"
              type="date"
              name="dataPropostaAte"
              value={values.searchData.dataPropostaAte}
              onChange={onChangeInput}
            />
          </div>
        </GroupBox>
        <GroupBox titulo="Data Venda" className="col-12 col-md-4 form-group">
          <div className="col-12 col-md-6">
            <label>De:</label>
            <input
              className="form-control"
              type="date"
              name="dataVendaDe"
              value={values.searchData.dataVendaDe}
              onChange={onChangeInput}
            />
          </div>

          <div className="col-12 col-md-6">
            <label>Até:</label>
            <input
              className="form-control"
              type="date"
              name="dataVendaAte"
              value={values.searchData.dataVendaAte}
              onChange={onChangeInput}
            />
          </div>
        </GroupBox>
        <GroupBox
          titulo="Data Pendência"
          className="col-12 col-md-4 form-group"
        >
          <div className="col-12 col-md-6">
            <label>De:</label>
            <input
              className="form-control"
              type="date"
              name="dataPendenciaDe"
              value={values.searchData.dataPendenciaDe}
              onChange={onChangeInput}
            />
          </div>

          <div className="col-12 col-md-6">
            <label>Até:</label>
            <input
              className="form-control"
              type="date"
              name="dataPendenciaAte"
              value={values.searchData.dataPendenciaAte}
              onChange={onChangeInput}
            />
          </div>
        </GroupBox>
        <GroupBox
          titulo="Limite Pendência"
          className="col-12 col-md-4 form-group"
        >
          <div className="col-12 col-md-6">
            <label>De:</label>
            <input
              className="form-control"
              type="date"
              name="dataLimitePendenciaDe"
              value={values.searchData.dataLimitePendenciaDe}
              onChange={onChangeInput}
            />
          </div>

          <div className="col-12 col-md-6">
            <label>Até:</label>
            <input
              className="form-control"
              type="date"
              name="dataLimitePendenciaAte"
              value={values.searchData.dataLimitePendenciaAte}
              onChange={onChangeInput}
            />
          </div>
        </GroupBox>
        <GroupBox
          titulo="Data Recusa"
          className="col-12 col-md-4 form-group"
        >
          <div className="col-12 col-md-6">
            <label>De:</label>
            <input
              className="form-control"
              type="date"
              name="dataRecusaDe"
              value={values.searchData.dataRecusaDe}
              onChange={onChangeInput}
            />
          </div>

          <div className="col-12 col-md-6">
            <label>Até:</label>
            <input
              className="form-control"
              type="date"
              name="dataRecusaAte"
              value={values.searchData.dataRecusaAte}
              onChange={onChangeInput}
            />
          </div>
        </GroupBox>
        <div className="col-12 col-md-2 d-flex form-group">
          <label style={{ width: 100 }}>Somente Renovação?</label>
          <div>
            <input
              type="checkbox"
              name="listarSomenteRenovacao"
              checked={values.searchData.listarSomenteRenovacao}
              onChange={onChangeCheck}
            />
          </div>
        </div>
      </Pesquisar>
      <TabelaResultados context={BackOfficeAtendimentoContext} />
    </Fragment>
  );
};

export default PesquisarBackOfficeAtendimento;
