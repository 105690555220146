/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useState } from 'react';
import { ListarFilaAtendimento, ModificaFilaAtendimento } from '../../../../Services/Atendimento';
import { ListarConsultoresOnline } from '../../../../Services/JornadaTrabalho';
import { Listar as ListarProduto } from '../../../../Services/Produto';
import { ListarSimples as ListarOrigemLead } from '../../../../Services/OrigemLead';
import { Listar as ListarTipoAgendamento } from '../../../../Services/TipoAgendamento';
import AppContext from '../../../../Store/AppContext';
import { validarPermissoesPagina } from '../../../../Utils/validarPermissoesPagina';

export const FilaAtendimentoContext = createContext();

const tituloTela = 'Fila Atendimento';

const searchData = {
	usuarioId: 0,
	atendimentoTipo: "",
	origemLeadId: 0,
	produtoId: 0,
	leadNome: '',
	leadDddTelefone: '',
	leadNumTelefone: '',
	leadEmail: '',
	leadDataVencApolice: ''
};

function initialState() {
	return {
		searchData: searchData,
		resultSearch: [],
		consultoresOnline: [],
		tiposAgendamento: [],
		produtos: [],
		origensLead: []
	};
}

const FilaAtendimentoProvider = ({ children }) => {
	const { showLoading, hideLoading, showMessage, setTituloTela, session } = useContext(AppContext);
	const [distribuirLeadsValues, setDistribuirLeadsValues] = useState(initialState());

	async function loadDependences() {

		try {
			const [
				permissoesPaginaAux,
				responseListarConsultoresOnline,
				responseListarTipoAgendamento,
				responseListarProduto,
				responseLListarOrigemLead
			] = await Promise.all(
				[
					validarPermissoesPagina(),
					ListarConsultoresOnline(true),
					ListarTipoAgendamento(),
					ListarProduto({}),
					ListarOrigemLead({}),
				]);

			return {
				permissoesPagina: permissoesPaginaAux,
				tiposAgendamento: await responseListarTipoAgendamento.tiposAgendamento.filter(
					(x) => x.tipoAgendamentoAtivo === 'A'
				),
				consultoresOnline: await responseListarConsultoresOnline.consultoresOnline.map((consultorOnline) => {
					return {
						value: consultorOnline.usuarioId,
						label: `${consultorOnline.usuarioNome} - ${consultorOnline.totalLeadsEmAtendimento} leads`
					};
				}),
				produtos: await responseListarProduto.produtos.filter((item) => item.produtoAtivo === 'A'),
				origensLead: await responseLListarOrigemLead.origensLead.filter((item) => item.origemLeadAtivo === 'A')
			};
		} catch (error) {
			showMessage('Erro', 'Erro Inesperado ao carregar dados basicos.');
			return;
		}
	}

	const resetScreen = () => {
		showLoading();
		async function carregarDependencias() {
			const dependencias = await loadDependences();
			setTituloTela(tituloTela);

			let usuarioFiltroDefault = 0;

			if (dependencias.consultoresOnline.find((x) => x.value === session.usuarioId)) {
				usuarioFiltroDefault = session.usuarioId;
			}

			setDistribuirLeadsValues({
				...initialState(),
				searchData: {
					...initialState().searchData,
					usuarioId: usuarioFiltroDefault
				},
				permissoesPagina: await dependencias.permissoesPagina,
				consultoresOnline: await dependencias.consultoresOnline,
				tiposAgendamento: await dependencias.tiposAgendamento,
				produtos: await dependencias.produtos,
				origensLead: await dependencias.origensLead
			});
			hideLoading();
		}
		carregarDependencias();
	};

	const searchHandler = async () => {
		showLoading();
		const responseListarFilaAtendimento = await ListarFilaAtendimento(distribuirLeadsValues.searchData);

		if (!responseListarFilaAtendimento.success) {
			showMessage('Erro', responseListarFilaAtendimento.message);
			if (responseListarFilaAtendimento.errors) {
				responseListarFilaAtendimento.errors.map((erro) => showMessage('Erro', erro.errorMessage));
			}
			hideLoading();
			return;
		}

		let resultSearchData = await responseListarFilaAtendimento.filaAtendimento;

		setDistribuirLeadsValues({
			...distribuirLeadsValues,
			resultSearch: resultSearchData
		});

		if (resultSearchData.length === 0) {
			showMessage('Alerta', 'Nenhum registro encontrado.');
		}
		hideLoading();
	};

	const modificarFilaHandler = async (dataInput) => {
		showLoading();
		const responseModificaFilaAtendimento = await ModificaFilaAtendimento({ ...dataInput });
		if (!responseModificaFilaAtendimento.success) {
			hideLoading();

			showMessage('Erro', `Erro ao modificar o registro: ${responseModificaFilaAtendimento.message}`);
			if (responseModificaFilaAtendimento.errors) {
				responseModificaFilaAtendimento.errors.map((erro) => showMessage('Erro', erro.errorMessage));
			}

			return;
		}
		resetScreen();
		hideLoading();
		showMessage('Sucesso', 'Item da Fila Modificado.');
	};

	return (
		<FilaAtendimentoContext.Provider
			value={{
				distribuirLeadsValues,
				setDistribuirLeadsValues,
				resetScreen,
				searchHandler,
				modificarFilaHandler
			}}
		>
			{children}
		</FilaAtendimentoContext.Provider>
	);
};

export default FilaAtendimentoProvider;
