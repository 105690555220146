import React, { Fragment } from "react";

const SemRegistros = ({
}) => {
  return (
    <div >
      <div className="row align-items-center">
        <div style={{ width: "100%", textAlign: "center" }} className="alert alert-info semInfo" role="alert">
          Sem Registros.
        </div>
      </div>
    </div>
  );
};

export default SemRegistros;
