import React from "react";
import $ from "jquery";
import "./SemAtendimento.css";
import { Fragment } from "react";
import { useHistory } from "react-router-dom";

export const showSemAtendimento = () => {
  $("#modalSemAtendimentoLeadAtendimento").modal({
    backdrop: "static",
    keyboard: false,
    show: true,
  });
};
export const hideSemAtendimento = () => {
  $("#modalSemAtendimentoLeadAtendimento").modal("hide");
};

const SemAtendimento = () => {
  const history = useHistory();
  return (
    <Fragment>
      <div
        className="modal fade"
        id="modalSemAtendimentoLeadAtendimento"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalSemAtendimentoLeadAtendimentoLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="modalSemAtendimentoLeadAtendimentoLabel"
              >

                Fila Vazia
            </h5>
            </div>
            <div className="modal-body componenteAgendaRedirecionar_modalBody">
              <div className="form-group">
                <label>Não foi encotrado um novo atendimento na fila.</label>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  data-dismiss="modal"
                  onClick={() => {  history.push(`/Atendimento/FilaAtendimento`); }}
                >
                  Fila Atendimento
              </button>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default SemAtendimento
