import React, { useContext, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { GrupoUsuarioContext } from "../GrupoUsuarioContext";
import Formulario from "../../../../../Components/Cadastro/Formulario/Formulario";
import Permissoes from "../../../../../Components/PermissoesMultLevel/Permissoes";
import PermissoesRelatorios from "../../../../../Components/PermissoesRelatorios/PermissoesRelatorios";

const FormularioGrupoUsuario = () => {
  const { values, setValues, getDetailHandler } = useContext(
    GrupoUsuarioContext
  );
  let { id } = useParams();

  useEffect(() => {
    getDetailHandler(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      data: {
        ...values.data,
        [name]: value,
      },
    });
  }

  function checkItemRelatorioChange(item) {
    let id_array = item.id.split('_');
    let relatorioModulo = id_array[0];
    let relatorioId = id_array[1];

    let relatorios = values.permissoesRelatoriosPerfil;

    let relatoriosModulo = relatorios.find(x => x.relatorioModulo === relatorioModulo);

    relatoriosModulo.relatorios.map(relario => {
      relario.temPermissao = relario.relatorioId == relatorioId ? item.checked : relario.temPermissao;
    })

    let permissoes = [];
    relatorios.map(relatorioModulo => {
      if (relatorioModulo.relatorioModulo === relatorioModulo) {
        relatorioModulo.relatorios = relatoriosModulo.relatorios;
      }
      let permissoesModulo = relatorioModulo.relatorios.filter(x => x.temPermissao === true);
      permissoesModulo.map(p => permissoes.push(p));

    })

    setValues({
      ...values,
      data: {
        ...values.data,
        permissoesRelatorios: permissoes,
      },
      permissoesRelatoriosPerfil: relatorios
    });

  }
  return (
    <Fragment>
      <Formulario context={GrupoUsuarioContext}>
        <div className="col-12 col-md-3 form-group">
          <label>Nome do perfil</label>
          <input
            disabled={values.formDisabled}
            className="form-control"
            type="text"
            name="grupoUsuarioNome"
            value={values.data.grupoUsuarioNome}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-3 form-group">
          <label>Nível</label>
          <select
            disabled={values.formDisabled}
            className="form-control"
            name="grupoUsuarioNivel"
            value={values.data.grupoUsuarioNivel}
            onChange={onChangeInput}
          >
            <option className="disabled" value="0"></option>
            {values.niveisAcesso.map((nivel) => (
              <option key={nivel.nivel} value={nivel.nivel}>
                {nivel.descricao}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12">
          <div className="default-title">Permissões</div>
          <div className="card shadow-sm mb-4">
            <div className="row mb-3">
              <div className="col-12">
                <Permissoes
                  permissoes={values.permissoesPerfil}
                  context={GrupoUsuarioContext}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="default-title">Relatórios</div>
          <div className="card shadow-sm mb-4">
            <div className="row mb-3">
              <div className="col-12">
                <PermissoesRelatorios
                  formDisabled={values.formDisabled}
                  permissoes={values.permissoesRelatoriosPerfil}
                  checkItemChange={checkItemRelatorioChange}
                />
              </div>
            </div>
          </div>
        </div>
      </Formulario>
    </Fragment>
  );
};

export default FormularioGrupoUsuario;
