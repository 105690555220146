import React from "react";
// import $ from 'jquery';
import { Route, Redirect } from "react-router-dom";
import { Autenticado } from "../Services/Autenticacao";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const autenticado = async () => {
    const responseAutenticado = await Autenticado();
    return await responseAutenticado.autenticado;
  };
  return (
    <Route
      {...rest}
      render={(props) => {
        if (autenticado) {
          // $('html, body').animate({ scrollTop: 0 }, 'fast');
          return <Component {...props} key={props.location.key} />;
        } else {
          return (
            <Redirect to={{ pathname: "/", State: { from: props.location } }} />
          );
        }
      }}
    />
  );
};

export default PrivateRoute;
