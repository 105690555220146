import React, { useContext } from "react";
import Novo from "../Novo/Novo";

const Pesquisar = ({ children, context, exibirBotaoNovo = true }) => {
  const { values, searchHandler } = useContext(context);

  return (
    <div className="row">
      <div className="col-12">
        <div className="default-title">Pesquisa</div>
        <div className="card shadow-sm mb-4">
          <div className="row align-items-end">
            <div className="col-12 mb-3 text-right">
              {values.permissoesPagina != null &&
              values.permissoesPagina.incluir &&
              exibirBotaoNovo ? (
                <Novo />
              ) : null}
            </div>
            {children}

            <div className="col-12 mb-3 text-right">
              <hr />
              <button
                type="button"
                className="btn btn-success"
                onClick={searchHandler}
              >
                Buscar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pesquisar;
