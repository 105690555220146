import React,{useEffect} from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRoute from "../../../Routes/PrivateRoutes";
import CobrancaProvider from "./CobrancaContext";
import PesquisarCobranca from "./Events/PesquisarCobranca";
import FormularioCobranca from "./Events/FormularioCobranca";
import { LoadMask } from "../../../Utils/mask";

const Index = () => {
  let match = useRouteMatch();
  useEffect(() => {
    LoadMask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <CobrancaProvider>
      <div className="container-fluid pl-3 pt-3 pb-3">
        <Switch>
          <PrivateRoute
            exact
            path={`${match.url}`}
            component={PesquisarCobranca}
          />
          <PrivateRoute
            path={`${match.url}/:id`}
            component={FormularioCobranca}
          />
        </Switch>
      </div>
    </CobrancaProvider>
  );
};

export default Index;
