import React, { useContext, Fragment, useEffect } from "react";
import { FluxoEventosContext } from "../FluxoEventosContext";
import Pesquisar from "../../../../../Components/Cadastro/Pesquisar/Pesquisar";
import TabelaResultados from "../../../../../Components/Cadastro/TabelaResultados/TabelaResultados";

const PesquisarFluxoEventos = () => {
  const { values, setValues, resetScreen } = useContext(FluxoEventosContext);

  useEffect(() => {
    resetScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      searchData: {
        ...values.searchData,
        [name]: value,
      },
    });
  }

  return (
    <Fragment>
      <Pesquisar context={FluxoEventosContext}>
        <div className="col-12 col-md-3">
          <label>Nome</label>
          <input
            className="form-control"
            type="text"
            name="fluxoEventosNome"
            value={values.searchData.fluxoEventosNome}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-3">
          <label>Tipo</label>
          <select
            className="form-control"
            name="fluxoEventosItensTipo"
            value={values.searchData.fluxoEventosItensTipo}
            onChange={onChangeInput}
          >
            <option className="disabled" />
            <option value="SMS"> SMS </option>
            <option value="EMAIL"> E-MAIL </option>
            <option value="WHATSAPP"> WHATSAPP </option>
          </select>
        </div>
      </Pesquisar>
      <TabelaResultados context={FluxoEventosContext} />
    </Fragment>
  );
};

export default PesquisarFluxoEventos;
