import React, { useEffect, useState } from "react";
import $ from "jquery";
import "./AguardarModal.css";
import { Fragment } from "react";

let eventId = 0;

export const showAguardarModal = (id) => {
  eventId = id;
  $("#aguardarModal").modal({
    backdrop: "static",
    keyboard: false,
    show: true,
  });
};

export const hideAguardarModal = () => {
  eventId = 0;
  $("#aguardarModal").modal("hide");
};

const initialState = () => {
  return {
    tipo: "aguardar",
    horas: 0,
    minutos: 0,
  };
};

const AguardarModal = ({
  eventoCorrente,
  confirmar = (data) => {},
  cancelar = (data) => {},
  formDisabled = true,
}) => {
  const [valuesEventoAguardar, setEventoAguardar] = useState(initialState);

  const formularioComErro =
    !valuesEventoAguardar.horas ||
    !valuesEventoAguardar.minutos ||
    parseInt(valuesEventoAguardar.horas) < 0 ||
    parseInt(valuesEventoAguardar.minutos) < 0 ||
    (parseInt(valuesEventoAguardar.horas) === 0 &&
      parseInt(valuesEventoAguardar.minutos) === 0);

  useEffect(() => {
    if (eventoCorrente) {
      setEventoAguardar(eventoCorrente);
    } else {
      setEventoAguardar(initialState());
    }
  }, [eventoCorrente]);

  const confirmarHandler = () => {
    setEventoAguardar(initialState());
    confirmar({ id: eventId, ...valuesEventoAguardar });
    hideAguardarModal();
  };

  const cancelarHandler = () => {
    hideAguardarModal();
    cancelar({ id: eventId, ...valuesEventoAguardar });
  };

  function onChangeInput(event) {
    const { name, value } = event.target;
    setEventoAguardar({
      ...valuesEventoAguardar,
      [name]: value,
    });
  }

  return (
    <Fragment>
      <div
        className="modal fade"
        id="aguardarModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="aguardarModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="aguardarModalLabel">
                Aguardar
              </h5>
            </div>
            <div className="modal-body componenteAgendaRedirecionar_modalBody">
              <div className="row align-items-center">
                <div className="col-6 form-group">
                  <label>Horas</label>
                  <input
                    disabled={formDisabled}
                    className="form-control"
                    type="number"
                    maxLength="3"
                    name="horas"
                    value={valuesEventoAguardar.horas}
                    onChange={onChangeInput}
                  />
                </div>
                <div className="col-6 form-group">
                  <label>Minutos</label>
                  <input
                    disabled={formDisabled}
                    className="form-control"
                    type="number"
                    maxLength="3"
                    name="minutos"
                    value={valuesEventoAguardar.minutos}
                    onChange={onChangeInput}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  cancelarHandler();
                }}
              >
                {formDisabled ? "Fechar" : "Cancelar"}
              </button>
              {formDisabled ? null : (
                <button
                  disabled={formularioComErro}
                  type="button"
                  className="btn btn-default"
                  onClick={confirmarHandler}
                >
                  Confirmar
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AguardarModal;
