/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext } from 'react';
import AppContext from '../../../../Store/AppContext';
import { retornarUnicos } from '../../../../Utils/globalFunctions';
import {
	Listar as ListarLandingPage,
	Detalhes as DetalhesLandingPage,
	Remover as RemoverLandingPage,
	Incluir as IncluirLandingPage,
	Atualizar as AtualizarLandingPage
} from '../../../../Services/LandingPage';
import { Listar as ListarCidades } from '../../../../Services/Cidade';
import { useHistory } from 'react-router-dom';
import { validarPermissoesPagina } from '../../../../Utils/validarPermissoesPagina';
export const LandingPageContext = createContext();

const tituloTela = 'Landing Page';

const defaultData = {
	landingPageId: 0,
	landingPageCod: '',
	landingPageNome: '',
	landingPageUrl: '',
	cidadeId: 0,
	uf: ''
};

const searchData = {
	landingPageCod: '',
	landingPageNome: ''
};

const columnsDefinition = [
	{
		text: 'Id',
		dataField: 'landingPageId',
		hidden: true
	},
	{
		text: 'Código',
		dataField: 'landingPageCod',
		headerStyle: { width: '100px' },
		sort: true
	},
	{
		text: 'Nome',
		dataField: 'landingPageNome',
		headerStyle: { width: '450px' },
		sort: true
	}
];

function initialState() {
	return {
		searchData: searchData,
		resultSearch: [],
		columnsDefinition: columnsDefinition,
		data: defaultData,
		formDisabled: true,
		ufs: [],
		cidades: []
	};
}

const LandingPageProvider = ({ children }) => {
	const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(AppContext);
	const [ values, setValues ] = useState(initialState());
	let history = useHistory();

	async function loadDependences() {
		const permissoesPagina = validarPermissoesPagina();
		const responseCidades = await ListarCidades();
		const cidadesApi = await responseCidades.cidades;
		const ufs = await responseCidades.cidades.map((cidade) => cidade.ufCod).filter(retornarUnicos);

		return { permissoesPagina, ufs, cidadesApi };
	}

	const resetScreen = () => {
		showLoading();
		async function carregarDependencias() {
			const dependencias = await loadDependences();
			setTituloTela(tituloTela);
			setValues({
				...initialState(),
				permissoesPagina: await dependencias.permissoesPagina,
				ufs: await dependencias.ufs,
				cidades: await dependencias.cidadesApi,
				cidadesApi: await dependencias.cidadesApi
			});
			hideLoading();
		}
		carregarDependencias();
	};

	const searchHandler = async () => {
		showLoading();
		const responseListar = await ListarLandingPage(values.searchData);

		if (!responseListar.success) {
			showMessage('Erro', 'Erro Inesperado durante a pesquisa.');
			hideLoading();
			return;
		}
		const resultSearchData = await responseListar.landingPages;
		resultSearchData.map((data) => (data.Id = data.landingPageId));

		setValues({
			...values,
			resultSearch: resultSearchData
		});

		if (resultSearchData.length === 0) {
			showMessage('Alerta', 'Nenhum registro encontrado.');
		}
		hideLoading();
	};

	const removeHandler = async (id) => {
		showLoading();

		const responseRemover = await RemoverLandingPage(id);

		if (!responseRemover.success) {
			hideLoading();
			showMessage('Erro', `Erro durante a exclusão: ${responseRemover.message}`);
			responseRemover.errors.map((erro) => showMessage('Erro', erro.errorMessage));
			return;
		}
		searchHandler();
		hideLoading();
		showMessage('Sucesso', 'Registro excluido.');
	};

	const getDetailHandler = async (id) => {
		showLoading();
		const dependencias = await loadDependences();

		if (id > 0) {
			const responseDetalhes = await DetalhesLandingPage(id);

			const { success, landingPage } = await responseDetalhes;

			if (!success) {
				showMessage('Erro', 'Erro ao buscar os detalhes do registro.');
				setValues({
					...values,
					data: defaultData,
					permissoesPagina: await dependencias.permissoesPagina,
					ufs: [],
					cidades: []
				});
			}

			setValues({
				...values,
				data: landingPage,
				permissoesPagina: await dependencias.permissoesPagina,
				ufs: await dependencias.ufs,
				cidades: await dependencias.cidadesApi,
				cidadesApi: await dependencias.cidadesApi
			});
		} else {
			setValues({
				...values,
				data: {
					...defaultData,
					landingPageId: 0
				},
				permissoesPagina: await dependencias.permissoesPagina,
				ufs: await dependencias.ufs,
				cidades: await dependencias.cidadesApi,
				cidadesApi: await dependencias.cidadesApi,
				formDisabled: false
			});
		}
		hideLoading();
	};

	const createData = async () => {
		showLoading();
		const responseIncluir = await IncluirLandingPage(values.data);
		if (!responseIncluir.success) {
			hideLoading();
			showMessage('Erro', `Erro ao criar o registro: ${responseIncluir.message}`);
			responseIncluir.errors.map((erro) => showMessage('Erro', erro.errorMessage));
			return;
		}

		resetScreen();
		hideLoading();
		showMessage('Sucesso', 'Registro criado.');
		history.goBack();
	};

	const editHandler = () => {
		setValues({
			...values,
			formDisabled: false
		});
	};

	const updateData = async () => {
		showLoading();
		const responseAtualizar = await AtualizarLandingPage(values.data);
		if (!responseAtualizar.success) {
			hideLoading();
			showMessage('Erro', `Erro ao atualizar o registro: ${responseAtualizar.message}`);
			responseAtualizar.errors.map((erro) => showMessage('Erro', erro.errorMessage));
			return;
		}
		resetScreen();
		hideLoading();
		showMessage('Sucesso', 'Registro atualizado.');
		history.goBack();
	};

	const saveHandler = async () => {
		if (values.data.landingPageId === 0) {
			await createData();
		} else {
			await updateData();
		}
	};

	const cancelHandler = () => {
		setValues({
			...values,
			data: defaultData,
			formDisabled: true
		});
	};

	const filtrarCidades = (uf) => {
		
		setValues({
			...values,
			data: {
				...values.data,
				uf: uf
			},
			cidades: values.cidadesApi.filter((cidade) => cidade.ufCod === uf)
		});
	};

	return (
		<LandingPageContext.Provider
			value={{
				values,
				setValues,
				resetScreen,
				searchHandler,
				cancelHandler,
				removeHandler,
				saveHandler,
				getDetailHandler,
				editHandler,
				filtrarCidades
			}}
		>
			{children}
		</LandingPageContext.Provider>
	);
};

export default LandingPageProvider;
