import React, { Fragment } from "react";

const DetalhesLeadAuto = ({
  leadAuto = {},
}) => {
  return (
    <Fragment>
      <div className="card shadow-sm mb-4 ml-3">
        <div className="row align-items-center">

          {leadAuto.leadAutoPlaca ? (
            <div className="col-md-2">
              <div className="font-weight-bold">
                Placa:{" "}
                <span className="font-weight-normal">
                  {leadAuto.leadAutoPlaca}
                </span>
              </div>
            </div>
          ) : null}

          {leadAuto.leadAutoFabricante ? (
            <div className="col-md-5">
              <div className="font-weight-bold">
                Fabricante:{" "}
                <span className="font-weight-normal">
                  {leadAuto.leadAutoFabricante}
                </span>
              </div>
            </div>
          ) : null}

          {leadAuto.leadAutoModelo ? (
            <div className="col-md-5">
              <div className="font-weight-bold">
                Modelo:{" "}
                <span className="font-weight-normal">
                  {leadAuto.leadAutoModelo}
                </span>
              </div>
            </div>
          ) : null}

          {leadAuto.leadAutoAnoModelo || leadAuto.leadAutoAnoFabricacao ? (
            <div className="col-md-3">
              <div className="font-weight-bold">
                Ano Modelo/Fabr:{" "}
                <span className="font-weight-normal">
                  {leadAuto.leadAutoAnoModelo} / {leadAuto.leadAutoAnoFabricacao}
                </span>
              </div>
            </div>
          ) : null}

          {leadAuto.leadAutoTipoUtilizacaoDutDescricao ? (
            <div className="col-md-3">
              <div className="font-weight-bold">
                DUT:{" "}
                <span className="font-weight-normal">
                  {leadAuto.leadAutoTipoUtilizacaoDutDescricao}
                </span>
              </div>
            </div>
          ) : null}


        </div>
      </div>
    </Fragment>
  );
};

export default DetalhesLeadAuto;
