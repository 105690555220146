import React, { useEffect, useState } from "react";
import $ from "jquery";
import "./EmailModal.css";
import { Fragment } from "react";
let eventId = 0;

export const showEmailModal = (id) => {
  eventId = id;
  $("#emailModal").modal({
    backdrop: "static",
    keyboard: false,
    show: true,
  });
};
export const hideEmailModal = () => {
  $("#emailModal").modal("hide");
};

function initialState() {
  return {
    tipo: "email",
    motor: "",
    modeloMensagem: "",
  };
}

const EmailModal = ({
  eventoCorrente,
  confirmar = (data) => {},
  cancelar = (data) => {},
  tiposEventoEmail = [],
  templatesEmail = [],
  formDisabled = true,
}) => {
  const [valuesEventoEmail, setEventoEmail] = useState(initialState());

  const formularioComErro =
    !valuesEventoEmail.motor || !valuesEventoEmail.modeloMensagem;

  useEffect(() => {
    if (eventoCorrente) {
      setEventoEmail(eventoCorrente);
    } else {
      setEventoEmail(initialState());
    }
  }, [eventoCorrente]);

  const confirmarHandler = () => {
    setEventoEmail(initialState());
    confirmar({ id: eventId, ...valuesEventoEmail });
    hideEmailModal();
  };
  const cancelarHandler = () => {
    setEventoEmail(initialState());
    hideEmailModal();
    cancelar({ id: eventId, ...valuesEventoEmail });
  };
  function onChangeInput(event) {
    const { name, value } = event.target;
    setEventoEmail({
      ...valuesEventoEmail,
      [name]: value,
    });
  }

  return (
    <Fragment>
      <div
        className="modal fade"
        id="emailModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="emailModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="emailModalLabel">
                Email
              </h5>
            </div>
            <div className="modal-body componenteAgendaRedirecionar_modalBody">
              <div className="row align-items-center">
                <div className="col-6 form-group">
                  <label>Motor</label>
                  <select
                    disabled={formDisabled}
                    className="form-control"
                    name="motor"
                    value={
                      valuesEventoEmail.motor ? valuesEventoEmail.motor : ""
                    }
                    onChange={onChangeInput}
                  >
                    <option className="disabled" value=""></option>
                    {tiposEventoEmail.map((tipoEvento) => (
                      <option
                        key={tipoEvento.tipoEventoId}
                        value={tipoEvento.tipoEventoId}
                      >
                        {tipoEvento.tipoEventoDescricao}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-6 form-group">
                  <label>Modelo de Mensagem</label>
                  <select
                    disabled={formDisabled}
                    className="form-control"
                    name="modeloMensagem"
                    value={
                      valuesEventoEmail.modeloMensagem
                        ? valuesEventoEmail.modeloMensagem
                        : ""
                    }
                    onChange={onChangeInput}
                  >
                    <option className="disabled" value=""></option>
                    {templatesEmail.map((templateEmail) => (
                      <option
                        key={templateEmail.templateEmailId}
                        value={templateEmail.templateEmailId}
                      >
                        {templateEmail.templateEmailNome}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  cancelarHandler();
                }}
              >
                {formDisabled ? "Fechar" : "Cancelar"}
              </button>
              {formDisabled ? null : (
                <button
                  disabled={formularioComErro}
                  type="button"
                  className="btn btn-default"
                  onClick={confirmarHandler}
                >
                  Confirmar
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EmailModal;
