import { RequestWebApi, validateRequestErrorStatus } from './Requests';

const controller = 'LandingPage';
export const Listar = (landingPage) =>
	RequestWebApi.get(`/${controller}/Listar`, {
		params: { ...landingPage },
	})
		.then(function (response) {
			const { resultData, success } = response.data;
			const landingPages = resultData;
			return { success, landingPages };
		})
		.catch(function (error) {
			validateRequestErrorStatus(error.response.status);
			const { success, message, errors } = error.response.data;
			return { success, message, errors, landingPages: [] };
		});

export const Detalhes = (landingPageId) =>
	RequestWebApi.get(`/${controller}/Detalhes`, {
		params: { landingPageId: landingPageId }
	})
		.then(function (response) {
			const { resultData, success } = response.data;
			const landingPage = resultData;
			return { success, landingPage };
		})
		.catch(function (error) {
			validateRequestErrorStatus(error.response.status);
			const { success, message, errors } = error.response.data;
			return { success, message, errors, landingPage: {} };
		});

export const Incluir = (landingPage) =>
	RequestWebApi.post(`/${controller}/Incluir`, landingPage)
		.then(function (response) {
			const { resultData, success } = response.data;
			const novoGrupoUsuario = resultData;
			return { success, novoGrupoUsuario };
		})
		.catch(function (error) {
			validateRequestErrorStatus(error.response.status);
			const { success, message, errors } = error.response.data;
			return { success, message, errors };
		});

export const Atualizar = (landingPage) =>
	RequestWebApi.put(`/${controller}/Atualizar`, landingPage)
		.then(function (response) {
			const { success } = response.data;
			return { success };
		})
		.catch(function (error) {
			validateRequestErrorStatus(error.response.status);
			const { success, message, errors } = error.response.data;
			return { success, message, errors };
		});

export const Remover = (landingPageId) =>
	RequestWebApi.delete(`/${controller}/Remover`, {
		params: { landingPageId: landingPageId }
	})
		.then(function (response) {
			const { success } = response.data;
			return { success };
		})
		.catch(function (error) {
			validateRequestErrorStatus(error.response.status);
			const { success, message, errors } = error.response.data;
			return { success, message, errors };
		});
