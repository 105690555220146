import React, { Fragment, useContext } from "react";

const FormularioCotacaoPedida = ({ context }) => {
  const {
    atendimentoValues,
    setAtendimentoValues,
    cotacaoPerdidaHandler,
  } = useContext(context);

  const solicitaDataVencimentoApolice = atendimentoValues.dadosAtendimento.cotacaoPerdida.motivoPerdaId > 0
    && atendimentoValues.motivosPerda.find(x => x.motivoPerdaId === Number(atendimentoValues.dadosAtendimento.cotacaoPerdida.motivoPerdaId)).motivoPerdaSolitarVencimentoApolice;

  const formularioComErro =
    atendimentoValues.dadosAtendimento.cotacaoPerdida.motivoPerdaId === "0" ||
    !atendimentoValues.dadosAtendimento.cotacaoPerdida.motivoPerdaId ||
    !atendimentoValues.dadosAtendimento.cotacaoPerdida.observacao ||
    (solicitaDataVencimentoApolice && !atendimentoValues.dadosAtendimento.dataVencimentoApolice);



  function onChangeInput(event) {
    const { name, value } = event.target;
    setAtendimentoValues({
      ...atendimentoValues,
      dadosAtendimento: {
        ...atendimentoValues.dadosAtendimento,
        cotacaoPerdida: {
          ...atendimentoValues.dadosAtendimento.cotacaoPerdida,
          [name]: value,
        },
      },
    });
  }

  function onChangeDadosAtendimentoInput(event) {
    const { name, value } = event.target;
    setAtendimentoValues({
      ...atendimentoValues,
      dadosAtendimento: {
        ...atendimentoValues.dadosAtendimento,
        [name]: value,
        cotacaoPerdida: {
          ...atendimentoValues.dadosAtendimento.cotacaoPerdida
        },
      },
    });
  }



  return (
    <Fragment>
      <div className="row">
        <div className="col-12 col-md-4 form-group">
          <label>Motivo<span className="campoObratorio">*</span></label>
          <select
            className="form-control"
            name="motivoPerdaId"
            value={
              atendimentoValues.dadosAtendimento.cotacaoPerdida.motivoPerdaId
            }
            onChange={onChangeInput}
          >
            <option className="disabled" value="0"></option>
            {atendimentoValues.motivosPerda.map((motivoPerda) => (
              <option key={motivoPerda.motivoPerdaId} value={motivoPerda.motivoPerdaId}>
                {motivoPerda.motivoPerdaDescricao}
              </option>
            ))}
          </select>
        </div>
        {solicitaDataVencimentoApolice
          ? <div className="col-12 col-md-4 form-group">
            <label>
              Data Vencimento Apólice<span className="campoObratorio">*</span>
            </label>
            <input
              type="date"
              className="form-control"
              name="dataVencimentoApolice"
              value={
                atendimentoValues.dadosAtendimento.dataVencimentoApolice
              }
              onChange={onChangeDadosAtendimentoInput}
            />
          </div> : null
        }


        <div className="col-12 form-group">
          <label>Observação<span className="campoObratorio">*</span></label>
          <textarea
            cols="30"
            rows="4"
            className="form-control"
            name="observacao"
            value={atendimentoValues.dadosAtendimento.cotacaoPerdida.observacao}
            onChange={onChangeInput}
          ></textarea>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-3">
          <button
            type="button"
            className="btn btn-default"
            disabled={formularioComErro}
            onClick={cotacaoPerdidaHandler}
          >
            Salvar e Finalizar
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default FormularioCotacaoPedida;
