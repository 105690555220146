import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "Cotacao";

export const GerarCotacao = (formularioCotacao) =>
  RequestWebApi.post(`/${controller}/GerarCotacao`, formularioCotacao)
    .then(function (response) {
      const { resultData, success } = response.data;
      const cotacoes = resultData;
      return { success, cotacoes };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, cotacoes: [] };
    });

export const TransmitirProposta = (proposta) =>
  RequestWebApi.post(`/${controller}/TransmitirProposta`, proposta)
    .then(function (response) {
      const { resultData, success } = response.data;
      const proposta = resultData;
      return { success, proposta };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });