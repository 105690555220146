import React, { Fragment } from "react";
import "./DadosUltimaContratacao.css";
const DadosUltimaContratacao = ({
  lead = null,
  atendimentoDadosUltimaContratacao = null,
}) => {
  return (
    <Fragment>
      <button
        type="button"
        className="btn btn-default ml-2 mr-2"
        data-toggle="modal"
        data-target="#dadosUltimaContratacao"
        data-dismiss="modal"
      >
        Dados Ultima Contratação
      </button>

      <div
        className="modal fade ComponenteDadosUltimaContratacao_ContainerScroll"
        id="dadosUltimaContratacao"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="dadosUltimaContratacaoTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered  modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalLeadLabel">
                Dados Ultima Contratação
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body componenteDadosUltimaContratacao_modalBody">
              <div className="row">
                {atendimentoDadosUltimaContratacao.dataFechamento ? (
                  <div className="col-md-4">
                    <div className="font-weight-bold">
                      Data Fechamento:{" "}
                      <span className="font-weight-normal">
                        {atendimentoDadosUltimaContratacao.dataFechamento}
                      </span>
                    </div>
                  </div>
                ) : null}
                {atendimentoDadosUltimaContratacao.seguradoraNome ? (
                  <div className="col-md-4">
                    <div className="font-weight-bold">
                      Seguradora:{" "}
                      <span className="font-weight-normal">
                        {atendimentoDadosUltimaContratacao.seguradoraNome}
                      </span>
                    </div>
                  </div>
                ) : null}
                {atendimentoDadosUltimaContratacao.valorLiquido ? (
                  <div className="col-md-4">
                    <div className="font-weight-bold">
                      Valor :{" "}
                      <span className="font-weight-normal">
                        {atendimentoDadosUltimaContratacao.valorLiquido}
                      </span>
                    </div>
                  </div>
                ) : null}
                {atendimentoDadosUltimaContratacao.vendaProtocoloSeguradora ? (
                  <div className="col-md-4">
                    <div className="font-weight-bold">
                      Protocolo Seguradora :{" "}
                      <span className="font-weight-normal">
                        {
                          atendimentoDadosUltimaContratacao.vendaProtocoloSeguradora
                        }
                      </span>
                    </div>
                  </div>
                ) : null}
                {atendimentoDadosUltimaContratacao.vendaPropostaSeguradora ? (
                  <div className="col-md-4">
                    <div className="font-weight-bold">
                      Proposta Seguradora :{" "}
                      <span className="font-weight-normal">
                        {
                          atendimentoDadosUltimaContratacao.vendaPropostaSeguradora
                        }
                      </span>
                    </div>
                  </div>
                ) : null}
                {atendimentoDadosUltimaContratacao.vendaServicoContratado ? (
                  <div className="col-md-4">
                    <div className="font-weight-bold">
                      Serviço Contratado :{" "}
                      <span className="font-weight-normal">
                        {
                          atendimentoDadosUltimaContratacao.vendaServicoContratado
                        }
                      </span>
                    </div>
                  </div>
                ) : null}
                {atendimentoDadosUltimaContratacao.consultorNome ? (
                  <div className="col-md-4">
                    <div className="font-weight-bold">
                      Consultor :{" "}
                      <span className="font-weight-normal">
                        {atendimentoDadosUltimaContratacao.consultorNome}
                      </span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DadosUltimaContratacao;
