/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext } from "react";
import AppContext from "../../../../Store/AppContext";
import { removeItemFromArray } from "../../../../Utils/globalFunctions";
import { ListarSimples as ListarUsuario } from "../../../../Services/Usuario";
import {
  Listar as ListarJornadaTrabalho,
  Detalhes as DetalhesJornadaTrabalho,
  Remover as RemoverJornadaTrabalho,
  Incluir as IncluirJornadaTrabalho,
  Atualizar as AtualizarJornadaTrabalho,
} from "../../../../Services/JornadaTrabalho";
import { useHistory } from "react-router-dom";
import { retornarDescricaoJornada } from "../../../../Utils/JornadaTrabalho";
import { validarPermissoesPagina } from "../../../../Utils/validarPermissoesPagina";
export const JornadaTrabalhoContext = createContext();

const tituloTela = "Jornada de Trabalho";

const defaultData = {
  jornadaTrabalhoId: 0,
  jornadaTrabalhoDescricao: "",
  descricaoDias: "",
  jornadaTrabalhoFeriado: 0,
  dias: {},
  consultores: [],
};

const searchData = {
  jornadaTrabalhoDescricao: "",
  usuarioId: 0,
};

const columnsDefinition = [
  {
    text: "Id",
    dataField: "jornadaTrabalhoId",
    hidden: true,
  },
  {
    text: "Descrição",
    dataField: "jornadaTrabalhoDescricao",
    headerStyle: { width: "150px" },
    sort: true,
  },
  {
    text: "Jornada",
    dataField: "descricaoDias",
    headerStyle: { width: "250px" },
    sort: true,
  },
  {
    text: "Trabalha em feriados",
    dataField: "jornadaTrabalhoFeriado",
    headerStyle: { width: "100px" },
    sort: true,
    formatter: (cell, row) => {
      return cell ? (
        <span className="badge badge-success">Sim</span>
      ) : (
        <span className="badge badge-danger">Não</span>
      );
    },
  },
  {
    text: "Consultores",
    dataField: "totalConsultores",
    headerStyle: { width: "100px" },
    sort: true,
  },
];

const columnsDefinitionConsultores = [
  {
    text: "Id",
    dataField: "usuarioId",
    hidden: true,
  },
  {
    text: "Consultor",
    dataField: "usuarioNome",
    headerStyle: { width: "250px" },
    sort: true,
  },
];

const defaultDataConsultor = { usuarioId: 0, usuarioNome: "" };

function initialState() {
  return {
    searchData: searchData,
    resultSearch: [],
    columnsDefinition: columnsDefinition,
    data: defaultData,
    formDisabled: true,
    usuarios: [],
    dataConsultor: defaultDataConsultor,
    columnsDefinitionConsultores: columnsDefinitionConsultores,
    consultoresJornada: [],
  };
}

const JornadaTrabalhoProvider = ({ children }) => {
  const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(
    AppContext
  );
  const [values, setValues] = useState(initialState());
  let history = useHistory();

  async function loadDependences() {

    const [permissoesPaginaAux,
      responseListarUsuaro
    ] = await Promise.all([
      validarPermissoesPagina(),
      ListarUsuario({})
    ]);

    let usuarios = [];

    if (!responseListarUsuaro.success) {
      showMessage("Erro", "Erro Inesperado ao carregar dados basicos.");
    } else {
      usuarios = await responseListarUsuaro.usuarios;
    }

    return { permissoesPagina: permissoesPaginaAux, usuarios: usuarios };
  }

  const resetScreen = () => {
    showLoading();
    async function carregarDependencias() {
      const dependencias = await loadDependences();
      setTituloTela(tituloTela);
      setValues({
        ...initialState(),
        permissoesPagina: await dependencias.permissoesPagina,
        usuarios: await dependencias.usuarios,
      });
      hideLoading();
    }
    carregarDependencias();
  };

  const searchHandler = async () => {
    showLoading();
    const responseListarJornada = await ListarJornadaTrabalho(
      values.searchData
    );

    if (!responseListarJornada.success) {
      showMessage("Erro", "Erro Inesperado durante a pesquisa.");
      hideLoading();
      return;
    }

    let resultSearchData = await responseListarJornada.jornadasTrabalho;

    resultSearchData.map((data) => {
      data.Id = data.jornadaTrabalhoId;
      data.descricaoDias = retornarDescricaoJornada(data.dias);
      return data;
    });

    setValues({
      ...values,
      resultSearch: resultSearchData,
    });

    if (resultSearchData.length === 0) {
      showMessage("Alerta", "Nenhum registro encontrado.");
    }
    hideLoading();
  };

  const removeHandler = async (id) => {
    showLoading();

    const responseRemover = await RemoverJornadaTrabalho(id);

    if (!responseRemover.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro durante a exclusão: ${responseRemover.message}`
      );
      return;
    }
    searchHandler();
    hideLoading();
    showMessage("Sucesso", "Registro excluido.");
  };

  const getDetailHandler = async (id) => {
    showLoading();
    const dependencias = await loadDependences();

    let usuariosBase = await dependencias.usuarios;

    if (id > 0) {
      const responseDetalhes = await DetalhesJornadaTrabalho(id);
      const {
        success,
        jornadaTrabalho,
        consultores,
        dias,
      } = await responseDetalhes;

      if (!success) {
        showMessage("Erro", "Erro ao buscar os detalhes do registro.");
        setValues({
          ...values,
          data: defaultData,
          permissoesPagina: await dependencias.permissoesPagina,
          usuarios: [],
        });
      }

      let consultoresJornada = consultores;
      consultoresJornada.map((consultorJornada) => {
        consultorJornada.id = consultorJornada.usuarioId;
        consultorJornada.usuarioNome = usuariosBase.find(
          (consultor) => consultor.usuarioId === consultorJornada.usuarioId
        ).usuarioNome;
        return consultorJornada;
      });
      let descricaoDias = retornarDescricaoJornada(dias);
      setValues({
        ...values,
        data: { ...jornadaTrabalho, consultores, dias, descricaoDias },
        permissoesPagina: await dependencias.permissoesPagina,
        usuarios: usuariosBase,
        consultoresJornada: consultoresJornada,
      });
    } else {
      setValues({
        ...values,
        data: {
          ...defaultData,
          jornadaTrabalhoId: 0,
        },
        formDisabled: false,
        permissoesPagina: await dependencias.permissoesPagina,
        usuarios: usuariosBase,
        consultoresJornada: [],
      });
    }
    hideLoading();
  };

  const createData = async () => {
    showLoading();
    const responseIncluir = await IncluirJornadaTrabalho(values.data);
    if (!responseIncluir.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao criar o registro: ${responseIncluir.message}`
      );
      responseIncluir.errors.map((erro) =>
        showMessage("Erro", erro.errorMessage)
      );

      return;
    }

    resetScreen();
    hideLoading();
    showMessage("Sucesso", "Registro criado.");
    history.goBack();
  };

  const editHandler = () => {
    setValues({
      ...values,
      formDisabled: false,
    });
  };

  const updateData = async () => {
    showLoading();
    const responseAtualizar = await AtualizarJornadaTrabalho(values.data);
    if (!responseAtualizar.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao atualizar o registro: ${responseAtualizar.message}`
      );
      responseAtualizar.errors.map((erro) =>
        showMessage("Erro", erro.errorMessage)
      );

      return;
    }
    resetScreen();
    hideLoading();
    showMessage("Sucesso", "Registro atualizado.");
    history.goBack();
  };

  const saveHandler = async () => {
    if (values.data.jornadaTrabalhoId === 0) {
      await createData();
    } else {
      await updateData();
    }
  };

  const cancelHandler = () => {
    setValues({
      ...values,
      data: defaultData,
      formDisabled: true,
    });
  };

  const adionarConsultor = () => {
    let supervisorJaAdiocionado = values.consultoresJornada.find(
      // eslint-disable-next-line eqeqeq
      (consultor) => consultor.usuarioId == values.dataConsultor.id
    );

    if (supervisorJaAdiocionado) {
      showMessage("Alerta", "Consultor já incluído.");
      return;
    }

    var consultoresJornadaNew = values.consultoresJornada;
    consultoresJornadaNew.push(values.dataConsultor);
    setValues({
      ...values,
      dataConsultor: defaultDataConsultor,
      consultoresJornada: consultoresJornadaNew,
      data: {
        ...values.data,
        consultores: consultoresJornadaNew,
      },
    });
  };

  const removerConsultor = (id) => {
    var consultoresJornadaNew = values.consultoresJornada;

    removeItemFromArray(
      consultoresJornadaNew,
      consultoresJornadaNew.find((consultor) => consultor.id === id)
    );

    setValues({
      ...values,
      dataConsultor: defaultDataConsultor,
      consultoresJornada: consultoresJornadaNew,
      data: {
        ...values.data,
        consultores: consultoresJornadaNew,
      },
    });
  };

  return (
    <JornadaTrabalhoContext.Provider
      value={{
        values,
        setValues,
        resetScreen,
        searchHandler,
        cancelHandler,
        removeHandler,
        saveHandler,
        getDetailHandler,
        editHandler,
        adionarConsultor,
        removerConsultor,
      }}
    >
      {children}
    </JornadaTrabalhoContext.Provider>
  );
};

export default JornadaTrabalhoProvider;
