import React, { Fragment, useContext } from 'react';
import FormularioContato from '../FormularioContato/FormularioContato';
import FormularioCotacaoPedida from '../FormularioCotacaoPedida/FormularioCotacaoPedida';
import FormularioNegociacao from '../FormularioNegociacao/FormularioNegociacao';
import { validarCpf, validarCnpj } from '../../../Utils/globalFunctions';
import AtalhosTelefone from "../../Lead/AtalhosTelefone/AtalhosTelefone";

const Formulario = ({ context }) => {
	const { atendimentoValues, setAtendimentoValues, showMessage } = useContext(context);

	function onChangeInput(event) {
		const { name, value } = event.target;
		setAtendimentoValues({
			...atendimentoValues,
			dadosAtendimento: {
				...atendimentoValues.dadosAtendimento,
				lead: {
					...atendimentoValues.dadosAtendimento.lead,
					[name]: value
				}
			}
		});
	}
	function onChangeCheck(event) {
		const { checked } = event.target;
		setAtendimentoValues({
			...atendimentoValues,
			dadosAtendimento: {
				...atendimentoValues.dadosAtendimento,
				lead: {
					...atendimentoValues.dadosAtendimento.lead,
					leadPreCotacao: checked
				}
			}
		});
	}
	function onChangeInputCpfCnpj(event) {
		const { value } = event.target;
		var numeroDocumento = value;
		if (numeroDocumento == null) numeroDocumento = '';

		numeroDocumento = numeroDocumento.replace(/[/.-]/g, '');
		setAtendimentoValues({
			...atendimentoValues,
			dadosAtendimento: {
				...atendimentoValues.dadosAtendimento,
				lead: {
					...atendimentoValues.dadosAtendimento.lead,
					leadNumDocumento: numeroDocumento
				}
			}
		});
	}

	function validarDocumentoDigitado(event) {

		const { value } = event.target;
		var numeroDocumento = value;
		if (numeroDocumento == null) numeroDocumento = '';

		numeroDocumento = numeroDocumento.replace(/[.-]/g, '');

		if (numeroDocumento.length !== 11 && numeroDocumento.length !== 14) {
			showMessage('erro', 'Documento digitado esta no formato inválido.');
			setAtendimentoValues({
				...atendimentoValues,
				dadosAtendimento: {
					...atendimentoValues.dadosAtendimento,
					lead: {
						...atendimentoValues.dadosAtendimento.lead,
						leadNumDocumento: ''
					}
				}
			});
			return;
		}

		if (numeroDocumento.length === 11) {
			if (!validarCpf(numeroDocumento)) {
				showMessage('erro', 'Cpf Inválido.');
				setAtendimentoValues({
					...atendimentoValues,
					dadosAtendimento: {
						...atendimentoValues.dadosAtendimento,
						lead: {
							...atendimentoValues.dadosAtendimento.lead,
							leadNumDocumento: ''
						}
					}
				});
				return;
			}
		}
		if (numeroDocumento.length === 14) {
			if (!validarCnpj(numeroDocumento)) {
				showMessage('erro', 'Cnpj Inválido.');
				setAtendimentoValues({
					...atendimentoValues,
					dadosAtendimento: {
						...atendimentoValues.dadosAtendimento,
						lead: {
							...atendimentoValues.dadosAtendimento.lead,
							leadNumDocumento: ''
						}
					}
				});
				return;
			}
		}
	}

	function onChangeInputTipoCotacao(event) {
		const { name, value } = event.target;
		setAtendimentoValues({
			...atendimentoValues,
			dadosAtendimento: {
				...atendimentoValues.dadosAtendimento,
				[name]: value
			}
		});
	}
	return (
		<Fragment>
			<div className="default-title">Formulário</div>
			<div className="card shadow-sm mb-4">
				<div className="row align-items-end">
					<div className="col-12 col-md-8 form-group">
						<label>
							Nome Completo<span className="campoObratorio">*</span>
						</label>
						<input
							className="form-control"
							type="text"
							name="leadNome"
							value={atendimentoValues.dadosAtendimento.lead.leadNome}
							onChange={onChangeInput}
						/>
					</div>
					<div className="col-12 col-md-4 form-group">
						<label>
							CPF/CNPJ<span className="campoObratorio">*</span>
						</label>
						<input
							className="form-control"
							type="text"
							name="leadNumDocumento"
							value={atendimentoValues.dadosAtendimento.lead.leadNumDocumento}
							onChange={onChangeInputCpfCnpj}
							onBlur={validarDocumentoDigitado}
						/>
					</div>
					<div className="col-12 col-md-6 form-group">
						<label>E-mail</label>
						<input
							className="form-control"
							type="email"
							name="leadEmail"
							value={atendimentoValues.dadosAtendimento.lead.leadEmail}
							onChange={onChangeInput}
						/>
					</div>
					<div className="col-12 col-md-3 form-group">
						<label>Data Primeiro Contato</label>
						<input
							className="form-control"
							type="date"
							name="leadDataPrimeiroContato"
							value={atendimentoValues.dadosAtendimento.lead.leadDataPrimeiroContato}
							onChange={onChangeInput}
						/>
					</div>
					<div className="col-12 col-md-3 form-group">
						<label>Hora Primeiro Contato</label>
						<input
							className="form-control"
							type="time"
							name="leadHoraPrimeiroContato"
							value={atendimentoValues.dadosAtendimento.lead.leadHoraPrimeiroContato}
							onChange={onChangeInput}
						/>
					</div>
					<div className="col-12 col-md-12" />
					<div className="col-12 col-md-2 form-group">
						<label>DDD</label>
						<input
							className="form-control"
							type="number"
							name="leadDddTelefone"
							value={atendimentoValues.dadosAtendimento.lead.leadDddTelefone}
							onChange={onChangeInput}
						/>
					</div>
					<div className="col-12 col-md-4 form-group">
						<label>Telefone Fixo</label>
						<div style={{ display: "flex" }}>
							<input
								className="form-control"
								type="text"
								name="leadNumTelefone"
								value={atendimentoValues.dadosAtendimento.lead.leadNumTelefone}
								onChange={onChangeInput}
							/>
							<AtalhosTelefone id="foneFixo" leadId={atendimentoValues.dadosAtendimento.lead.leadId} texto="+" numeroTelefoneEntrada={`${atendimentoValues.dadosAtendimento.lead.leadDddTelefone}${atendimentoValues.dadosAtendimento.lead.leadNumTelefone}`} />
						</div>
					</div>
					<div className="col-12 col-md-2 form-group">
						<label>DDD</label>
						<input
							className="form-control"
							type="number"
							name="leadDddCelular"
							value={atendimentoValues.dadosAtendimento.lead.leadDddCelular}
							onChange={onChangeInput}
						/>
					</div>
					<div className="col-12 col-md-4 form-group">
						<label>Celular</label>
						<div style={{ display: "flex" }}>
							<input
								className="form-control"
								type="text"
								name="leadNumCelular"
								value={atendimentoValues.dadosAtendimento.lead.leadNumCelular}
								onChange={onChangeInput}
							/>
							<AtalhosTelefone id="foneMovel" leadId={atendimentoValues.dadosAtendimento.lead.leadId} texto="+" numeroTelefoneEntrada={`${atendimentoValues.dadosAtendimento.lead.leadDddCelular}${atendimentoValues.dadosAtendimento.lead.leadNumCelular}`} />
						</div>
					</div>
					<div className="col-12 col-md-3 form-group">
						<label>Mês de Produção</label>
						<input
							className="form-control mesano"
							type="text"
							name="leadMesAnoProducao"
							value={atendimentoValues.dadosAtendimento.lead.leadMesAnoProducao}
							onChange={onChangeInput}
						/>
					</div>
					<div className="col-12 col-md-4 form-group">
						<label>Tipo Negócio<span className="campoObratorio">*</span></label>
						<select
							className="form-control"
							name="leadTipoNegocio"
							value={atendimentoValues.dadosAtendimento.lead.leadTipoNegocio}
							onChange={onChangeInput}
						>
							<option className="disabled" value="0" />
							{atendimentoValues.leadTiposNegocio.map((leadTipoNegocio) => (
								<option key={leadTipoNegocio.codigo} value={leadTipoNegocio.codigo}>
									{leadTipoNegocio.descricao}
								</option>
							))}
						</select>
					</div>
					<div className="col-12 col-md-4 form-group">
						<div className="custom-control custom-switch float-right">
							<input
								checked={atendimentoValues.dadosAtendimento.lead.leadPreCotacao}
								type="checkbox"
								className="custom-control-input"
								id="ckLeadPreCotacao"
								onChange={onChangeCheck}
							/>
							<label className="custom-control-label" htmlFor="ckLeadPreCotacao">
								Pré cotação?
							</label>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<ul className="nav nav-tabs" id="myTab" role="tablist">
							<li className="nav-item" role="presentation">
								<a
									className="nav-link active"
									id="contato-tab"
									data-toggle="tab"
									href="#contato"
									role="tab"
									aria-controls="contato"
									aria-selected="true"
								>
									Contato
								</a>
							</li>
							<li className="nav-item" role="presentation">
								<a
									className="nav-link"
									id="cotacao-tab"
									data-toggle="tab"
									href="#cotacao"
									role="tab"
									aria-controls="cotacao"
									aria-selected="false"
								>
									Cotação
								</a>
							</li>
						</ul>
						<div className="tab-content" id="myTabContent">
							<div
								className="tab-pane fade show active"
								id="contato"
								role="tabpanel"
								aria-labelledby="contato-tab"
							>
								<FormularioContato context={context} />
							</div>
							<div className="tab-pane fade" id="cotacao" role="tabpanel" aria-labelledby="cotacao-tab">
								<div className="row mt-3">
									<div className="col-12 col-md-4 ml-1 form-group">
										<label>Selecione</label>
										<select
											className="form-control"
											name="tipoAtendimento"
											onChange={onChangeInputTipoCotacao}
											value={atendimentoValues.dadosAtendimento.tipoAtendimento}
										>
											<option
												className="disabled"
												value={atendimentoValues.tiposAtendimentoEnum.contato}
											/>
											<option value={atendimentoValues.tiposAtendimentoEnum.perdido}>
												Perdido
											</option>
											<option value={atendimentoValues.tiposAtendimentoEnum.negociacao}>
												Negociação
											</option>
										</select>
									</div>
									<div className="col-12 col-md-12">
										{atendimentoValues.dadosAtendimento.tipoAtendimento ===
											atendimentoValues.tiposAtendimentoEnum.perdido ? (
											<FormularioCotacaoPedida context={context} />
										) : atendimentoValues.dadosAtendimento.tipoAtendimento ===
											atendimentoValues.tiposAtendimentoEnum.negociacao ? (
											<FormularioNegociacao context={context} />
										) : (
											<div className="alert alert-secondary">Escolha um processo de cotação</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Formulario;
