import React from "react";
import { Link, useRouteMatch } from "react-router-dom";

const images = require.context("../../../assets/img/icones/pages", true);
const iconeSearch = images("./search.png");
const Visualizar = ({ id }) => {
  let match = useRouteMatch();
  return (
    <Link to={`${match.url}/${id}`}>
      <button type="button" className="btn btn-info btn-sm">
        <img src={iconeSearch} alt="" />
      </button>
    </Link>
  );
};

export default Visualizar;
