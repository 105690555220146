import React, { useContext, Fragment, useEffect } from "react";
import { LojaVirtualContext } from "../LojaVirtualContext";
import Pesquisar from "../../../../../Components/Cadastro/Pesquisar/Pesquisar";
import TabelaResultados from "../../../../../Components/Cadastro/TabelaResultados/TabelaResultados";

const PesquisarLojaVirtual = () => {
  const { values, setValues, resetScreen } = useContext(LojaVirtualContext);

  useEffect(() => {
    resetScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      searchData: {
        ...values.searchData,
        [name]: value,
      },
    });
  }

  return (
    <Fragment>
      <Pesquisar context={LojaVirtualContext}>
        <div className="col-12 col-md-4">
          <label>Nome</label>
          <input
            className="form-control"
            type="text"
            name="lojaVirtualNome"
            value={values.searchData.lojaVirtualNome}
            onChange={onChangeInput}
          />
        </div>
      </Pesquisar>
      <TabelaResultados context={LojaVirtualContext} />
    </Fragment>
  );
};

export default PesquisarLojaVirtual;
