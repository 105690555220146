import React,{useEffect} from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRoute from "../../../../Routes/PrivateRoutes";
import BackOfficeAtendimentoProvider from "./BackOfficeAtendimentoContext";
import PesquisarBackOfficeAtendimento from "./Events/PesquisarBackOfficeAtendimento";
import FormularioBackOfficeAtendimento from "./Events/FormularioBackOfficeAtendimento";
import { LoadMask } from "../../../../Utils/mask";

const Index = () => {
  let match = useRouteMatch();
  useEffect(() => {
    LoadMask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.url]);
  return (
    <BackOfficeAtendimentoProvider>
      <div className="container-fluid pl-3 pt-3 pb-3">
        <Switch>
          <PrivateRoute
            exact
            path={`${match.url}`}
            component={PesquisarBackOfficeAtendimento}
          />
          <PrivateRoute
            path={`${match.url}/:id`}
            component={FormularioBackOfficeAtendimento}
          />
        </Switch>
      </div>
    </BackOfficeAtendimentoProvider>
  );
};

export default Index;
