import React, { Fragment } from "react";
import "./HistoricoAtendimento.css";
import TimeLine from "../../Historico/TimeLine/TimeLine";
const HistoricoAtendimento = ({ historicoAtendimento = [], titulo = "" }) => {
  return (
    <Fragment>
      <button
        type="button"
        className="btn btn-default ml-2 mr-2"
        data-toggle="modal"
        data-target="#historicoAtendimento"
        data-dismiss="modal"
      >
        Exibir Histórico Completo
      </button>

      <div
        className="modal fade ComponenteHistoricoAtendimento_ContainerScroll"
        id="historicoAtendimento"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="historicoAtendimentoTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalLeadLabel">
                {titulo}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body componenteHistoricoAtendimento_modalBody">
              <div className="row">
                <TimeLine atendimentos={historicoAtendimento} show="true" />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default HistoricoAtendimento;
