import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "TemplateWhatsAppBlip";
export const Listar = (templateWhatsAppBlip) =>
  RequestWebApi.get(`/${controller}/Listar`, {
    params: { ...templateWhatsAppBlip },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const templatesWhatsAppBlip = resultData;
      return { success, templatesWhatsAppBlip };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { templatesWhatsAppBlip: [] };
    });

export const Detalhes = (templateWhatsAppBlipId) =>
  RequestWebApi.get(`/${controller}/Detalhes`, {
    params: { templateWhatsAppBlipId: templateWhatsAppBlipId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const templateWhatsAppBlip = resultData;
      return { success, templateWhatsAppBlip };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, templateWhatsAppBlip: {} };
    });

export const Incluir = (templateWhatsAppBlip) =>
  RequestWebApi.post(`/${controller}/Incluir`, templateWhatsAppBlip)
    .then(function (response) {
      const { resultData, success } = response.data;
      const novoId = resultData;
      return { success, novoId };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Atualizar = (templateWhatsAppBlip) =>
  RequestWebApi.put(`/${controller}/Atualizar`, templateWhatsAppBlip)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (templateWhatsAppBlipId) =>
  RequestWebApi.delete(`/${controller}/Remover`, {
    params: { templateWhatsAppBlipId: templateWhatsAppBlipId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });
