import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "Catalogo";
export const Listar = (catalogo) =>
  RequestWebApi.get(`/${controller}/Listar`, {
    params: { ...catalogo },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const catalogos = resultData;
      return { success, catalogos };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { catalogos: [] };
    });
