import React, { Fragment } from "react";
import BackofficeRealizado from "./BackofficeRealizado";

const BackofficesRealizadosSemVenda = ({
  defineDetalhesObjeto = () => { },
  backoffices = [],
}) => {
  return (
    <Fragment>
      <div className="default-title">BackOffices Sem Venda</div>
      <div className="card shadow-sm mb-4">
        <div className="row align-items-center">
          {backoffices.map((backoffice) => {
            return <Fragment key={backoffice.backofficeId}>
              <BackofficeRealizado defineDetalhesObjeto={defineDetalhesObjeto} backoffice={backoffice} />
              <div className="col-12">
                <hr />
              </div>
            </Fragment>
          })}

        </div>
      </div>
    </Fragment>
  );
};

export default BackofficesRealizadosSemVenda;
