import React, { useContext, Fragment, useEffect } from "react";
import { PipeLineContext } from "../PipeLineContext";
import Pesquisar from "../../../../../Components/Cadastro/Pesquisar/Pesquisar";
import TabelaResultados from "../../../../../Components/Cadastro/TabelaResultados/TabelaResultados";

const PesquisarPipeLine = () => {
  const { values, setValues, resetScreen } = useContext(PipeLineContext);

  useEffect(() => {
    resetScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      searchData: {
        ...values.searchData,
        [name]: value,
      },
    });
  }

  return (
    <Fragment>
      <Pesquisar context={PipeLineContext}>
        <div className="col-12 col-md-3">
          <label>Descrição</label>
          <input
            className="form-control"
            type="text"
            name="pipeLineDescricao"
            value={values.searchData.pipeLineDescricao}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-3">
          <label>Consultor</label>
          <select
            className="form-control"
            name="usuarioId"
            value={values.searchData.usuarioId}
            onChange={onChangeInput}
          >
            <option className="disabled" value="0"></option>
            {values.usuarios.map((usuario) => (
              <option key={usuario.usuarioId} value={usuario.usuarioId}>
                {usuario.usuarioNome}
              </option>
            ))}
          </select>
        </div>
      </Pesquisar>
      <TabelaResultados context={PipeLineContext} />
    </Fragment>
  );
};

export default PesquisarPipeLine;
