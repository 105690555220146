import React, { Fragment, useContext } from "react";

import './PermissoesRelatoriosItem.css';

const PermissoesRelatoriosItem = ({
  id,
  nome,
  modulo,
  checked,
  checkItemChange = () => { },
  formDisabled = false
}) => {

  function onChangeCheckAll(event) {
    const { id, checked } = event.target;
    checkItemChange({ id, checked });
  }

  return (
    <Fragment>
      <li className="cd-accordion__item">
        <a
          className="cd-accordion__label cd-accordion__label--icon-img cd-accordion__label--icon-noimg"
          href="#0"
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-6 pl-10">
                <div className="form-check">
                  <input
                    disabled={formDisabled}
                    checked={checked}
                    className="form-check-input mult_Level_check_box"
                    type="checkbox"
                    id={`${modulo}_${id}`}
                    onChange={onChangeCheckAll}
                  />
                  <label
                    className="form-check-label text-white form-check-label-text"
                    htmlFor={`${modulo}_${id}`}
                  >
                    {nome}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </a>
      </li>
    </Fragment>
  );
};

export default PermissoesRelatoriosItem;
