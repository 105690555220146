import React, { useContext, Fragment, useEffect } from "react";
import { CampanhaContext } from "../CampanhaContext";
import Pesquisar from "../../../../../Components/Cadastro/Pesquisar/Pesquisar";
import TabelaResultados from "../../../../../Components/Cadastro/TabelaResultados/TabelaResultados";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCodeBranch } from "@fortawesome/free-solid-svg-icons";
import { Link, useRouteMatch } from "react-router-dom";

const PesquisarCampanha = () => {
  const { values, setValues, resetScreen } = useContext(CampanhaContext);
  let match = useRouteMatch();

  useEffect(() => {
    resetScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAditionalEventComponentList = (id) => {
    return [
      {
        id: 1,
        component: (
          <Link to={`${match.url}/${id}/Jornada`}>
            <button
              type="button"
              style={{ marginLeft: 5, paddingLeft: 8, paddingRight: 8 }}
              className="btn btn-default btn-sm "
            >
              <FontAwesomeIcon style={{ fontSize: 18 }} icon={faCodeBranch} />
            </button>
          </Link>
        ),
      },
    ];
  };

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      searchData: {
        ...values.searchData,
        [name]: value,
      },
    });
  }

  return (
    <Fragment>
      <Pesquisar context={CampanhaContext}>
        <div className="col-12 col-md-4">
          <label>Nome</label>
          <input
            className="form-control"
            type="text"
            name="campanhaNome"
            value={values.searchData.campanhaNome}
            onChange={onChangeInput}
          />
        </div>
      </Pesquisar>
      <TabelaResultados
        context={CampanhaContext}
        getAditionalEventComponentList={getAditionalEventComponentList}
      />
    </Fragment>
  );
};

export default PesquisarCampanha;
