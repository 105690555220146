import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "Seguradora";
export const Listar = (seguradora) =>
  RequestWebApi.get(`/${controller}/Listar`, {
    params: { ...seguradora },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const seguradoras = resultData;
      return { success, seguradoras };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { seguradoras: [] };
    });

export const Detalhes = (seguradoraId) =>
  RequestWebApi.get(`/${controller}/Detalhes`, {
    params: { seguradoraId: seguradoraId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const seguradora = resultData;
      return { success, seguradora };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, seguradora: {} };
    });

export const Incluir = (seguradora) =>
  RequestWebApi.post(`/${controller}/Incluir`, seguradora)
    .then(function (response) {
      const { resultData, success } = response.data;
      const novoGrupoUsuario = resultData;
      return { success, novoGrupoUsuario };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Atualizar = (seguradora) =>
  RequestWebApi.put(`/${controller}/Atualizar`, seguradora)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (seguradoraId) =>
  RequestWebApi.delete(`/${controller}/Remover`, {
    params: { seguradoraId: seguradoraId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });
