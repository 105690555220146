/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext } from "react";
import AppContext from "../../../../Store/AppContext";
import {
  Listar as ListarCampanha,
  Detalhes as DetalhesCampanha,
  Remover as RemoverCampanha,
  Incluir as IncluirCampanha,
  Atualizar as AtualizarCampanha,
} from "../../../../Services/Campanha";
import {
  Listar as ListarSegmentacaoPublico,
  ListarSegmentacaoPublicoCompleto,
} from "../../../../Services/SegmentacaoPublico";
import { Listar as ListarTemplateEmail } from "../../../../Services/TemplateEmail";
import { Listar as ListarTemplateSms } from "../../../../Services/TemplateSms";
import { Listar as ListarEquipe } from "../../../../Services/Equipe";
import { Listar as ListarProduto } from "../../../../Services/Produto";
import { ListarTipoEvento } from "../../../../Services/MotorEventos";
import { useHistory } from "react-router-dom";
import { validarPermissoesPagina } from "../../../../Utils/validarPermissoesPagina";

export const CampanhaContext = createContext();

const tituloTela = "Campanhas";

const defaultData = {
  campanhaId: 0,
  campanhaNome: "",
  campanhaDataInicio: "",
  campanhaDataFim: "",
  campanhaObservacao: "",
  campanhaStatus: "",
  segmentacoesPublico: [],
  prospectsRemovidos: [],
};

const searchData = {
  campanhaNome: "",
};

const columnsDefinition = [
  {
    text: "Id",
    dataField: "campanhaId",
    hidden: true,
  },
  {
    text: "Nome",
    dataField: "campanhaNome",
    headerStyle: { width: "450px" },
    sort: true,
  },
  {
    text: "Início",
    dataField: "campanhaDataInicio",
    headerStyle: { width: "100px" },
    sort: true,
  },
  {
    text: "Fim",
    dataField: "campanhaDataFim",
    headerStyle: { width: "100px" },
    sort: true,
  },
  {
    text: "Status",
    dataField: "campanhaStatus",
    headerStyle: { width: "80px" },
    sort: true,
    formatter: (cell, row) => {
      return cell === "A" ? (
        <span className="badge badge-success">Ativo</span>
      ) : (
        <span className="badge badge-danger">Inátivo</span>
      );
    },
  },
];

function initialState() {
  return {
    searchData: searchData,
    resultSearch: [],
    columnsDefinition: columnsDefinition,
    data: defaultData,
    formDisabled: true,
    segmetacoesPulicoData: [],
    baseProspectsItens: [],
    templatesEmail: [],
    templatesSms: [],
    equipes: [],
    produtos: [],
    tiposEventoSms: [],
    tiposEventoEmail: [],
  };
}

const CampanhaProvider = ({ children }) => {
  const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(
    AppContext
  );
  const [values, setValues] = useState(initialState());
  let history = useHistory();

  async function loadDependences() {
    const permissoesPagina = validarPermissoesPagina("Cadastros/Campanha");

    const responseListarTemplateEmail = await ListarTemplateEmail({});
    let templatesEmail = [];

    const responseListarTemplateSms = await ListarTemplateSms({});
    let templatesSms = [];

    const responseListarEquipe = await ListarEquipe({});
    let equipes = [];

    const responseListarProduto = await ListarProduto({});
    let produtos = [];

    const responseListarTipoEventoSms = await ListarTipoEvento("sms");
    let tiposEventoSms = [];

    const responseListarTipoEventoEmail = await ListarTipoEvento("email");
    let tiposEventoEmail = [];

    if (
      !responseListarTemplateEmail.success ||
      !responseListarTemplateSms.success ||
      !responseListarEquipe.success ||
      !responseListarProduto.success ||
      !responseListarTipoEventoSms.success ||
      !responseListarTipoEventoEmail.success
    ) {
      showMessage("Erro", "Erro Inesperado ao carregar dados basicos.");
    } else {
      templatesEmail = await responseListarTemplateEmail.templatesEmail;
      templatesSms = await responseListarTemplateSms.templatesSms;
      equipes = await responseListarEquipe.equipes;
      produtos = await responseListarProduto.produtos.filter((item) => item.produtoAtivo === 'A');
      tiposEventoSms = await responseListarTipoEventoSms.tiposEvento.filter(
        (x) => x.tipoEventoAtivo === "A"
      );
      tiposEventoEmail = await responseListarTipoEventoEmail.tiposEvento.filter(
        (x) => x.tipoEventoAtivo === "A"
      );
    }
    return {
      permissoesPagina,
      templatesEmail,
      templatesSms,
      equipes,
      produtos,
      tiposEventoSms,
      tiposEventoEmail,
    };
  }

  const resetScreen = () => {
    showLoading();
    async function carregarDependencias() {
      const dependencias = await loadDependences();
      setTituloTela(tituloTela);
      setValues({
        ...initialState(),
        permissoesPagina: await dependencias.permissoesPagina,
      });
      hideLoading();
    }
    carregarDependencias();
  };

  const searchHandler = async () => {
    showLoading();
    const responseListarCampanha = await ListarCampanha(values.searchData);

    if (!responseListarCampanha.success) {
      showMessage("Erro", "Erro Inesperado durante a pesquisa.");
      hideLoading();
      return;
    }

    const resultSearchData = await responseListarCampanha.campanhas;

    resultSearchData.map((data) => (data.Id = data.campanhaId));

    setValues({
      ...values,
      resultSearch: resultSearchData,
    });

    if (resultSearchData.length === 0) {
      showMessage("Alerta", "Nenhum registro encontrado.");
    }
    hideLoading();
  };

  const removeHandler = async (id) => {
    showLoading();
    const responseRemover = await RemoverCampanha(id);
    if (!responseRemover.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro durante a exclusão: ${responseRemover.message}`
      );
      return;
    }
    searchHandler();
    hideLoading();
    showMessage("Sucesso", "Registro excluido.");
  };

  const getDetailHandler = async (id, loadFlowChart = () => { }) => {
    showLoading();
    const dependencias = await loadDependences();

    if (id > 0) {
      const responseDetalhes = await DetalhesCampanha(id);

      let { success, campanha } = await responseDetalhes;

      if (!success) {
        showMessage("Erro", "Erro ao buscar os detalhes do registro.");
        setValues({
          ...values,
          data: defaultData,
          permissoesPagina: await dependencias.permissoesPagina,
        });
        return;
      }

      const { segmentacoesPublico, prospectsRemovidos } = campanha;

      let segmentacoesPublicoCompleto = await processarSegmentacao(
        segmentacoesPublico,
        prospectsRemovidos
      );

      let segmentacoesPublicoBloqueados = [];
      if (segmentacoesPublicoCompleto) {
        segmentacoesPublicoBloqueados = segmentacoesPublicoCompleto.map(
          (item) => {
            return item.segmentacaoPublicoId;
          }
        );
      }

      setValues({
        ...values,
        data: {
          ...campanha,
          segmentacoesPublico: segmentacoesPublicoCompleto
            ? segmentacoesPublicoCompleto
            : [],
        },
        permissoesPagina: await dependencias.permissoesPagina,
        templatesEmail: await dependencias.templatesEmail,
        templatesSms: await dependencias.templatesSms,
        equipes: await dependencias.equipes,
        produtos: await dependencias.produtos,
        tiposEventoSms: await dependencias.tiposEventoSms,
        tiposEventoEmail: await dependencias.tiposEventoEmail,
        segmentacoesPublicoBloqueados: segmentacoesPublicoBloqueados,
      });

      loadFlowChart(campanha.estruturaJornada);
    } else {
      setValues({
        ...values,
        data: {
          ...defaultData,
          campanhaId: 0,
        },
        permissoesPagina: await dependencias.permissoesPagina,
        templatesEmail: await dependencias.templatesEmail,
        templatesSms: await dependencias.templatesSms,
        equipes: await dependencias.equipes,
        produtos: await dependencias.produtos,
        tiposEventoSms: await dependencias.tiposEventoSms,
        tiposEventoEmail: await dependencias.tiposEventoEmail,
        formDisabled: false,
      });
    }
    hideLoading();
  };

  const createData = async () => {
    showLoading();
    const responseIncluir = await IncluirCampanha(values.data);
    if (!responseIncluir.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao criar o registro: ${responseIncluir.message}`
      );
      responseIncluir.errors.map((erro) =>
        showMessage("Erro", erro.errorMessage)
      );
      return;
    }

    resetScreen();
    hideLoading();
    showMessage("Sucesso", "Registro criado.");
    history.goBack();
  };

  const editHandler = () => {
    setValues({
      ...values,
      formDisabled: false,
    });
  };

  const updateData = async () => {
    showLoading();
    const responseAtualizar = await AtualizarCampanha(values.data);
    if (!responseAtualizar.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao atualizar o registro: ${responseAtualizar.message}`
      );
      return;
    }
    resetScreen();
    hideLoading();
    showMessage("Sucesso", "Registro atualizado.");
    history.goBack();
  };

  const saveHandler = async () => {
    if (values.data.campanhaId === 0) {
      await createData();
    } else {
      await updateData();
    }
  };

  const cancelHandler = () => {
    setValues({
      ...values,
      data: defaultData,
      formDisabled: true,
    });
  };

  const buscarSegmentacao = async (searchData) => {
    showLoading();
    const responseListarSegmentacaoPublico = await ListarSegmentacaoPublico(
      searchData
    );

    if (!responseListarSegmentacaoPublico.success) {
      showMessage("Erro", "Erro Inesperado durante a pesquisa.");
      hideLoading();
      return;
    }

    const resultSearchData = await responseListarSegmentacaoPublico.segmentacaoPublicos.filter(
      (segmentacao) => segmentacao.segmentacaoPublicoStatus === "A"
    );

    resultSearchData.map((data) => (data.Id = data.campanhaId));

    if (resultSearchData.length === 0) {
      showMessage("Alerta", "Nenhum registro encontrado.");
    }

    setValues({
      ...values,
      segmetacoesPulicoData: resultSearchData,
    });

    hideLoading();
  };

  const processarTotalizadoresSegmentacao = (
    segmentacao,
    baseProspectsItensRemovidos
  ) => {
    let baseProspectsItens = segmentacao.baseProspectsItens;
    let baseProspectsItensRemovidosCount = 0;
    for (var i = 0; i < baseProspectsItensRemovidos.length; i++) {
      let itemRemovido = baseProspectsItens.find(
        // eslint-disable-next-line no-loop-func
        (item) => item.baseProspectItensId === baseProspectsItensRemovidos[i]
      );
      if (itemRemovido) baseProspectsItensRemovidosCount++;
    }

    return {
      ...segmentacao,
      totalPublicoRemovido: baseProspectsItensRemovidosCount,
    };
  };

  const processarSegmentacao = async (
    segmentacoes,
    baseProspectsItensRemovidos
  ) => {
    if (segmentacoes.length === 0) return;

    const responseListarSegmentacaoPublicoCompleto = await ListarSegmentacaoPublicoCompleto(
      segmentacoes.map((segmentacao) => {
        return segmentacao.segmentacaoPublicoId;
      })
    );

    if (!responseListarSegmentacaoPublicoCompleto.success) {
      showMessage("Erro", "Erro Inesperado durante a pesquisa.");
      hideLoading();
      return;
    }

    const segmentacoesPublicoBase = await responseListarSegmentacaoPublicoCompleto.segmentacaoPublicos;

    let segmentacoesPublicoCampanha = [];

    segmentacoesPublicoBase.map((segmentacaoPublico) => {
      const segmentacaoPublicoCampanha = values.data.segmentacoesPublico.find(
        (segmentacoesPublicoCampanha) =>
          segmentacoesPublicoCampanha.segmentacaoPublicoId ===
          segmentacaoPublico.segmentacaoPublicoId
      );

      let novaSegmentacaoPublico = {
        ...segmentacaoPublico,
        totalPublico: segmentacaoPublico.baseProspectsItens.length,
        totalPublicoRemovido: 0,
        campanhaQuaisSegmentacaoDesativado: segmentacaoPublicoCampanha
          ? segmentacaoPublicoCampanha.campanhaQuaisSegmentacaoDesativado
          : false,
      };

      segmentacoesPublicoCampanha.push(
        processarTotalizadoresSegmentacao(
          novaSegmentacaoPublico,
          baseProspectsItensRemovidos
        )
      );

      return null;
    });

    return segmentacoesPublicoCampanha;
  };

  const adicionaSegmentacaoSegmentacao = async (segmentacoesId) => {
    if (segmentacoesId.length === 0) return;

    let segmentacoes = segmentacoesId.map((segmentacaoId) => {
      return { segmentacaoPublicoId: segmentacaoId };
    });

    let segmentacoesPublicoCampanha = await processarSegmentacao(
      segmentacoes,
      values.data.prospectsRemovidos
    );

    setValues({
      ...values,
      data: {
        ...values.data,
        segmentacoesPublico: segmentacoesPublicoCampanha,
      },
      segmetacoesPulicoData: [],
    });
  };

  const adicionaProspectsRemovidos = (selectedItens) => {
    let prospectsRemovidos = values.data.prospectsRemovidos;

    selectedItens.map((selectedItem) => {
      if (
        !prospectsRemovidos.find(
          (itemRemovido) => itemRemovido === selectedItem
        )
      )
        prospectsRemovidos.push(selectedItem);
      return null;
    });

    let segmentacoesPublico = values.data.segmentacoesPublico.map(
      (segmentacaoPublico) => {
        return processarTotalizadoresSegmentacao(
          segmentacaoPublico,
          prospectsRemovidos
        );
      }
    );

    setValues({
      ...values,
      data: {
        ...values.data,
        segmentacoesPublico,
        prospectsRemovidos: prospectsRemovidos,
      },
      baseProspectsItens: [],
    });
  };

  const buscarProspectSegmentacao = async (segmentacaoPublicoId) => {
    showLoading();

    let segmentacaoPublico = values.data.segmentacoesPublico.find(
      (segmentacaoPublico) =>
        segmentacaoPublico.segmentacaoPublicoId === segmentacaoPublicoId
    );

    setValues({
      ...values,
      baseProspectsItens: segmentacaoPublico.baseProspectsItens,
    });

    hideLoading();
  };

  return (
    <CampanhaContext.Provider
      value={{
        values,
        setValues,
        resetScreen,
        searchHandler,
        cancelHandler,
        removeHandler,
        saveHandler,
        getDetailHandler,
        editHandler,
        buscarSegmentacao,
        adicionaSegmentacaoSegmentacao,
        adicionaProspectsRemovidos,
        buscarProspectSegmentacao,
      }}
    >
      {children}
    </CampanhaContext.Provider>
  );
};

export default CampanhaProvider;
