import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "WhatsApp";


export const ListarHistoricoMensagens = (ddd, numeroTelefone) =>
  RequestWebApi.get(`/${controller}/ListarHistoricoMensagens`, {
    params: { ddd: ddd, numeroTelefone: numeroTelefone }
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const mensagens = resultData;
      return { success, mensagens };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, mensagens: null };
    });
