/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext } from "react";
import AppContext from "../../../../Store/AppContext";
import { ListarConsultoresOnline } from "../../../../Services/JornadaTrabalho";
import { Listar as ListarOrigens } from "../../../../Services/OrigemLead";
import { Listar as ListarCidade } from "../../../../Services/Cidade";
import { Listar as ListarProduto } from "../../../../Services/Produto";
import { Listar as ListarFornecedorLead } from "../../../../Services/FornecedorLead";
import { Listar as ListarLandingPage } from "../../../../Services/LandingPage";
import { Listar as ListarLojaVirtual } from '../../../../Services/LojaVirtual';
import { validarPermissoesPagina } from "../../../../Utils/validarPermissoesPagina";
import { retornaDescricaoTipoAtendimento } from "../../../../Utils/globalFunctions";
import {
  ListarLeadAutoNaoAtribuido,
  AtribuirLead,
  ListarClassificacaoLead,
} from "../../../../Services/Lead";
export const DistribuicaoLeadContext = createContext();

const tituloTela = "Distribuição de Leads";

const defaultData = {
  usuario: {},
  usuarioId: 0,
  leads: [],
};

const searchData = {
  cidadesSelecioandas: [],
  cidades: [],
  classificacoesSelecioandas: [],
  classificacoes: [],
  origemLead: {},
  origemLeadId: 0,
  usuarioId: 0,
  usuario: { value: 0, label: 'NENHUM' },
  listarSomenteRenovacao: false,
  produtos: [],
  produtosSelecionados: [],
  fornecedores: [],
  fornecedoresSelecionados: [],
  landingPages: [],
  lojasVirtual: [],
  landingPagesSelecionados: [],
  mesAno: '',
};
const statusTabela = (cell, row) => {
  let classe = "info";
  if (row.atendimentoTipo === "C") {
    classe = row.filaAtendimentoCorStatus ? row.filaAtendimentoCorStatus : "warning";
  } else if (row.atendimentoTipo === "N") {
    classe = "success";
  }
  return (
    <div className="d-flex ">

      {!row.leadIdFk && !row.leadIdRenovacao ? (
        <span
          data-toggle="tooltip"
          data-placement="top"
          title="Novo Lead"
          className={`badge-info rounded-pill pl-1 pr-1 mr-1`}
        >
          N
        </span>
      ) : null}


      {!row.leadIdFk && row.leadIdRenovacao && row.leadIdRenovacao > 0 ? (
        <span
          data-toggle="tooltip"
          data-placement="top"
          title="Renovação"
          className={`badge-default rounded-pill pl-1 pr-1 mr-1`}
        >
          R
        </span>
      ) : null}

      {!row.leadIdRenovacao && row.leadIdFk && row.leadIdFk > 0 ? (
        <span
          data-toggle="tooltip"
          data-placement="top"
          title="Relead"
          className={`badge-default rounded-pill pl-1 pr-1 mr-1`}
          style={{ backgroundColor: "#993399" }}
        >
          X
        </span>
      ) : null}


      <span className={`badge badge-${classe} pt-1`}>
        {retornaDescricaoTipoAtendimento(row.atendimentoTipo)}
      </span>
    </div>
  );
};
const columnsDefinition = [
  {
    text: "Id",
    dataField: "leadId",
    hidden: true,
  },
  {
    text: "Status",
    dataField: "atendimentoTipo",
    headerStyle: { width: "90px" },
    sort: true,
    formatter: statusTabela,
  },
  {
    text: "Nome",
    dataField: "leadNome",
    headerStyle: { width: "200px" },
    sort: true,
  },
  {
    text: "Entrada do Lead",
    dataField: "leadDataCadastro",
    headerStyle: { width: "170px" },
    sort: true,
  },
  {
    text: "Origem",
    dataField: "origemLeadNome",
    headerStyle: { width: "130px" },
    sort: true,
  },
  {
    text: "Mod. Carro",
    dataField: "modeloCarro",
    headerStyle: { width: "100px" },
    sort: true,
  },
];

function initialState() {
  return {
    searchData: searchData,
    resultSearch: [],
    columnsDefinition: columnsDefinition,
    data: defaultData,
    formDisabled: true,
    consultoresOnline: [],
    origensLead: [],
    cidades: [],
    classificacoes: [],
  };
}

const DistribuicaoLeadProvider = ({ children }) => {
  const { showLoading, hideLoading, showMessage, setTituloTela } =
    useContext(AppContext);
  const [distribuirLeadsValues, setDistribuirLeadsValues] = useState(
    initialState()
  );

  async function loadDependences() {
    let consultoresOnline = [];
    let origensLead = [];
    let cidades = [];
    let produtos = [];
    let fornecedores = [];
    let landingPages = [];
    let lojasVirtual = [];
    let classificacoes = [];

    const [
      permissoesPaginaAux,
      responseListarConsultoresOnline,
      responseListarOrigens,
      responseListarCidade,
      responseListarClassificacaoLead,
      responseListarProduto,
      responseListarFornecedorLead,
      responseListarLandingPage,
      responseListarLojaVirtual
    ] = await Promise.all([
      validarPermissoesPagina(),
      ListarConsultoresOnline(),
      ListarOrigens({}),
      ListarCidade(),
      ListarClassificacaoLead(),
      ListarProduto(),
      ListarFornecedorLead(),
      ListarLandingPage(),
      ListarLojaVirtual({})
    ]);

    if (
      !responseListarConsultoresOnline.success ||
      !responseListarOrigens.success ||
      !responseListarCidade.success ||
      !responseListarClassificacaoLead.success ||
      !responseListarProduto.success ||
      !responseListarFornecedorLead.success ||
      !responseListarLojaVirtual.success ||
      !responseListarLandingPage.success
    ) {
      showMessage("Erro", "Erro Inesperado ao carregar dados basicos.");
    } else {
      consultoresOnline =
        await responseListarConsultoresOnline.consultoresOnline.map(
          (consultorOnline) => {
            return {
              value: consultorOnline.usuarioId,
              label: `${consultorOnline.usuarioNome} - ${consultorOnline.totalLeadsEmAtendimento} leads`,
            };
          }
        );

      origensLead = await responseListarOrigens.origensLead.filter((item) => item.origemLeadAtivo === 'A').map(
        (origenLead) => {
          return {
            value: origenLead.origemLeadId,
            label: origenLead.origemLeadNome,
          };
        }
      );

      cidades = await responseListarCidade.cidades.map((cidade) => {
        return { value: cidade.cidadeId, label: cidade.cidadeNome };
      });

      classificacoes = responseListarClassificacaoLead.classificacoes.map(
        (classificacao) => {
          return {
            value: classificacao,
            label: classificacao,
          };
        }
      );

      produtos = responseListarProduto.produtos.filter((item) => item.produtoAtivo === 'A').map(
        (produto) => {
          return {
            value: produto.produtoId,
            label: produto.produtoNome,
          };
        }
      );

      fornecedores = responseListarFornecedorLead.fornecedoresLead.filter((item) => item.fornecedorLeadAtivo === 'A').map(
        (fornecedorLead) => {
          return {
            value: fornecedorLead.fornecedorLeadId,
            label: fornecedorLead.fornecedorLeadNome,
          };
        }
      );

      landingPages = responseListarLandingPage.landingPages.map(
        (landingPage) => {
          return {
            value: landingPage.landingPageId,
            label: landingPage.landingPageNome,
          };
        }
      );


      lojasVirtual = responseListarLojaVirtual.lojasVirtual.map(
        (lojaVirtual) => {
          return {
            value: lojaVirtual.lojaVirtualId,
            label: lojaVirtual.lojaVirtualNome,
          };
        }
      );

      lojasVirtual.unshift({ value: 0, label: "" });

    }

    return {
      permissoesPagina: permissoesPaginaAux,
      consultoresOnline,
      origensLead,
      cidades,
      classificacoes,
      produtos,
      fornecedores,
      landingPages,
      lojasVirtual
    };
  }

  const resetScreen = () => {
    showLoading();
    async function carregarDependencias() {
      const dependencias = await loadDependences();
      setTituloTela(tituloTela);
      setDistribuirLeadsValues({
        ...initialState(),
        permissoesPagina: await dependencias.permissoesPagina,
        consultoresOnline: await dependencias.consultoresOnline,
        origensLead: await dependencias.origensLead,
        cidades: await dependencias.cidades,
        classificacoes: await dependencias.classificacoes,
        produtos: await dependencias.produtos,
        fornecedores: await dependencias.fornecedores,
        landingPages: await dependencias.landingPages,
        lojasVirtual: await dependencias.lojasVirtual,
      });
      hideLoading();
    }
    carregarDependencias();
  };

  const searchHandler = async () => {
    showLoading();
    const responseListarLeadAutoNaoAtribuido = await ListarLeadAutoNaoAtribuido(
      distribuirLeadsValues.searchData
    );

    if (!responseListarLeadAutoNaoAtribuido.success) {
      showMessage("Erro", "Erro Inesperado durante a pesquisa.");
      responseListarLeadAutoNaoAtribuido.errors.map((erro) =>
        showMessage("Erro", erro.errorMessage)
      );
      hideLoading();
      return;
    }

    let resultSearchData =
      await responseListarLeadAutoNaoAtribuido.leadsAutoNaoAtribuido;



    setDistribuirLeadsValues({
      ...distribuirLeadsValues,
      resultSearch: resultSearchData,
      data: defaultData,
    });

    if (resultSearchData.length === 0) {
      showMessage("Alerta", "Nenhum registro encontrado.");
    }
    hideLoading();
  };

  const enviarLeads = async () => {
    showLoading();
    const responseAtribuirLead = await AtribuirLead(distribuirLeadsValues.data);
    if (!responseAtribuirLead.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao atualizar o registro: ${responseAtribuirLead.message}`
      );
      responseAtribuirLead.errors.map((erro) =>
        showMessage("Erro", erro.errorMessage)
      );

      return;
    }
    resetScreen();
    hideLoading();
    showMessage("Sucesso", "Lead(s) enviado(s).");
  };

  return (
    <DistribuicaoLeadContext.Provider
      value={{
        distribuirLeadsValues,
        setDistribuirLeadsValues,
        resetScreen,
        searchHandler,
        enviarLeads,
      }}
    >
      {children}
    </DistribuicaoLeadContext.Provider>
  );
};

export default DistribuicaoLeadProvider;
