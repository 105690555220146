import React, { useContext, useState } from "react";
import "./Anexos.css";
import { Fragment } from "react";
import DataTableAnexos from "./DataTableAnexos/DataTableAnexos";

function initialState() {
  return {
    arquivoId: "",
    nome: "",
    nomeArquivo: "",
    arquivo: "",
  };
}

const Anexos = ({ id, disabled = false, context }) => {
  const [anexosValues, setAnexosValues] = useState(initialState());
  const { uploadAnexo } = useContext(context);

  function onFileChange(event) {
    const { files } = event.target;
    let arquivo = files[0];
    if (arquivo) {
      setAnexosValues({
        ...anexosValues,
        arquivoId: arquivo.lastModified,
        nomeArquivo: arquivo.name,
        arquivo: arquivo,
      });
    }
  }

  function onChangeInput(event) {
    const { value } = event.target;
    setAnexosValues({
      ...anexosValues,
      nome: value,
    });
  }

  const adicionarArquivo = () => {
    uploadAnexo(anexosValues);
    setAnexosValues(initialState());
  };

  const formularioComErro = !anexosValues.nome || !anexosValues.arquivo;

  return (
    <Fragment>
      <button
        type="button"
        className="btn btn-success"
        data-toggle="modal"
        data-target={`#modalAnexosAtendimento${id}`}
        data-dismiss="modal"
      >
        + Adicionar
      </button>
      <div
        className="modal fade"
        id={`modalAnexosAtendimento${id}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby={`modalAnexosAtendimento${id}Label`}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id={`modalAnexosAtendimento${id}Label`}
              >
                Adicionar anexos
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body componenteAtendimentoAnexos_modalBody">
              <div className="row align-items-end">
                <div className="col-12 col-md-4">
                  <label>Nome</label>
                  <input
                    className="form-control"
                    type="text"
                    name="nome"
                    maxLength="20"
                    value={anexosValues.nome}
                    onChange={onChangeInput}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <label>Escolher arquivo</label>
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id={`customFile${id}`}
                      name="leadEmail"
                      onChange={onFileChange}
                    />
                    <label
                      className="custom-file-label selected"
                      htmlFor={`customFile${id}`}
                    >
                      {" "}
                      {anexosValues.nomeArquivo}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-2">
                  <button
                    type="button"
                    disabled={disabled || formularioComErro}
                    className="btn btn-default"
                    onClick={adicionarArquivo}
                  >
                    Adicionar
                  </button>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <DataTableAnexos disabled={disabled} context={context} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Anexos;
