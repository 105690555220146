import React, { Fragment } from "react";
import SinistroRealizada from "./SinistroRealizado";

const SinistrosRealizados = ({
  defineDetalhesObjeto = () => { },
  sinistros = [],
}) => {
  return (
    <Fragment>
      <div className="default-title">Sinistros</div>
      <div className="card shadow-sm mb-4">
        <div className="row align-items-center">
          {sinistros.map((sinistro) => {
            return <Fragment key={sinistro.sinistroId}>
              <SinistroRealizada defineDetalhesObjeto={defineDetalhesObjeto} sinistro={sinistro} />
              <div className="col-12">
                <hr />
              </div>
            </Fragment>
          })}

        </div>
      </div>
    </Fragment>
  );
};

export default SinistrosRealizados;
