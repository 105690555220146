/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext } from "react";
import AppContext from "../../../../Store/AppContext";
import { Listar as ListarLeads } from '../../../../Services/Lead';
import HistoricoLead from "../../../../Components/Lead/HistoricoLead/HistoricoLead";
import { validarPermissoesPagina } from "../../../../Utils/validarPermissoesPagina";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
export const HistoricoClienteContext = createContext();

const tituloTela = "Histórico de Cliente/Lead";

const searchData = {
  leadNome: '',
  leadNumDocumento: '',
  contato: ''
};

const columnsDefinition = [
  {
    text: 'Id',
    dataField: 'id',
    hidden: true
  },
  {
    text: "Histórico",
    dataField: "leadNome",
    headerStyle: { width: "50px" },
    sort: true,
    formatter: (cell, row) => {
      return (
        <HistoricoLead leadId={row.leadId} disabled={false} >
          <div style={{ width: "100%", textAlign: "center" }}>
            <button
              type="button"
              className="btn btn-default btn-sm ">
              <FontAwesomeIcon icon={faEye} />
            </button>
          </div>
        </HistoricoLead>
      );
    },
  },
  {
    text: 'Nome',
    dataField: 'leadNome',
    headerStyle: { width: '220px' },
    sort: true
  },
  {
    text: 'Documento',
    dataField: 'leadNumDocumento',
    headerStyle: { width: '70px' },
    sort: true
  },
  {
    text: 'Email',
    dataField: 'leadEmail',
    headerStyle: { width: '130px' },
    sort: true
  },
  {
    text: 'Telefone',
    dataField: 'leadNumCelular',
    headerStyle: { width: '70px' },
    sort: true
  }
];

function initialState() {
  return {
    searchData: searchData,
    resultSearch: [],
    columnsDefinition: columnsDefinition
  };
}

const HistoricoClienteProvider = ({ children }) => {
  const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(
    AppContext
  );
  const [values, setValues] = useState(initialState());

  async function loadDependences() {
    const permissoesPagina = validarPermissoesPagina();
    return { permissoesPagina };
  }

  const resetScreen = () => {
    showLoading();
    async function carregarDependencias() {
      const dependencias = await loadDependences();
      setTituloTela(tituloTela);
      setValues({
        ...initialState(),
        permissoesPagina: await dependencias.permissoesPagina,
      });
      hideLoading();
    }
    carregarDependencias();
  };


  const searchHandler = async () => {
    showLoading();
    const responseListarLeads = await ListarLeads(values.searchData);
    if (!responseListarLeads.success) {
      showMessage("Erro", "Erro Inesperado durante a pesquisa.");
      hideLoading();
      return;
    }
    let leads = responseListarLeads.leads;
    leads.map(x => x.id = x.vendaId);
    setValues({
      ...values,
      resultSearch: leads,
    });
    if (leads.length === 0) {
      showMessage("Alerta", "Nenhum registro encontrado.");
    }
    hideLoading();
  };





  return (
    <HistoricoClienteContext.Provider
      value={{
        values,
        setValues,
        resetScreen,
        searchHandler,
      }}
    >
      {children}
    </HistoricoClienteContext.Provider>
  );
};

export default HistoricoClienteProvider;
