import React, { useEffect, useState, useContext, Fragment } from 'react';
import './PagamentoStyleDefault.css';
import { MoipCreditCard } from 'moip-sdk-js';
import JSEncrypt from 'node-jsencrypt';
import AppContext from '../../Store/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import asseguroulogo from '../../assets/img/asseguroulogo.svg';
import lgassegurouwhite from '../../assets/img/lgassegurouwhite.svg';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { DetalhesPagamento, ProcessarPagamento, ObterChavePublicaWireCard } from '../../Services/Pagamento';
import Sucesso from './Sucesso';
import Erro from './Erro';

const detalhesPagamentoInitialState = {
	clienteNome: '',
	produtoNome: '',
	valor: ''
};

const initialState = {
	number: '',
	completeName: '',
	cvc: '',
	expirationMonth: '',
	expirationYear: '',
	hashCreditCard: ''
};

const Pagamento = ({ pagamentoHandler = () => {} }) => {
	const [ chavePublicaValues, SetChavePublicaValues ] = useState('');
	const { showLoading, hideLoading } = useContext(AppContext);
	const [ detalhesPagamentoValues, SetDetalhesPagamento ] = useState(detalhesPagamentoInitialState);
	const [ dadosCartaoValues, SetDadosCartaoValues ] = useState(initialState);
	const [ hashValues, SetHashValues ] = useState('');
	const getPagamentoHash = () => {
		const queryString = new URLSearchParams(window.location.search);
		return queryString.get('pagamento');
	};
	useEffect(() => {
		showLoading('Buscando Informações do pagamento.');
		ObterChavePublicaWireCard()
			.then((obterChavePublicaWireCardResult) => {
				if (obterChavePublicaWireCardResult.success) {
					SetChavePublicaValues(obterChavePublicaWireCardResult.chavePublica);
					DetalhesPagamento(getPagamentoHash()).then((detalhesPagamentoResult) => {
						if (!detalhesPagamentoResult.success) {
							pagamentoHandler(
								<Erro erroProcessamento={false} mensagem={detalhesPagamentoResult.message} />
							);
						} else {
							SetDetalhesPagamento({
								...detalhesPagamentoResult.detalhePagamento
							});
						}
					});
					hideLoading();
				} else {
					pagamentoHandler(
						<Erro erroProcessamento={false} mensagem={obterChavePublicaWireCardResult.message} />
					);
				}
				hideLoading();
			})
			.catch(() => {
				hideLoading();
				pagamentoHandler(
					<Erro erroProcessamento={false} mensagem={'Erro desconhecido ao obter os detalhes do pagamento!'} />
				);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const generateCardHash = () => {
		if (
			!dadosCartaoValues.number ||
			!dadosCartaoValues.cvc ||
			!dadosCartaoValues.expirationMonth ||
			!dadosCartaoValues.expirationYear
		) {
			SetHashValues('');
		}

		MoipCreditCard.setEncrypter(JSEncrypt, 'node')
			.setPubKey(chavePublicaValues)
			.setCreditCard({
				number: dadosCartaoValues.number,
				cvc: dadosCartaoValues.cvc,
				expirationMonth: dadosCartaoValues.expirationMonth,
				expirationYear: dadosCartaoValues.expirationYear
			})
			.hash()
			.then((hash) => {
				SetHashValues(hash);
			});
	};

	function onChangeInput(event) {
		const { name, value } = event.target;
		SetDadosCartaoValues({
			...dadosCartaoValues,
			[name]: value
		});
		generateCardHash();
	}

	const processarPagamento = () => {
		showLoading('Processando pagamento.');
		const pagamentoInfo = {
			hash: getPagamentoHash(),
			hashCartao: hashValues
		};

		ProcessarPagamento(pagamentoInfo)
			.then((processarPagamentoResult) => {
				hideLoading();
				if (!processarPagamentoResult.success) {
					pagamentoHandler(
						<Erro
							erroProcessamento={true}
							pagamentoHandler={pagamentoHandler}
							mensagem={processarPagamentoResult.message}
						/>
					);
				} else {
					pagamentoHandler(<Sucesso />);
				}
			})
			.catch(() => {
				hideLoading();
				pagamentoHandler(
					<Erro erroProcessamento={false} mensagem={'Erro desconhecido ao processar o pagamento!'} />
				);
			});
	};

	return (
		<Fragment>
			<div className="pagePagamentoCartao_header">
				<div className="pagePagamentoCartao_container">
					<div className="row align-items-center">
						<div className="col-6 col-md-6">
							<div className="logo">
								<img src={asseguroulogo} alt="" width="250" />
							</div>
						</div>
						<div className="col-6 col-md-6 text-right">
							<div className="pagePagamentoCartao_seguranca">
								<FontAwesomeIcon icon={faLock} />{' '}
								<span className="pagePagamentoCartao_texto">Ambiente seguro</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="pagePagamentoCartao_container">
				<div className="row">
					<div className="col-12 col-md-8">
						<div className="pagePagamentoCartao_section-title">
							Olá, <span>{detalhesPagamentoValues.clienteNome}</span>!
						</div>
						<div className="pagePagamentoCartao_subtitle-title">
							Você está adquirindo o produto: <span>{detalhesPagamentoValues.produtoNome}</span>
						</div>
					</div>
					<div className="col-12 col-md-4">
						<div className="pagePagamentoCartao_product-value">
							Total: <br />
							<sup>R$</sup>
							<span>{detalhesPagamentoValues.valor}</span>
						</div>
					</div>
				</div>
			</div>

			<div className="break" />

			<div id="root" className="pagePagamentoCartao_root">
				<div id="card">
					<div id="card-bottom" />
					<div id="card-top">
						<div className="logo">
							<img src={lgassegurouwhite} alt="" />
						</div>
						<div className="card-number">{dadosCartaoValues.number}</div>
						<div className="row-container">
							<div className="card-holder uppercase">
								<span>{dadosCartaoValues.completeName}</span>
							</div>
							<div className="expiry">
								<span>Vald.</span>
								<span>
									<span className="expiry-month">{dadosCartaoValues.expirationMonth}</span>
									/
									<span className="expiry-year">{dadosCartaoValues.expirationYear}</span>
								</span>
							</div>
						</div>
					</div>
				</div>
				<form id="form" autoComplete="off">
					<header>
						<h2 style={{ fontFamily: [ 'Montserrat', 'sans-serif' ] }}>Dados do pagamento</h2>
					</header>
					<fieldset className="card-number">
						<legend>Número do cartão</legend>
						<span>
							<input
								id="card-number"
								className="inputFormField cartao_credito"
								maxLength="19"
								name="number"
								value={dadosCartaoValues.number}
								onChange={onChangeInput}
							/>
						</span>
					</fieldset>
					<fieldset className="card-holder">
						<legend>Nome completo</legend>
						<input
							type="text"
							id="card-holder"
							className="inputFormField uppercase"
							name="completeName"
							value={dadosCartaoValues.completeName}
							onChange={onChangeInput}
						/>
					</fieldset>
					<div className="row-container">
						<fieldset className="expiry-month">
							<legend>Vencimento</legend>
							<select
								className="inputFormField"
								name="expirationMonth"
								value={dadosCartaoValues.expirationMonth}
								onChange={onChangeInput}
							>
								<option />
								<option value="01">01</option>
								<option value="02">02</option>
								<option value="03">03</option>
								<option value="04">04</option>
								<option value="05">05</option>
								<option value="06">06</option>
								<option value="07">07</option>
								<option value="08">08</option>
								<option value="09">09</option>
								<option value="10">10</option>
								<option value="11">11</option>
								<option value="12">12</option>
							</select>
						</fieldset>
						<fieldset className="expiry-year">
							<legend>&nbsp;</legend>
							<select
								id="card-expiration-year"
								className="inputFormField"
								name="expirationYear"
								value={dadosCartaoValues.expirationYear}
								onChange={onChangeInput}
							>
								<option />
								<option value="20">2020</option>
								<option value="21">2021</option>
								<option value="22">2022</option>
								<option value="23">2023</option>
								<option value="24">2024</option>
								<option value="25">2025</option>
								<option value="26">2026</option>
								<option value="27">2027</option>
								<option value="28">2028</option>
								<option value="29">2029</option>
								<option value="30">2030</option>
							</select>
						</fieldset>
						<fieldset className="cvc">
							<legend>CVV</legend>
							<input
								type="text"
								className="inputFormField"
								maxLength="3"
								name="cvc"
								value={dadosCartaoValues.cvc}
								onChange={onChangeInput}
							/>
						</fieldset>
					</div>

					<button
						type="button"
						disabled={!hashValues}
						className="btn btn-default"
						onClick={() => processarPagamento()}
					>
						Pagar
					</button>
				</form>
			</div>
		</Fragment>
	);
};

export default Pagamento;
