import React, { Fragment, useContext, useEffect } from 'react';
import './FormularioTransmissao.css';
import GroupBox from './../../../../../GroupBox/GroupBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { formatarMoeda } from '../../../../../../Utils/globalFunctions';
import $ from 'jquery';

const FormularioTransmissao = ({ context, cotacoes, cotacaoSelecionada = null, carregarDadosDoLead = () => {} }) => {
	useEffect(() => {
		$(function() {
			$('[data-toggle="tooltip"]').tooltip();
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { atendimentoValues, setAtendimentoValues } = useContext(context);

	function onChangeEvent(event) {
		const { name, value } = event.target;
		setAtendimentoValues({
			...atendimentoValues,
			dadosAtendimento: {
				...atendimentoValues.dadosAtendimento,
				autoRastreador: {
					...atendimentoValues.dadosAtendimento.autoRastreador,
					[name]: value
				}
			}
		});
	}
	return (
		<Fragment>
			<div className="row text-center border-bottom componenteModalCotacaoAutoRastreador_formularioTransmissao_detalhes ">
				<div className="col-4 col-md-3  ">
					{!cotacaoSelecionada ? '' : `Seguradora : ${cotacaoSelecionada.seguradora}`}
				</div>
				<div className="col-8 col-md-5">
					{!cotacaoSelecionada ? '' : `Cobertura : ${cotacaoSelecionada.nomeCobertura}`}
				</div>
				<div className="col-8 col-md-2">
					{!cotacaoSelecionada ? '' : `Valor : ${formatarMoeda(cotacaoSelecionada.comissaoAplicada.valorPremio)}`}
				</div>
				<div className="col-8 col-md-2">
					{!cotacaoSelecionada ? '' : `Parcelamento: Até ${cotacaoSelecionada.parcelamentoMaximo}x`}
				</div>
			</div>
			<div className="row">
				<GroupBox className="col-12 col-md-12" titulo="Contato">
					<div className="col-12 col-md-1">
						<div className="form-group">
							<button
								type="button"
								className="btn btn-info"
								style={{ width: 40 }}
								data-toggle="tooltip"
								data-placement="top"
								title="Copiar dados do Lead"
								onClick={() => {
									carregarDadosDoLead();
								}}
							>
								<FontAwesomeIcon style={{ fontSize: 16 }} icon={faCopy} />
							</button>
						</div>
					</div>
					<div className="col-12 col-md-6 form-group">
						<label>
							E-mail<span className="campoObratorio">*</span>
						</label>
						<input
							className="form-control"
							type="email"
							name="leadAutoRastreadorEmail"
							value={atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorEmail}
							onChange={onChangeEvent}
						/>
					</div>
					<div className="col-12 col-md-2 form-group">
						<label>
							DDD<span className="campoObratorio">*</span>
						</label>
						<input
							className="form-control"
							type="number"
							name="leadAutoRastreadorCelularDdd"
							value={atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorCelularDdd}
							onChange={onChangeEvent}
						/>
					</div>
					<div className="col-12 col-md-3 form-group">
						<label>
							Celular<span className="campoObratorio">*</span>
						</label>
						<input
							className="form-control"
							type="text"
							name="leadAutoRastreadorCelularNumero"
							value={atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorCelularNumero}
							onChange={onChangeEvent}
						/>
					</div>
				</GroupBox>
				<GroupBox className="col-12 col-md-12" titulo="Dados do Veículo">
					<div className="col-12 col-md-2">
						<div className="form-group">
							<label>
								Placa<span className="campoObratorio">*</span>
							</label>
							<input
								type="text"
								maxLength={15}
								className="form-control"
								name="leadAutoRastreadorPlaca"
								value={atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorPlaca}
								onChange={onChangeEvent}
							/>
						</div>
					</div>

					<div className="col-12 col-md-3">
						<div className="form-group">
							<label>
								Num. Chassi<span className="campoObratorio">*</span>
							</label>
							<input
								type="text"
								maxLength={20}
								className="form-control"
								name="leadAutoRastreadorNumChassi"
								value={atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorNumChassi}
								onChange={onChangeEvent}
							/>
						</div>
					</div>

					<div className="col-12 col-md-3">
						<div className="form-group">
							<label>Renavam</label>
							<input
								type="text"
								maxLength={15}
								className="form-control"
								name="leadAutoRastreadorRenavam"
								value={atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorRenavam}
								onChange={onChangeEvent}
							/>
						</div>
					</div>

					<div className="col-12 col-md-2">
						<div className="form-group">
							<label>
								Cor<span className="campoObratorio">*</span>
							</label>
							<select
								className="form-control"
								name="leadAutoRastreadorCor"
								value={atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorCor}
								onChange={onChangeEvent}
							>
								<option className="disabled" />
								<option value="1"> Amarelo </option>
								<option value="2"> Azul </option>
								<option value="3"> Bege </option>
								<option value="4"> Branco </option>
								<option value="5"> Bronze </option>
								<option value="6"> Cinza </option>
								<option value="7"> Dourado </option>
								<option value="8"> Laranja </option>
								<option value="9"> Marrom </option>
								<option value="10"> Prata </option>
								<option value="11"> Preto </option>
								<option value="12"> Rosa </option>
								<option value="13"> Roxo </option>
								<option value="14"> Verde </option>
								<option value="15"> Vermelho </option>
								<option value="16"> Vinho </option>
							</select>
						</div>
					</div>

					<div className="col-12 col-md-2">
						<div className="form-group">
							<label>
								Combustivel<span className="campoObratorio">*</span>
							</label>
							<select
								className="form-control"
								name="leadAutoRastreadorCombustivel"
								value={atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorCombustivel}
								onChange={onChangeEvent}
							>
								<option className="disabled" />
								<option value="1"> Alcool </option>
								<option value="2"> Diesel </option>
								<option value="3"> Hibrido </option>
								<option value="4"> Flex </option>
								<option value="5"> GNV </option>
								<option value="6"> Gasolina </option>
							</select>
						</div>
					</div>
				</GroupBox>
				<GroupBox className="col-12 col-md-12" titulo="Endereço">
					<div className="col-12 col-md-2">
						<div className="form-group">
							<label>
								Cep <span className="campoObratorio">*</span>
							</label>
							<input
								type="text"
								maxLength={9}
								className="form-control cep"
								name="leadAutoRastreadorCep"
								value={atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorCep}
								onChange={onChangeEvent}
							/>
						</div>
					</div>
					<div className="col-12 col-md-6">
						<div className="form-group">
							<label>
								Logradouro <span className="campoObratorio">*</span>
							</label>
							<input
								type="text"
								maxLength={150}
								className="form-control"
								name="leadAutoRastreadorLogradouro"
								value={atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorLogradouro}
								onChange={onChangeEvent}
							/>
						</div>
					</div>
					<div className="col-12 col-md-2">
						<div className="form-group">
							<label>
								Numero <span className="campoObratorio">*</span>
							</label>
							<input
								type="text"
								maxLength={10}
								className="form-control"
								name="leadAutoRastreadorNumero"
								value={atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorNumero}
								onChange={onChangeEvent}
							/>
						</div>
					</div>
					<div className="col-12 col-md-2">
						<div className="form-group">
							<label>Complemento</label>
							<input
								type="text"
								maxLength={10}
								className="form-control"
								name="leadAutoRastreadorComplemento"
								value={atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorComplemento}
								onChange={onChangeEvent}
							/>
						</div>
					</div>
					<div className="col-12 col-md-5">
						<div className="form-group">
							<label>
								Bairro <span className="campoObratorio">*</span>
							</label>
							<input
								type="text"
								maxLength={150}
								className="form-control"
								name="leadAutoRastreadorBairro"
								value={atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorBairro}
								onChange={onChangeEvent}
							/>
						</div>
					</div>
					<div className="col-12 col-md-5">
						<div className="form-group">
							<label>
								Cidade <span className="campoObratorio">*</span>
							</label>
							<input
								type="text"
								maxLength={150}
								className="form-control"
								name="leadAutoRastreadorCidade"
								value={atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorCidade}
								onChange={onChangeEvent}
							/>
						</div>
					</div>
					<div className="col-12 col-md-2">
						<div className="form-group">
							<label>
								Uf <span className="campoObratorio">*</span>
							</label>
							<input
								type="text"
								maxLength={2}
								className="form-control"
								name="leadAutoRastreadorUf"
								value={atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorUf}
								onChange={onChangeEvent}
							/>
						</div>
					</div>
				</GroupBox>
				<GroupBox className="col-12 col-md-12" titulo="Informações da Proposta">
					<div className="col-12 col-md-5">
						<div className="form-group">
							<label>
								Tipo Pagamento<span className="campoObratorio2">*</span>
							</label>
							<select
								className="form-control"
								name="leadAutoRastreadorTipoPagamento"
								value={
									atendimentoValues.dadosAtendimento.autoRastreador.leadAutoRastreadorTipoPagamento
								}
								onChange={onChangeEvent}
							>
								<option className="disabled" value="0" />
								<option value="1">1 = Boleto - 1ª parcela antecipada</option>
								<option value="2">
									2 = Boleto - 1ª parcela 3 dias pós início vigência/mínimo 5 dias emissão
								</option>
							</select>
						</div>
					</div>
					<div className="col-12 col-md-3">
						<div className="form-group">
							<label>
								Dia Venc. Demais Parcelas. <span className="campoObratorio">*</span>
							</label>
							<input
								type="number"
								className="form-control"
								name="leadAutoRastreadorDiaVencimentoDemaisParcelas"
								value={
									atendimentoValues.dadosAtendimento.autoRastreador
										.leadAutoRastreadorDiaVencimentoDemaisParcelas
								}
								onChange={onChangeEvent}
							/>
						</div>
					</div>
					<div className="col-12 col-md-3">
						<div className="form-group">
							<label>
								Parcelamento <span className="campoObratorio">*</span>
							</label>
							<select
								className="form-control"
								name="leadAutoRastreadorQuantidadeParcelas"
								value={
									atendimentoValues.dadosAtendimento.autoRastreador
										.leadAutoRastreadorQuantidadeParcelas
								}
								onChange={onChangeEvent}
							>
								<option className="disabled" value="0" />
								{cotacaoSelecionada.parcelamento ? (
									cotacaoSelecionada.parcelamento.map((parcelamento) => (
										<option key={parcelamento.parcela} value={parcelamento.parcela}>
											{`${parcelamento.parcela}x de R$${parcelamento.valorParcela}`}
										</option>
									))
								) : null}
							</select>
						</div>
					</div>
				</GroupBox>
			</div>
		</Fragment>
	);
};

export default FormularioTransmissao;
