import React, { useContext, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { JornadaTrabalhoFaltaContext } from "../JornadaTrabalhoFaltaContext";
import Formulario from "../../../../../Components/Cadastro/Formulario/Formulario";
import FormularioComplementar from "../../../../../Components/Cadastro/FormularioComplementar/FormularioComplementar";
import DataTable from "../../../../../Components/DataTable/DataTable";

const FormularioJornadaTrabalhoFalta = () => {
  const {
    values,
    setValues,
    getDetailHandler,
    removerConsultor,
    adionarConsultor,
  } = useContext(JornadaTrabalhoFaltaContext);
  let { id } = useParams();

  useEffect(() => {
    getDetailHandler(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      data: {
        ...values.data,
        [name]: value,
      },
    });
  }

  function onChangeInputData(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      dataDataFalta: {
        ...values.dataDataFalta,
        id: value.replace("/", "").replace("/", ""),
        [name]: value,
      },
    });
  }

  return (
    <Fragment>
      <Formulario context={JornadaTrabalhoFaltaContext}>
        <div className="col-12 col-md-3 form-group">
          <label>Consultor</label>
          <select
            disabled={values.lockField}
            className="form-control"
            name="usuarioId"
            value={values.data.usuarioId}
            onChange={onChangeInput}
          >
            <option className="disabled"></option>
            {values.usuarios.map((usuario) => (
              <option key={usuario.usuarioId} value={usuario.usuarioId}>
                {usuario.usuarioNome}
              </option>
            ))}
          </select>
        </div>
      </Formulario>
      <FormularioComplementar titulo="Consultores">
        <div className="col-12 col-md-2 form-group">
          <label>Data</label>
          <input
            disabled={values.formDisabled}
            className="form-control"
            type="text"
            name="data"
            value={values.dataDataFalta.data}
            onChange={onChangeInputData}
          />
        </div>

        <div className="col-12 col-md-2 form-group">
          <button
            disabled={!values.dataDataFalta.data}
            type="button"
            className="btn btn-default buttonFormularios"
            onClick={adionarConsultor}
          >
            Adicionar
          </button>
        </div>
        <div className="col-12 col-md-12 form-group">
          <DataTable
            data={values.datasfalta}
            columnsDefinition={values.columnsDefinitionDataFalta}
            removeHandler={values.formDisabled ? null : removerConsultor}
          />
        </div>
      </FormularioComplementar>
    </Fragment>
  );
};

export default FormularioJornadaTrabalhoFalta;
