/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext } from 'react';
import AppContext from '../../../../Store/AppContext';
import { Listar as ListarProduto } from '../../../../Services/Produto';
import { removeItemFromArray } from '../../../../Utils/globalFunctions';
import { Listar as ListarUsuario } from '../../../../Services/Usuario';
import { Listar as ListarLandingPage } from '../../../../Services/LandingPage';
import { Listar as ListarLojaVirtual } from '../../../../Services/LojaVirtual';
import { retornarUnicos } from '../../../../Utils/globalFunctions';
import {
	Listar as ListarEquipe,
	Detalhes as DetalhesEquipe,
	Remover as RemoverEquipe,
	Incluir as IncluirEquipe,
	Atualizar as AtualizarEquipe
} from '../../../../Services/Equipe';
import { useHistory } from 'react-router-dom';
import { Listar as ListarCidades } from '../../../../Services/Cidade';
import { validarPermissoesPagina } from '../../../../Utils/validarPermissoesPagina';
export const EquipeContext = createContext();

const tituloTela = 'Cadastro de Equipe';

const defaultData = {
	equipeId: 0,
	produtoId: 0,
	equipeNome: '',
	equipeAgendaTempoAvisoEmailCheck: 0,
	equipeAgendaTempoAvisoEmail: 0,
	equipeAgendaTempoAvisoTelaCheck: 0,
	equipeAgendaTempoAvisoTela: 0,
	supervisores: [],
	equipeAtendeRenovacao: 0,
};

const searchData = {
	equipeNome: '',
	produtoId: 0
};

const columnsDefinition = [
	{
		text: 'Id',
		dataField: 'equipeId',
		hidden: true
	},
	{
		text: 'Nome da Equipe',
		dataField: 'equipeNome',
		headerStyle: { width: '250px' },
		sort: true
	},
	{
		text: 'Produto',
		dataField: 'produto',
		headerStyle: { width: '250px' },
		sort: true
	},
	{
		text: "Atende Renovação?",
		dataField: "equipeAtendeRenovacao",
		headerStyle: { width: "120px" },
		sort: true,
		formatter: (cell, row) => {
			return cell == '1' ? (
				<span className="badge badge-success">SIM</span>
			) : (
				<span className="badge badge-danger">NÃO</span>
			);
		},
	}
];

const columnsDefinitionSupervisores = [
	{
		text: 'Id',
		dataField: 'usuarioId',
		hidden: true
	},
	{
		text: 'Usuário Responsável',
		dataField: 'usuarioNome',
		headerStyle: { width: '250px' },
		sort: true
	}
];

const columnsDefinitionLojasVirtual = [
	{
		text: 'Id',
		dataField: 'lojaVirtualId',
		hidden: true
	},
	{
		text: 'Código',
		dataField: 'lojaVirtualCod',
		headerStyle: { width: '70px' },
		sort: true
	},
	{
		text: 'Nome',
		dataField: 'lojaVirtualNome',
		headerStyle: { width: '250px' },
		sort: true
	}
];


const columnsDefinitionLandingPages = [
	{
		text: 'Id',
		dataField: 'landingPageId',
		hidden: true
	},
	{
		text: 'Nome',
		dataField: 'landingPageNome',
		headerStyle: { width: '250px' },
		sort: true
	},
	{
		text: 'Cidade',
		dataField: 'cidadeNome',
		headerStyle: { width: '150px' },
		sort: true
	},
	{
		text: 'UF',
		dataField: 'uf',
		headerStyle: { width: '50px' },
		sort: true
	}
];

const defaultDataSupervisores = { usuarioId: 0, usuarioNome: '' };
const defaultDataLandingPages = { landingPageId: 0, landingPageNome: '' };
const defaultDataLojaVirtual = { lojaVirtualId: 0, lojaVirtualNome: '' };

function initialState() {
	return {
		searchData: searchData,
		resultSearch: [],
		columnsDefinition: columnsDefinition,
		data: defaultData,
		formDisabled: true,
		produtos: [],
		cidades: [],
		cidadesApi: [],
		ufs: [],
		supervisores: [],
		landingPages: [],
		lojasVirtual: [],
		landingPagesApi: [],
		dataSupervisores: defaultDataSupervisores,
		dataLandingPages: defaultDataLandingPages,
		dataLojaVirtual: defaultDataLojaVirtual,
		columnsDefinitionSupervisores: columnsDefinitionSupervisores,
		columnsDefinitionLandingPages: columnsDefinitionLandingPages,
		columnsDefinitionLojasVirtual: columnsDefinitionLojasVirtual,
		supervisoresEquipe: [],
		landingPagesEquipe: [],
		lojasVirtualEquipe: []
	};
}

const EquipeProvider = ({ children }) => {
	const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(AppContext);
	const [values, setValues] = useState(initialState());
	let history = useHistory();

	async function loadDependences() {
		const [
			permissoesPaginaAux,
			responseListarProduto,
			responseListarLojaVirtual
		] = await Promise.all([
			validarPermissoesPagina(),
			ListarProduto({}),
			ListarLojaVirtual({})
		]);

		if (!responseListarProduto.success || !responseListarLojaVirtual.success) {
			showMessage('Erro', 'Erro Inesperado ao carregar dados basicos.');
			return { produtos: [], lojasVirtual: [] };
		}

		const produtos = await responseListarProduto.produtos.filter((item) => item.produtoAtivo === 'A');
		const lojasVirtual = await responseListarLojaVirtual.lojasVirtual;

		return { permissoesPagina: permissoesPaginaAux, produtos: produtos, lojasVirtual: lojasVirtual };
	}

	const resetScreen = () => {
		showLoading();
		async function carregarDependencias() {
			const dependencias = await loadDependences();
			setTituloTela(tituloTela);
			setValues({
				...initialState(),
				permissoesPagina: await dependencias.permissoesPagina,
				produtos: await dependencias.produtos,
				lojasVirtual: await dependencias.lojasVirtual
			});
			hideLoading();
		}
		carregarDependencias();
	};

	const searchHandler = async () => {
		showLoading();
		const responseListarEquipe = await ListarEquipe(values.searchData);

		if (!responseListarEquipe.success) {
			showMessage('Erro', 'Erro Inesperado durante a pesquisa.');
			hideLoading();
			return;
		}

		const resultSearchData = await responseListarEquipe.equipes;
		resultSearchData.map((data) => {
			data.Id = data.equipeId;
			data.produto = values.produtos.find((produto) => produto.produtoId === data.produtoId).produtoNome;
			return data;
		});

		setValues({
			...values,
			resultSearch: resultSearchData
		});

		if (resultSearchData.length === 0) {
			showMessage('Alerta', 'Nenhum registro encontrado.');
		}
		hideLoading();
	};

	const removeHandler = async (id) => {
		showLoading();

		const responseRemover = await RemoverEquipe(id);

		if (!responseRemover.success) {
			hideLoading();
			showMessage('Erro', `Erro durante a exclusão: ${responseRemover.message}`);
			return;
		}
		searchHandler();
		hideLoading();
		showMessage('Sucesso', 'Registro excluido.');
	};

	const getDetailHandler = async (id) => {
		showLoading();
		const dependencias = await loadDependences();

		const responseListarUsuario = await ListarUsuario({});
		const responseListarLandingPage = await ListarLandingPage({});
		const responseCidades = await ListarCidades();

		const cidadesApi = await responseCidades.cidades;
		const ufs = await responseCidades.cidades.map((cidade) => cidade.ufCod).filter(retornarUnicos);

		const supervisoresBase = responseListarUsuario.usuarios;
		const landingPagesBase = responseListarLandingPage.landingPages;
		let produtosBase = await dependencias.produtos;
		let lojasVirtualBase = await dependencias.lojasVirtual;

		if (id > 0) {
			const responseDetalhes = await DetalhesEquipe(id);
			const { success, equipe, supervisores, landingPages, lojasVirtual } = await responseDetalhes;

			if (!success) {
				showMessage('Erro', 'Erro ao buscar os detalhes do registro.');
				setValues({
					...values,
					data: defaultData,
					permissoesPagina: await dependencias.permissoesPagina,
					produtos: [],
					cidades: [],
					cidadesApi: [],
					ufs: []
				});
			}

			let supervisoresEquipe = supervisores;

			supervisoresEquipe.map((responsavelEquipe) => {
				responsavelEquipe.id = responsavelEquipe.usuarioId;
				responsavelEquipe.usuarioNome = supervisoresBase.find(
					(supervisor) => supervisor.usuarioId === responsavelEquipe.usuarioId
				).usuarioNome;
				return responsavelEquipe;
			});

			let landingPagesEquipe = landingPages;

			landingPagesEquipe.map((landingPageEquipe) => {
				landingPageEquipe.id = landingPageEquipe.landingPageId;
				const landingPageItem = landingPagesBase.find(
					(landingPage) => landingPage.landingPageId === landingPageEquipe.landingPageId
				);

				landingPageEquipe.landingPageNome = landingPageItem.landingPageNome;
				landingPageEquipe.uf = landingPageItem.uf;
				landingPageEquipe.cidadeNome = landingPageItem.cidadeNome;
				return landingPageEquipe;
			});


			let lojasVirtualEquipe = lojasVirtual;

			lojasVirtualEquipe.map((lojaVirtualEquipe) => {
				lojaVirtualEquipe.id = lojaVirtualEquipe.lojaVirtualId;
				const lojaVirtualItem = lojasVirtualBase.find(
					(lojaVirtual) => lojaVirtual.lojaVirtualId === lojaVirtualEquipe.lojaVirtualId
				);
				lojaVirtualEquipe.lojaVirtualCod = lojaVirtualItem.lojaVirtualCod;
				lojaVirtualEquipe.lojaVirtualNome = lojaVirtualItem.lojaVirtualNome;
				return lojaVirtualEquipe;
			});

			setValues({
				...values,
				data: { ...equipe, supervisores, landingPages, lojasVirtual },
				permissoesPagina: await dependencias.permissoesPagina,
				produtos: produtosBase,
				supervisores: supervisoresBase,
				landingPages: landingPagesBase,
				lojasVirtual: lojasVirtualBase,
				landingPagesApi: landingPagesBase,
				supervisoresEquipe: supervisoresEquipe,
				landingPagesEquipe: landingPagesEquipe,
				lojasVirtualEquipe: lojasVirtualEquipe,
				cidades: cidadesApi,
				cidadesApi: cidadesApi,
				ufs: ufs
			});
		} else {
			setValues({
				...values,
				data: {
					...defaultData,
					equipeId: 0
				},
				formDisabled: false,
				permissoesPagina: await dependencias.permissoesPagina,
				produtos: produtosBase,
				lojasVirtual: lojasVirtualBase,
				supervisores: supervisoresBase,
				landingPages: landingPagesBase,
				landingPagesApi: landingPagesBase,
				supervisoresEquipe: [],
				lojasVirtualEquipe: [],
				cidades: cidadesApi,
				cidadesApi: cidadesApi,
				ufs: ufs
			});
		}
		hideLoading();
	};

	const createData = async () => {
		showLoading();
		const responseIncluir = await IncluirEquipe(values.data);
		if (!responseIncluir.success) {
			hideLoading();
			showMessage('Erro', `Erro ao criar o registro: ${responseIncluir.message}`);
			responseIncluir.errors.map((erro) => showMessage('Erro', erro.errorMessage));

			return;
		}

		resetScreen();
		hideLoading();
		showMessage('Sucesso', 'Registro criado.');
		history.goBack();
	};

	const editHandler = () => {
		setValues({
			...values,
			formDisabled: false
		});
	};

	const updateData = async () => {
		showLoading();
		const responseAtualizar = await AtualizarEquipe(values.data);
		if (!responseAtualizar.success) {
			hideLoading();
			showMessage('Erro', `Erro ao atualizar o registro: ${responseAtualizar.message}`);
			responseAtualizar.errors.map((erro) => showMessage('Erro', erro.errorMessage));

			return;
		}
		resetScreen();
		hideLoading();
		showMessage('Sucesso', 'Registro atualizado.');
		history.goBack();
	};

	const saveHandler = async () => {
		if (values.data.equipeId === 0) {
			await createData();
		} else {
			await updateData();
		}
	};

	const cancelHandler = () => {
		setValues({
			...values,
			data: defaultData,
			formDisabled: true
		});
	};

	const adionarResponsavel = () => {
		let supervisorJaAdiocionado = values.supervisoresEquipe.find(
			// eslint-disable-next-line eqeqeq
			(supervisor) => supervisor.usuarioId == values.dataSupervisores.id
		);

		if (supervisorJaAdiocionado) {
			showMessage('Alerta', 'Supervisor já incluído.');
			return;
		}

		var supervisoresEquipeNew = values.supervisoresEquipe;
		supervisoresEquipeNew.push(values.dataSupervisores);
		setValues({
			...values,
			dataSupervisores: defaultDataSupervisores,
			supervisoresEquipe: supervisoresEquipeNew,
			data: {
				...values.data,
				supervisores: supervisoresEquipeNew
			}
		});
	};

	const removerResponsavel = (id) => {
		var supervisoresEquipeNew = values.supervisoresEquipe;

		removeItemFromArray(supervisoresEquipeNew, supervisoresEquipeNew.find((supervisor) => supervisor.id === id));

		setValues({
			...values,
			dataSupervisores: defaultDataSupervisores,
			supervisoresEquipe: supervisoresEquipeNew,
			data: {
				...values.data,
				supervisores: supervisoresEquipeNew
			}
		});
	};

	const adionarLandingPage = () => {
		let landingPageJaAdiocionado = values.landingPagesEquipe.find(
			// eslint-disable-next-line eqeqeq
			(landingPage) => landingPage.landingPageId == values.dataLandingPages.id
		);

		if (landingPageJaAdiocionado) {
			showMessage('Alerta', 'Landing Page já incluído.');
			return;
		}

		var landingPagesEquipeNew = values.landingPagesEquipe;
		landingPagesEquipeNew.push(values.dataLandingPages);
		setValues({
			...values,
			dataLandingPages: defaultDataLandingPages,
			landingPagesEquipe: landingPagesEquipeNew,
			data: {
				...values.data,
				landingPages: landingPagesEquipeNew
			}
		});
	};

	const removerLandingPage = (id) => {
		var landingPagesEquipeNew = values.landingPagesEquipe;
		removeItemFromArray(landingPagesEquipeNew, landingPagesEquipeNew.find((supervisor) => supervisor.id === id));
		setValues({
			...values,
			dataSupervisores: defaultDataSupervisores,
			landingPagesEquipe: landingPagesEquipeNew,
			data: {
				...values.data,
				landingPages: landingPagesEquipeNew
			}
		});
	};

	const obterLandingPages = (uf, cidadeId) => {
		const result = values.landingPagesApi.filter(
			(landingPage) => (uf === '' || landingPage.uf == uf) && (cidadeId == 0 || landingPage.cidadeId == cidadeId)
		);
		return result;
	};

	const filtrarCidades = (uf) => {
		setValues({
			...values,
			data: {
				...values.data,
				uf: uf
			},
			cidades: values.cidadesApi.filter((cidade) => uf === '' || cidade.ufCod === uf),
			landingPages: obterLandingPages(uf, 0)
		});
	};

	const filtrarLandingPages = (cidadeId) => {
		setValues({
			...values,
			data: {
				...values.data,
				cidadeId: cidadeId
			},
			landingPages: obterLandingPages(values.data.uf, cidadeId)
		});
	};



	const adionarLojaVirtual = () => {
		let lojaVirtualJaAdiocionado = values.lojasVirtualEquipe.find(
			// eslint-disable-next-line eqeqeq
			(lojaVirtual) => lojaVirtual.lojaVirtualId == values.dataLojaVirtual.id
		);
		if (lojaVirtualJaAdiocionado) {
			showMessage('Alerta', 'Loja Virtual já incluída.');
			return;
		}
		var lojaVirtualparaAdicionar = values.lojasVirtual.find(
			// eslint-disable-next-line eqeqeq
			(lojaVirtual) => lojaVirtual.lojaVirtualId == values.dataLojaVirtual.lojaVirtualId
		);
		var lojasVirtualEquipeNew = values.lojasVirtualEquipe;
		lojasVirtualEquipeNew.push(lojaVirtualparaAdicionar);
		setValues({
			...values,
			dataLojaVirtual: defaultDataLojaVirtual,
			lojasVirtualEquipe: lojasVirtualEquipeNew,
			data: {
				...values.data,
				lojasVirtual: lojasVirtualEquipeNew
			}
		});
	};

	const removerLojaVirtual = (id) => {
		var lojasVirtualEquipeNew = values.lojasVirtualEquipe;

		removeItemFromArray(lojasVirtualEquipeNew, lojasVirtualEquipeNew.find((lojaVirtual) => lojaVirtual.id === id));

		setValues({
			...values,
			dataLojaVirtual: defaultDataLojaVirtual,
			lojasVirtualEquipe: lojasVirtualEquipeNew,
			data: {
				...values.data,
				lojasVirtual: lojasVirtualEquipeNew
			}
		});
	};

	return (
		<EquipeContext.Provider
			value={{
				values,
				setValues,
				resetScreen,
				searchHandler,
				cancelHandler,
				removeHandler,
				saveHandler,
				getDetailHandler,
				editHandler,
				adionarResponsavel,
				removerResponsavel,
				adionarLandingPage,
				removerLandingPage,
				filtrarCidades,
				filtrarLandingPages,
				adionarLojaVirtual,
				removerLojaVirtual
			}}
		>
			{children}
		</EquipeContext.Provider>
	);
};

export default EquipeProvider;
