import React, { useContext } from "react";
import "./SideBar.css";
import AppContext from "../../Store/AppContext";
import lgAssegurouB from "../../assets/img/lgAssegurouB.png";

import SideBarTopList from "./SideBarTopList/SideBarTopList";
import SideBarTopLink from "./SideBarTopLink/SideBarTopLink";
const menuHome = {
  id: 0,
  nome: "Home",
  descricao: "Home",
  icone: "dashboard.png",
  path: "Home",
  subMenus: [],
};
const Sidebar = () => {
  const { acessos } = useContext(AppContext);

  if (!acessos) {
    window.location.replace("/");
  }
  return (
    <div className="border-right" id="sidebar-wrapper">
      <div className="float-left collapse show" id="sidebar">
        <div className="list-group border-0">
          <div className="text-center mb-1 pt-3">
            <img src={lgAssegurouB} alt="Assegurou" className="img-fluid" />
          </div>
          <SideBarTopLink key={menuHome.id} id={menuHome.id} menu={menuHome} />
          {acessos.map((menu) =>
            menu.subMenus.length === 0 ? (
              <SideBarTopLink key={menu.id} id={menu.id} menu={menu} />
            ) : (
              <SideBarTopList key={menu.id} id={menu.id} menu={menu} />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
