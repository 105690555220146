import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "Ura";
export const ObterGravacaoPorUuid = (usuarioHistoricoLigacoesId) =>
  RequestWebApi.get(`/${controller}/ObterGravacaoPorUuid`, {
    params: { usuarioHistoricoLigacoesId: usuarioHistoricoLigacoesId }
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const gravacao = resultData;
      return { success, gravacao };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, gravacao: null };
    });


export const FazerLigacao = (data) =>
  RequestWebApi.post(`/${controller}/FazerLigacao`, data)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });
