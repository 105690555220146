import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ListaObjetos = ({
  templateElementList,
  adicionarElemento = () => {},
}) => {
  return (
    <div className="row">
      <div className="col-12 text-center formularioCampanhaJornadaTemplateTitle">
        Listagem de Objetos
      </div>
      {templateElementList.map((template) => {
        return (
          <div key={template.type} className="col-6">
            <div
              className="border border-default rounded formularioCampanhaJornadaTemplateBody"
              onClick={() => {
                adicionarElemento(template);
              }}
            >
              <FontAwesomeIcon
                style={{ color: template.corIcone }}
                className="formularioCampanhaJornadaIcone"
                icon={template.icone}
              />
              <div className="row" />
              <span className="formularioCampanhaJornadaText">
                {template.descricao}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ListaObjetos;
