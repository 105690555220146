import React, { useState } from 'react';
import './TarefasExecutarItem.css';
import { Fragment } from 'react';
let idComponente = '';

const defaultData = {
	tarefasExecutarItensId: null,
	tarefasExecutarId: 0,
	tarefasExecutarItensDescrSolicitacao: "",
	tarefasExecutarItensObjeto: null,
	tarefasExecutarItensDataHora: "",
	acao: "N",
	tipo: null,
};
function initialState() {
	return {
		data: defaultData
	};
}

const TarefasExecutarItem = ({ id, children, tarefasExecutarItem, disabled = false, confirmarHandler = () => { } }) => {
	idComponente = id;
	const [valuesTarefasExecutarItem, setValuesTarefasExecutarItem] = useState({
		...initialState,
		data: tarefasExecutarItem == null ? defaultData : tarefasExecutarItem
	});

	function onChangeInput(event) {
		const { name, value } = event.target;
		setValuesTarefasExecutarItem({
			...valuesTarefasExecutarItem,
			data: {
				...valuesTarefasExecutarItem.data,
				[name]: value,
			},
		});
	}
	function getRandomInt(min, max) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min) + min);
	}
	function confirmarItemHandler() {
		let data = valuesTarefasExecutarItem.data;
		if (data.tarefasExecutarItensId == null) {
			data.tarefasExecutarItensId = getRandomInt(-2147483648, 2147483647);
			setValuesTarefasExecutarItem(initialState);
		}
		confirmarHandler(data);
	}

	return (
		<Fragment>
			<div data-toggle="modal" data-target={`#modalTarefasExecutarItem${idComponente}`} data-dismiss="modal">
				{children}
			</div>

			<div
				className="modal fade"
				data-backdrop="static"
				data-keyboard={false}
				id={`modalTarefasExecutarItem${idComponente}`}
				tabIndex="-1"
				role="dialog"
				aria-labelledby={`modalTarefasExecutarItem${idComponente}Label`}
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id={`modalTarefasExecutarItem${idComponente}Label`}>
								Item
							</h5>
						</div>

						<div className="modal-body modalTarefasExecutarItem_modalBody">
							<Fragment>
								<div className="col-12 col-md-3 form-group">
									<label>
										Data
									</label>
									<input
										disabled={disabled}
										className="form-control"
										type="date"
										name="tarefasExecutarItensDataHora"
										value={valuesTarefasExecutarItem.data.tarefasExecutarItensDataHora}
										onChange={onChangeInput}
									/>
								</div>
								<div className="col-12 col-md-12 form-group">
									<label>Descrição</label>
									<textarea
										disabled={disabled}
										cols="30"
										rows="6"
										className="form-control"
										type="text"
										name="tarefasExecutarItensDescrSolicitacao"
										value={valuesTarefasExecutarItem.data.tarefasExecutarItensDescrSolicitacao}
										onChange={onChangeInput}
									/>
								</div>
							</Fragment>

							{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto == null ? null :
								(
									<Fragment>
										<Fragment>

											<div className="row">
												<div className="col-12 col-md-12 form-group text-orange tarefasExecutarItem_subTitulosModal mt-3">
													Informações Adicionais
													<hr />
												</div>

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.seguradora == null ? null :
													<div className="col-12 col-md-7 form-group">
														<label>
															seguradora
														</label>
														<input
															disabled={true}
															className="form-control"
															name="seguradora"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.seguradora}
														/>
													</div>
												}
												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.numeroApolice == null ? null :
													<div className="col-12 col-md-5 form-group">
														<label>
															Numero Apólice
														</label>
														<input
															disabled={true}
															className="form-control"
															name="numeroApolice"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.numeroApolice}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.cpfSegurado == null ? null :
													<div className="col-12 col-md-4 form-group">
														<label>
															Cpf Segurado
														</label>
														<input
															disabled={true}
															className="form-control"
															name="cpfSegurado"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.cpfSegurado}
														/>
													</div>
												}



												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.nomeSegurado == null ? null :
													<div className="col-12 col-md-8 form-group">
														<label>
															Nome Segurado
														</label>
														<input
															disabled={true}
															className="form-control"
															name="nomeSegurado"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.nomeSegurado}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.telefoneSegurado == null ? null :
													<div className="col-12 col-md-3 form-group">
														<label>
															Telefone Segurado
														</label>
														<input
															disabled={true}
															className="form-control"
															name="telefoneSegurado"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.telefoneSegurado}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.emailSegurado == null ? null :
													<div className="col-12 col-md-9 form-group">
														<label>
															Email Segurado
														</label>
														<input
															disabled={true}
															className="form-control"
															name="emailSegurado"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.emailSegurado}
														/>
													</div>
												}
												<div className="col-12 col-md-12 form-group text-orange tarefasExecutarItem_subTitulosModal">
													Informações do Veículo
													<hr />
												</div>
												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.marca == null ? null :
													<div className="col-12 col-md-6 form-group">
														<label>
															Marca
														</label>
														<input
															disabled={true}
															className="form-control"
															name="marca"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.marca}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.modelo == null ? null :
													<div className="col-12 col-md-6 form-group">
														<label>
															Modelo
														</label>
														<input
															disabled={true}
															className="form-control"
															name="modelo"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.modelo}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.ano == null ? null :
													<div className="col-12 col-md-3 form-group">
														<label>
															Ano
														</label>
														<input
															disabled={true}
															className="form-control"
															name="ano"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.ano}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.placa == null ? null :
													<div className="col-12 col-md-3 form-group">
														<label>
															Placa
														</label>
														<input
															disabled={true}
															className="form-control"
															name="placa"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.placa}
														/>
													</div>
												}

											</div>
										</Fragment>

										{valuesTarefasExecutarItem.data.tipo == "S" ? <Fragment>
											<div className="row">
												<div className="col-12 col-md-12 form-group text-orange tarefasExecutarItem_subTitulosModal">
													Informações Sinistro
													<hr />
												</div>

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.numeroAvisoSinistro == null ? null :
													<div className="col-12 col-md-3 form-group">
														<label>
															Numero Aviso
														</label>
														<input
															disabled={true}
															className="form-control"
															name="numeroAvisoSinistro"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.numeroAvisoSinistro}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.tipoSinistro == null ? null :
													<div className="col-12 col-md-3 form-group">
														<label>
															Tipo
														</label>
														<input
															disabled={true}
															className="form-control"
															name="tipoSinistro"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.tipoSinistro}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.situacaoSinistro == null ? null :
													<div className="col-12 col-md-3 form-group">
														<label>
															Situação
														</label>
														<input
															disabled={true}
															className="form-control"
															name="situacaoSinistro"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.situacaoSinistro}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.dataSinistro == null ? null :
													<div className="col-12 col-md-3 form-group">
														<label>
															Data
														</label>
														<input
															disabled={true}
															className="form-control"
															name="dataSinistro"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.dataSinistro}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.localSinistro == null ? null :
													<div className="col-12 col-md-12 form-group">
														<label>
															Local
														</label>
														<input
															disabled={true}
															className="form-control"
															name="localSinistro"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.localSinistro}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.descricao == null ? null :
													<div className="col-12 col-md-12 form-group">
														<label>
															Descrição
														</label>
														<textarea
															disabled={true}
															className="form-control"
															cols="30"
															rows="6"
															type="text"
															name="descricao"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.descricao}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.nomeOficina == null ? null :
													<div className="col-12 col-md-8 form-group">
														<label>
															Nome Oficina
														</label>
														<input
															disabled={true}
															className="form-control"
															name="nomeOficina"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.nomeOficina}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.telefoneOficina == null ? null :
													<div className="col-12 col-md-4 form-group">
														<label>
															Telefone Oficina
														</label>
														<input
															disabled={true}
															className="form-control"
															name="telefoneOficina"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.telefoneOficina}
														/>
													</div>
												}
												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.cpfTerceiro == null ? null :
													<div className="col-12 col-md-3 form-group">
														<label>
															Cpf Terceiro
														</label>
														<input
															disabled={true}
															className="form-control"
															name="cpfTerceiro"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.cpfTerceiro}
														/>
													</div>
												}
												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.nomeTerceiro == null ? null :
													<div className="col-12 col-md-9 form-group">
														<label>
															Nome Terceiro
														</label>
														<input
															disabled={true}
															className="form-control"
															name="nomeTerceiro"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.nomeTerceiro}
														/>
													</div>
												}
												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.emailTerceiro == null ? null :
													<div className="col-12 col-md-8 form-group">
														<label>
															Email Terceiro
														</label>
														<input
															disabled={true}
															className="form-control"
															name="emailTerceiro"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.emailTerceiro}
														/>
													</div>
												}
												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.telefoneTerceiro == null ? null :
													<div className="col-12 col-md-4 form-group">
														<label>
															Telefone Terceiro
														</label>
														<input
															disabled={true}
															className="form-control"
															name="telefoneTerceiro"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.telefoneTerceiro}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.placaTerceiro == null ? null :
													<div className="col-12 col-md-3 form-group">
														<label>
															Placa Terceiro
														</label>
														<input
															disabled={true}
															className="form-control"
															name="placaTerceiro"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.placaTerceiro}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.numeroAvisoSinistroTerceiro == null ? null :
													<div className="col-12 col-md-3 form-group">
														<label>
															Numero Aviso Terceiro
														</label>
														<input
															disabled={true}
															className="form-control"
															name="numeroAvisoSinistroTerceiro"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.numeroAvisoSinistroTerceiro}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.coberturasAcionadas == null ? null :
													<div className="col-12 col-md-12 form-group">
														<label>
															Coberturas Acionadas
														</label>
														<input
															disabled={true}
															className="form-control"
															name="coberturasAcionadas"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.coberturasAcionadas}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.valorApurado == null ? null :
													<div className="col-12 col-md-3 form-group">
														<label>
															Valor Apurado
														</label>
														<input
															disabled={true}
															className="form-control"
															name="valorApurado"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.valorApurado}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.valorFranquia == null ? null :
													<div className="col-12 col-md-3 form-group">
														<label>
															Valor Franquia
														</label>
														<input
															disabled={true}
															className="form-control"
															name="valorFranquia"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.valorFranquia}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.valorIndenizado == null ? null :
													<div className="col-12 col-md-3 form-group">
														<label>
															Valor Indenizado
														</label>
														<input
															disabled={true}
															className="form-control"
															name="valorIndenizado"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.valorIndenizado}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.dataPagamento == null ? null :
													<div className="col-12 col-md-3 form-group">
														<label>
															Data Pagamento
														</label>
														<input
															disabled={true}
															className="form-control"
															name="dataPagamento"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.dataPagamento}
														/>
													</div>
												}


											</div>
										</Fragment> : null}
										{valuesTarefasExecutarItem.data.tipo == "E" ? <Fragment>
											<div className="row">
												<div className="col-12 col-md-12 form-group text-orange tarefasExecutarItem_subTitulosModal">
													Informações Endosso
													<hr />
												</div>

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.numeroProposta == null ? null :
													<div className="col-12 col-md-3 form-group">
														<label>
															Numero Proposta
														</label>
														<input
															disabled={true}
															className="form-control"
															name="numeroProposta"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.numeroProposta}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.numeroEndosso == null ? null :
													<div className="col-12 col-md-3 form-group">
														<label>
															Numero
														</label>
														<input
															disabled={true}
															className="form-control"
															name="numeroEndosso"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.numeroEndosso}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.tipoEndosso == null ? null :
													<div className="col-12 col-md-3 form-group">
														<label>
															Tipo
														</label>
														<input
															disabled={true}
															className="form-control"
															name="tipoEndosso"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.tipoEndosso}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.quandoTratar == null ? null :
													<div className="col-12 col-md-3 form-group">
														<label>
															Quando Tratar
														</label>
														<input
															disabled={true}
															className="form-control"
															name="quandoTratar"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.quandoTratar}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.marcaNovoVeiculo == null ? null :
													<div className="col-12 col-md-6 form-group">
														<label>
															Marca Novo Veículo
														</label>
														<input
															disabled={true}
															className="form-control"
															name="marcaNovoVeiculo"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.marcaNovoVeiculo}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.modeloNovoVeiculo == null ? null :
													<div className="col-12 col-md-6 form-group">
														<label>
															Modelo Novo Veículo
														</label>
														<input
															disabled={true}
															className="form-control"
															name="modeloNovoVeiculo"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.modeloNovoVeiculo}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.anoNovoVeiculo == null ? null :
													<div className="col-12 col-md-3 form-group">
														<label>
															Ano Novo Veículo
														</label>
														<input
															disabled={true}
															className="form-control"
															name="anoNovoVeiculo"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.anoNovoVeiculo}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.placaNovoVeiculo == null ? null :
													<div className="col-12 col-md-3 form-group">
														<label>
															Placa Novo Veículo
														</label>
														<input
															disabled={true}
															className="form-control"
															name="placaNovoVeiculo"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.placaNovoVeiculo}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.premioLiquido == null ? null :
													<div className="col-12 col-md-3 form-group">
														<label>
															Premio Líquido
														</label>
														<input
															disabled={true}
															className="form-control"
															name="premioLiquido"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.premioLiquido}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.inicioVigencia == null ? null :
													<div className="col-12 col-md-3 form-group">
														<label>
															Inicio Vigência
														</label>
														<input
															disabled={true}
															className="form-control"
															name="inicioVigencia"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.inicioVigencia}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.terminoVigencia == null ? null :
													<div className="col-12 col-md-3 form-group">
														<label>
															Termino Vigência
														</label>
														<input
															disabled={true}
															className="form-control"
															name="terminoVigencia"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.terminoVigencia}
														/>
													</div>
												}


											</div>
										</Fragment> : null}
										{valuesTarefasExecutarItem.data.tipo == "C" ? <Fragment>
											<div className="row">
												<div className="col-12 col-md-12 form-group text-orange tarefasExecutarItem_subTitulosModal">
													Informações Cobrança
													<hr />
												</div>

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.numeroParcela == null ? null :
													<div className="col-12 col-md-3 form-group">
														<label>
															Numero Parcela
														</label>
														<input
															disabled={true}
															className="form-control"
															name="numeroParcela"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.numeroParcela}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.valorParcela == null ? null :
													<div className="col-12 col-md-3 form-group">
														<label>
															Valor Parcela
														</label>
														<input
															disabled={true}
															className="form-control"
															name="valorParcela"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.valorParcela}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.vencimentoParcela == null ? null :
													<div className="col-12 col-md-3 form-group">
														<label>
															Vencimento Parcela
														</label>
														<input
															disabled={true}
															className="form-control"
															name="vencimentoParcela"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.vencimentoParcela}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.meioPagamento == null ? null :
													<div className="col-12 col-md-3 form-group">
														<label>
															Meio Pagamento
														</label>
														<input
															disabled={true}
															className="form-control"
															name="meioPagamento"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.meioPagamento}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.situacaoCobranca == null ? null :
													<div className="col-12 col-md-3 form-group">
														<label>
															Situação Cobrança
														</label>
														<input
															disabled={true}
															className="form-control"
															name="situacaoCobranca"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.situacaoCobranca}
														/>
													</div>
												}

											</div>
										</Fragment> : null}
										{valuesTarefasExecutarItem.data.tipo == "P" ? <Fragment>
											<div className="row">
												<div className="col-12 col-md-12 form-group text-orange tarefasExecutarItem_subTitulosModal">
													Informações Pendência
													<hr />
												</div>

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.tipoPendencia == null ? null :
													<div className="col-12 col-md-3 form-group">
														<label>
															Tipo
														</label>
														<input
															disabled={true}
															className="form-control"
															name="tipoPendencia"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.tipoPendencia}
														/>
													</div>
												}

												{valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.prazoRegularizacao == null ? null :
													<div className="col-12 col-md-3 form-group">
														<label>
															Prazo Regularização
														</label>
														<input
															disabled={true}
															className="form-control"
															name="prazoRegularizacao"
															value={valuesTarefasExecutarItem.data.tarefasExecutarItensObjeto.prazoRegularizacao}
														/>
													</div>
												}

											</div>
										</Fragment> : null}




									</Fragment>

								)
							}

						</div>

						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-secondary"
								data-dismiss="modal"
							>
								{disabled ? "Fechar" : "Cancelar"}
							</button>
							{
								disabled ? null : <button
									type="button"
									className="btn btn-default"
									data-dismiss="modal"
									onClick={confirmarItemHandler}
								>
									Confirmar
								</button>

							}

						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default TarefasExecutarItem;
