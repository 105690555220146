import React, { useContext, useEffect, Fragment } from "react";
import "./FormularioOrigemLead.css";
import { useParams } from "react-router-dom";
import { OrigemLeadContext } from "../OrigemLeadContext";
import Formulario from "../../../../../Components/Cadastro/Formulario/Formulario";

const FormularioOrigemLead = () => {
  const { values, setValues, getDetailHandler } = useContext(OrigemLeadContext);
  let { id } = useParams();

  useEffect(() => {
    getDetailHandler(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      data: {
        ...values.data,
        [name]: value,
      },
    });
  }

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setValues({
        ...values,
        data: {
          ...values.data,
          origemLeadIcone: undefined,
        },
      });
      return;
    }

    const selectedFile = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(selectedFile);

    reader.onload = function () {
      const origemLeadIcone = reader.result;
      setValues({
        ...values,
        data: {
          ...values.data,
          origemLeadIcone: origemLeadIcone,
        },
      });
      return;
    };
    reader.onerror = function (error) {
      setValues({
        ...values,
        data: {
          ...values.data,
          origemLeadIcone: undefined,
        },
      });
      return;
    };
  };

  return (
    <Fragment>
      <Formulario context={OrigemLeadContext}>
        <div className="col-12 col-md-3 form-group">
          <div className="row justify-content-md-center">
            <img
              src={values.data.origemLeadIcone}
              alt=""
              srcSet=""
              className="fomularioOrigemLeadIcone"
            />
            <input
              disabled={values.formDisabled}
              type="file"
              className="customFileInputHide"
              onChange={onSelectFile}
              id="imageUpload"
            />
            {values.formDisabled ? null : (
              <label
                type="button"
                className="btn btn-primary"
                htmlFor="imageUpload"
              >
                Importar Icone
              </label>
            )}
          </div>
        </div>
        <div className="col-12 col-md-9 form-group">
          <div className="row">
            <div className="col-12 col-md-2 form-group">
              <label>Código</label>
              <input
                disabled={values.formDisabled}
                className="form-control"
                type="text"
                maxLength="5"
                name="origemLeadCod"
                value={values.data.origemLeadCod}
                onChange={onChangeInput}
              />
            </div>
            <div className="col-12 col-md-4 form-group">
              <label>Origem dos Leads</label>
              <input
                disabled={values.formDisabled}
                className="form-control uppercase"
                type="text"
                maxLength="20"
                name="origemLeadNome"
                value={values.data.origemLeadNome}
                onChange={onChangeInput}
              />
            </div>
            <div className="col-12 col-md-2 form-group">
              <label>Status</label>
              <select
                disabled={values.formDisabled}
                className="form-control"
                name="origemLeadAtivo"
                value={values.data.origemLeadAtivo}
                onChange={onChangeInput}
              >
                <option className="disabled"></option>
                <option value="A"> Ativo </option>
                <option value="I"> Inativo </option>
              </select>
            </div>
          </div>
        </div>
      </Formulario>
    </Fragment>
  );
};

export default FormularioOrigemLead;
