import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "ListBox";

export const Listar = (listBox) =>
  RequestWebApi.get(`/${controller}/Listar`, {
    params: { ...listBox },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const origensLead = resultData;
      return { success, origensLead };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { origensLead: [] };
    });

export const ListarListBoxPorRotulo = (ListBoxRotulo) =>
  RequestWebApi.get(`/${controller}/ListarListBoxPorRotulo`, {
    params: { ListBoxRotulo },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const items = resultData;
      return { success, items };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { items: [] };
    });

export const Detalhes = (listBoxId) =>
  RequestWebApi.get(`/${controller}/Detalhes`, {
    params: { listBoxId: listBoxId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const listBox = resultData;
      return { success, listBox };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, listBox: {} };
    });

export const Incluir = (listBox) =>
  RequestWebApi.post(`/${controller}/Incluir`, listBox)
    .then(function (response) {
      const { resultData, success } = response.data;
      const novoGrupoUsuario = resultData;
      return { success, novoGrupoUsuario };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Atualizar = (listBox) =>
  RequestWebApi.put(`/${controller}/Atualizar`, listBox)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (listBoxId) =>
  RequestWebApi.delete(`/${controller}/Remover`, {
    params: { listBoxId: listBoxId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });
