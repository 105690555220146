import React, { Fragment, useContext } from 'react';

const FormularioLeadAuto = ({ context, onChangeEvent = (event) => { } }) => {
	const { atendimentoValues } = useContext(context);

	return (
		<Fragment>
			{atendimentoValues.dadosAtendimento.produto.produtoFormAuxLead !== 'FORMLEADAUTO' ? null : (
				<Fragment>
					<div className="col-12 col-md-2">
						<div className="form-group">
							<label>
								Placa<span className="campoObratorio">*</span>
							</label>
							<input
								type="text"
								className="form-control placa_veiculo"
								name="placaVeiculo"
								value={atendimentoValues.dadosAtendimento.lead.placaVeiculo}
								onChange={onChangeEvent}
							/>
						</div>
					</div>
					<div className="col-12 col-md-3">
						<div className="form-group">
							<label>
								Veículo Marca<span className="campoObratorio">*</span>
							</label>
							<input
								type="text"
								className="form-control"
								name="marcaVeiculo"
								value={atendimentoValues.dadosAtendimento.lead.marcaVeiculo}
								onChange={onChangeEvent}
							/>
						</div>
					</div>
					<div className="col-12 col-md-3">
						<div className="form-group">
							<label>
								Veículo Modelo<span className="campoObratorio">*</span>
							</label>
							<input
								type="text"
								className="form-control"
								name="modeloVeiculo"
								value={atendimentoValues.dadosAtendimento.lead.modeloVeiculo}
								onChange={onChangeEvent}
							/>
						</div>
					</div>
					<div className="col-12 col-md-2">
						<div className="form-group">
							<label>
								Ano Fabric.<span className="campoObratorio">*</span>
							</label>
							<input
								type="text"
								className="form-control numerico"
								maxLength="4"
								name="anoFabricacaoVeiculo"
								value={atendimentoValues.dadosAtendimento.lead.anoFabricacaoVeiculo}
								onChange={onChangeEvent}
							/>
						</div>
					</div>
					<div className="col-12 col-md-2">
						<div className="form-group">
							<label>
								Ano Modelo<span className="campoObratorio">*</span>
							</label>
							<input
								type="text"
								className="form-control numerico"
								maxLength="4"
								name="anoModeloVeiculo"
								value={atendimentoValues.dadosAtendimento.lead.anoModeloVeiculo}
								onChange={onChangeEvent}
							/>
						</div>
					</div>
					<div className="col-12 col-md-4">
						<div className="form-group">
							<label>Tipo Utilização Dut<span className="campoObratorio">*</span></label>
							<select
								className="form-control"
								name="leadAutoTipoUtilizacaoDut"
								value={atendimentoValues.dadosAtendimento.lead.leadAutoTipoUtilizacaoDut}
								onChange={onChangeEvent}
							>
								<option className="disabled" value="" />
								{atendimentoValues.listaDut.map((dut) => (
									<option key={dut.dutCodigo} value={dut.dutCodigo}>
										{dut.dutDescricao}
									</option>
								))}
							</select>
						</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default FormularioLeadAuto;
