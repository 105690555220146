import React, { Fragment, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Anexos from '../../../../Components/Anexos/Anexos';
import Formulario from '../../../../Components/Cadastro/Formulario/Formulario';
import HistoricoLead from "../../../../Components/Lead/HistoricoLead/HistoricoLead";
import PesquisaVenda from '../../../../Components/Venda/PesquisaVenda/PesquisaVenda';
import { validarCpf, validarCnpj } from '../../../../Utils/globalFunctions';
import { CobrancaContext } from '../CobrancaContext';
import Help from "../../../../Components/Help/Help";
const FormularioCobranca = () => {
	const { values, setValues, getDetailHandler, showMessage } = useContext(CobrancaContext);
	let { id } = useParams();

	useEffect(() => {
		getDetailHandler(id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function onChangeInput(event) {
		const { name, value } = event.target;
		setValues({
			...values,
			data: {
				...values.data,
				[name]: value
			}
		});
	}

	function onChangeInputCpfCnpj(event) {
		const { name, value } = event.target;
		var numeroDocumento = value;
		if (numeroDocumento == null) numeroDocumento = '';

		numeroDocumento = numeroDocumento.replace(/[.-]/g, '');
		setValues({
			...values,
			data: {
				...values.data,
				[name]: numeroDocumento
			}
		});
	}
	function validarDocumentoDigitado(event) {

		const { value } = event.target;
		var numeroDocumento = value;
		if (numeroDocumento == null) numeroDocumento = '';

		numeroDocumento = numeroDocumento.replace(/[.-]/g, '');

		if (numeroDocumento.length !== 11 && numeroDocumento.length !== 14) {
			showMessage('erro', 'Documento digitado esta no formato inválido.');
			setValues({
				...values,
				data: {
					...values.data,
					leadNumDocumento: ''
				}
			});
			return;
		}

		if (numeroDocumento.length === 11) {
			if (!validarCpf(numeroDocumento)) {
				showMessage('erro', 'Cpf Inválido.');
				setValues({
					...values,
					data: {
						...values.data,
						leadNumDocumento: ''
					}
				});
				return;
			}
		}
		if (numeroDocumento.length === 14) {
			if (!validarCnpj(numeroDocumento)) {
				showMessage('erro', 'Cnpj Inválido.');
				setValues({
					...values,
					data: {
						...values.data,
						leadNumDocumento: ''
					}
				});
				return;
			}
		}
	}
	function callBackPesquisaVenda(vendaComDependencias) {
		setValues({
			...values,
			data: {
				...values.data,
				vendaId: vendaComDependencias.vendaId,
				leadNome: vendaComDependencias.clienteNomeCompleto,
				leadNumDocumento: vendaComDependencias.clienteNumDocumento,
				produtoId: vendaComDependencias.produtoId,
				seguradoraId: vendaComDependencias.seguradoraId,
				origemLeadId: vendaComDependencias.origemLeadId
			}
		});
	}
	return (
		<Fragment>
			<Formulario context={CobrancaContext}>
				<div className="col-12 col-md-12 form-group">
					<PesquisaVenda vendaId={values.data.vendaId} disabled={values.formDisabled} callBack={callBackPesquisaVenda} />
				</div>
				<div className="col-3 col-md-3 pt-4 form-group ">
					{values.data.leadId > 0 ?
						<HistoricoLead leadId={values.data.leadId} disabled={false} >
							<span className="link_primary">
								Histórico do Lead
							</span>
						</HistoricoLead> : null
					}
				</div>
				<div className="col-12" />
				<div className="col-12 col-md-5 form-group">
					<label>
						Nome<span className="campoObratorio">*</span>
					</label>
					<input
						disabled={values.formDisabled}
						className="form-control uppercase"
						type="text"
						maxLength="150"
						name="leadNome"
						value={values.data.leadNome}
						onChange={onChangeInput}
					/>
				</div>
				<div className="col-12 col-md-2 form-group">
					<label>
						CPF/CNPJ<span className="campoObratorio">*</span>
					</label>
					<input
						disabled={values.formDisabled}
						className="form-control"
						type="text"
						name="leadNumDocumento"
						value={values.data.leadNumDocumento}
						onChange={onChangeInputCpfCnpj}
						onBlur={validarDocumentoDigitado}
					/>
				</div>

				<div className="col-12 col-md-3 form-group">
					<label>
						Produto<span className="campoObratorio">*</span>
					</label>
					<select
						disabled={values.formDisabled}
						className="form-control"
						name="produtoId"
						value={values.data.produtoId}
						onChange={onChangeInput}
					>
						<option className="disabled" value="0" />
						{values.produtos.map((produto) => (
							<option key={produto.produtoId} value={produto.produtoId}>
								{produto.produtoNome}
							</option>
						))}
					</select>
				</div>

				<div className="col-12 col-md-2 form-group">
					<label>
						Data Venc. Repique<span className="campoObratorio">*</span>
					</label>
					<input
						disabled={values.formDisabled}
						className="form-control"
						type="date"
						name="cobrancaDataVencRepique"
						value={values.data.cobrancaDataVencRepique}
						onChange={onChangeInput}
					/>
				</div>

				<div className="col-12 col-md-3 form-group">
					<label>
						Seguradora<span className="campoObratorio">*</span>
					</label>
					<select
						disabled={values.formDisabled}
						className="form-control"
						name="seguradoraId"
						value={values.data.seguradoraId}
						onChange={onChangeInput}
					>
						<option className="disabled" value="0" />
						{values.seguradoras.map((seguradora) => (
							<option key={seguradora.seguradoraId} value={seguradora.seguradoraId}>
								{seguradora.seguradoraDescricao}
							</option>
						))}
					</select>
				</div>
				<div className="col-12 col-md-2 form-group">
					<label>Num. Apólice</label>
					<input
						disabled={values.formDisabled}
						className="form-control"
						type="text"
						maxLength="25"
						name="cobrancaApolice"
						value={values.data.cobrancaApolice}
						onChange={onChangeInput}
					/>
				</div>
				<div className="col-12 col-md-2 form-group">
					<label>
						Data Venc. Apólice
					</label>
					<input
						disabled={values.formDisabled}
						className="form-control"
						type="date"
						name="cobrancaDataFimApolice"
						value={values.data.cobrancaDataFimApolice}
						onChange={onChangeInput}
					/>
				</div>
				<div className="col-12 col-md-2 form-group">
					<label>Num. Parcelas</label>
					<input
						disabled={values.formDisabled}
						className="form-control"
						type="number"
						name="cobrancaNumParcela"
						value={values.data.cobrancaNumParcela}
						onChange={onChangeInput}
					/>
				</div>

				<div className="col-12 col-md-3 form-group">
					<label>
						Contato Cliente<span className="campoObratorio">*</span>
					</label>
					<select
						disabled={values.formDisabled}
						className="form-control"
						name="cobrancaContatoCliente"
						value={values.data.cobrancaContatoCliente}
						onChange={onChangeInput}
					>
						<option className="disabled" value="" />
						{values.tiposContato.map((tipoContato) => (
							<option key={tipoContato.codigo} value={tipoContato.codigo}>
								{tipoContato.descricao}
							</option>
						))}
					</select>
				</div>
				<div className="col-12 col-md-5 form-group">
					<label>
						Endosso
					</label>
					<input
						disabled={values.formDisabled}
						className="form-control "
						type="text"
						maxLength="40"
						name="cobrancaEndosso"
						value={values.data.cobrancaEndosso}
						onChange={onChangeInput}
					/>
				</div>
				<div className="col-12 col-md-2 form-group">
					<label>
						Data Venc. Parcela
					</label>
					<input
						disabled={values.formDisabled}
						className="form-control"
						type="date"
						name="cobrancaVencimentoParcela"
						value={values.data.cobrancaVencimentoParcela}
						onChange={onChangeInput}
					/>
				</div>

				<div className="col-12 col-md-3">
					<div className="form-group">
						<Help identificadorHelp="ORIGEMLEAD">
							<label>
								Origem
							</label>
						</Help>
						<select
							disabled={true}
							className="form-control"
							name="origemLeadId"
							value={values.data.origemLeadId}
							onChange={onChangeInput}
						>
							<option className="disabled" value="0" />
							{values.origensLead.map((origem) => (
								<option key={origem.origemLeadId} value={origem.origemLeadId}>
									{origem.origemLeadNome}
								</option>
							))}
						</select>
					</div>
				</div>
				<div className="col-12" />
				<div className="col-12 col-md-3 form-group">
					<label>
						Forma Pagamento<span className="campoObratorio">*</span>
					</label>
					<select
						disabled={values.formDisabled}
						className="form-control"
						name="cobrancaFormaPagto"
						value={values.data.cobrancaFormaPagto}
						onChange={onChangeInput}
					>
						<option className="disabled" value="" />
						{values.formasPagamento.map((formaPagamento) => (
							<option key={formaPagamento.codigo} value={formaPagamento.codigo}>
								{formaPagamento.descricao}
							</option>
						))}
					</select>
				</div>

				<div className="col-12 col-md-3 form-group">
					<label>
						Status<span className="campoObratorio">*</span>
					</label>
					<select
						disabled={values.formDisabled}
						className="form-control"
						name="cobrancaStatus"
						value={values.data.cobrancaStatus}
						onChange={onChangeInput}
					>
						<option className="disabled" value="" />
						{values.statusCobranca.map((statusCobranca) => (
							<option key={statusCobranca.codigo} value={statusCobranca.codigo}>
								{statusCobranca.descricao}
							</option>
						))}
					</select>
				</div>
				{values.data.cobrancaStatus === 'C' ? (
					<div className="col-12 col-md-3 form-group">
						<label>
							Data Cancelamento<span className="campoObratorio">*</span>
						</label>
						<input
							disabled={values.formDisabled}
							className="form-control"
							type="date"
							name="cobrancaDataCancelamento"
							value={values.data.cobrancaDataCancelamento}
							onChange={onChangeInput}
						/>
					</div>
				) : null}
				<div className="col-12 col-md-3 form-group">
					<label>Anexos</label>
					<Anexos id="formularioCobrancaContext" context={CobrancaContext} disabled={values.formDisabled} />
				</div>

				<div className="col-12 col-md-12 form-group">
					<label>Observação</label>
					<textarea
						disabled={values.formDisabled}
						className="form-control"
						type="text"
						rows="4"
						maxLength="10000"
						name="cobrancaObservacao"
						value={values.data.cobrancaObservacao ? values.data.cobrancaObservacao : ""}
						onChange={onChangeInput}
					/>
				</div>

				{values.data.observacaoHistorico ? (
					<div className="col-12 col-md-12 form-group">
						<label>Histórico</label>
						<textarea
							cols="30"
							rows="6"
							disabled={true}
							className="form-control"
							type="text"
							name="observacaoHistorico"
							value={values.data.observacaoHistorico}
							onChange={onChangeInput}
						/>
					</div>
				) : null}
			</Formulario>
		</Fragment>
	);
};

export default FormularioCobranca;
