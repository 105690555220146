/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext } from 'react';
import AppContext from '../../../../Store/AppContext';
import { Listar as ListarProduto } from '../../../../Services/Produto';
import { Listar as ListarCatalogo } from '../../../../Services/Catalogo';
import { removeItemFromArray } from '../../../../Utils/globalFunctions';
import {
	Listar as ListarTemplateWhatsAppBlip,
	Detalhes as DetalhesTemplateWhatsAppBlip,
	Remover as RemoverTemplateWhatsAppBlip,
	Incluir as IncluirTemplateWhatsAppBlip,
	Atualizar as AtualizarTemplateWhatsAppBlip
} from '../../../../Services/TemplateWhatsAppBlip';
import { useHistory } from 'react-router-dom';
import { validarPermissoesPagina } from '../../../../Utils/validarPermissoesPagina';
export const TemplateWhatsAppBlipContext = createContext();

const tituloTela = 'Cadastro de Template WhatsApp Blip';

const defaultData = {
	templateWhatsAppBlipId: 0,
	templateWhatsAppBlipNome: "",
	templateWhatsAppBlipTemplateBlip: "",
	templateWhatsAppBlipTipo: "",
	templateWhatsAppBlipContaBlip: "",
	parametros: []
};

const searchData = {
	templateWhatsAppBlipNome: ''
};

const columnsDefinition = [
	{
		text: 'Id',
		dataField: 'templateWhatsAppBlipId',
		hidden: true
	},
	{
		text: 'Nome Template',
		dataField: 'templateWhatsAppBlipNome',
		headerStyle: { width: '300px' },
		sort: true
	},
	{
		text: 'Template Blip',
		dataField: 'templateWhatsAppBlipTemplateBlip',
		headerStyle: { width: '250px' },
		sort: true
	},
	{
		text: 'Tipo',
		dataField: 'templateWhatsAppBlipTipo',
		headerStyle: { width: '100px' },
		sort: true
	},
	{
		text: 'Conta Blip',
		dataField: 'templateWhatsAppBlipContaBlip',
		headerStyle: { width: '100px' },
		sort: true
	},

];

const columnsDefinitionParametros = [
	{
		text: 'Id',
		dataField: 'templateWhatsAppBlipParametroSequencia',
		hidden: true
	},
	{
		text: 'Sequência',
		dataField: 'templateWhatsAppBlipParametroSequencia',
		headerStyle: { width: '50px' },
		sort: true
	},
	{
		text: 'Chave',
		dataField: 'templateWhatsAppBlipParametroChave',
		headerStyle: { width: '250px' },
		sort: true
	}
];


const defaultDataParametros = {
	templateWhatsAppBlipParametroId: 0,
	templateWhatsAppBlipId: 0,
	templateWhatsAppBlipParametroSequencia: "",
	templateWhatsAppBlipParametroTipo: "text",
	templateWhatsAppBlipParametroChave: ""
};

function initialState() {
	return {
		searchData: searchData,
		resultSearch: [],
		columnsDefinition: columnsDefinition,
		data: defaultData,
		formDisabled: true,
		parametros: [],
		catalogos: [],
		dataParametros: defaultDataParametros,
		columnsDefinitionParametros: columnsDefinitionParametros,
		templateWhatsAppBlipParametros: []
	};
}

const TemplateWhatsAppBlipProvider = ({ children }) => {
	const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(AppContext);
	const [values, setValues] = useState(initialState());
	let history = useHistory();

	async function loadDependences() {
		const permissoesPagina = validarPermissoesPagina();
		const responseListarProduto = await ListarProduto({});
		const responseListarCatalogo = await ListarCatalogo({ catalogoRotulo: "BLIP" });

		if (!responseListarProduto.success || !responseListarCatalogo.success) {
			showMessage('Erro', 'Erro Inesperado ao carregar dados basicos.');
			return { produtos: [], catalogos: [] };
		}

		const produtos = await responseListarProduto.produtos.filter((item) => item.produtoAtivo === 'A');
		const catalogos = await responseListarCatalogo.catalogos;
		return { permissoesPagina, produtos: produtos, catalogos: catalogos };
	}

	const resetScreen = () => {
		showLoading();
		async function carregarDependencias() {
			const dependencias = await loadDependences();
			setTituloTela(tituloTela);
			setValues({
				...initialState(),
				permissoesPagina: await dependencias.permissoesPagina,
				produtos: await dependencias.produtos,
				catalogos: await dependencias.catalogos
			});
			hideLoading();
		}
		carregarDependencias();
	};

	const searchHandler = async () => {
		showLoading();
		const responseListarTemplateWhatsAppBlip = await ListarTemplateWhatsAppBlip(values.searchData);

		if (!responseListarTemplateWhatsAppBlip.success) {
			showMessage('Erro', 'Erro Inesperado durante a pesquisa.');
			hideLoading();
			return;
		}

		const resultSearchData = await responseListarTemplateWhatsAppBlip.templatesWhatsAppBlip;
		resultSearchData.map((data) => {
			data.Id = data.templateWhatsAppBlipId;
			return data;
		});

		setValues({
			...values,
			resultSearch: resultSearchData
		});

		if (resultSearchData.length === 0) {
			showMessage('Alerta', 'Nenhum registro encontrado.');
		}
		hideLoading();
	};

	const removeHandler = async (id) => {
		showLoading();

		const responseRemover = await RemoverTemplateWhatsAppBlip(id);

		if (!responseRemover.success) {
			hideLoading();
			showMessage('Erro', `Erro durante a exclusão: ${responseRemover.message}`);
			return;
		}
		searchHandler();
		hideLoading();
		showMessage('Sucesso', 'Registro excluido.');
	};

	const getDetailHandler = async (id) => {
		showLoading();
		const dependencias = await loadDependences();

		if (id > 0) {
			const responseDetalhes = await DetalhesTemplateWhatsAppBlip(id);

			const { success, templateWhatsAppBlip } = await responseDetalhes;

			if (!success) {
				showMessage('Erro', 'Erro ao buscar os detalhes do registro.');
				setValues({
					...values,
					data: defaultData,
					permissoesPagina: await dependencias.permissoesPagina,
					catalogos: await dependencias.catalogos
				});
			}
			let templateWhatsAppBlipParametros = templateWhatsAppBlip.parametros;

			templateWhatsAppBlipParametros.map((templateWhatsAppBlipParametro) => {
				templateWhatsAppBlipParametro.id = templateWhatsAppBlipParametro.templateWhatsAppBlipParametroSequencia;
				return templateWhatsAppBlipParametro;
			});

			setValues({
				...values,
				data: { ...templateWhatsAppBlip },
				templateWhatsAppBlipParametros: templateWhatsAppBlipParametros,
				permissoesPagina: await dependencias.permissoesPagina,
				catalogos: await dependencias.catalogos
			});
		} else {
			setValues({
				...values,
				data: {
					...defaultData,
					templateWhatsAppBlipId: 0
				},
				formDisabled: false,
				permissoesPagina: await dependencias.permissoesPagina,
				catalogos: await dependencias.catalogos,
				templateWhatsAppBlipParametros: []
			});
		}
		hideLoading();
	};

	const createData = async () => {
		showLoading();
		const responseIncluir = await IncluirTemplateWhatsAppBlip(values.data);
		if (!responseIncluir.success) {
			hideLoading();
			showMessage('Erro', `Erro ao criar o registro: ${responseIncluir.message}`);
			responseIncluir.errors.map((erro) => showMessage('Erro', erro.errorMessage));

			return;
		}

		resetScreen();
		hideLoading();
		showMessage('Sucesso', 'Registro criado.');
		history.goBack();
	};

	const editHandler = () => {
		setValues({
			...values,
			formDisabled: false
		});
	};

	const updateData = async () => {
		showLoading();
		const responseAtualizar = await AtualizarTemplateWhatsAppBlip(values.data);
		if (!responseAtualizar.success) {
			hideLoading();
			showMessage('Erro', `Erro ao atualizar o registro: ${responseAtualizar.message}`);
			responseAtualizar.errors.map((erro) => showMessage('Erro', erro.errorMessage));

			return;
		}
		resetScreen();
		hideLoading();
		showMessage('Sucesso', 'Registro atualizado.');
		history.goBack();
	};

	const saveHandler = async () => {
		if (values.data.templateWhatsAppBlipId === 0) {
			await createData();
		} else {
			await updateData();
		}
	};

	const cancelHandler = () => {
		setValues({
			...values,
			data: defaultData,
			formDisabled: true
		});
	};

	const adionarParametro = () => {
		let parametroJaAdiocionado = values.templateWhatsAppBlipParametros.find(
			// eslint-disable-next-line eqeqeq
			(parametro) => parametro.templateWhatsAppBlipParametroSequencia == values.dataParametros.templateWhatsAppBlipParametroSequencia
				|| parametro.templateWhatsAppBlipParametroChave == values.dataParametros.templateWhatsAppBlipParametroChave
		);

		if (parametroJaAdiocionado) {
			showMessage('Alerta', 'Sequência ou Chave já incluído.');
			return;
		}

		var chave = values.dataParametros.templateWhatsAppBlipParametroChave;

		if (chave.indexOf('[') == -1 || chave.indexOf(']') == -1 || chave.indexOf(' ') > -1) {
			showMessage('Alerta', 'Formato de Chave Inválido, a chave deve seguir o formato seguinte [CHAVE_TESTE].');
			return;
		}
		values.dataParametros.templateWhatsAppBlipParametroChave = chave.toUpperCase();

		var templateWhatsAppBlipParametrosNew = values.templateWhatsAppBlipParametros;
		values.dataParametros.id = parseInt(values.dataParametros.templateWhatsAppBlipParametroSequencia);
		values.dataParametros.templateWhatsAppBlipParametroSequencia = parseInt(values.dataParametros.templateWhatsAppBlipParametroSequencia);
		templateWhatsAppBlipParametrosNew.push(values.dataParametros);
		setValues({
			...values,
			dataParametros: defaultDataParametros,
			templateWhatsAppBlipParametros: templateWhatsAppBlipParametrosNew,
			data: {
				...values.data,
				parametros: templateWhatsAppBlipParametrosNew
			}
		});
	};

	const removerParametro = (id) => {
		var templateWhatsAppBlipParametrosNew = values.templateWhatsAppBlipParametros;

		removeItemFromArray(templateWhatsAppBlipParametrosNew, templateWhatsAppBlipParametrosNew.find((supervisor) => supervisor.id === id));

		setValues({
			...values,
			dataParametros: defaultDataParametros,
			templateWhatsAppBlipParametros: templateWhatsAppBlipParametrosNew,
			data: {
				...values.data,
				parametros: templateWhatsAppBlipParametrosNew
			}
		});
	};

	return (
		<TemplateWhatsAppBlipContext.Provider
			value={{
				values,
				setValues,
				resetScreen,
				searchHandler,
				cancelHandler,
				removeHandler,
				saveHandler,
				getDetailHandler,
				editHandler,
				adionarParametro,
				removerParametro
			}}
		>
			{children}
		</TemplateWhatsAppBlipContext.Provider>
	);
};

export default TemplateWhatsAppBlipProvider;
