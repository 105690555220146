import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "Dut";
export const Listar = (tipoVeiculoId) =>
  RequestWebApi.get(`/${controller}/Listar`, {
		params: { tipoVeiculoId: tipoVeiculoId }
	})
    .then(function (response) {
      const { resultData, success } = response.data;
      const listaDut = resultData;
      return { success, listaDut };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { listaDut: [] };
    });
