import React from "react";
import "./TimeLineItem.css";
import { retornaDescricaoTipoAtendimento } from "../../../Utils/globalFunctions";

const TimeLineItem = ({
  atendimentoTipo = "",
  tipoContatoDescricao = "",
  atendimentoDataHora = "",
  atendimentoHora = "",
  atendimentoObservacao = "",
}) => {
  return (
    <li className="timeline-item">
      <div className="timeline-info">
        <span>
          {atendimentoDataHora}-{atendimentoHora}
        </span>
      </div>
      <div className="timeline-marker"></div>
      <div className="timeline-content">
        <div className="timeline-title">{`${retornaDescricaoTipoAtendimento(
          atendimentoTipo
        )}${tipoContatoDescricao ? " - " + tipoContatoDescricao : ""}`}</div>
        <p>{atendimentoObservacao}</p>
      </div>
    </li>
  );
};

export default TimeLineItem;
