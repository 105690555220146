/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext } from "react";
import AppContext from "../../../../Store/AppContext";
import { removeItemFromArray } from "../../../../Utils/globalFunctions";
import { Listar as ListarUsuario } from "../../../../Services/Usuario";
import {
  Listar as ListarJornadaTrabalhoFalta,
  Detalhes as DetalhesJornadaTrabalhoFalta,
  Remover as RemoverJornadaTrabalhoFalta,
  Incluir as IncluirJornadaTrabalhoFalta,
} from "../../../../Services/JornadaTrabalhoFalta";
import { useHistory } from "react-router-dom";
import { validarPermissoesPagina } from "../../../../Utils/validarPermissoesPagina";
export const JornadaTrabalhoFaltaContext = createContext();

const tituloTela = "Datas Offline";

const defaultData = {
  usuarioId: 0,
  datas: [],
};

const searchData = {
  usuarioNome: "",
};

const columnsDefinition = [
  {
    text: "Id",
    dataField: "usuarioId",
    hidden: true,
  },
  {
    text: "Nome Consultor",
    dataField: "usuarioNome",
    headerStyle: { width: "800px" },
    sort: true,
  },
];

const columnsDefinitionDataFalta = [
  {
    text: "Data",
    dataField: "data",
    headerStyle: { width: "500px" },
    sort: true,
  },
];

const defaultDataDataFalta = { data: "" };

function initialState() {
  return {
    searchData: searchData,
    resultSearch: [],
    columnsDefinition: columnsDefinition,
    data: defaultData,
    lockField: true,
    formDisabled: true,
    usuarios: [],
    dataDataFalta: defaultDataDataFalta,
    columnsDefinitionDataFalta: columnsDefinitionDataFalta,
    datasfalta: [],
  };
}

const JornadaTrabalhoFaltaProvider = ({ children }) => {
  const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(
    AppContext
  );
  const [values, setValues] = useState(initialState());
  let history = useHistory();

  async function loadDependences() {
    const permissoesPagina = validarPermissoesPagina();
    const responseListarUsuaro = await ListarUsuario({});

    let usuarios = [];

    if (!responseListarUsuaro.success) {
      showMessage("Erro", "Erro Inesperado ao carregar dados basicos.");
    } else {
      usuarios = await responseListarUsuaro.usuarios;
    }

    return {permissoesPagina:permissoesPagina, usuarios: usuarios };
  }

  const resetScreen = () => {
    showLoading();
    async function carregarDependencias() {
      const dependencias = await loadDependences();
      setTituloTela(tituloTela);
      setValues({
        ...initialState(),
        permissoesPagina: await dependencias.permissoesPagina,
        usuarios: await dependencias.usuarios,
      });
      hideLoading();
    }
    carregarDependencias();
  };

  const searchHandler = async () => {
    showLoading();
    const responseListarJornada = await ListarJornadaTrabalhoFalta(
      values.searchData
    );

    if (!responseListarJornada.success) {
      showMessage("Erro", "Erro Inesperado durante a pesquisa.");
      hideLoading();
      return;
    }

    let resultSearchData = await responseListarJornada.jornadasTrabalhoFalta;

    resultSearchData.map((data) => (data.Id = data.usuarioId));

    setValues({
      ...values,
      resultSearch: resultSearchData,
    });

    if (resultSearchData.length === 0) {
      showMessage("Alerta", "Nenhum registro encontrado.");
    }
    hideLoading();
  };

  const removeHandler = async (id) => {
    showLoading();

    const responseRemover = await RemoverJornadaTrabalhoFalta(id);

    if (!responseRemover.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro durante a exclusão: ${responseRemover.message}`
      );
      return;
    }
    searchHandler();
    hideLoading();
    showMessage("Sucesso", "Registro excluido.");
  };

  const getDetailHandler = async (id) => {
    showLoading();
    const dependencias = await loadDependences();

    let usuariosBase = await dependencias.usuarios;

    if (id > 0) {
      const responseDetalhes = await DetalhesJornadaTrabalhoFalta(id);
      const { success, usuarioId, datas } = await responseDetalhes;

      if (!success) {
        showMessage("Erro", "Erro ao buscar os detalhes do registro.");
        setValues({
          ...values,
          data: defaultData,
          permissoesPagina: await dependencias.permissoesPagina,
          usuarios: [],
          lockField: true,
        });
      }
      let datasfalta = datas;
      datasfalta.map(
        (data) => (data.id = data.data.replace("/", "").replace("/", ""))
      );

      setValues({
        ...values,
        data: { usuarioId, datas },
        permissoesPagina: await dependencias.permissoesPagina,
        usuarios: usuariosBase,
        datasfalta: datasfalta,
        lockField: true,
      });
    } else {
      setValues({
        ...values,
        data: {
          ...defaultData,
          usuarioId: 0,
        },
        formDisabled: false,
        lockField: false,
        permissoesPagina: await dependencias.permissoesPagina,
        usuarios: usuariosBase,
        datasfalta: [],
      });
    }
    hideLoading();
  };

  const editHandler = () => {
    setValues({
      ...values,
      formDisabled: false,
    });
  };

  const saveHandler = async () => {
    showLoading();
    const responseIncluir = await IncluirJornadaTrabalhoFalta(values.data);
    if (!responseIncluir.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao criar o registro: ${responseIncluir.message}`
      );
      responseIncluir.errors.map((erro) =>
        showMessage("Erro", erro.errorMessage)
      );

      return;
    }

    resetScreen();
    hideLoading();
    showMessage("Sucesso", "Registro criado.");
    history.goBack();
  };

  const cancelHandler = () => {
    setValues({
      ...values,
      data: defaultData,
      formDisabled: true,
    });
  };

  const adionarConsultor = () => {
    let dataJaAdiocionada = values.datasfalta.find(
      // eslint-disable-next-line eqeqeq
      (data) => data.id === values.dataDataFalta.id
    );

    if (dataJaAdiocionada) {
      showMessage("Alerta", "Data já incluída.");
      return;
    }

    var datasfaltaNew = values.datasfalta;
    datasfaltaNew.push(values.dataDataFalta);
    setValues({
      ...values,
      dataDataFalta: defaultDataDataFalta,
      datasfalta: datasfaltaNew,
      data: {
        ...values.data,
        datas: datasfaltaNew,
      },
    });
  };

  const removerConsultor = (id) => {
    var datasfaltaNew = values.datasfalta;

    removeItemFromArray(
      datasfaltaNew,
      datasfaltaNew.find((consultor) => consultor.id === id)
    );

    setValues({
      ...values,
      dataDataFalta: defaultDataDataFalta,
      datasfalta: datasfaltaNew,
      data: {
        ...values.data,
        datas: datasfaltaNew,
      },
    });
  };

  return (
    <JornadaTrabalhoFaltaContext.Provider
      value={{
        values,
        setValues,
        resetScreen,
        searchHandler,
        cancelHandler,
        removeHandler,
        saveHandler,
        getDetailHandler,
        editHandler,
        adionarConsultor,
        removerConsultor,
      }}
    >
      {children}
    </JornadaTrabalhoFaltaContext.Provider>
  );
};

export default JornadaTrabalhoFaltaProvider;
