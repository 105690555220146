import { RequestWebApi, validateRequestErrorStatus } from './Requests';

const controller = 'JornadaTrabalho';
export const Listar = (jornadaTrabalho) =>
	RequestWebApi.get(`/${controller}/Listar`, {
		params: { ...jornadaTrabalho }
	})
		.then((response) => {
			const { resultData, success } = response.data;
			const jornadasTrabalho = resultData;
			return { success, jornadasTrabalho };
		})
		.catch((error) => {
			validateRequestErrorStatus(error.response.status);
			return { jornadasTrabalho: [] };
		});

export const ListarConsultoresOnline = (listarOffline = false) =>
	RequestWebApi.get(`/${controller}/ListarConsultoresOnline`, {
		params: { listarOffline: listarOffline }
	})
		.then((response) => {
			const { resultData, success } = response.data;
			const consultoresOnline = resultData;
			return { success, consultoresOnline };
		})
		.catch((error) => {
			validateRequestErrorStatus(error.response.status);
			return { consultoresOnline: [] };
		});

export const Detalhes = (jornadaTrabalhoId) =>
	RequestWebApi.get(`/${controller}/Detalhes`, {
		params: { jornadaTrabalhoId: jornadaTrabalhoId }
	})
		.then((response) => {
			const { resultData, success } = response.data;
			const { jornadaTrabalho, consultores, dias } = resultData;
			return { success, jornadaTrabalho, consultores, dias };
		})
		.catch((error) => {
			validateRequestErrorStatus(error.response.status);
			const { success, message, errors } = error.response.data;
			return {
				success,
				message,
				errors,
				jornadaTrabalho: {},
				consultores: [],
				dias: {}
			};
		});

export const Incluir = (jornadaTrabalho) =>
	RequestWebApi.post(`/${controller}/Incluir`, jornadaTrabalho)
		.then((response) => {
			const { resultData, success } = response.data;
			const novaJornadaTrabalho = resultData;
			return { success, novaJornadaTrabalho };
		})
		.catch((error) => {
			validateRequestErrorStatus(error.response.status);
			const { success, message, errors } = error.response.data;
			return { success, message, errors };
		});

export const Atualizar = (jornadaTrabalho) =>
	RequestWebApi.put(`/${controller}/Atualizar`, jornadaTrabalho)
		.then((response) => {
			const { success } = response.data;
			return { success };
		})
		.catch((error) => {
			validateRequestErrorStatus(error.response.status);
			const { success, message, errors } = error.response.data;
			return { success, message, errors };
		});

export const Remover = (jornadaTrabalhoId) =>
	RequestWebApi.delete(`/${controller}/Remover`, {
		params: { jornadaTrabalhoId: jornadaTrabalhoId }
	})
		.then((response) => {
			const { success } = response.data;
			return { success };
		})
		.catch((error) => {
			validateRequestErrorStatus(error.response.status);
			const { success, message, errors } = error.response.data;
			return { success, message, errors };
		});
