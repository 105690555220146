import { RequestWebApi, validateRequestErrorStatus } from './Requests';

const controller = 'Atendimento';
export const ListarFilaAtendimento = (usuario) =>
	RequestWebApi.get(`/${controller}/ListarFilaAtendimento`, {
		params: { ...usuario }
	})
		.then(function(response) {
			const { resultData, success } = response.data;
			const filaAtendimento = resultData;
			return { success, filaAtendimento };
		})
		.catch(function(error) {
			validateRequestErrorStatus(error.response.status);
			const { success, message, errors } = error.response.data;
			return { success, message, errors, filaAtendimento: [] };
		});

export const RedirecionarFilaAtendimento = ({ usuarioId, filaAtendimentos }) =>
	RequestWebApi.post(`/${controller}/RedirecionarFilaAtendimento`, {
		usuarioId,
		filaAtendimentos
	})
		.then(function(response) {
			const { resultData, success } = response.data;
			const sucesso = resultData;
			return { success, sucesso };
		})
		.catch(function(error) {
			validateRequestErrorStatus(error.response.status);
			const { success, message, errors } = error.response.data;
			return { success, message, errors };
		});

export const ModificaFilaAtendimento = ({ usuarioId, filaAtendimentoId, produtoId }) =>
	RequestWebApi.post(`/${controller}/ModificaFilaAtendimento`, {
		usuarioId,
		filaAtendimentoId,
		produtoId
	})
		.then(function(response) {
			const { resultData, success } = response.data;
			const sucesso = resultData;
			return { success, sucesso };
		})
		.catch(function(error) {
			validateRequestErrorStatus(error.response.status);
			const { success, message, errors } = error.response.data;
			return { success, message, errors };
		});

export const ProcessarAtendimento = (dadosAtendimento, tipoAtendimento, tempoAtendimento) =>
	RequestWebApi.post(`/${controller}/ProcessarAtendimento`, {
		dadosAtendimento: {
			...dadosAtendimento,
			tipoAtendimento,
			tempoAtendimento
		}
	})
		.then(function(response) {
			const { resultData, success } = response.data;
			const sucesso = resultData;
			return { success, sucesso };
		})
		.catch(function(error) {
			validateRequestErrorStatus(error.response.status);
			const { success, message, errors } = error.response.data;
			return { success, message, errors };
		});

export const RetornarAtendimento = (filaAtendimentoId) =>
	RequestWebApi.get(`/${controller}/RetornarAtendimento`, {
		params: { filaAtendimentoId }
	})
		.then(function(response) {
			const { resultData, success } = response.data;
			const dadosAtendimento = resultData;
			return { success, dadosAtendimento };
		})
		.catch(function(error) {
			validateRequestErrorStatus(error.response.status);
			return { dadosAtendimento: {} };
		});
