import React, { useEffect, useState, useContext } from "react";
import "./Index.css";
import { RetornarUrlGrafico } from "../../Services/Grafico";
import AppContext from "../../Store/AppContext";
import { useParams } from "react-router-dom";
import { validarPermissoesPagina } from "../../Utils/validarPermissoesPagina";

const tituloTela = "Dashboard";

function initialState() {
  return {
    url: "",
  };
}

const Index = () => {
  const { setTituloTela } = useContext(AppContext);
  const [urlValues, setUrlValues] = useState(initialState());
  let { rotulo } = useParams();

  useEffect(() => {
    async function carregarDependencias() {
      setTituloTela(tituloTela);
      const permissoesPagina = await validarPermissoesPagina();
      const responseRetornarUrlGrafico = await RetornarUrlGrafico(rotulo);
      const urlGrafico = await responseRetornarUrlGrafico.url;
      setUrlValues({ permissoesPagina, url: urlGrafico });
    }
    carregarDependencias();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rotulo]);

  return (
    <div className="container-fluid pl-0 pt-0 pb-0">
      {urlValues.url ? (
        <iframe
          className="PageHome_iframe"
          title="Graficos"
          src={urlValues.url}
          frameBorder="false"
          allowFullScreen
        />
      ) : null}
    </div>
  );
};

export default Index;
