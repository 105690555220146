import React, { Fragment } from "react";
import Spinner from "react-spinkit";
import "./CarregandoDados.css";

const CarregandoDados = ({ message }) => {
  return <div className="row divContainerSpin">
    <div>
      <center>
        <Spinner name="folding-cube" fadeIn="none" color="coral" />
      </center>
      <br />
      <span className="divContainerMessage">{message}</span>
    </div>
  </div>
};

export default CarregandoDados;
