import React, { Fragment, useContext } from "react";
import Agendamento from "../Agendamento/Agendamento";
import Anexos from "../../Anexos/Anexos";

const FormularioContato = ({ context }) => {
  const {
    atendimentoValues,
    setAtendimentoValues,
    agendarContatoHandler,
  } = useContext(context);

  const formularioComErro =
    atendimentoValues.dadosAtendimento.contato.tipoContatoId === "0" ||
    !atendimentoValues.dadosAtendimento.contato.tipoContatoId ||
    !atendimentoValues.dadosAtendimento.contato.observacao;

  function onChangeInput(event) {
    const { name, value } = event.target;
    setAtendimentoValues({
      ...atendimentoValues,
      dadosAtendimento: {
        ...atendimentoValues.dadosAtendimento,
        contato: {
          ...atendimentoValues.dadosAtendimento.contato,
          [name]: value,
        },
      },
    });
  }

  return (
    <Fragment>
      <div className="row mt-3">
        <div className="col-12 col-md-4 form-group">
          <label>
            Selecione<span className="campoObratorio">*</span>
          </label>
          <select
            className="form-control"
            name="tipoContatoId"
            value={atendimentoValues.dadosAtendimento.contato.tipoContatoId}
            onChange={onChangeInput}
          >
            <option className="disabled" value="0"></option>
            {atendimentoValues.tiposContato.map((tipoContato) => (
              <option key={tipoContato.value} value={tipoContato.value}>
                {tipoContato.label}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-4 form-group">
          <label>Anexos</label>
          <Anexos id="AbaContato" context={context} />
        </div>
        <div className="col-12 form-group">
          <label>
            Observação<span className="campoObratorio">*</span>
          </label>
          <textarea
            cols="30"
            rows="4"
            className="form-control"
            name="observacao"
            value={atendimentoValues.dadosAtendimento.contato.observacao}
            onChange={onChangeInput}
          ></textarea>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-3">
          <Agendamento
            id="agendarContato"
            context={context}
            disabled={formularioComErro}
            onClickHandler={agendarContatoHandler}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default FormularioContato;
