import React from "react";
import FilaAtendimentoProvider from "./FilaAtendimentoContext";
import PesquisarFilaAtendimento from "./Events/PesquisarFilaAtendimento";

const Index = () => {
  return (
    <FilaAtendimentoProvider>
      <div className="container-fluid pl-3 pt-3 pb-3">
        <PesquisarFilaAtendimento />
      </div>
    </FilaAtendimentoProvider>
  );
};

export default Index;
