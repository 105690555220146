import React, { useContext, Fragment, useEffect } from "react";
import { UsuarioContext } from "../UsuarioContext";
import Pesquisar from "../../../../../Components/Cadastro/Pesquisar/Pesquisar";
import TabelaResultados from "../../../../../Components/Cadastro/TabelaResultados/TabelaResultados";

const PesquisarUsuario = () => {
  const { values, setValues, resetScreen } = useContext(UsuarioContext);

  useEffect(() => {
    resetScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      searchData: {
        ...values.searchData,
        [name]: value,
      },
    });
  }

  return (
    <Fragment>
      <Pesquisar context={UsuarioContext}>
        <div className="col-12 col-md-3">
          <label>Login</label>
          <input
            className="form-control"
            type="text"
            name="usuarioLogin"
            value={values.searchData.usuarioLogin}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-3">
          <label>Nome do usuário</label>
          <input
            className="form-control"
            type="text"
            name="usuarioNome"
            value={values.searchData.usuarioNome}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-3">
          <label>Perfil</label>
          <select
            className="form-control"
            name="grupoUsuarioId"
            value={values.searchData.grupoUsuarioId}
            onChange={onChangeInput}
          >
            <option className="disabled" value="0"></option>
            {values.perfis.map((perfil) => (
              <option key={perfil.grupoUsuarioId} value={perfil.grupoUsuarioId}>
                {perfil.grupoUsuarioNome}
              </option>
            ))}
          </select>
        </div>
      </Pesquisar>
      <TabelaResultados context={UsuarioContext} />
    </Fragment>
  );
};

export default PesquisarUsuario;
