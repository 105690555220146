/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import HistoricoLead from "../../../Components/Lead/HistoricoLead/HistoricoLead";
import { DownloadArquivo } from '../../../Services/Download';
import {
	Detalhes as DetalhesEndosso, Listar as ListarEndosso, ProcessarEndosso, Remover as RemoverEndosso
} from '../../../Services/Endosso';
import { IncluirLeadInterno, ListarArquivosLead, RemoverAnexosLead, UploadAnexosLead } from '../../../Services/Lead';
import { ListarListBoxPorRotulo } from '../../../Services/ListBox';
import { Listar as ListarProduto } from '../../../Services/Produto';
import { Listar as ListarSeguradora } from '../../../Services/Seguradora';
import { Listar as ListarTipoEndosso } from '../../../Services/TipoEndosso';
import { ListarSimples as ListarUsuario } from '../../../Services/Usuario';
import AppContext from '../../../Store/AppContext';
import { validarPermissoesPagina } from '../../../Utils/validarPermissoesPagina';
export const EndossoContext = createContext();

const tituloTela = 'Endosso';

const situacoes = [
	{ codigo: 'A', descricao: 'Aberto' },
	{ codigo: 'S', descricao: 'Simulado' },
	{ codigo: 'F', descricao: 'Finalizado' }
];

const getClassNameSituacao = (codigo) => {
	let estilo = 'success'
	switch (codigo) {
		case 'S':
			estilo = 'warning';
			break;
		case 'F':
			estilo = 'danger';
			break;
		default:
			break;
	}

	return `badge badge-${estilo}`;
};

const getSituacaoDesc = (codigo) => {

	let situacao = situacoes.find(s => s.codigo === codigo);

	if (!situacao) {
		situacao = situacoes.find(s => s.codigo === 'A');
	}
	return situacao.descricao;
};


const defaultData = {
	endossoId: 0,
	leadId: 0,
	leadNome: '',
	leadNumDocumento: '',
	produtoId: 0,
	seguradoraId: 0,
	endossoDataSolicitacao: '',
	tipoEndossoId: 0,
	endossoSolicitante: '',
	endossoObservacao: '',
	observacaoHistorico: '',
	endossoDataTransmissao: '',
	endossoStatusDocumentos: '',
	endossoFinalizado: 'A',
	endossoDataHora: '',
	usuarioId: 0,
	endossoPercComissao: 0,
	endossoValorLiqPremio: 0,
	endossoNumeroEndosso: '',
	apolice: '',
	endossoDataFimApolice: '',
	leadAuto: {
		leadId: 0,
		leadAutoPlaca: '',
		leadAutoFabricante: '',
		leadAutoModelo: '',
		leadAutoAnoModelo: '',
		leadAutoAnoFabricacao: ''
	},
	tipoEndossoSubstituicao: false
};

const searchData = {
	leadNumDocumento: '',
	endossoNomeCliente: '',
	seguradoraId: 0,
	tipoEndossoId: 0,
	endossoSolicitante: '',
	endossoFinalizado: '',
	endossoDataHoraDe: '',
	endossoDataHoraAte: '',
	endossoDataSolicitacaoDe: '',
	endossoDataSolicitacaoAte: ''
};

const columnsDefinition = [
	{
		text: 'Id',
		dataField: 'endossoId',
		hidden: true
	},
	{
		text: 'Data Solicitacão',
		dataField: 'endossoDataSolicitacao',
		headerStyle: { width: '100px' },
		sort: true
	},
	{
		text: 'Nome',
		dataField: 'leadNome',
		headerStyle: { width: '150px' },
		sort: true,
		formatter: (cell, row) => {
			return (
				<HistoricoLead leadId={row.leadId} disabled={false} >
					<span className="link_primary">
						{cell}
					</span>
				</HistoricoLead>
			);
		},
	},
	{
		text: 'Seguradora',
		dataField: 'seguradoraDescricao',
		headerStyle: { width: '100px' },
		sort: true
	},
	{
		text: 'Tipo Endosso',
		dataField: 'tipoEndossoDescricao',
		headerStyle: { width: '100px' },
		sort: true
	},
	{
		text: 'Situação',
		dataField: 'endossoFinalizado',
		headerStyle: { width: '55px' },
		sort: true,
		formatter: (cell, row) => {
			return <span className={getClassNameSituacao(cell)}>{getSituacaoDesc(cell)}</span>;
		}
	}
];

function initialState() {
	return {
		searchData: searchData,
		resultSearch: [],
		columnsDefinition: columnsDefinition,
		data: defaultData,
		seguradoras: [],
		produtos: [],
		tiposEndosso: [],
		usuarios: [],
		documentosEndosso: [],
		situacoes: situacoes,
		formDisabled: true

	};
}
let arquivos = [];
const EndossoProvider = ({ children }) => {
	const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(AppContext);
	const [values, setValues] = useState(initialState());
	let history = useHistory();

	const resetScreen = () => {
		showLoading();
		async function carregarDependencias() {
			const dependencias = await loadDependences();
			setTituloTela(tituloTela);
			setValues({
				...initialState(),
				permissoesPagina: await dependencias.permissoesPagina,
				seguradoras: await dependencias.seguradoras,
				produtos: await dependencias.produtos,
				documentosEndosso: await dependencias.documentosEndosso,
				tiposEndosso: await dependencias.tiposEndosso,
				usuarios: await dependencias.usuarios,
			});
			hideLoading();
		}
		arquivos = [];
		carregarDependencias();
	};

	const searchHandler = async () => {
		showLoading();
		const responseListar = await ListarEndosso(values.searchData);

		if (!responseListar.success) {
			showMessage('Erro', 'Erro Inesperado durante a pesquisa.');
			hideLoading();
			return;
		}

		const resultSearchData = await responseListar.origensLead;

		resultSearchData.map((data) => (data.Id = data.endossoId));

		setValues({
			...values,
			resultSearch: resultSearchData
		});

		if (resultSearchData.length === 0) {
			showMessage('Alerta', 'Nenhum registro encontrado.');
		}
		hideLoading();
	};

	const removeHandler = async (id) => {
		showLoading();
		const responseRemover = await RemoverEndosso(id);
		if (!responseRemover.success) {
			hideLoading();
			showMessage('Erro', `Erro durante a exclusão: ${responseRemover.message}`);
			return;
		}
		searchHandler();
		hideLoading();
		showMessage('Sucesso', 'Registro excluido.');
	};

	async function loadDependences() {
		let seguradoras = [];
		let produtos = [];
		let documentosEndosso = [];
		let tiposEndosso = [];
		let usuarios = [];

		const [
			permissoesPaginaAux,
			responseListarSeguradora,
			responseListarProduto,
			responseListarTipoEndosso,
			responseListarUsuario,
			responseListarListBoxPorRotuloDocumentosEndosso
		] = await Promise.all([
			validarPermissoesPagina(),
			ListarSeguradora({}),
			ListarProduto({}),
			ListarTipoEndosso({}),
			ListarUsuario({}),
			ListarListBoxPorRotulo('DocsEndosso')]);

		if (
			!responseListarSeguradora.success ||
			!responseListarProduto.success ||
			!responseListarTipoEndosso.success ||
			!responseListarUsuario.success ||
			!responseListarListBoxPorRotuloDocumentosEndosso.success
		) {
			showMessage('Erro', 'Erro Inesperado ao carregar dados basicos.');
		} else {
			seguradoras = await responseListarSeguradora.seguradoras.filter(
				(seguradora) => seguradora.seguradoraStatus === 'A'
			);
			produtos = await responseListarProduto.produtos.filter((item) => item.produtoAtivo === 'A');
			documentosEndosso = await responseListarListBoxPorRotuloDocumentosEndosso.items;
			tiposEndosso = await responseListarTipoEndosso.tiposEndosso.filter(
				(tipoEndosso) => tipoEndosso.tipoEndossoAtivo === 'A'
			);

			usuarios = await responseListarUsuario.usuarios;
		}

		return {
			permissoesPagina: permissoesPaginaAux,
			seguradoras: seguradoras,
			produtos: produtos,
			documentosEndosso: documentosEndosso,
			tiposEndosso: tiposEndosso,
			usuarios: usuarios
		};
	}

	const getDetailHandler = async (id) => {
		showLoading();
		setTituloTela(tituloTela);
		const dependencias = await loadDependences();

		if (id > 0) {
			const responseDetalhes = await DetalhesEndosso(id);

			const { success, endosso } = await responseDetalhes;

			if (!success) {
				showMessage('Erro', 'Erro ao buscar os detalhes do registro.');
				setValues({
					...values,
					data: defaultData,
					permissoesPagina: await dependencias.permissoesPagina,
					seguradoras: [],
					produtos: [],
					tiposEndosso: [],
					usuarios: [],
					documentosEndosso: []
				});
			}

			const responseListarArquivosLead = await ListarArquivosLead(endosso.leadId);

			if (!responseListarArquivosLead.success) {
				showMessage('Erro', responseListarArquivosLead.message);
				responseListarArquivosLead.errors.map((erro) => showMessage('Erro', erro.errorMessage));
				arquivos = [];
			}
			arquivos = await responseListarArquivosLead.arquivos;
			setValues({
				...values,
				data: endosso,
				permissoesPagina: await dependencias.permissoesPagina,
				seguradoras: await dependencias.seguradoras,
				produtos: await dependencias.produtos,
				tiposEndosso: await dependencias.tiposEndosso,
				usuarios: await dependencias.usuarios,
				documentosEndosso: await dependencias.documentosEndosso
			});
		} else {
			const responseIncluirLeadInterno = await IncluirLeadInterno();

			setValues({
				...values,
				data: {
					...defaultData,
					endossoId: 0,
					leadId: responseIncluirLeadInterno.leadId
				},
				formDisabled: false,
				permissoesPagina: await dependencias.permissoesPagina,
				seguradoras: await dependencias.seguradoras,
				produtos: await dependencias.produtos,
				tiposEndosso: await dependencias.tiposEndosso,
				usuarios: await dependencias.usuarios,
				documentosEndosso: await dependencias.documentosEndosso
			});
		}
		hideLoading();
	};

	const editHandler = () => {
		setValues({
			...values,
			formDisabled: false
		});
	};
	const validateFormHandler = () => {
		if (values.data.endossoFinalizado === 'F' && values.data.apolice === '') {
			showMessage('Erro', 'Informe a apólice.');
			return false;
		}

		return true;
	};
	const saveHandler = async () => {
		if (!validateFormHandler()) {
			return;
		}

		showLoading();
		const responseAtualizar = await ProcessarEndosso(values.data);
		if (!responseAtualizar.success) {
			hideLoading();
			showMessage('Erro', `Erro ao atualizar o registro: ${responseAtualizar.message}`);
			responseAtualizar.errors.map((erro) => showMessage('Erro', erro.errorMessage));
			return;
		}
		resetScreen();
		hideLoading();
		showMessage('Sucesso', 'Registro atualizado.');
		history.goBack();
	};

	const cancelHandler = () => {
		setValues({
			...values,
			data: defaultData,
			formDisabled: true
		});
	};

	const uploadAnexo = async (arquivo) => {
		showLoading('Aguarde, Importando Arquivo.');
		const responseUploadAnexosLead = await UploadAnexosLead(values.data.leadId, arquivo.nome, arquivo.arquivo);
		if (!responseUploadAnexosLead.success) {
			hideLoading();
			showMessage(
				'Erro',
				`Erro ao finalizar o atendimento: ${responseUploadAnexosLead.message
					? responseUploadAnexosLead.message
					: 'Indefinido'}`
			);
			if (responseUploadAnexosLead.errors) {
				responseUploadAnexosLead.errors.map((erro) => showMessage('Erro', erro.errorMessage));
			}
			return;
		}
		arquivos = responseUploadAnexosLead.arquivos;

		hideLoading();
		showMessage('Sucesso', 'Arquivo Importado com sucesso.');
	};

	const removerAnexo = async (arquivoId) => {
		showLoading('Aguarde, Removendo o arquivo.');
		const responseRemoverAnexoLead = await RemoverAnexosLead(values.data.leadId, arquivoId);
		if (!responseRemoverAnexoLead.success) {
			hideLoading();
			showMessage(
				'Erro',
				`Erro ao finalizar o atendimento: ${responseRemoverAnexoLead.message
					? responseRemoverAnexoLead.message
					: 'Indefinido'}`
			);
			if (responseRemoverAnexoLead.errors) {
				responseRemoverAnexoLead.errors.map((erro) => showMessage('Erro', erro.errorMessage));
			}
			return;
		}
		arquivos = await responseRemoverAnexoLead.arquivos;
		hideLoading();
		showMessage('Sucesso', 'Arquivo Removido.');
	};

	const downloadAnexo = async (arquivoId) => {
		showLoading('Aguarde, Baixando Arquivo.');
		await DownloadArquivo(arquivoId);
		hideLoading();
	};

	return (
		<EndossoContext.Provider
			value={{
				values,
				setValues,
				resetScreen,
				searchHandler,
				cancelHandler,
				removeHandler,
				saveHandler,
				getDetailHandler,
				editHandler,
				uploadAnexo,
				removerAnexo,
				downloadAnexo,
				arquivos,
				showMessage
			}}
		>
			{children}
		</EndossoContext.Provider>
	);
};

export default EndossoProvider;
