import React, { useContext, Fragment, useEffect } from "react";
import "./Agenda.css";
import Coluna from "../../../../Components/Agenda/Coluna/Coluna";
import { AgendaContext } from "../AgendaContext";
import Pesquisar from "./Pesquisar/Pesquisar";
import RedirecionarAgendamento from "./RedirecionarAgendamento/RedirecionarAgendamento";
const PesquisarAgenda = () => {
  const { resetScreen, agendaValues } = useContext(AgendaContext);

  useEffect(() => {
    resetScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <div className="default-title mb-3">
            {agendaValues.podeRedirecionar &&
              !agendaValues.habilitarSelecao && (
                <Pesquisar context={AgendaContext} />
              )}
            {agendaValues.podeRedirecionar && (
              <RedirecionarAgendamento context={AgendaContext} />
            )}
          </div>
          <div className="PageAgenda_ContainerScroll">
            {agendaValues.resultSearch.map((tipoAgendamento) => {
              return (
                <Coluna
                  key={tipoAgendamento.tipoAgendamentoId}
                  tipoAgendamentoId={tipoAgendamento.tipoAgendamentoId}
                  titulo={tipoAgendamento.tipoAgendamentoDescricao}
                  agendamentos={tipoAgendamento.agendamentos}
                  context={AgendaContext}
                />
              );
            })}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PesquisarAgenda;
