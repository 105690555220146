import React from "react";

const FormularioComplementar = ({ titulo, children }) => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="default-title">{titulo}</div>
          <div className="card shadow-sm mb-4">
            <div className="row">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormularioComplementar;
