import { RequestWebApi, validateRequestErrorStatus } from './Requests'

const controller = 'Relatorio'

export const ListarRelatorios = (modulo) =>
  RequestWebApi.get(`/${controller}/ListarRelatorios`, {
    params: { modulo },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const relatorios = resultData;
      return { success, relatorios };

    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { relatorios: [] };
    });

export const ListarRelatoriosGrupoUsuario = (grupoUsuarioId) =>
  RequestWebApi.get(`/${controller}/ListarRelatoriosGrupoUsuario`, {
    params: { grupoUsuarioId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const relatorios = resultData;
      return { success, relatorios };

    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { relatorios: [] };
    });

export const ListarRelatoriosUsuario = (usuarioId) =>
  RequestWebApi.get(`/${controller}/ListarRelatoriosUsuario`, {
    params: { usuarioId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const relatorios = resultData;
      return { success, relatorios };

    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { relatorios: [] };
    });


export const NegociosFechados = (searchData) =>
  RequestWebApi.get(`/${controller}/NegociosFechados`, {
    params: { ...searchData },
    responseType: 'blob',
  })
    .then(function (response) {
      const type = response.headers['content-type']
      const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'NegociosFechados'
      link.click()
      return { success: true }
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status)
      return { success: false }
    })


export const UltimoAtendimentoLead = (searchData) =>
  RequestWebApi.get(`/${controller}/UltimoAtendimentoLead`, {
    params: { ...searchData },
    responseType: 'blob',
  })
    .then(function (response) {
      const type = response.headers['content-type']
      const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'UltimoAtendimentoLead'
      link.click()
      return { success: true }
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status)
      return { success: false }
    })


export const ConversaoLead = (searchData) =>
  RequestWebApi.get(`/${controller}/ConversaoLead`, {
    params: { ...searchData },
    responseType: 'blob',
  })
    .then(function (response) {
      const type = response.headers['content-type']
      const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'ConversaoLead'
      link.click()
      return { success: true }
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status)
      return { success: false }
    })
