import React, { Fragment, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useParams } from 'react-router-dom';
import Anexos from '../../../../Components/Anexos/Anexos';
import Formulario from '../../../../Components/Cadastro/Formulario/Formulario';
import HistoricoLead from "../../../../Components/Lead/HistoricoLead/HistoricoLead";
import PesquisaVenda from '../../../../Components/Venda/PesquisaVenda/PesquisaVenda';
import { validarCpf, validarCnpj } from '../../../../Utils/globalFunctions';
import { SinistroContext } from '../SinistroContext';
import FormularioLeadAuto from "../../../../Components/BackOffice/FormulariosEspecificosProduto/FormularioLeadAuto";
import FormularioComplementar from "../../../../Components/Cadastro/FormularioComplementar/FormularioComplementar";
import DataTable from '../../../../Components/DataTable/DataTable';
import SinistroTerceiro from '../Components/SinistroTerceiro/SinistroTerceiro';
const images = require.context("../../../../assets/img/icones/pages", true);

const iconeEdit = images("./edit.png");
const FormularioSinistro = () => {
	const { values, setValues, getDetailHandler, showMessage } = useContext(SinistroContext);
	let { id } = useParams();


	const columnsDefinitionTerceiros = [
		{
			text: 'sinistroTerceirosId',
			dataField: 'sinistroTerceirosId',
			hidden: true
		},
		{
			text: "Nome Terceiro",
			dataField: "sinistroTerceirosNome",
			headerStyle: { width: '250px' },
			sort: true,
			formatter: (cell, row) => {
				return (
					<SinistroTerceiro
						key={"VIEW_" + row.sinistroTerceirosId}
						id={"VIEW_" + row.sinistroTerceirosId}
						sinistroTerceiro={row}
						disabled={true}
						confirmarHandler={null}>
						<span className="link_primary">
							{cell}
						</span>
					</SinistroTerceiro>
				);
			},

		},
		{
			text: 'Nº Aviso Seg.',
			dataField: 'sinistroTerceirosAvisoSeguradora',
			headerStyle: { width: '70px' },
			sort: true
		},
		{
			text: 'Ddd',
			dataField: 'sinistroTerceirosDdd',
			headerStyle: { width: '25px' },
			sort: true
		},
		{
			text: 'Telefone',
			dataField: 'sinistroTerceirosTelefone',
			headerStyle: { width: '70px' },
			sort: true
		}
	];

	useEffect(() => {
		getDetailHandler(id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function onChangeInput(event) {
		const { name, value } = event.target;
		setValues({
			...values,
			data: {
				...values.data,
				[name]: value
			}
		});
	}

	function onChangeInputCpfCnpj(event) {
		const { name, value } = event.target;
		var numeroDocumento = value;
		if (numeroDocumento == null) numeroDocumento = '';

		numeroDocumento = numeroDocumento.replace(/[.-]/g, '');
		setValues({
			...values,
			data: {
				...values.data,
				[name]: numeroDocumento
			}
		});
	}
	function onChangeCheck(event) {
		const { name, checked } = event.target;
		setValues({
			...values,
			data: {
				...values.data,
				[name]: checked
			}
		});
	}

	function validarDocumentoDigitado(event) {

		const { value } = event.target;
		var numeroDocumento = value;
		if (numeroDocumento == null) numeroDocumento = '';

		numeroDocumento = numeroDocumento.replace(/[.-]/g, '');

		if (numeroDocumento.length !== 11 && numeroDocumento.length !== 14) {
			showMessage('erro', 'Documento digitado esta no formato inválido.');
			setValues({
				...values,
				data: {
					...values.data,
					leadNumDocumento: ''
				}
			});
			return;
		}

		if (numeroDocumento.length === 11) {
			if (!validarCpf(numeroDocumento)) {
				showMessage('erro', 'Cpf Inválido.');
				setValues({
					...values,
					data: {
						...values.data,
						leadNumDocumento: ''
					}
				});
				return;
			}
		}
		if (numeroDocumento.length === 14) {
			if (!validarCnpj(numeroDocumento)) {
				showMessage('erro', 'Cnpj Inválido.');
				setValues({
					...values,
					data: {
						...values.data,
						leadNumDocumento: ''
					}
				});
				return;
			}
		}
	}
	function callBackPesquisaVenda(vendaComDependencias) {
		setValues({
			...values,
			data: {
				...values.data,
				vendaId: vendaComDependencias.vendaId,
				sinistroNomeCliente: vendaComDependencias.clienteNomeCompleto,
				leadNumDocumento: vendaComDependencias.clienteNumDocumento,
				sinistroDddCliente: vendaComDependencias.clienteDddCelular,
				sinistroTelCliente: vendaComDependencias.clienteNumCelular,
				produtoId: vendaComDependencias.produtoId,
				seguradoraId: vendaComDependencias.seguradoraId
			}
		});
	}


	let removeSinistroTerceiroHandler = (sinistroTerceirosId) => {

		let itens = values.data.terceiros ?? [];
		let novosItens = [];
		let i = 0;
		for (i in itens) {
			if (itens[i].sinistroTerceirosId != sinistroTerceirosId) {
				novosItens.push(itens[i]);
			}
		}
		setValues({
			...values,
			data: {
				...values.data,
				terceiros: novosItens,
			},
		});
	}

	function confirmarItemHandler(sinistroTerceiro) {
		let itens = values.data.terceiros ?? [];
		let i = 0;
		if (itens.find(x => x.sinistroTerceirosId == sinistroTerceiro.sinistroTerceirosId)) {
			for (i in itens) {
				if (itens[i].sinistroTerceirosId == sinistroTerceiro.sinistroTerceirosId) {
					itens[i] = sinistroTerceiro;
				}
			}
		} else {
			itens = [...itens, sinistroTerceiro]
		}
		setValues({
			...values,
			data: {
				...values.data,
				terceiros: itens,
			},
		});
	}



	return (
		<Fragment>
			<Formulario context={SinistroContext}>
				<div className="col-12 col-md-12 form-group">
					<PesquisaVenda vendaId={values.data.vendaId} disabled={values.formDisabled} callBack={callBackPesquisaVenda} />
				</div>
				{values.data.sinistroId > 0 ? (
					<div className="col-12 col-md-2 form-group">
						<label>Numero Aviso : {values.data.sinistroId}</label>
					</div>
				) : null}
				{values.data.sinistroId > 0 ? (
					<div className="col-12 col-md-4 form-group">
						<label>Aviso do Corretor : {values.data.sinistroDataHora}</label>
					</div>
				) : null}
				<div className="col-12 col-md-12 form-group" />
				<div className="col-12 col-md-12 pt-4 form-group ">
					{values.data.leadId > 0 ?
						<HistoricoLead leadId={values.data.leadId} disabled={false} >
							<span className="link_primary">
								Histórico do Lead
							</span>
						</HistoricoLead> : null
					}
				</div>
				<div className="col-12 col-md-2 form-group">
					<label>
						CPF/CNPJ<span className="campoObratorio">*</span>
					</label>
					<input
						disabled={values.formDisabled}
						className="form-control"
						type="text"
						name="leadNumDocumento"
						value={values.data.leadNumDocumento}
						onChange={onChangeInputCpfCnpj}
						onBlur={validarDocumentoDigitado}
					/>
				</div>
				<div className="col-12 col-md-4 form-group">
					<label>
						Nome
						<span className="campoObratorio">*</span>
					</label>
					<input
						disabled={values.formDisabled}
						className="form-control uppercase"
						type="text"
						maxLength="150"
						name="sinistroNomeCliente"
						value={values.data.sinistroNomeCliente}
						onChange={onChangeInput}
					/>
				</div>
				<div className="col-12 col-md-3 form-group">
					<label>Num. Aviso Seg.</label>
					<input
						disabled={values.formDisabled}
						className="form-control uppercase"
						type="text"
						maxLength="50"
						name="sinistroAvisoSegCliente"
						value={values.data.sinistroAvisoSegCliente}
						onChange={onChangeInput}
					/>
				</div>
				<div className="col-12 col-md-1 form-group">
					<label>
						DDD
						<span className="campoObratorio">*</span>
					</label>
					<input
						disabled={values.formDisabled}
						className="form-control numerico"
						type="text"
						maxLength="2"
						name="sinistroDddCliente"
						value={values.data.sinistroDddCliente}
						onChange={onChangeInput}
					/>
				</div>

				<div className="col-12 col-md-2 form-group">
					<label>
						Telefone
						<span className="campoObratorio">*</span>
					</label>
					<input
						disabled={values.formDisabled}
						className="form-control numerico"
						type="text"
						name="sinistroTelCliente"
						value={values.data.sinistroTelCliente}
						onChange={onChangeInput}
					/>
				</div>
				<div className="col-12 col-md-12 form-group" />

				{values.formDisabled ? null :
					<div className="col-12 col-md-2 form-group">
						<SinistroTerceiro disabled={values.formDisabled} confirmarHandler={confirmarItemHandler}>
							<button
								disabled={values.formDisabled}
								style={{ marginTop: '10px', marginBottom: '10px' }}
								type="button"
								className="btn btn-default buttonFormularios">
								Incluir Terceiro
							</button>
						</SinistroTerceiro>
					</div>
				}
				<div className="col-12 col-md-12 form-group">
					<DataTable
						data={values.data.terceiros ?? []}
						columnsDefinition={columnsDefinitionTerceiros}
						campoIdentificador="sinistroTerceirosId"
						removeHandler={values.formDisabled ? null : removeSinistroTerceiroHandler}
						cunstomSelectButton={values.formDisabled ? null : (data) => {
							return (<SinistroTerceiro
								key={data.sinistroTerceirosId}
								id={data.sinistroTerceirosId}
								sinistroTerceiro={data}
								disabled={values.formDisabled}
								confirmarHandler={confirmarItemHandler}>
								<button
									type="button"
									className="btn btn-info btn-sm">
									<img src={iconeEdit} alt="" />
								</button>
							</SinistroTerceiro>);

						}}
					/>
				</div>

				<div className="col-12 col-md-12 form-group" />

				<div className="col-12 col-md-3 form-group">
					<label>
						Ramo
						<span className="campoObratorio">*</span>
					</label>
					<select
						disabled={values.formDisabled}
						className="form-control"
						name="produtoId"
						value={values.data.produtoId}
						onChange={onChangeInput}
					>
						<option className="disabled" value="0" />
						{values.produtos.map((produto) => (
							<option key={produto.produtoId} value={produto.produtoId}>
								{produto.produtoNome}
							</option>
						))}
					</select>
				</div>

				<div className="col-12 col-md-3 form-group">
					<label>
						Seguradora
						<span className="campoObratorio">*</span>
					</label>
					<select
						disabled={values.formDisabled}
						className="form-control"
						name="seguradoraId"
						value={values.data.seguradoraId}
						onChange={onChangeInput}
					>
						<option className="disabled" value="0" />
						{values.seguradoras.map((seguradora) => (
							<option key={seguradora.seguradoraId} value={seguradora.seguradoraId}>
								{seguradora.seguradoraDescricao}
							</option>
						))}
					</select>
				</div>

				<div className="col-12 col-md-3 form-group">
					<label>
						Tipo do Sinistro
						<span className="campoObratorio">*</span>
					</label>
					<select
						disabled={values.formDisabled}
						className="form-control"
						name="tipoSinistroId"
						value={values.data.tipoSinistroId}
						onChange={onChangeInput}
					>
						<option className="disabled" value="0" />
						{values.tiposSinistro.map((tipoSinistro) => (
							<option key={tipoSinistro.tipoSinistroId} value={tipoSinistro.tipoSinistroId}>
								{tipoSinistro.tipoSinistroDescricao}
							</option>
						))}
					</select>
				</div>

				<div className="col-12 col-md-3 form-group">
					<label>
						Situação
						<span className="campoObratorio">*</span>
					</label>
					<select
						disabled={values.formDisabled}
						className="form-control"
						name="situacaoSinistroId"
						value={values.data.situacaoSinistroId}
						onChange={onChangeInput}
					>
						<option className="disabled" value="0" />
						{values.situacoesSinistro.map((situacaoSinistro) => (
							<option
								key={situacaoSinistro.situacaoSinistroId}
								value={situacaoSinistro.situacaoSinistroId}
							>
								{situacaoSinistro.situacaoSinistroDescricao}
							</option>
						))}
					</select>
				</div>

				<div className="col-12 col-md-2 form-group">
					<label>Aviso Seguradora</label>
					<input
						disabled={values.formDisabled}
						className="form-control"
						type="date"
						name="sinistroDataAvisoSeguradora"
						value={values.data.sinistroDataAvisoSeguradora}
						onChange={onChangeInput}
					/>
				</div>

				<div className="col-12 col-md-2 form-group">
					<label>Data apólice</label>
					<input
						disabled={values.formDisabled}
						className="form-control"
						type="date"
						name="dataApolice"
						value={values.data.dataApolice}
						onChange={onChangeInput}
					/>
				</div>

				<div className="col-12 col-md-2 form-group">
					<label>Apólice</label>
					<input
						disabled={values.formDisabled}
						className="form-control"
						type="text"
						maxLength="50"
						name="numeroApolice"
						value={values.data.numeroApolice}
						onChange={onChangeInput}
					/>
				</div>

				<div className="col-12 col-md-2 form-group">
					<label>Data acidente</label>
					<input
						disabled={values.formDisabled}
						className="form-control"
						type="date"
						name="sinistroDataAcidente"
						value={values.data.sinistroDataAcidente}
						onChange={onChangeInput}
					/>
				</div>
				<div className="col-12 col-md-2 form-group">
					<label>
						Data retorno<span className="campoObratorio">*</span>
					</label>
					<input
						disabled={values.formDisabled}
						className="form-control"
						type="date"
						name="sinistroDataRetorno"
						value={values.data.sinistroDataRetorno}
						onChange={onChangeInput}
					/>
				</div>
				<div className="col-12 col-md-12 form-group" />

				<div className="col-12 col-md-2 form-group">
					<label>Valor da Indenização</label>
					<input
						disabled={values.formDisabled}
						className="form-control"
						type="text"
						name="sinistroVlrIndenizacao"
						value={values.data.sinistroVlrIndenizacao}
						onChange={onChangeInput}
					/>
				</div>
				<div className="col-12 col-md-2 form-group">
					<label>Valor da Ind. Terceiro</label>
					<input
						disabled={values.formDisabled}
						className="form-control"
						type="text"
						name="sinistroVlrIndenizacaoTerceiro"
						value={values.data.sinistroVlrIndenizacaoTerceiro}
						onChange={onChangeInput}
					/>
				</div>
				<div className="col-12 col-md-2 form-group">
					<label>Data da Liquidação</label>
					<input
						disabled={values.formDisabled}
						className="form-control"
						type="date"
						name="sinistroDataLiquidacao"
						value={values.data.sinistroDataLiquidacao}
						onChange={onChangeInput}
					/>
				</div>
				<div className="col-12 col-md-2 form-group">
					<label>Finalizado?</label>
					<div className="col-12 col-md-2 ">
						<div className="custom-control custom-switch float-center">
							<input
								disabled={values.formDisabled}
								checked={values.data.sinistroFinalizado}
								type="checkbox"
								className="custom-control-input"
								id="sinistroFinalizado"
								name="sinistroFinalizado"
								onChange={onChangeCheck}
							/>
							<label className="custom-control-label" htmlFor="sinistroFinalizado" />
						</div>
					</div>
				</div>
				<div className="col-12 col-md-2 form-group">
					<label>Anexos</label>
					<Anexos id="formularioSinistroContext" context={SinistroContext} disabled={values.formDisabled} />
				</div>
				<div className="col-12 col-md-12 form-group">
					<label>Observação</label>
					<textarea
						disabled={values.formDisabled}
						className="form-control"
						type="text"
						rows="4"
						maxLength="1000"
						name="sinistroObservacao"
						value={values.data.sinistroObservacao ? values.data.sinistroObservacao : ""}
						onChange={onChangeInput}
					/>
				</div>
				{values.data.observacaoHistorico ? (
					<div className="col-12 col-md-12 form-group">
						<label>Histórico</label>
						<textarea
							cols="30"
							rows="6"
							disabled={true}
							className="form-control"
							type="text"
							name="observacaoHistorico"
							value={values.data.observacaoHistorico}
							onChange={onChangeInput}
						/>
					</div>
				) : null}
			</Formulario>
			{values.produto.produtoFormAuxLead ||
				values.produto.produtoFormAuxVenda ? (
				<FormularioComplementar titulo="Dados Complementares">
					<FormularioLeadAuto
						produto={values.produto}
						leadAuto={values.data.leadAuto}
					/>
				</FormularioComplementar>
			) : null}
		</Fragment>
	);
};

export default FormularioSinistro;
