/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext, useEffect } from "react";
import AppContext from "../../../../Store/AppContext";
import { ImportarCobrancas } from "../../../../Services/Cobranca";
import { DownloadTemplate } from "../../../../Services/Download";
import { validarPermissoesPagina } from "../../../../Utils/validarPermissoesPagina";

const tituloTela = "Importar Cobrança";
export const ImportarCobrancaContext = createContext();

function initialState() {
  return {
    arquivo: null,
    resultadosImportacao: [],
    totalCobrancasImportados: 0,
    totalCobrancasComSucesso: 0,
    totalCobrancasComErro: 0,
    permissoesPagina: null,
  };
}

const ImportarArquivo = async (arquivo) => {
  const result = await ImportarCobrancas(arquivo);
  return result;
};

const ImportarCobrancaProvider = ({ children }) => {
  const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(
    AppContext
  );
  const [values, setValues] = useState(initialState());

  useEffect(() => {
    showLoading();
    async function carregarDependencias() {
      const permissoesPagina = await validarPermissoesPagina();

      setValues({
        ...values,
        permissoesPagina: permissoesPagina,
      });
      hideLoading();
      setTituloTela(tituloTela);
    }

    carregarDependencias();
  }, []);

  async function importar() {
    showLoading();

    if (!values.arquivo) {
      showMessage("Erro", "Arquivo não Informado.");
      hideLoading();
      return;
    }

    const result = await ImportarArquivo(values.arquivo);

    if (result.success) {
      const resultadosImportacao = result.resultadosImportacao;

      const totalCobrancasImportados = result.resultadosImportacao.length;

      const totalCobrancasComSucesso = result.resultadosImportacao.filter(
        (resultadoImportacao) => resultadoImportacao.sucesso
      ).length;

      const totalCobrancasComErro = result.resultadosImportacao.filter(
        (resultadoImportacao) => !resultadoImportacao.sucesso
      ).length;

      setValues({
        ...values,
        resultadosImportacao: resultadosImportacao,
        totalCobrancasImportados: totalCobrancasImportados,
        totalCobrancasComSucesso: totalCobrancasComSucesso,
        totalCobrancasComErro: totalCobrancasComErro,
      });

      showMessage("Sucesso", "Operação Realizada com Sucesso.");
    } else {
      showMessage("Erro", "Erro durante a gravação do Cobranca.");
      result.errors.map((erro) => showMessage("Erro", erro.errorMessage));
    }

    hideLoading();
  }
  const downloadTemplate = async () => {
    const result = await DownloadTemplate("Cobrancas.xlsx");
    return result;
  };
  return (
    <ImportarCobrancaContext.Provider
      value={{
        values,
        setValues,
        importar,
        downloadTemplate,
      }}
    >
      {children}
    </ImportarCobrancaContext.Provider>
  );
};

export default ImportarCobrancaProvider;
