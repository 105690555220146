import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "CarsystemApiConsultaHdi";
export const Listar = (carsystemApiConsultaHdi) =>
  RequestWebApi.get(`/${controller}/Listar`, {
    params: { ...carsystemApiConsultaHdi },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const carsystemApiConsultaHdiList = resultData;
      return { success, carsystemApiConsultaHdiList };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { carsystemApiConsultaHdiList: [] };
    });

export const ListarUf = () =>
  RequestWebApi.get(`/${controller}/ListarUf`)
    .then(function (response) {
      const { resultData, success } = response.data;
      const carsystemApiConsultaHdiUfList = resultData;
      return { success, Ufs: carsystemApiConsultaHdiUfList };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { Ufs: [] };
    });

export const ListarCidade = () =>
  RequestWebApi.get(`/${controller}/ListarCidade`)
    .then(function (response) {
      const { resultData, success } = response.data;
      const carsystemApiConsultaHdiCidadeList = resultData;
      return { success, Cidades: carsystemApiConsultaHdiCidadeList };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { Cidades: [] };
    });

export const ListarUtilizacao = () =>
  RequestWebApi.get(`/${controller}/ListarUtilizacao`)
    .then(function (response) {
      const { resultData, success } = response.data;
      const carsystemApiConsultaHdiUtilizacaoList = resultData;
      return { success, Ultilizacoes: carsystemApiConsultaHdiUtilizacaoList };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { Ultilizacoes: [] };
    });

export const Detalhes = (carsystemApiConsultaHdiId) =>
  RequestWebApi.get(`/${controller}/Detalhes`, {
    params: { carsystemApiConsultaHdiId: carsystemApiConsultaHdiId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const carsystemApiConsultaHdi = resultData;
      return { success, carsystemApiConsultaHdi };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, carsystemApiConsultaHdi: {} };
    });

export const Incluir = (carsystemApiConsultaHdi) =>
  RequestWebApi.post(`/${controller}/Incluir`, carsystemApiConsultaHdi)
    .then(function (response) {
      const { resultData, success } = response.data;
      const novoProduto = resultData;
      return { success, novoProduto };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Atualizar = (carsystemApiConsultaHdi) =>
  RequestWebApi.put(`/${controller}/Atualizar`, carsystemApiConsultaHdi)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const AtualizarLista = (dataList) =>
  RequestWebApi.put(`/${controller}/AtualizarLista`, {
    items: dataList,
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (carsystemApiConsultaHdiId) =>
  RequestWebApi.delete(`/${controller}/Remover`, {
    params: { carsystemApiConsultaHdiId: carsystemApiConsultaHdiId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });
