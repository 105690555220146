import React, { useContext, Fragment, useEffect } from 'react'
import { NegociosFechadosContext } from '../NegociosFechadosContext'
import PesquisarRelatorio from '../../../../../Components/PesquisarRelatorio/PesquisarRelatorio'
import GroupBox from '../../../../../Components/GroupBox/GroupBox'

const PesquisarNegociosFechados = () => {
  const { values, setValues, resetScreen } = useContext(NegociosFechadosContext)

  useEffect(() => {
    resetScreen()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onChangeInput(event) {
    const { name, value } = event.target
    setValues({
      ...values,
      searchData: {
        ...values.searchData,
        [name]: value,
      },
    })
  }

  return (
    <Fragment>
      <PesquisarRelatorio context={NegociosFechadosContext}>
        <div className="col-12 col-md-4 form-group">
          <label>Consultor</label>
          <select
            className="form-control"
            name="usuarioId"
            value={values.searchData.usuarioId}
            onChange={onChangeInput}
          >
            <option className="disabled" value={0}></option>
            {values.usuarios.map((usuario) => (
              <option key={usuario.usuarioId} value={usuario.usuarioId}>
                {usuario.usuarioNome}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-2 form-group">
          <label>Mes/Ano</label>
          <input
            className="form-control mesano"
            type="text"
            name="mesAno"
            value={values.searchData.mesAno}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-3 form-group">
          <label>Status</label>
          <select
            className="form-control"
            name="tipoContatoId"
            value={values.searchData.tipoContatoId}
            onChange={onChangeInput}
          >
            <option className="disabled" value={0}></option>
            {values.tiposContato.map((tipoContato) => (
              <option
                key={tipoContato.tipoContatoId}
                value={tipoContato.tipoContatoId}
              >
                {tipoContato.tipoContatoDescricao}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-3 form-group">
          <label>Seguradora</label>
          <select
            className="form-control"
            name="seguradoraId"
            value={values.searchData.seguradoraId}
            onChange={onChangeInput}
          >
            <option className="disabled" value={0}></option>
            {values.seguradoras.map((seguradora) => (
              <option
                key={seguradora.seguradoraId}
                value={seguradora.seguradoraId}
              >
                {seguradora.seguradoraDescricao}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-12 form-group"></div>
        <GroupBox
          titulo="Data Recebimento "
          className="col-12 col-md-6 form-group"
        >
          <div className="col-12 col-md-6 ">
            <label>De:</label>
            <input
              className="form-control"
              type="date"
              name="dataRecebimentoDe"
              value={values.searchData.dataRecebimentoDe}
              onChange={onChangeInput}
            />
          </div>
          <div className="col-12 col-md-6 ">
            <label>Até:</label>
            <input
              className="form-control"
              type="date"
              name="dataRecebimentoAte"
              value={values.searchData.dataRecebimentoAte}
              onChange={onChangeInput}
            />
          </div>
        </GroupBox>

        <GroupBox
          titulo="Data Fechamento "
          className="col-12 col-md-6 form-group"
        >
          <div className="col-12 col-md-6 ">
            <label>De:</label>
            <input
              className="form-control"
              type="date"
              name="dataFechamentoDe"
              value={values.searchData.dataFechamentoDe}
              onChange={onChangeInput}
            />
          </div>
          <div className="col-12 col-md-6 ">
            <label>Até:</label>
            <input
              className="form-control"
              type="date"
              name="dataFechamentoAte"
              value={values.searchData.dataFechamentoAte}
              onChange={onChangeInput}
            />
          </div>
        </GroupBox>
      </PesquisarRelatorio>
    </Fragment>
  )
}

export default PesquisarNegociosFechados
