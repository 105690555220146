/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext } from "react";
import AppContext from "../../../../Store/AppContext";
import {
  ListarUf,
  ListarCidade,
  ListarUtilizacao,
  Listar as ListarCarsystemApiConsultaHdi,
  Detalhes as DetalhesCarsystemApiConsultaHdi,
  Remover as RemoverCarsystemApiConsultaHdi,
  AtualizarLista as AtualizarListaCarsystemApiConsultaHdi,
} from "../../../../Services/CarsystemApiConsultaHdi";
import { useHistory } from "react-router-dom";
import { validarPermissoesPagina } from "../../../../Utils/validarPermissoesPagina";
export const CarsystemApiConsultaHdiContext = createContext();

const tituloTela = "Carsystem Hdi";

const defaultData = {
  carsystemApiConsultaHdiId: 0,
  carsystemApiConsultaHdiModelo: "",
  carsystemApiConsultaHdiFabricante: "",
  carsystemApiConsultaHdiChassi: "",
  carsystemApiConsultaHdiPlaca: "",
  carsystemApiConsultaHdiAno: "",
  carsystemApiConsultaHdiDataConfirmacao: "",
  carsystemApiConsultaHdiDataCancelamento: "",
  carsystemApiConsultaHdiCdProduto: "",
  carsystemApiConsultaHdiContrato: "",
  carsystemApiConsultaHdiPedido: "",
  carsystemApiConsultaHdiTipo: "",
  carsystemApiConsultaHdiDataHoraInsert: "",
  carsystemApiConsultaHdiDataMov: "",
  carsystemApiConsultaHdiProcessado: false,
  carsystemApiConsultaHdiArquivoCsv: "",
  carsystemApiConsultaHdiCidade: "",
  carsystemApiConsultaHdiUf: "",
  carsystemApiConsultaHdiUtilizacao: ""
};

const searchData = {
  carsystemApiConsultaHdiDescricao: "",
};

const columnsDefinition = [
  {
    text: "Id",
    dataField: "carsystemApiConsultaHdiId",
    hidden: true,
  },
  {
    text: "Dt. Confir.",
    dataField: "carsystemApiConsultaHdiDataConfirmacao",
    headerStyle: { width: "90px" },
    sort: true,
  },
  {
    text: "Dt.Cancel.",
    dataField: "carsystemApiConsultaHdiDataCancelamento",
    headerStyle: { width: "90px" },
    sort: true,
  },
  {
    text: "Fabricante",
    dataField: "carsystemApiConsultaHdiFabricante",
    headerStyle: { width: "90px" },
    sort: true,
  },
  {
    text: "Modelo",
    dataField: "carsystemApiConsultaHdiModelo",
    headerStyle: { width: "200px" },
    sort: true,
  },
  {
    text: "Ano",
    dataField: "carsystemApiConsultaHdiAno",
    headerStyle: { width: "50px" },
    sort: true,
  },
  {
    text: "Placa",
    dataField: "carsystemApiConsultaHdiPlaca",
    headerStyle: { width: "50px" },
    sort: true,
  },
  {
    text: "Tipo",
    dataField: "carsystemApiConsultaHdiTipo",
    headerStyle: { width: "70px" },
    sort: true,
  },
  {
    text: "Cidade/UF",
    dataField: "carsystemApiConsultaHdiCidade",
    headerStyle: { width: "150px" },
    sort: true,
    formatter: (cell, row) => {
      return (row.carsystemApiConsultaHdiCidade ?? '') + ' / ' + (row.carsystemApiConsultaHdiUf ?? '')
    },
  }
];

function initialState() {
  return {
    searchData: searchData,
    resultSearch: [],
    itensModificados: [],
    columnsDefinition: columnsDefinition,
    data: defaultData,
    formDisabled: true,
    ufs: [],
    cidades: [],
    utilizacoes: []
  };
}

const CarsystemApiConsultaHdiProvider = ({ children }) => {
  const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(
    AppContext
  );
  const [values, setValues] = useState(initialState());

  async function loadDependences() {
    let ufs = [];
    let cidades = [];
    let utilizacoes = [];

    let [
      permissoesPaginaAux,
      responseListarUf,
      responseListarCidade,
      responseListarUtilizacao,] = await Promise.all(
        [
          validarPermissoesPagina(),
          ListarUf(),
          ListarCidade(),
          ListarUtilizacao()
        ]
      );

    if (
      !responseListarUf.success ||
      !responseListarCidade.success ||
      !responseListarUtilizacao.success
    ) {
      showMessage('Erro', 'Erro Inesperado ao carregar dados basicos.');
    } else {
      ufs = await responseListarUf.Ufs;
      cidades = await responseListarCidade.Cidades;
      utilizacoes = await responseListarUtilizacao.Ultilizacoes;
    }

    return { permissoesPagina: permissoesPaginaAux, ufs, cidades, utilizacoes };
  }

  const resetScreen = () => {
    showLoading();
    async function carregarDependencias() {
      const dependencias = await loadDependences();
      setTituloTela(tituloTela);
      setValues({
        ...initialState(),
        ...await dependencias
      });
      hideLoading();
    }
    carregarDependencias();
  };

  const searchHandler = async () => {
    showLoading();
    const responseListarCarsystemApiConsultaHdi = await ListarCarsystemApiConsultaHdi(
      values.searchData
    );

    if (!responseListarCarsystemApiConsultaHdi.success) {
      showMessage("Erro", "Erro Inesperado durante a pesquisa.");
      hideLoading();
      return;
    }

    const resultSearchData = await responseListarCarsystemApiConsultaHdi.carsystemApiConsultaHdiList;

    resultSearchData.forEach(item => {
      item.Id = item.carsystemApiConsultaHdiId;
      item.foiModificado = false;
    });

    setValues({
      ...values,
      resultSearch: resultSearchData,
      itensModificados: []
    });


    if (resultSearchData.length === 0) {
      showMessage("Alerta", "Nenhum registro encontrado.");
    }
    hideLoading();
  };

  const removeHandler = async (id) => {
    showLoading();

    const responseRemover = await RemoverCarsystemApiConsultaHdi(id);

    if (!responseRemover.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro durante a exclusão: ${responseRemover.message}`
      );
      return;
    }
    searchHandler();
    hideLoading();
    showMessage("Sucesso", "Registro excluido.");
  };

  const getDetailHandler = async (id) => {
    showLoading();
    const dependencias = await loadDependences();

    if (id > 0) {
      const responseDetalhes = await DetalhesCarsystemApiConsultaHdi(id);

      const { success, carsystemApiConsultaHdi } = await responseDetalhes;

      if (!success) {
        showMessage("Erro", "Erro ao buscar os detalhes do registro.");
        setValues({
          ...values,
          data: defaultData,
          ...await dependencias,
        });
      }

      setValues({
        ...values,
        data: carsystemApiConsultaHdi,
        ...await dependencias,
      });
    } else {
      setValues({
        ...values,
        data: {
          ...defaultData,
          carsystemApiConsultaHdiId: 0,
        },
        ...await dependencias,
        formDisabled: false,
      });
    }
    hideLoading();
  };

  const updateData = async () => {
    showLoading();
    const responseAtualizar = await AtualizarListaCarsystemApiConsultaHdi(values.itensModificados);
    if (!responseAtualizar.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao atualizar o registro: ${responseAtualizar.message}`
      );
      return;
    }
    resetScreen();
    hideLoading();
    showMessage("Sucesso", "Registro atualizado.");
  };

  const saveHandler = async () => {
    await updateData();
  };

  const cancelHandler = () => {
    setValues({
      ...values,
      data: defaultData,
      formDisabled: true,
    });
  };

  function onChangeInputPadraoGrid(event) {
    const { id, name, value } = event.target;
    var idLinha = id.split(/(?<=[_])/)[1];

    let listData = values.resultSearch;

    listData.forEach(item => {
      if (item.carsystemApiConsultaHdiId == idLinha) {
        switch (name) {
          case "carsystemApiConsultaHdiUtilizacao":
            item.carsystemApiConsultaHdiUtilizacao = value;
            break;
          case "carsystemApiConsultaHdiChassi":
            item.carsystemApiConsultaHdiChassi = value;
            break;

          default:
            break;
        }
        item.foiModificado = true;
      }
    });

    let itensModificados = listData.filter(item => item.foiModificado);
    setValues({
      ...values,
      resultSearch: listData,
      itensModificados
    });
  }

  return (
    <CarsystemApiConsultaHdiContext.Provider
      value={{
        values,
        setValues,
        resetScreen,
        searchHandler,
        cancelHandler,
        removeHandler,
        saveHandler,
        getDetailHandler,
        onChangeInputPadraoGrid
      }}
    >
      {children}
    </CarsystemApiConsultaHdiContext.Provider>
  );
};

export default CarsystemApiConsultaHdiProvider;
