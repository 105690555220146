import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "Endosso";

export const Listar = (endosso) =>
  RequestWebApi.get(`/${controller}/Listar`, {
    params: { ...endosso },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const origensLead = resultData;
      return { success, origensLead };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { origensLead: [] };
    });

export const Detalhes = (endossoId) =>
  RequestWebApi.get(`/${controller}/Detalhes`, {
    params: { endossoId: endossoId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const endosso = resultData;
      return { success, endosso };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, endosso: {} };
    });

export const ProcessarEndosso = (endosso) =>
  RequestWebApi.put(`/${controller}/ProcessarEndosso`, endosso)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (endossoId) =>
  RequestWebApi.delete(`/${controller}/Remover`, {
    params: { endossoId: endossoId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });
