/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext } from 'react';
import AppContext from '../../../../Store/AppContext';
import { ConversaoLead } from '../../../../Services/Relatorio';
import { validarPermissoesRelatorio } from '../../../../Utils/validarPermissoesPagina';
export const ConversaoLeadContext = createContext();

const tituloTela = 'Conversão de leads';

const searchData = {
	ano: '0',
	mes: '0'
};

function initialState() {
	return {
		searchData: searchData,
	};
}

const ConversaoLeadProvider = ({ children }) => {
	const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(AppContext);
	const [values, setValues] = useState(initialState());

	async function loadDependences() {

		const [
			validarPermissoesRelatorioAux
		] = await Promise.all([
			validarPermissoesRelatorio()
		]);

		return {
			permissoesRelatorio: validarPermissoesRelatorioAux
		};
	}

	const resetScreen = () => {
		showLoading();
		async function carregarDependencias() {
			const dependencias = await loadDependences();
			setTituloTela(tituloTela);
			setValues({
				...initialState(),
				permissoesRelatorio: await dependencias.permissoesRelatorio
			});
			hideLoading();
		}
		carregarDependencias();
	};

	const searchHandler = async () => {

		if (values.searchData.ano == "0") {
			showMessage('Erro', 'Ano não informado.');
			return;
		}

		if (values.searchData.mes == "0") {
			showMessage('Erro', 'Mês não informado.');
			return;
		}

		showLoading();
		const responseListarConversaoLead = await ConversaoLead(values.searchData);

		if (!responseListarConversaoLead.success) {
			showMessage('Erro', 'Erro Inesperado durante a pesquisa.');
			hideLoading();
			return;
		}

		hideLoading();
	};

	return (
		<ConversaoLeadContext.Provider
			value={{
				values,
				setValues,
				resetScreen,
				searchHandler
			}}
		>
			{children}
		</ConversaoLeadContext.Provider>
	);
};

export default ConversaoLeadProvider;
