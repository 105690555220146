import React, { Fragment, useContext } from "react";
import Select from "react-select";

export const EnviarLeads = ({ context }) => {
  const {
    distribuirLeadsValues,
    setDistribuirLeadsValues,
    enviarLeads,
  } = useContext(context);

  const handleChangeUsuario = (usuario) => {
    setDistribuirLeadsValues({
      ...distribuirLeadsValues,
      data: {
        ...distribuirLeadsValues.data,
        usuario,
        usuarioId: usuario ? usuario.value : 0,
      },
    });
  };

  function onChangeCheck(event) {
    const { name, checked } = event.target;
    setDistribuirLeadsValues({
      ...distribuirLeadsValues,
      data: {
        ...distribuirLeadsValues.data,
        [name]: checked,
      },
    });
  }

  return (
    <Fragment>
      <div className="col-3">
        <div className="default-title">Enviar Leads</div>
        <div className="card shadow-sm mb-4">
          <div className="row">
            <div className="col-12 col-md-12 ">
              <label>
                Leads Selecionados: {distribuirLeadsValues.data.leads.length}
              </label>
            </div>

            <div className="col-12 col-md-12 pt-2">
              <label>Enviar para</label>
              <Select
                styles={{ input: () => ({}) }}
                placeholder=""
                value={distribuirLeadsValues.data.usuario}
                onChange={handleChangeUsuario}
                options={distribuirLeadsValues.consultoresOnline}
              />
            </div>
            <div className="col-12 col-md-12 pt-2 d-flex">
              <div>
                <input
                  type="checkbox"
                  name="definirComoNovoLead"
                  onChange={onChangeCheck}
                  checked={distribuirLeadsValues.data.definirComoNovoLead}

                />
              </div>
              <label style={{ paddingLeft: "10px", paddingTop: "8px" }}>Novo Contato?</label>
            </div>
            <div className="col-12 col-md-12">
              <div className="row justify-content-end">
                <div className="col-auto">
                  <button
                    disabled={
                      !distribuirLeadsValues.data.usuarioId ||
                      distribuirLeadsValues.data.usuarioId === "0" ||
                      distribuirLeadsValues.data.leads.length === 0
                    }
                    type="button"
                    className="btn btn-success buttonFormularios"
                    onClick={enviarLeads}
                  >
                    Enviar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EnviarLeads;
