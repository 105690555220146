import React, { useContext, Fragment, useEffect } from "react";
import { HistoricoClienteContext } from "../HistoricoClienteContext";
import Pesquisar from "../../../../../Components/Cadastro/Pesquisar/Pesquisar";
import DataTableDefault from "../../../../../Components/DataTableDefault/DataTableDefault";

const PesquisarHistoricoCliente = () => {
  const { values, setValues, resetScreen } = useContext(HistoricoClienteContext);

  useEffect(() => {
    resetScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      searchData: {
        ...values.searchData,
        [name]: value,
      },
    });
  }

  return (
    <Fragment>
      <Pesquisar exibirBotaoNovo={false} context={HistoricoClienteContext}>
        <div className="col-12 col-md-3">
          <label>Nome Cliente</label>
          <input
            className="form-control"
            type="text"
            name="leadNome"
            value={values.searchData.leadNome}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-3">
          <label>Documento Cliente</label>
          <input
            className="form-control"
            type="text"
            name="leadNumDocumento"
            value={values.searchData.leadNumDocumento}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-3">
          <label>Contato Cliente</label>
          <input
            className="form-control"
            type="text"
            name="contato"
            value={values.searchData.contato}
            onChange={onChangeInput}
          />
        </div>
      </Pesquisar>
      <DataTableDefault
        keyField="id"
        data={values.resultSearch}
        columns={values.columnsDefinition}
      />
    </Fragment>
  );
};

export default PesquisarHistoricoCliente;
