import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRoute from "../../../../Routes/PrivateRoutes";
import HistoricoClienteProvider from "./HistoricoClienteContext";
import PesquisarHistoricoCliente from "./Events/PesquisarHistoricoCliente";

const Index = () => {
  let match = useRouteMatch();
  return (
    <HistoricoClienteProvider>
      <div className="container-fluid pl-3 pt-3 pb-3">
        <Switch>
          <PrivateRoute
            exact
            path={`${match.url}`}
            component={PesquisarHistoricoCliente}
          />
        </Switch>
      </div>
    </HistoricoClienteProvider>
  );
};

export default Index;
