import React, { useContext, useState } from "react";
import "./AdicionarSegmentacao.css";
import { Fragment } from "react";
import DataTableDefaultSelection from "../../../Components/DataTableDefaultSelection/DataTableDefaultSelection";

const columnsDefinition = [
  {
    text: "Id",
    dataField: "segmentacaoPublicoId",
    hidden: true,
  },
  {
    text: "Nome",
    dataField: "segmentacaoPublicoNome",
    headerStyle: { width: "450px" },
    sort: true,
  },
];

function initialStateFilters() {
  return {
    segmentacaoPublicoNome: "",
  };
}

const AdicionarSegmentacao = ({ id, context, selectedItensBase = [], selectedItensBaseBloqueados = [] }) => {
  const [
    adicionarSegmentacaoSelectedItensValues,
    setAdicionarSegmentacaoSelectedItens,
  ] = useState(selectedItensBase);

  const [
    adicionarSegmentacaoFilterValues,
    setAdicionarSegmentacaoFilterValues,
  ] = useState(initialStateFilters);

  const {
    values,
    buscarSegmentacao,
    adicionaSegmentacaoSegmentacao,
  } = useContext(context);

  const selectedItemHandler = (selectedItens) => {
    setAdicionarSegmentacaoSelectedItens(selectedItens);
  };

  const initializeBase = () => {
    setAdicionarSegmentacaoSelectedItens(selectedItensBase);
  };

  function onChangeInput(event) {
    const { name, value } = event.target;
    setAdicionarSegmentacaoFilterValues({
      ...adicionarSegmentacaoFilterValues,
      [name]: value,
    });
  }

  return (
    <Fragment>
      <button
        type="button"
        className="btn btn-default"
        data-toggle="modal"
        data-target={`#modalAdicionarSegmentacao${id}`}
        data-dismiss="modal"
        onClick={initializeBase}
      >
        Adicionar Segmentação
      </button>
      <div
        className="modal fade"
        id={`modalAdicionarSegmentacao${id}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby={`modalAdicionarSegmentacao${id}Label`}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog  modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id={`modalAdicionarSegmentacao${id}Label`}
              >
                Adicionar Segmentacão
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body componenteAdicionarSegmentacao_modalBody">
              <div className="row mt-3">
                <div className="col-12">
                  <div className="row">
                    <div className="col-9 form-group">
                      <label>Nome</label>
                      <input
                        className="form-control"
                        type="text"
                        maxLength="50"
                        name="segmentacaoPublicoNome"
                        value={
                          adicionarSegmentacaoFilterValues.segmentacaoPublicoNome
                        }
                        onChange={onChangeInput}
                      />
                    </div>
                    <div className="col-3 mt-4">
                      <button
                        type="button"
                        onClick={() => {
                          buscarSegmentacao(adicionarSegmentacaoFilterValues);
                        }}
                        className="btn btn-success"
                      >
                        Buscar
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <DataTableDefaultSelection
                    keyField="segmentacaoPublicoId"
                    data={values.segmetacoesPulicoData}
                    selectedItens={adicionarSegmentacaoSelectedItensValues}
                    nonSelectable={selectedItensBaseBloqueados}
                    columns={columnsDefinition}
                    selectedItemHandler={selectedItemHandler}
                  />
                </div>

                <div className="col-12 text-center pt-4">
                  <button
                    type="button"
                    data-dismiss="modal"
                    className="btn btn-default"
                    aria-label="Close"
                    onClick={() => {
                      adicionaSegmentacaoSegmentacao(
                        adicionarSegmentacaoSelectedItensValues
                      );
                    }}
                  >
                    Adicionar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AdicionarSegmentacao;
