import React, { useContext } from "react";

const PesquisarRelatorio = ({ children, context}) => {
  const { searchHandler } = useContext(context);

  return (
    <div className="row">
      <div className="col-12">
        <div className="default-title">Pesquisa</div>
        <div className="card shadow-sm mb-4">
          <div className="row align-items-end">
            {children}
            <div className="col-12 mb-3 text-center">
              <hr />
              <button
                type="button"
                className="btn btn-default"
                onClick={searchHandler}
              >
                Exportar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PesquisarRelatorio;
