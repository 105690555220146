import React, { Fragment } from "react";
const CobrancaRealizada = ({
  defineDetalhesObjeto = () => { },
  cobranca = {},
}) => {
  return (
    <Fragment>
      {cobranca.vendaId ? (
        <div className="col-md-2 pt-1">
          <div className="font-weight-bold">
            Prot. Venda :{" "}
            <span role="button"
              className="font-weight-normal link_primary"
              onClick={() => defineDetalhesObjeto("V", cobranca.vendaId)} >
              {cobranca.vendaId}
            </span>
          </div>
        </div>
      ) : null}

      {cobranca.cobrancaDataHora ? (
        <div className="col-md-2 pt-1">
          <div className="font-weight-bold">
            Data/Hora:{" "}
            <span className="font-weight-normal">
              {cobranca.cobrancaDataHora}
            </span>
          </div>
        </div>
      ) : null}

      {cobranca.cobrancaStatus ? (
        <div className="col-md-2 pt-1">
          <div className="font-weight-bold">
            Status:{" "}
            <span className="font-weight-normal">
              {cobranca.cobrancaStatus}
            </span>
          </div>
        </div>
      ) : null}

      {cobranca.seguradoraDescricao ? (
        <div className="col-md-5 pt-1">
          <div className="font-weight-bold">
            Seguradora:{" "}
            <span className="font-weight-normal">
              {cobranca.seguradoraDescricao}
            </span>
          </div>
        </div>
      ) : null}

      {cobranca.cobrancaApolice ? (
        <div className="col-md-3 pt-1">
          <div className="font-weight-bold">
            Núm. Apólice:{" "}
            <span className="font-weight-normal">
              {cobranca.cobrancaApolice}
            </span>
          </div>
        </div>
      ) : null}

      {cobranca.cobrancaDataFimApolice ? (
        <div className="col-md-3 pt-1">
          <div className="font-weight-bold">
            Data Venc. Apólice:{" "}
            <span className="font-weight-normal">
              {cobranca.cobrancaDataFimApolice}
            </span>
          </div>
        </div>
      ) : null}

      {cobranca.cobrancaNumParcela ? (
        <div className="col-md-2 pt-1">
          <div className="font-weight-bold">
            Núm. Parcela:{" "}
            <span className="font-weight-normal">
              {cobranca.cobrancaNumParcela}
            </span>
          </div>
        </div>
      ) : null}

      {cobranca.cobrancaVencimentoParcela ? (
        <div className="col-md-3 pt-1">
          <div className="font-weight-bold">
            Data Venc. Parcela:{" "}
            <span className="font-weight-normal">
              {cobranca.cobrancaVencimentoParcela}
            </span>
          </div>
        </div>
      ) : null}

      {cobranca.cobrancaEndosso ? (
        <div className="col-md-3 pt-1">
          <div className="font-weight-bold">
            Núm. Endosso:{" "}
            <span className="font-weight-normal">
              {cobranca.cobrancaEndosso}
            </span>
          </div>
        </div>
      ) : null}

      {cobranca.usuarioNome ? (
        <div className="col-md-5 pt-1">
          <div className="font-weight-bold">
            Nome Usuário:{" "}
            <span className="font-weight-normal">
              {cobranca.usuarioNome}
            </span>
          </div>
        </div>
      ) : null}


      {cobranca.cobrancaFormaPagtoDescricao ? (
        <div className="col-md-3 pt-1">
          <div className="font-weight-bold">
            Forma de Pagto:{" "}
            <span className="font-weight-normal">
              {cobranca.cobrancaFormaPagtoDescricao}
            </span>
          </div>
        </div>
      ) : null}

      {cobranca.cobrancaDataVencRepique ? (
        <div className="col-md-3 pt-1">
          <div className="font-weight-bold">
            Data Venc. Repique:{" "}
            <span className="font-weight-normal">
              {cobranca.cobrancaDataVencRepique}
            </span>
          </div>
        </div>
      ) : null}

      {cobranca.cobrancaDataCancelamento ? (
        <div className="col-md-3 pt-1">
          <div className="font-weight-bold">
            Data Cancelamento:{" "}
            <span className="font-weight-normal">
              {cobranca.cobrancaDataCancelamento}
            </span>
          </div>
        </div>
      ) : null}

      {cobranca.cobrancaObservacao ? (

        <div className="col-md-12 pt-1">
          <div className="font-weight-bold">
            Observações :{" "}
            <br />
            <textarea
              disabled={true}
              className="form-control"
              type="text"
              rows="4"
              maxLength="1000"
              name="cobrancaObservacao"
              value={cobranca.cobrancaObservacao}
            />
          </div>
        </div>
      ) : null}



    </Fragment>
  );
};

export default CobrancaRealizada;
