import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

const Formulario = ({ children, context }) => {
  const { values, cancelHandler, saveHandler, editHandler } = useContext(
    context
  );
  let history = useHistory();

  const cancelar = () => {
    cancelHandler();
    history.goBack();
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="default-title">{values.formDisabled ? "Detalhes" : "Novo"}</div>
          <div className="card shadow-sm mb-4">
            <div className="row mb-3">
              <div className="col-12 text-right">
                <button
                  type="button"
                  className="btn btn-secondary mr-3"
                  onClick={cancelar}
                >
                  Cancelar
                </button>
                {values.formDisabled ? (
                  (values.permissoesPagina != null && values.permissoesPagina.editar) && editHandler ? (
                    <button
                      type="button"
                      className="btn btn-default"
                      onClick={editHandler}
                    >
                      Editar
                    </button>
                  ) : null
                ) : (
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={saveHandler}
                  >
                    Salvar
                  </button>
                )}
              </div>
            </div>
            <div className="row">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Formulario;
