import React from "react";
import "./MuralFluxoEventosItem.css";

const MuralFluxoEventos = ({ valorDescricao = "", valor = 0, percentual = 0, cor }) => {


  return (
    <div className="mt-2">
      <div className="font-weight-bold text-left mt-3 mb-1">
        {valorDescricao} :
        <span className="font-weight-normal ml-1">
          {valor}
        </span>
      </div>
      <div className="progress">
        <div className={`progress-bar bg-${cor}`} role="progressbar" aria-label="Enviado" style={{ width: `${percentual}%` }} aria-valuenow={percentual} aria-valuemin="0" aria-valuemax="100">{percentual}%</div>
      </div>
    </div>
  );
};

export default MuralFluxoEventos;
