/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from "react";
import { ImportarDadosSeguradora } from "../../../../Services/DadosSeguradora";
import { DownloadTemplate } from "../../../../Services/Download";
import AppContext from "../../../../Store/AppContext";
import { validarPermissoesPagina } from "../../../../Utils/validarPermissoesPagina";
import { convertFileParaBase } from "../../../../Utils/globalFunctions";

const tituloTela = "Importar Dados Seguradora";
export const ImportarDadosSeguradoraContext = createContext();

const obterAnoPropostaList = () => {
  let pastData = new Date("2014/01/01");
  let currentData = new Date();
  let yearList = [];
  while (pastData.getFullYear() <= currentData.getFullYear()) {
    yearList = [...yearList, pastData.getFullYear()];
    pastData = new Date((pastData.getFullYear() + 1) + "/01/01");
  }
  return yearList;
}

function initialState() {
  return {
    arquivo: null,
    anoProposta: 0,
    anoPropostaList: obterAnoPropostaList(),
    resultadosImportacao: [],
    totalPropostasImportados: 0,
    totalPropostasComSucesso: 0,
    totalPropostasComErro: 0,
    permissoesPagina: null,
  };
}

const ImportarArquivo = async (base64, arquivoNome, anoProposta) => {
  let dadosSeguradora = {
    arquivo: base64,
    arquivoNome: arquivoNome,
    anoProposta: anoProposta
  }

  const result = await ImportarDadosSeguradora(dadosSeguradora);
  return result;
};

const ImportarDadosSeguradoraProvider = ({ children }) => {
  const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(
    AppContext
  );
  const [values, setValues] = useState(initialState());

  useEffect(() => {
    showLoading();
    async function carregarDependencias() {
      const permissoesPagina = await validarPermissoesPagina();

      setValues({
        ...values,
        permissoesPagina: permissoesPagina,
      });
      hideLoading();
      setTituloTela(tituloTela);
    }

    carregarDependencias();
  }, []);

  async function importar() {
    showLoading();

    if (!values.arquivo) {
      showMessage("Erro", "Arquivo não Informado.");
      hideLoading();
      return;
    }
    convertFileParaBase(values.arquivo).then(data => {
      ImportarArquivo(data, values.arquivo.name, values.anoProposta).then(result => {
        if (result.success) {
          const resultadosImportacao = result.resultadosImportacao;

          const totalPropostasImportados = result.resultadosImportacao.length;

          const totalPropostasComSucesso = result.resultadosImportacao.filter(
            (resultadoImportacao) => resultadoImportacao.sucesso
          ).length;

          const totalPropostasComErro = result.resultadosImportacao.filter(
            (resultadoImportacao) => !resultadoImportacao.sucesso
          ).length;

          setValues({
            ...values,
            resultadosImportacao: resultadosImportacao,
            totalPropostasImportados: totalPropostasImportados,
            totalPropostasComSucesso: totalPropostasComSucesso,
            totalPropostasComErro: totalPropostasComErro,
          });

          showMessage("Sucesso", "Operação Realizada com Sucesso.");
          hideLoading();
        } else {
          hideLoading();
          showMessage("Erro", "Erro durante a gravação do Proposta.");
          result.errors.map((erro) => showMessage("Erro", erro.errorMessage));
        }
      });

    });




  }
  const downloadTemplate = async () => {
    const result = await DownloadTemplate("DadosSeguradora.xlsx");
    return result;
  };
  return (
    <ImportarDadosSeguradoraContext.Provider
      value={{
        values,
        setValues,
        importar,
        downloadTemplate,
      }}
    >
      {children}
    </ImportarDadosSeguradoraContext.Provider>
  );
};

export default ImportarDadosSeguradoraProvider;
