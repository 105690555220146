import React, { Fragment, useState, useEffect } from 'react';
import './CarrocelGraficos.css';
import $ from "jquery";
import GraficoBarrasLinha from '../../Graficos/GraficoBarrasLinha/GraficoBarrasLinha';

const initialState = {
	legendas: [],
	dataChart: [ 0 ],
	dataChartLinha: [ 0 ]
};
const tatalizador = 'diario';
const CarrocelGraficos = ({ dataPerformace = null, meuRendimento = 0 }) => {
	const [ metasEquipesDiarioValues, setMetasEquipesDiarioValues ] = useState(initialState);
	const [ metasConsultorDiarioValues, setMetasConsultorDiarioValues ] = useState(initialState);

	useEffect(() => {
		if (!dataPerformace) return;
		$('.carousel').carousel();
		async function carregarDependencias() {
			metasEquipesHandler(tatalizador);
			metasConsultorHandler(tatalizador);
		}
		carregarDependencias();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const metasEquipesHandler = (agrupador) => {
		if (!dataPerformace) {
			return;
		}
		let legendas = dataPerformace.metasEquipes.legendas;
		let dataChart = dataPerformace.metasEquipes.metasAtingida[agrupador];
		let dataChartLinha = dataPerformace.metasEquipes.metasDefinida[agrupador];
		setMetasEquipesDiarioValues({ legendas, dataChart, dataChartLinha });
	};

	const metasConsultorHandler = (agrupador) => {
		if (!dataPerformace) {
			return;
		}
		let legendas = dataPerformace.metasConsultor.legendas;
		let dataChart = dataPerformace.metasConsultor.metasAtingida[agrupador];
		let dataChartLinha = dataPerformace.metasConsultor.metasDefinida[agrupador];
		setMetasConsultorDiarioValues({ legendas, dataChart, dataChartLinha });
	};

	return (
		<Fragment>
			<div className="default-title">Meu Rendimento</div>
			<div className="card shadow-sm mb-4 carrocelGraficoscard">
				<div className="row align-items-start">
					{/* <div className="col-12 text-center mb-3">
						<div className="progress">
							<div
								className="progress-bar btn-default"
								role="progressbar"
								style={{ width: `${meuRendimento}%` }}
								aria-valuenow={meuRendimento}
								aria-valuemin="0"
								aria-valuemax="100"
							>
								{meuRendimento}/100
							</div>
						</div>
					</div> */}
					<div className="col-12 mt-1 mb-1">
						<div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
							<div className="carousel-inner ">
								<div className="carousel-item  active">
									<div className="row justify-content-center ">
										<div className="col-12">
											<div className="card shadow-sm text-center">
												<h3 className="font-weight-bold carrocelGraficosTitulo">
													Meta das Equipes
												</h3>
												<h1 className="font-weight-bold carrocelGraficosSubTitulo">
													(Diário)
												</h1>
												<div className="outer carrocelGraficosBodyGraficos">
													<GraficoBarrasLinha
														legendas={metasEquipesDiarioValues.legendas}
														dataChart={metasEquipesDiarioValues.dataChart}
														dataChartLinha={metasEquipesDiarioValues.dataChartLinha}
														mostrarLegenda={false}
														height={300}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="carousel-item ">
									<div className="row justify-content-center ">
										<div className="col-12">
											<div className="card shadow-sm text-center">
												<h3 className="font-weight-bold carrocelGraficosTitulo">
													Meta dos Consultores
												</h3>
												<h1 className="font-weight-bold carrocelGraficosSubTitulo">
													(Diário)
												</h1>
												<div className="outer carrocelGraficosBodyGraficos">
													<GraficoBarrasLinha
														legendas={metasConsultorDiarioValues.legendas}
														dataChart={metasConsultorDiarioValues.dataChart}
														dataChartLinha={metasConsultorDiarioValues.dataChartLinha}
														mostrarLegenda={false}
														height={300}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default CarrocelGraficos;
