import React, { Fragment, useContext, useEffect } from "react";
import AppContext from "../../Store/AppContext";
// import AlterarDados from "../../Components/Lead/AlterarDados/AlterarDados";
import "./Index.css";

const tituloTela = "Home";

const Index = () => {
  const { setTituloTela } = useContext(AppContext);

  useEffect(() => {
    async function carregarDependencias() {
      setTituloTela(tituloTela);
    }
    carregarDependencias();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Fragment>
    {/* <AlterarDados></AlterarDados> */}
  </Fragment>;
};

export default Index;
