import React, { Fragment } from "react";
const EndossoRealizado = ({
  defineDetalhesObjeto = () => { },
  endosso = {},
}) => {
  return (
    <Fragment>
      {endosso.vendaId ? (
        <div className="col-md-2">
          <div className="font-weight-bold">
            Prot. Venda:{" "}
            <span role="button"
              className="font-weight-normal link_primary"
              onClick={() => defineDetalhesObjeto("V", endosso.vendaId)} >
              {endosso.vendaId}
            </span>
          </div>
        </div>
      ) : null}

      {endosso.endossoDataHora ? (
        <div className="col-md-2">
          <div className="font-weight-bold">
            Data/Hora:{" "}
            <span className="font-weight-normal">
              {endosso.endossoDataHora}
            </span>
          </div>
        </div>
      ) : null}

      {endosso.usuarioNome ? (
        <div className="col-md-5">
          <div className="font-weight-bold">
            Nome Usuário:{" "}
            <span className="font-weight-normal">
              {endosso.usuarioNome}
            </span>
          </div>
        </div>
      ) : null}


      {endosso.apoliceNumero ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Núm. Apólice:{" "}
            <span className="font-weight-normal">
              {endosso.apoliceNumero}
            </span>
          </div>
        </div>
      ) : null}


      {endosso.apoliceData ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Data Apólice:{" "}
            <span className="font-weight-normal">
              {endosso.apoliceData}
            </span>
          </div>
        </div>
      ) : null}

      {endosso.tipoEndossoDescricao ? (
        <div className="col-md-4">
          <div className="font-weight-bold">
            Tipo Endosso:{" "}
            <span className="font-weight-normal">
              {endosso.tipoEndossoDescricao}
            </span>
          </div>
        </div>
      ) : null}

      {endosso.endossoNumeroEndosso ? (
        <div className="col-md-2">
          <div className="font-weight-bold">
            Núm. Endosso:{" "}
            <span className="font-weight-normal">
              {endosso.endossoNumeroEndosso}
            </span>
          </div>
        </div>
      ) : null}

      {endosso.endossoSolicitante ? (
        <div className="col-md-4">
          <div className="font-weight-bold">
            Solicitante:{" "}
            <span className="font-weight-normal">
              {endosso.endossoSolicitante}
            </span>
          </div>
        </div>
      ) : null}

      {endosso.endossoDataSolicitacao ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Data Solicitação:{" "}
            <span className="font-weight-normal">
              {endosso.endossoDataSolicitacao}
            </span>
          </div>
        </div>
      ) : null}

      {endosso.seguradoraDescricao ? (
        <div className="col-md-5">
          <div className="font-weight-bold">
            Seguradora:{" "}
            <span className="font-weight-normal">
              {endosso.seguradoraDescricao}
            </span>
          </div>
        </div>
      ) : null}

      {endosso.endossoFinalizado ? (
        <div className="col-md-2">
          <div className="font-weight-bold">
            Finalizado:{" "}
            <span className="font-weight-normal">
              {endosso.endossoFinalizado === "1" ? "SIM" : "NÃO"}
            </span>
          </div>
        </div>
      ) : null}

      {endosso.endossoValorLiqPremio ? (
        <div className="col-md-2">
          <div className="font-weight-bold">
            Valor Liq.:{" "}
            <span className="font-weight-normal">
              {endosso.endossoValorLiqPremio}
            </span>
          </div>
        </div>
      ) : null}

      {endosso.endossoPercComissao ? (
        <div className="col-md-2">
          <div className="font-weight-bold">
            Perc. Comissão:{" "}
            <span className="font-weight-normal">
              {endosso.endossoPercComissao}
            </span>
          </div>
        </div>
      ) : null}

      {endosso.endossoDataTransmissao ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Data Transmissão:{" "}
            <span className="font-weight-normal">
              {endosso.endossoDataTransmissao}
            </span>
          </div>
        </div>
      ) : null}


      {endosso.endossoStatusDocumentosDescricao ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Documento :{" "}
            <span className="font-weight-normal">
              {endosso.endossoStatusDocumentosDescricao}
            </span>
          </div>
        </div>
      ) : null}

      {endosso.endossoDataFimApolice ? (
        <div className="col-md-4">
          <div className="font-weight-bold">
            Data Fim Apólice :{" "}
            <span className="font-weight-normal">
              {endosso.endossoDataFimApolice}
            </span>
          </div>
        </div>
      ) : null}

      {endosso.endossoObservacao ? (

        <div className="col-md-12">
          <div className="font-weight-bold">
            Observações :{" "}
            <br />
            <textarea
              disabled={true}
              className="form-control"
              type="text"
              rows="4"
              maxLength="1000"
              name="endossoObservacao"
              value={endosso.endossoObservacao}
            />
          </div>
        </div>
      ) : null}




    </Fragment>
  );
};

export default EndossoRealizado;
