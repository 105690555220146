import { RequestWebApi, validateRequestErrorStatus } from "./Requests";
import qs from "qs";

const controller = "SegmentacaoPublico";
export const Listar = (segmentacaoPublico) =>
  RequestWebApi.get(`/${controller}/Listar`, {
    params: { ...segmentacaoPublico },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const segmentacaoPublicos = resultData;
      return { success, segmentacaoPublicos };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { segmentacaoPublicos: [] };
    });

    export const ListarSegmentacaoPublicoCompleto = (segmentacaoPublicoIdList) =>
    RequestWebApi.get(`/${controller}/ListarSegmentacaoPublicoCompleto`, {
      params: { segmentacaoPublicoIdList: segmentacaoPublicoIdList },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
      .then(function (response) {
        const { resultData, success } = response.data;
        const segmentacaoPublicos = resultData;
        return { success, segmentacaoPublicos };
      })
      .catch(function (error) {
        validateRequestErrorStatus(error.response.status);
        return { segmentacaoPublicos: [] };
      });
  

export const Detalhes = (segmentacaoPublicoId) =>
  RequestWebApi.get(`/${controller}/Detalhes`, {
    params: { segmentacaoPublicoId: segmentacaoPublicoId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const segmentacaoPublico = resultData;
      return { success, segmentacaoPublico };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, segmentacaoPublico: {} };
    });

export const Incluir = (segmentacaoPublico) =>
  RequestWebApi.post(`/${controller}/Incluir`, segmentacaoPublico)
    .then(function (response) {
      const { resultData, success } = response.data;
      const segmentacaoPublico = resultData;
      return { success, segmentacaoPublico };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Atualizar = (segmentacaoPublico) =>
  RequestWebApi.put(`/${controller}/Atualizar`, segmentacaoPublico)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (segmentacaoPublicoId) =>
  RequestWebApi.delete(`/${controller}/Remover`, {
    params: { segmentacaoPublicoId: segmentacaoPublicoId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const DownloadArquivoImportado = (segmentacaoPublicoId) =>
  RequestWebApi.get(`/${controller}/DownloadArquivoImportado`, {
    params: { segmentacaoPublicoId: segmentacaoPublicoId },
    responseType: "blob",
  })
    .then(function (response) {
      const type = response.headers["content-type"];
      const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `BaseProspect`;
      link.click();
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { filaAtendimento: [] };
    });
