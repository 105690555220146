import React from "react";
import "./TimeLine.css";
import TimeLineItem from "../TimeLineItem/TimeLineItem";

const TimeLine = ({ atendimentos = [], show = false  }) => {
  if (atendimentos.length > 0) {
    return (
      <div className="col-12">
        <a
          className="btn btn-default btn-block text-left"
          data-toggle="collapse"
          href="#collapseHistory"
          role="button"
          aria-expanded="false"
          aria-controls="collapseHistory"
        >
          Histórico
        </a>
        <div
          className={`collapse mt-3 ${show ? "show" : ""}`}
          id="collapseHistory"
        >
          <div className="row">
            <div className="col-12">
              <ul className="timeline">
                {atendimentos.map((atendimento) => {
                  return (
                    <TimeLineItem
                      key={atendimento.atendimentoId}
                      atendimentoTipo={atendimento.atendimentoTipo}
                      tipoContatoDescricao={atendimento.tipoContatoDescricao}
                      atendimentoDataHora={atendimento.atendimentoDataHora}
                      atendimentoHora={atendimento.atendimentoHora}
                      atendimentoObservacao={atendimento.atendimentoObservacao}
                    />
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="col-12">
        <div className="alert alert-secondary">
          Sem histórico de atendimento
        </div>
      </div>
    );
  }
};

export default TimeLine;
