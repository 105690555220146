import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "MotorEventos";
export const ListarTipoEvento = (tipoEventoRotulo) =>
  RequestWebApi.get(`/${controller}/ListarTipoEvento`, {
    params: { tipoEventoRotulo },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const tiposEvento = resultData;
      return { success, tiposEvento };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { tiposEvento: [] };
    });

export const Listar = (motorEventoApi) =>
  RequestWebApi.get(`/${controller}/ListarMotorEventoApi`, {
    params: { ...motorEventoApi },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const motorEventosApi = resultData;
      return { success, motorEventosApi };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { motorEventosApi: [] };
    });

export const AlterarStatusEventos = (data) =>
  RequestWebApi.put(`/${controller}/AlterarStatusEventos`, data)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const ListarRotuloMotorEvento = () =>
  RequestWebApi.get(`/${controller}/ListarRotuloMotorEvento`)
    .then(function (response) {
      const { resultData, success } = response.data;
      const rotulos = resultData;
      return { success, rotulos };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { rotulos: [] };
    });
