import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import "./Modulos.css";
import ModalTextReadOnly from "../../../../../../Components/Modal/ModalTextReadOnly/ModalTextReadOnly";
const images = require.context("../../../../../../assets/img/icones/relatorios", true);

const Modulo = ({ relatorioId, titulo, descricao, helpDescricao, icone, path }) => {
  const imagemIcone = images(`./${icone}`);
  const handleOnClick = async (pathRoot) => {
    window.location.replace(`/${pathRoot}`);
  };
  return (
    <div className="relatorioModuloV2Card">

      <div
        role="button"
        onClick={() => handleOnClick(path)}>
        <img src={imagemIcone} alt={titulo} />
        <span className="relatorioModuloV2CardTextTitulo">{titulo}</span>
        <br />
        <span className="relatorioModuloV2CardTextDescricao">{descricao}</span>
      </div>

      <div role="button">
        <ModalTextReadOnly
          id={`Ajuda${relatorioId}`}
          titulo={`${titulo}`}
          value={helpDescricao}
        >
          <FontAwesomeIcon
            className="relatorioModuloV2CardIconeInfo"
            icon={faInfoCircle}
          />
        </ModalTextReadOnly>

      </div>


    </div>
  );
};

export default Modulo;
