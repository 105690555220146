import React, { Fragment, useState } from "react";

const initialState = {
  tatalizador: "diario",
};
const GraficoTemplate = ({
  id,
  titulo,
  children,
  selectResultHandler = () => {},
}) => {
  const [values, setValues] = useState(initialState);

  function onChangeInput(event) {
    const { value } = event.target;
    setValues({
      ...values,
      tatalizador: value,
    });
    selectResultHandler(value);
  }

  return (
    <Fragment>
      <div className="col-12 col-md-6">
        <div className="card mb-3">
          <div className="row align-items-center">
            <div className="col-6 font-weight-bold">{titulo}</div>
            <div className="col-6 text-right">
              <div className="btn-group btn-group-toggle">
                <label
                  className={`btn btn-light ${
                    values.tatalizador === "diario" ? "active" : ""
                  }`}
                  htmlFor={`diario${id}`}
                >
                  <input
                    type="radio"
                    name="agrupador"
                    id={`diario${id}`}
                    value="diario"
                    checked={values.tatalizador === "diario"}
                    onChange={onChangeInput}
                  ></input>
                  Diário
                </label>
                <label
                  className={`btn btn-light ${
                    values.tatalizador === "mensal" ? "active" : ""
                  }`}
                  htmlFor={`mensal${id}`}
                >
                  <input
                    type="radio"
                    name="agrupador"
                    value="mensal"
                    id={`mensal${id}`}
                    checked={values.tatalizador === "mensal"}
                    onChange={onChangeInput}
                  ></input>
                  Mensal
                </label>
                <label
                  className={`btn btn-light ${
                    values.tatalizador === "semanal" ? "active" : ""
                  }`}
                  htmlFor={`semanal${id}`}
                >
                  <input
                    type="radio"
                    name="agrupador"
                    value="semanal"
                    id={`semanal${id}`}
                    checked={values.tatalizador === "semanal"}
                    onChange={onChangeInput}
                  ></input>
                  Semanal
                </label>
              </div>
            </div>
            <div className="col-12 mt-3">{children}</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default GraficoTemplate;
