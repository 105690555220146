import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "ConsultorNivel";
export const Listar = (consultorNivel) =>
  RequestWebApi.get(`/${controller}/Listar`, {
    params: { ...consultorNivel },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const consultorNivelList = resultData;
      return { success, consultorNivelList };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { consultorNivelList: [] };
    });

export const Detalhes = (consultorNivelId) =>
  RequestWebApi.get(`/${controller}/Detalhes`, {
    params: { consultorNivelId: consultorNivelId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const consultorNivel = resultData;
      return { success, consultorNivel };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, consultorNivel: {} };
    });

export const Incluir = (consultorNivel) =>
  RequestWebApi.post(`/${controller}/Incluir`, consultorNivel)
    .then(function (response) {
      const { resultData, success } = response.data;
      const novoProduto = resultData;
      return { success, novoProduto };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Atualizar = (consultorNivel) =>
  RequestWebApi.put(`/${controller}/Atualizar`, consultorNivel)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (consultorNivelId) =>
  RequestWebApi.delete(`/${controller}/Remover`, {
    params: { consultorNivelId: consultorNivelId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });
