import React, { Fragment, useEffect, useRef } from "react";
import "./HistoricoConversa.css";
import { MessageBox, MessageList, Input } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';



const HistoricoConversa = ({
  defineDetalhesObjeto = () => { },
  mensagens = [],
}) => {

  return (
    <Fragment>
      <div className="default-title">Histórico Conversa</div>
      <MessageList
        className='message-list message-listContent'
        lockable={true}
        toBottomHeight={'100%'}
        dataSource={mensagens.map((mensagem) => {
          return {
            position: mensagem.logConversasWhatsAppDirecao == 'sent' ? 'right' : 'left',
            type: 'text',
            text: mensagem.logConversasWhatsAppMensagem,
            // date: mensagem.logConversasWhatsAppData,
          }
        })
        }
      />
    </Fragment>
  );
};

export default HistoricoConversa;
