import React, { Fragment } from "react";
const SinistroRealizado = ({
  defineDetalhesObjeto = () => { },
  sinistro = {},
}) => {
  return (
    <Fragment>

      {sinistro.vendaId ? (
        <div className="col-md-2">
          <div className="font-weight-bold">
            Prot. Venda:{" "}
            <span role="button"
              className="font-weight-normal link_primary"
              onClick={() => defineDetalhesObjeto("V", sinistro.vendaId)} >
              {sinistro.vendaId}
            </span>
          </div>
        </div>
      ) : null}

      {sinistro.tipoSinistroDescricao ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Tipo Sinistro:{" "}
            <span className="font-weight-normal">
              {sinistro.tipoSinistroDescricao}
            </span>
          </div>
        </div>
      ) : null}

      {sinistro.situacaoSinistroDescricao ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Situação Sinistro:{" "}
            <span className="font-weight-normal">
              {sinistro.situacaoSinistroDescricao}
            </span>
          </div>
        </div>
      ) : null}

      {sinistro.sinistroDataHora ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Data/Hora:{" "}
            <span className="font-weight-normal">
              {sinistro.sinistroDataHora}
            </span>
          </div>
        </div>
      ) : null}

      {sinistro.usuarioNome ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Nome Usuário:{" "}
            <span className="font-weight-normal">
              {sinistro.usuarioNome}
            </span>
          </div>
        </div>
      ) : null}

      {sinistro.seguradoraDescricao ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Seguradora:{" "}
            <span className="font-weight-normal">
              {sinistro.seguradoraDescricao}
            </span>
          </div>
        </div>
      ) : null}

      {sinistro.apoliceNumero ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Núm. Apólice:{" "}
            <span className="font-weight-normal">
              {sinistro.apoliceNumero}
            </span>
          </div>
        </div>
      ) : null}

      {sinistro.apoliceData ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Data Apólice:{" "}
            <span className="font-weight-normal">
              {sinistro.apoliceData}
            </span>
          </div>
        </div>
      ) : null}

      {sinistro.sinistroDataAcidente ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Data Sinistro:{" "}
            <span className="font-weight-normal">
              {sinistro.sinistroDataAcidente}
            </span>
          </div>
        </div>
      ) : null}

      {sinistro.sinistroDataRetorno ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Data de Retorno:{" "}
            <span className="font-weight-normal">
              {sinistro.sinistroDataRetorno}
            </span>
          </div>
        </div>
      ) : null}

      {sinistro.sinistroDataAvisoSeguradora ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Data Aviso Seguradora:{" "}
            <span className="font-weight-normal">
              {sinistro.sinistroDataAvisoSeguradora}
            </span>
          </div>
        </div>
      ) : null}

      {sinistro.sinistroDataLiquidacao ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Data Liquidação:{" "}
            <span className="font-weight-normal">
              {sinistro.sinistroDataLiquidacao}
            </span>
          </div>
        </div>
      ) : null}

      {sinistro.sinistroNomeCliente ? (
        <div className="col-md-5">
          <div className="font-weight-bold">
            Nome Cliente:{" "}
            <span className="font-weight-normal">
              {sinistro.sinistroNomeCliente}
            </span>
          </div>
        </div>
      ) : null}

      {sinistro.sinistroAvisoSegCliente ? (
        <div className="col-md-4">
          <div className="font-weight-bold">
            Núm. Aviso Segur. Cliente:{" "}
            <span className="font-weight-normal">
              {sinistro.sinistroAvisoSegCliente}
            </span>
          </div>
        </div>
      ) : null}

      {sinistro.sinistroTelCliente ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Telefone Cliente:{" "}
            <span className="font-weight-normal">
              {sinistro.sinistroDddCliente}{sinistro.sinistroTelCliente}
            </span>
          </div>
        </div>
      ) : null}

      {sinistro.sinistroVlrIndenizacao ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Valor Indenização:{" "}
            <span className="font-weight-normal">
              {sinistro.sinistroVlrIndenizacao}
            </span>
          </div>
        </div>
      ) : null}
      <div className="col-12" />
      {sinistro.sinistroNomeTerceiro ? (
        <div className="col-md-5">
          <div className="font-weight-bold">
            Nome Terceiro:{" "}
            <span className="font-weight-normal">
              {sinistro.sinistroNomeTerceiro}
            </span>
          </div>
        </div>
      ) : null}

      {sinistro.sinistroAvisoSegTerceiro ? (
        <div className="col-md-4">
          <div className="font-weight-bold">
            Núm. Aviso Segur. Terceiro:{" "}
            <span className="font-weight-normal">
              {sinistro.sinistroAvisoSegTerceiro}
            </span>
          </div>
        </div>
      ) : null}

      {sinistro.sinistroTelTerceiro ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Tel. Terceiro:{" "}
            <span className="font-weight-normal">
              {sinistro.sinistroDddTerceiro}{sinistro.sinistroTelTerceiro}
            </span>
          </div>
        </div>
      ) : null}

      {sinistro.sinistroVlrIndenizacaoTerceiro ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Valor Indenização Terceiro:{" "}
            <span className="font-weight-normal">
              {sinistro.sinistroVlrIndenizacaoTerceiro}
            </span>
          </div>
        </div>
      ) : null}
      <div className="col-12" />
      {sinistro.sinistroFinalizado ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Finalizado:{" "}
            <span className="font-weight-normal">
              {sinistro.sinistroFinalizado === "1" ? "Sim" : "Não"}
            </span>
          </div>
        </div>
      ) : null}

      {sinistro.sinistroObservacao ? (
        <div className="col-md-12">
          <div className="font-weight-bold">
            Observações :{" "}
            <br />
            <textarea
              disabled={true}
              className="form-control"
              type="text"
              rows="4"
              maxLength="1000"
              name="sinistroObservacao"
              value={sinistro.sinistroObservacao}
            />
          </div>
        </div>
      ) : null}

    </Fragment>
  );
};

export default SinistroRealizado;
