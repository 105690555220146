import { ValidarAcessoPath, ValidarAcessoRelatorio } from "../Services/Acessos";

export const validarPermissoesPagina = (urlPath = "") => {

  if (!urlPath) {
    urlPath = window.location.pathname.toLowerCase().substring(1);
  }
  urlPath = urlPath.toLowerCase();
  const validarAcesso = urlPath !== "semacesso" && urlPath !== "home";

  async function ValidarAcessoRota() {
    const responseListaAcessos = await ValidarAcessoPath(urlPath);
    const permissoes = await responseListaAcessos.permissoes;
    if (!permissoes.temAcesso) {
      window.location.replace("/semacesso");
    }
    return permissoes;
  }

  if (validarAcesso) {
    return ValidarAcessoRota();
  }
};

export const validarPermissoesRelatorio = (urlPath = "") => {

  if (!urlPath) {
    urlPath = window.location.pathname.toLowerCase().substring(1);
  }
  urlPath = urlPath.toLowerCase();
  const validarAcesso = urlPath !== "semacesso" && urlPath !== "home";

  async function ValidarAcessoRota() {
    const responseListaAcessos = await ValidarAcessoRelatorio(urlPath);
    const permissoes = await responseListaAcessos.permissoes;
    if (!permissoes.temAcesso) {
      window.location.replace("/semacesso");
    }
    return permissoes;
  }

  if (validarAcesso) {
    return ValidarAcessoRota();
  }
};
