import React from 'react';
import { Fragment } from 'react';
import './PagamentoStyleDefault.css';
import asseguroulogo from '../../assets/img/asseguroulogo.svg';

const Erro = ({ erroProcessamento = false, mensagem = '', pagamentoHandler = () => {} }) => {
	return (
		<Fragment>
			<div className="pagePagamentoCartao_header">
				<div className="pagePagamentoCartao_container">
					<div className="row align-items-center">
						<div className="col-6 col-md-6">
							<div className="logo">
								<img src={asseguroulogo} alt="" width="250" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container mt-5 pt-5">
				<div className="row">
					<div className="col-12 text-center">
						{erroProcessamento ? (
							<div className="pagePagamentoCartao_title-step">
								Ops, algo deu errado durante o processamento do pagamento!
							</div>
						) : null}
						{erroProcessamento ? null : <div className="pagePagamentoCartao_error-section">{mensagem}</div>}
					</div>
				</div>
				<br />
				{pagamentoHandler && erroProcessamento ? (
					<div className="col-12 text-center">
						<button type="button" className="btn btn-default" onClick={() => pagamentoHandler(null)}>
							Alterar Informações do Cartão
						</button>
					</div>
				) : null}
			</div>
		</Fragment>
	);
};

export default Erro;
