import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "Download";
export const DownloadArquivo = (arquivoId) =>
  RequestWebApi.get(`/${controller}/DownloadArquivo`, {
    params: { arquivoId: arquivoId },
    responseType: "blob",
  })
    .then(function (response) {
      const type = response.headers["content-type"];
      const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `Arquivo_${arquivoId}`;
      link.click();
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { filaAtendimento: [] };
    });

export const DownloadTemplate = (nomeTemplate) =>
  RequestWebApi.get(`/${controller}/DownloadTemplate`, {
    params: { nomeTemplate: nomeTemplate },
    responseType: "blob",
  })
    .then(function (response) {
      const type = response.headers["content-type"];
      const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = nomeTemplate;
      link.click();
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { filaAtendimento: [] };
    });
