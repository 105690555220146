import React, { useContext } from 'react'
import "./FormularioFinalizacao.css";

const FormularioFinalizacao = ({ context, cotacaoSelecionada}) => {
  const {
    atendimentoValues
  } = useContext(context);
  return (
    <div className="componenteModalCotacaoAutoRastreador_formularioFinalizacaoContainer">
      <div className="componenteModalCotacaoAutoRastreador_formularioFinalizacaoSucesso">
        Proposta transmitida com sucesso
                </div>
      <div className="componenteModalCotacaoAutoRastreador_formularioFinalizacaoMensagem">
        {`A proposta ${atendimentoValues.dadosAtendimento.venda.vendaPropostaSeguradora} foi criada com a seguradora ${cotacaoSelecionada.seguradora}.`}
      </div>
    </div>

  )
}

export default FormularioFinalizacao
