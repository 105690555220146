/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext, useRef } from "react";
import AppContext from "../../../../Store/AppContext";
import {
  showApresentacao,
  hideApresentacao,
} from "../../../../Components/Atendimento/Apresentacao/Apresentacao";
import {
  showFinalizacao,
  hideFinalizacao,
} from "../../../../Components/Atendimento/Finalizacao/Finalizacao";
import { showSemAtendimento } from "../../../../Components/Atendimento/SemAtendimento/SemAtendimento";
import { buscarInitialStateFormularioAutoRastreador } from "../../../../Components/Atendimento/FormulariosEspecificosProduto/AutoRastreador/FormularioAutoRastreador";
import { Listar as ListarTipoContato } from "../../../../Services/TipoContato";
import { Listar as ListarSeguradora } from "../../../../Services/Seguradora";
import { Listar as ListarMotivoPerda } from "../../../../Services/MotivoPerda";
import { Listar as ListarTipoNegociacao } from "../../../../Services/TipoNegociacao";
import { RetornarAtendimento } from "../../../../Services/Atendimento";
import { Listar as ListarTipoAgendamento } from "../../../../Services/TipoAgendamento";
import { ProcessarAtendimento } from "../../../../Services/Atendimento";
import { ListarListBoxPorRotulo } from "../../../../Services/ListBox";
import { Listar as ListarDut } from "../../../../Services/Dut";
import {
  ListarArquivosLead,
  UploadAnexosLead,
  RemoverAnexosLead,
} from "../../../../Services/Lead";
import { DownloadArquivo } from "../../../../Services/Download";
import {
  RetornarMetaEquipe,
  RetornarMetaConsultor,
} from "../../../../Services/Grafico";
import { validarPermissoesPagina } from "../../../../Utils/validarPermissoesPagina";
export const AtendimentoContext = createContext();

const tituloTela = "Atendimento";

const tiposAtendimentoEnum = {
  contato: "C",
  perdido: "P",
  negociacao: "N",
  venda: "V",
};

const descricaoTemperatura = (leadScoringSystem) => {
  switch (leadScoringSystem) {
    case "1":
      return "Muito Frio";
    case "2":
      return "Frio";
    case "3":
      return "Morno";
    case "4":
      return "Quente";
    case "5":
      return "Muito Quente";
    default:
      return "";
  }
};

const dadosAtendimentoDefault = {
  tipoAtendimento: tiposAtendimentoEnum.contato,
  tempoAtendimento: 0,
  tipoNegociacaoId: 0,
  dataVencimentoApolice: "",
  filaAtendimento: {
    filaAtendimentoId: "",
    totalContatos: "",
    dataRecebimentoLead: "",
    horaRecebimentoLead: "",
  },
  lead: {
    leadId: 0,
    leadCod: "",
    leadNome: "",
    leadNumDocumento: "",
    leadEmail: "",
    leadDddTelefone: "",
    leadNumTelefone: "",
    leadDddCelular: "",
    leadNumCelular: "",
    leadDataCadastro: "",
    leadMesAnoProducao: "",
    cidadeId: "",
    leadScoringUser: "",
    leadScoringSystem: "",
    leadDataVencApolice: "",
    placaVeiculo: "",
    marcaVeiculo: "",
    modeloVeiculo: "",
    anoFabricacaoVeiculo: "",
    anoModeloVeiculo: "",
    cidade: "",
    uf: "",
    temperaturaValor: "",
    temperaturaDescricao: "",
    leadDataInteracao: "",
    leadClassificacao: "",
    landingPageNome: "",
    landingPageUrl: "",
    tipoLead: "",
    origemLeadNome: "",
    fornecedorLead: "",
    leadAutoTipoUtilizacaoDut: "",
    keyWords: [],
    leadIdRenovacao: 0,
    leadPreferenciaContato: "",
    leadObservacaoLead: "",
    leadDataPrimeiroContato: "",
    leadHoraPrimeiroContato: "",
    leadPreCotacao: false,
    leadCorporativo: {
      leadCorporativoPorte: '',
      leadCorporativoEmpresaPlanoVigente: '',
      leadCorporativoDataAnivContrato: '',
      leadCorporativoNumFuncionarios: 0,
      leadCorporativoVlrFaturamentoAnual: 0,
      leadCorporativoPercAgenciamento: 0,
      leadCorporativoPercVitalicio: 0
    },
    leadContatos: []
  },
  agendamento: {
    tipoAgendamentoId: 0,
    dataAgendamento: "",
    horaAgendamento: "",
    observacao: "",
  },
  contato: {
    tipoContatoId: 0,
    observacao: "",
  },
  cotacaoPerdida: {
    motivoPerdaId: 0,
    observacao: "",
  },
  negociacao: {
    observacao: "",
  },
  venda: {
    seguradoraId: 0,
    dataFechamento: "",
    valorLiquido: "",
    vendaTipoTransmissao: "",
    vendaPercComissao: 0,
    vendaProtocoloSeguradora: "",
    vendaPropostaSeguradora: "",
    vendaServicoContratado: "",
  },
  produto: {
    produtoId: 0,
    produtoCod: "",
    produtoNome: "",
    produtoFormAuxLead: "",
    produtoFormAuxVenda: "",
  },
  historicoAtendimento: [],
  vendaAuto: {
    vendaId: 0,
    vendaAutoFormaPagto1Parc: "",
    vendaAutoTipoVistoria: "",
    vendaAutoNumAgendVistoria: "",
    vendaAutoNomePrestadora: "",
    vendaAutoDataPrevistaVistoria: "",
    vendaAutoRamal: "",
    vendaAutoData: "",
    vendaAutoHora: "",
    vendaAutoAdiqCartaoPortoVenda: "N",
  },
  autoRastreador: buscarInitialStateFormularioAutoRastreador(),
  atendimentoDadosUltimaContratacao: {
    seguradoraId: "",
    seguradoraNome: "",
    dataFechamento: "",
    valorLiquido: "",
    vendaProtocoloSeguradora: "",
    vendaPropostaSeguradora: "",
    vendaServicoContratado: "",
    consultorNome: "",
  },
};

function initialState(filaAtendimentoId) {
  return {
    tiposAtendimentoEnum: tiposAtendimentoEnum,
    atendimentoIniciado: false,
    gerarPedidoVenda: false,
    filaAtendimentoId: filaAtendimentoId,
    dadosAtendimento: dadosAtendimentoDefault,
    tiposTransmissao: [],
    tiposContato: [],
    motivosPerda: [],
    tiposAgendamento: [],
    seguradoras: [],
    formasPagamento: [],
    tiposVistoria: [],
    listaDut: [],
    leadTiposNegocio: [],
    dadosGraficos: null,
    cotacoes: [],
    tiposNegociacao: [],
    descricaoTemperatura: descricaoTemperatura,
  };
}

let arquivos = [];

const obterDadosGraficos = async () => {
  let dataPerformace = null;
  const responseRetornarMetaEquipe = await RetornarMetaEquipe();
  if (responseRetornarMetaEquipe.success) {
    dataPerformace = {
      ...dataPerformace,
      metasEquipes: responseRetornarMetaEquipe.metasEquipes,
    };
  }
  const responseRetornarMetaConsultor = await RetornarMetaConsultor();

  if (responseRetornarMetaConsultor.success) {
    dataPerformace = {
      ...dataPerformace,
      metasConsultor: responseRetornarMetaConsultor.metasConsultor,
    };
  }

  return dataPerformace;
};

const AtendimentoProvider = ({ children }) => {
  const tempoTela = useRef();
  const { showLoading, hideLoading, setTituloTela, showMessage } =
    useContext(AppContext);
  const [atendimentoValues, setAtendimentoValues] = useState(initialState());

  async function loadDependences() {
    let tiposAgendamento = [];
    let tiposContato = [];
    let motivosPerda = [];
    let seguradoras = [];
    let tiposTransmissao = [];
    let formasPagamento = [];
    let tiposVistoria = [];
    let leadTiposNegocio = [];
    let listaDut = [];
    let tiposNegociacao = [];

    const permissoesPagina = validarPermissoesPagina();
    const responseListarTipoContato = await ListarTipoContato();
    const responseListarSeguradora = await ListarSeguradora();
    const responseListarListarMotivoPerda = await ListarMotivoPerda();
    const responseListarTipoAgendamento = await ListarTipoAgendamento();
    const responseListarTipoTransmissao = await ListarListBoxPorRotulo(
      "TipoTransmissao"
    );
    const responseListarFormaPagamento = await ListarListBoxPorRotulo(
      "FormaPagamento"
    );
    const responseListarTipoVistoria = await ListarListBoxPorRotulo(
      "TipoVistoria"
    );
    const responseListarLeadTipoNegocio = await ListarListBoxPorRotulo(
      "LeadTipoNegocio"
    );
    const dadosGraficos = await obterDadosGraficos();
    const responseListarDut = await ListarDut(0);

    const responseListarListarTipoNegociacao = await ListarTipoNegociacao();

    if (
      !responseListarTipoContato.success ||
      !responseListarSeguradora.success ||
      !responseListarListarMotivoPerda.success ||
      !responseListarTipoAgendamento.success ||
      !responseListarTipoTransmissao.success ||
      !responseListarFormaPagamento.success ||
      !responseListarTipoVistoria.success ||
      !responseListarLeadTipoNegocio.success ||
      !responseListarDut.success ||
      !responseListarListarTipoNegociacao.success
    ) {
      showMessage("Erro", "Erro Inesperado ao carregar dados basicos.");
    } else {
      tiposContato = await responseListarTipoContato.tiposContato
        .filter((x) => x.tipoContatoAtivo === "A")
        .map((tipoContato) => {
          return {
            value: tipoContato.tipoContatoId,
            label: tipoContato.tipoContatoDescricao,
          };
        });

      motivosPerda = await responseListarListarMotivoPerda.motivosPerda
        .filter((x) => x.motivoPerdaStatus === "A")
        .map((motivoPerda) => {
          return motivoPerda;
        });
      tiposAgendamento = await responseListarTipoAgendamento.tiposAgendamento
        .filter((x) => x.tipoAgendamentoAtivo === "A")
        .map((tipoAgendamento) => {
          return {
            value: tipoAgendamento.tipoAgendamentoId,
            label: tipoAgendamento.tipoAgendamentoDescricao,
          };
        });

      seguradoras = await responseListarSeguradora.seguradoras
        .filter((x) => x.seguradoraStatus === "A")
        .map((seguradora) => {
          return {
            value: seguradora.seguradoraId,
            label: seguradora.seguradoraDescricao,
          };
        });

      tiposNegociacao = await responseListarListarTipoNegociacao.tiposNegociacao
        .filter((x) => x.tipoNegociacaoStatus === "A")
        .map((tipoNegociacao) => {
          return {
            value: tipoNegociacao.tipoNegociacaoId,
            label: tipoNegociacao.tipoNegociacaoDescricao,
          };
        });

      tiposTransmissao = await responseListarTipoTransmissao.items;
      formasPagamento = await responseListarFormaPagamento.items;
      tiposVistoria = await responseListarTipoVistoria.items;
      leadTiposNegocio = await responseListarLeadTipoNegocio.items;
      listaDut = await responseListarDut.listaDut.filter(
        (dut) => dut.dutDesativado === "N"
      );
    }

    return {
      permissoesPagina,
      tiposContato,
      motivosPerda,
      tiposAgendamento,
      seguradoras,
      tiposTransmissao,
      formasPagamento,
      tiposVistoria,
      leadTiposNegocio,
      dadosGraficos,
      listaDut,
      tiposNegociacao,
    };
  }

  const buscaProximoAtendimentoDaFila = async (filaAtendimentoId) => {
    setTituloTela(tituloTela);
    showLoading();
    window.scroll(0, 0);
    let dadosAtendimento = dadosAtendimentoDefault;
    if (filaAtendimentoId) {
      dadosAtendimento = await RetornarAtendimento(filaAtendimentoId);
    } else {
      dadosAtendimento = await RetornarAtendimento();
    }
    dadosAtendimento = await dadosAtendimento.dadosAtendimento;

    if (!dadosAtendimento.filaAtendimento) {
      dadosAtendimento = dadosAtendimentoDefault;
      arquivos = [];
      hideLoading();
      showSemAtendimento();
      return;
    } else {
      hideFinalizacao();
    }

    const responseListarArquivosLead = await ListarArquivosLead(
      dadosAtendimento.lead.leadId
    );

    if (!responseListarArquivosLead.success) {
      showMessage("Erro", responseListarArquivosLead.message);
      responseListarArquivosLead.errors.map((erro) =>
        showMessage("Erro", erro.errorMessage)
      );
      arquivos = [];
    }

    arquivos = await responseListarArquivosLead.arquivos;

    const dependencias = await loadDependences();

    setAtendimentoValues({
      ...initialState(),
      dadosAtendimento: {
        ...dadosAtendimento,
        tipoAtendimento: tiposAtendimentoEnum.contato,
        contato: dadosAtendimentoDefault.contato,
        cotacaoPerdida: dadosAtendimentoDefault.cotacaoPerdida,
        agendamento: dadosAtendimentoDefault.agendamento,
        venda: dadosAtendimentoDefault.venda,
        vendaAuto: dadosAtendimentoDefault.vendaAuto,
        autoRastreador: dadosAtendimento.autoRastreador
          ? dadosAtendimento.autoRastreador
          : dadosAtendimentoDefault.autoRastreador,
      },
      permissoesPagina: await dependencias.permissoesPagina,
      tiposContato: await dependencias.tiposContato,
      motivosPerda: await dependencias.motivosPerda,
      tiposAgendamento: await dependencias.tiposAgendamento,
      seguradoras: await dependencias.seguradoras,
      tiposTransmissao: await dependencias.tiposTransmissao,
      formasPagamento: await dependencias.formasPagamento,
      tiposVistoria: await dependencias.tiposVistoria,
      leadTiposNegocio: await dependencias.leadTiposNegocio,
      dadosGraficos: await dependencias.dadosGraficos,
      listaDut: await dependencias.listaDut,
      tiposNegociacao: await dependencias.tiposNegociacao,
    });
    showApresentacao();
    hideLoading();
  };

  const iniciarAtendimento = () => {
    setAtendimentoValues({
      ...atendimentoValues,
      atendimentoIniciado: true,
    });

    hideApresentacao();
  };

  const saveHandler = async (tipoContato) => {
    showLoading();
    let tempoAtendimento = tempoTela.current;
    let tipoAtendimento = tipoContato;

    const responseProcessarAtendimento = await ProcessarAtendimento(
      atendimentoValues.dadosAtendimento,
      tipoAtendimento,
      tempoAtendimento
    );

    if (!responseProcessarAtendimento.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao finalizar o atendimento: ${responseProcessarAtendimento.message
          ? responseProcessarAtendimento.message
          : "Indefinido"
        }`
      );
      if (responseProcessarAtendimento.errors) {
        responseProcessarAtendimento.errors.map((erro) =>
          showMessage("Erro", erro.errorMessage)
        );
      }
      setAtendimentoValues({
        ...atendimentoValues,
        atendimentoIniciado: false,
      });
      return;
    }

    hideLoading();
    showMessage("Sucesso", "Atendimento finalizado com sucesso.");
  };

  const agendarContatoHandler = () => {
    saveHandler(tiposAtendimentoEnum.contato);
    showFinalizacao();
  };
  const cotacaoPerdidaHandler = () => {
    saveHandler(tiposAtendimentoEnum.perdido);
    showFinalizacao();
  };
  const agendarNegociacaoHandler = () => {
    saveHandler(tiposAtendimentoEnum.negociacao);
    showFinalizacao();
  };
  const finalizarNegociacaoHandler = () => {
    saveHandler(tiposAtendimentoEnum.venda);
    showFinalizacao();
  };

  const finalizacaoHandler = () => {
    buscaProximoAtendimentoDaFila();
  };

  const uploadAnexo = async (arquivo) => {
    showLoading("Aguarde, Importando Arquivo.");
    const responseUploadAnexosLead = await UploadAnexosLead(
      atendimentoValues.dadosAtendimento.lead.leadId,
      arquivo.nome,
      arquivo.arquivo
    );
    if (!responseUploadAnexosLead.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao finalizar o atendimento: ${responseUploadAnexosLead.message
          ? responseUploadAnexosLead.message
          : "Indefinido"
        }`
      );
      if (responseUploadAnexosLead.errors) {
        responseUploadAnexosLead.errors.map((erro) =>
          showMessage("Erro", erro.errorMessage)
        );
      }
      return;
    }
    arquivos = responseUploadAnexosLead.arquivos;
    hideLoading();
    showMessage("Sucesso", "Arquivo Importado com sucesso.");
  };

  const removerAnexo = async (arquivoId) => {
    showLoading("Aguarde, Removendo o arquivo.");
    const responseRemoverAnexosLead = await RemoverAnexosLead(
      atendimentoValues.dadosAtendimento.lead.leadId,
      arquivoId
    );
    if (!responseRemoverAnexosLead.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao finalizar o atendimento: ${responseRemoverAnexosLead.message
          ? responseRemoverAnexosLead.message
          : "Indefinido"
        }`
      );
      if (responseRemoverAnexosLead.errors) {
        responseRemoverAnexosLead.errors.map((erro) =>
          showMessage("Erro", erro.errorMessage)
        );
      }
      return;
    }
    arquivos = await responseRemoverAnexosLead.arquivos;

    hideLoading();
    showMessage("Sucesso", "Arquivo Removido.");
  };

  const downloadAnexo = async (arquivoId) => {
    showLoading("Aguarde, Removendo Arquivo.");
    await DownloadArquivo(arquivoId);
    hideLoading();
    showMessage("Sucesso", "Arquivo Importado com sucesso.");
  };

  const obterTempoAtendimento = (segundos) => {
    tempoTela.current = segundos;
  };

  return (
    <AtendimentoContext.Provider
      value={{
        atendimentoValues,
        setAtendimentoValues,
        iniciarAtendimento,
        buscaProximoAtendimentoDaFila,
        agendarContatoHandler,
        cotacaoPerdidaHandler,
        agendarNegociacaoHandler,
        finalizarNegociacaoHandler,
        finalizacaoHandler,
        uploadAnexo,
        removerAnexo,
        downloadAnexo,
        obterTempoAtendimento,
        arquivos,
        showMessage,
        showLoading,
        hideLoading,
      }}
    >
      {children}
    </AtendimentoContext.Provider>
  );
};

export default AtendimentoProvider;
