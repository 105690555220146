import React, { useContext, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import "./FormularioLojaVirtual.css";
import { LojaVirtualContext } from "../LojaVirtualContext";
import Formulario from "../../../../../Components/Cadastro/Formulario/Formulario";

const FormularioLojaVirtual = () => {
  const { values, setValues, getDetailHandler } = useContext(
    LojaVirtualContext
  );
  let { id } = useParams();

  useEffect(() => {
    getDetailHandler(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      data: {
        ...values.data,
        [name]: value,
      },
    });
  }

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setValues({
        ...values,
        data: {
          ...values.data,
          lojaVirtualImagem: undefined,
        },
      });
      return;
    }

    const selectedFile = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(selectedFile);

    reader.onload = function () {
      const lojaVirtualImagem = reader.result;
      setValues({
        ...values,
        data: {
          ...values.data,
          lojaVirtualImagem: lojaVirtualImagem,
        },
      });
      return;
    };
    reader.onerror = function (error) {
      setValues({
        ...values,
        data: {
          ...values.data,
          lojaVirtualImagem: undefined,
        },
      });
      return;
    };
  };

  return (
    <Fragment>
      <Formulario context={LojaVirtualContext}>

        <div className="col-12 col-md-3 form-group">
          <div className="row justify-content-md-center">
            {values.data.lojaVirtualImagem ? <img
              src={values.data.lojaVirtualImagem}
              alt=""
              srcSet=""
              className="fomularioLojaVirtualImagem"
            /> : null}
            <input
              disabled={values.formDisabled}
              type="file"
              className="customFileInputHide"
              onChange={onSelectFile}
              id="imageUpload"
            />
            {values.formDisabled ? null : (
              <label
                type="button"
                className="btn btn-primary ml-3"
                htmlFor="imageUpload"
              >
                Importar Foto
              </label>
            )}
          </div>
        </div>
        <div className="col-12" />
        <div className="col-12 col-md-2 form-group">
          <label>Codigo</label>
          <input
            disabled={values.formDisabled}
            className="form-control"
            type="text"
            name="lojaVirtualCod"
            value={values.data.lojaVirtualCod}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-10 form-group">
          <label>Nome</label>
          <input
            disabled={values.formDisabled}
            className="form-control"
            type="text"
            name="lojaVirtualNome"
            value={values.data.lojaVirtualNome}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-7 form-group">
          <label>Email</label>
          <input
            disabled={values.formDisabled}
            className="form-control"
            type="text"
            name="lojaVirtualEmail"
            value={values.data.lojaVirtualEmail}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-3 form-group">
          <label>Senha</label>
          <input
            disabled={values.formDisabled}
            className="form-control"
            type="text"
            name="lojaVirtualSenha"
            value={values.data.lojaVirtualSenha}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-2 form-group">
          <label>Status</label>
          <select
            disabled={values.formDisabled}
            className="form-control"
            name="lojaVirtualStatus"
            value={values.data.lojaVirtualStatus}
            onChange={onChangeInput}
          >
            <option className="disabled"></option>
            <option value="A"> Ativo </option>
            <option value="I"> Inativo </option>
          </select>
        </div>
        <div className="col-12 col-md-2 form-group">
          <label>Perc. Comissão</label>
          <input
            disabled={values.formDisabled}
            className="form-control porcentagem"
            type="text"
            name="lojaVirtualPercComissao"
            value={values.data.lojaVirtualPercComissao}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-3 form-group">
          <label>Tipo Documento</label>
          <select
            disabled={values.formDisabled}
            className="form-control"
            name="lojaVirtualTipoDocumento"
            value={values.data.lojaVirtualTipoDocumento}
            onChange={onChangeInput}
          >
            <option className="disabled" value="" />
            {values.tiposDocumento.map((tipoDocumento) => (
              <option key={tipoDocumento.codigo} value={tipoDocumento.codigo}>
                {tipoDocumento.descricao}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-3 form-group">
          <label>Documento</label>
          <input
            disabled={values.formDisabled}
            className="form-control"
            type="text"
            name="lojaVirtualNumDocumento"
            value={values.data.lojaVirtualNumDocumento}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-1 form-group">
          <label>Ddd</label>
          <input
            disabled={values.formDisabled}
            className="form-control numerico"
            type="text"
            name="lojaVirtualDddCelular"
            value={values.data.lojaVirtualDddCelular}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-3 form-group">
          <label>Celular</label>
          <input
            disabled={values.formDisabled}
            className="form-control numerico"
            type="text"
            name="lojaVirtualNumCelular"
            value={values.data.lojaVirtualNumCelular}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-12 form-group">
          <label>Endereço Pix</label>
          <input
            disabled={values.formDisabled}
            className="form-control"
            type="text"
            name="lojaVirtualEndPix"
            value={values.data.lojaVirtualEndPix}
            onChange={onChangeInput}
          />
        </div>

        <div className="col-12 col-md-12 form-group">
          <label>Observação</label>
          <textarea
            disabled={values.formDisabled}
            className="form-control"
            type="text"
            rows="4"
            maxLength="1000"
            name="lojaVirtualObservacao"
            value={values.data.lojaVirtualObservacao}
            onChange={onChangeInput}
          />
        </div>
      </Formulario>
    </Fragment>
  );
};

export default FormularioLojaVirtual;
