import React from 'react';
import $ from 'jquery';
import './Finalizacao.css';
import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';

export const showFinalizacao = () => {
	window.scroll(0, 0);
	$('#modalFinalizacaoLeadAtendimento').modal({
		backdrop: 'static',
		keyboard: false,
		show: true
	});
};
export const hideFinalizacao = () => {
	$('#modalFinalizacaoLeadAtendimento').modal('hide');
};

const Finalizacao = ({ proximoAtendimentoHandler }) => {
	const history = useHistory();
	return (
		<Fragment>
			<div
				className="modal fade"
				id="modalFinalizacaoLeadAtendimento"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="modalFinalizacaoLeadAtendimentoLabel"
				aria-hidden="true"
				data-keyboard="false"
				data-backdrop="static"
			>
				<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="modalFinalizacaoLeadAtendimentoLabel">
								Atendimento Finalizado
							</h5>
						</div>
						<div className="modal-body componenteAgendaRedirecionar_modalBody">
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-success"
									data-dismiss="modal"
									onClick={() => {
										history.push(`/Atendimento/FilaAtendimento`);
									}}
								>
									Fila Atendimento
								</button>
								<button
									type="button"
									className="btn btn-default"
									onClick={() => {
										proximoAtendimentoHandler();
									}}
								>
									Proximo Atendimento
								</button>
							</div>
						</div>{' '}
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Finalizacao;
