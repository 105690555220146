import React, { useContext, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { TarefasExecutarContext } from "../TarefasExecutarContext";
import Formulario from "../../../../../Components/Cadastro/Formulario/Formulario";
import TarefasExecutarItem from "../Components/TarefasExecutarItem/TarefasExecutarItem";
import FormularioComplementar from "../../../../../Components/Cadastro/FormularioComplementar/FormularioComplementar";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import PesquisaVenda from '../../../../../Components/Venda/PesquisaVenda/PesquisaVenda';
const images = require.context("../../../../../assets/img/icones/pages", true);
const iconeRemove = images("./trash.png");
const iconeEditar = images("./edit.png");

const FormularioTarefasExecutar = () => {
  const { values, setValues, getDetailHandler } = useContext(TarefasExecutarContext);
  let { id } = useParams();

  useEffect(() => {
    getDetailHandler(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      data: {
        ...values.data,
        [name]: value,
      },
    });
  }

  let removeHandler = (tarefasExecutarItensId) => {
    let itens = values.data.itens;
    let i = 0;
    for (i in itens) {
      if (itens[i].tarefasExecutarItensId == tarefasExecutarItensId) {
        itens[i].acao = "D";
      }
    }
    setValues({
      ...values,
      data: {
        ...values.data,
        itens,
      },
    });
  }

  function confirmarItemHandler(tarefasExecutarItem) {
    let itens = values.data.itens;
    let i = 0;
    if (itens.find(x => x.tarefasExecutarItensId == tarefasExecutarItem.tarefasExecutarItensId)) {
      for (i in itens) {
        if (itens[i].tarefasExecutarItensId == tarefasExecutarItem.tarefasExecutarItensId) {
          itens[i] = tarefasExecutarItem;
        }
      }
    } else {
      itens = [...itens, tarefasExecutarItem]
    }
    setValues({
      ...values,
      data: {
        ...values.data,
        itens,
      },
    });
  }

  const columnsDefinitionItens = [
    {
      text: "Id",
      dataField: "tarefasExecutarItensId",
      hidden: true,
    },
    {
      text: "Descrição",
      dataField: "tarefasExecutarItensDescrSolicitacao",
      headerStyle: { width: "200px" },
      sort: true,
    },
    {
      text: "Data",
      dataField: "tarefasExecutarItensDataHoraExibicao",
      headerStyle: { width: "50px" },
      sort: true,
    }
  ];
  const actionsTable = (cell, row) => {
    return (
      <div className="tabelasResultados__acoes">
        {<TarefasExecutarItem id={"tarefasExecutarItemEditar" + row.tarefasExecutarItensId} tarefasExecutarItem={row} confirmarHandler={confirmarItemHandler}>
          <button
            type="button"
            className="btn btn-primary btn-sm ml-1">
            <img src={iconeEditar} alt="" />
          </button>
        </TarefasExecutarItem>}
        {<button
          type="button"
          className="btn btn-danger btn-sm ml-2"
          onClick={() => removeHandler(row.tarefasExecutarItensId)}>
          <img src={iconeRemove} alt="" />
        </button>}
      </div>
    );
  };

  let columnsDefinitionComplete = [
    {
      dataField: "actions",
      text: "Ações",
      headerStyle: { width: "35px", textAlign: "center" },
      isDummyField: true,
      csvExport: false,
      headerAttrs: { width: 100 },
      formatter: actionsTable,
    },
    ...columnsDefinitionItens,
  ];
  const paginationOptions = {
    pageStartIndex: 0,
    paginationSize: 5,
    showTotal: true,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    firstPageText: 'Primeira',
    prePageText: 'Anterior',
    nextPageText: 'Proximo',
    lastPageText: 'Ultima',
    nextPageTitle: 'Proximo',
    prePageTitle: 'Anterior',
    firstPageTitle: 'Primeira',
    lastPageTitle: 'Ultima',
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    paginationTotalRenderer: (from, to, size) => (
      <span>
        Mostrando de {from} até {to} de {size} registros
      </span>
    )
  };
  function callBackPesquisaVenda(vendaComDependencias) {
    setValues({
      ...values,
      data: {
        ...values.data,
        vendaId: vendaComDependencias.vendaId,
      },
    });
  }
  return (
    <Fragment>
      <Formulario context={TarefasExecutarContext}>
        <div className="row">
          <div className="col-12 col-md-12 form-group">
            <PesquisaVenda vendaId={values.data.vendaId} disabled={values.formDisabled} callBack={callBackPesquisaVenda} />
          </div>
          <div className="col-12 col-md-5 form-group">
            <label>Código<span className="campoObratorio">*</span></label>
            <input
              disabled={values.formDisabled}
              className="form-control"
              type="text"
              maxLength="50"
              name="tarefasExecutarCodigo"
              value={values.data.tarefasExecutarCodigo}
              onChange={onChangeInput}
            />
          </div>
          <div className="col-12 col-md-3 form-group">
            <label>Tipo<span className="campoObratorio">*</span></label>
            <select
              disabled={values.formDisabled}
              className="form-control"
              name="tarefasExecutarTipo"
              value={values.data.tarefasExecutarTipo}
              onChange={onChangeInput}
            >
              <option className="disabled" value="" />
              <option className="disabled" value="P" >Pendência</option>
              <option className="disabled" value="E" >Endosso</option>
              <option className="disabled" value="S" >Sinistro</option>
              <option className="disabled" value="C" >Cobrança</option>
            </select>
          </div>
          <div className="col-12 col-md-4 form-group">
            <label>Status Controle</label>
            <input
              disabled={values.formDisabled}
              className="form-control uppercase"
              type="text"
              maxLength="50"
              name="tarefasExecutarStatusControle"
              value={values.data.tarefasExecutarStatusControle}
              onChange={onChangeInput}
            />
          </div>
          <div className="col-12 col-md-4 form-group">
            <label>
              Consultor Solicitante <span className="campoObratorio">*</span>
            </label>
            <select
              disabled={values.formDisabled}
              className="form-control"
              name="usuarioIdSolicitante"
              value={values.data.usuarioIdSolicitante}
              onChange={onChangeInput}
            >
              <option className="disabled" value="0" />
              {values.usuarios.map((usuario) => (
                <option key={usuario.usuarioId} value={usuario.usuarioId}>
                  {usuario.usuarioNome}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-md-4 form-group">
            <label>
              Consultor Dono
            </label>
            <select
              disabled={values.formDisabled}
              className="form-control"
              name="usuarioIdDono"
              value={values.data.usuarioIdDono}
              onChange={onChangeInput}
            >
              <option className="disabled" value="" />
              {values.usuarios.map((usuario) => (
                <option key={usuario.usuarioId} value={usuario.usuarioId}>
                  {usuario.usuarioNome}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-md-2 form-group">
            <label>
              Data Recebido
            </label>
            <input
              disabled={values.formDisabled}
              className="form-control"
              type="date"
              name="tarefasExecutarDataHoraRecebido"
              value={values.data.tarefasExecutarDataHoraRecebido}
              onChange={onChangeInput}
            />
          </div>
          <div className="col-12 col-md-2 form-group">
            <label>
              Data Enviado
            </label>
            <input
              disabled={values.formDisabled}
              className="form-control"
              type="date"
              name="tarefasExecutarDataHoraEnviado"
              value={values.data.tarefasExecutarDataHoraEnviado}
              onChange={onChangeInput}
            />
          </div>
        </div>
      </Formulario>
      <FormularioComplementar titulo="Itens" context={TarefasExecutarContext}>
        {values.formDisabled ? null :
          <TarefasExecutarItem id={"tarefasExecutarItemNovo"} tarefasExecutarItem={null} confirmarHandler={confirmarItemHandler}>
            <button
              type="button"
              className="btn btn-primary mb-3"
            >
              Adicionar Item
            </button>
          </TarefasExecutarItem>
        }

        <Fragment>
          <div className="row">
            <div className="col-12">
              <BootstrapTable
                bootstrap4={true}
                condensed={true}
                noDataIndication={() => "Nenhum Registro Selecionado"}
                striped={true}
                hover={true}
                keyField="tarefasExecutarItensId"
                data={values.data.itens.filter(x => x.acao == null || x.acao == "N" || x.acao == "U")}
                columns={values.formDisabled ? columnsDefinitionItens : columnsDefinitionComplete}
                rowStyle={{ fontSize: '11px' }}
                pagination={paginationFactory(paginationOptions)}
              />
            </div>
          </div>
        </Fragment>

      </FormularioComplementar>
    </Fragment>
  );
};

export default FormularioTarefasExecutar;
