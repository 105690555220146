/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import HistoricoLead from "../../../Components/Lead/HistoricoLead/HistoricoLead";
import {
	Detalhes as DetalhesCobranca, Listar as ListarCobranca, ProcessarCobranca, Remover as RemoverCobranca
} from '../../../Services/Cobranca';
import { DownloadArquivo } from '../../../Services/Download';
import { IncluirLeadInterno, ListarArquivosLead, RemoverAnexosLead, UploadAnexosLead } from '../../../Services/Lead';
import { ListarListBoxPorRotulo } from '../../../Services/ListBox';
import { Listar as ListarProduto } from '../../../Services/Produto';
import { Listar as ListarSeguradora } from '../../../Services/Seguradora';
import AppContext from '../../../Store/AppContext';
import { validarPermissoesPagina } from '../../../Utils/validarPermissoesPagina';
import { ListarSimples as ListarUsuario } from '../../../Services/Usuario';
import AlterarStatusCobranca from "./Components/AlterarStatusCobranca/AlterarStatusCobranca";
import { ListarSimples as ListarOrigensLead } from '../../../Services/OrigemLead';
export const CobrancaContext = createContext();

const tituloTela = 'Cobrança';

const defaultData = {
	cobrancaId: 0,
	leadId: 0,
	leadNome: '',
	leadNumDocumento: '',
	seguradoraId: 0,
	produtoId: 0,
	cobrancaApolice: '',
	cobrancaNumParcela: 0,
	cobrancaContatoCliente: '',
	cobrancaFormaPagto: '',
	cobrancaDataVencRepique: '',
	cobrancaStatus: '',
	cobrancaObservacao: '',
	observacaoHistorico: '',
	usuarioId: 0,
	cobrancaDataCancelamento: '',
	cobrancaDataFimApolice: '',
	cobrancaEndosso: '',
	cobrancaVencimentoParcela: '',
	cobrancaSolicitante: ''
};

const searchData = {
	leadNome: '',
	leadNumDocumento: '',
	cobrancaDataHoraDe: '',
	cobrancaDataHoraAte: '',
	cobrancaFormaPagto: '',
	cobrancaDataVencRepiqueDe: '',
	cobrancaDataVencRepiqueAte: '',
	cobrancaDataCancelamentoDe: '',
	cobrancaDataCancelamentoAte: '',
	cobrancaStatus: '',
	seguradoraId: 0,
	cobrancaSolicitante: '',
};

const columnsDefinition = [
	{
		text: 'Id',
		dataField: 'cobrancaId',
		hidden: true
	},
	{
		text: 'cobrancaStatus',
		dataField: 'cobrancaStatus',
		hidden: true
	},
	{
		text: '',
		dataField: 'cobrancaId',
		headerStyle: { width: '30px' },
		sort: true,
		formatter: (cell, row) => {
			return (
				<AlterarStatusCobranca cobrancaId={row.cobrancaId} cobrancaStatus={row.cobrancaStatus} disabled={false} />
			);
		},
	},
	{
		text: 'Data',
		dataField: 'cobrancaDataHora',
		headerStyle: { width: '75px' },
		sort: true
	},
	{
		text: 'Seguradora',
		dataField: 'seguradoraDescricao',
		headerStyle: { width: '175px' },
		sort: true
	},
	{
		text: 'Venc. Repique',
		dataField: 'cobrancaDataVencRepique',
		headerStyle: { width: '100px' },
		sort: true
	},
	{
		text: 'Nº Parcela',
		dataField: 'cobrancaNumParcela',
		headerStyle: { width: '75px' },
		sort: true
	},
	{
		text: 'Nome',
		dataField: 'leadNome',
		headerStyle: { width: '200px' },
		sort: true,
		formatter: (cell, row) => {
			return (
				<HistoricoLead leadId={row.leadId} disabled={false} >
					<span className="link_primary">
						{cell}
					</span>
				</HistoricoLead>
			);
		},
	},
	{
		text: 'Status',
		dataField: 'cobrancaStatusDescricao',
		headerStyle: { width: '100px' },
		sort: true,
		formatter: (cell, row) => {
			let cssClass = 'info';
			switch (row.cobrancaStatus) {
				case 'P':
					cssClass = 'success';
					break;
				case 'C':
					cssClass = 'danger';
					break;
				default:
					break;
			}
			return <span className={`badge badge-${cssClass}`}>{cell}</span>;
		}
	}
];

function initialState() {
	return {
		searchData: searchData,
		resultSearch: [],
		columnsDefinition: columnsDefinition,
		data: defaultData,
		seguradoras: [],
		statusCobranca: [],
		formasPagamento: [],
		origensLead: [],
		tiposContato: [],
		produtos: [],
		usuarios: [],
		formDisabled: true
	};
}
let arquivos = [];
const CobrancaProvider = ({ children }) => {
	const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(AppContext);
	const [values, setValues] = useState(initialState());
	let history = useHistory();

	const resetScreen = () => {
		showLoading();
		async function carregarDependencias() {
			const dependencias = await loadDependences();
			setTituloTela(tituloTela);
			setValues({
				...initialState(),
				permissoesPagina: await dependencias.permissoesPagina,
				seguradoras: await dependencias.seguradoras,
				statusCobranca: await dependencias.statusCobranca,
				formasPagamento: await dependencias.formasPagamento,
				origensLead: await dependencias.origensLead,
				tiposContato: await dependencias.tiposContato,
				produtos: await dependencias.produtos,
				usuarios: await dependencias.usuarios,
			});
			hideLoading();
		}
		arquivos = [];
		carregarDependencias();
	};

	const searchHandler = async () => {
		showLoading();
		const responseListar = await ListarCobranca(values.searchData);

		if (!responseListar.success) {
			showMessage('Erro', 'Erro Inesperado durante a pesquisa.');
			hideLoading();
			return;
		}

		const resultSearchData = await responseListar.origensLead;

		resultSearchData.map((data) => (data.Id = data.cobrancaId));

		setValues({
			...values,
			resultSearch: resultSearchData
		});

		if (resultSearchData.length === 0) {
			showMessage('Alerta', 'Nenhum registro encontrado.');
		}
		hideLoading();
	};

	const removeHandler = async (id) => {
		showLoading();
		const responseRemover = await RemoverCobranca(id);
		if (!responseRemover.success) {
			hideLoading();
			showMessage('Erro', `Erro durante a exclusão: ${responseRemover.message}`);
			return;
		}
		searchHandler();
		hideLoading();
		showMessage('Sucesso', 'Registro excluido.');
	};

	async function loadDependences() {
		try {
			const [
				permissoesPagina,
				responseListarSeguradora,
				responseListarProduto,
				responseListarUsuario,
				responseListarOrigensLead,
				responseListarListBoxPorRotuloStatusCobranca,
				responseListarListBoxPorRotuloFormaPagamento,
				responseListarListBoxPorRotuloTiposContato,
			] = await Promise.all(
				[
					validarPermissoesPagina(),
					ListarSeguradora({}),
					ListarProduto({}),
					ListarUsuario({}),
					ListarOrigensLead({}),
					ListarListBoxPorRotulo('StatusCobranca'),
					ListarListBoxPorRotulo('FormaPagamento'),
					ListarListBoxPorRotulo('TipoContato')
				]);

			return {
				permissoesPagina: permissoesPagina,
				seguradoras: responseListarSeguradora.seguradoras.filter(
					(seguradora) => seguradora.seguradoraStatus === 'A'
				),
				statusCobranca: responseListarListBoxPorRotuloStatusCobranca.items,
				formasPagamento: responseListarListBoxPorRotuloFormaPagamento.items,
				tiposContato: responseListarListBoxPorRotuloTiposContato.items,
				produtos: responseListarProduto.produtos.filter((item) => item.produtoAtivo === 'A'),
				usuarios: responseListarUsuario.usuarios,
				origensLead: responseListarOrigensLead.origensLead.filter((item) => item.origemLeadAtivo === 'A'),
			};
		} catch (error) {
			showMessage('Erro', 'Erro Inesperado ao carregar dados basicos.');
			return;
		}

	}

	const getDetailHandler = async (id) => {
		showLoading();
		setTituloTela(tituloTela);
		const dependencias = await loadDependences();

		if (id > 0) {
			const responseDetalhes = await DetalhesCobranca(id);
			const { success, cobranca } = await responseDetalhes;
			if (!success) {
				showMessage('Erro', 'Erro ao buscar os detalhes do registro.');
				setValues({
					...values,
					data: defaultData,
					permissoesPagina: await dependencias.permissoesPagina,
					seguradoras: [],
					statusCobranca: [],
					formasPagamento: [],
					origensLead: [],
					tiposContato: [],
					produtos: [],
					usuarios: [],
				});
			}

			const responseListarArquivosLead = await ListarArquivosLead(cobranca.leadId);

			if (!responseListarArquivosLead.success) {
				showMessage('Erro', responseListarArquivosLead.message);
				responseListarArquivosLead.errors.map((erro) => showMessage('Erro', erro.errorMessage));
				arquivos = [];
			}

			arquivos = await responseListarArquivosLead.arquivos;

			setValues({
				...values,
				data: {
					...defaultData,
					...cobranca
				},
				permissoesPagina: await dependencias.permissoesPagina,
				seguradoras: await dependencias.seguradoras,
				statusCobranca: await dependencias.statusCobranca,
				formasPagamento: await dependencias.formasPagamento,
				origensLead: await dependencias.origensLead,
				tiposContato: await dependencias.tiposContato,
				produtos: await dependencias.produtos,
				usuarios: await dependencias.usuarios,
			});
		} else {
			const responseIncluirLeadInterno = await IncluirLeadInterno();

			setValues({
				...values,
				data: {
					...defaultData,
					cobrancaId: 0,
					leadId: responseIncluirLeadInterno.leadId
				},
				formDisabled: false,
				permissoesPagina: await dependencias.permissoesPagina,
				seguradoras: await dependencias.seguradoras,
				statusCobranca: await dependencias.statusCobranca,
				formasPagamento: await dependencias.formasPagamento,
				origensLead: await dependencias.origensLead,
				tiposContato: await dependencias.tiposContato,
				produtos: await dependencias.produtos,
				usuarios: await dependencias.usuarios,
			});
		}
		hideLoading();
	};

	const editHandler = () => {
		setValues({
			...values,
			formDisabled: false
		});
	};

	const saveHandler = async () => {
		showLoading();
		const responseIncluir = await ProcessarCobranca(values.data);
		if (!responseIncluir.success) {
			hideLoading();
			showMessage('Erro', `Erro ao criar o registro: ${responseIncluir.message}`);
			responseIncluir.errors.map((erro) => showMessage('Erro', erro.errorMessage));
			return;
		}

		resetScreen();
		hideLoading();
		showMessage('Sucesso', 'Registro criado.');
		history.goBack();
	};

	const cancelHandler = () => {
		setValues({
			...values,
			data: defaultData,
			formDisabled: true
		});
	};

	const uploadAnexo = async (arquivo) => {
		showLoading('Aguarde, Importando Arquivo.');
		const responseUploadAnexosLead = await UploadAnexosLead(values.data.leadId, arquivo.nome, arquivo.arquivo);
		if (!responseUploadAnexosLead.success) {
			hideLoading();
			showMessage(
				'Erro',
				`Erro ao finalizar o atendimento: ${responseUploadAnexosLead.message
					? responseUploadAnexosLead.message
					: 'Indefinido'}`
			);
			if (responseUploadAnexosLead.errors) {
				responseUploadAnexosLead.errors.map((erro) => showMessage('Erro', erro.errorMessage));
			}
			return;
		}
		arquivos = responseUploadAnexosLead.arquivos;

		hideLoading();
		showMessage('Sucesso', 'Arquivo Importado com sucesso.');
	};

	const removerAnexo = async (arquivoId) => {
		showLoading('Aguarde, Removendo o arquivo.');
		const responseRemoverAnexoLead = await RemoverAnexosLead(values.data.leadId, arquivoId);
		if (!responseRemoverAnexoLead.success) {
			hideLoading();
			showMessage(
				'Erro',
				`Erro ao finalizar o atendimento: ${responseRemoverAnexoLead.message
					? responseRemoverAnexoLead.message
					: 'Indefinido'}`
			);
			if (responseRemoverAnexoLead.errors) {
				responseRemoverAnexoLead.errors.map((erro) => showMessage('Erro', erro.errorMessage));
			}
			return;
		}
		arquivos = await responseRemoverAnexoLead.arquivos;
		hideLoading();
		showMessage('Sucesso', 'Arquivo Removido.');
	};

	const downloadAnexo = async (arquivoId) => {
		showLoading('Aguarde, Baixando Arquivo.');
		await DownloadArquivo(arquivoId);
		hideLoading();
	};

	return (
		<CobrancaContext.Provider
			value={{
				values,
				setValues,
				resetScreen,
				searchHandler,
				cancelHandler,
				removeHandler,
				saveHandler,
				getDetailHandler,
				editHandler,
				uploadAnexo,
				removerAnexo,
				downloadAnexo,
				arquivos,
				showMessage
			}}
		>
			{children}
		</CobrancaContext.Provider>
	);
};

export default CobrancaProvider;
