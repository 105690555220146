import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "JornadaTrabalhoFalta";
export const Listar = (jornadasTrabalhoFalta) =>
  RequestWebApi.get(`/${controller}/Listar`, {
    params: { ...jornadasTrabalhoFalta },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const jornadasTrabalhoFalta = resultData;
      return { success, jornadasTrabalhoFalta };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { jornadasTrabalhoFalta: [] };
    });

export const Detalhes = (usuarioId) =>
  RequestWebApi.get(`/${controller}/Detalhes`, {
    params: { usuarioId: usuarioId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const { usuarioId, datas } = resultData;
      return { success, usuarioId, datas };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, usuarioId: "", datas: [] };
    });

export const Incluir = (jornadaTrabalhoFalta) =>
  RequestWebApi.post(`/${controller}/Incluir`, jornadaTrabalhoFalta)
    .then(function (response) {
      const { resultData, success } = response.data;
      const novajornadaTrabalhoFalta = resultData;
      return { success, novajornadaTrabalhoFalta };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (usuarioId) =>
  RequestWebApi.delete(`/${controller}/Remover`, {
    params: { usuarioId: usuarioId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });
