import React, { useContext, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { ProdutoContext } from '../ProdutoContext';
import Formulario from '../../../../../Components/Cadastro/Formulario/Formulario';

const FormularioProduto = () => {
	const { values, setValues, getDetailHandler } = useContext(ProdutoContext);
	let { id } = useParams();

	useEffect(() => {
		getDetailHandler(id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function onChangeInput(event) {
		const { name, value } = event.target;
		setValues({
			...values,
			data: {
				...values.data,
				[name]: value
			}
		});
	}

	return (
		<Fragment>
			<Formulario context={ProdutoContext}>
				<div className="col-12 col-md-2 form-group">
					<label>Código</label>
					<input
						disabled={values.formDisabled}
						className="form-control"
						type="text"
						maxLength="5"
						name="produtoCod"
						value={values.data.produtoCod}
						onChange={onChangeInput}
					/>
				</div>
				<div className="col-12 col-md-3 form-group">
					<label>Nome do Produto</label>
					<input
						disabled={values.formDisabled}
						className="form-control"
						type="text"
						maxLength="20"
						name="produtoNome"
						value={values.data.produtoNome}
						onChange={onChangeInput}
					/>
				</div>
				<div className="col-12 col-md-3 form-group">
					<label>Form. Aux. Lead</label>
					<select
						disabled={values.formDisabled}
						className="form-control"
						name="produtoFormAuxLead"
						value={values.data.produtoFormAuxLead}
						onChange={onChangeInput}
					>
						<option value="">NENHUM</option>
						<option value="FORMLEADAUTO"> FORMLEADAUTO </option>
						<option value="FORMAUTORASTREADOR"> FORMAUTORASTREADOR </option>
						<option value="FORMLEADCORPORATIVO"> FORMLEADCORPORATIVO </option>
						<option value="FORMLEADRISK"> FORMLEADRISK </option>
					</select>
				</div>
				<div className="col-12 col-md-3 form-group">
					<label>Form. Aux. Venda</label>
					<select
						disabled={values.formDisabled}
						className="form-control"
						name="produtoFormAuxVenda"
						value={values.data.produtoFormAuxVenda}
						onChange={onChangeInput}
					>
						<option value="">NENHUM</option>
						<option value="FORMVDAAUTO"> FORMVDAAUTO </option>
					</select>
				</div>
				<div className="col-12 col-md-2 form-group">
					<label>Status</label>
					<select
						disabled={values.formDisabled}
						className="form-control"
						name="produtoAtivo"
						value={values.data.produtoAtivo}
						onChange={onChangeInput}
					>
						<option className="disabled"></option>
						<option value="A"> Ativo </option>
						<option value="I"> Inativo </option>
					</select>
				</div>
			</Formulario>
		</Fragment>
	);
};

export default FormularioProduto;
