import React, { useContext, Fragment, useEffect } from "react";
import { TemplateSmsContext } from "../TemplateSmsContext";
import Pesquisar from "../../../../../Components/Cadastro/Pesquisar/Pesquisar";
import TabelaResultados from "../../../../../Components/Cadastro/TabelaResultados/TabelaResultados";

const PesquisarTemplateSms = () => {
  const { values, setValues, resetScreen } = useContext(TemplateSmsContext);

  useEffect(() => {
    resetScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      searchData: {
        ...values.searchData,
        [name]: value,
      },
    });
  }

  return (
    <Fragment>
      <Pesquisar context={TemplateSmsContext}>
      <div className="col-12 col-md-3">
          <label>Produto</label>
          <select
            className="form-control"
            name="produtoId"
            value={values.searchData.produtoId}
            onChange={onChangeInput}
          >
            <option className="disabled" value="0"></option>
            {values.produtos.map((produto) => (
              <option key={produto.produtoId} value={produto.produtoId}>
                {produto.produtoNome}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-4">
          <label>Nome do Template</label>
          <input
            className="form-control"
            type="text"
            name="templateSmsNome"
            value={values.searchData.templateSmsNome}
            onChange={onChangeInput}
          />
        </div>
      </Pesquisar>
      <TabelaResultados context={TemplateSmsContext} />
    </Fragment>
  );
};

export default PesquisarTemplateSms;
