import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "Sinistro";

export const Listar = (sinistro) =>
  RequestWebApi.get(`/${controller}/Listar`, {
    params: { ...sinistro },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const origensLead = resultData;
      return { success, origensLead };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { origensLead: [] };
    });

export const Detalhes = (sinistroId) =>
  RequestWebApi.get(`/${controller}/Detalhes`, {
    params: { sinistroId: sinistroId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const sinistro = resultData;
      return { success, sinistro };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, sinistro: {} };
    });

export const ProcessarSinistro = (sinistro) =>
  RequestWebApi.post(`/${controller}/ProcessarSinistro`, sinistro)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (sinistroId) =>
  RequestWebApi.delete(`/${controller}/Remover`, {
    params: { sinistroId: sinistroId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });
