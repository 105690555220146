import $ from 'jquery';
import React, { Fragment, useEffect, useState, useContext } from "react";
import "./AtalhosTelefone.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faWhatsappSquare } from "@fortawesome/free-brands-svg-icons"
import { FazerLigacao } from "../../../Services/Ura";
import AppContext from '../../../Store/AppContext';

const initialState = {
  telefoneMovel: false


}

const AtalhosTelefone = ({ children = null, id = 0, texto = "", leadId = null, numeroTelefoneEntrada = "", disabled = false }) => {
  const { showLoading, hideLoading, showMessage } = useContext(AppContext);
  const [atalhosTelefoneValues, setAtalhosTelefoneValues] = useState(initialState);
  function showModal() {
    $(`#modalAtalhosTelefone${id}`).modal('show');
  }

  useEffect(() => {
    let numeroProcessado = corrigeDDI(numeroTelefoneEntrada);
    setAtalhosTelefoneValues({
      ...atalhosTelefoneValues,
      telefoneMovel: telefoneMovel(numeroProcessado),
      telefoneValido: telefoneValido(numeroProcessado),
      numeroTelefone: numeroProcessado
    });
  }, [numeroTelefoneEntrada]);

  function corrigeDDI(numero) {
    if ((numero.substring(0, 2) !== "55" || numero.substring(0, 3) !== "+55")) {
      return `+55${numero}`;
    }
    return numero;
  }

  const telefoneValido = (numeroTelefone) => {
    return numeroTelefone && numeroTelefone.length >= 10;
  }

  const telefoneMovel = (numeroTelefone) => {
    return numeroTelefone
      && numeroTelefone.length >= 11
      && (numeroTelefone.substring(0, 2) === "55" || numeroTelefone.substring(0, 3) === "+55")
      && numeroTelefone.substring(5, 6) === "9";
  }

  function executeSms() {
    window.open(`https://o-insurance.desk.blip.ai/`);
  }

  async function executeLigacao() {
    showLoading();
    const responseFazerLigacao = await FazerLigacao({ leadId });
    if (responseFazerLigacao.success) {
      showMessage('Sucesso', 'Realizado com Sucesso, aguarde a ligação.');
    } else {
      showMessage('Erro', responseFazerLigacao.message);
      if (responseFazerLigacao.errors) {
        responseFazerLigacao.errors.map((erro) => showMessage('Erro', erro.errorMessage));
      }
    }

    hideLoading();
  }



  return (
    <Fragment>
      {
        atalhosTelefoneValues.telefoneValido ? <Fragment>

          <div className="row compomenteAtalhosTelefoneBorda pt-1">
            <div className="col-12 col-md-12">
              <span role="button"
                disabled={disabled}
                onClick={showModal}
              >
                {children == null ? <button
                  className={`btn btn-info`}
                >
                  {texto}
                </button> : children}
              </span>
            </div>
          </div>

          <div
            className="modal fade"
            id={`modalAtalhosTelefone${id}`}
            tabIndex="-1"
            role="dialog"
            aria-labelledby={`modalAtalhosTelefone${id}Label`}
            aria-hidden="true"
          >

            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-sm">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title"
                    id={`modalAtalhosTelefone${id}Label`}
                  >
                    Ações Contato
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body componenteAtendimentoAtalhosTelefone_modalBody">
                  <div className='componenteAtendimentoAtalhosTelefone_container'>
                    {atalhosTelefoneValues.telefoneMovel ?
                      <FontAwesomeIcon className='componenteAtendimentoAtalhosTelefone_icones' title='WhatsApp' icon={faWhatsappSquare} onClick={executeSms} />
                      : null
                    }

                    <FontAwesomeIcon className='componenteAtendimentoAtalhosTelefone_icones' title='Ligação' icon={faPhone} onClick={executeLigacao} />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Fechar
                  </button>
                </div>
              </div>
            </div>

          </div>
        </Fragment> : null
      }

    </Fragment>
  );
};

export default AtalhosTelefone;
