import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "TipoAgendamento";
export const Listar = (tipoAgendamento) =>
  RequestWebApi.get(`/${controller}/Listar`, {
    params: { ...tipoAgendamento },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const tiposAgendamento = resultData;
      return { success, tiposAgendamento };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { tiposAgendamento: [] };
    });

export const Detalhes = (tipoAgendamentoId) =>
  RequestWebApi.get(`/${controller}/Detalhes`, {
    params: { tipoAgendamentoId: tipoAgendamentoId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const tipoAgendamento = resultData;
      return { success, tipoAgendamento };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, tipoAgendamento: {} };
    });

export const Incluir = (tipoAgendamento) =>
  RequestWebApi.post(`/${controller}/Incluir`, tipoAgendamento)
    .then(function (response) {
      const { resultData, success } = response.data;
      const novoProduto = resultData;
      return { success, novoProduto };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Atualizar = (tipoAgendamento) =>
  RequestWebApi.put(`/${controller}/Atualizar`, tipoAgendamento)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (tipoAgendamentoId) =>
  RequestWebApi.delete(`/${controller}/Remover`, {
    params: { tipoAgendamentoId: tipoAgendamentoId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });
