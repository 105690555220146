import React, { useContext, Fragment, useEffect } from "react";
import { PreLeadCotacaoContext } from "../PreLeadCotacaoContext";
import Pesquisar from "../../../../../Components/Cadastro/Pesquisar/Pesquisar";
import DataTable from "../../../../../Components/DataTable/DataTable";
import Visualizar from "../../../../../Components/Cadastro/Visualizar/Visualizar";

const PesquisarPreLeadCotacao = () => {
  const { values, setValues, resetScreen } = useContext(PreLeadCotacaoContext);

  useEffect(() => {
    resetScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      searchData: {
        ...values.searchData,
        [name]: value,
      },
    });
  }

  return (
    <Fragment>
      <Pesquisar exibirBotaoNovo={false} context={PreLeadCotacaoContext}>
        <div className="col-12 col-md-2">
          <label>Jornada Assistida</label>
          <input
            className="form-control"
            type="text"
            name="preLeadCotacaoJornadaAssistida"
            value={values.searchData.preLeadCotacaoJornadaAssistida}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-2">
          <label>Placa</label>
          <input
            className="form-control placa_veiculo"
            type="text"
            name="placaVeiculo"
            value={values.searchData.placaVeiculo}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-2">
          <label>Cpf</label>
          <input
            className="form-control cpf"
            type="text"
            name="cpf"
            value={values.searchData.cpf}
            onChange={onChangeInput}
          />
        </div>
      </Pesquisar>
      <DataTable
        cunstomSelectButton={(data) => {
          return (
            <Visualizar id={data.Id} />
          );
        }}
        data={values.resultSearch}
        columnsDefinition={values.columnsDefinition} />
    </Fragment>
  );
};

export default PesquisarPreLeadCotacao;
