import React, { Fragment, useContext, useState, useEffect } from 'react';
import "./FormularioLeadCorporativo.css";
import FormularioComplementar from "../../../../Components/Cadastro/FormularioComplementar/FormularioComplementar";
import DataTable from "../../../../Components/DataTable/DataTable";
import { ListarListBoxPorRotulo } from '../../../../Services/ListBox';
import { removeItemFromArray, formatarMoeda } from "../../../../Utils/globalFunctions";
function initialState() {
	return {
		dataLeadContato: {
			leadContatoNome: "",
			leadContatoCargo: "",
			leadContatoEmail: "",
			leadContatoDdd: "",
			leadContatoTelefone: "",
		},
		leadCorporativoPortes: []

	};
}
const columnsDefinitionContatos = [
	{
		text: "Id",
		dataField: "leadContatoId",
		hidden: true,
	},
	{
		text: "Nome",
		dataField: "leadContatoNome",
		headerStyle: { width: "150px" },
		sort: true,
	},
	{
		text: "Cargo",
		dataField: "leadContatoCargo",
		headerStyle: { width: "100px" },
		sort: true,
	},
	{
		text: "Email",
		dataField: "leadContatoEmail",
		headerStyle: { width: "100px" },
		sort: true,
	},
	{
		text: "DDD",
		dataField: "leadContatoDdd",
		headerStyle: { width: "40px" },
		sort: true,
	},
	{
		text: "Telefone",
		dataField: "leadContatoTelefone",
		headerStyle: { width: "60px" },
		sort: true,
	},
];
const FormularioLeadCorporativo = ({ context }) => {
	const { atendimentoValues, setAtendimentoValues, showMessage } = useContext(context);
	const [formularioLeadCorporativoValues, SetFormularioLeadCorporativoValues] = useState(initialState());
	useEffect(() => {
		async function carregarDependencias() {
			const responseListarLeadCorporativoPorte = await ListarListBoxPorRotulo('LeadCorporativoPorte');
			SetFormularioLeadCorporativoValues({
				...formularioLeadCorporativoValues,
				leadCorporativoPortes: await responseListarLeadCorporativoPorte.items
			});
		}

		carregarDependencias();
	}, []);

	function onChangeLeadCorporativo(event) {
		const { name, value } = event.target;
		setAtendimentoValues({
			...atendimentoValues,
			dadosAtendimento: {
				...atendimentoValues.dadosAtendimento,
				lead: {
					...atendimentoValues.dadosAtendimento.lead,
					leadCorporativo: {
						...atendimentoValues.dadosAtendimento.lead.leadCorporativo,
						[name]: value,
					}
				},
			},
		});
	}

	function onChangeInputContato(event) {
		const { name, value } = event.target;
		SetFormularioLeadCorporativoValues({
			...formularioLeadCorporativoValues,
			dataLeadContato: {
				...formularioLeadCorporativoValues.dataLeadContato,
				[name]: value,
			},
		});
	}


	const adicionarContato = () => {

		let dataLeadContato = formularioLeadCorporativoValues.dataLeadContato;

		let leadContatoId = dataLeadContato.leadContatoDdd + dataLeadContato.leadContatoTelefone
		let contatoJaAdiocionado = atendimentoValues.dadosAtendimento.lead.leadContatos.find(
			// eslint-disable-next-line eqeqeq
			(contato) => contato.id == leadContatoId
		);
		if (contatoJaAdiocionado) {
			showMessage("Alerta", "Contato já incluído.");
			return;
		}
		var leadContatosNew = atendimentoValues.dadosAtendimento.lead.leadContatos;
		dataLeadContato.leadContatoId = 0
		dataLeadContato.id = leadContatoId
		leadContatosNew.push(dataLeadContato);

		setAtendimentoValues({
			...atendimentoValues,
			dadosAtendimento: {
				...atendimentoValues.dadosAtendimento,
				lead: {
					...atendimentoValues.dadosAtendimento.lead,
					leadContatos: leadContatosNew
				},
			},
		});

		SetFormularioLeadCorporativoValues({
			...formularioLeadCorporativoValues,
			dataLeadContato: initialState().dataLeadContato,
		});
	};

	const removerContato = (id) => {
		var leadContatosNew = atendimentoValues.dadosAtendimento.lead.leadContatos;

		removeItemFromArray(
			leadContatosNew,
			leadContatosNew.find((contato) => contato.id === id)
		);

		setAtendimentoValues({
			...atendimentoValues,
			dadosAtendimento: {
				...atendimentoValues.dadosAtendimento,
				lead: {
					...atendimentoValues.dadosAtendimento.lead,
					leadContatos: leadContatosNew
				},
			},
		});
	};


	const valorAgenciamento = formatarMoeda(
		(!atendimentoValues.dadosAtendimento.lead.leadCorporativo.leadCorporativoVlrFaturamentoAnual ||
			!atendimentoValues.dadosAtendimento.lead.leadCorporativo.leadCorporativoPercAgenciamento ||
			atendimentoValues.dadosAtendimento.lead.leadCorporativo.leadCorporativoVlrFaturamentoAnual === 0 ||
			atendimentoValues.dadosAtendimento.lead.leadCorporativo.leadCorporativoPercAgenciamento === 0)
			? 0
			: (atendimentoValues.dadosAtendimento.lead.leadCorporativo.leadCorporativoVlrFaturamentoAnual.replace(
				".",
				""
			).replace(
				",",
				"."
			) /
				100) *
			atendimentoValues.dadosAtendimento.lead.leadCorporativo.leadCorporativoPercAgenciamento.replace(
				".",
				""
			).replace(
				",",
				"."
			)

	);
	const valorVitalicio = formatarMoeda(
		(
			!atendimentoValues.dadosAtendimento.lead.leadCorporativo.leadCorporativoVlrFaturamentoAnual ||
			!atendimentoValues.dadosAtendimento.lead.leadCorporativo.leadCorporativoPercVitalicio ||
			atendimentoValues.dadosAtendimento.lead.leadCorporativo.leadCorporativoVlrFaturamentoAnual === 0 ||
			atendimentoValues.dadosAtendimento.lead.leadCorporativo.leadCorporativoPercVitalicio === 0)
			? 0
			: (atendimentoValues.dadosAtendimento.lead.leadCorporativo.leadCorporativoVlrFaturamentoAnual.replace(
				".",
				""
			).replace(
				",",
				"."
			) /
				100) *
			atendimentoValues.dadosAtendimento.lead.leadCorporativo.leadCorporativoPercVitalicio.replace(
				".",
				""
			).replace(
				",",
				"."
			)
	);

	return (
		<Fragment>
			{atendimentoValues.dadosAtendimento.produto.produtoFormAuxLead !== 'FORMLEADCORPORATIVO' ? null : (
				<Fragment>
					<div className="col-12">
						<div className="default-title">Informações da Empresa</div>
						<div className="card shadow-sm mb-4">
							<div className="row">
								<div className="col-12 col-md-4">
									<div className="form-group">
										<label>Porte</label>
										<select
											className="form-control"
											name="leadCorporativoPorte"
											value={atendimentoValues.dadosAtendimento.lead.leadCorporativo.leadCorporativoPorte}
											onChange={onChangeLeadCorporativo}
										>
											<option className="disabled" value="" />
											{formularioLeadCorporativoValues.leadCorporativoPortes.map((leadCorporativoPorte) => (
												<option key={leadCorporativoPorte.codigo} value={leadCorporativoPorte.codigo}>
													{leadCorporativoPorte.descricao}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className="col-12 col-md-5">
									<div className="form-group">
										<label>Plano Vigente</label>
										<input
											type="text"
											className="form-control"
											name="leadCorporativoEmpresaPlanoVigente"
											value={atendimentoValues.dadosAtendimento.lead.leadCorporativo.leadCorporativoEmpresaPlanoVigente}
											onChange={onChangeLeadCorporativo}
										/>
									</div>
								</div>
								<div className="col-12 col-md-3">
									<div className="form-group">
										<label>Aniversário Contrato</label>
										<input
											type="date"
											className="form-control"
											name="leadCorporativoDataAnivContrato"
											value={atendimentoValues.dadosAtendimento.lead.leadCorporativo.leadCorporativoDataAnivContrato}
											onChange={onChangeLeadCorporativo}
										/>
									</div>
								</div>
								<div className="col-12 col-md-3">
									<div className="form-group">
										<label>Numero Funcionários</label>
										<input
											type="number"
											className="form-control"
											name="leadCorporativoNumFuncionarios"
											value={atendimentoValues.dadosAtendimento.lead.leadCorporativo.leadCorporativoNumFuncionarios}
											onChange={onChangeLeadCorporativo}
										/>
									</div>
								</div>
								<div className="col-12 col-md-4">
									<div className="form-group">
										<label>Fatura Anual</label>
										<input
											type="text"
											className="form-control monetario"
											name="leadCorporativoVlrFaturamentoAnual"
											value={atendimentoValues.dadosAtendimento.lead.leadCorporativo.leadCorporativoVlrFaturamentoAnual}
											onChange={onChangeLeadCorporativo}
											onBlur={onChangeLeadCorporativo}
											onKeyDown={onChangeLeadCorporativo}
										/>
									</div>
								</div>
								<div className="col-12 col-md-12" />
								<div className="col-12 col-md-3">
									<div className="form-group">
										<label>Perc. Agenciamento</label>
										<input
											type="text"
											className="form-control porcentagem3Digitos"
											name="leadCorporativoPercAgenciamento"
											value={atendimentoValues.dadosAtendimento.lead.leadCorporativo.leadCorporativoPercAgenciamento}
											onChange={onChangeLeadCorporativo}
										/>
									</div>
								</div>
								<div className="col-12 col-md-3">
									<div className="form-group">
										<label>Valor Agenciamento</label>
										<input
											disabled={true}
											type="text"
											className="form-control leadManual-valoresCalculados"
											name="valorComissao"
											value={valorAgenciamento}
										/>
									</div>
								</div>
								<div className="col-12 col-md-3">
									<div className="form-group">
										<label>Perc. Vitalício</label>
										<input
											type="text"
											className="form-control porcentagem3Digitos"
											name="leadCorporativoPercVitalicio"
											value={atendimentoValues.dadosAtendimento.lead.leadCorporativo.leadCorporativoPercVitalicio}
											onChange={onChangeLeadCorporativo}
										/>
									</div>
								</div>
								<div className="col-12 col-md-3">
									<div className="form-group">
										<label>Valor Vitalício</label>
										<input
											disabled={true}
											type="text"
											className="form-control leadManual-valoresCalculados"
											name="valorComissao"
											value={valorVitalicio}
										/>
									</div>
								</div>
								<FormularioComplementar titulo="Contatos">
									<div className="col-12 col-md-5">
										<div className="form-group">
											<label>Nome</label>
											<input
												type="text"
												className="form-control"
												name="leadContatoNome"
												value={formularioLeadCorporativoValues.dataLeadContato.leadContatoNome}
												onChange={onChangeInputContato}
											/>
										</div>
									</div>
									<div className="col-12 col-md-3">
										<div className="form-group">
											<label>Cargo</label>
											<input
												type="text"
												className="form-control"
												name="leadContatoCargo"
												value={formularioLeadCorporativoValues.dataLeadContato.leadContatoCargo}
												onChange={onChangeInputContato}
											/>
										</div>
									</div>
									<div className="col-12 col-md-4">
										<div className="form-group">
											<label>Email</label>
											<input
												type="text"
												className="form-control"
												name="leadContatoEmail"
												value={formularioLeadCorporativoValues.dataLeadContato.leadContatoEmail}
												onChange={onChangeInputContato}
											/>
										</div>
									</div>
									<div className="col-12 col-md-2">
										<div className="form-group">
											<label>DDD</label>
											<input
												type="text"
												maxLength="2"
												className="form-control numerico"
												name="leadContatoDdd"
												value={formularioLeadCorporativoValues.dataLeadContato.leadContatoDdd}
												onChange={onChangeInputContato}
											/>
										</div>
									</div>
									<div className="col-12 col-md-3">
										<div className="form-group">
											<label>Telefone</label>
											<input
												type="text"
												maxLength="9"
												className="form-control numerico"
												name="leadContatoTelefone"
												value={formularioLeadCorporativoValues.dataLeadContato.leadContatoTelefone}
												onChange={onChangeInputContato}
											/>
										</div>
									</div>
									<div className="col-12 col-md-2 form-group">
										<button
											type="button"
											disabled={
												!formularioLeadCorporativoValues.dataLeadContato.leadContatoNome
												|| !formularioLeadCorporativoValues.dataLeadContato.leadContatoCargo
												|| !formularioLeadCorporativoValues.dataLeadContato.leadContatoEmail
												|| !formularioLeadCorporativoValues.dataLeadContato.leadContatoDdd
												|| !formularioLeadCorporativoValues.dataLeadContato.leadContatoTelefone
											}
											className="btn btn-default buttonFormularios"
											onClick={adicionarContato}
										>
											Adicionar
										</button>
									</div>
									<div className="col-12 col-md-12 form-group">
										<DataTable
											data={atendimentoValues.dadosAtendimento.lead.leadContatos}
											columnsDefinition={columnsDefinitionContatos}
											removeHandler={removerContato}
										/>
									</div>
								</FormularioComplementar>

							</div>
						</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default FormularioLeadCorporativo;
