import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRoute from "../../../../Routes/PrivateRoutes";
import TarefasExecutarProvider from "./TarefasExecutarContext";
import PesquisarTarefasExecutar from "./Events/PesquisarTarefasExecutar";
import FormularioTarefasExecutar from "./Events/FormularioTarefasExecutar";

const Index = () => {
  let match = useRouteMatch();
  return (
    <TarefasExecutarProvider>
      <div className="container-fluid pl-3 pt-3 pb-3">
        <Switch>
          <PrivateRoute
            exact
            path={`${match.url}`}
            component={PesquisarTarefasExecutar}
          />
          <PrivateRoute
            path={`${match.url}/:id`}
            component={FormularioTarefasExecutar}
          />
        </Switch>
      </div>
    </TarefasExecutarProvider>
  );
};

export default Index;
