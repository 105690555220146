import React, { Fragment, useState, useContext } from "react";
import AppContext from "../../../Store/AppContext";
import { formatarDataParaInput } from "../../../Utils/globalFunctions";
import { AlterarAgendamento } from "../../../Services/Agendamento";

const defaultData = {
  agendamentoId: "0",
  agendamentoDataHoraRetorno: "",
  tipoAgendamentoId: "0",
};

function initialState() {
  return {
    altararAgendamento: false,
    dataAgendamento: "",
    horaAgendamento: "",
    data: defaultData,
  };
}
const AlteraAgendamento = ({
  tiposAgendamento = [],
  refreshEventHandler = () => {},
  agendamentoId = 0,
  tipoAgendamentoId = 0,
  dataAgendamento = "",
  horaAgendamento = "",
  diaSemanaAgendamento = "",
  dataToggle,
  dataTarget,
  dataDismiss,
}) => {
  const { showLoading, hideLoading, showMessage } = useContext(AppContext);
  const [alteraAgendamentoValues, setAlteraAgendamentoValues] = useState(
    initialState()
  );

  const alterarAgendamentoHandler = () => {
    setAlteraAgendamentoValues({
      ...alteraAgendamentoValues,
      altararAgendamento: !alteraAgendamentoValues.altararAgendamento,
      dataAgendamento: formatarDataParaInput(dataAgendamento),
      horaAgendamento: horaAgendamento,
      tipoAgendamentoId: tipoAgendamentoId,
    });
  };

  const salvarAgendamentoHandler = async () => {
    showLoading("Alterando Agenda");

    const tipoAgendamentoId = alteraAgendamentoValues.tipoAgendamentoId;
    const agendamentoDataHoraRetorno = `${alteraAgendamentoValues.dataAgendamento} ${alteraAgendamentoValues.horaAgendamento}`;

    const responseAtualizar = await AlterarAgendamento(
      agendamentoId,
      agendamentoDataHoraRetorno,
      tipoAgendamentoId
    );
    if (!responseAtualizar.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao alterar o agendamento: ${responseAtualizar.message}`
      );
      return;
    }
    hideLoading();
    showMessage("Sucesso", "Agendamento alterado.");

    setAlteraAgendamentoValues({
      ...alteraAgendamentoValues,
      altararAgendamento: !alteraAgendamentoValues.altararAgendamento,
    });

    refreshEventHandler();
  };
  function onChangeInput(event) {
    const { name, value } = event.target;
    setAlteraAgendamentoValues({
      ...alteraAgendamentoValues,
      [name]: value,
    });
  }
  return (
    <Fragment>
      {alteraAgendamentoValues.altararAgendamento ? (
        <div className="col-12">
          <div className="row">
            <div className="col-4 col-md-4  text-right ">
              <select
                className="form-control"
                name="tipoAgendamentoId"
                value={alteraAgendamentoValues.tipoAgendamentoId}
                onChange={onChangeInput}
              >
                <option className="disabled" value="0"></option>
                {tiposAgendamento.map((tipoAgendamento) => (
                  <option
                    key={tipoAgendamento.tipoAgendamentoId}
                    value={tipoAgendamento.tipoAgendamentoId}
                  >
                    {tipoAgendamento.tipoAgendamentoDescricao}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-3 col-md-3 text-right">
              <input
                type="date"
                className="form-control"
                name="dataAgendamento"
                value={alteraAgendamentoValues.dataAgendamento}
                onChange={onChangeInput}
              />
            </div>
            <div className="col-2 col-md-2 text-right">
              <input
                type="time"
                className="form-control"
                name="horaAgendamento"
                value={alteraAgendamentoValues.horaAgendamento}
                onChange={onChangeInput}
              />
            </div>
            <div className="col-2 col-md-2 text-right">
              <button
                disabled={
                  alteraAgendamentoValues.tipoAgendamentoId === "0" ||
                  alteraAgendamentoValues.dataAgendamento === "" ||
                  alteraAgendamentoValues.horaAgendamento === ""
                }
                type="button"
                className="btn btn-default ml-2"
                data-toggle={dataToggle}
                data-target={dataTarget}
                data-dismiss={dataDismiss}
                onClick={salvarAgendamentoHandler}
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-12">
          {`${dataAgendamento} - ${horaAgendamento} (${diaSemanaAgendamento})`}
          <button
            type="button"
            className="btn btn-primary ml-2"
            onClick={alterarAgendamentoHandler}
          >
            Alterar
          </button>
        </div>
      )}
    </Fragment>
  );
};

export default AlteraAgendamento;
