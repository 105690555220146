import React, { Fragment, useContext, useState, useEffect } from 'react';
import "./FormularioLeadRisk.css";
import FormularioComplementar from "../../../Cadastro/FormularioComplementar/FormularioComplementar";
import DataTable from "../../../DataTable/DataTable";
import { ListarListBoxPorRotulo } from '../../../../Services/ListBox';
import { removeItemFromArray, formatarMoeda } from "../../../../Utils/globalFunctions";
function initialState() {
	return {
		dataLeadContato: {
			leadContatoNome: "",
			leadContatoCargo: "",
			leadContatoEmail: "",
			leadContatoDdd: "",
			leadContatoTelefone: "",
		},
		leadRiskPortes: []

	};
}
const columnsDefinitionContatos = [
	{
		text: "Id",
		dataField: "leadContatoId",
		hidden: true,
	},
	{
		text: "Nome",
		dataField: "leadContatoNome",
		headerStyle: { width: "150px" },
		sort: true,
	},
	{
		text: "Cargo",
		dataField: "leadContatoCargo",
		headerStyle: { width: "100px" },
		sort: true,
	},
	{
		text: "Email",
		dataField: "leadContatoEmail",
		headerStyle: { width: "100px" },
		sort: true,
	},
	{
		text: "DDD",
		dataField: "leadContatoDdd",
		headerStyle: { width: "40px" },
		sort: true,
	},
	{
		text: "Telefone",
		dataField: "leadContatoTelefone",
		headerStyle: { width: "60px" },
		sort: true,
	},
];
const FormularioLeadRisk = ({ context }) => {
	const { atendimentoValues, setAtendimentoValues, showMessage } = useContext(context);
	const [formularioLeadRiskValues, SetFormularioLeadRiskValues] = useState(initialState());
	useEffect(() => {
		async function carregarDependencias() {
			const responseListarLeadRiskPorte = await ListarListBoxPorRotulo('LeadRiskPorte');
			SetFormularioLeadRiskValues({
				...formularioLeadRiskValues,
				leadRiskPortes: await responseListarLeadRiskPorte.items
			});
		}

		carregarDependencias();
	}, []);

	function onChangeLeadRisk(event) {
		const { name, value } = event.target;
		setAtendimentoValues({
			...atendimentoValues,
			dadosAtendimento: {
				...atendimentoValues.dadosAtendimento,
				lead: {
					...atendimentoValues.dadosAtendimento.lead,
					leadRisk: {
						...atendimentoValues.dadosAtendimento.lead.leadRisk,
						[name]: value,
					}
				},
			},
		});
	}

	function onChangeInputContato(event) {
		const { name, value } = event.target;
		SetFormularioLeadRiskValues({
			...formularioLeadRiskValues,
			dataLeadContato: {
				...formularioLeadRiskValues.dataLeadContato,
				[name]: value,
			},
		});
	}


	const adicionarContato = () => {

		let dataLeadContato = formularioLeadRiskValues.dataLeadContato;

		let leadContatoId = dataLeadContato.leadContatoDdd + dataLeadContato.leadContatoTelefone
		let contatoJaAdiocionado = atendimentoValues.dadosAtendimento.lead.leadContatos.find(
			// eslint-disable-next-line eqeqeq
			(contato) => contato.id == leadContatoId
		);
		if (contatoJaAdiocionado) {
			showMessage("Alerta", "Contato já incluído.");
			return;
		}
		var leadContatosNew = atendimentoValues.dadosAtendimento.lead.leadContatos;
		dataLeadContato.leadContatoId = 0
		dataLeadContato.id = leadContatoId
		leadContatosNew.push(dataLeadContato);

		setAtendimentoValues({
			...atendimentoValues,
			dadosAtendimento: {
				...atendimentoValues.dadosAtendimento,
				lead: {
					...atendimentoValues.dadosAtendimento.lead,
					leadContatos: leadContatosNew
				},
			},
		});

		SetFormularioLeadRiskValues({
			...formularioLeadRiskValues,
			dataLeadContato: initialState().dataLeadContato,
		});
	};

	const removerContato = (id) => {
		var leadContatosNew = atendimentoValues.dadosAtendimento.lead.leadContatos;

		removeItemFromArray(
			leadContatosNew,
			leadContatosNew.find((contato) => contato.id === id)
		);

		setAtendimentoValues({
			...atendimentoValues,
			dadosAtendimento: {
				...atendimentoValues.dadosAtendimento,
				lead: {
					...atendimentoValues.dadosAtendimento.lead,
					leadContatos: leadContatosNew
				},
			},
		});
	};


	return (
		<Fragment>
			{atendimentoValues.dadosAtendimento.produto.produtoFormAuxLead !== 'FORMLEADRISK' ? null : (
				<Fragment>
					<div className="col-12">
						<div className="default-title">Informações da Empresa</div>
						<div className="card shadow-sm mb-4">
							<div className="row">
								<div className="col-12 col-md-4">
									<div className="form-group">
										<label>Porte</label>
										<select
											className="form-control"
											name="leadRiskPorte"
											value={atendimentoValues.dadosAtendimento.lead.leadRisk.leadRiskPorte}
											onChange={onChangeLeadRisk}
										>
											<option className="disabled" value="" />
											{formularioLeadRiskValues.leadRiskPortes.map((leadRiskPorte) => (
												<option key={leadRiskPorte.codigo} value={leadRiskPorte.codigo}>
													{leadRiskPorte.descricao}
												</option>
											))}
										</select>
									</div>
								</div>

								<div className="col-12 col-md-3">
									<div className="form-group">
										<label>Numero Funcionários</label>
										<input
											type="number"
											className="form-control"
											name="leadRiskNumFuncionarios"
											value={atendimentoValues.dadosAtendimento.lead.leadRisk.leadRiskNumFuncionarios}
											onChange={onChangeLeadRisk}
										/>
									</div>
								</div>

								<FormularioComplementar titulo="Contatos">
									<div className="col-12 col-md-5">
										<div className="form-group">
											<label>Nome</label>
											<input
												type="text"
												className="form-control"
												name="leadContatoNome"
												value={formularioLeadRiskValues.dataLeadContato.leadContatoNome}
												onChange={onChangeInputContato}
											/>
										</div>
									</div>
									<div className="col-12 col-md-3">
										<div className="form-group">
											<label>Cargo</label>
											<input
												type="text"
												className="form-control"
												name="leadContatoCargo"
												value={formularioLeadRiskValues.dataLeadContato.leadContatoCargo}
												onChange={onChangeInputContato}
											/>
										</div>
									</div>
									<div className="col-12 col-md-4">
										<div className="form-group">
											<label>Email</label>
											<input
												type="text"
												className="form-control"
												name="leadContatoEmail"
												value={formularioLeadRiskValues.dataLeadContato.leadContatoEmail}
												onChange={onChangeInputContato}
											/>
										</div>
									</div>
									<div className="col-12 col-md-2">
										<div className="form-group">
											<label>DDD</label>
											<input
												type="text"
												maxLength="2"
												className="form-control numerico"
												name="leadContatoDdd"
												value={formularioLeadRiskValues.dataLeadContato.leadContatoDdd}
												onChange={onChangeInputContato}
											/>
										</div>
									</div>
									<div className="col-12 col-md-3">
										<div className="form-group">
											<label>Telefone</label>
											<input
												type="text"
												maxLength="9"
												className="form-control numerico"
												name="leadContatoTelefone"
												value={formularioLeadRiskValues.dataLeadContato.leadContatoTelefone}
												onChange={onChangeInputContato}
											/>
										</div>
									</div>
									<div className="col-12 col-md-2 form-group">
										<button
											type="button"
											disabled={
												!formularioLeadRiskValues.dataLeadContato.leadContatoNome
												|| !formularioLeadRiskValues.dataLeadContato.leadContatoCargo
												|| !formularioLeadRiskValues.dataLeadContato.leadContatoEmail
												|| !formularioLeadRiskValues.dataLeadContato.leadContatoDdd
												|| !formularioLeadRiskValues.dataLeadContato.leadContatoTelefone
											}
											className="btn btn-default buttonFormularios"
											onClick={adicionarContato}
										>
											Adicionar
										</button>
									</div>
									<div className="col-12 col-md-12 form-group">
										<DataTable
											data={atendimentoValues.dadosAtendimento.lead.leadContatos}
											columnsDefinition={columnsDefinitionContatos}
											removeHandler={removerContato}
										/>
									</div>
								</FormularioComplementar>

							</div>
						</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default FormularioLeadRisk;
