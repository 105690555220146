import React, { Fragment, useContext } from "react";
import "./TabelaLeads.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { removeItemFromArray } from "../../../Utils/globalFunctions";

const paginationOptions = {
  pageStartIndex: 0,
  paginationSize: 5,
  showTotal: true,
  withFirstAndLast: true,
  alwaysShowAllBtns: true,
  firstPageText: "Primeira",
  prePageText: "Anterior",
  nextPageText: "Proximo",
  lastPageText: "Ultima",
  nextPageTitle: "Proximo",
  prePageTitle: "Anterior",
  firstPageTitle: "Primeira",
  lastPageTitle: "Ultima",
  hideSizePerPage: true,
  hidePageListOnlyOnePage: false,
  paginationTotalRenderer: (from, to, size) => (
    <span>
      Mostrando de {from} até {to} de {size} registros
    </span>
  ),
};


export const TabelaLeads = ({ context }) => {
  const { distribuirLeadsValues, setDistribuirLeadsValues } = useContext(
    context
  );

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setDistribuirLeadsValues({
        ...distribuirLeadsValues,
        data: {
          ...distribuirLeadsValues.data,
          leads: [...distribuirLeadsValues.data.leads, row.leadId],
        },
      });
    } else {
      setDistribuirLeadsValues({
        ...distribuirLeadsValues,
        data: {
          ...distribuirLeadsValues.data,
          leads: distribuirLeadsValues.data.leads.filter(
            (x) => x !== row.leadId
          ),
        },
      });
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    const leads = rows.map((row) => row.leadId);
    if (isSelect) {
      setDistribuirLeadsValues({
        ...distribuirLeadsValues,
        data: {
          ...distribuirLeadsValues.data,
          leads: [...distribuirLeadsValues.data.leads, ...leads],
        },
      });
    } else {
      let leadsNew = distribuirLeadsValues.data.leads;

      leads.map((leadRemover) =>
        removeItemFromArray(
          leadsNew,
          leadsNew.find((lead) => lead === leadRemover)
        )
      );

      setDistribuirLeadsValues({
        ...distribuirLeadsValues,
        data: {
          ...distribuirLeadsValues.data,
          leads: leadsNew,
        },
      });
    }
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    selected: distribuirLeadsValues.data.leads,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
  };

  return (
    <Fragment>
      <div className="col-9">
        <div className="default-title">Leads</div>
        <div className="card shadow-sm mb-4">
          <div className="row">
            <div className="col-12">
              <BootstrapTable
                bootstrap4={true}
                condensed={true}
                noDataIndication={() => "Nenhum Registro Selecionado"}
                striped={true}
                hover={true}
                pagination={paginationFactory(paginationOptions)}
                keyField="leadId"
                data={distribuirLeadsValues.resultSearch}
                columns={distribuirLeadsValues.columnsDefinition}
                selectRow={selectRow}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TabelaLeads;
