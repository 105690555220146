import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "Feriado";
export const Listar = (feriado) =>
  RequestWebApi.get(`/${controller}/Listar`, {
    params: { ...feriado },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const feriados = resultData;
      return { success, feriados };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { feriados: [] };
    });

export const Detalhes = (feriadoId) =>
  RequestWebApi.get(`/${controller}/Detalhes`, {
    params: { feriadoId: feriadoId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const feriado = resultData;
      return { success, feriado };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, feriado: {} };
    });

export const Incluir = (feriado) =>
  RequestWebApi.post(`/${controller}/Incluir`, feriado)
    .then(function (response) {
      const { resultData, success } = response.data;
      const novoFeriado = resultData;
      return { success, novoFeriado };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Atualizar = (feriado) =>
  RequestWebApi.put(`/${controller}/Atualizar`, feriado)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (feriadoId) =>
  RequestWebApi.delete(`/${controller}/Remover`, {
    params: { feriadoId: feriadoId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });
