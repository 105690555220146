/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext } from 'react';
import AppContext from '../../../../Store/AppContext';
import { Listar as ListarSeguradora } from '../../../../Services/Seguradora';
import { ListarSimples as ListarUsuario } from '../../../../Services/Usuario';
import { Listar as ListarTipoContato } from '../../../../Services/TipoContato';
import { NegociosFechados } from '../../../../Services/Relatorio';
import { validarPermissoesRelatorio } from '../../../../Utils/validarPermissoesPagina';
export const NegociosFechadosContext = createContext();

const tituloTela = 'Negócio Fechado';

const searchData = {
	usuarioId: 0,
	mesAno: '',
	tipoContatoId: 0,
	seguradoraId: 0,
	dataRecebimentoDe: '',
	dataRecebimentoAte: '',
	dataFechamentoDe: '',
	dataFechamentoAte: ''
};

function initialState() {
	return {
		searchData: searchData,
		seguradoras: [],
		usuarios: [],
		tiposContato: []
	};
}

const NegociosFechadosProvider = ({ children }) => {
	const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(AppContext);
	const [values, setValues] = useState(initialState());

	async function loadDependences() {
		let seguradoras = [];
		let usuarios = [];
		let tiposContato = [];

		const [
			validarPermissoesRelatorioAux,
			responseListarSeguradora,
			responseListarUsuario,
			responseListarTipoContato
		] = await Promise.all([
			validarPermissoesRelatorio(),
			ListarSeguradora({}),
			ListarUsuario({}),
			ListarTipoContato({})
		]);

		if (!responseListarSeguradora.success || !responseListarUsuario.success || !responseListarTipoContato.success) {
			showMessage('Erro', 'Erro Inesperado ao carregar dados basicos.');
		} else {
			seguradoras = await responseListarSeguradora.seguradoras.filter(
				(seguradora) => seguradora.seguradoraStatus === 'A'
			);

			usuarios = await responseListarUsuario.usuarios;
			tiposContato = await responseListarTipoContato.tiposContato;
		}

		return {
			permissoesRelatorio: validarPermissoesRelatorioAux,
			seguradoras,
			usuarios,
			tiposContato
		};
	}

	const resetScreen = () => {
		showLoading();
		async function carregarDependencias() {
			const dependencias = await loadDependences();
			setTituloTela(tituloTela);
			setValues({
				...initialState(),
				permissoesRelatorio: await dependencias.permissoesRelatorio,
				seguradoras: await dependencias.seguradoras,
				usuarios: await dependencias.usuarios,
				tiposContato: await dependencias.tiposContato
			});
			hideLoading();
		}
		carregarDependencias();
	};

	const searchHandler = async () => {
		showLoading();
		const responseListarNegociosFechados = await NegociosFechados(values.searchData);

		if (!responseListarNegociosFechados.success) {
			showMessage('Erro', 'Erro Inesperado durante a pesquisa.');
			hideLoading();
			return;
		}

		hideLoading();
	};

	return (
		<NegociosFechadosContext.Provider
			value={{
				values,
				setValues,
				resetScreen,
				searchHandler
			}}
		>
			{children}
		</NegociosFechadosContext.Provider>
	);
};

export default NegociosFechadosProvider;
