import React, { useContext, Fragment, useEffect } from "react";
import { MotorEventosContext } from "../MotorEventosContext";
import "./PesquisarMotorEventos.css";
import Pesquisar from "../../../../Components/Cadastro/Pesquisar/Pesquisar";
import ModalTextReadOnly from "../../../../Components/Modal/ModalTextReadOnly/ModalTextReadOnly";
import DataTableDefaultSelection from "../../../../Components/DataTableDefaultSelection/DataTableDefaultSelection";
import FormularioComplementar from "../../../../Components/Cadastro/FormularioComplementar/FormularioComplementar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileUpload,
  faFileDownload,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

const columnsDefinition = [
  {
    text: "Id",
    dataField: "motorEventoApiId",
    hidden: true,
  },
  {
    text: "Direção",
    dataField: "motorEventoApiDirecaoDescricao",
    headerStyle: { width: "30px" },
    sort: true,
    formatter: (cell, row) => {
      let cssClass = "info";
      switch (row.motorEventoApiDirecao) {
        case "S":
          cssClass = "primary";
          break;
        case "E":
          cssClass = "secondary";
          break;
        default:
          break;
      }
      return <span className={`badge badge-${cssClass}`}>{cell}</span>;
    },
  },
  {
    text: "Rotulo",
    dataField: "motorEventoApiRotulo",
    headerStyle: { width: "60px" },
    sort: true,
  },
  {
    text: "Código",
    dataField: "motorEventoApiCodigo",
    headerStyle: { width: "50px" },
    sort: true,
  },
  {
    text: "Data Criação",
    dataField: "motorEventoApiDataHora",
    headerStyle: { width: "40px" },
    sort: true,
  },
  {
    text: "Status",
    dataField: "motorEventoApiStatusDescricao",
    headerStyle: { width: "30px" },
    sort: true,
    formatter: (cell, row) => {
      let cssClass = "info";
      switch (row.motorEventoApiStatus) {
        case 1:
          cssClass = "warning";
          break;
        case 2:
          cssClass = "info";
          break;
        case 3:
          cssClass = "success";
          break;
        case 4:
          cssClass = "danger";
          break;
        default:
          break;
      }
      return <span className={`badge badge-${cssClass}`}>{cell}</span>;
    },
  },
  {
    text: "Data Proc.",
    dataField: "motorEventoApiDataHoraProcessamento",
    headerStyle: { width: "40px" },
    sort: true,
  },
  {
    text: "Request",
    dataField: "motorEventoApiRequest",
    headerStyle: { width: "25px" },
    formatter: (cell, row) => {
      return (!cell || cell.trim() === "") ? null : (
        <div className="pesquisarMotorEventos_containerIcone" role="button">
          <ModalTextReadOnly
            id={`request${row.motorEventoApiId}`}
            titulo="Request"
            value={cell}
          >
            <FontAwesomeIcon
              icon={faFileUpload}
              className="pesquisarMotorEventos_iconesTabela pesquisarMotorEventos_iconesTabelarequest"
            />
          </ModalTextReadOnly>
        </div>
      );
    },
  },
  {
    text: "Response",
    dataField: "motorEventoApiResponse",
    headerStyle: { width: "25px" },
    formatter: (cell, row) => {
      return (!cell || cell.trim() === "") ? null : (
        <div className="pesquisarMotorEventos_containerIcone" role="button">
          <ModalTextReadOnly
            id={`response${row.motorEventoApiId}`}
            titulo="Response"
            value={cell}
          >
            <FontAwesomeIcon
              icon={faFileDownload}
              className="pesquisarMotorEventos_iconesTabela pesquisarMotorEventos_iconesTabelaresponse"
            />
          </ModalTextReadOnly>
        </div>
      );
    },
  },
  {
    text: "Mensagem",
    dataField: "motorEventoApiErro",
    headerStyle: { width: "25px" },
    formatter: (cell, row) => {
      return (!cell || cell.trim() === "") ? null : (
        <div className="pesquisarMotorEventos_containerIcone" role="button">
          <ModalTextReadOnly
            id={`mensagem${row.motorEventoApiId}`}
            titulo="Mensagem"
            value={cell}
          >
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="pesquisarMotorEventos_iconesTabela pesquisarMotorEventos_iconesTabelaerro"
            />
          </ModalTextReadOnly>
        </div>
      );
    },
  },
];

const PesquisarMotorEventos = () => {
  const { values, setValues, resetScreen, resetEventHandler } = useContext(
    MotorEventosContext
  );

  useEffect(() => {
    resetScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedItemHandler = (selectedItens) => {
    setValues({
      ...values,
      data: { ...values.data, eventosId: selectedItens },
    });
  };

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      searchData: {
        ...values.searchData,
        [name]: value,
      },
    });
  }

  return (
    <Fragment>
      <Pesquisar exibirBotaoNovo={false} context={MotorEventosContext}>
        <div className="col-12 col-md-4 form-group">
          <label>Rotulo</label>
          <select
            className="form-control"
            name="motorEventoApiRotulo"
            value={values.searchData.motorEventoApiRotulo}
            onChange={onChangeInput}
          >
            <option className="disabled" value=""></option>
            {values.rotulos.map((rotulo) => (
              <option
                key={rotulo}
                value={rotulo}
              >
                {rotulo}
              </option>
            ))}
          </select>
        </div>

        <div className="col-12 col-md-4 form-group">
          <label>Código</label>
          <input
            className="form-control"
            type="text"
            name="motorEventoApiCodigo"
            value={values.searchData.motorEventoApiCodigo}
            onChange={onChangeInput}
          />
        </div>

        <div className="col-12 col-md-2 form-group">
          <label>Direção</label>
          <select
            className="form-control"
            name="motorEventoApiDirecao"
            value={values.searchData.motorEventoApiDirecao}
            onChange={onChangeInput}
          >
            <option className="disabled" value=""></option>
            <option value="S">Saída</option>
            <option value="E">Entrada</option>
          </select>
        </div>

        <div className="col-12 col-md-2 form-group">
          <label>Status</label>
          <select
            className="form-control"
            name="motorEventoApiStatus"
            value={values.searchData.motorEventoApiStatus}
            onChange={onChangeInput}
          >
            <option className="disabled" value="0"></option>
            <option value="1">Pendente</option>
            <option value="2">Processando</option>
            <option value="3">Sucesso</option>
            <option value="4">Erro</option>
          </select>
        </div>

        <div className="col-12 col-md-2 form-group">
          <label>Inicio:</label>
          <input
            className="form-control"
            type="date"
            name="motorEventoApiDataHoraInicio"
            value={values.searchData.motorEventoApiDataHoraInicio}
            onChange={onChangeInput}
          />
        </div>

        <div className="col-12 col-md-2 form-group">
          <label>Fim:</label>
          <input
            className="form-control"
            type="date"
            name="motorEventoApiDataHoraFim"
            value={values.searchData.motorEventoApiDataHoraFim}
            onChange={onChangeInput}
          />
        </div>
      </Pesquisar>
      <FormularioComplementar titulo="Eventos">
        <div className="col-12 text-right" style={{ marginBottom: 20 }}>
          <button
            disabled={values.data.eventosId.length === 0}
            type="button"
            className={`btn ${values.data.eventosId.length === 0
              ? "btn-secondary"
              : "btn-primary"
              } `}
            onClick={() => resetEventHandler()}
          >
            {values.data.eventosId.length === 0
              ? "Nenhum Evento Selecionado"
              : `Reprocessar ${values.data.eventosId.length} Evento${values.data.eventosId.length > 1 ? "s" : ""
              } Selecionado${values.data.eventosId.length > 1 ? "s" : ""}`}
          </button>
        </div>
        <DataTableDefaultSelection
          keyField="motorEventoApiId"
          data={values.resultSearch}
          selectedItens={values.data.eventosId}
          columns={columnsDefinition}
          selectedItemHandler={selectedItemHandler}
        />
      </FormularioComplementar>
    </Fragment>
  );
};

export default PesquisarMotorEventos;
