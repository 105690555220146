import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRoute from "../../../../Routes/PrivateRoutes";
import OrigemLeadProvider from "./OrigemLeadContext";
import PesquisarOrigemLead from "./Events/PesquisarOrigemLead";
import FormularioOrigemLead from "./Events/FormularioOrigemLead";

const Index = () => {
  let match = useRouteMatch();
  return (
    <OrigemLeadProvider>
      <div className="container-fluid pl-3 pt-3 pb-3">
        <Switch>
          <PrivateRoute
            exact
            path={`${match.url}`}
            component={PesquisarOrigemLead}
          />
          <PrivateRoute
            path={`${match.url}/:id`}
            component={FormularioOrigemLead}
          />
        </Switch>
      </div>
    </OrigemLeadProvider>
  );
};

export default Index;
