import React, { useContext, Fragment } from "react";
import "./Pesquisar.css";
import filtro from "../../../../../assets/img/icones/pages/tools-and-utensils.png";
const Pesquisar = ({ context }) => {
  const { agendaValues, setAgendaValues, searchHandler } = useContext(context);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setAgendaValues({
      ...agendaValues,
      searchData: {
        ...agendaValues.searchData,
        [name]: value,
      },
    });
  }
  return (
    <Fragment>
      <button
        type="button"
        className="btn btn-success"
        data-toggle="modal"
        data-target="#modalPesquisarAgenda"
        data-dismiss="modal"
      >
        <img src={filtro} alt="Pesquisar" />
      </button>
      <div
        className="modal fade"
        id="modalPesquisarAgenda"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalPesquisarAgendaLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalPesquisarAgendaLabel">
                Pesquisar Agenda
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body componenteAgendaPesquisar_modalBody">
              <div className="row">
                <div className="col-12 col-md-3">
                  <div className="form-group">
                    <label>Periodo de:</label>
                    <input
                      type="date"
                      className="form-control"
                      name="dataAgendamentoDe"
                      value={agendaValues.searchData.dataAgendamentoDe}
                      onChange={onChangeInput}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className="form-group">
                    <label> Até</label>
                    <input
                      type="date"
                      className="form-control"
                      name="dataAgendamentoAte"
                      value={agendaValues.searchData.dataAgendamentoAte}
                      onChange={onChangeInput}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className="form-group">
                    <label>
                      Produto<span className="campoObratorio">*</span>
                    </label>
                    <select
                      className="form-control"
                      name="produtoId"
                      value={agendaValues.searchData.produtoId}
                      onChange={onChangeInput}
                    >
                      <option className="disabled" value="0"></option>
                      {agendaValues.produtos.map((produto) => (
                        <option key={produto.value} value={produto.value}>
                          {produto.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className="form-group">
                    <label>Equipe</label>
                    <select
                      className="form-control"
                      name="equipeId"
                      value={agendaValues.searchData.equipeId}
                      onChange={onChangeInput}
                    >
                      <option className="disabled" value="0"></option>
                      {agendaValues.equipes
                        .filter(
                          (eq) =>
                            // eslint-disable-next-line eqeqeq
                            eq.produtoId == agendaValues.searchData.produtoId
                        )
                        .map((equipe) => (
                          <option key={equipe.value} value={equipe.value}>
                            {equipe.label}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group">
                    <label>
                      Consultor<span className="campoObratorio">*</span>
                    </label>
                    <select
                      className="form-control"
                      name="usuarioId"
                      value={agendaValues.searchData.usuarioId}
                      onChange={onChangeInput}
                    >
                      <option className="disabled" value="0"></option>
                      {agendaValues.consultores.map((consultor) => (
                        <option key={consultor.value} value={consultor.value}>
                          {consultor.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Fechar
              </button>
              <button
                disabled={
                  agendaValues.searchData.produtoId === "0" ||
                  agendaValues.searchData.usuarioId === "0"
                }
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={searchHandler}
              >
                Pesquisar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Pesquisar;
