/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Listar as ListarCidades } from '../../../../Services/Cidade';
import { Listar as ListarFornecedoresLead } from '../../../../Services/FornecedorLead';
import { Listar as ListarLandingPages } from '../../../../Services/LandingPage';
import { IncluirLead } from '../../../../Services/Lead';
import { ListarListBoxPorRotulo } from '../../../../Services/ListBox';
import { ListarSimples as ListarOrigensLead } from '../../../../Services/OrigemLead';
import { Listar as ListarProduto } from '../../../../Services/Produto';
import { Listar as ListarTipoVeiculo } from '../../../../Services/TipoVeiculo';
import { Listar as ListarSeguradora } from '../../../../Services/Seguradora';
import { ListarSimples as ListarUsuario } from '../../../../Services/Usuario';
import AppContext from '../../../../Store/AppContext';
import { retornarUnicos } from '../../../../Utils/globalFunctions';
import { validarPermissoesPagina } from '../../../../Utils/validarPermissoesPagina';
import { removeItemFromArray } from "../../../../Utils/globalFunctions";

const tituloTela = 'Cadastro Manual de Lead';
export const LeadManualContext = createContext();
const defaultData = {
	formularioAdicional: '',
	nomeCompleto: '',
	leadNumDocumento: '',
	dddTelefone: '',
	telefone: '',
	email: '',
	cidade: '',
	vencimentoApolice: '',
	tipo: '',
	classificacao: '',
	origem: '0',
	produtoId: '0',
	fornecedor: '0',
	landingPage: '0',
	dataInteracao: '',
	chance: '0',
	scoring: '0',
	uf: '',
	leadConsultorId: '0',
	tipoVeiculoId: '0',
	leadTipoNegocio: '',
	leadCategoriaProduto: '',
	seguradoraId: 0,
	leadAuto: {
		leadAutoPlaca: '',
		leadAutoFabricante: '',
		leadAutoModelo: '',
		leadAutoAnoFabricacao: '',
		leadAutoAnoModelo: '',
	},
	leadCorporativo: {
		leadCorporativoPorte: '',
		leadCorporativoEmpresaPlanoVigente: '',
		leadCorporativoDataAnivContrato: '',
		leadCorporativoNumFuncionarios: 0,
		leadCorporativoVlrFaturamentoAnual: 0,
		leadCorporativoPercAgenciamento: 0,
		leadCorporativoPercVitalicio: 0
	},
	leadRisk: {
		leadRiskPorte: '',
		leadRiskNumFuncionarios: 0,
	},
	leadContatos: []
};
const columnsDefinitionContatos = [
	{
		text: "Id",
		dataField: "leadContatoId",
		hidden: true,
	},
	{
		text: "Nome",
		dataField: "leadContatoNome",
		headerStyle: { width: "200px" },
		sort: true,
	},
	{
		text: "Cargo",
		dataField: "leadContatoCargo",
		headerStyle: { width: "150px" },
		sort: true,
	},
	{
		text: "Email",
		dataField: "leadContatoEmail",
		headerStyle: { width: "200px" },
		sort: true,
	},
	{
		text: "DDD",
		dataField: "leadContatoDdd",
		headerStyle: { width: "30px" },
		sort: true,
	},
	{
		text: "Telefone",
		dataField: "leadContatoTelefone",
		headerStyle: { width: "50px" },
		sort: true,
	},
];

function initialState() {
	return {
		cidadesApi: [],
		origensLeadApi: [],
		fornecedoresLeadApi: [],
		landingPagesApi: [],
		ufs: [],
		cidades: [],
		produtos: [],
		tiposNegocio: [],
		categoriasProduto: [],
		leadCorporativoPortes: [],
		leadRiskPortes: [],
		tiposVeiculo: [],
		consultores: [],
		seguradoras: [],
		lead: defaultData,
		permissoesPagina: null,
		columnsDefinitionContatos: columnsDefinitionContatos,
		dataLeadContato: {
			leadContatoNome: "",
			leadContatoCargo: "",
			leadContatoEmail: "",
			leadContatoDdd: "",
			leadContatoTelefone: "",
		}
	};
}

const LeadManualProvider = ({ children }) => {
	const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(AppContext);
	const [values, setValues] = useState(initialState());

	useEffect(() => {
		showLoading();
		async function carregarDependencias() {

			const [
				permissoesPaginaAux,
				responseCidades,
				responseOrigensLead,
				responseFornecedoresLead,
				responseLandingPages,
				responseListarProduto,
				responseListarLeadTipoNegocio,
				responseListarLeadCategoriaProduto,
				responseListarLeadCorporativoPorte,
				responseListarLeadRiskPorte,
				responseListarUsuaro,
				responseListarTipoVeiculo,
				responseListarSeguradora,
			] = await Promise.all([
				validarPermissoesPagina(),
				ListarCidades(),
				ListarOrigensLead(),
				ListarFornecedoresLead(),
				ListarLandingPages(),
				ListarProduto({}),
				ListarListBoxPorRotulo('LeadTipoNegocio'),
				ListarListBoxPorRotulo('LeadCategoriaProduto'),
				ListarListBoxPorRotulo('LeadCorporativoPorte'),
				ListarListBoxPorRotulo('LeadRiskPorte'),
				ListarUsuario({}),
				ListarTipoVeiculo(),
				ListarSeguradora()

			]);


			const ufs = await responseCidades.cidades.map((cidade) => cidade.ufCod).filter(retornarUnicos);

			let tiposVeiculo = await responseListarTipoVeiculo.tiposVeiculo.filter(
				(tipoVeiculo) => tipoVeiculo.tipoVeiculo_desativado !== 'N'
			);

			let seguradoras = await responseListarSeguradora.seguradoras.filter(
				(seguradora) => seguradora.seguradoraStatus === 'A'
			);

			let consultores = await responseListarUsuaro.usuarios.filter((usuario) => usuario.usuario_status !== 'A');

			setValues({
				...values,
				permissoesPagina: permissoesPaginaAux,
				cidadesApi: await responseCidades.cidades,
				origensLeadApi: await responseOrigensLead.origensLead.filter((item) => item.origemLeadAtivo === 'A'),
				fornecedoresLeadApi: await responseFornecedoresLead.fornecedoresLead.filter((item) => item.fornecedorLeadAtivo === 'A'),
				landingPagesApi: await responseLandingPages.landingPages,
				ufs: ufs,
				produtos: await responseListarProduto.produtos.filter((item) => item.produtoAtivo === 'A'),
				tiposNegocio: await responseListarLeadTipoNegocio.items,
				categoriasProduto: await responseListarLeadCategoriaProduto.items,
				leadCorporativoPortes: await responseListarLeadCorporativoPorte.items,
				seguradoras: seguradoras,
				leadRiskPortes: await responseListarLeadRiskPorte.items,
				consultores: consultores,
				tiposVeiculo: tiposVeiculo
			});
			hideLoading();
			setTituloTela(tituloTela);
		}

		carregarDependencias();
	}, []);

	const filtrarCidades = (uf) => {
		setValues({
			...values,
			lead: {
				...values.lead,
				uf: uf
			},
			cidades: values.cidadesApi.filter((cidade) => cidade.ufCod === uf)
		});
	};

	const Incluir = async (lead) => {
		showLoading();
		const responseIncluirLead = await IncluirLead(lead);
		if (responseIncluirLead.success) {
			showMessage('Sucesso', 'Operação Realizada com Sucesso.');
			LimparFormulario();
		} else {
			showMessage('Erro', responseIncluirLead.message);
			if (responseIncluirLead.errors) {
				responseIncluirLead.errors.map((erro) => showMessage('Erro', erro.errorMessage));
			}
		}

		hideLoading();
	};

	const LimparFormulario = () => {
		setValues({
			...values,
			lead: defaultData,
			cidades: []
		});
	};


	const adicionarContato = () => {

		let dataLeadContato = values.dataLeadContato;

		let leadContatoId = dataLeadContato.leadContatoDdd + dataLeadContato.leadContatoTelefone
		let contatoJaAdiocionado = values.lead.leadContatos.find(
			// eslint-disable-next-line eqeqeq
			(contato) => contato.leadContatoId == leadContatoId
		);
		if (contatoJaAdiocionado) {
			showMessage("Alerta", "Contato já incluído.");
			return;
		}
		var leadContatosNew = values.lead.leadContatos;
		dataLeadContato.leadContatoId = leadContatoId
		dataLeadContato.id = dataLeadContato.leadContatoId
		leadContatosNew.push(dataLeadContato);
		setValues({
			...values,
			dataLeadContato: initialState().dataLeadContato,
			lead: {
				...values.lead,
				leadContatos: leadContatosNew
			}
		});
	};

	const removerContato = (id) => {
		var leadContatosNew = values.lead.leadContatos;

		removeItemFromArray(
			leadContatosNew,
			leadContatosNew.find((contato) => contato.leadContatoId === id)
		);

		setValues({
			...values,
			dataLeadContato: initialState().dataLeadContato,
			lead: {
				...values.lead,
				leadContatos: leadContatosNew
			}
		});
	};

	return (
		<LeadManualContext.Provider
			value={{
				values,
				setValues,
				showMessage,
				Incluir,
				filtrarCidades,
				adicionarContato,
				removerContato
			}}
		>
			{children}
		</LeadManualContext.Provider>
	);
};

export default LeadManualProvider;
