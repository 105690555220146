import React, { useContext } from "react";
import "./Card.css";
import {
  MuitoFeliz,
  Feliz,
  Neutro,
  Triste,
  MuitoTriste,
} from "../../Score/Score";
import Agendamento from "../Agendamento/Agendamento";
import { retornaDescricaoTipoAtendimento } from "../../../Utils/globalFunctions";

const retornaClasseStatus = (status) => {
  let classe = "PageAgenda_ContainerCardAgendaItem";

  switch (status) {
    case 2:
      classe = `${classe} PageAgenda_ContainerCardAgendaBackgroundYellow`;
      break;
    case 3:
      classe = `${classe} PageAgenda_ContainerCardAgendaBackgroundRed`;
      break;
    default:
      break;
  }

  return classe;
};
const retornaScore = (scoreLead) => {
  switch (scoreLead) {
    case 2:
      return <Triste className="componentesScore scoreMuitoTriste" />;
    case 3:
      return <Neutro className="componentesScore scoreTriste" />;
    case 4:
      return <Feliz className="componentesScore scoreNeutro" />;
    case 5:
      return <MuitoFeliz className="componentesScore scoreFeliz" />;
    default:
      return <MuitoTriste className="componentesScore scoreMuitoFeliz" />;
  }
};

const statusTabela = (atendimentoTipo) => {
  let classe = "info";
  if (atendimentoTipo === "C") {
    classe = "warning";
  } else if (atendimentoTipo === "N") {
    classe = "success";
  }
  return (
    <div className="d-flex ">
      <span className={`badge badge-${classe} pt-1`}>
        {retornaDescricaoTipoAtendimento(atendimentoTipo)}
      </span>
    </div>
  );
};

const Card = ({ agendamento, context }) => {
  const { agendaValues, selectHandler, searchHandler } = useContext(context);
  const limiteTamanho = 20;
  return (
    <Agendamento
      refreshEventHandler={searchHandler}
      tiposAgendamento={agendaValues.tiposAgendamento}
      bloquearModal={agendaValues.habilitarSelecao}
      agendamentoId={agendamento.agendamentoId}
      tipoAgendamentoId={agendamento.tipoAgendamentoId}
      filaAtendimentoId={agendamento.filaAtendimentoId}
      tipoAgendamentoDescricao={agendamento.tipoAgendamentoDescricao}
      leadNome={agendamento.nomeLead}
      cidadeLead={agendamento.cidadeLead}
      totalContatos={agendamento.totalContatos}
      dataRecebimentoLead={agendamento.dataRecebimentoLead}
      horaRecebimentoLead={agendamento.horaRecebimentoLead}
      dataAgendamento={agendamento.dataAgendamento}
      horaAgendamento={agendamento.horaAgendamento}
      diaSemanaAgendamento={agendamento.diaSemanaAgendamento}
      atendimentos={agendamento.atendimentos}
      leadIdRenovacao={agendamento.leadIdRenovacao}
    >
      <div
        role="button"
        onClick={() => { }}
        className={retornaClasseStatus(agendamento.status)}
      >
        {agendaValues.habilitarSelecao ? (
          <div className="form-check mb-2">
            <input
              className="form-check-input PageAgenda_ContainerCardAgendaCheckBox"
              type="checkbox"
              onChange={(event) =>
                selectHandler(
                  event.target.checked,
                  agendamento.filaAtendimentoId
                )
              }
            />
            <label className="form-check-label ml-2">Selecionar</label>
          </div>
        ) : (
          ""
        )}
        <div>
          {agendamento.ultimoAtendimento ?
            <div className="pb-2" >{
              statusTabela(agendamento.ultimoAtendimento.atendimentoTipo)
            }</div> : null}
          {`${agendamento.dataAgendamento} - ${agendamento.horaAgendamento} (${agendamento.diaSemanaAgendamento})`}
          <div
            className="PageAgenda_ContainerCardAgendaItemFace"
            title={agendamento.nomeLead}
          >
            {retornaScore(agendamento.scoreLead)}
          </div>
        </div>
        <div className="font-weight-bold" title={agendamento.nomeLead}>
          {agendamento.nomeLead.length <= limiteTamanho
            ? agendamento.nomeLead
            : `${agendamento.nomeLead.substring(0, limiteTamanho)}...`}
        </div>

        {/* <div>{agendamento.cidadeLead}</div>
        <div>{agendamento.origemLead}</div>
        <div>{agendamento.totalContatos}</div>
        <div className="mt-1">
          {agendamento.leadIdRenovacao && agendamento.leadIdRenovacao > 0 ? (
            <span
              data-toggle="tooltip"
              data-placement="top"
              title="Renovação"
              className={`badge-default rounded-pill pl-1 pt-1 pb-1 pr-1 mr-1`}
            >
              Renovação
            </span>
          ) : (
            <span
              data-toggle="tooltip"
              data-placement="top"
              title="Novo Lead"
              className={`badge-info rounded-pill pl-1 pt-1 pb-1 pr-1 mr-1`}
            >
              Novo Lead
            </span>
          )}
        </div> */}
      </div>
    </Agendamento>
  );
};

export default Card;
