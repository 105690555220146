import React, { Fragment, useState } from "react";
const images = require.context("../../assets/img/icones/pages", true);

const inicialState = {
  domingo: { checked: false, inicio: "00:00", fim: "00:00" },
  segunda: { checked: false, inicio: "00:00", fim: "00:00" },
  terca: { checked: false, inicio: "00:00", fim: "00:00" },
  quarta: { checked: false, inicio: "00:00", fim: "00:00" },
  quinta: { checked: false, inicio: "00:00", fim: "00:00" },
  sexta: { checked: false, inicio: "00:00", fim: "00:00" },
  sabado: { checked: false, inicio: "00:00", fim: "00:00" },
};

function JornadaSemanal({
  formDisabled,
  recuperarJornadaSemanal,
  jornadaSemanalBase,
  descricaoJornadaSemanal,
}) {
  const [jornadaSemanal, setJornadaSemanal] = useState(inicialState);

  function onChangeCheck(event) {
    const { name, checked } = event.target;
    setJornadaSemanal({
      ...jornadaSemanal,
      [name]: {
        checked: checked,
        inicio: "00:00",
        fim: "00:00",
      },
    });
  }
  function corrigeHoraInicio(event) {
    const { name, value } = event.target;
    setJornadaSemanal({
      ...jornadaSemanal,
      [name]: {
        checked: jornadaSemanal[name].checked,
        inicio: value ? value : "00:00",
        fim: jornadaSemanal[name].fim,
      },
    });
  }
  function corrigeHoraFim(event) {
    const { name, value } = event.target;
    setJornadaSemanal({
      ...jornadaSemanal,
      [name]: {
        checked: jornadaSemanal[name].checked,
        inicio: jornadaSemanal[name].inicio,
        fim: value ? value : "00:00",
      },
    });
  }
  function onChangeInicio(event) {
    const { name, value } = event.target;
    setJornadaSemanal({
      ...jornadaSemanal,
      [name]: {
        checked: jornadaSemanal[name].checked,
        inicio: value,
        fim: jornadaSemanal[name].fim,
      },
    });
  }
  function onChangeFim(event) {
    const { name, value } = event.target;
    setJornadaSemanal({
      ...jornadaSemanal,
      [name]: {
        checked: jornadaSemanal[name].checked,
        inicio: jornadaSemanal[name].inicio,
        fim: value,
      },
    });
  }

  const carregarJornada = () => {
    setJornadaSemanal({
      ...jornadaSemanal,
      ...jornadaSemanalBase,
    });
  };
  const confirmar = () => {
    setJornadaSemanal({
      ...jornadaSemanal,
    });
    recuperarJornadaSemanal(jornadaSemanal);
  };
  return (
    <Fragment>
      <div className="mb-3 col-12 col-md-6">
        <div className="input-group">
          <label>Jornada Semanal</label>
          <input
            disabled
            type="text"
            className="form-control"
            aria-describedby="button-addon2"
            value={descricaoJornadaSemanal}
          />
          <div className="input-group-append">
            <button
              className="btn btn-success"
              type="button"
              id="button-addon2"
              data-toggle="modal"
              data-target="#modalJornadaSemanal"
              onClick={carregarJornada}
            >
              <img src={images(`./interface.png`)} alt="" />
            </button>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalJornadaSemanal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalJornadaSemanalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalJornadaSemanalLabel">
              Jornada Semanal
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row align-items-center">
                <div className="col form-group">
                  <label>&nbsp;</label>
                  <div className="form-check">
                    <input
                      disabled={formDisabled}
                      className="form-check-input"
                      type="checkbox"
                      name="segunda"
                      checked={jornadaSemanal.segunda.checked}
                      onChange={onChangeCheck}
                    />
                    <label className="form-check-label">Segunda-Feira</label>
                  </div>
                </div>
                <div className="col form-group">
                  <label>Hora Início</label>
                  <input
                    disabled={formDisabled || !jornadaSemanal.segunda.checked}
                    type="time"
                    className="form-control"
                    name="segunda"
                    value={jornadaSemanal.segunda.inicio}
                    onChange={onChangeInicio}
                    onBlur={corrigeHoraInicio}
                  />
                </div>
                <div className="col form-group">
                  <label>Hora Fim</label>
                  <input
                    disabled={formDisabled || !jornadaSemanal.segunda.checked}
                    type="time"
                    className="form-control"
                    name="segunda"
                    value={jornadaSemanal.segunda.fim}
                    onChange={onChangeFim}
                    onBlur={corrigeHoraFim}
                  />
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col form-group">
                  <div className="form-check">
                    <input
                      disabled={formDisabled}
                      className="form-check-input"
                      type="checkbox"
                      name="terca"
                      checked={jornadaSemanal.terca.checked}
                      onChange={onChangeCheck}
                    />
                    <label className="form-check-label">Terça-Feira</label>
                  </div>
                </div>
                <div className="col form-group">
                  <input
                    disabled={formDisabled || !jornadaSemanal.terca.checked}
                    type="time"
                    className="form-control"
                    name="terca"
                    value={jornadaSemanal.terca.inicio}
                    onChange={onChangeInicio}
                    onBlur={corrigeHoraInicio}
                  />
                </div>
                <div className="col form-group">
                  <input
                    disabled={formDisabled || !jornadaSemanal.terca.checked}
                    type="time"
                    className="form-control"
                    name="terca"
                    value={jornadaSemanal.terca.fim}
                    onChange={onChangeFim}
                    onBlur={corrigeHoraFim}
                  />
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col form-group">
                  <div className="form-check">
                    <input
                      disabled={formDisabled}
                      className="form-check-input"
                      type="checkbox"
                      name="quarta"
                      checked={jornadaSemanal.quarta.checked}
                      onChange={onChangeCheck}
                    />
                    <label className="form-check-label">Quarta-Feira</label>
                  </div>
                </div>
                <div className="col form-group">
                  <input
                    disabled={formDisabled || !jornadaSemanal.quarta.checked}
                    type="time"
                    className="form-control"
                    name="quarta"
                    value={jornadaSemanal.quarta.inicio}
                    onChange={onChangeInicio}
                    onBlur={corrigeHoraInicio}
                  />
                </div>
                <div className="col form-group">
                  <input
                    disabled={formDisabled || !jornadaSemanal.quarta.checked}
                    type="time"
                    className="form-control"
                    name="quarta"
                    value={jornadaSemanal.quarta.fim}
                    onChange={onChangeFim}
                    onBlur={corrigeHoraFim}
                  />
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col form-group">
                  <div className="form-check">
                    <input
                      disabled={formDisabled}
                      className="form-check-input"
                      type="checkbox"
                      name="quinta"
                      checked={jornadaSemanal.quinta.checked}
                      onChange={onChangeCheck}
                    />
                    <label className="form-check-label">Quinta-Feira</label>
                  </div>
                </div>
                <div className="col form-group">
                  <input
                    disabled={formDisabled || !jornadaSemanal.quinta.checked}
                    type="time"
                    className="form-control"
                    name="quinta"
                    value={jornadaSemanal.quinta.inicio}
                    onChange={onChangeInicio}
                    onBlur={corrigeHoraInicio}
                  />
                </div>
                <div className="col form-group">
                  <input
                    disabled={formDisabled || !jornadaSemanal.quinta.checked}
                    type="time"
                    className="form-control"
                    name="quinta"
                    value={jornadaSemanal.quinta.fim}
                    onChange={onChangeFim}
                    onBlur={corrigeHoraFim}
                  />
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col form-group">
                  <div className="form-check">
                    <input
                      disabled={formDisabled}
                      className="form-check-input"
                      type="checkbox"
                      name="sexta"
                      checked={jornadaSemanal.sexta.checked}
                      onChange={onChangeCheck}
                    />
                    <label className="form-check-label">Sexta-Feira</label>
                  </div>
                </div>
                <div className="col form-group">
                  <input
                    disabled={formDisabled || !jornadaSemanal.sexta.checked}
                    type="time"
                    className="form-control"
                    name="sexta"
                    value={jornadaSemanal.sexta.inicio}
                    onChange={onChangeInicio}
                    onBlur={corrigeHoraInicio}
                  />
                </div>
                <div className="col form-group">
                  <input
                    disabled={formDisabled || !jornadaSemanal.sexta.checked}
                    type="time"
                    className="form-control"
                    name="sexta"
                    value={jornadaSemanal.sexta.fim}
                    onChange={onChangeFim}
                    onBlur={corrigeHoraFim}
                  />
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col form-group">
                  <div className="form-check">
                    <input
                      disabled={formDisabled}
                      className="form-check-input"
                      type="checkbox"
                      name="sabado"
                      checked={jornadaSemanal.sabado.checked}
                      onChange={onChangeCheck}
                    />
                    <label className="form-check-label">Sábado</label>
                  </div>
                </div>
                <div className="col form-group">
                  <input
                    disabled={formDisabled || !jornadaSemanal.sabado.checked}
                    type="time"
                    className="form-control"
                    name="sabado"
                    value={jornadaSemanal.sabado.inicio}
                    onChange={onChangeInicio}
                    onBlur={corrigeHoraInicio}
                  />
                </div>
                <div className="col form-group">
                  <input
                    disabled={formDisabled || !jornadaSemanal.sabado.checked}
                    type="time"
                    className="form-control"
                    name="sabado"
                    value={jornadaSemanal.sabado.fim}
                    onChange={onChangeFim}
                    onBlur={corrigeHoraFim}
                  />
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col form-group">
                  <div className="form-check">
                    <input
                      disabled={formDisabled}
                      className="form-check-input"
                      type="checkbox"
                      name="domingo"
                      checked={jornadaSemanal.domingo.checked}
                      onChange={onChangeCheck}
                    />
                    <label className="form-check-label">Domingo</label>
                  </div>
                </div>
                <div className="col form-group">
                  <input
                    disabled={formDisabled || !jornadaSemanal.domingo.checked}
                    type="time"
                    className="form-control"
                    name="domingo"
                    value={jornadaSemanal.domingo.inicio}
                    onChange={onChangeInicio}
                    onBlur={corrigeHoraInicio}
                  />
                </div>
                <div className="col form-group">
                  <input
                    disabled={formDisabled || !jornadaSemanal.domingo.checked}
                    type="time"
                    className="form-control"
                    name="domingo"
                    value={jornadaSemanal.domingo.fim}
                    onChange={onChangeFim}
                    onBlur={corrigeHoraFim}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Fechar
              </button>
              <button
                disabled={formDisabled}
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={confirmar}
              >
                Alterar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default JornadaSemanal;
