import React, { memo } from "react";
import "./Padrao.css";
import { Handle } from "react-flow-renderer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default memo(({ data }) => {
  return (
    <>
      <Handle
        type="target"
        position="top"
        style={{ top: 10 }}
      />

      <div className="border border-secondary rounded customNodePadraoBody">
        <FontAwesomeIcon
          style={{ color: data.corIcone }}
          className="customNodePadraoIcone fa-7x"
          icon={data.icone}
        />
        <div className="row" />
        <div className="customNodePadraoText">{data.descricao}</div>
      </div>

      <Handle type="source" position="bottom" />
    </>
  );
});
