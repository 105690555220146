import React, { useContext, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { PreLeadCotacaoContext } from "../PreLeadCotacaoContext";
import DataTableDefault from "../../../../../Components/DataTableDefault/DataTableDefault";
import { useHistory } from "react-router-dom";
import $ from 'jquery';

import FormularioComplementar from '../../../../../Components/Cadastro/FormularioComplementar/FormularioComplementar';

const FormularioPreLeadCotacao = () => {
  const { values, getDetailHandler } = useContext(
    PreLeadCotacaoContext
  );
  let history = useHistory();
  let { id } = useParams();

  const voltar = () => {
    history.goBack();
  };

  useEffect(() => {
    getDetailHandler(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const columnsDefinitionItens = [
    {
      text: "Id",
      dataField: "preLeadCotacaoId",
      hidden: true,
    },
    {
      text: "Data",
      dataField: "preLeadCotacaoDataHora",
      headerStyle: { width: "30px" },
      sort: true,
    },
    {
      text: "Campo",
      dataField: "preLeadCotacaoCampo",
      headerStyle: { width: "70px" },
      sort: true,
    },
    {
      text: "Valor",
      dataField: "preLeadCotacaoValor",
      headerStyle: { width: "150px" },
      sort: true,
      formatter: (cell, row) => {
        return (
          <div dangerouslySetInnerHTML={{ __html: cell ? cell : "" }}>

          </div>
        );
      }
    },
  ];
  return (
    <Fragment>
      <div className="row mb-3">
        <div className="col-12 text-right">
          <button
            type="button"
            className="btn btn-secondary mr-3"
            onClick={voltar}
          >
            Voltar
          </button>

        </div>
      </div>
      <FormularioComplementar titulo={""}>
        <div className="col-12 col-md-4 form-group">
          <span style={{ fontWeight: "bold" }}>Nome:</span> {values.data.leadNome}
        </div>
        <div className="col-12 col-md-3 form-group">
          <span style={{ fontWeight: "bold" }}>Produto:</span> {values.data.produtoNome}
        </div>
        <div className="col-12 col-md-3 form-group">
          <span style={{ fontWeight: "bold" }}>Jornada Assistida:</span> {values.data.preLeadCotacaoJornadaAssistida}
        </div>
        <div className="col-12 col-md-2 form-group">
          <span style={{ fontWeight: "bold" }}>Jornada:</span> {values.data.preLeadCotacaoJornada}
        </div>

        <div className="col-12 text-center">
          <hr />
          <button
            type="button"
            className="btn btn-success mr-3"
            onClick={() => { getDetailHandler(id); }}
          >
            Atualizar
          </button>
          <button
            type="button"
            className="btn btn-info mr-3"
            onClick={() => {
              var p = $(".buttonParaTopo").last();
              var offset = p.offset();
              $('html, body').animate({
                scrollTop: (offset.top - ((offset.top / 100) * 15))

              }, 'fast');
            }}
          >
            Ir para o final das interações
          </button>
        </div>
      </FormularioComplementar>

      <FormularioComplementar titulo={"Interações"}>
        <DataTableDefault
          keyField="Id"
          data={values.preLeadCotacoes}
          columns={columnsDefinitionItens}
          removerPaginacao={true}
        />
        <div className="col-12 text-center">

          <button
            type="button"
            className="btn btn-info mr-3 buttonParaTopo"
            onClick={() => { $('html, body').animate({ scrollTop: 0 }, 'fast'); }}
          >
            Ir para o inicio das interações
          </button>
        </div>
      </FormularioComplementar>

    </Fragment>
  );
};

export default FormularioPreLeadCotacao;
