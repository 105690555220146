import React, { Fragment } from "react";
import VendaRealizada from "./VendaRealizada";

const VendasRealizadas = ({
  defineDetalhesObjeto = () => { },
  vendas = [],
  apolices = [],
  backoffices = [],
  endossos = [],
  cobrancas = [],
  sinistros = [],
}) => {

  const obterApolice = (venda) => {
    return apolices.find(x => x.apoliceId === venda.apoliceId);
  }

  const obterBackOffice = (venda) => {
    return backoffices.find(x => x.backofficeId == venda.backofficeId);
  }

  const obterEndossos = (venda) => {
    return !venda ? [] : endossos.filter(x => x.vendaId === venda.vendaId);


  }

  const obterCobrancas = (venda) => {
    return !venda ? [] : cobrancas.filter(x => x.vendaId === venda.vendaId);
  }

  const obterSinistros = (venda) => {
    return !venda ? [] : sinistros.filter(x => x.vendaId === venda.vendaId);
  }

  return (
    <Fragment>
      <div className="default-title">Vendas</div>
      <div className="card shadow-sm mb-4">
        <div className="row align-items-center">
          {vendas.map((venda) => {
            return <Fragment key={venda.vendaId}>
              <VendaRealizada defineDetalhesObjeto={defineDetalhesObjeto}
                venda={venda}
                apolice={obterApolice(venda)}
                backoffice={obterBackOffice(venda)}
                endossos={obterEndossos(venda)}
                cobrancas={obterCobrancas(venda)}
                sinistros={obterSinistros(venda)}
              />
              <div className="col-12">
                <hr />
              </div>
            </Fragment>
          })}

        </div>
      </div>
    </Fragment>
  );
};

export default VendasRealizadas;
