/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext } from "react";
import AppContext from "../../../../Store/AppContext";
import {
  Listar as ListarTemplateSms,
  Detalhes as DetalhesTemplateSms,
  Remover as RemoverTemplateSms,
  Incluir as IncluirTemplateSms,
  Atualizar as AtualizarTemplateSms,
} from "../../../../Services/TemplateSms";

import { useHistory } from "react-router-dom";
import { Listar as ListarProduto } from "../../../../Services/Produto";
import { validarPermissoesPagina } from "../../../../Utils/validarPermissoesPagina";
export const TemplateSmsContext = createContext();

const tituloTela = "Template Sms";

const defaultData = {
  templateSmsId: 0,
  produtoId: 0,
  templateSmsNome: "",
  templateSmsMsg: "",
  templateSmsTipo: "I",
};

const searchData = {
  produtoId: 0,
  templateSmsNome: "",
};

const columnsDefinition = [
  {
    text: "Id",
    dataField: "templateSmsId",
    hidden: true,
  },
  {
    text: "Nome do Template",
    dataField: "templateSmsNome",
    headerStyle: { width: "400px" },
    sort: true,
  },
  {
    text: "Produto",
    dataField: "produtoNome",
    headerStyle: { width: "300px" },
    sort: true,
  },
  {
    text: "Tipo",
    dataField: "templateSmsTipo",
    headerStyle: { width: "100px" },
    sort: true,
  },
];

function initialState() {
  return {
    searchData: searchData,
    resultSearch: [],
    columnsDefinition: columnsDefinition,
    data: defaultData,
    formDisabled: true,
    produtos: []
  };
}

const TemplateSmsProvider = ({ children }) => {
  const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(
    AppContext
  );
  const [values, setValues] = useState(initialState());
  let history = useHistory();

  async function loadDependences() {
    const permissoesPagina = validarPermissoesPagina();
    const responseListarProduto = await ListarProduto({});

    if (
      !responseListarProduto.success
    ) {
      showMessage("Erro", "Erro Inesperado ao carregar dados basicos.");
      return {
        permissoesPagina: [],
        produtos: []
      };
    }

    const produtos = await responseListarProduto.produtos.filter((item) => item.produtoAtivo === 'A');

    return { permissoesPagina, produtos };
  }

  const resetScreen = () => {
    showLoading();
    async function carregarDependencias() {
      const dependencias = await loadDependences();
      setTituloTela(tituloTela);
      setValues({
        ...initialState(),
        permissoesPagina: await dependencias.permissoesPagina,
        produtos: await dependencias.produtos
      });
      hideLoading();
    }
    carregarDependencias();
  };

  const searchHandler = async () => {
    showLoading();
    const responseListar = await ListarTemplateSms(values.searchData);
    if (!responseListar.success) {
      showMessage("Erro", "Erro Inesperado durante a pesquisa.");
      hideLoading();
      return;
    }

    const resultSearchData = await responseListar.templatesSms;

    resultSearchData.map((data) => (data.Id = data.templateSmsId));

    setValues({
      ...values,
      resultSearch: resultSearchData,
    });

    if (resultSearchData.length === 0) {
      showMessage("Alerta", "Nenhum registro encontrado.");
    }
    hideLoading();
  };

  const removeHandler = async (id) => {
    showLoading();

    const responseRemover = await RemoverTemplateSms(id);

    if (!responseRemover.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro durante a exclusão: ${responseRemover.message}`
      );
      return;
    }
    searchHandler();
    hideLoading();
    showMessage("Sucesso", "Registro excluido.");
  };

  const getDetailHandler = async (id) => {
    showLoading();
    const dependencias = await loadDependences();

    if (id > 0) {
      const responseDetalhes = await DetalhesTemplateSms(id);

      const { success, templateSms } = await responseDetalhes;

      if (!success) {
        showMessage("Erro", "Erro ao buscar os detalhes do registro.");
        setValues({
          ...values,
          data: defaultData,
          permissoesPagina: await dependencias.permissoesPagina,
        });
      }

      setValues({
        ...values,
        data: templateSms,
        permissoesPagina: await dependencias.permissoesPagina,
        produtos: await dependencias.produtos
      });
    } else {
      setValues({
        ...values,
        data: {
          ...defaultData,
          templateSmsId: 0,
        },
        permissoesPagina: await dependencias.permissoesPagina,
        produtos: await dependencias.produtos,
        formDisabled: false,
      });
    }
    hideLoading();
  };

  const createData = async () => {
    showLoading();
    const responseIncluir = await IncluirTemplateSms(values.data);
    if (!responseIncluir.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao criar o registro: ${responseIncluir.message}`
      );
      responseIncluir.errors.map((erro) =>
        showMessage("Erro", erro.errorMessage)
      );
      return;
    }

    resetScreen();
    hideLoading();
    showMessage("Sucesso", "Registro criado.");
    history.goBack();
  };

  const editHandler = () => {
    setValues({
      ...values,
      formDisabled: false,
    });
  };

  const updateData = async () => {
    showLoading();
    const responseAtualizar = await AtualizarTemplateSms(values.data);
    if (!responseAtualizar.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao atualizar o registro: ${responseAtualizar.message}`
      );
      responseAtualizar.errors.map((erro) =>
        showMessage("Erro", erro.errorMessage)
      );
      return;
    }
    resetScreen();
    hideLoading();
    showMessage("Sucesso", "Registro atualizado.");
    history.goBack();
  };

  const saveHandler = async () => {
    if (values.data.templateSmsId === 0) {
      await createData();
    } else {
      await updateData();
    }
  };

  const cancelHandler = () => {
    setValues({
      ...values,
      data: defaultData,
      formDisabled: true,
    });
  };

  return (
    <TemplateSmsContext.Provider
      value={{
        values,
        setValues,
        resetScreen,
        searchHandler,
        cancelHandler,
        removeHandler,
        saveHandler,
        getDetailHandler,
        editHandler,
      }}
    >
      {children}
    </TemplateSmsContext.Provider>
  );
};

export default TemplateSmsProvider;
