import React, { Fragment, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from 'react-bootstrap-table2-paginator';
import "./DataTable.css";
import Excluir from "./Excluir/Excluir";
import Selecionar from "./Selecionar/Selecionar";

const paginationOptions = {
  pageStartIndex: 0,
  paginationSize: 5,
  showTotal: true,
  withFirstAndLast: true,
  alwaysShowAllBtns: true,
  firstPageText: 'Primeira',
  prePageText: 'Anterior',
  nextPageText: 'Proximo',
  lastPageText: 'Ultima',
  nextPageTitle: 'Proximo',
  prePageTitle: 'Anterior',
  firstPageTitle: 'Primeira',
  lastPageTitle: 'Ultima',
  hideSizePerPage: true,
  hidePageListOnlyOnePage: true,
  paginationTotalRenderer: (from, to, size) => (
    <span>
      Mostrando de {from} até {to} de {size} registros
    </span>
  )
};

export const DataTable = ({
  data,
  columnsDefinition,
  removeHandler,
  selectHandler,
  cunstomSelectButton = null,
  showSelectIcon = false,
  campoIdentificador = null

}) => {

  let columnsDefinitionComplete = columnsDefinition;

  if (removeHandler || selectHandler || cunstomSelectButton) {
    const actionsTable = (cell, row) => {
      return (
        <div className="tabelasResultados__acoes">
          {removeHandler ? <Excluir removeHandler={removeHandler} id={campoIdentificador ? row[campoIdentificador] : row.id} /> : null}
          <div className="ml-1" />
          {cunstomSelectButton ? cunstomSelectButton(row) : (selectHandler ? <Selecionar selectHandler={selectHandler} showIcone={showSelectIcon} id={campoIdentificador ? row[campoIdentificador] : row.id} /> : null)}

        </div>
      );
    };

    columnsDefinitionComplete = [
      {
        dataField: "actions",
        text: "Ações",
        headerStyle: { width: "40px", textAlign: "center" },
        isDummyField: true,
        csvExport: false,
        headerAttrs: { width: 100 },
        formatter: actionsTable,
      },
      ...columnsDefinition,
    ];
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <BootstrapTable
            bootstrap4={true}
            condensed={true}
            noDataIndication={() => "Nenhum Registro Selecionado"}
            striped={true}
            hover={true}
            keyField="id"
            data={data.map((item) => { return { ...item, id: item[campoIdentificador] ?? item.id } })}
            columns={columnsDefinitionComplete}
            rowStyle={{ fontSize: '11px' }}
            pagination={paginationFactory(paginationOptions)}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default DataTable;
