import React, { useContext, Fragment } from "react";
import "./RedirecionarAgendamento.css";
import Redirecionar from "../../../../../Components/Agenda/Redirecionar/Redirecionar";

const RedirecionarAgendamento = ({ context }) => {
  const { agendaValues, setAgendaValues } = useContext(context);

  const habilitarSelecao = () => {
    setAgendaValues({
      ...agendaValues,
      habilitarSelecao: !agendaValues.habilitarSelecao,
      data: {
        usuarioId: "0",
        filaAtendimentos: [],
      },
    });
  };
  return (
    <Fragment>
      {agendaValues.habilitarSelecao ? (
        <>
          <button
            type="button"
            className="btn btn-secondary mr-2"
            onClick={habilitarSelecao}
          >
            Cancelar
          </button>
          <Redirecionar context={context} />
        </>
      ) : (
        <button
          type="button"
          className="btn btn-primary ml-2"
          onClick={habilitarSelecao}
        >
          Redirecionar Agendamentos
        </button>
      )}
    </Fragment>
  );
};

export default RedirecionarAgendamento;
