import React, { Fragment, useContext } from "react";
import "./Agendamento.css";

const Agendamento = ({
  context = null,
  id = "",
  disabled = false,
  onClickHandler = () => {},
}) => {
  const { atendimentoValues, setAtendimentoValues } = useContext(context);

  const dataInvalida = new Date(
    `${atendimentoValues.dadosAtendimento.agendamento.dataAgendamento} ${atendimentoValues.dadosAtendimento.agendamento.horaAgendamento}`  
  ) < new Date().getTime();

  const formularioComErro =
    atendimentoValues.dadosAtendimento.agendamento.tipoAgendamentoId === "0" ||
    !atendimentoValues.dadosAtendimento.agendamento.tipoAgendamentoId ||
    !atendimentoValues.dadosAtendimento.agendamento.horaAgendamento ||
    !atendimentoValues.dadosAtendimento.agendamento.dataAgendamento ||
    dataInvalida;

 
  function onChangeInput(event) {
   
    const { name, value } = event.target;
    setAtendimentoValues({
      ...atendimentoValues,
      dadosAtendimento: {
        ...atendimentoValues.dadosAtendimento,
        agendamento: {
          ...atendimentoValues.dadosAtendimento.agendamento,
          [name]: value,
        },
      },
    });
  }
  return (
    <Fragment>
      <button
        disabled={disabled}
        type="button"
        className="btn btn-default"
        data-toggle="modal"
        data-target={`#modalAgendamentoAtendimento${id}`}
      >
        Salvar e Agendar
      </button>

      <div
        className="modal fade"
        id={`modalAgendamentoAtendimento${id}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby={`modalAgendamentoAtendimento${id}Label`}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id={`modalAgendamentoAtendimento${id}Label`}
              >
                Agendamento
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body componenteAtendimentoAgendamento_modalBody">
              <div className="row">
                <div className="col-5  text-right ">
                  <label>
                    Tipo<span className="campoObratorio">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="tipoAgendamentoId"
                    value={
                      atendimentoValues.dadosAtendimento.agendamento
                        .tipoAgendamentoId
                    }
                    onChange={onChangeInput}
                  >
                    <option className="disabled" value="0"></option>
                    {atendimentoValues.tiposAgendamento.map(
                      (tipoAgendamento) => (
                        <option
                          key={tipoAgendamento.value}
                          value={tipoAgendamento.value}
                        >
                          {tipoAgendamento.label}
                        </option>
                      )
                    )}
                  </select>
                </div>
                <div className="col-4  text-right">
                  <label>
                    Data<span className="campoObratorio">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    name="dataAgendamento"
                    value={
                      atendimentoValues.dadosAtendimento.agendamento
                        .dataAgendamento
                    }
                    onChange={onChangeInput}
                  />
                </div>
                <div className="col-3 text-right">
                  <label>
                    Hora<span className="campoObratorio">*</span>
                  </label>
                  <input
                    type="time"
                    className="form-control"
                    name="horaAgendamento"
                    value={
                      atendimentoValues.dadosAtendimento.agendamento
                        .horaAgendamento
                    }
                    onChange={onChangeInput}
                  />
                </div>
                <div className="col-12 form-group">
                  <label>
                    Observação
                  </label>
                  <textarea
                    cols="30"
                    rows="4"
                    className="form-control"
                    name="observacao"
                    value={
                      atendimentoValues.dadosAtendimento.agendamento.observacao
                    }
                    onChange={onChangeInput}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancelar
              </button>
              <button
                disabled={formularioComErro}
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={onClickHandler}
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Agendamento;
