import React, { Fragment } from "react";
import "./Excluir.css";
const images = require.context("../../../assets/img/icones/pages", true);
const iconeRemove = images("./trash.png");
const Excluir = ({ removeHandler = () => { }, id }) => {
  return (
    <Fragment>
      <button
        type="button"
        className="btn btn-danger btn-sm dataTableExcluir_botao"
        onClick={() => removeHandler(id)}>
        <img src={iconeRemove} alt="" />
      </button>
    </Fragment>
  );
};

export default Excluir;
