import axios from 'axios';
import storage from '../Utils/storage';
import { Autenticado } from './Autenticacao';
import { Alerta } from '../Utils/Alerta';

const pathSemAutenticacao = ['/', '/Pagamento/Cartao'];

function permiteAcessoExterno(currentPath) {
	const index = pathSemAutenticacao.find((x) => x === currentPath);
	return index;
}

function redirect() {
	Alerta('alerta', 'Sua Sessão foi Perdida.');
	setTimeout(() => {
		window.location.replace('/');
	}, 500);
}

export const getToken = () => {
	if (!storage.get('token') && !permiteAcessoExterno(window.location.pathname)) {
		redirect();
		return;
	}

	if (storage.get('token')) {
		const { token } = storage.get('token');
		Autenticado(token).then((autenticado) => {
			if (!autenticado.autenticado && !permiteAcessoExterno(window.location.pathname)) {
				redirect();
			}
		});
		return token;
	}
	return '';
};

export const RequestWebApi = axios.create({
	baseURL: process.env.REACT_APP_WEB_API_URL_BASE,
	headers: {
		'Content-Type': 'application/json; charset=utf-8',
		'Accept-Encoding': 'gzip',
		'Connection': 'keep-alive',
		Authorization: `Bearer ${getToken()}`
	}
});

export const RequestOpenWebApi = axios.create({
	baseURL: process.env.REACT_APP_WEB_API_URL_BASE,
	headers: {
		'Content-Type': 'application/json; charset=utf-8',
		'Accept-Encoding': 'gzip',
		'Connection': 'keep-alive'
	}
});

export const RequestWebApiSetToken = (token) => {
	return axios.create({
		baseURL: process.env.REACT_APP_WEB_API_URL_BASE,
		headers: {
			'Content-Type': 'application/json; charset=utf-8',
			'Accept-Encoding': 'gzip',
			'Connection': 'keep-alive',
			Authorization: `Bearer ${token}`
		}
	});
};

export const validateRequestErrorStatus = (status) => {

	if (status === 401) {
		getToken();
	}
};
