import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "BackOfficeAtendimento";

export const Listar = (backOfficeAtendimento) =>
  RequestWebApi.get(`/${controller}/Listar`, {
    params: { ...backOfficeAtendimento },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const backOffice = resultData;
      return { success, backOffice };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { backOffice: [] };
    });

export const Detalhes = (leadId) =>
  RequestWebApi.get(`/${controller}/Detalhes`, {
    params: { leadId: leadId },
  })
    .then(function (response) {
      const { resultData, success } = response.data;
      const backOffice = resultData;
      return { success, backOffice };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors, backOffice: {} };
    });

export const ProcessarBackOfficeAtendimento = (backOffice) =>
  RequestWebApi.post(
    `/${controller}/ProcessarBackOfficeAtendimento`,
    backOffice
  )
    .then(function (response) {
      const { resultData, success } = response.data;
      const backOfficeId = resultData;
      return { success, backOfficeId };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (origemLeadId) =>
  RequestWebApi.delete(`/${controller}/Remover`, {
    params: { origemLeadId: origemLeadId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });
