import React, { useEffect } from "react";
import { LoadMask } from "../../../../Utils/mask";
import Incluir from "./Events/Incluir";
import LeadManualProvider from "./LeadManualContext";

const LeadCadstroManual = () => {
  useEffect(() => {
    LoadMask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <LeadManualProvider>
      <div className="container-fluid pl-3 pt-3 pb-3">
        <Incluir />
      </div>
    </LeadManualProvider>
  );
};

export default LeadCadstroManual;
