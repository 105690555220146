/* eslint-disable eqeqeq */
import React, { useContext, useEffect, Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EquipeContext } from '../EquipeContext';
import Formulario from '../../../../../Components/Cadastro/Formulario/Formulario';
import FormularioComplementar from '../../../../../Components/Cadastro/FormularioComplementar/FormularioComplementar';
import DataTable from '../../../../../Components/DataTable/DataTable';
import Select from 'react-select';

const initialStateFomularioEquipe = {
	uf: { label: 'TODOS', value: '' },
	cidade: { label: 'TODOS', value: '' },
	landingPage: { label: '', value: '' }
};
const FormularioEquipe = () => {
	const [formularioEquipeValues, setFormularioEquipeValues] = useState(initialStateFomularioEquipe);
	const {
		values,
		setValues,
		getDetailHandler,
		removerResponsavel,
		adionarResponsavel,
		removerLandingPage,
		adionarLandingPage,
		filtrarCidades,
		filtrarLandingPages,
		adionarLojaVirtual,
		removerLojaVirtual
	} = useContext(EquipeContext);
	let { id } = useParams();

	useEffect(() => {
		getDetailHandler(id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSelect2ChangeUf = (item) => {
		setFormularioEquipeValues({ ...formularioEquipeValues, uf: item });
		filtrarCidades(item.value);
	};
	const handleSelect2ChangeCidade = (item) => {
		setFormularioEquipeValues({ ...formularioEquipeValues, cidade: item });

		filtrarLandingPages(item.value);
	};

	const handleSelect2ChangeLandingPage = (item) => {
		setFormularioEquipeValues({ ...formularioEquipeValues, landingPage: item });

		const value = item.value;
		const landingPage = values.landingPages.find((x) => x.landingPageId == value);
		setValues({
			...values,
			dataLandingPages: {
				id: value,
				...landingPage
			}
		});
	};

	const handleAdionarLandingPage = (item) => {
		adionarLandingPage();
		setFormularioEquipeValues({ ...formularioEquipeValues, landingPage: initialStateFomularioEquipe.landingPage });
	};

	function onChangeInput(event) {
		const { name, value } = event.target;
		setValues({
			...values,
			data: {
				...values.data,
				[name]: value
			}
		});
	}

	function onChangeInputSupervisores(event) {
		const value = event.target.value;
		const text = event.target.options[event.target.selectedIndex].text;
		setValues({
			...values,
			dataSupervisores: {
				id: value,
				usuarioId: value,
				usuarioNome: text
			}
		});
	}

	function onChangeInputLojaVirtual(event) {
		const value = event.target.value;
		const text = event.target.options[event.target.selectedIndex].text;
		setValues({
			...values,
			dataLojaVirtual: {
				id: value,
				lojaVirtualId: value,
				lojaVirtualNome: text
			}
		});
	}

	function onChangeCheck(event) {
		const { name, checked } = event.target;
		setValues({
			...values,
			data: {
				...values.data,
				[name.replace('Check', '')]: 0,
				[name]: checked ? 1 : 0
			}
		});
	}

	return (
		<Fragment>
			<Formulario context={EquipeContext}>
				<div className="col-12 col-md-5 form-group">
					<label>Nome da Equipe</label>
					<input
						disabled={values.formDisabled}
						className="form-control"
						type="text"
						name="equipeNome"
						value={values.data.equipeNome}
						maxLength={20}
						onChange={onChangeInput}
					/>
				</div>

				<div className="col-12 col-md-3 form-group">
					<label>Produto</label>
					<select
						disabled={values.formDisabled}
						className="form-control"
						name="produtoId"
						value={values.data.produtoId}
						onChange={onChangeInput}
					>
						<option className="disabled" />
						{values.produtos.map((produto) => (
							<option key={produto.produtoId} value={produto.produtoId}>
								{produto.produtoNome}
							</option>
						))}
					</select>
				</div>
				<div className="col-12 col-md-2 form-group ">
					<label>Atende Renovação</label>
					<input
						disabled={values.formDisabled}
						type="checkbox"
						name="equipeAtendeRenovacao"
						checked={values.data.equipeAtendeRenovacao}
						onChange={onChangeCheck}
					/>
				</div>
				<div className="col-12 col-md-12 form-group " />
				<div className="col-12 col-md-2 form-group ">
					<label>Avisar por e-mail</label>
					<input
						disabled={values.formDisabled}
						type="checkbox"
						name="equipeAgendaTempoAvisoEmailCheck"
						checked={values.data.equipeAgendaTempoAvisoEmailCheck}
						onChange={onChangeCheck}
					/>
				</div>
				<div className="col-12 col-md-2 form-group">
					<label>Tempo de antecedência</label>
					<label>(Minutos)</label>
					<input
						disabled={values.formDisabled || values.data.equipeAgendaTempoAvisoEmailCheck === 0}
						className="form-control"
						type="number"
						min="0"
						name="equipeAgendaTempoAvisoEmail"
						value={values.data.equipeAgendaTempoAvisoEmail}
						onChange={onChangeInput}
					/>
				</div>

				<div className="col-12 col-md-12 form-group " />
				<div className="col-12 col-md-2 form-group ">
					<label>Avisar na tela</label>
					<input
						disabled={values.formDisabled}
						type="checkbox"
						name="equipeAgendaTempoAvisoTelaCheck"
						checked={values.data.equipeAgendaTempoAvisoTelaCheck}
						onChange={onChangeCheck}
					/>
				</div>
				<div className="col-12 col-md-2 form-group">
					<label>Tempo de antecedência</label>
					<label>(Minutos)</label>
					<input
						disabled={values.formDisabled || values.data.equipeAgendaTempoAvisoTelaCheck === 0}
						className="form-control"
						type="number"
						min="0"
						name="equipeAgendaTempoAvisoTela"
						value={values.data.equipeAgendaTempoAvisoTela}
						onChange={onChangeInput}
					/>
				</div>
			</Formulario>
			<FormularioComplementar titulo="Supervisores">
				<div className="col-12 col-md-4 form-group">
					<label>Supervisor</label>
					<select
						disabled={values.formDisabled}
						className="form-control"
						name="usuarioId"
						value={values.dataSupervisores.usuarioId}
						onChange={onChangeInputSupervisores}
					>
						<option className="disabled" />
						{values.supervisores.map((supervisor) => (
							<option key={supervisor.usuarioId} value={supervisor.usuarioId}>
								{supervisor.usuarioNome}
							</option>
						))}
					</select>
				</div>

				<div className="col-12 col-md-2 form-group">
					<button
						disabled={!values.dataSupervisores.usuarioId}
						style={{ marginTop: '26px' }}
						type="button"
						className="btn btn-default buttonFormularios"
						onClick={adionarResponsavel}
					>
						Adicionar
					</button>
				</div>
				<div className="col-12 col-md-12 form-group">
					<DataTable
						data={values.supervisoresEquipe}
						columnsDefinition={values.columnsDefinitionSupervisores}
						removeHandler={values.formDisabled ? null : removerResponsavel}
					/>
				</div>
			</FormularioComplementar>
			<FormularioComplementar titulo="Landing Pages">
				<div className="col-12 col-md-2">
					<div className="form-group">
						<label>UF</label>

						<Select
							isDisabled={values.formDisabled}
							isMulti={false}
							isSearchable={true}
							placeholder=""
							closeMenuOnSelect={true}
							value={formularioEquipeValues.uf}
							onChange={handleSelect2ChangeUf}
							options={[
								initialStateFomularioEquipe.uf,
								...values.ufs.map((uf) => {
									return { value: uf, label: uf };
								})
							]}
						/>
					</div>
				</div>

				<div className="col-12 col-md-4">
					<div className="form-group autocomplete">
						<label>Cidade</label>

						<Select
							isDisabled={values.formDisabled}
							isMulti={false}
							isSearchable={true}
							placeholder=""
							closeMenuOnSelect={true}
							value={formularioEquipeValues.cidade}
							onChange={handleSelect2ChangeCidade}
							options={[
								initialStateFomularioEquipe.cidade,
								...values.cidades.map((cidade) => {
									return { value: cidade.cidadeId, label: cidade.cidadeNome };
								})
							]}
						/>
					</div>
				</div>

				<div className="col-12 col-md-4 form-group">
					<label>Landing Page</label>
					<Select
						isDisabled={values.formDisabled}
						isMulti={false}
						isSearchable={true}
						placeholder=""
						closeMenuOnSelect={true}
						value={formularioEquipeValues.landingPage}
						onChange={handleSelect2ChangeLandingPage}
						options={values.landingPages.map((landingPage) => {
							return { value: landingPage.landingPageId, label: landingPage.landingPageNome };
						})}
					/>
				</div>

				<div className="col-12 col-md-2 form-group">
					<button
						disabled={!values.dataLandingPages.landingPageId}
						style={{ marginTop: '26px' }}
						type="button"
						className="btn btn-default buttonFormularios"
						onClick={handleAdionarLandingPage}
					>
						Adicionar
					</button>
				</div>
				<div className="col-12 col-md-12 form-group">
					<DataTable
						data={values.landingPagesEquipe}
						columnsDefinition={values.columnsDefinitionLandingPages}
						removeHandler={values.formDisabled ? null : removerLandingPage}
					/>
				</div>
			</FormularioComplementar>

			<FormularioComplementar titulo="Lojas Virtual">
				<div className="col-12 col-md-4 form-group">
					<label>Lojas Virtual</label>
					<select
						disabled={values.formDisabled}
						className="form-control"
						name="lojaVirtualId"
						value={values.dataLojaVirtual.lojaVirtualId}
						onChange={onChangeInputLojaVirtual}
					>
						<option className="disabled" />
						{values.lojasVirtual.map((lojaVirtual) => (
							<option key={lojaVirtual.lojaVirtualId} value={lojaVirtual.lojaVirtualId}>
								{lojaVirtual.lojaVirtualNome}
							</option>
						))}
					</select>
				</div>

				<div className="col-12 col-md-2 form-group">
					<button
						disabled={!values.dataLojaVirtual.lojaVirtualId}
						style={{ marginTop: '26px' }}
						type="button"
						className="btn btn-default buttonFormularios"
						onClick={adionarLojaVirtual}
					>
						Adicionar
					</button>
				</div>
				<div className="col-12 col-md-12 form-group">
					<DataTable
						data={values.lojasVirtualEquipe}
						columnsDefinition={values.columnsDefinitionLojasVirtual}
						removeHandler={values.formDisabled ? null : removerLojaVirtual}
					/>
				</div>
			</FormularioComplementar>

		</Fragment>
	);
};

export default FormularioEquipe;
