import React, { Fragment, useContext, useState } from "react";
import DataTable from "../../../Components/DataTable/DataTable";

const publicoColumnsDefinition = [
  {
    text: "id",
    dataField: "id",
    hidden: true,
  },
  {
    text: "baseProspectId",
    dataField: "baseProspectId",
    hidden: true,
  },
  {
    text: "Público",
    dataField: "baseProspectNome",
    headerStyle: { width: "120px" },
    sort: true,
  },
];

function initialState() {
  return {
    baseProspectId: "0",
    baseProspectNome: "",
  };
}

export const Publico = ({ context }) => {
  const { values, adicionarPublico, removerPublico } = useContext(context);
  const [publicoValues, setPublicoValues] = useState(initialState());

  function onChangeInput(event) {
    const { value, selectedIndex } = event.target;

    setPublicoValues({
      baseProspectId: value,
      baseProspectNome: event.nativeEvent.target[selectedIndex].text,
    });
  }
  function adicionar() {
    adicionarPublico(
      publicoValues.baseProspectId,
      publicoValues.baseProspectNome
    );
    setPublicoValues(initialState());
  }
  const formularioComErro = publicoValues.baseProspectId === "0";
  return (
    <Fragment>
      <div className="col-3">
        <div className="default-title">Público</div>
        <div className="card shadow-sm mb-4">
          <div className="row">
            <div className="col-10" style={{marginRight:-13}}>
              <select
                disabled={values.formDisabled}
                className="form-control"
                name="produtoId"
                value={publicoValues.baseProspectId}
                onChange={onChangeInput}
              >
                <option className="disabled" value="0"></option>
                {values.baseProspects.map((baseProspect) => (
                  <option
                    key={baseProspect.baseProspectId}
                    value={baseProspect.baseProspectId}
                  >
                    {baseProspect.baseProspectNome}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-2">
              <button
                disabled={formularioComErro}
                type="button"
                className="btn btn-success"
                onClick={adicionar}
              >
                +
              </button>
            </div>
            <div className="col-12 col-md-12 pt-2">
              <DataTable
                data={values.data.basesProspect}
                columnsDefinition={publicoColumnsDefinition}
                removeHandler={values.formDisabled ? null : removerPublico}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Publico;
