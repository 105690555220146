import React, { useEffect, useState } from "react";
import $ from "jquery";
import "./CondicaoModal.css";
import { Fragment } from "react";

let eventId = 0;

export const showCondicaoModal = (id) => {
  eventId = id;
  $("#condicaoModal").modal({
    backdrop: "static",
    keyboard: false,
    show: true,
  });
};

export const hideCondicaoModal = () => {
  eventId = 0;
  $("#condicaoModal").modal("hide");
};

const initialState = () => {
  return {
    tipo: "condicao",
    score: 0,
  };
};

const CondicaoModal = ({
  eventoCorrente,
  confirmar = (data) => { },
  cancelar = (data) => { },
  formDisabled = true,
}) => {
  const [valuesEventoCondicao, setEventoCondicao] = useState(initialState);

  const formularioComErro =
    !valuesEventoCondicao.score ||
    parseInt(valuesEventoCondicao.score) < 0 ||
    parseInt(valuesEventoCondicao.score) === 0 ||
    parseInt(valuesEventoCondicao.score) > 99999;

  useEffect(() => {
    if (eventoCorrente) {
      setEventoCondicao(eventoCorrente);
    } else {
      setEventoCondicao(initialState());
    }
  }, [eventoCorrente]);

  const confirmarHandler = () => {
    setEventoCondicao(initialState());
    confirmar({ id: eventId, ...valuesEventoCondicao });
    hideCondicaoModal();
  };

  const cancelarHandler = () => {
    hideCondicaoModal();
    cancelar({ id: eventId, ...valuesEventoCondicao });
  };

  function onChangeInput(event) {
    const { name, value } = event.target;
    setEventoCondicao({
      ...valuesEventoCondicao,
      [name]: value,
    });
  }

  return (
    <Fragment>
      <div
        className="modal fade"
        id="condicaoModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="condicaoModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="condicaoModalLabel">
                Condicão
              </h5>
            </div>
            <div className="modal-body componenteAgendaRedirecionar_modalBody">
              <div className="row align-items-center">
                <div className="col-12 form-group ">
                  <label>
                    <div className="row">
                      <div className={`col-12 text-${formDisabled ? "center" : "left"}`}>

                        {formDisabled ?
                          `Score do prospect maior ou igual a ${valuesEventoCondicao.score ? valuesEventoCondicao.score : 0}?`
                          :
                          "A condição será considerada verdadeira caso o score do prospect seja maior ou igual a:"
                        }
                      </div>
                    </div>
                  </label>
                </div>

                {formDisabled ?
                  null
                  :
                  <Fragment>
                    <div className="col-4 form-group" />
                    <div className="col-4 form-group">
                      <input
                        disabled={formDisabled}
                        className="form-control"
                        type="number"
                        maxLength="5"
                        max="99999"
                        min="0"
                        name="score"
                        value={valuesEventoCondicao.score}
                        onChange={onChangeInput}
                      />
                    </div>
                    <div className="col-4 form-group" />
                  </Fragment>
                }
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  cancelarHandler();
                }}
              >
                {formDisabled ? "Fechar" : "Cancelar"}
              </button>
              {formDisabled ? null : (
                <button
                  disabled={formularioComErro}
                  type="button"
                  className="btn btn-default"
                  onClick={confirmarHandler}
                >
                  Confirmar
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CondicaoModal;
