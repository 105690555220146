import React from "react";
import ImportarCobrancaProvider from "./ImportarCobrancaContext";
import ImportarCobranca from "./Events/ImportarCobranca";

const ImportarCobrancaIndex = () => {
  return (
    <ImportarCobrancaProvider>
      <div className="container-fluid pl-3 pt-3 pb-3">
        <ImportarCobranca />
      </div>
    </ImportarCobrancaProvider>
  );
};

export default ImportarCobrancaIndex;
