import React, { Fragment, useContext } from "react";
const images = require.context("../../../../assets/img/icones/pages", true);
const iconeRemove = images("./trash.png");

const Excluir = ({ context, disabled, arquivoId }) => {
  const { removerAnexo } = useContext(context);

  return (
    <Fragment>
      <button
        type="button"
        className="btn btn-danger btn-sm ml-1"
        onClick={() => removerAnexo(arquivoId)}
      >
        <img src={iconeRemove} alt="" />
      </button>
    </Fragment>
  );
};

export default Excluir;
