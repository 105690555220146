import React, { useContext, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { LandingPageContext } from '../LandingPageContext';
import Formulario from '../../../../../Components/Cadastro/Formulario/Formulario';

const FormularioLandingPage = () => {
	const { values, setValues, getDetailHandler, filtrarCidades } = useContext(LandingPageContext);
	let { id } = useParams();

	useEffect(() => {
		getDetailHandler(id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function onChangeUf(event) {
		filtrarCidades(event.target.value);
	}

	function onChangeInput(event) {
		const { name, value } = event.target;
		setValues({
			...values,
			data: {
				...values.data,
				[name]: value
			}
		});
	}

	return (
		<Fragment>
			<Formulario context={LandingPageContext}>
				<div className="col-12 form-group">
					<div className="row">
						<div className="col-12 col-md-2 form-group">
							<label>Código</label>
							<input
								disabled={values.formDisabled}
								className="form-control"
								type="text"
								maxLength="5"
								name="landingPageCod"
								value={values.data.landingPageCod}
								onChange={onChangeInput}
							/>
						</div>
						<div className="col-12 col-md-10 form-group">
							<label>Nome</label>
							<input
								disabled={values.formDisabled}
								className="form-control uppercase"
								type="text"
								maxLength="40"
								name="landingPageNome"
								value={values.data.landingPageNome}
								onChange={onChangeInput}
							/>
						</div>
						<div className="col-12 col-md-12 form-group">
							<label>Url</label>
							<input
								disabled={values.formDisabled}
								className="form-control uppercase"
								type="text"
								maxLength="100"
								name="landingPageUrl"
								value={values.data.landingPageUrl}
								onChange={onChangeInput}
							/>
						</div>

						<div className="col-12 col-md-2">
							<div className="form-group">
								<label>UF</label>
								<select
									className="form-control"
									disabled={values.formDisabled}
									name="uf"
									value={values.data.uf}
									onChange={onChangeUf}
								>
									<option className="disabled" />
									{values.ufs.map((uf) => (
										<option key={uf} value={uf}>
											{uf}
										</option>
									))}
								</select>
							</div>
						</div>

						<div className="col-12 col-md-4">
							<div className="form-group autocomplete">
								<label>Cidade</label>
								<select
									disabled={values.formDisabled}
									className="form-control"
									name="cidadeId"
									value={values.data.cidadeId}
									onChange={onChangeInput}
								>
									<option className="disabled" />
									{values.cidades.map((cidade) => (
										<option key={cidade.cidadeId} value={cidade.cidadeId}>
											{cidade.cidadeNome}
										</option>
									))}
								</select>
							</div>
						</div>
					</div>
				</div>
			</Formulario>
		</Fragment>
	);
};

export default FormularioLandingPage;
