import { RequestWebApi, validateRequestErrorStatus } from "./Requests";

const controller = "Cidade";
export const Listar = () =>
  RequestWebApi.get(`/${controller}/Listar`)
    .then(function (response) {
      const { resultData, success } = response.data;
      const cidades = resultData;
      return { success, cidades };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      return { cidades: [] };
    });

export const Incluir = (cidade) =>
  RequestWebApi.post(`/${controller}/Incluir`, cidade)
    .then(function (response) {
      const { resultData, success } = response.data;
      const novaCidade = resultData;
      return { success, novaCidade };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Atualizar = (cidade) =>
  RequestWebApi.put(`/${controller}/Atualizar`, cidade)
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });

export const Remover = (cidadeId) =>
  RequestWebApi.delete(`/${controller}/Remover`, {
    params: { cidadeId: cidadeId },
  })
    .then(function (response) {
      const { success } = response.data;
      return { success };
    })
    .catch(function (error) {
      validateRequestErrorStatus(error.response.status);
      const { success, message, errors } = error.response.data;
      return { success, message, errors };
    });
