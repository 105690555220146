import React, { useContext, Fragment, useEffect } from 'react'
import { ConversaoLeadContext } from '../ConversaoLeadContext'
import PesquisarRelatorio from '../../../../../Components/PesquisarRelatorio/PesquisarRelatorio'
import GroupBox from '../../../../../Components/GroupBox/GroupBox'

const PesquisarConversaoLead = () => {
  const { values, setValues, resetScreen } = useContext(ConversaoLeadContext)

  useEffect(() => {
    resetScreen()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onChangeInput(event) {
    const { name, value } = event.target
    setValues({
      ...values,
      searchData: {
        ...values.searchData,
        [name]: value,
      },
    })
  }

  const listaAnos = () => {
    return [{ descricao: "2024", value: "2024" }]
  }


  const listaMeses = () => {
    return [
      { descricao: "Janeiro", value: "1" },
      { descricao: "Fevereiro", value: "2" },
      { descricao: "Março", value: "3" },
      { descricao: "Abril", value: "4" },
      { descricao: "Maio", value: "5" },
      { descricao: "Junho", value: "6" },
      { descricao: "Julho", value: "7" },
      { descricao: "Agosto", value: "8" },
      { descricao: "Setembro", value: "9" },
      { descricao: "Outubro", value: "10" },
      { descricao: "Novembro", value: "11" },
      { descricao: "Dezembro", value: "12" },
    ]
  }

  return (
    <Fragment>
      <PesquisarRelatorio context={ConversaoLeadContext}>

        <div className="col-12 col-md-2">
          <div className="form-group">
            <label>Ano<span className="campoObratorio">*</span></label>
            <select
              className="form-control"
              name="ano"
              value={values.searchData.ano}
              onChange={onChangeInput}
            >
              <option className="disabled" />
              {listaAnos().map((ano) => (
                <option key={ano.value} value={ano.value}>
                  {ano.descricao}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-12 col-md-2">
          <div className="form-group">
            <label>Mês<span className="campoObratorio">*</span></label>
            <select
              className="form-control"
              name="mes"
              value={values.searchData.mes}
              onChange={onChangeInput}
            >
              <option className="disabled" />
              {listaMeses().map((ano) => (
                <option key={ano.value} value={ano.value}>
                  {ano.descricao}
                </option>
              ))}
            </select>
          </div>
        </div>


      </PesquisarRelatorio>
    </Fragment>
  )
}

export default PesquisarConversaoLead
