import React, { useContext, Fragment, useEffect } from "react";
import { BaseProspectContext } from "../BaseProspectContext";
import Pesquisar from "../../../../../Components/Cadastro/Pesquisar/Pesquisar";
import TabelaResultados from "../../../../../Components/Cadastro/TabelaResultados/TabelaResultados";

const PesquisarBaseProspect = () => {
  const { values, setValues, resetScreen } = useContext(BaseProspectContext);

  useEffect(() => {
    resetScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      searchData: {
        ...values.searchData,
        [name]: value,
      },
    });
  }

  return (
    <Fragment>
      <Pesquisar context={BaseProspectContext}>
        <div className="col-12 col-md-4 form-group">
          <label>Nome da Base</label>
          <input
            className="form-control"
            type="text"
            name="baseProspectNome"
            value={values.searchData.baseProspectNome}
            onChange={onChangeInput}
          />
        </div>

        <div className="col-12 col-md-3 form-group">
          <label>Produto</label>
          <select
            className="form-control"
            name="produtoId"
            value={values.searchData.produtoId}
            onChange={onChangeInput}
          >
            <option className="disabled" value="0"></option>
            {values.produtos.map((produto) => (
              <option key={produto.produtoId} value={produto.produtoId}>
                {produto.produtoNome}
              </option>
            ))}
          </select>
        </div>

        <div className="col-12 col-md-3 form-group">
          <label>Status</label>
          <select
            className="form-control"
            name="baseProspectStatus"
            value={values.searchData.baseProspectStatus}
            onChange={onChangeInput}
          >
            <option className="disabled" value=""></option>
            {values.statusList.map((status) => (
              <option key={status.key} value={status.key}>
                {status.value}
              </option>
            ))}
          </select>
        </div>
      </Pesquisar>
      <TabelaResultados context={BaseProspectContext} />
    </Fragment>
  );
};

export default PesquisarBaseProspect;
