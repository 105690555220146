import React, { useContext, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { FeriadoContext } from "../FeriadoContext";
import Formulario from "../../../../../Components/Cadastro/Formulario/Formulario";

const FormularioProduto = () => {
  const { values, setValues, getDetailHandler } = useContext(FeriadoContext);
  let { id } = useParams();

  useEffect(() => {
    getDetailHandler(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      data: {
        ...values.data,
        [name]: value,
      },
    });
  }

  return (
    <Fragment>
      <Formulario context={FeriadoContext}>
        <div className="col-12 col-md-3 form-group">
          <label>Data</label>
          <input
            disabled={values.formDisabled}
            className="form-control"
            type="date"
            maxLength="10"
            name="feriadoData"
            value={values.data.feriadoData}
            onChange={onChangeInput}
          />
        </div>
        <div className="col-12 col-md-5 form-group">
          <label>Nome do Feriado</label>
          <input
            disabled={values.formDisabled}
            className="form-control uppercase"
            type="text"
            maxLength="50"
            name="feriadoDescricao"
            value={values.data.feriadoDescricao}
            onChange={onChangeInput}
          />
        </div>
      </Formulario>
    </Fragment>
  );
};

export default FormularioProduto;
