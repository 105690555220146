import React, { Fragment, useContext } from "react";

const _incluir = "incluir";
const _editar = "editar";
const _excluir = "excluir";

const PermissoesItem = ({
  id,
  nome,
  incluir = false,
  editar = false,
  excluir = false,
  context,
}) => {
  const { values, definirPermissao } = useContext(context);

  function onChangeCheck(event) {
    const { name, checked } = event.target;
    definirPermissao(id, name, checked);
  }

  function onChangeCheckAll(event) {
    const { checked } = event.target;
    definirPermissao(id, _incluir, checked);
    definirPermissao(id, _editar, checked);
    definirPermissao(id, _excluir, checked);
  }

  return (
    <Fragment>
      <li className="cd-accordion__item">
        <a
          className="cd-accordion__label cd-accordion__label--icon-img cd-accordion__label--icon-noimg"
          href="#0"
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-6 pl-10">
                <div className="form-check">
                  <input
                    disabled={values.formDisabled}
                    className="form-check-input mult_Level_check_box"
                    type="checkbox"
                    id={`defaultCheck${id}`}
                    checked={incluir || editar || excluir}
                    onChange={onChangeCheckAll}
                  />
                  <label
                    className="form-check-label text-white"
                    htmlFor={`defaultCheck${id}`}
                  >
                    {nome}
                  </label>
                </div>
              </div>
              <div className="col-12 col-md-6 text-right">
                <div className="form-check form-check-inline">
                  <input
                    disabled={values.formDisabled}
                    className="form-check-input mult_Level_check_box"
                    name={_incluir}
                    type="checkbox"
                    id={`${_incluir}_Item_${id}`}
                    onChange={onChangeCheck}
                    checked={incluir}
                  />
                  <label
                    className="form-check-label text-white"
                    htmlFor={`${_incluir}_Item_${id}`}
                  >
                    Incluir
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    disabled={values.formDisabled}
                    className="form-check-input mult_Level_check_box"
                    name={_excluir}
                    type="checkbox"
                    id={`${_excluir}_Item_${id}`}
                    checked={excluir}
                    onChange={onChangeCheck}
                  />
                  <label
                    className="form-check-label text-white"
                    htmlFor={`${_excluir}_Item_${id}`}
                  >
                    Excluir
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    disabled={values.formDisabled}
                    className="form-check-input mult_Level_check_box"
                    name={_editar}
                    type="checkbox"
                    id={`${_editar}_Item_${id}`}
                    checked={editar}
                    onChange={onChangeCheck}
                  />
                  <label
                    className="form-check-label text-white"
                    htmlFor={`${_editar}_Item_${id}`}
                  >
                    Editar
                  </label>
                </div>
              </div>
            </div>
          </div>
        </a>
      </li>
    </Fragment>
  );
};

export default PermissoesItem;
