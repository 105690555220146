import React, { useContext, Fragment } from "react";
import { GrupoUsuarioContext } from "../GrupoUsuarioContext";
import Pesquisar from "../../../../../Components/Cadastro/Pesquisar/Pesquisar";
import TabelaResultados from "../../../../../Components/Cadastro/TabelaResultados/TabelaResultados";

const PesquisarGrupoUsuario = () => {
  const { values, setValues } = useContext(GrupoUsuarioContext);
  function onChangeInput(event) {
    const { name, value } = event.target;
    setValues({
      ...values,
      searchData: {
        ...values.searchData,
        [name]: value,
      },
    });
  }

  return (
    <Fragment>
      <Pesquisar context={GrupoUsuarioContext}>
        <div className="col-12 col-md-3">
          <label>Nome do Perfil</label>
          <input
            className="form-control"
            type="text"
            name="grupoUsuarioNome"
            value={values.searchData.grupoUsuarioNome}
            onChange={onChangeInput}
          />
        </div>
      </Pesquisar>
      <TabelaResultados context={GrupoUsuarioContext} />
    </Fragment>
  );
};

export default PesquisarGrupoUsuario;
