import React from "react";
import { Fragment } from "react";
import PermissoesRelatoriosItem from "./PermissoesRelatoriosItem/PermissoesRelatoriosItem";

const PermissoesRelatorios = ({ permissoes = [], checkItemChange = () => { }, formDisabled = false }) => {
  return (
    <Fragment>
      {permissoes.length > 0 ? (
        <ul className="cd-accordion cd-accordion--animated">
          {permissoes.map((menu) => (
            <li
              key={menu.relatorioModulo}
              className="cd-accordion__item cd-accordion__item--has-children"
            >
              <input
                className="cd-accordion__input"
                type="checkbox"
                name={`modulo-${menu.relatorioModulo}`}
                id={`modulo-${menu.relatorioModulo}`}
              />
              <label
                className="cd-accordion__label cd-accordion__label--icon-folder"
                htmlFor={`modulo-${menu.relatorioModulo}`}
              >
                <span>{menu.relatorioModulo}</span>
              </label>
              <ul className="cd-accordion__sub cd-accordion__sub--l1">
                {menu.relatorios.map((relatorio) =>
                  <PermissoesRelatoriosItem
                    key={relatorio.relatorioId}
                    id={relatorio.relatorioId}
                    nome={relatorio.relatorioNome}
                    modulo={menu.relatorioModulo}
                    checked={relatorio.temPermissao}
                    formDisabled={formDisabled}
                    checkItemChange={checkItemChange}
                  />
                )}
              </ul>
            </li>
          ))}
        </ul>
      ) : null}
    </Fragment>
  );
};

export default PermissoesRelatorios;
