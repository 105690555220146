import $ from 'jquery';
import React, { Fragment, useContext, useEffect, useState } from "react";
import DataTable from "../../../Components/DataTable/DataTable";
import { DetalhesVenda, DetalhesVendaComDependencias, ListarVendaPorCliente } from '../../../Services/Venda';
import AppContext from '../../../Store/AppContext';
import "./PesquisaVenda.css";

const columnsDefinition = [
  {
    text: 'Id',
    dataField: 'id',
    hidden: true
  },
  {
    text: 'Cliente',
    dataField: 'clienteNome',
    headerStyle: { width: '200px' },
    sort: true
  },
  {
    text: 'Data Venda',
    dataField: 'vendaDataHora',
    headerStyle: { width: '100px' },
    sort: true
  },
  {
    text: 'Nº Proposta',
    dataField: 'apoliceNumProposta',
    headerStyle: { width: '100px' },
    sort: true
  },
  {
    text: 'Data Proposta',
    dataField: 'apoliceDataProposta',
    headerStyle: { width: '100px' },
    sort: true
  }
];

const searchData = {
  clienteNome: '',
  clienteDocumento: '',
  clienteContato: ''
};

function initialState() {
  return {
    searchData: searchData,
    resultSearch: [],
    venda: {},
    columnsDefinition: columnsDefinition,
  };
}
export const hideModal = (target) => {
  $("#modalPesquisaVendaAtendimento").modal('hide');
};

const PesquisaVenda = ({ vendaId = 0, disabled = false, callBack = () => { } }) => {
  const { showLoading, hideLoading } = useContext(AppContext);
  const [pesquisaVendaValues, setPesquisaVendaValues] = useState(initialState());

  useEffect(() => {
    async function BuscarDetalhesVenda() {
      const responseDetalhesVenda = await DetalhesVenda(vendaId);
      if (!responseDetalhesVenda.success) {
        return;
      } else {
        let venda = responseDetalhesVenda.venda;
        setPesquisaVendaValues({
          ...pesquisaVendaValues,
          venda: venda
        });
      }
    }
    if (vendaId > 0) {
      BuscarDetalhesVenda();
    }
  }, [vendaId]);

  function onChangeInput(event) {
    const { name, value } = event.target;

    setPesquisaVendaValues({
      ...pesquisaVendaValues,
      searchData: {
        ...pesquisaVendaValues.searchData,
        [name]: value
      }
    });
  }

  async function onSearchHandler() {
    showLoading();
    const responseListarVendaPorCliente = await ListarVendaPorCliente(pesquisaVendaValues.searchData);
    let vendas = responseListarVendaPorCliente.vendas;
    vendas.map(x => x.id = x.vendaId);
    if (responseListarVendaPorCliente.success) {
      setPesquisaVendaValues({
        ...pesquisaVendaValues,
        resultSearch: vendas
      });
    }
    hideLoading();
  }



  async function onSelectHandler(data) {
    hideModal();
    showLoading();

    const responseDetalhesVendaComDependencias = await DetalhesVendaComDependencias(data);
    let vendaComDependencias = responseDetalhesVendaComDependencias.vendaComDependencias;

    callBack(vendaComDependencias);
    hideLoading();
  }

  const formularioComErro = (!pesquisaVendaValues.searchData.clienteNome || pesquisaVendaValues.searchData.clienteNome.length < 3)
    && (!pesquisaVendaValues.searchData.clienteDocumento || pesquisaVendaValues.searchData.clienteDocumento.length < 3)
    && (!pesquisaVendaValues.searchData.clienteContato || pesquisaVendaValues.searchData.clienteContato.length < 3)

  return (
    <Fragment>
      <div className="row compomentePesquisaVendaBorda">
        {vendaId > 0 ?
          <Fragment>
            <div className="col-12 col-md-2 pt-1">
              <label>Venda: {pesquisaVendaValues.venda.vendaId}</label>
            </div>
            <div className="col-12 col-md-3 pt-1">
              <label>Data Venda: {pesquisaVendaValues.venda.vendaDataHora}</label>
            </div>
          </Fragment>
          : null}
        <div className="col-12 col-md-3">
          <button
            disabled={disabled}
            type="button"
            className={`btn  ${vendaId > 0 ? "btn-info" : "btn-default"}`}
            data-toggle="modal"
            data-target={`#modalPesquisaVendaAtendimento`}
            data-dismiss="modal"
          >
            {vendaId > 0 ? "Vincular outra Venda" : "Vincular Venda"}
          </button>
        </div>
      </div>

      <div
        className="modal fade"
        id={`modalPesquisaVendaAtendimento`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby={`modalPesquisaVendaAtendimentoLabel`}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id={`modalPesquisaVendaAtendimentoLabel`}
              >
                Vincular Venda
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body componenteAtendimentoPesquisaVenda_modalBody">
              <div className="row">
                <div className="col-12 col-md-3">
                  <label>Nome Cliente</label>
                  <input
                    className="form-control"
                    type="text"
                    name="clienteNome"
                    value={pesquisaVendaValues.searchData.clienteNome}
                    onChange={onChangeInput}
                  />
                </div>
                <div className="col-12 col-md-3">
                  <label>Documento Cliente</label>
                  <input
                    className="form-control"
                    type="text"
                    name="clienteDocumento"
                    value={pesquisaVendaValues.searchData.clienteDocumento}
                    onChange={onChangeInput}
                  />
                </div>
                <div className="col-12 col-md-3">
                  <label>Contato Cliente</label>
                  <input
                    className="form-control"
                    type="text"
                    name="clienteContato"
                    value={pesquisaVendaValues.searchData.clienteContato}
                    onChange={onChangeInput}
                  />
                </div>
              </div>

              <div className="col-12 mb-3 text-right">
                <hr />
                <button
                  disabled={formularioComErro}
                  type="button"
                  className="btn btn-success"
                  onClick={onSearchHandler}
                >
                  Buscar
                </button>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <DataTable columnsDefinition={columnsDefinition}
                    data={pesquisaVendaValues.resultSearch}
                    selectHandler={onSelectHandler} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PesquisaVenda;
