export const retornarDescricaoJornada = (semana) => {
  if (
    semana.segunda.checked &&
    semana.terca.checked &&
    semana.quarta.checked &&
    semana.quinta.checked &&
    semana.sexta.checked &&
    !semana.sabado.checked &&
    !semana.domingo.checked
  ) {
    return "Trabalha de Segunda-Feira a Sexta-Feira";
  }

  if (
    semana.segunda.checked &&
    semana.terca.checked &&
    semana.quarta.checked &&
    semana.quinta.checked &&
    semana.sexta.checked &&
    semana.sabado.checked &&
    !semana.domingo.checked
  ) {
    return "Trabalha de Segunda-Feira a Sabado";
  }

  if (
    semana.segunda.checked &&
    semana.terca.checked &&
    semana.quarta.checked &&
    semana.quinta.checked &&
    semana.sexta.checked &&
    semana.sabado.checked &&
    semana.domingo.checked
  ) {
    return "Todos os dias";
  }

  if (
    !semana.segunda.checked &&
    !semana.terca.checked &&
    !semana.quarta.checked &&
    !semana.quinta.checked &&
    !semana.sexta.checked &&
    semana.sabado.checked &&
    semana.domingo.checked
  ) {
    return "Sabado e Domingo";
  }

  let dias = [];
  if (semana.segunda.checked) {
    dias.push("Segunda-Feira ");
  }
  if (semana.terca.checked) {
    dias.push("Terça-Feira");
  }
  if (semana.quarta.checked) {
    dias.push("Quarta-Feira");
  }
  if (semana.quinta.checked) {
    dias.push("Quinta-Feira");
  }
  if (semana.sexta.checked) {
    dias.push("Sexta-Feira");
  }
  if (semana.sabado.checked) {
    dias.push("Sabado");
  }
  if (semana.domingo.checked) {
    dias.push("Domingo");
  }

  let descricaoDias = dias.join(",");

  return descricaoDias || "Não foi nenhum dia para essa jornada.";
};
