import React, { useContext } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoutes';
import PagesLoginIndex from '../Pages/Login/Index';
import PagamentoCartaoIndex from '../Pages/PagamentoCartao/Index';
import PagesModulosIndex from '../Pages/Modulos/Index';
import PagesMasterPage from '../Pages/MasterPage/MasterPage';
import AppContext from '../Store/AppContext';
import Loading from '../Components/Loading/Loading';

const Routes = () => {
	const { loading, message } = useContext(AppContext);
	return (
		<BrowserRouter>
			<Switch>
				<Route exact path="/" component={PagesLoginIndex} />
				<Route exact path="/Pagamento/Cartao" component={PagamentoCartaoIndex} />
				<PrivateRoute exact path="/Modulos" component={PagesModulosIndex} />
				<PrivateRoute path="/:modulo" component={PagesMasterPage} />
			</Switch>
			<Loading loading={loading} message={message} />
		</BrowserRouter>
	);
};

export default Routes;
