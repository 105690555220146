import React, { Fragment } from "react";
import GroupBox from "../../../GroupBox/GroupBox";
import DetalhesLeadAuto from "../DetalhesLead/DetalhesLeadAuto";

const AtendimentoRealizado = ({
  defineDetalhesObjeto = () => { },
  atendimento = {},
  leadAuto = null,
}) => {
  return (
    <Fragment>

      {atendimento.atendimentoId ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Protoc. Atendimento:{" "}
            <span role="button"
              className="font-weight-normal link_primary"
              onClick={() => defineDetalhesObjeto("A", atendimento.atendimentoId)} >
              {atendimento.atendimentoId}
            </span>
          </div>
        </div>
      ) : null}

      {atendimento.vendaId ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Prot. Venda:{" "}
            <span role="button"
              className="font-weight-normal link_primary"
              onClick={() => defineDetalhesObjeto("V", atendimento.vendaId)} >
              {atendimento.vendaId}
            </span>
          </div>
        </div>
      ) : null}

      {atendimento.filaAtendimentoDataHora ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Data/Hora Distribuição:{" "}
            <span className="font-weight-normal">
              {atendimento.filaAtendimentoDataHora}
            </span>
          </div>
        </div>
      ) : null}

      {atendimento.usuarioNome ? (
        <div className="col-md-5">
          <div className="font-weight-bold">
            Consultor:{" "}
            <span className="font-weight-normal">
              {atendimento.usuarioNome}
            </span>
          </div>
        </div>
      ) : null}

      {atendimento.atendimentoDataHora ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Data/Hora Atendimento:{" "}
            <span className="font-weight-normal">
              {atendimento.atendimentoDataHora}
            </span>
          </div>
        </div>
      ) : null}

      {atendimento.atendimentoTipoDescricao ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Tipo Atendimento:{" "}
            <span className="font-weight-normal">
              {atendimento.atendimentoTipoDescricao}
            </span>
          </div>
        </div>
      ) : null}

      {atendimento.tipoContatoDescricao ? (
        <div className="col-md-5">
          <div className="font-weight-bold">
            Tipo de Contato:{" "}
            <span className="font-weight-normal">
              {atendimento.tipoContatoDescricao}
            </span>
          </div>
        </div>
      ) : null}

      {atendimento.motivoPerdaDescricao ? (
        <div className="col-md-5">
          <div className="font-weight-bold">
            Motivo da Perda:{" "}
            <span className="font-weight-normal">
              {atendimento.motivoPerdaDescricao}
            </span>
          </div>
        </div>
      ) : null}

      {atendimento.tipoNegociacaoDescricao ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Tipo Negociação:{" "}
            <span className="font-weight-normal">
              {atendimento.tipoNegociacaoDescricao}
            </span>
          </div>
        </div>
      ) : null}

      {atendimento.atendimentoDataVencimentoApolice ? (
        <div className="col-md-3">
          <div className="font-weight-bold">
            Data Venc. Apólice:{" "}
            <span className="font-weight-normal">
              {atendimento.atendimentoDataVencimentoApolice}
            </span>
          </div>
        </div>
      ) : null}

      {atendimento.atendimentoObservacao ? (

        <div className="col-md-12">
          <div className="font-weight-bold">
            Observações Atendimento:{" "}
            <br />
            <textarea
              disabled={true}
              className="form-control"
              type="text"
              rows="4"
              maxLength="1000"
              name="atendimentoObservacao"
              value={atendimento.atendimentoObservacao}
            />
          </div>
        </div>
      ) : null}

      {leadAuto ? (
        <GroupBox titulo="Auto" className="col-12">
          <div className="col-md-12">
            <DetalhesLeadAuto leadAuto={leadAuto} />
          </div>
        </GroupBox>
      ) : null}


    </Fragment >
  );
};

export default AtendimentoRealizado;
