import React, { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import './AlterarStatusCobranca.css';
import { ListarListBoxPorRotulo } from '../../../../../Services/ListBox';
import {
	AlterarStatusCobranca as AlterarStatus
} from '../../../../../Services/Cobranca';

import AppContext from '../../../../../Store/AppContext';
import { Fragment } from 'react';
let idComponente = '';

const defaultData = {
	cobrancaId: null,
	cobrancaStatus: null,
};
function initialState() {
	return {
		data: defaultData,
		statusCobrancaList: []
	};
}


const AlterarStatusCobranca = ({ cobrancaId = 0, cobrancaStatus = null, children, disabled = false, confirmarHandlerCallBack = () => { } }) => {
	idComponente = cobrancaId;
	const { showLoading, hideLoading, showMessage } = useContext(AppContext);
	const [valuesAlterarStatusCobranca, setValuesAlterarStatusCobranca] = useState(initialState());

	useEffect(() => {
		async function ListarCobrancas() {
			showLoading();
			const responseListarListBoxPorRotuloStatusCobranca = await ListarListBoxPorRotulo('StatusCobranca');

			if (!responseListarListBoxPorRotuloStatusCobranca.success) {
				showMessage('Erro', 'Erro Inesperado durante a pesquisa.');
				hideLoading();
				return;
			}

			setValuesAlterarStatusCobranca({
				...valuesAlterarStatusCobranca,
				statusCobrancaList: responseListarListBoxPorRotuloStatusCobranca.items,
				data: {
					...valuesAlterarStatusCobranca.data,
					cobrancaId,
					cobrancaStatus,
				},

			});
		}

		if (valuesAlterarStatusCobranca.statusCobrancaList.length === 0) {
			ListarCobrancas();
		}

		hideLoading();
	}, [valuesAlterarStatusCobranca.statusCobrancaList]);

	function onChangeInput(event) {
		const { name, value } = event.target;
		setValuesAlterarStatusCobranca({
			...valuesAlterarStatusCobranca,
			data: {
				...valuesAlterarStatusCobranca.data,
				[name]: value,
			},
		});
	}

	const alterarStatusCobrancaHandler = async () => {
		showLoading();
		const responseIncluir = await AlterarStatus(valuesAlterarStatusCobranca.data);
		if (!responseIncluir.success) {
			hideLoading();
			showMessage('Erro', `Erro ao alterar o status: ${responseIncluir.message}`);
			responseIncluir.errors.map((erro) => showMessage('Erro', erro.errorMessage));
			return;
		}
		hideLoading();
		showMessage('Sucesso', 'Status Alteardo.');
		confirmarHandlerCallBack();
	};
	const formularioComErro = valuesAlterarStatusCobranca.data.cobrancaStatus === cobrancaStatus;
	return (
		<Fragment>
			<div data-toggle="modal" data-target={`#modalAlterarStatusCobranca${idComponente}`} data-dismiss="modal">
				{children ? children :
					<button
						type="button"
						className="btn btn-primary btn-sm ">
						<FontAwesomeIcon icon={faExchangeAlt} />
					</button>}
			</div>

			<div
				className="modal fade"
				data-backdrop="static"
				data-keyboard={false}
				id={`modalAlterarStatusCobranca${idComponente}`}
				tabIndex="-1"
				role="dialog"
				aria-labelledby={`modalAlterarStatusCobranca${idComponente}Label`}
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-sm">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id={`modalAlterarStatusCobranca${idComponente}Label`}>
								Alterar Status Cobrança
							</h5>
						</div>

						<div className="modal-body modalAlterarStatusCobranca_modalBody">

							<div className="col-12 col-md-12 form-group">
								<label>Status</label>
								<select
									className="form-control"
									name="cobrancaStatus"
									value={valuesAlterarStatusCobranca.data.cobrancaStatus}
									onChange={onChangeInput}
								>
									<option className="disabled" value=""></option>
									{valuesAlterarStatusCobranca.statusCobrancaList.map((statusCobranca) => (
										<option key={statusCobranca.codigo} value={statusCobranca.codigo}>
											{statusCobranca.descricao}
										</option>
									))}
								</select>
							</div>
						</div>

						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-secondary"
								data-dismiss="modal"
							>
								{disabled ? "Fechar" : "Cancelar"}
							</button>
							{
								disabled ? null : <button
									type="button"
									disabled={formularioComErro}
									className="btn btn-default"
									data-dismiss="modal"
									onClick={alterarStatusCobrancaHandler}
								>
									Confirmar
								</button>

							}

						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default AlterarStatusCobranca;
