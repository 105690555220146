/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext } from "react";
import AppContext from "../../../../Store/AppContext";
import {
  Listar as ListarLojaVirtual,
  Detalhes as DetalhesLojaVirtual,
  Remover as RemoverLojaVirtual,
  Incluir as IncluirLojaVirtual,
  Atualizar as AtualizarLojaVirtual,
} from "../../../../Services/LojaVirtual";
import { useHistory } from "react-router-dom";
import { validarPermissoesPagina } from "../../../../Utils/validarPermissoesPagina";
import { ListarListBoxPorRotulo } from '../../../../Services/ListBox';
export const LojaVirtualContext = createContext();

const tituloTela = "Loja Virtual";

const defaultData = {
  lojaVirtualId: 0,
  lojaVirtualCod: '',
  lojaVirtualNome: '',
  lojaVirtualStatus: '',
  lojaVirtualEmail: '',
  lojaVirtualSenha: '',
  lojaVirtualPercComissao: 0,
  lojaVirtualImagem: '',
  lojaVirtualTipoDocumento: '',
  lojaVirtualNumDocumento: '',
  lojaVirtualDddCelular: '',
  lojaVirtualNumCelular: '',
  lojaVirtualEndPix: '',
  lojaVirtualObservacao: ''
};

const searchData = {
  lojaVirtualNome: "",
};

const columnsDefinition = [
  {
    text: "Id",
    dataField: "lojaVirtualId",
    hidden: true,
  },
  {
    text: "Nome",
    dataField: "lojaVirtualNome",
    headerStyle: { width: "250px" },
    sort: true,
  },
  {
    text: "Email",
    dataField: "lojaVirtualEmail",
    headerStyle: { width: "200px" },
    sort: true,
  },
  {
    text: "Status",
    dataField: "lojaVirtualStatus",
    headerStyle: { width: "80px" },
    sort: true,
    formatter: (cell, row) => {
      return cell === "A" ? (
        <span className="badge badge-success">Ativo</span>
      ) : (
        <span className="badge badge-danger">Inátivo</span>
      );
    },
  },
];

function initialState() {
  return {
    searchData: searchData,
    resultSearch: [],
    columnsDefinition: columnsDefinition,
    data: defaultData,
    tiposDocumento: [],
    formDisabled: true,
  };
}

const LojaVirtualProvider = ({ children }) => {
  const { showLoading, hideLoading, showMessage, setTituloTela } = useContext(
    AppContext
  );
  const [values, setValues] = useState(initialState());
  let history = useHistory();

  async function loadDependences() {
    const permissoesPagina = validarPermissoesPagina();
    const responseListarLojaVirtualTipoDocumento = await ListarListBoxPorRotulo('LojaVirtualTipoDoc');

    return {
      permissoesPagina,
      tiposDocumento: await responseListarLojaVirtualTipoDocumento.items
    };
  }

  const resetScreen = () => {
    showLoading();
    async function carregarDependencias() {
      const dependencias = await loadDependences();
      setTituloTela(tituloTela);
      setValues({
        ...initialState(),
        permissoesPagina: await dependencias.permissoesPagina,
        tiposDocumento: await dependencias.tiposDocumento,
      });
      hideLoading();
    }
    carregarDependencias();
  };

  const searchHandler = async () => {
    showLoading();
    const responseListarLojaVirtual = await ListarLojaVirtual(
      values.searchData
    );
    if (!responseListarLojaVirtual.success) {
      showMessage("Erro", "Erro Inesperado durante a pesquisa.");
      hideLoading();
      return;
    }

    const resultSearchData = await responseListarLojaVirtual.lojasVirtual;
    resultSearchData.map((data) => (data.Id = data.lojaVirtualId));

    setValues({
      ...values,
      resultSearch: resultSearchData,
    });

    if (resultSearchData.length === 0) {
      showMessage("Alerta", "Nenhum registro encontrado.");
    }
    hideLoading();
  };

  const removeHandler = async (id) => {
    showLoading();

    const responseRemover = await RemoverLojaVirtual(id);

    if (!responseRemover.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro durante a exclusão: ${responseRemover.message}`
      );
      return;
    }
    searchHandler();
    hideLoading();
    showMessage("Sucesso", "Registro excluido.");
  };

  const getDetailHandler = async (id) => {
    showLoading();
    const dependencias = await loadDependences();

    if (id > 0) {
      const responseDetalhes = await DetalhesLojaVirtual(id);

      const { success, lojaVirtual } = await responseDetalhes;

      if (!success) {
        showMessage("Erro", "Erro ao buscar os detalhes do registro.");
        setValues({
          ...values,
          data: defaultData,
          permissoesPagina: await dependencias.permissoesPagina,
          tiposDocumento: await dependencias.tiposDocumento,
        });
      }

      setValues({
        ...values,
        data: lojaVirtual,
        permissoesPagina: await dependencias.permissoesPagina,
        tiposDocumento: await dependencias.tiposDocumento,
      });
    } else {
      setValues({
        ...values,
        data: {
          ...defaultData,
          lojaVirtualId: 0,
        },
        permissoesPagina: await dependencias.permissoesPagina,
        tiposDocumento: await dependencias.tiposDocumento,
        formDisabled: false,
      });
    }
    hideLoading();
  };

  const createData = async () => {
    showLoading();
    const responseIncluir = await IncluirLojaVirtual(values.data);
    if (!responseIncluir.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao criar o registro: ${responseIncluir.message}`
      );
      responseIncluir.errors.map((erro) =>
        showMessage("Erro", erro.errorMessage)
      );
      return;
    }

    resetScreen();
    hideLoading();
    showMessage("Sucesso", "Registro criado.");
    history.goBack();
  };

  const editHandler = () => {
    setValues({
      ...values,
      formDisabled: false,
    });
  };

  const updateData = async () => {
    showLoading();
    const responseAtualizar = await AtualizarLojaVirtual(values.data);
    if (!responseAtualizar.success) {
      hideLoading();
      showMessage(
        "Erro",
        `Erro ao atualizar o registro: ${responseAtualizar.message}`
      );
      return;
    }
    resetScreen();
    hideLoading();
    showMessage("Sucesso", "Registro atualizado.");
    history.goBack();
  };

  const saveHandler = async () => {
    if (values.data.lojaVirtualId === 0) {
      await createData();
    } else {
      await updateData();
    }
  };

  const cancelHandler = () => {
    setValues({
      ...values,
      data: defaultData,
      formDisabled: true,
    });
  };

  return (
    <LojaVirtualContext.Provider
      value={{
        values,
        setValues,
        resetScreen,
        searchHandler,
        cancelHandler,
        removeHandler,
        saveHandler,
        getDetailHandler,
        editHandler,
      }}
    >
      {children}
    </LojaVirtualContext.Provider>
  );
};

export default LojaVirtualProvider;
