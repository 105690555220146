import React,{ useEffect } from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRoute from "../../../../Routes/PrivateRoutes";
import UsuarioProvider from "./UsuarioContext";
import PesquisarUsuario from "./Events/PesquisarUsuario";
import FormularioUsuario from "./Events/FormularioUsuario";
import { LoadMask } from "../../../../Utils/mask";

const Index = () => {
  let match = useRouteMatch();
  useEffect(() => {
    LoadMask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <UsuarioProvider>
      <div className="container-fluid pl-3 pt-3 pb-3">
        <Switch>
          <PrivateRoute
            exact
            path={`${match.url}`}
            component={PesquisarUsuario}
          />
          <PrivateRoute
            path={`${match.url}/:id`}
            component={FormularioUsuario}
          />
        </Switch>
      </div>
    </UsuarioProvider>
  );
};

export default Index;
