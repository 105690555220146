import React from "react";
import Resultado from "./Resultado";

const TabelaResultado = ({ resultadosImportacao }) => {
  return (
    <div className="col-12">
      <table
        id="tableImport"
        className="table table-striped table-hover dataTable"
      >
        <thead>
          <tr>
            <th>Linha</th>
            <th>Identificador</th>
            <th>Status</th>
            <th>Mensagem</th>
          </tr>
        </thead>
        <tbody>
          {resultadosImportacao.map((resultadoImportacao) => (
            <Resultado
              key={resultadoImportacao.linha}
              linha={resultadoImportacao.linha}
              identificador={resultadoImportacao.identificador}
              sucesso={resultadoImportacao.sucesso}
              mensagem={resultadoImportacao.mensagem}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TabelaResultado;
