import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import './Cotacoes.css';
import { formatarMoeda } from '../../../../../../Utils/globalFunctions';
const imagemDefault =
	'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAS4AAACnCAMAAACYVkHVAAAAJ1BMVEX09PTa2trc3Nz29vbj4+Pg4ODv7+/Y2Njy8vLt7e3q6urm5ubn5+eHk7pVAAAEMklEQVR4nO2ci46jMAxFSZw3/P/3ru0QFtoppSPtUsn3rDSLmFaCIycxjplpAgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAPxzSLj7Ir4b8UPdU6lL8MvdF/TtiKjSQnQ5O+cClbsv6OvQEddDKrEo7xTPuAhdeySUivgqqc5xmBq6+MfdF/hVSFxRSYuEVGY93j/ocunuS/wCxujjsTeLKOe3aFJlfMKHJfDJXK2vjUVNldpCl7SLJvXlY2hJBmiSs4vlXGJM596tpvxel+OQSrt0S05Gk7aKGuhjT91sAbUeSUhNx7SUIv8i3nXFt8KiJKTyELVN5jz2ZhY1PSfwNMnk5S0mEhT6JL4be1kjqhZN4Pkj5dELURO5xeBopPnvbJXV1CKz+ekzIesSu8mirjrCSseemprerXmUOCDdbFAXJwWsK3ZR178VOXXdL4125jGJrjBUlbKWHd4QZSXYn7j3Hv4fPSn48HYpcHTtl8ZkJbxUlx/B5a+yJWcrVuZ9muXOR1LgfosZXVXutkLXNahwQp9HKfmQ20PXT4igsB6LrvgZIZrSJXO9H7VR0RXW1OBSRkHr5GdHVxBdayIgh+GTO2dhwZSuadHb7cerrpKuMpnTlTLPXm3U/USXrJZZGf+/pE7WdBWpR8xHXb6vkMctjWe8QV381Ohz/FlXCKfpg0ldUl5Yl8ajrkWWvvkkvizq4kyAh1xfGg+65t4XMUPXHi0l57407nR5N61Pkg+Zvl//GdXFSyPfb9PDva5RpiB/mO99f1Cyq0sfrHty+l4X/6ZuFWuTuki2GPu24WEw1j531d1glNJW5QefmvvOtkldum2oh4ep3nddx+abntDyfGdVF1GT7F3n9aMu30ppx4nLz9p2UihY1aVL41ohfEhTnxKHv8/fFMzqKnLDywVduz0Q0iK/RV0TbSbe6OLPbNGl+yA2dUlPiS6Np7rysYuEik1dWrLqj0Gnuh57biiZHIxEC1vSx6DXurzLz92oNVdz5cHRVyIJ1Ul0/dSMSnMzqEtmbacVwidd+cyWtv4a1CUVQu2oOejiTCGUIM3O2buXjc4GdfFTo1QIy0N0xUaFmqajy0sdFnVJhdCnH8qD8irLItu2L1tsLOpqWvKS9F51ScEwbsOP0nzSj2RRl75YMG+6qne7zdly2rRrUBcvjU4rhCO6fDv00Z991aIubbmMI7okni7fvkVda1/J0PXcS3/yVXu6aJr1DdjwcUuJSV3T1NzY3IGuCyS3vbf4WcOSSV2l9N0x+Rnef3yHxUdsJm4t4D58grVmyw6F0Goqr2r0J/QvGNM19TenPnW1YU1Xz7Sg6yL9di+/5PII/kwCeMsv/1JLmSy+aQwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABjiD11yHkXgwpzGAAAAAElFTkSuQmCC';

const Cotacoes = ({
	cotacoes,
	cotacaoSelecionada,
	selecionarCotacao = () => { },
	selecionarDetalhesCotacaoComErro = () => { },
	alterarComissao = (event, codigoCobertura) => { }
}) => {
	function comissaoOnChange(event, cotacao) {
		const { value } = event.target;
		var comissaoSelecionada = cotacao.comissoes[value];
		alterarComissao(parseInt(value), comissaoSelecionada, cotacao.codigoCobertura);
	}

	function getDefaultIndex(cotacao) {
		let cotacaoIndex = cotacao.comissoes.length - 1;
		cotacao.comissoes.map((comissaoItem, index) => {
			if (comissaoItem.percentual === cotacao.comissaoAplicada.percentual) {
				cotacaoIndex = index;
			}
			return cotacaoIndex;
		});
		return cotacaoIndex;
	}

	return (
		<Fragment>
			<div className="componenteModalCotacaoAutoRastreador_cotacaoContainer">
				{cotacoes.map((cotacao) => (
					<div key={cotacao.codigoCobertura} className="card cardCotacao">
						<img src={cotacao.logo ? cotacao.logo : imagemDefault} className="card-img-top" alt="" />
						<div className="card-body cardCotacaoBody">
							{cotacao.notificacoes.length > 0 ? (
								<Fragment>
									<p className="textBack cardCotacaoDescicaoCobertura">
										Ocorreram erros durante a solicitação de cotação dessa seguradora.
									</p>
								</Fragment>
							) : (
								<Fragment>
									<p className="textBack cardCotacaoDescicaoCobertura" alt={cotacao.nomeCobertura}>
										{cotacao.nomeCobertura.length <= 50 ? (
											cotacao.nomeCobertura
										) : (
											`${cotacao.nomeCobertura.substring(0, 50)}...`
										)}
									</p>
									<p className="textBack cardCotacaoValorCobertura">{`${formatarMoeda(
										cotacao.comissaoAplicada.valorPremio
									)}`}</p>
									<p className="textBack cardCotacaoValorCoberturaLabel">/por ano</p>
									{cotacao.detalhesCobertura.map((detalheCobertura, index) => (
										<div key={index} className="cardCotacaoDetalheCobertura">
											<FontAwesomeIcon
												className="cardCotacaoDetalheCoberturaIcon"
												icon={faCheck}
											/>
											{`  ${detalheCobertura}`}
										</div>
									))}
								</Fragment>
							)}
						</div>
						<div className="card-footer cardCotacaoFooter">
							{cotacao.notificacoes.length > 0 ? (
								<button
									type="button"
									className="btn btn-warning mt-1 btn-fullWidth"
									onClick={() => {
										selecionarDetalhesCotacaoComErro(cotacao);
									}}
								>
									Detalhes
								</button>
							) : (
								<Fragment>
									<div className="row">
										<div className="col-12 pb-2 text-center">
											<span className="detalheText textBack">Comissão</span>
										</div>
										<div className="col-4 text-left">
											<span className="detalheText">
												{formatarMoeda(cotacao.comissaoMinima.valorComissao)}
											</span>
										</div>
										<div className="col-4 text-center">
											<span className="detalheText text-orange">
												{formatarMoeda(cotacao.comissaoAplicada.valorComissao)}
											</span>
										</div>
										<div className="col-4 text-right">
											<span className="detalheText">
												{formatarMoeda(cotacao.comissaoMaxima.valorComissao)}
											</span>
										</div>
										<div className="col-12 pt-1 pb-2 text-center">
											<input
												className="form-control-range"
												type="range"
												min={0}
												max={cotacao.comissoes.length - 1}
												step={1}
												value={getDefaultIndex(cotacao)}
												list={`listaComissoes${cotacao.codigoCobertura}`}
												onChange={(e) => comissaoOnChange(e, cotacao)}
											/>
											<datalist id={`listaComissoes${cotacao.codigoCobertura}`}>
												{cotacao.comissoes.map((comissao, index) => (
													<option
														key={`${cotacao.codigoCobertura}${comissao.id}`}
														value={index}
													/>
												))}
											</datalist>
										</div>
									</div>

									{cotacaoSelecionada &&
										cotacaoSelecionada.seguradora === cotacao.seguradora &&
										cotacaoSelecionada.protocoloSeguradora === cotacao.protocoloSeguradora &&
										cotacaoSelecionada.codigoCobertura === cotacao.codigoCobertura ? (
										<button
											style={{ pointerEvents: 'none' }}
											className="btn btn-success mt-1 btn-fullWidth "
										>
											SELECIONADO
										</button>
									) : (
										cotacao.comissoes.length > 0 ?
											<button
												type="button"
												className="btn btn-default mt-1 btn-fullWidth"
												onClick={() => {
													selecionarCotacao(cotacao);
												}}
											>
												Selecionar
											</button> :

											<button
												disabled
												type="button"
												className="btn btn-secondary mt-1 btn-fullWidth"
											>
												Sem Comissões <FontAwesomeIcon
													icon={faQuestionCircle}
													title="Não foram retornados registros de comissões para essa cobertura na corretora."
												/>
											</button>

									)}
								</Fragment>
							)}
						</div>
					</div>
				))}
			</div>
		</Fragment>
	);
};

export default Cotacoes;
